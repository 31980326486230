import { combineReducers } from "redux";
import * as types from "../types/user";

//////////////////////
// update password biker
//////////////////////
const updatePasswordBikers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_STARTED: {
      return true;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const updatedPasswordBikers = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return action.payload.response;
    }
    default: {
      return state;
    }
  }
};

const failedPasswordBikers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_STARTED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

////////////////////////////////////////////
// update info controller  reducer
////////////////////////////////////////////

const updatePasswordControllers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_STARTED: {
      return true;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const passwordUpdateCompleted = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return action.payload.response;
    }
    default: {
      return state;
    }
  }
};

const faildPasswordControllers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_CONTROLLER_STARTED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_PASSWORD_CONTROLLER_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

////////////////////////////////////////////
// update info controller  reducer
////////////////////////////////////////////

const updateInfoControllers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_INFO_CONTROLLER_STARTED: {
      return true;
    }
    case types.UPDATE_INFO_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_INFO_CONTROLLER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const updateControllers = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_INFO_CONTROLLER_COMPLETED: {
      return action.payload.response;
    }
    default: {
      return state;
    }
  }
};

const faildUpdateControllers = (state = false, action) => {
  switch (action.type) {
    case types.UPDATE_INFO_CONTROLLER_STARTED: {
      return false;
    }
    case types.UPDATE_INFO_CONTROLLER_COMPLETED: {
      return false;
    }
    case types.UPDATE_INFO_CONTROLLER_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

////////////////////////////////////////////
// Find emails controllers and users this is reducer
////////////////////////////////////////////
const findEmailStart = (state = false, action) => {
  switch (action.type) {
    case types.FIND_EMAIL_AUTH_STARTED: {
      return true;
    }
    case types.FIND_EMAIL_AUTH_COMPLETED: {
      return false;
    }
    case types.FIND_EMAIL_AUTH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const emailUsers = (state = null, action) => {
  switch (action.type) {
    case types.FIND_EMAIL_AUTH_COMPLETED: {
      return action.payload.info;
    }
    default: {
      return state;
    }
  }
};

const failedEmailUser = (state = false, action) => {
  switch (action.type) {
    case types.FIND_EMAIL_AUTH_STARTED: {
      return false;
    }
    case types.FIND_EMAIL_AUTH_COMPLETED: {
      return false;
    }
    case types.FIND_EMAIL_AUTH_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

///////////////////////////////////////
// created pilot user in auth firebase
///////////////////////////////////////

const creatingPilotInit = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_USER_PILOT_STARTED: {
      return true;
    }
    case types.CREATE_USER_PILOT_COMPLETED: {
      return false;
    }
    case types.CREATE_USER_PILOT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const createdPilotFinish = (state = null, action) => {
  switch (action.type) {
    case types.CREATE_USER_PILOT_COMPLETED: {
      return action.payload.info;
    }
    default: {
      return state;
    }
  }
};

const creatingPilotFailed = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_USER_PILOT_STARTED: {
      return false;
    }
    case types.CREATE_USER_PILOT_COMPLETED: {
      return false;
    }
    case types.CREATE_USER_PILOT_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

///////////////////////////////////////
// created image Pilot
///////////////////////////////////////

const creatingImagePilot = (state = false, action) => {
  switch (action.type) {
    case types.CREATED_IMAGE_STARTED: {
      return true;
    }
    case types.CREATED_IMAGE_COMPLETED: {
      return false;
    }
    case types.CREATED_IMAGE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const createdImagePilotFinish = (state = null, action) => {
  switch (action.type) {
    case types.CREATED_IMAGE_COMPLETED: {
      return action.payload.pic;
    }
    default: {
      return state;
    }
  }
};

const creatingFaildImage = (state = false, action) => {
  switch (action.type) {
    case types.CREATED_IMAGE_STARTED: {
      return false;
    }
    case types.CREATED_IMAGE_COMPLETED: {
      return false;
    }
    case types.CREATED_IMAGE_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

////////////////////////
// Getting orders new schema
////////////////////////

const gettingOrdersAll = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_ORDERS_STARTED: {
      return true;
    }
    case types.GETTING_ORDERS_COMPLETED: {
      return false;
    }
    case types.GETTING_ORDERS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getOrdersAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_ORDERS_COMPLETED: {
      return action.payload.orders;
    }
    default: {
      return state;
    }
  }
};

const getOrdersAllFailed = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_ORDERS_STARTED: {
      return false;
    }
    case types.GETTING_ORDERS_COMPLETED: {
      return false;
    }
    case types.GETTING_ORDERS_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const gettingAccountAll = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_USER_ACCOUNT_STARTED: {
      return true;
    }
    case types.GETTING_USER_ACCOUNT_COMPLETED: {
      return false;
    }
    case types.GETTING_USER_ACCOUNT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getAccountAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_USER_ACCOUNT_COMPLETED: {
      return action.payload.accounts;
    }
    default: {
      return state;
    }
  }
};

const gettingAccountFail = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_USER_ACCOUNT_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

/*
    GETTING_SMS_STARTED
    GETTING_SMS_COMPLETED
    GETTING_SMS_FAILED
*/

const gettingSms = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_SMS_STARTED: {
      return true;
    }
    case types.GETTING_SMS_COMPLETED: {
      return false;
    }
    case types.GETTING_SMS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getSmsAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SMS_COMPLETED: {
      return action.payload.sms;
    }
    default: {
      return state;
    }
  }
};

const gettingSmsFail = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SMS_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

/*
    GETTING_SUMMARY_DELIVERIES_BIKER_STARTED
    GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED
    GETTING_SUMMARY_DELIVERIES_BIKER_FAILED
*/

const gettingBikerDeliveries = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_STARTED: {
      return true;
    }
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED: {
      return false;
    }
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getBikerDeliveriesAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED: {
      return action.payload.biker;
    }
    default: {
      return state;
    }
  }
};

const gettingBikerDeliveriesFail = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

/*
    GETTING_SUMMARY_ORDERS_STARTED
    GETTING_SUMMARY_ORDERS_COMPLETED
    GETTING_SUMMARY_ORDERS_FAILED
*/

const gettingOrderDelivery = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_ORDERS_STARTED: {
      return true;
    }
    case types.GETTING_SUMMARY_ORDERS_COMPLETED: {
      return false;
    }
    case types.GETTING_SUMMARY_DELIVERIES_BIKER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getOrderDeliveryAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_ORDERS_COMPLETED: {
      return action.payload.delivery;
    }
    default: {
      return state;
    }
  }
};

const gettingOrderDeliveryFail = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_ORDERS_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

/*
  GETTING_SUMMARY_GMAILS_STARTED
  GETTING_SUMMARY_GMAILS_COMPLETED
  GETTING_SUMMARY_GMAILS_FAILED
*/

const gettingGmails = (state = false, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_GMAILS_STARTED: {
      return true;
    }
    case types.GETTING_SUMMARY_GMAILS_COMPLETED: {
      return false;
    }
    case types.GETTING_SUMMARY_GMAILS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getGettingGmailAll = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_GMAILS_COMPLETED: {
      return action.payload.gmail;
    }
    default: {
      return state;
    }
  }
};

const gettingGmailsFail = (state = null, action) => {
  switch (action.type) {
    case types.GETTING_SUMMARY_GMAILS_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

const user = combineReducers({
  gettingAccountAll,
  getAccountAll,
  gettingAccountFail,
  // update info user controller and users
  updateInfoControllers,
  updateControllers,
  faildUpdateControllers,
  // find email auth firebase
  findEmailStart,
  emailUsers,
  failedEmailUser,
  // update password controller and users
  updatePasswordControllers,
  passwordUpdateCompleted,
  faildPasswordControllers,
  // creatin pilot user
  createdPilotFinish,
  creatingPilotFailed,
  creatingPilotInit,
  // created image pilot
  creatingImagePilot,
  createdImagePilotFinish,
  creatingFaildImage,
  // update password
  updatePasswordBikers,
  updatedPasswordBikers,
  failedPasswordBikers,
  // getting all orders
  gettingOrdersAll,
  getOrdersAll,
  getOrdersAllFailed,
  // sms report
  gettingSms,
  getSmsAll,
  gettingSmsFail,
  // biker deliveries
  gettingBikerDeliveries,
  getBikerDeliveriesAll,
  gettingBikerDeliveriesFail,
  // orders deliveries
  gettingOrderDelivery,
  getOrderDeliveryAll,
  gettingOrderDeliveryFail,
  // gmails
  gettingGmails,
  getGettingGmailAll,
  gettingGmailsFail
});

export default user;

///////////////////
// getting orders all
///////////////////

export const gettingOrdes = (state) => state.gettingOrdersAll;
export const getAllOrders = (state) => state.getOrdersAll;
export const getAllOrdersFailed = (state) => state.getOrdersAllFailed;

///////////////////
// update password creating
///////////////////

export const passwordUpdateBikers = (state) => state.updatePasswordBikers;
export const passwordUpdatedBikers = (state) => state.updatedPasswordBikers;
export const passwordUpdateBikersFailed = (state) => state.failedPasswordBikers;
//////////////
// creating image pilot
/////////////
export const userImagePilotCreating = (state) => state.creatingImagePilot;
export const userImagePilotCreated = (state) => state.createdImagePilotFinish;
export const userImagePilotCreatingFaild = (state) => state.creatingFaildImage;
//////////////
// creating pilot user firebase
/////////////
export const userPilotCreating = (state) => state.creatingPilotInit;
export const userPilotCreated = (state) => state.createdPilotFinish;
export const userPilotCreatingFaild = (state) => state.creatingPilotFailed;
//////////////
// update password controller
/////////////

export const updatePasswordController = (state) =>
  state.updatePasswordControllers;
export const passwordControllerCompleted = (state) =>
  state.passwordUpdateCompleted;
export const updatePasswordFailed = (state) => state.faildPasswordControllers;

//////////////
// update info controller
/////////////
export const updateInformationController = (state) =>
  state.updateInfoControllers;
export const updateInfo = (state) => state.updateControllers;
export const updateInfoFailed = (state) => state.faildUpdateControllers;

//////////////
// find email
/////////////

export const findControllerAndUserEmail = (state) => state.findEmailStart;
export const foundControllerAndUserEmail = (state) => state.emailUsers;
export const failedControllerAndUserEmail = (state) => state.failedEmailUser;

/* 
    ACCOUNT SUMMARY
        gettingAccountAll
        getAccountAll
        gettingAccountFail
*/
export const gettingAccountUser = (state) => state.gettingAccountAll;
export const getAccountUser = (state) => state.getAccountAll;
export const gettingAccountUserFail = (state) => state.gettingAccountFail;

/* 
    ACCOUNT SUMMARY
        gettingSms,
        getSmsAll,
        gettingSmsFail
*/
export const gettingSMS = (state) => state.gettingSms;
export const getSMS = (state) => state.getSmsAll;
export const gettingSMSFail = (state) => state.gettingSmsFail;

/* 
    ACCOUNT SUMMARY
        gettingBikerDeliveries
        getBikerDeliveriesAll
        gettingBikerDeliveriesFail
*/
export const gettingBikerDeliveriesStart = (state) =>
  state.gettingBikerDeliveries;
export const getBikerDeliveriesAllStart = (state) =>
  state.getBikerDeliveriesAll;
export const gettingBikerDeliveriesFailed = (state) =>
  state.gettingBikerDeliveriesFail;

/*
    gettingOrderDelivery
    getOrderDeliveryAll
    gettingOrderDeliveryFail
 */
export const gettingOrderDeliveryStart = (state) => state.gettingOrderDelivery;
export const getOrderDeliveryAllStart = (state) => state.getOrderDeliveryAll;
export const gettingOrderDeliveryFailed = (state) => state.gettingOrderDeliveryFail;

/* 
  SUMMARY GMAIL
    gettingGmails
    getGettingGmailAll
    gettingGmailsFail
*/
export const gettingGmail = (state) => state.gettingGmails;
export const getGmail = (state) => state.getGettingGmailAll;
export const gettingGmailFail = (state) => state.gettingGmailsFail;
