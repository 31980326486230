/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React from "react";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Column,
  Export,
  Summary,
  TotalItem,
  ColumnChooser,
  ColumnFixing,
  Paging,
  StateStoring,
  SearchPanel,
  Pager,
  Selection,
  Scrolling,
  Sorting,
} from "devextreme-react/data-grid";
// import { Workbook } from 'exceljs';
// import { saveAs } from 'file-saver-es';
import moment from "moment";
import { isEmpty } from "lodash";
import { LoadPanel } from "devextreme-react";
import { Typography } from "@material-ui/core";
moment.locale("es");
const ReportTable = ({ rows }) => {
  const allowedPageSizes = [5, 10, 20, 50, 100];
  if (rows.length > 0) {
    rows.map((time) => {
      if (
        time.stateTime["En ruta"] !== undefined ||
        time.stateTime["En ruta"] !== null
      ) {
        time.onRoute = isEmpty(time.stateTime["En ruta"])
          ? ""
          : moment(time.stateTime["En ruta"].seconds * 1000).format("LTS");
      }
      if (
        time.stateTime.Entregado !== undefined ||
        time.stateTime.Entregado !== null
      ) {
        time.delivered = isEmpty(time.stateTime.Entregado)
          ? ""
          : moment(time.stateTime.Entregado.seconds * 1000).format("LTS");
      }
      if (
        time.stateTime.Recibiendo !== undefined ||
        time.stateTime.Recibiendo !== null
      ) {
        time.receiving = isEmpty(time.stateTime.Recibiendo)
          ? ""
          : moment(time.stateTime.Recibiendo.seconds * 1000).format("LTS");
      }
      if (
        time.stateTime.Asignado !== undefined ||
        time.stateTime.Asignado !== null
      ) {
        time.assigned = isEmpty(time.stateTime.Asignado)
          ? ""
          : moment(time.stateTime.Asignado.seconds * 1000).format("LTS");
      }
      if (!isEmpty(time.payMethod)) {
        if (time.payMethod === "visa-link") {
          time.payMethod = "VISA LINK";
        } else if (time.payMethod === "credit-card") {
          time.payMethod = "TARJETA DE CRÉDITO";
        } else if (time.payMethod === "cash") {
          time.payMethod = "EFECTIVO";
        }
      }
    });

    rows.map((data) => {
      if (data.branch.name === "pollo brujo zona 1") {
        data.branch.name = "ZONA 1";
      } else if (data.branch.name === "vista hermosa pollo brujo") {
        data.branch.name = "VISTA HERMOSA";
      } else if (data.branch.name === "	unicentro") {
        data.branch.name = "UNICENTRO";
      } else if (data.branch.name === "sucursal zona 9") {
        data.branch.name = "ZONA 9";
      } else if (data.branch.name === "sucursal portales") {
        data.branch.name = "PORTALES";
      } else if (data.branch.name === "unicentro") {
        data.branch.name = "UNICENTRO";
      } else if (data.branch.name === "sucursal gran via") {
        data.branch.name = "GRAN VIA";
      } else if (data.branch.name === "Vistares") {
        data.branch.name = "VISTARES";
      } else if (
        data.branch.name === "metro sur" ||
        data.branch.name === "pollo brujo metro sur"
      ) {
        data.branch.name = "METRO SUR";
      } else if (
        data.branch.name === "kalu pollo brujo " ||
        data.branch.name === "kalu"
      ) {
        data.branch.name = "KALU";
      } else if (data.branch.name === "san lucas") {
        data.branch.name = "SAN LUCAS";
      } else if (
        data.branch.name === "zona 12" ||
        data.branch.name === "sucursal zona 12 "
      ) {
        data.branch.name = "ZONA 12";
      } else if (data.branch.name === "sucursal gran plaza") {
        data.branch.name = "GRAN PLAZA";
      } else if (data.branch.name === "pollo brujo utatlan") {
        data.branch.name = "UTATLAN";
      }
    });

    rows.map((data) => {
      if (data.destinatario) {
        data.destinatario = data.destinatario.toUpperCase();
      } else {
        data.destinatario = "SIN DESTINATARIO";
      }
    });
  }

  return (
    <div>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={rows}
        width={"100%"}
        showBorders={true}
        rowAlternationEnabled={true}
        noDataText="NO HAY INFORMACIÓN"
        repaintChangesOnly={true}
      >
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="orderId"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="reportOrders"
        />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} placeholder="BUSCAR" width={240} />
        <Pager
          allowedPageSizes={allowedPageSizes}
          showPageSizeSelector={true}
          displayMode={true}
          showInfo={true}
          showNavigationButtons={true}
        />

        <Selection mode="single" />
        {/* <Scrolling columnRenderingMode="virtual" /> */}
        <Sorting mode="none" />
        <LoadPanel enabled={false} />
        <Scrolling mode="infinite" />
        <Paging enabled={true} defaultPageSize={20} />

        {/* <ColumnChooser enabled={true} /> */}
        {/* <ColumnFixing enabled={true} /> */}
        <Column caption="CODIGO" dataField="orderId" width={"auto"} />
        <Column caption="NOMBRE CLIENTE" dataField="name" width={"auto"} />
        <Column caption="CARGA DE ORDEN" dataField="createdAt" width={"auto"} />
        <Column
          caption="FECHA CARGA DE ORDEN"
          dataField="createdAtDate"
          width={"auto"}
        />
        <Column
          caption="ASIGNACIÓN WHEELER"
          dataField="assigned"
          width={"auto"}
        />
        <Column
          caption="ARRIBO SUCURSAL"
          dataField="receiving"
          width={"auto"}
        />
        <Column caption="EN RUTA" dataField="onRoute" width={"auto"} />
        <Column
          caption="ORDEN ENTREGADA"
          dataField="delivered"
          width={"auto"}
        />
        {/* <Column caption="CIRCULO" dataField="circleId" width={"auto"} /> */}
        <Column caption="PILOTO" dataField="wheeler" width={"auto"} />
        <Column
          caption="DISTANCIA PILOTO"
          dataField="distanceTotal"
          width={"auto"}
        />
        <Column caption="ZONA" dataField="zone" width={"auto"} />
        <Column caption="MUNICIPIO" dataField="munic" width={"auto"} />
        <Column caption="DISTANCIA" dataField="distance" width={"auto"} />
        <Column
          caption="DISTANCIA DE ENTREGA"
          dataField="deliveryDetails.distance"
          width="auto"
        />
        <Column caption="COSTO" dataField="deliveryDetails.calculatedCost" width="auto" />
        <Column caption="TIPO DE PAGO" dataField="payMethod" width={"auto"} />
        <Column
          caption="TIEMPO DE PREPARACION"
          dataField="preparationTime"
          width={"auto"}
        />
        <Column
          caption="TIEMPO DE ENTREGA"
          dataField="deliveryTime"
          width={"auto"}
        />
        <Column
          caption="TIEMPO TOTAL DE ENTREGA"
          dataField="deliveryTotalTime"
          width={"auto"}
        />
        <Column caption="CONTENIDO" dataField="nota" width={"auto"} />
        <Column caption="TIPO DE ORDEN" dataField="typeOrder" width={"auto"} />
        <Column
          caption="INCIDENTES"
          dataField="orderObservations"
          width={"auto"}
        />
        <Column caption="EMPRESA" dataField="company.name" width={"auto"} />
        <Column caption="SUCURSAL" dataField="branch.name" width={"auto"} />
        <Column caption="DIRECCIÓN" dataField="address" width={"auto"} />
        <Column
          caption="ENTREGA LATITUD"
          dataField="locationEnd._lat"
          width={"auto"}
        />
        <Column
          caption="ENTREGA LONGITUD"
          dataField="locationEnd._long"
          width={"auto"}
        />
        <Column
          caption="DESTINATARIO"
          dataField="destinatario"
          width={"auto"}
        />
        <Column
          caption="ESTADO"
          dataField="status"
          width={"auto"}
        />
        <Export enabled={true} />
      </DataGrid>
    </div>
  );
};

export default ReportTable;
