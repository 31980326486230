import * as types from "../types/user";

// update info controller
export const updateInfoController = (info) => ({
  type: types.UPDATE_INFO_CONTROLLER_STARTED,
  payload: {
    info,
  },
});

export const infoController = (response) => ({
  type: types.UPDATE_INFO_CONTROLLER_COMPLETED,
  payload: {
    response,
  },
});

export const infoFailedController = (error) => ({
  type: types.UPDATE_INFO_CONTROLLER_FAILED,
  payload: {
    error,
  },
});
//////////////////////////
// update password controller
//////////////////////////
export const updatePasswordController = (info) => ({
  type: types.UPDATE_PASSWORD_CONTROLLER_STARTED,
  payload: {
    info,
  },
});

export const passwordController = (response) => ({
  type: types.UPDATE_PASSWORD_CONTROLLER_COMPLETED,
  payload: {
    response,
  },
});

export const passwordFailedController = (error) => ({
  type: types.UPDATE_PASSWORD_CONTROLLER_FAILED,
  payload: {
    error,
  },
});

//////////////////////////
// update password biker
//////////////////////////

export const updatePasswordBiker = (info) => ({
  type: types.UPDATE_PASSWORD_PILOT_STARTED,
  payload: {
    info,
  },
});

export const passwordBiker = (response) => ({
  type: types.UPDATE_PASSWORD_PILOT_COMPLETED,
  payload: {
    response,
  },
});

export const passwordFailedBiker = (error) => ({
  type: types.UPDATE_PASSWORD_PILOT_FAILED,
  payload: {
    error,
  },
});

//////////////////////////
// create user biker
//////////////////////////

export const createUserBiker = (info) => ({
  type: types.CREATE_USER_PILOT_STARTED,
  payload: {
    info,
  },
});

export const userBiker = (response) => ({
  type: types.CREATE_USER_PILOT_COMPLETED,
  payload: {
    response,
  },
});

export const userBikerFailed = (error) => ({
  type: types.CREATE_USER_PILOT_FAILED,
  payload: {
    error,
  },
});

//////////////////////////
// find email auth or controller
//////////////////////////

export const findEmail = (email) => ({
  type: types.FIND_EMAIL_AUTH_STARTED,
  payload: {
    email,
  },
});

export const emailFound = (info) => ({
  type: types.FIND_EMAIL_AUTH_COMPLETED,
  payload: {
    info,
  },
});

export const emailFailedFind = (error) => ({
  type: types.FIND_EMAIL_AUTH_FAILED,
  payload: {
    error,
  },
});

/////////////
// created pilots app
/////////////

export const craetingPilot = (info) => ({
  type: types.CREATE_USER_PILOT_STARTED,
  payload: {
    info,
  },
});

export const craetedPilot = (info) => ({
  type: types.CREATE_USER_PILOT_COMPLETED,
  payload: {
    info,
  },
});

export const creatingFailed = (error) => ({
  type: types.CREATE_USER_PILOT_FAILED,
  payload: {
    error,
  },
});

export const creatingImage = (pic) => ({
  type: types.CREATED_IMAGE_STARTED,
  payload: {
    pic,
  },
});

export const createdImage = (pic) => ({
  type: types.CREATED_IMAGE_COMPLETED,
  payload: {
    pic,
  },
});

export const failedCreated = (failed) => ({
  type: types.CREATED_IMAGE_FAILED,
  payload: {
    failed,
  },
});

export const gettingOrders = () => ({
  type: types.GETTING_ORDERS_STARTED,
});

export const getOrders = (orders) => ({
  type: types.GETTING_ORDERS_COMPLETED,
  payload: { orders },
});

export const getOrdersFailed = (e) => ({
  type: types.GETTING_ORDERS_FAILED,
  payload: { e },
});

/*
    ACCOUNT USERR
*/

export const gettingUserAccount = () => ({
  type: types.GETTING_USER_ACCOUNT_STARTED,
});

export const getUserAccount = (accounts) => ({
  type: types.GETTING_USER_ACCOUNT_COMPLETED,
  payload: { accounts },
});

export const getUserAccountFailed = (e) => ({
  type: types.GETTING_USER_ACCOUNT_FAILED,
  payload: { e },
});

/*
    sms 
    GETTING_SMS_STARTED
    GETTING_SMS_COMPLETED
    GETTING_SMS_FAILED
*/

export const gettingSms = (startDate, endDate) => ({
  type: types.GETTING_SMS_STARTED,
  payload: { startDate, endDate },
});

export const getSms = (sms) => ({
  type: types.GETTING_SMS_COMPLETED,
  payload: { sms },
});

export const getSmsFailed = (e) => ({
  type: types.GETTING_SMS_FAILED,
  payload: { e },
});

/*
    BIKER SUMMARY
        GETTING_SUMMARY_DELIVERIES_BIKER_STARTED
        GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED
        GETTING_SUMMARY_DELIVERIES_BIKER_FAILED
 */

export const gettinDeliveriesBiker = () => ({
  type: types.GETTING_SUMMARY_DELIVERIES_BIKER_STARTED,
});

export const getDeliveriesBiker = (biker) => ({
  type: types.GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED,
  payload: { biker },
});

export const getDeliveriesBikerFailed = (e) => ({
  type: types.GETTING_SUMMARY_DELIVERIES_BIKER_FAILED,
  payload: { e },
});

/*
    BIKER ORDERS
      GETTING_SUMMARY_ORDERS_STARTED
      GETTING_SUMMARY_ORDERS_COMPLETED
      GETTING_SUMMARY_ORDERS_FAILED
 */

export const gettinOrdersSummary = (startDate, endDate) => ({
  type: types.GETTING_SUMMARY_ORDERS_STARTED,
  payload: { startDate, endDate }
});

export const getOrdersSummary = (delivery) => ({
  type: types.GETTING_SUMMARY_ORDERS_COMPLETED,
  payload: { delivery },
});

export const getOrdersSummaryFailed = (e) => ({
  type: types.GETTING_SUMMARY_ORDERS_FAILED,
  payload: { e },
});

/*
  Gmail data  
    GETTING_SUMMARY_GMAILS_STARTED
    GETTING_SUMMARY_GMAILS_COMPLETED
    GETTING_SUMMARY_GMAILS_FAILED
*/

export const gettingGmail = (startDate, endDate) => ({
  type: types.GETTING_SUMMARY_GMAILS_STARTED,
  payload: { startDate, endDate },
});

export const getGmail = (gmail) => ({
  type: types.GETTING_SUMMARY_GMAILS_COMPLETED,
  payload: { gmail },
});

export const getGmailFailed = (e) => ({
  type: types.GETTING_SUMMARY_GMAILS_FAILED,
  payload: { e },
});