/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";
import AssistanceTable from "./AssistanceTable";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import * as selector from "../../logic/reducers";

function PilotAssistance({
  completedSchedules,
  fetchingSchedules,
  paramsBranch,
  general,
}) {
  useEffect(() => {
    fetchingSchedules(paramsBranch);
  }, [fetchingSchedules]);


  return (
    <div>
      <div>
        <AssistanceTable
          data={completedSchedules}
          branch={paramsBranch?.name}
          general={general}
        />
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    completedSchedules: selector.getPilotSchedulesCompleted(state),
  }),
  (dispatch) => ({
    fetchingSchedules(paramsBranch) {
      dispatch(actions.pilotSchedulesStarted(paramsBranch));
    },
  })
)(PilotAssistance);
