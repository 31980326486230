/* eslint-disable no-unused-vars */
import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { ROUTES } from "../../constants/constants";
import "./styles.css";
import { configureStore } from "../../store";
import LogIn from "../LogIn";
import AllOrders from "../AllOrders";
import Alerts from "../Alerts";
import HistoryOrders from "../HistoryOrders";
import AbandonedOrders from "../AbandonedOrders/AbandonedOrders";
import DeletedOrders from "../DeletedOrders/DeletedOrders";
import ReagendOrders from "../ReagendOrders/ReagendOrders";
import CanceledOrders from "../CanceledOrders/CanceledOrders";
import BikerQualification from "../Biker/BikerQualification";
import CreateOrder from "../CreateOrder/CreateOrder";
import NewOrders from "../NewOrders";
import CatalogOperations from "../Catalog/CatalogOperations";
import EditOrder from "../EditOrder/EditOrder";
import Maps from "../Map";
import Biker from "../Biker/Biker";
import BikerList from "../Biker/BikerList";
import EditBiker from "../Biker/EditBiker";
import CreateBiker from "../Biker/CreateBiker";
import Order from "../Order/Order";
import OrderImport from "../Order/OrderImport";
import BranchResume from "../Branch/BranchResume";
import BranchCreate from "../Branch/BranchCreate";
import BranchModify from "../Branch/BranchModify";
import Companies from "../Company/Companies";
import CompanyBranch from "../Company/CompanyBranch";
import CreateCompany from "../Company/CreateCompany";
import ReportView from "../Report/ReportView";
import DailyPerformancePage from "../DailyPerformance/DailyPerformancePage";
import UserList from "../User/UserList";
import PrivateRoute from "../Routes/PrivateRoute";
import LoginShare from "../LoginShare";
import Share from "../Share";
import AppLayout from "../../layout";
import ResumeView from "../Resume";
import BranchResumeView from "../Resume/BranchResume";
import Chat from "../Chat/Chatbot";
import CompanyResumeView from "../Resume/CompanyResume";
import PilotAssistance from "../PilotAssistance";
import CreateUsers from "../CreateUsers";
import AllOrdersFilter from "../AllOrdersFilter";
import EditOrderFilters from "../EditOrderFilters/EditOrderFilters";
import BikerCreateUser from "../UserBikers/create-biker";
import BikerUpdatePassword from "../UserBikers/update-biker";
import ControllerUpdatePassword from "../UsersControllers/update-password";
import ControllerUpdateInfo from "../UsersControllers/update-info";
import OrdersNewView from "../OrdersNewView";

/* VERSION 2.0.2 */
import SummaryAccountUser from "../Resume/SummaryAccountUser";
import SummaryCompany from "../Resume/SummaryCompany";
import DashboardAccounts from "../Resume/DashboardAccounts";
import SmsQty from "../Resume/SmsQty";
import SmsReport from "../Resume/SmsReport";
import SummaryBikers from "../Resume/SummaryBikers";
import SummaryEmails from "../Resume/SummaryEmails";
import ShareCompanies from "../ShareCompanies";
import NewSchedule from "../PilotAssistanceCompany/NewSchedule";
/* ADD NEW VIEWS */
import OrderIshop from "../AllOrdersFilter/OrderIshop";
import BikerLocation from "../BikerLocation";
import DevicesBikers from "../BikerLocation/DevicesBikers";
/* VIEWS ADMINISTRATOR */
import LocationBikerBranch from "../BikerLocation/RoleAdministrator";
import OrdersSanMartin from "../AllOrdersFilter/OrdersSanMartin";
import ApprovedIndex from "../PilotAssistanceCompany/ApprovedIndex";
import SingleBikerLocation from "../BikerLocation/SingleBikerLocation";
/* VIEW REQUEST */
import MapRequest from "../Request/MapRequest";
import DashboardAlerts from "../Request/DashboardAlerts";
import DashboardCircleAllCompany from "../Circle/DashboardCircleAllCompany";

export const { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            <Route path="/loginLink/:id" component={LoginShare} />
            <Route path={ROUTES.SHARE} component={Share} />
            <Route
              path={"/approved-check/:check/:id"}
              component={ApprovedIndex}
            />
            <Redirect exact from="/" to="/login" />
            <Route path="/login" component={LogIn} />
            <SnackbarProvider
              maxSnack={5}
              preventDuplicate
              style={{ width: "400px" }}
            >
              <AppLayout>
                <PrivateRoute path={ROUTES.ALL_ORDERS} component={AllOrders} />
                <PrivateRoute
                  path={ROUTES.CREATE_ORDER}
                  component={CreateOrder}
                />
                <PrivateRoute path="/orders-new" component={OrdersNewView} />
                <PrivateRoute
                  path="/pilot-create"
                  component={BikerCreateUser}
                />
                <PrivateRoute
                  path="/pilot-update-pass"
                  component={BikerUpdatePassword}
                />
                <PrivateRoute
                  path="/share-companies/:id"
                  Component={ShareCompanies}
                />
                <PrivateRoute
                  path="/summary-account-user"
                  component={SummaryAccountUser}
                />
                <PrivateRoute
                  path="/summary-company"
                  component={SummaryCompany}
                />
                <PrivateRoute
                  path="/dashboard-account"
                  component={DashboardAccounts}
                />

                <PrivateRoute path="/dashboard-sms" component={SmsQty} />
                <PrivateRoute path="/report-sms" component={SmsReport} />
                <PrivateRoute
                  path="/reporte-motorista"
                  component={SummaryBikers}
                />
                <PrivateRoute path="/reporte-gmail" component={SummaryEmails} />

                {/* modificar horarios */}
                <PrivateRoute path="/schedules-biker" component={NewSchedule} />

                <PrivateRoute
                  path="/controller-update-pass"
                  component={ControllerUpdatePassword}
                />
                <PrivateRoute
                  path="/controller-update-info"
                  component={ControllerUpdateInfo}
                />

                <PrivateRoute
                  path={ROUTES.ALL_ORDERS_FILTER}
                  component={AllOrdersFilter}
                />

                {/* filtrar por companias en delivery */}
                <PrivateRoute
                  path={ROUTES.ALL_ORDER_ISHOP}
                  component={OrderIshop}
                />

                <PrivateRoute
                  path={ROUTES.ALL_ORDER_SAN_MARTIN}
                  component={OrdersSanMartin}
                />

                <PrivateRoute
                  path="/localization-biker/:branchId"
                  component={LocationBikerBranch}
                />

                <PrivateRoute
                  path="/pilot-assistance"
                  component={PilotAssistance}
                />
                <PrivateRoute path={ROUTES.NEW_ORDERS} component={NewOrders} />
                {/* BIKER ALERTS */}
                <PrivateRoute path={ROUTES.ALERTS.HOME} component={Alerts} />
                {/* Biker Locations */}
                <PrivateRoute
                  path={"/biker-location"}
                  component={BikerLocation}
                />
                <PrivateRoute
                  path={"/ver-mas/:bikerId"}
                  component={DevicesBikers}
                />
                <PrivateRoute
                  path={ROUTES.CREATE_USERS}
                  component={CreateUsers}
                />
                <PrivateRoute
                  path={ROUTES.HISTORY_ORDERS}
                  component={HistoryOrders}
                />
                <PrivateRoute
                  path={`${ROUTES.EDIT_ORDER_FILTER}/:orderId`}
                  component={EditOrderFilters}
                />
                <PrivateRoute
                  path={ROUTES.ABANDONED_ORDERS}
                  component={AbandonedOrders}
                />
                <PrivateRoute
                  path={ROUTES.DELETED_ORDERS}
                  component={DeletedOrders}
                />
                <PrivateRoute
                  path={ROUTES.REAGEND_ORDERS}
                  component={ReagendOrders}
                />
                <PrivateRoute
                  path={ROUTES.CANCELED_ORDERS}
                  component={CanceledOrders}
                />
                <PrivateRoute
                  path={ROUTES.BIKERS_QUALIFICATION}
                  component={BikerQualification}
                />
                <PrivateRoute
                  path={`${ROUTES.EDIT_ORDER}/:orderId`}
                  component={EditOrder}
                />
                <PrivateRoute
                  path={`${ROUTES.ORDER}/:orderId`}
                  component={Order}
                />
                <PrivateRoute
                  path={ROUTES.ORDER_IMPORT}
                  component={OrderImport}
                />

                <PrivateRoute path={ROUTES.MAP} component={Maps} />

                <PrivateRoute
                  path={`${ROUTES.BIKER}/:bikerId`}
                  component={Biker}
                />
                <PrivateRoute
                  path={`${ROUTES.BIKER}/single-location/:bikerId`}
                  component={SingleBikerLocation}
                />
                <PrivateRoute
                  path={`${ROUTES.BIKER_MODIFY}/:bikerId`}
                  component={EditBiker}
                />
                <PrivateRoute path={ROUTES.BIKERS} component={BikerList} />
                <PrivateRoute
                  path={ROUTES.BIKER_CREATE}
                  component={CreateBiker}
                />

                {/* Branches */}
                <PrivateRoute
                  path={`${ROUTES.BRANCH_RESUME}/:branchId`}
                  component={BranchResume}
                />
                <PrivateRoute
                  path={`${ROUTES.BRANCH.CREATE}/:companyId`}
                  component={BranchCreate}
                />
                <PrivateRoute
                  path={`${ROUTES.BRANCH.MODIFY}/:companyId/:branchId`}
                  component={BranchModify}
                />

                <PrivateRoute
                  path={ROUTES.COMPANY}
                  exact
                  component={Companies}
                />
                <PrivateRoute
                  path={`${ROUTES.COMPANY_BRANCHES}/:companyId`}
                  component={CompanyBranch}
                />
                <PrivateRoute
                  path={ROUTES.CREATE_COMPANY}
                  exact
                  component={CreateCompany}
                />

                <PrivateRoute
                  path={ROUTES.REPORT_VIEW}
                  component={ReportView}
                />
                <PrivateRoute
                  path={ROUTES.DAILY_PERFORMANCE}
                  component={DailyPerformancePage}
                />
                <PrivateRoute
                  path={ROUTES.RESUME_VIEW}
                  component={ResumeView}
                />
                <PrivateRoute
                  path={ROUTES.DASHBOARDS.concat(
                    "",
                    `${ROUTES.BRANCH_DASHBOARD}/:branchId`
                  )}
                  Component={BranchResumeView}
                />
                <PrivateRoute
                  path={ROUTES.DASHBOARDS.concat("", ROUTES.COMPANY_RESUME)}
                  Component={CompanyResumeView}
                />
                <PrivateRoute path={ROUTES.USERS.LIST} component={UserList} />
                <PrivateRoute
                  path={`${ROUTES.CATALOG.ALL_CATALOGS}`}
                  component={CatalogOperations}
                />
                <PrivateRoute path="/chatbot" component={Chat} />
                {/* CIRCLES */}
                <PrivateRoute path="/circles" component={DashboardCircleAllCompany} />
                {/* request */}
                <PrivateRoute path="/alertas/mapas" component={MapRequest} />
                <PrivateRoute path="/dashboards/alertas" component={DashboardAlerts} />
              </AppLayout>
            </SnackbarProvider>
          </Switch>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
