import React, { useState, useEffect } from "react";
import {
  Card,
  makeStyles,
  Avatar,
  CardContent,
  Button,
  TextField,
} from "@material-ui/core";
import Modal from "../../components/Design/Modal";
import { useSnackbar } from "notistack";
import { BikerModel } from "../../models";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ReactStars from "react-rating-stars-component";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import { getController } from "../../logic/reducers";
import BikerRaitingModal from "../../components/Biker/BikerRaitingModal";
import { bikerRatingState } from "../../logic/selectors/bikers";
import isEmpty from "lodash/isEmpty";
import { BIKER_RATING_STATUS, snackBarConst } from "../../constants/constants";

const commonIssues = [
  {
    value: "orderCrossed",
    text: "Cruce de Orden",
  },
  {
    value: "isLate",
    text: "Llega tarde",
  },
  {
    value: "team",
    text: "Equipo",
  },
  {
    value: "appFailing",
    text: "Falla aplicación",
  },
  {
    value: "notInShift",
    text: "Falta a turno",
  },
  {
    value: "driverLicense",
    text: "Licencia de conducir",
  },
  {
    value: "noApp",
    text: "No aplicación",
  },
  {
    value: "noKnowRoute",
    text: "No conoce ruta",
  },
  {
    value: "orderCompletedBeforeDelivery",
    text: "Orden finalizada antes de entregada",
  },
  {
    value: "lost",
    text: "Perdido",
  },
  {
    value: "personalPresentation",
    text: "Presentación personal",
  },
  {
    value: "noUniform",
    text: "Falta uniforme",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 200,
    height: 400,
    position: "relative",
  },
  img: {
    zIndex: 10,
    margin: "auto",
    marginBottom: "-20px",
  },
  header: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: 80,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  content: {
    flexDirection: "column",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    top: 80,
    right: 0,
    width: "100%",
    height: 350,
  },
  bikerDetails: {
    textAlign: "center",
  },
  button: {
    margin: "auto",
  },
  shapeLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    clipPath: "polygon(0 0, 80% 0, 35% 50%, 0 12%)",
  },
  shapeTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    clipPath: "polygon(0 12%, 0 86%, 35% 50%)",
    backgroundColor: "#808080",
  },
  ellipse: {
    background: "rgba(242,103,36,0.18)",
    width: "120%",
    height: "100px",
    borderRadius: "50%",
    position: "absolute",
    bottom: "-50px",
    left: "-10%",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
  },
}));
const RatingComponent = ({ onRating, value, details }) => {
  return (
    <ReactStars count={5} onChange={onRating} size={24} activeColor="#ffd700" />
  );
};
const CustomBikerCard = ({
  details = new BikerModel(),
  branchId,
  allowForm = true,
}) => {
  const ratingState = useSelector(bikerRatingState);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [state, setState] = useState({ message: "", rating: 2 });
  const [commonIssueValue, setCommonIssueValue] = useState("n/a");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const controller = useSelector(getController);
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };
  const onRating = (value) => {
    setState({
      ...state,
      rating: Number(value),
    });
  };
  const onSubmit = () => {
    dispatch(
      actions.pilotActions.createBikerRatingStarted({
        branchId,
        rating: state.rating,
        message: state.message,
        nameBiker: details.name,
        phone: details.phone,
        transportId: details.transportId,
        branch: details['SUCURSAL'],
        dpi: details.dpi,
        bikerId: details.id,
        controllerId: controller.id,
        issue: (
          commonIssues.find((val) => val.value === commonIssueValue) || {
            text: "n/a",
          }
        ).text,
        type: "BRANCH",
        ...state,
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(ratingState)) {
      if (ratingState === BIKER_RATING_STATUS.COMPLETED) {
        dispatch(actions.pilotActions.clearBikerRatingStarted());
        enqueueSnackbar("Comentario creado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        setCommonIssueValue("n/a");
        setState({ message: "", rating: 0 });
      } else if (ratingState === BIKER_RATING_STATUS.FAILED) {
        dispatch(actions.pilotActions.clearBikerRatingStarted());
        enqueueSnackbar(
          "Hubo un error al crear el comentario, por favor, intente más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingState]);

  const getAvgRaiting = () => {
    const avg =
      details.raitings.reduce((acc, current) => acc + current.rating, 0) /
      (details.raitings || []).length;
    return avg;
  };


  return (
    <Card>
      {isModalOpened && (
        <Modal
          isOpened={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          body={
            <BikerRaitingModal
              onModalClose={() => setIsModalOpened(false)}
              biker={details}
            />
          }
        />
      )}
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.circle} />
          <div className={classes.shapeLeft} />
          <div className={classes.shapeTop} />
          <div className={classes.img}>
            {!isEmpty(details.pic) ? (
              <img
                src={details.pic}
                alt="2Wheeler"
                className={`MuiAvatar-root MuiAvatar-circle ${classes.large}`}
              />
            ) : (
              <Avatar className={classes.large}>{details.name[0]}</Avatar>
            )}
          </div>
        </div>
        <CardContent className={classes.content}>
          <p style={{ fontSize: "25px", fontWeight: "600", color: "#808080" }}>
            {details.name}
          </p>
          <small>{details.phone}</small>
          {!allowForm && (
            <>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#808080",
                }}
              >
                CALIFICACIÓN PROMEDIO
              </p>
              <ReactStars
                count={5}
                value={getAvgRaiting()}
                size={24}
                activeColor="#ffd700"
                edit={false}
              />
            </>
          )}
          {allowForm && (
            <>
              <RatingComponent
                onRating={onRating}
                value={state.rating}
                details={details}
              />
              <FormControl variant="outlined" className="full-width">
                <Select
                  label="Incidente"
                  onChange={(e) => {
                    setCommonIssueValue(e.target.value);
                  }}
                  value={commonIssueValue}
                >
                  <MenuItem value="n/a">NINGUNO</MenuItem>
                  {commonIssues.map((opt) => (
                    <MenuItem value={opt.value} key={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                autoFocus
                margin="dense"
                id="message"
                label="Comentario"
                type="message"
                className="all-upper-case"
                onChange={onChange}
                value={state.message}
                fullWidth
              />
            </>
          )}
        </CardContent>

        <div className={classes.footer}>
          <div className={classes.ellipse} />
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={allowForm ? onSubmit : () => setIsModalOpened(true)}
          >
            {allowForm ? "ENVIAR CALIFICACIÓN" : "VER CALIFICACIONES"}
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default CustomBikerCard;
