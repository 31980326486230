import React, { useState, useEffect } from "react";
import Map from "../Map/Map";
import { BIKER_ICON } from "../../constants/constants";

const BikerPositionModal = ({ onModalClose, biker }) => {
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(14);
  const [center, setCenter] = useState();
  const [preserveViewport, setPreserveViewport] = useState(false);

  useEffect(() => {
    setCenter({
      lat: biker.actualPosition.latitude,
      lng: biker.actualPosition.longitude,
    });
    const currentMarkers = [
      {
        id: biker.id,
        icon: BIKER_ICON,
        position: {
          latitude: biker.actualPosition.latitude,
          longitude: biker.actualPosition.longitude,
        },
      },
    ];
    setMarkers(currentMarkers);
  }, [biker]);

  return (
    <div className="modal-container modal-container-height">
      <div className="modal-title">
        <div className="modal-title-text">Rastrear Piloto</div>
        <div className="close" onClick={() => onModalClose()}>
          x
        </div>
      </div>
      <div className="modal-body">
        <Map
          markers={markers}
          center={center}
          zoom={zoom}
          setZoom={setZoom}
          preserveViewport={preserveViewport}
          setPreserveViewport={setPreserveViewport}
          height="400px"
          shouldCreateRoute
        />
      </div>
    </div>
  );
};

export default BikerPositionModal;
