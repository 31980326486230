import { INPUT_TYPE } from "./constants";

export const emptyCompany = {
  name: "",
  description: "",
  sms: false,
  email: false,
  emails: "",
};

export const defaultFormFields = [
  {
    label: "Nombre",
    name: "name",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Descripción",
    name: "description",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "SMS",
    name: "sms",
    cssClasses: "col-12",
    fullWidth: true,
    inputType: INPUT_TYPE.CHECKBOX,
  },
  {
    label: "Email",
    name: "email",
    cssClasses: "col-12",
    fullWidth: true,
    inputType: INPUT_TYPE.CHECKBOX,
  },
  {
    label: "Lista de Correos",
    name: "emails",
    cssClasses: "col-12 m-top-sm",
    fullWidth: true,
    multiline: true,
  },
];
