import React, { useState } from "react";
import { Button } from "@mui/material";
import MapWithAMarker from "../map/googleMaps";
import "./new_styles/styles.css";
// data de order
import Stepped from "./new_styles/stepped";
import BikersInformation from "./new_styles/information";

const LinkRuta = (props) => {
  const [showInformation, setShowInformation] = useState(true);
  const [showBiker, setshowBiker] = useState(true);

  const trogglerButton = () => {
    setShowInformation(!showInformation);
  };

  const troggerBiker = () => {
    setshowBiker(!showBiker);
  };

  const CustomButton = ({ label, handler }) => {
    return (
      <Button
        fullWidth
        style={{
          backgroundColor: "#20419a", // Color de fondo
          color: "white",
          alignItems: "center",
          borderRadius: 8,
          padding: "10px 20px",
          fontSize: 16,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          marginTop: "1rem",
        }}
        variant="contained"
        color="primary"
        type="submit"
        className="show-more-button"
        onClick={handler}
      >
        {label}
      </Button>
    );
  };

  return (
    <div className="content">
      {/* information about status orders */}
      <div className="left-column">
        <div
          className="tracking-delivery"
          style={{
            display: showInformation ? "block" : "none",
          }}
        >
          <Stepped orders_data={props.data} timer={props.time} />
        </div>
        {/* logic to show the information of the biker */}
        {showInformation ? (
          <CustomButton
            label={"CERRAR RASTREO DE LA ORDEN"}
            handler={trogglerButton}
          />
        ) : (
          <CustomButton
            label={"MOSTRAR RASTREO DE LA ORDEN"}
            handler={trogglerButton}
          />
        )}
      </div>

      {/* right column */}
      <div className="right-column">
        {/* mapa render */}
        <div className="map-container">
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3FPeuS4S-c2F_6Z83jULWD7MUfg1gBAw&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div className="map-item" />}
            containerElement={<div style={{ height: "auto", width: "auto" }} />}
            mapElement={
              <div
                style={{ height: "92vh", width: "auto", position: "fixed" }}
              />
            }
            pilot={props.biker}
            data={props.data}
          />
        </div>

        {/* informacion de los motoristas */}
        <div className="information-biker">
          <div
            className="tracking-delivery"
            style={{
              display: showBiker ? "block" : "none",
            }}
          >
            {/* information about bikers */}
            <BikersInformation data_biker={props.biker} data_orders={props.data} />
          </div>
          {/* logic to show the information of the biker */}
          {showBiker ? (
            <CustomButton
              label={"OCULTAR INFORMACION"}
              handler={troggerBiker}
            />
          ) : (
            <CustomButton
              label={"MOSTRAR INFORMACION"}
              handler={troggerBiker}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LinkRuta;
