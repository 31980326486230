import React from "react";
import Expandable from "../Design/Expandable";
import Card from "../Design/Card";

const OrderAlerts = ({
  orders,
  total,
  useExpandable = true,
  fullBackground = false,
}) => {
  const renderOrders = () =>
    orders.map((order) => (
      <div
        className={`card-container ${order.cardCssClases}`}
        key={order.label}
      >
        <Card
          bodyCssClases="sumary-body text-center"
          titleCssClases={`${order.cssClases} text-center padding-top-sm padding-bottom-sm`}
          cssClases="no-padding"
          noPadding
          title={<>{order.label}</>}
          body={fullBackground ? order.value : <h2>{`${order.value}/${total}`}</h2>}
        />
      </div>
    ));

  const renderView = () => {
    if (useExpandable) {
      return <Expandable title="Resumen">{renderOrders()}</Expandable>;
    }
    return renderOrders();
  };

  return <>{renderView()}</>;
};

export default OrderAlerts;
