import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import * as selector from "../../logic/reducers";
import DataGrid, {
  SearchPanel,
  Paging,
  Pager,
  Column,
} from "devextreme-react/data-grid";
import { Grid } from "@material-ui/core";

const BranchBikerQualify = ({
  paramsBranch,
  completed,
  started,
  fetchingRatingPilots,
}) => {
  useEffect(() => {
    fetchingRatingPilots();
  }, [fetchingRatingPilots]);
  const allowedPageSizes = [20, 40, 50];
  const filterValue = [["comment.branch", "=", paramsBranch.name]];

  return (
    <div>
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justify="center"
        className="dx-field-label"
      >
        <Grid item xs={11} md={11} lg={12}>
          <DataGrid
            keyExpr="id"
            dataSource={completed}
            showBorders={true}
            columnAutoWidth={true}
            rowAlternationEnabled={true}
            defaultFilterValue={filterValue}
            repaintChangesOnly={true}
          >
            <Paging enabled={true} defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              displayMode={true}
              allowedPageSizes={allowedPageSizes}
              showInfo={true}
              showNavigationButtons={true}
            />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Column
              dataField="nameBiker"
              caption="PILOTO"
              alignment="center"
              width={"100"}
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="phone"
              caption="TELEFONO"
              alignment="center"
              width={"100"}
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="transportId"
              caption="PLACA"
              alignment="center"
              width={"100"}
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="avarage"
              caption="PROMEDIO CALIFICACIÓN"
              alignment="center"
              cssClass="cell-highlighted"
              width={"auto"}
            />
            <Column
              dataField="totalComments"
              caption="TOTAL DE COMENTARIOS"
              alignment="center"
              width={"auto"}
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="dpi"
              caption="DOCUMENTO DE IDENTIFICACIÓN"
              alignment="center"
              width={"200"}
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="comment.comment"
              caption="COMENTARIO SUCURSAL"
              alignment="left"
              cssClass="cell-highlighted"
              allowEditing={false}
            />
            <Column
              dataField="comment.branch"
              caption="SUCURSAL"
              alignment="left"
              cssClass="cell-highlighted"
              allowEditing={false}
            />
          </DataGrid>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(
  (state) => ({
    completed: selector.getPilotRatingCompleted(state),
    started: selector.getPilotRatingStarted(state),
  }),
  (dispatch) => ({
    fetchingRatingPilots() {
      dispatch(actions.pilotRatingGetStarted());
    },
  })
)(BranchBikerQualify);
