import { INPUT_TYPE } from "./constants";

export const emptyBiker = {
  alert: "",
  company: {},
  branch: {},
  disponibility: "",
  name: "",
  phone: "",
  pushToken: "",
  total_traveled: "",
  wildcard: "",
  pic: "",
  type: {},
};

export const defaultFormFields = [
  {
    label: "Nombre",
    name: "name",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "DPI",
    name: "dpi",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "PLACA MOTO",
    name: 'transportId',
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Teléfono",
    name: "phone",
    cssClasses: "col-6",
    fullWidth: true,
  },
  {
    label: "Tipo",
    name: "type",
    cssClasses: "col-6",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [
      {
        value: "WILDCARD",
        text: "COMODIN",
      },
      {
        value: "PERMANENT",
        text: "PERMANENTE",
      },
      {
        value: "AVAILABLE",
        text: "DISPONIBLE",
      },
    ],
  },
  {
    label: "Compañía",
    name: "company",
    cssClasses: "col-6",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
  },
  {
    label: "Sucursal",
    name: "branch",
    cssClasses: "col-6",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Pertenece",
    name: "pertenece",
    cssClasses: "col-6",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [
      {
        value: "PROYECTOS",
        text: "PROYECTOS",
      },
      {
        value: "POLLO BRUJO",
        text: "POLLO BRUJO",
      },
      {
        value: "ISHOP",
        text: "ISHOP",
      },
    ],
  }
];
