import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import * as yup from "yup";
import queryString from "query-string";
import theme from "../../assets/theme";
import Map from "./Map";
import DynamicForm from "../Design/Form/DynamicForm";
import * as selectors from "../../logic/reducers";
import * as branchActions from "../../logic/actions/branches";
import * as pilotActions from "../../logic/actions/pilots";
import * as orderActions from "../../logic/actions/orders";
import isEmpty from "lodash/isEmpty";
import {
  DELIVERY_ICON,
  BIKER_ICON,
  INPUT_TYPE,
  MAP_FILTERS,
  BIKER_ALERTS_FILTERS,
} from "../../constants/constants";

const formFields = [
  {
    label: "Piloto",
    autofocus: true,
    name: "biker",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [],
  },
  {
    label: "Alerta",
    autofocus: true,
    name: "alertBiker",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [
      BIKER_ALERTS_FILTERS.NONE,
      BIKER_ALERTS_FILTERS.MECHANIC_FAILURE,
      BIKER_ALERTS_FILTERS.TIRE_FAIL,
      BIKER_ALERTS_FILTERS.TIRE_FAIL_2,
      BIKER_ALERTS_FILTERS.OUT_OF_GAS,
      BIKER_ALERTS_FILTERS.ACCIDENT,
      BIKER_ALERTS_FILTERS.THIEF,
      BIKER_ALERTS_FILTERS.LOST,
      BIKER_ALERTS_FILTERS.NO_PROBLEMS,
      BIKER_ALERTS_FILTERS.ALL_OK,
    ],
  },
];

const validationSchema = yup.object({});

const Maps = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const bikers = useSelector(selectors.getPilots);
  const orders = useSelector(selectors.getOrders);
  // eslint-disable-next-line no-unused-vars
  const [branchesMarkers, setBranchesMarkers] = useState([]);
  const [ordersMarkers, setOrdersMarkers] = useState([]);
  const [bikersMarkers, setBikersMarkers] = useState([]);
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [filteredBikersMarkers, setFilteredBikersMarkers] = useState([]);
  const [zoom, setZoom] = useState(12);
  const [center] = useState({ lat: 14.587461, lng: -90.514048 });
  const [filters, setFilters] = useState({
    biker: MAP_FILTERS.BIKER.ALL,
    alertBiker: BIKER_ALERTS_FILTERS.NONE,
  });
  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  useEffect(() => {
    const values = queryString.parse(search);
    const startedFilters = {
      branchId: values.sucursal,
    };
    dispatch(branchActions.clearBranchOffices());
    dispatch(orderActions.clearOrderSubscription());
    dispatch(pilotActions.clearPilotsSubscription());
    dispatch(branchActions.startFetchingBranchOffices(startedFilters));
    dispatch(pilotActions.startFetchingPilots(startedFilters));
    dispatch(orderActions.startFetchingOrders(startedFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarkerClick = (fn, id) => {
    fn((val) => {
      if (isEmpty(val)) {
        return val;
      }
      const markerIndex = val.findIndex((item) => item.id === id);
      if (markerIndex >= 0) {
        val[markerIndex] = {
          ...val[markerIndex],
          showInfoWindow: !val[markerIndex].showInfoWindow,
        };
      }
      return [...val];
    });
  };

  const applyBikerFilters = (bikerFilters, currentFilteredResults = []) => {
    let filteredResults = [...currentFilteredResults];
    switch (bikerFilters.value) {
      case MAP_FILTERS.BIKER.NONE.value: {
        filteredResults = [];
        break;
      }
      case MAP_FILTERS.BIKER.ALL.value: {
        filteredResults = [...bikersMarkers];
        break;
      }

      // new data filter
      case MAP_FILTERS.BIKER.PROJECT.value: {
        const filter = bikers.filter(
          (biker) => biker.Pertenece === "PROYECTOS"
        );

        filteredResults = bikersMarkers.filter((bikerMarker) =>
          filter.find((custom) => custom.id === bikerMarker.id)
        );
        break;
      }

      // new data filter
      case MAP_FILTERS.BIKER.POLLO_BRUJO.value: {
        const filter = bikers.filter(
          (biker) => biker.Pertenece === "POLLO BRUJO"
        );

        filteredResults = bikersMarkers.filter((bikerMarker) =>
          filter.find((custom) => custom.id === bikerMarker.id)
        );
        break;
      }

      // case MAP_FILTERS.BIKER.WITH_ORDERS.value: {
      //   if (!isEmpty(orders)) {
      //     const customFilters = bikers.filter(
      //       (biker) => biker.counters["En ruta"] > 0
      //     );
      //     filteredResults = bikersMarkers.filter((bikerMarker) =>
      //       customFilters.find((custom) => custom.id === bikerMarker.id)
      //     );
      //   }
      //   break;
      // }
      // case MAP_FILTERS.BIKER.WITHOUT_ORDERS.value: {
      //   if (!isEmpty(orders)) {
      //     const customFilters = bikers.filter(
      //       (biker) => biker.counters["En ruta"] === 0
      //     );
      //     filteredResults = bikersMarkers.filter((bikerMarker) =>
      //       customFilters.find((custom) => custom.id === bikerMarker.id)
      //     );
      //   }
      //   break;
      // }
      default: {
        filteredResults = [
          ...bikersMarkers.filter(
            (bikerMarker) => bikerMarker.id === bikerFilters.value
          ),
        ];
        break;
      }
    }
    return filteredResults;
  };

  const applyAlertBikerFilters = (
    alertBikerFilters,
    currentFilteredResults = []
  ) =>
    [...currentFilteredResults].filter(
      (result) =>
        !isEmpty(result.bikerAlert) &&
        result.bikerAlert.toUpperCase() === alertBikerFilters.value
    );

  const applyFilters = (currFilters) => {
    setFilteredBikersMarkers((currBikerMarkers) => {
      let filteredResults = [...currBikerMarkers];
      if (
        !isEmpty(currFilters.biker) &&
        currFilters.biker !== MAP_FILTERS.BIKER.NONE
      ) {
        filteredResults = applyBikerFilters(currFilters.biker, filteredResults);
      }
      if (
        !isEmpty(currFilters.alertBiker) &&
        currFilters.alertBiker !== BIKER_ALERTS_FILTERS.NONE
      ) {
        filteredResults = applyAlertBikerFilters(
          currFilters.alertBiker,
          filteredResults
        );
      }
      return filteredResults;
    });
  };

  useSubscribeOrders(orders, setOrdersMarkers, onMarkerClick);
  useSubscribeBikers(
    bikers,
    onMarkerClick,
    setBikersMarkers,
    setFilteredBikersMarkers,
    filters,
    applyFilters,
    setCustomFormFields
  );

  const onSubmit = (values) => {
    setFilters(values);
    applyFilters(values);
  };
  return (
    <ThemeProvider theme={theme}>
      {/* <NavBar title="mapa" /> */}

      <div className="col-3 vertical-top">
        <DynamicForm
          obj={filters}
          fields={customFormFields}
          schema={validationSchema}
          buttons={btnList}
          onSubmit={onSubmit}
          btnAlignment="right"
        />
      </div>
      <div className="col-9 vertical-top">
        <Map
          markers={[
            ...filteredBikersMarkers,
            ...ordersMarkers,
            ...branchesMarkers,
          ]}
          center={center}
          zoom={zoom}
          setZoom={setZoom}
        />
      </div>
    </ThemeProvider>
  );
};

const useSubscribeOrders = (orders, setOrdersMarkers, onMarkerClick) => {
  useEffect(() => {
    if (!isEmpty(orders)) {
      setOrdersMarkers(
        orders
          .filter((order) => !isEmpty(order.position))
          .map((order) => ({
            id: order.id,
            icon: DELIVERY_ICON,
            position: {
              latitude: order.locationRef.latitude,
              longitude: order.locationRef.longitude,
            },
            showInfoWindow: false,
            infoWindow: (
              <>
                <Typography variant="subtitle1">
                  {`ID ORDEN: ${order.orderId}`}
                </Typography>
                <Divider />
                <Typography variant="body">{`CLIENTE: ${order.name}`}</Typography>
                <Divider />
                <Typography variant="body">
                  {`AGENTE: ${order.alias.name}`}
                </Typography>
              </>
            ),
            onClick: () => {
              onMarkerClick(setOrdersMarkers, order.id);
            },
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);
};

const useSubscribeBikers = (
  bikers,
  onMarkerClick,
  setBikersMarkers,
  setFilteredBikersMarkers,
  filters,
  applyFilters,
  setCustomFormFields
) => {
  useEffect(() => {
    if (!isEmpty(bikers)) {
      const bikersMarkers = bikers.map((biker, i) => ({
        isClusterer: true,
        id: biker.id,
        icon: BIKER_ICON,
        bikerAlert: biker.alert,
        position: {
          latitude: biker.actualPosition.latitude,
          longitude: biker.actualPosition.longitude,
        },
        showInfoWindow: false,
        infoWindow: (
          <>
            <Typography variant="subtitle1">
              {`AGENTE: ${biker.name}`}
            </Typography>
            <Divider />
            <Typography variant="body">
              {`COMPAÑIA: ${biker.company}`}
            </Typography>
            <Divider />
            <Typography variant="body">
              {`SUCURSAL: ${biker.SUCURSAL}`}
            </Typography>
          </>
        ),
        onClick: () => {
          onMarkerClick(setFilteredBikersMarkers, biker.id);
        },
      }));
      const newBikerList = [
        MAP_FILTERS.BIKER.NONE,
        MAP_FILTERS.BIKER.ALL,
        MAP_FILTERS.BIKER.PROJECT,
        MAP_FILTERS.BIKER.POLLO_BRUJO,
        // MAP_FILTERS.BIKER.WITH_ORDERS,
        // MAP_FILTERS.BIKER.WITHOUT_ORDERS,
        ...bikers.map((biker) => ({
          value: biker.id,
          text: biker.name,
        })),
      ];
      setCustomFormFields((fields) => {
        let newFields = [...fields];
        const bikerFieldIndex = newFields.findIndex(
          (field) => field.name === "biker"
        );
        if (bikerFieldIndex > -1) {
          newFields[bikerFieldIndex].options = newBikerList;
        }
        return newFields;
      });
      setBikersMarkers(bikersMarkers);
      setFilteredBikersMarkers(bikersMarkers);
      applyFilters(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikers]);
};

export default Maps;
