/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import companyActions from "../../logic/actions/company";
import generatePassword from "generate-password";
import Grid from "@material-ui/core/Grid"
import { useSnackbar } from "notistack";
import { snackBarConst } from "../../constants/constants";
import generator from "generate-password";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © DELIVERY LAB '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();
const CreateUsers = ({
    image,
    loadingImage,
    creating,
    created,
    createdUser,
    createdImage,
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        dispatch(companyActions.getCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChangeUser = (event) => {

    };
    let password = ""

    if (!creating) {
        password = generator.generate({
            length: 10,
            numbers: true
        });
    }
    const schema = yup.object({
        name: yup.string().min(6).required("Ingrese nombre y apellido"),
        email: yup.string().email().required("Ingrese correo valido y existente en google"),
        "phone": yup.number().min(8).required("Ingrese numero de Telefono del piloto"),
        "transportId": yup.string().min(6).max(8).required("Ingrese Placa del Piloto"),
        "dpi": yup.string().min(12).required("Ingrese DPI del piloto"),
    });

    // send photo biker
    const onChangeFile = (e) => {
        createdImage(e.target.files[0]);
    }

    useEffect(() => {
        if (created !== null && created !== "Error when you try creating Bikers") {
            enqueueSnackbar("Usuario Encontrado", {
                variant: "success",
                preventDuplicate: true,
                anchorOrigin: {
                    ...snackBarConst,
                },
            });
        } else if (created === 'Error when you try creating Bikers') {
            enqueueSnackbar("Error al encontrar los usuarios", {
                variant: "warning",
                preventDuplicate: true,
                anchorOrigin: {
                    ...snackBarConst,
                },
            });
        };
    }, [generatePassword, creating]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#F88B1E' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        CREAR PILOTOS
                    </Typography>
                    <Typography variant='subtitle2'>
                        CREACIÓN DE PILOTOS APLICACIÓN
                    </Typography>
                    {
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Formik
                                initialValues={{
                                    email: "",
                                    name: "",
                                    "phone": "",
                                    "transportId": "",
                                    "dpi": ""
                                }}
                                onSubmit={(values, { resetForm }) => {
                                    let props = {
                                        ...values,
                                        password: password,
                                        pic: image,
                                    };
                                    createdUser(props);
                                    resetForm({ values: "" })
                                }}
                                validationSchema={schema}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                    values,
                                    touched,
                                }) => (
                                    <form>
                                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Nombre y Apellido"
                                                name="name"
                                                autoComplete="name"
                                                autoFocus
                                                value={values.name}
                                                error={errors.name && touched.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.name && touched.name) && errors.name}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                type="email"
                                                id="email"
                                                label="CORREO ELECTRONICO"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={values.email}
                                                error={errors.email && touched.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.email && touched.email) && errors.email}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                type="number"
                                                id="phone"
                                                label="NUMERO TELEFONO PILOTO"
                                                name="phone"
                                                autoComplete="phone"
                                                autoFocus
                                                value={values.phone}
                                                error={errors.phone && touched.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.phone && touched.phone) && errors.phone}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="transportId"
                                                label="PLACA MOTOCICLETA"
                                                name="transportId"
                                                autoComplete="transportId"
                                                autoFocus
                                                value={values.transportId}
                                                error={errors.transportId && touched.transportId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.transportId && touched.transportId) && errors.transportId}
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="transportId"
                                                label="DPI PILOTO"
                                                name="dpi"
                                                autoComplete="dpi"
                                                autoFocus
                                                value={values.dpi}
                                                error={errors.dpi && touched.dpi}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={(errors.dpi && touched.dpi) && errors.dpi}
                                            />
                                            <Typography style={{ textAlign: 'center', paddingBottom: '10px' }}> INGRESE IMAGEN DE PILOTO </Typography>
                                            <div style={{ border: '1px solid #D6D6D6' }}>
                                                <input
                                                    id="firstName"
                                                    label="Primer nombre"
                                                    name="firstName"
                                                    type="file"
                                                    accept="image/png"
                                                    onChange={onChangeFile}
                                                />
                                            </div>
                                            <div>
                                                {
                                                    loadingImage ? (
                                                        <div style={{ textAlign: 'center' }}> CREANDO IMAGEN ...</div>
                                                    ) : (
                                                        <Grid container direction="column" justify='center' alignContent='center'>
                                                            <Grid item xs={12} lg={12}>
                                                                <img src={image} alt="" width="300px" heigh="auto" />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                            </div>
                                            <div style={{ color: '#D6D6D6', padding: '20px' }}> Las contraseñas son genericas </div>
                                            <TextField
                                                label="CONTRASEÑA"
                                                id="password"
                                                name="password"
                                                fullWidth
                                                value={password}
                                                defaultValue={password}
                                                onChange={(e) => handleChangeUser(e)}
                                                disabled={true}
                                            />
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                disabled={image ? false : true}
                                            >
                                                Crear Piloto
                                            </Button>
                                            <div style={{ color: '#D6D6D6', padding: '20px' }}> La información del Piloto sera enviada al correo el cual se le fue asignado al piloto </div>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

export default connect(
    (state) => ({
        image: selectors.userImagePilotCreated(state),
        loadingImage: selectors.userImagePilotCreating(state),
        creating: selectors.userPilotCreating(state),
        created: selectors.userPilotCreated(state)
    }),
    (dispatch) => ({
        createdUser(info) {
            dispatch(actions.craetingPilot(info))
        },
        createdImage(image) {
            dispatch(actions.creatingImage(image))
        }
    })
)(CreateUsers);