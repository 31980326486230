import {CREATING_ISSUE_STARTED,CREATING_ISSUE_COMPLETED,CREATING_ISSUE_FAILED} from "../types/issues";
import {combineReducers} from "redux";
import {VIEW_STATE} from "../../constants/constants";
const postIssue = (state={
    loading:false,
    uploaded:false,
    error:false
}.NON,action)=>{
    switch (action.type) {
        case CREATING_ISSUE_STARTED:
            return {
                loading:true,
                uploaded:false,
                error:false
            };
        case CREATING_ISSUE_COMPLETED:
            return {
                loading:false,
                uploaded:true,
                error:false
            };
        case CREATING_ISSUE_FAILED:
            return VIEW_STATE.ERROR;
        default:
            return {
                loading:false,
                uploaded:false,
                error:true
            };
    }
}
const reducer = combineReducers({
    postIssue
})
export default reducer;