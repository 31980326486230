import React from "react";
import TextField from "@material-ui/core/TextField";
import isEmpty from "lodash/isEmpty";

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default ({ field, form, ...props }) => {
  const { touched, errors } = form;
  return (
    <div
      className={`text-field vertical-top ${props.fullWidth && "full-width"} ${
        props.cssClasses
      }`}
    >
      <TextField
        {...field}
        {...props}
        onChange={(e) => {
          if (!isEmpty(e) && !isEmpty(e.target) && e.target) {
            props.formikProps.setFieldValue(field.name, e.target.value);
            if (props.handleChange) {
              props.handleChange(e);
            }
            if (props.setState) {
              if (props.forceSetState) {
                props.setState((val) => {
                  if (!isEmpty(e.target)) {
                    return {
                      ...val,
                      [field.name]: e.target.value,
                    };
                  }
                  return {
                    ...val,
                  };
                });
              }
              debounce(() => {
                props.setState((val) => {
                  if (!isEmpty(e.target)) {
                    return {
                      ...val,
                      [field.name]: e.target.value,
                    };
                  }
                  return {
                    ...val,
                  };
                });
              }, 250);
            }
          }
        }}
        variant="outlined"
      />
      {touched && !isEmpty(errors) && !isEmpty(errors[field.name]) && (
        <div className="text-field-errors">
          {Object.keys(errors)
            .filter((val) => val === field.name)
            .map((err) => (
              <div key={err} className="text-field-error">
                {errors[err]}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
