import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import {
  getOrders,
  subscribeOrders,
  getOrderByOrderId,
  removeOrderByOrderId,
  subscribeGetOrdersByBikerId,
  getTrackingOrder,
  subscribeOrdersByMonth,
  isUpdateLink,
  getOrderLink,
} from "../../firebase/orders";
import { uploadImage, getImageUrl, removeImage } from "../../firebase/image";
import { getAuthUser } from "../../firebase/auth";
import orderService from "../services/orders";
import { FIRESTORAGE } from "../../constants/constants";
import firebaseHelper from "../../helpers/firebaseHelper";
import * as types from "../types/orders";
import * as actions from "../actions/orders";
import { isEmpty } from "lodash";
import ordersHelper from "../../helpers/ordersHelper";
import { linkOrderWithTime } from "../../helpers/ordersHelper";

// function saga fetchOrders
function* fetchOrders(action) {
  try {
    yield put(actions.setFetchOrdersLoadingStatus(true));
    const {
      payload: { filters },
    } = action;
    yield subscribeOrders(filters);
    yield put(actions.setFetchOrdersLoadingStatus(false));
  } catch (error) {
    console.log("ERROR", error);
    yield put(actions.failFetchingOrders(error));
  }
}

// getting orders wathc saga
export function* watchFetchOrders() {
  yield takeEvery(types.FETCH_ORDERS_STARTED, fetchOrders);
}

function* fetchMonthlyOrders({ payload }) {
  try {
    const { filters } = payload;
    yield call(subscribeOrdersByMonth, filters);
  } catch (error) {
    console.log("ERROR", error);
    yield put(actions.failFetchingOrders(error));
  }
}

export function* watchFetchMonthlyOrders() {
  yield takeEvery(types.FETCH_MONTHLY_ORDERS_STARTED, fetchMonthlyOrders);
}
function* editOrders(action) {
  try {
    const { prevOrder } = action.payload;
    const order = { ...action.payload.order };
    order.payMethod = order.payMethod.value;
    const orderId = order.id;
    delete order.id;
    delete order.afterCounterReference;
    delete order.beforeCounterReference;
    Object.keys(order).forEach((key) => {
      if (isEmpty(order[key])) {
        delete order[key];
      }
    });
    if (!isEmpty(order._createdAt)) {
      order._createdAt = new Date(order._createdAt).toISOString();
    }
    let notifyBiker = false;
    if (!isEmpty(prevOrder.alias) && !isEmpty(order.alias_id)) {
      notifyBiker = prevOrder.alias.id !== order.alias_id;
    }
    yield orderService.editOrder(orderId, order, notifyBiker);
    yield put(actions.completeEditingOrder());
  } catch (error) {
    console.log(error.response);
    yield put(actions.failEditingOrder(error.message));
  }
}

export function* watchEditOrders() {
  yield takeEvery(types.EDIT_ORDER_STARTED, editOrders);
}

function* fetchHistoryOrders(action) {
  try {
    const {
      payload: { statusFilter, branchId, companyId, allOrders, days },
    } = action;
    const response = yield getOrders(
      statusFilter,
      branchId,
      companyId,
      allOrders,
      days
    );
    if (response.error === null) {
      yield put(
        actions.completeFetchingHistoryOrders(
          response.orders.byId,
          response.orders.order
        )
      );
    } else {
      yield put(actions.failFetchingHistoryOrders(response.error));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.failFetchingHistoryOrders(error));
  }
}

export function* watchFetchHistoryOrders() {
  yield takeEvery(types.FETCH_HISTORY_ORDERS_STARTED, fetchHistoryOrders);
}

function* fetchOrderByOrderId(action) {
  try {
    const {
      payload: { orderId },
    } = action;
    yield getOrderByOrderId(orderId);
  } catch (error) {
    console.log("err", error);
    yield put(actions.orderActions.getOrderByOrderIdFailure(error.message));
  }
}

export function* watchFetchOrderByOrderId() {
  yield takeEvery(types.GET_ORDER_BY_ID, fetchOrderByOrderId);
}

function* fetchOrdersByBikerId(action) {
  try {
    const {
      payload: { bikerId },
    } = action;
    yield subscribeGetOrdersByBikerId(bikerId);
  } catch (error) {
    console.log(error);
    yield put(actions.failFetchingOrders(error));
  }
}

export function* watchFetchOrdersByBikerId() {
  yield takeEvery(types.SUBSCRIBE_ORDRES_BY_PILOT_ID, fetchOrdersByBikerId);
}

function* getOrdersWithTime(action) {
  const {
    payload: { biker, orders },
  } = action;
  try {
    const response = yield ordersHelper.getOrdersArrivalTime(biker, orders);
    if (response.length !== orders.length) {
      throw new Error("set orders manually");
    }
    yield put(actions.orderActions.getOrdersWithTimeByBikerIdSuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(actions.orderActions.getOrdersWithTimeByBikerIdSuccess(orders));
  }
}
export function* watchGetOrdersWithTime() {
  yield takeLatest(types.GET_ORDRES_WITH_TIME_BY_PILOT_ID, getOrdersWithTime);
}

function* deleteOrderByOrderId(action) {
  try {
    const {
      payload: { order },
    } = action;
    yield removeOrderByOrderId(order);
    yield put(actions.orderActions.deleteOrderSuccess());
  } catch (error) {
    console.log("err", error);
    yield put(actions.orderActions.deleteOrderFailure(error.message));
  }
}

export function* watchDeleteOrderByOrderId() {
  yield takeEvery(types.DELETE_ORDER, deleteOrderByOrderId);
}

function* fetchSingleOrder(action) {
  try {
    const id = action.payload.id;
    const response = yield getTrackingOrder(id);

    if (response.error === null) {
      yield put(actions.completeFetchingSingleOrder(response.order));
    } else {
      yield put(actions.failFetchingSingleOrder(response.error));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.failFetchingSingleOrder(error));
  }
}

export function* watchFetchSingleOrder() {
  yield takeEvery(types.FETCH_SINGLE_ORDER_STARTED, fetchSingleOrder);
}

function* createOrder(action) {
  let uploadedImage;
  try {
    const orderToCreate = {
      ...action.payload.order,
    };
    if (!isEmpty(orderToCreate.image)) {
      uploadedImage = yield uploadImage(
        FIRESTORAGE.ORDERS,
        orderToCreate.image[0]
      );
      const imageUrl = yield getImageUrl(uploadedImage);
      orderToCreate.orderPic = orderToCreate.receivedPic = imageUrl;
      delete orderToCreate.image;
    }
    // Remove null keys
    Object.keys(orderToCreate).forEach((key) => {
      if (isEmpty(orderToCreate[key])) {
        delete orderToCreate[key];
      }
    });
    if (!isEmpty(orderToCreate.isPriority)) {
      orderToCreate.isPriority =
        orderToCreate.isPriority === "Yes" ? true : false;
    }
    yield orderService.createOrder(orderToCreate);
    yield put(actions.orderActions.createOrderSuccess());
  } catch (error) {
    console.log(error);
    if (!isEmpty(uploadedImage)) {
      yield removeImage(uploadedImage);
    }
    yield put(
      actions.orderActions.createOrderFailure(
        "Ha ocurrido un error creando la orden"
      )
    );
  }
}

export function* watchCreateOrder() {
  yield takeEvery(types.CREATE_ORDER, createOrder);
}

function* editOrderUsersInForm(action) {
  try {
    const { orderId, clear, orderModifiyingById } = action.payload;
    const modifiyingBy = yield getAuthUser();
    if (isEmpty(modifiyingBy)) return;
    const date = new Date();
    // real-time-user: Adding 5 minutes
    date.setMinutes(date.getMinutes() + 5);
    let shouldModify = true;
    if (!isEmpty(orderModifiyingById)) {
      shouldModify = modifiyingBy.id === orderModifiyingById;
    }
    if (shouldModify) {
      yield orderService.editUsersInFormOrder(
        orderId,
        modifiyingBy.id,
        modifiyingBy.name,
        firebaseHelper.getFirebaseDate(date),
        clear
      );
    }
  } catch (error) {
    console.log(`Error editing users in form ${error}`);
  }
}

export function* watchEditOrderUsersInForm() {
  yield takeEvery(types.EDIT_ORDER_USERS_IN_FORM, editOrderUsersInForm);
}

function* singleOrderLink(action) {
  try {
    yield getOrderLink(action.payload.id);
  } catch (error) {
    yield put(actions.failedOrderLInk(error.message));
  }
}

export function* watchLinkSinlgeOrder() {
  yield takeEvery(types.GET_ORDER_LINK_STARTED, singleOrderLink);
}

function* updateOrderLink(action) {
  try {
    let Response = yield isUpdateLink(action.payload.id, action.payload.order);
    if (Response.error != null) {
      yield put(actions.updateLinkCompleted(Response.order));
    } else {
      yield put(actions.updateLinkError(Response.error));
    }
  } catch (error) {
    console.log("error");
  }
}

export function* watchLinkUpdateSigleOrder() {
  yield takeEvery(types.UPDATE_ORDER_LINK_STARTED, updateOrderLink);
}

export function* getLinkTimeBikerLink(action) {
  try {
    let response = yield linkOrderWithTime(action.payload.id);
    let {id,error} = response; 
    if(error === null){
      yield put(actions.linkGetOrderWhitTimerBikerCompleted(id[0]))
    }else{
      yield put(actions.linkGetOrderWhitTimerBikerFailed(error))
    }
  } catch (error) {
    console.log("error");
  }
}
export function* watchLinkOrderWhitTime() {
  yield takeEvery(types.GET_ORDER_WITH_TIME_STARTED, getLinkTimeBikerLink);
}

/* GET ALL ORDERS */
function* gettingAllOrders(){
  let response = yield orderService.gettingOrders();
  const {data, error} = response;
  if(!error){
      yield put(actions.getAllOrders(data))
  } else{
      yield put(actions.getAllOrdersFailed(error))
  }
}

export function* watchGettingOrdersSummary(){
  yield takeEvery(types.GET_ORDER_ALL_ORDER_STARTED, gettingAllOrders);
}
