import React, { useState } from "react";
import { BikerModel } from "../../models";
import {
  Card,
  makeStyles,
  Avatar,
  CardContent,
  Button,
} from "@material-ui/core";
import CommentBikerModal from "./CommentBikerModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 200,
    height: 160,
  },
  icon: {
    width: "auto",
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingRight: "5px",
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    position: "absolute",
    bottom: "5px",
    right: "5px",
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    position: "relative",
    // backgroundColor:theme.palette.secondary.main
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.light,
  },
}));
const BikerCard = ({ details = new BikerModel() }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card className={classes.root} variant="elevation">
        <div className={classes.icon}>
          <Avatar className={classes.large}>{details.name[0]}</Avatar>
        </div>
        <CardContent className={classes.content}>
          <p>{details.name}</p>
          <small>{details.phone}</small>
          <div className={classes.button}>
            {details.qualified === false ? (
              <Button color="primary" variant="text" onClick={handleOpen}>
                Comentar
              </Button>
            ) : (
              "Comentado"
            )}
          </div>
        </CardContent>
      </Card>
      <CommentBikerModal
        open={open}
        handleClose={handleClose}
        details={details}
      />
    </div>
  );
};
export default BikerCard;
