/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProcess from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getController } from "../../logic/reducers";
import { BIKER_TYPE } from "../../constants/constants";
import dateHelper from "../../helpers/dateHelper";
import Button from "@material-ui/core/Button";
import CreateOrderModal from "../Order/CreateOrderModalGeneral";
import TraslaterOrderModal from "../Order/createOrderTraslate";
import PickUpOrderModal from "../Order/createOrderPickup";
import { useSnackbar } from "notistack";
import AdminButton from "../Design/AdminButton";
import ModifyCompanyModal from "./ModifyCompanyModal";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import * as branchActions from "../../logic/actions/branches";
import companyActions from "../../logic/actions/company";
import DataTable from "../Design/DataTable";
import { Tabs, Tab, Paper, Box } from "@material-ui/core";
import CompanyTableOnRoute from "./CompanyTableonRoute.jsx";
import { orderStatus } from "../../constants/constants";
import * as actions from "../../logic/actions/orders";
import * as selectors from "../../logic/reducers";
import CompanyReports from "./CompanyReports";
import Modal from "../Design/Modal";
import OrderMap from "../Order/OrderMap";
import {
  getCompanies,
  getAreCompaniesLoading,
  isDeleteCompanyLoading,
  isDeleteDone,
  getDeleteError,
} from "../../logic/selectors/company";
import {
  getBranches,
  getAreBranchesLoading,
  isLoadingDeleteBranch,
  getErrorDeleteBranch,
  isDoneDeleteBranch,
} from "../../logic/selectors/branch";
import { getBikerList } from "../../logic/selectors/bikers";
import { pilotActions } from "../../logic/actions/pilots";
import { ROUTES, snackBarConst } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import StorefrontIcon from "@material-ui/icons/Storefront";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import CreateIcon from "@material-ui/icons/Create";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import { useParams } from "react-router-dom";
import CompanyTableOnDelivered from "./CompanyTableOnDelivered";
import CompanyTableOnStatusAll from "./CompanyTableOnStatusAll";
import CompanyAssistanceBiker from "./CompanyAssistanceBiker";
// import PilotAssistanceCompany from "../PilotAssistanceCompany";
import TrackingBikers from "./TrackingBikers.js";
import NewSchedule from "../PilotAssistanceCompany/NewSchedule.js";
import DashboardCircleByCompany from "../Circle/DashboardCircleByCompany.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanyBranch = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const controller = useSelector(getController);
  // Companies
  const isDeleting = useSelector(isDeleteCompanyLoading);
  const isDeletingDone = useSelector(isDeleteDone);
  const isDeletingError = useSelector(getDeleteError);
  //orders
  const orders = useSelector(selectors.getOrders);
  // Branches
  const isDeletingBranch = useSelector(isLoadingDeleteBranch);
  const isDeletingBranchDone = useSelector(isDoneDeleteBranch);
  const isDeletingBranchError = useSelector(getErrorDeleteBranch);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currOrder, setCurrOrder] = useState();
  const [mapModalOpen, setModalMapOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [nameModal, setNameModal] = useState("");
  const idParams = useParams();
  const bikers = useSelector(getBikerList);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  );
  // Branches
  const [branchToDelete, setBranchToDelete] = useState({});
  const [company, setCompany] = useState();
  // Company
  const companies = useSelector(getCompanies);
  const areCompaniesLoading = useSelector(getAreCompaniesLoading);
  // Branches
  const branches = useSelector(getBranches);
  const areBranchesLoading = useSelector(getAreBranchesLoading);

  const clearOnCompanyDelete = () => {
    dispatch(companyActions.clearAll());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(actions.clearOrderSubscription());
    dispatch(
      actions.startFetchingOrders({
        statusFilter: [
          orderStatus.GOING,
          orderStatus.ON_HOLD,
          orderStatus.PENDING,
          orderStatus.ACCEPTED,
          orderStatus.ASSIGNED,
          orderStatus.RECEIVING,
          orderStatus.CANCELLED,
          orderStatus.DELIVERED,
        ],
        companyId: params.companyId,
      })
    );
    dispatch(pilotActions.getPilots());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isEmpty(currOrder) && !isEmpty(orders)) {
      setCurrOrder(orders.find((order) => order.id === currOrder.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);
  useEffect(() => {}, [orders]);
  const clearOnBranchDelete = () => {
    dispatch(branchActions.branchActions.clearBranchStatuses());
    dispatch(
      branchActions.branchActions.getBranches({ companyId: params.companyId })
    );
  };
  const onMapModalClose = () => {
    setModalMapOpen(false);
  };
  useOnLoad(params, setIsLoading, dispatch);
  useOnDataLoaded(
    isLoading,
    setIsLoading,
    companies,
    setCompany,
    areCompaniesLoading,
    areBranchesLoading
  );
  useOnDelete(
    isDeletingDone,
    isDeletingError,
    isDeleting,
    enqueueSnackbar,
    true,
    history,
    "Compañía",
    "compañía",
    clearOnCompanyDelete
  );
  useOnDelete(
    isDeletingBranchDone,
    isDeletingBranchError,
    isDeletingBranch,
    enqueueSnackbar,
    false,
    history,
    "Sucursal",
    "sucursal",
    clearOnBranchDelete
  );

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  const onModifyCompany = () => {
    setIsModalOpen(true);
  };

  const onDeleteCompany = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onDelete = () => {
    setIsConfirmationDialogOpen(false);
    dispatch(companyActions.deleteCompany(company.id));
  };

  const onCancelDeletion = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onDeleteBranch = () => {
    dispatch(branchActions.branchActions.deleteBranch(branchToDelete.id));
    setBranchToDelete({});
  };

  const onCancelDeleteBranch = () => {
    setBranchToDelete({});
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setBranchToDelete({});
  };

  const onModalOpen = (value, name) => {
    setNameModal(name);
    setIsModalOpenCreate(value);
  };

  const onModalCloseCreate = () => {
    setIsModalOpenCreate(false);
  };

  const getHeaders = () => {
    let headers = [
      {
        id: "name",
        name: "Sucursal",
        style: {
          width: "70%",
        },
      },
      {
        id: "check",
        name: "",
        style: {
          width: "10%",
        },
      },
    ];
    if (isEmpty(controller.companyId) && isEmpty(controller.branchId)) {
      headers = [
        ...headers,
        {
          id: "modify",
          name: "",
          style: {
            width: "10%",
          },
        },
        {
          id: "delete",
          name: "",
          style: {
            width: "10%",
          },
        },
      ];
    }
    return headers;
  };

  const getRowDelivered = () => {
    const onGoindOrders = orders
      .filter((order) => order.status === orderStatus.DELIVERED)
      .map((order) => {
        let bikerPhone = "";
        const bikerByOrder = getBikerByOrder(order);
        let totalDeliveredTime = "";
        let deliveredTime = "";
        if (!isEmpty(bikerByOrder)) {
          bikerPhone = bikerByOrder.phone || "";
        }
        if (!isEmpty(order.stateTime)) {
          if (
            !isEmpty(order.stateTime["Entregado"]) &&
            !isEmpty(order.stateTime["Entregado"]).seconds &&
            !isEmpty(order.createdAt) &&
            !isEmpty(order.createdAt).seconds
          ) {
            deliveredTime = moment(
              new Date(order.stateTime["Entregado"].seconds * 1000)
            ).format("HH:mm:ss");
            if (
              !isEmpty(order.stateTime["En ruta"]) &&
              !isEmpty(order.stateTime["En ruta"]).seconds
            ) {
              totalDeliveredTime = dateHelper.secondsToHms(
                order.stateTime["Entregado"].seconds -
                  order.stateTime["En ruta"].seconds
              );
            }
          }
        }
        return { ...order, bikerPhone, totalDeliveredTime, deliveredTime };
      });
    return onGoindOrders;
  };

  const getRows = () => {
    const rows = orders
      .filter(
        (order) =>
          order.status !== orderStatus.CANCELLED &&
          order.status !== orderStatus.DELIVERED
      )
      .map((order) => {
        let bikerPhone = "";
        let transportId = "";
        const getBikerTranport = getBikerByOrder(order);
        if (!isEmpty(getBikerTranport)) {
          transportId = getBikerTranport.transportId;
        }
        const bikerByOrder = getBikerByOrder(order);
        if (!isEmpty(bikerByOrder)) {
          bikerPhone = bikerByOrder.phone || "";
        }
        return {
          ...order,
          bikerPhone,
          transportId,
          createdDate: !isEmpty(order.createdAt)
            ? `${order.createdAt.seconds}`
            : "",
        };
      });
    let returnRows = rows.filter((el) => el.company.id === idParams.companyId);
    return returnRows;
  };

  const getRowsAllOrders = () => {
    const rows = orders
      .filter((order) => order.status !== orderStatus.CANCELLED)
      .map((order) => {
        let bikerPhone = "";
        let transportId = "";
        const getBikerTranport = getBikerByOrder(order);
        if (!isEmpty(getBikerTranport)) {
          transportId = getBikerTranport.transportId;
        }
        const bikerByOrder = getBikerByOrder(order);
        if (!isEmpty(bikerByOrder)) {
          bikerPhone = bikerByOrder.phone || "";
        }
        return {
          ...order,
          bikerPhone,
          transportId,
          createdDate: !isEmpty(order.createdAt)
            ? `${order.createdAt.seconds}`
            : "",
        };
      });
    let returnRows = rows.filter((el) => el.company.id === idParams.companyId);
    return returnRows;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const iconsFormAdmin = [
    {
      icon: <StorefrontIcon />,
      name: "CREAR SUCURSAL",
      history: `${ROUTES.BRANCH.CREATE}/${company?.id}`,
    },
    {
      icon: <CreateIcon />,
      name: "CREAR ORDEN",
      modalView: true,
    },
    {
      icon: <CompareArrowsIcon />,
      name: "TRASLADO A TIENDAS",
      modalView: true,
    },
    {
      icon: <MarkunreadMailboxIcon />,
      name: "PICK-UP",
      modalView: true,
    },
  ];

  const iconsFormUser = [
    {
      icon: <CreateIcon />,
      name: "CREAR ORDEN",
      modalView: true,
    },
    {
      icon: <CompareArrowsIcon />,
      name: "TRASLADO A TIENDAS",
      modalView: true,
    },
    {
      icon: <MarkunreadMailboxIcon />,
      name: "PICK-UP",
      modalView: true,
    },
  ];

  const renderView = () => {
    return (
      <div className="page-container">
        <div className="container-body">
          {isConfirmationDialogOpen && (
            <ConfirmationDialog
              text={
                <div>
                  {"Está seguro de querer eliminar la compañía?"}
                  <br />
                  {
                    "Esto eliminará todas las sucursales/ordenes que pertenezcan a la compañía"
                  }
                </div>
              }
              onCancel={onCancelDeletion}
              onConfirm={onDelete}
            />
          )}
          {!isEmpty(branchToDelete) && (
            <ConfirmationDialog
              text={
                <div>
                  {`Está seguro de querer eliminar la sucursal ${branchToDelete.name}?`}
                  <br />
                  {
                    "Esto eliminará todas las ordenes que pertenezcan a la sucursal"
                  }
                </div>
              }
              onCancel={onCancelDeleteBranch}
              onConfirm={onDeleteBranch}
            />
          )}
          {isModalOpen && (
            <Modal
              isOpened={isModalOpen}
              onClose={onModalClose}
              body={
                <ModifyCompanyModal
                  onModalClose={onModalClose}
                  company={company}
                />
              }
            />
          )}
          <div className="margin-bottom-lg">
            <div className="display-inline-block">
              <Button
                size="small"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={() => history.push(`${ROUTES.COMPANY}`)}
              >
                Regresar
              </Button>
            </div>
            <div className="display-inline-block margin-left-sm">
              <AdminButton>
                <Button
                  size="small"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={onModifyCompany}
                >
                  Modificar
                </Button>
              </AdminButton>
            </div>
            <div className="display-inline-block margin-left-sm">
              <AdminButton>
                <Button
                  size="small"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={onDeleteCompany}
                >
                  Eliminar
                </Button>
              </AdminButton>
            </div>
          </div>
          {!isEmpty(company.logo) && (
            <div className="col-3 company-branch-logo">
              <img src={company.logo} alt="companyLogo" />
            </div>
          )}
          <div className="col-9 vertical-top">
            <DataTable
              headers={getHeaders()}
              rows={(branches || []).map((branch) => ({
                ...branch,
                name: branch.name.toUpperCase(),
                check: (
                  <div className="company-branches-links">
                    <div
                      onClick={() =>
                        history.push(`${ROUTES.BRANCH_RESUME}/${branch.id}`)
                      }
                    >
                      VER
                    </div>
                  </div>
                ),
                modify: (
                  <div className="company-branches-links">
                    <div
                      onClick={() =>
                        history.push(
                          `${ROUTES.BRANCH.MODIFY}/${company.id}/${branch.id}`
                        )
                      }
                    >
                      MODIFICAR
                    </div>
                  </div>
                ),
                delete: (
                  <div className="company-branches-links">
                    <div onClick={() => setBranchToDelete(branch)}>
                      ELIMINAR
                    </div>
                  </div>
                ),
              }))}
            />
          </div>
        </div>
        <div className="footer">
          <div className="floating-action-button">
            {isEmpty(controller.companyId) && isEmpty(controller.branchId) && (
              <SpeedDial
                ariaLabel="SpeedDial example"
                // className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {iconsFormAdmin.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={
                      (handleClose,
                      () => {
                        action.modalView === true
                          ? onModalOpen(action.modalView, action.name)
                          : history.push(action.history);
                      })
                    }
                  />
                ))}
              </SpeedDial>
            )}
            {!isEmpty(controller.companyId) &&
              isEmpty(controller.branchId) &&
              controller.role === "ADMINISTRATOR" && (
                <SpeedDial
                  ariaLabel="SpeedDial example"
                  icon={<SpeedDialIcon />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {iconsFormUser.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={
                        (handleClose,
                        () => {
                          onModalOpen(action.modalView, action.name);
                        })
                      }
                    />
                  ))}
                </SpeedDial>
              )}
            <div className="container-body">
              {isModalOpenCreate && nameModal === "CREAR ORDEN" && (
                <Modal
                  isOpened={isModalOpenCreate}
                  onClose={onModalCloseCreate}
                  body={
                    <CreateOrderModal
                      onModalClose={onModalCloseCreate}
                      branchData={branches}
                    />
                  }
                />
              )}
              {isModalOpenCreate && nameModal === "TRASLADO A TIENDAS" && (
                <Modal
                  isOpened={isModalOpenCreate}
                  onClose={onModalCloseCreate}
                  body={
                    <TraslaterOrderModal
                      onModalClose={onModalCloseCreate}
                      branchData={branches}
                    />
                  }
                />
              )}
              {isModalOpenCreate && nameModal === "PICK-UP" && (
                <Modal
                  isOpened={isModalOpenCreate}
                  onClose={onModalCloseCreate}
                  body={
                    <PickUpOrderModal
                      onModalClose={onModalCloseCreate}
                      branchData={branches}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBikerByOrder = (order) => {
    if (isEmpty(bikers)) return "";
    if (isEmpty(order.alias)) return "";
    if (isEmpty(order.alias.id)) return "";
    return bikers.find((biker) => biker.id === order.alias.id);
  };

  const getCurrOrder = () => {
    if (!isEmpty(currOrder) && !isEmpty(currOrder.alias)) {
      let currBiker = bikers.filter((biker) => biker.id === currOrder.alias.id);
      if (currBiker.length === 0) return currOrder;
      return {
        ...currOrder,
        alias: {
          ...currOrder.alias,
          actualPosition: currBiker[0].actualPosition,
        },
      };
    }
    return currOrder;
  };

  const currOrderWithLocation = getCurrOrder();
  return (
    <div title={!isEmpty(company) ? company.name : "Compañía"}>
      {mapModalOpen && !isEmpty(currOrderWithLocation) && (
        <Modal
          isOpened={mapModalOpen}
          onClose={onMapModalClose}
          body={
            <OrderMap
              onModalClose={onMapModalClose}
              order={currOrderWithLocation}
              branch={null}
            />
          }
        />
      )}
      {isLoading || isEmpty(company) ? (
        renderLoading()
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="EMPRESA" {...a11yProps(0)} />
          <Tab label="EN RUTA" {...a11yProps(1)} />
          <Tab label="ENTREGADAS" {...a11yProps(2)} />
          <Tab label="REPORTE DE ORDENES" {...a11yProps(3)} />
          <Tab label="ESTATUS DE ORDENES" {...a11yProps(4)} />
          <Tab label="CIRCULO" {...a11yProps(5)} />
          {company?.assistance === true ? (
            <Tab label="ASISTENCIA DIARIA" {...a11yProps(6)} />
          ) : (
            ""
          )}
          {company?.assistance === true ? (
            <Tab label="ASISTENCIA MES" {...a11yProps(7)} />
          ) : (
            ""
          )}
          {company?.assistance === true ? (
            <Tab label="RASTREO DE MOTORISTAS" {...a11yProps(8)} />
          ) : (
            ""
          )}
        </Tabs>
      )}
      <br></br>
      <TabPanel value={value} index={0}>
        {isLoading || isEmpty(company) ? renderLoading() : renderView()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper elevation={0}>
          <CompanyTableOnRoute
            rows={getRows()}
            setCurrOrder={setCurrOrder}
            setModalMapOpen={setModalMapOpen}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CompanyTableOnDelivered rows={getRowDelivered()} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Paper elevation={0}>
          <Box padding={2}>
            <CompanyReports
              bikers={(bikers || []).filter(
                (biker) => biker.type === BIKER_TYPE.PERMANENT
              )}
              companyId={params.companyId}
            />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CompanyTableOnStatusAll getRowsAllOrdersdev={getRowsAllOrders()} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DashboardCircleByCompany companyId={params.companyId} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        {/* <PilotAssistanceCompany general={true} /> */}
        <NewSchedule companyId={params.companyId} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <CompanyAssistanceBiker />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <TrackingBikers companyId={params.companyId} />
      </TabPanel>
    </div>
  );
};

const useOnDataLoaded = (
  isLoading,
  setIsLoading,
  companies,
  setCompany,
  areCompaniesLoading,
  areBranchesLoading
) => {
  useEffect(() => {
    if (
      isLoading &&
      !areCompaniesLoading &&
      !areBranchesLoading &&
      !isEmpty(companies)
    ) {
      setCompany(companies[0]);
      setIsLoading(false);
    }
  }, [isLoading, companies, areCompaniesLoading, areBranchesLoading]);
};

const useOnLoad = (params, setIsLoading, dispatch) => {
  useEffect(() => {
    if (!isEmpty(params.companyId)) {
      dispatch(companyActions.clearCompanies());
      dispatch(branchActions.branchActions.clearBranches());
      dispatch(companyActions.getCompanies({ companyId: params.companyId }));
      dispatch(
        branchActions.branchActions.getBranches({ companyId: params.companyId })
      );
      setIsLoading(true);
    }
  }, []);
};

const useOnDelete = (
  isDeleted,
  error,
  isLoading,
  enqueueSnackbar,
  useHistory,
  history,
  deletedText,
  errorText,
  clearFn
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isDeleted) {
        enqueueSnackbar(`${deletedText} eliminada correctamente`, {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        clearFn();
        if (useHistory) history.push(ROUTES.COMPANY);
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          `Hubo un error al eliminar la ${errorText}, por favor, intenta más tarde`,
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [isDeleted, error, isLoading]);
};

export default CompanyBranch;
