import axios from "axios";
import { API_URL } from "../../constants/constants";

const getTagCommentsByCompanyId = async (companyId, limit = 9999) => {
  const response = await axios.get(
    `${API_URL}comments/tag-comments?companyId=${companyId}&limit=${limit}`
  );
  return response.data.items;
};

const tagCommentsService = {
  getTagCommentsByCompanyId,
};

export default tagCommentsService;
