export const UPDATE_INFO_CONTROLLER_STARTED = "UPDATE_INFO_CONTROLLER_STARTED";
export const UPDATE_INFO_CONTROLLER_COMPLETED = "UPDATE_INFO_CONTROLLER_COMPLETED";
export const UPDATE_INFO_CONTROLLER_FAILED = "UPDATE_INFO_CONTROLLER_FAILED";


// udpate password pilot 

export const UPDATE_PASSWORD_PILOT_STARTED = "UPDATE_PASSWORD_PILOT_STARTED";
export const UPDATE_PASSWORD_PILOT_COMPLETED = "UPDATE_PASSWORD_PILOT_COMPLETED";
export const UPDATE_PASSWORD_PILOT_FAILED =  "UPDATE_PASSWORD_PILOT_FAILED";
 

// update password controller

export const UPDATE_PASSWORD_CONTROLLER_STARTED = "UPDATE_PASSWORD_CONTROLLER_STARTED" 
export const UPDATE_PASSWORD_CONTROLLER_COMPLETED = "UPDATE_PASSWORD_CONTROLLER_COMPLETED" 
export const UPDATE_PASSWORD_CONTROLLER_FAILED = "UPDATE_PASSWORD_CONTROLLER_FAILED"

// create user pilot

export const CREATE_USER_PILOT_STARTED = "CREATE_USER_PILOT_STARTED";
export const CREATE_USER_PILOT_COMPLETED = "CREATE_USER_PILOT_COMPLETED";
export const CREATE_USER_PILOT_FAILED = "CREATE_USER_PILOT_FAILED";

// find email auth bikers or controller 

export const FIND_EMAIL_AUTH_STARTED = "FIND_EMAIL_AUTH_STARTED";
export const FIND_EMAIL_AUTH_COMPLETED = "FIND_EMAIL_AUTH_COMPLETED";
export const FIND_EMAIL_AUTH_FAILED = "FIND_EMAIL_AUTH_FAILED";

export const CREATED_IMAGE_STARTED ="CREATED_IMAGE_STARTED"
export const CREATED_IMAGE_COMPLETED = "CREATED_IMAGE_COMPLETED"
export const CREATED_IMAGE_FAILED = "CREATED_IMAGE_FAILED"

export const GETTING_ORDERS_STARTED = "GETTING_ORDERS_STARTED";
export const GETTING_ORDERS_COMPLETED = "GETTING_ORDERS_COMPLETED";
export const GETTING_ORDERS_FAILED = "GETTING_ORDERS_FAILED";

export const GETTING_USER_ACCOUNT_STARTED = 'GETTING_USER_ACCOUNT_STARTED';
export const GETTING_USER_ACCOUNT_COMPLETED = 'GETTING_USER_ACCOUNT_COMPLETED';
export const GETTING_USER_ACCOUNT_FAILED = 'GETTING_USER_ACCOUNT_FAILED';

/* get sms information  */
export const GETTING_SMS_STARTED = 'GETTING_SMS_STARTED';
export const GETTING_SMS_COMPLETED = 'GETTING_SMS_COMPLETED';
export const GETTING_SMS_FAILED = 'GETTING_SMS_FAILED';

/* get information deliveries biker */
export const GETTING_SUMMARY_DELIVERIES_BIKER_STARTED = 'GETTING_SUMMARY_DELIVERIES_BIKER_STARTED';
export const GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED = 'GETTING_SUMMARY_DELIVERIES_BIKER_COMPLETED';
export const GETTING_SUMMARY_DELIVERIES_BIKER_FAILED = 'GETTING_SUMMARY_DELIVERIES_BIKER_FAILED';

/* get information orders */
export const GETTING_SUMMARY_ORDERS_STARTED = 'GETTING_SUMMARY_ORDERS_STARTED';
export const GETTING_SUMMARY_ORDERS_COMPLETED = 'GETTING_SUMMARY_ORDERS_COMPLETED';
export const GETTING_SUMMARY_ORDERS_FAILED = 'GETTING_SUMMARY_ORDERS_FAILED';

/* get information gmails */
export const GETTING_SUMMARY_GMAILS_STARTED = 'GETTING_SUMMARY_GMAILS_STARTED';
export const GETTING_SUMMARY_GMAILS_COMPLETED = 'GETTING_SUMMARY_GMAILS_COMPLETED';
export const GETTING_SUMMARY_GMAILS_FAILED = 'GETTING_SUMMARY_GMAILS_FAILED';