// CANCELLED ORDERS FILTERS
export const CANCELLED_ORDERS_ON_SAVE_FILTERS =
  "CANCELLED_ORDERS_ON_SAVE_FILTERS";
export const CANCELLED_ORDERS_ON_REMOVE_FILTERS =
  "CANCELLED_ORDERS_ON_REMOVE_FILTERS";

// ABANDONED ORDERS FILTERS
export const ABANDONED_ORDERS_ON_SAVE_FILTERS =
  "ABANDONED_ORDERS_ON_SAVE_FILTERS";
export const ABANDONED_ORDERS_ON_REMOVE_FILTERS =
  "ABANDONED_ORDERS_ON_REMOVE_FILTERS";

// DELIVERED ORDERS FILTERS
export const DELIVERED_ORDERS_ON_SAVE_FILTERS =
  "DELIVERED_ORDERS_ON_SAVE_FILTERS";
export const DELIVERED_ORDERS_ON_REMOVE_FILTERS =
  "DELIVERED_ORDERS_ON_REMOVE_FILTERS";

// IN_ROUTE ORDERS FILTERS
export const IN_ROUTE_ORDERS_ON_SAVE_FILTERS =
  "IN_ROUTE_ORDERS_ON_SAVE_FILTERS";
export const IN_ROUTE_ORDERS_ON_REMOVE_FILTERS =
  "IN_ROUTE_ORDERS_ON_REMOVE_FILTERS";

// DELETED ORDERS FILTERS
export const DELETED_ORDERS_ON_SAVE_FILTERS = "DELETED_ORDERS_ON_SAVE_FILTERS";
export const DELETED_ORDERS_ON_REMOVE_FILTERS =
  "DELETED_ORDERS_ON_REMOVE_FILTERS";

  // RE_AGEND ORDERS FILTERS
export const RE_AGEND_ORDERS_ON_SAVE_FILTERS = "RE_AGEND_ORDERS_ON_SAVE_FILTERS";
export const RE_AGEND_ORDERS_ON_REMOVE_FILTERS =
  "RE_AGEND_ORDERS_ON_REMOVE_FILTERS";
