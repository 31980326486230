import React from "react";
import Expandable from "../Design/Expandable";
import Card from "../Design/Card";
import { isEmpty } from "lodash";

const getRandomColor = () => {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const TopCircle = ({ orders, top = 10, useExpandable = true }) => {
  const getCircle = (id) => ({
    id,
    name: `${id}`,
    quantity: 0,
  });

  const renderTop = () => {
    let circles = [getCircle(1), getCircle(2), getCircle(3), getCircle(4)];
    orders.forEach((order) => {
      if (!isEmpty(order) && !isEmpty(order.circle)) {
        const circleIndex = circles.findIndex(
          (circle) => circle.id === order.circle.id
        );
        if (circleIndex > -1) {
          circles[circleIndex] = {
            ...circles[circleIndex],
            quantity: circles[circleIndex].quantity + 1,
          };
        } else {
          circles = [
            ...circles,
            {
              id: order.circle.id,
              name: `${order.circle.id}`,
              quantity: 1,
            },
          ];
        }
      }
    });
    return circles.map((circle, i) => (
      <div
        className="card-container card-container-5 no-border-bottom"
        style={{ background: getRandomColor() }}
        key={circle.id}
      >
        <Card
          bodyCssClases="sumary-body text-center"
          titleCssClases="text-center padding-top-sm padding-bottom-sm"
          cssClases="no-padding"
          noPadding
          title={
            <div className="uppercase margin-bottom-md bold">{circle.name}</div>
          }
          body={`Ordenes Entregadas: ${circle.quantity}`}
        />
      </div>
    ));
  };

  const renderView = () => {
    if (useExpandable) {
      return <Expandable title={`Circulos`}>{renderTop()}</Expandable>;
    }
    return renderTop();
  };

  return <>{renderView()}</>;
};

export default TopCircle;
