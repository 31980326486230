/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import * as yup from "yup";

import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import moment from "moment";

import { getController } from "../../logic/reducers";
import { INPUT_TYPE } from "../../constants/constants";

import CircularProgress from "@material-ui/core/CircularProgress";

import DynamicForm from "../Design/Form/DynamicForm";
import isEmpty from "lodash/isEmpty";

import BreadCrumb from "../../containers/navs/breadcrumb";

import CircularProcess from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  Export,
  FilterPanel,
  TotalItem,
  Summary,
  Selection,
  HeaderFilter,
  FilterRow,
  Scrolling
} from "devextreme-react/data-grid";

const formFields = [
  {
    label: "FECHA DE INICIO",
    autofocus: true,
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "FECHA FINAL",
    autofocus: true,
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
];

const validationSchema = yup.object({});

const SmsQty = ({ init, sms, failed, gettingSMSReport }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filters, setFilters] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [filterError, setFilterError] = useState("");
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  const pageSizes = [10, 25, 50, 100];

  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  useEffect(() => {
    //console.log(`sms: ${sms}`);
  }, [sms]);

  const applyFilters = (currFilters) => {
    // console.log(`currFilters: ${JSON.stringify(currFilters)}`);
    setRows(currFilters);
  };

  const onSubmit = async (values) => {
    setFilterError("");
    if (!isEmpty(values.startDate) && !isEmpty(values.endDate)) {
      const startDate = new Date(`${filters.startDate} 00:00:00`).getTime();
      const endDate = new Date(`${filters.endDate} 23:59:59`).getTime();
      gettingSMSReport(startDate, endDate);

      if (startDate > endDate) {
        setFilterError("Fecha de inicio debe ser antes que la fecha final");
        return;
      }
    }
    applyFilters(values);
  };

  const renderView = () => {
    if (hasError) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }

    const newDateFormat = ({ data }) => {
      // console.log(data.data)
      const dateFormNew = moment(data.data.createAtSms.seconds * 1000).format(
        "L"
      );
      return (
        <>
          <div>{dateFormNew}</div>
        </>
      );
    };

    /* logica para calcular cantidad */
    let dictonary = {};

    if (sms) {
      sms.forEach((e) => {
        if (dictonary[e.data.company?.name]) {
          dictonary[e.data.company?.name] += 1;
        } else {
          dictonary[e.data.company?.name] = 1;
        }
      });
    }

    const dict = Object.entries(dictonary).map(([k, v]) => {
      return {
        name: k,
        value: v,
      };
    });

    return (
      <>
        {!isEmpty(filterError) && (
          <>
            <Alert severity="error">{filterError}</Alert>
            <br />
          </>
        )}
        <div>
          <BreadCrumb match={{ path: "/" }} title="DASHBOARD SMS" />
        </div>
        <div style={{ padding: "30px 30px" }}>
          <DynamicForm
            obj={filters}
            setState={setFilters}
            fields={customFormFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            forceSetState
          />
        </div>
        {/* <ResponsiveContainer width="100%" height="30%"> */}
          <BarChart
            width={1000}
            height={400}
            data={dict}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" fontSize={"10px"} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="rgb(31, 68, 161)" />
          </BarChart>
        {/* </ResponsiveContainer> */}
        {init ? (
          <CircularProgress disableShrink />
        ) : (
          <>
            <DataGrid
              keyExpr="name"
              dataSource={dict}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              noDataText="NO HAY INFORMACIÓN"
            >
              <FilterRow visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <HeaderFilter visible={true} />
              <Grouping autoExpandAll={false} />
              <Pager
                showPageSizeSelector={true}
                displayMode={true}
                allowedPageSizes={pageSizes}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Paging defaultPageSize={10} />
              <Summary recalculateWhileEditing={true}>
                <TotalItem
                  column="name"
                  displayFormat="ORDENES: {0}"
                  summaryType="count"
                />
              </Summary>
              <FilterPanel
                visible={true}
                texts={{
                  createFilter: "CREAR FILTRO",
                  clearFilter: "ELIMINAR FILTRO",
                  filterEnabledHint: "CREACIÓN DE FILTROS",
                }}
              />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dashboardSms"
              />
              <Selection mode="single" />
              <Column dataField="name" caption="Compania" />
              <Column dataField="value" caption="Cantidad" />
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

              <Export enabled={true} allowExportSelectedData={true} />
            </DataGrid>
          </>
        )}
      </>
    );
  };

  return (
    <div title="Reporte" cssClass="page-container">
      <div className="container-body">
        {isLoading ? <CircularProcess color="secondary" /> : renderView()}
      </div>
    </div>
  );
};

const { gettingSMS, getSMS, gettingSMSFail } = selectors;

export default connect(
  (state) => ({
    init: gettingSMS(state),
    sms: getSMS(state),
    failed: gettingSMSFail(state),
  }),
  (dispatch) => ({
    gettingSMSReport(startDate, endDate) {
      dispatch(actions.gettingSms(startDate, endDate));
    },
  })
)(SmsQty);
