import React from "react";
import { OrderItem } from "../../containers/items";
import { List } from "@material-ui/core";

const ResponsiveOrderList = ({ orders = [], onClick }) => {
  return (
    <List>
      {orders.map((element) => {
        return <OrderItem details={element} onClick={onClick} />;
      })}
    </List>
  );
};
export default ResponsiveOrderList;
