export const getCancelledOrdersFilters = (state) =>
  state.filters.canceledOrders.filters || [];
export const getAbandonedOrdersFilters = (state) =>
  state.filters.abandonedOrders.filters || [];
export const getDeliveredOrdersFilters = (state) =>
  state.filters.deliveredOrders.filters || [];
export const getInRouteOrdersFilters = (state) =>
  state.filters.inRouteOrders.filters || [];
export const getDeletedOrdersFilters = (state) =>
  state.filters.deletedOrders.filters || [];
export const getReAgendOrdersFilters = (state) =>
  state.filters.reAgendOrders.filters || [];
