/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getBikers } from "../../firebase/orders";
import { BIKER_ICON } from "../../constants/constants";
import Map from "../Map/Map";

const SingleBikerLocation = ({ match: { params } }) => {
  const [bikerData, setBikerData] = useState([]);

  const getBikersById = async () => {
    const bikersCollection = await getBikers();
    const bikersSnapshot = await bikersCollection.get();
    let dataBikers = [];
    bikersSnapshot.forEach((biker) => {
      dataBikers.push({
        ...biker.data(),
        id: biker.id,
      });
    });
    return dataBikers;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pilotData = await getBikersById();
        const selectedBiker = pilotData.find(
          (biker) => biker.id === params.bikerId
        );
        setBikerData(selectedBiker);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  return (
      <Map
        markers={[
          {
            id: bikerData.id,
            icon: BIKER_ICON,
            position: {
              latitude: bikerData.actualPosition?._lat,
              longitude: bikerData.actualPosition?._long,
            },
            showInfoWindow: false,
          },
        ]}
        center={{
          lat: bikerData.actualPosition?._lat,
          lng: bikerData.actualPosition?._long,
        }}
        zoom={14}
      />
  );
};

export default SingleBikerLocation;
