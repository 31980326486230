import React from "react";
import {
  makeStyles,
  List,
  Typography,
  ListItem,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import ListTileItem from "./ListTileItem";
import PropTypes from "prop-types";
import { deepOrange } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  leadingProps: {
    width: 30,
    fontSize: 15,
    fontWeight: "w500",
    color: "#565656",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
  },
  titles: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
}));
const TrailingComponent = ({ going, delivered, canceled }) => {
  const classes = useStyles();
  return (
    <div className={classes.titles}>
      <p style={{ width: 80, color: "#565656" }}>{going}</p>
      <p style={{ width: 80, color: "red" }}>{canceled}</p>
      <p style={{ width: 80, color: "green" }}>{delivered}</p>
    </div>
  );
};
const RenderTitle = () => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText secondary={"Pilotos"} />
      <div className={classes.titles}>
        <ListItemText secondary={"En ruta"} style={{ width: 80 }} />
        <ListItemText secondary={"Canceladas"} style={{ width: 80 }} />
        <ListItemText secondary={"Entregadas"} style={{ width: 80 }} />
      </div>
    </ListItem>
  );
};
const LeadingComponent = ({ content }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.leadingProps} variant="body1" component="h6">
      {content}
    </Typography>
  );
};
const TopBikersList = (props) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <List className={classes.root}>
      <RenderTitle />
      {(data || []).map((element, index) => (
        <ListTileItem
          key={index}
          going={element.going > 0}
          title={element.name}
          Icon={
            <Avatar className={classes.orange}>
              {element.name[0].toUpperCase()}
            </Avatar>
          }
          Leading={<LeadingComponent content={index + 1} />}
          Trailing={
            <TrailingComponent
              going={element.going}
              delivered={element.delivered}
              canceled={element.cancelled}
              pos={index}
            />
          }
        />
      ))}
    </List>
  );
};
TopBikersList.propTypes = {
  /**Array of data that will be displayed */
  data: PropTypes.array.isRequired,
};
TopBikersList.defaultProps = {
  data: [],
};
export default TopBikersList;
