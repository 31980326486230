/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import queryString from "query-string";
import { ThemeProvider } from "@material-ui/core/styles";
import CircularProcess from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../logic/actions/orders";
import RotateRight from "@material-ui/icons/RotateRight";
import RotateLeft from "@material-ui/icons/RotateLeft";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import * as actions from "../../logic/actions/orders";
import Tooltip from "@material-ui/core/Tooltip";
import MapWithAddress from "../Map/MapWithAddress";
import { editDefaultFormFields } from "../../constants/order_constants";
import { PROVIDER_TYPE, orderStatus } from "../../constants/constants";
import {
  getError,
  getIsLoading,
  getUpdateOrderDone,
  getUpdateOrderError,
  getUpdateOrderIsLoading,
  getDeleteOrderDone,
  getDeleteOrderError,
  getDeleteOrderIsLoading,
  getOrder,
} from "../../logic/selectors/orders";
import { ROUTES, snackBarConst } from "../../constants/constants";
import theme from "../../assets/theme";
import * as selectors from "../../logic/reducers";
import {
  startEditingOrder,
  modifyUserInForm,
} from "../../logic/actions/orders";
import OrderForm from "../Order/OrderForm";
import TabPanel from "../Design/TabPanel";
import dateHelper from "../../helpers/dateHelper";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import defaultImg from "../../assets/img/default.jpg";
import "react-block-ui/style.css";

const validationSchema = yup.object({
  alias: yup
    .object()
    .shape({ text: yup.string().required("El agente es requerido") })
    .required(),
  company: yup
    .object()
    .shape({ text: yup.string().required("La compañía es requerida") })
    .required(),
  branch: yup
    .object()
    .shape({ text: yup.string().required("La sucursal es requerida") })
    .required(),
  phone: yup.number().typeError("Télefono debe ser un valor númerico"),
  zone: yup.number().typeError("Zona debe ser un valor númerico"),
});

const EditOrder = ({ match: { params }, location: { search } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const controller = useSelector(selectors.getController);
  const orders = useSelector(selectors.getOrders);
  const error = useSelector(getError);
  const deliveredOrder = useSelector(getOrder);
  const isLoading = useSelector(getIsLoading);
  const [formFields, setFormFields] = useState(editDefaultFormFields);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showStayInForm, setShowStayInForm] = useState(false);
  const [isOrderModified, setIsOrderModified] = useState(false);
  // Update Order
  const updateOrderError = useSelector(getUpdateOrderError);
  const updateOrderDone = useSelector(getUpdateOrderDone);
  const updateOrderIsLoading = useSelector(getUpdateOrderIsLoading);
  // Delete Order
  const deleteOrderError = useSelector(getDeleteOrderError);
  const deleteOrderDone = useSelector(getDeleteOrderDone);
  const deleteOrderIsLoading = useSelector(getDeleteOrderIsLoading);

  const [tabIndex, setTabIndex] = useState(0);
  const [imgWidth, setImgWidth] = useState(90);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isOrderBlocked, setIsOrderBlocked] = useState(false);
  const [rotation, setRotation] = useState("");
  const [order, setOrder] = useState();
  const [orderIndex, setOrderIndex] = useState(-1);
  const [orderState, setOrderState] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [btnList, setBtnList] = useState();
  const [marker, setMarker] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const getOtherActions = () => {
    const queryParams = queryString.parse(search);
    if (!isEmpty(queryParams.delivered)) return [];
    let actions = [];
    if (orderIndex > 0) {
      actions = [
        {
          id: "back-arrow",
          component: (
            <Tooltip title="ORDEN ANTERIOR">
              <div
                className="display-inline-block edit-form-arrow mr-sm"
                onClick={(e) => {
                  if (orders.length > orderIndex - 1) {
                    const orderId = orders[orderIndex - 1].id;
                    setOrder(orders[orderIndex - 1]);
                    setOrderIndex(orderIndex - 1);
                    history.replace(`${ROUTES.EDIT_ORDER_FILTER}/${orderId}`);
                    window.location.reload();
                  }
                }}
              >
                <ArrowBackIcon />
              </div>
            </Tooltip>
          ),
        },
      ];
    }
    if (orderIndex < orders.length - 1) {
      actions = [
        ...actions,
        {
          id: "next-arrow",
          component: (
            <Tooltip title="SIGUIENTE ORDEN">
              <div
                className="display-inline-block edit-form-arrow"
                onClick={(e) => {
                  if (orderIndex + 1 < orders.length) {
                    const orderId = orders[orderIndex + 1].id;
                    setOrder(orders[orderIndex + 1]);
                    setOrderIndex(orderIndex + 1);
                    history.replace(`${ROUTES.EDIT_ORDER_FILTER}/${orderId}`);
                  }
                }}
              >
                <ArrowForwardIcon />
              </div>
            </Tooltip>
          ),
        },
      ];
    }
    return actions;
  };

  const onMarkerClick = (marker) => {
    setMarker({
      ...marker,
      showInfoWindow: !marker.showInfoWindow,
    });
  };

  useEffect(() => {
    const queryParams = queryString.parse(search);
    if (isEmpty(queryParams.delivered)) {
      dispatch(actions.clearOrderSubscription());
      dispatch(actions.startFetchingOrders());
    } else {
      dispatch(orderActions.getOrderByOrderId(params.orderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(order)) {
      let newFormFields = [...formFields];
      let btnListToUse = [
        {
          id: "delete-order",
          type: "button",
          variant: "contained",
          color: "secondary",
          cssClasses: "display-inline-block padding-r-xsm",
          text: "eliminar",
          onClick: (element) => {
            setShowConfirmationDialog(true);
          },
        },
        {
          id: "cancel-order",
          type: "button",
          variant: "contained",
          color: "secondary",
          cssClasses: "display-inline-block padding-r-xsm",
          text: "cancelar",
          onClick: () => {
            const queryParams = queryString.parse(search);
            dispatch(
              modifyUserInForm(
                order.id,
                true,
                !isEmpty(order.modifyingBy) ? order.modifyingBy.id : ""
              )
            );
            if (queryParams.state === "forgotten") {
              history.push(ROUTES.ABANDONED_ORDERS);
              return;
            }
            if (isEmpty(queryParams.delivered)) {
              history.push(ROUTES.ALL_ORDERS_FILTER);
            } else {
              if (isEmpty(queryParams.route)) {
                history.push(ROUTES.HISTORY_ORDERS);
              } else {
                history.push(ROUTES.CANCELED_ORDERS);
              }
            }
            dispatch(orderActions.clearOrderEdition());
            return;
          },
        },
        {
          id: "edit-new-order",
          type: "submit",
          variant: "contained",
          color: "primary",
          cssClasses: "display-inline-block padding-r-xsm",
          text: "guardar",
        },
      ];
      if (order.status === orderStatus.GOING) {
        newFormFields = newFormFields.filter((el) => el.name !== "deliveredAt");
      }
      const queryParams = queryString.parse(search);
      if (isEmpty(queryParams.delivered)) {
        if (
          !isEmpty(order.modifyingBy) &&
          !isEmpty(order.modifyingBy.name) &&
          !isEmpty(order.modifyingBy.id)
        ) {
          if (order.modifyingBy.id !== controller.id) {
            setIsDisabled(true);
            newFormFields = newFormFields.map((el) => ({
              ...el,
              disabled: true,
            }));
            btnListToUse = btnListToUse.map((btn) => ({
              ...btn,
              disabled:
                btn.id === "delete-order" || btn.id === "edit-new-order",
            }));
          }
        }
      }

      if (order.status === orderStatus.DELIVERED) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "alias"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = true;
        }
      }

      if (order.status === orderStatus.GOING || order.status === orderStatus.ON_HOLD || order.status === orderStatus.CANCELLED
        || order.status === orderStatus.RE_AGEND || order.status === orderStatus.ASSIGNED || order.status === orderStatus.RECEIVING
      ) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "alias"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }

      if (order.status === orderStatus.GOING || order.status === orderStatus.ON_HOLD || order.status === orderStatus.CANCELLED
        || order.status === orderStatus.RE_AGEND || order.status === orderStatus.ASSIGNED || order.status === orderStatus.RECEIVING
      ) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "status"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }

      if (order.status === orderStatus.DELIVERED) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "status"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = true;
        }
      }

      if (order.status === orderStatus.CANCELLED) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "createdAt"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }
      if (order.status === orderStatus.CANCELLED) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "typeOrder"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }
      if (order.status === orderStatus.CANCELLED) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "orderId"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }
      if (order.status === orderStatus.RE_AGEND) {
        const horaComandaField = newFormFields.find(
          (el) => el.name === "createdAt"
        );
        if (!isEmpty(horaComandaField)) {
          horaComandaField.disabled = false;
        }
      }

      if (!isEmpty(queryParams.delivered)) {
        btnListToUse = btnListToUse.filter((btn) => btn.id !== "delete-order");
      }
      setBtnList(btnListToUse);
      setFormFields(newFormFields);
      const orderToUse = {
        ...order,
        createdAt: dateHelper.getDateOrUndefined(order.createdAt),
        deliveredAt: dateHelper.getDateOrUndefined(order.deliveredAt),
      };

      orderToUse.payMethod = {
        value: orderToUse.payMethod,
        text: orderToUse.payMethod
      }
      orderToUse.status = {
        value: orderToUse.status,
        text: orderToUse.status,
      };

      if (!isEmpty(orderToUse.alias)) {
        orderToUse.alias = {
          value: orderToUse.alias.id,
          text: orderToUse.alias.name,
        };
      }
      if (!isEmpty(orderToUse.branch)) {
        orderToUse.branch = {
          value: orderToUse.branch.id,
          text: orderToUse.branch.name,
        };
      }
      if (!isEmpty(orderToUse.company)) {
        orderToUse.company = {
          value: orderToUse.company.id,
          text: orderToUse.company.name,
        };
      }
      Object.keys(orderToUse).forEach((key) => {
        if (
          typeof orderToUse[key] === "string" &&
          orderToUse[key].trim().toUpperCase() === "ESPERANDO..."
        ) {
          orderToUse[key] = "...";
        }
      });
      setOrderState(orderToUse);
      setMarker({
        position: {
          longitude: parseFloat((order.locationRef || {}).longitude),
          latitude: parseFloat((order.locationRef || {}).latitude),
        },
        showInfoWindow: false,
        infoWindow: order.address,
        onClick: onMarkerClick,
      });
      setIsLoaded(true);
      if (isEmpty(queryParams.delivered)) {
        if (!isOrderModified) {
          if (
            !isEmpty(order.modifyingBy) &&
            !isEmpty(order.modifyingBy.name) &&
            !isEmpty(order.modifyingBy.id)
          ) {
            if (order.modifyingBy.id === controller.id) {
              setIsOrderModified(true);
              dispatch(modifyUserInForm(order.id));
              // onOrderTimeout();
            }
          } else {
            setIsOrderModified(true);
            dispatch(modifyUserInForm(order.id));
            // onOrderTimeout();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const onStayInForm = () => {
    dispatch(modifyUserInForm(order.id));
  };

  const removeUserFromForm = () => {
    dispatch(
      modifyUserInForm(
        order.id,
        true,
        !isEmpty(order.modifyingBy) ? order.modifyingBy.id : ""
      )
    );
    history.push(ROUTES.ALL_ORDERS_FILTER);
  };

  const onOrderTimeout = () => {
    // real-time-user: Validating when 4:30 are gone
    setTimeout(() => {
      setShowStayInForm(true);
      onOrderFormTimeout();
    }, 4 * 60 * 1000);
  };

  const onOrderFormTimeout = () => {
    // real-time-user: Giving 30 seconds to user to make a decission
    setTimeout(() => {
      removeUserFromForm();
    }, 60 * 1000);
  };

  useEffect(() => {
    if (!isLoading && (!isEmpty(error) || !isEmpty(orders))) {
      const orderCurrOrder = orders.findIndex(
        (order) => order.id === params.orderId
      );
      if (
        orderCurrOrder > -1 &&
        order === undefined &&
        !isEmpty(orders[orderCurrOrder])
      ) {
        setOrder(orders[orderCurrOrder]);
        setOrderIndex(orderCurrOrder);
      } else {
        // setOrderState({});
        // setIsLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading, orders]);

  useEffect(() => {
    if (!isLoading && !isEmpty(deliveredOrder)) {
      setOrder(deliveredOrder);
    }
  }, [error, isLoading, deliveredOrder]);

  useEffect(() => {
    if (!deleteOrderIsLoading) {
      if (deleteOrderDone) {
        enqueueSnackbar("Orden eliminada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearOrderEdition());
        history.push(ROUTES.ALL_ORDERS_FILTER);
      } else if (!isEmpty(deleteOrderError)) {
        enqueueSnackbar(
          "Hubo un error al eliminar la orden, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrderIsLoading, deleteOrderDone, deleteOrderError]);

  useEffect(() => {
    if (!updateOrderIsLoading) {
      if (updateOrderDone) {
        enqueueSnackbar("Orden actualizada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearOrderEdition());
        const queryParams = queryString.parse(search);
        if (queryParams.state === "forgotten") {
          history.push(ROUTES.ABANDONED_ORDERS);
          return;
        }
        if (isEmpty(queryParams.delivered)) {
          history.push(ROUTES.ALL_ORDERS_FILTER);
        } else {
          if (isEmpty(queryParams.route)) {
            history.push(ROUTES.HISTORY_ORDERS);
          } else {
            history.push(ROUTES.CANCELED_ORDERS);
          }
        }
      } else if (!isEmpty(updateOrderError)) {
        enqueueSnackbar(
          `Hubo un error al actualizar la orden, por favor, intenta más tarde: ${updateOrderError}`,
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderIsLoading, updateOrderDone, updateOrderError]);

  const onDelete = () => {
    const values = { ...order, status: orderStatus.DELETED };
    setShowConfirmationDialog(false);
    // dispatch(orderActions.deleteOrder(orderState.id));
    delete values.updatedAt;
    delete values.orderPic;
    delete values.receivedPic;
    delete values.alert;
    delete values.modifyingByName;
    delete values.toBeDeliveredAt;
    delete values.timeInBranch;
    delete values.modifyingBy;
    delete values.createdAt;
    delete values.deliveredAt;
    delete values.canceledAt;
    // delete values.stateTime;
    // delete values.deliveryDate;
    if (!isEmpty(values.alias)) {
      values.alias_id = values.alias.value;
      delete values.alias;
    }
    if (!isEmpty(values.branch)) {
      values.branch_id = values.branch.value;
      delete values.branch;
    }

    if (!isEmpty(values.company)) {
      values.company_id = values.company.value;
      delete values.company;
    }
    if (!isEmpty(values.locationRef)) {
      values.origin = `${values.locationRef.latitude},${values.locationRef.longitude}`;
      delete values.locationRef;
    }
    dispatch(startEditingOrder(values, order));
  };

  const onCancelDeletion = () => {
    setShowConfirmationDialog(false);
  };

  const onConfirmAddress = (values) => {
    setOrderState((val) => ({
      ...val,
      locationRef: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      munic: values.munic,
      depto: values.depto,
      zone: values.zone,
    }));
    setMarker((val) => ({
      ...val,
      position: {
        longitude: values.longitude,
        latitude: values.latitude,
      },
    }));
  };
  const handleChange = (e) => {
    setOrderState({
      ...orderState,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (values, shouldUpdateUserInForm = true) => {
    delete values.updatedAt;
    delete values.orderPic;
    delete values.receivedPic;
    delete values.alert;
    delete values.modifyingByName;
    delete values.toBeDeliveredAt;
    delete values.timeInBranch;
    const modifyingBy = values.modifyingBy;
    delete values.modifyingBy;
    dispatch(startEditingOrder(values, order));
    const queryParams = queryString.parse(search);
    if (isEmpty(queryParams.delivered) && shouldUpdateUserInForm) {
      dispatch(
        modifyUserInForm(
          order.id,
          true,
          !isEmpty(modifyingBy) ? modifyingBy.id : ""
        )
      );
    }
  };

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  const renderUserInForm = () => {
    if (isEmpty(order.modifyingByName)) return <></>;
    return (
      <div className="text-right margin-left-sm">{`Usuario: ${order.modifyingByName}`}</div>
    );
  };

  const renderForm = () => {
    return (
      <>
        {!isEmpty(orderState) &&
          !isEmpty(orderState.locationRef) &&
          orderState.locationRef.latitude === 14 &&
          orderState.locationRef.longitude === 14 && (
            <div className="text-right margin-left-sm">
              COORDENADAS NO ENCONTRADAS
            </div>
          )}
        <OrderForm
          defaultFormFields={formFields.map((formField) => ({
            ...formField,
          }))}
          handleChange={handleChange}
          prevOrder={order}
          order={orderState}
          btnList={btnList}
          onSubmit={onSubmit}
          setOrder={setOrderState}
          validationSchema={validationSchema}
          formCssClasses="scrollable-column all-upper-case"
          btnAlignment="right"
          btnCssClass="margin-bottom-sm"
          otherActions={getOtherActions()}
          isDisabled={isDisabled}
          buttonsTop
          closeBikers
          useService
          isEditing
        />
        <div>
          <span>Foto: </span>
          <a
            href={
              isEmpty(orderState.orderPic) &&
                orderState.provider === PROVIDER_TYPE.ECOMMERCE
                ? "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/defaultimages%2Fecommerce.jpg?alt=media&token=cf8ed91d-863e-44c9-b003-669350894927"
                : orderState.orderPic
            }
            target="__blank"
          >
            {isEmpty(orderState.orderPic) &&
              orderState.provider === PROVIDER_TYPE.ECOMMERCE
              ? "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/defaultimages%2Fecommerce.jpg?alt=media&token=cf8ed91d-863e-44c9-b003-669350894927"
              : orderState.orderPic}
          </a>
        </div>
      </>
    );
  };

  const getImg = () => {
    if (isEmpty(orderState.orderPic) || orderState.orderPic === "SIN URL") {
      if (orderState.provider === PROVIDER_TYPE.ECOMMERCE) {
        return "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/defaultimages%2Fecommerce.jpg?alt=media&token=cf8ed91d-863e-44c9-b003-669350894927";
      }
      return defaultImg;
    }
    return orderState.orderPic;
  };

  const renderImage = () => (
    <div className="text-center">
      <div className="margin-bottom-sm">
        <div className="display-inline-block margin-right-lg">
          <Tooltip title="Menos Zoom">
            <div
              className="rotate-icon margin-right-xsm"
              onClick={() => {
                if (imgWidth > 10) {
                  setImgWidth((currWidth) => currWidth - 10);
                }
              }}
            >
              <RemoveIcon />
            </div>
          </Tooltip>
          <Tooltip title="Reiniciar Zoom">
            <div
              className="display-inline-block margin-left-sm margin-right-sm cursor-pointer"
              onClick={() => {
                setImgWidth(90);
              }}
            >
              Zoom
            </div>
          </Tooltip>
          <Tooltip title="Más Zoom">
            <div
              className="rotate-icon margin-right-xsm"
              onClick={() => setImgWidth((currWidth) => currWidth + 10)}
            >
              <AddIcon />
            </div>
          </Tooltip>
        </div>
        <div className="display-inline-block">
          <Tooltip title="Rotar a la izquierda">
            <div
              className="rotate-icon margin-right-xsm"
              onClick={() =>
                setRotation((val) => {
                  if (!isEmpty(val)) {
                    if (val === "translate-Y-down") {
                      return "translate-X-reverse";
                    } else if (val === "translate-X-reverse") {
                      return "translate-Y-top";
                    } else if (val === "normal") {
                      return "translate-Y-down";
                    } else {
                      return "normal";
                    }
                  } else {
                    return "translate-Y-down";
                  }
                })
              }
            >
              <RotateLeft />
            </div>
          </Tooltip>
          <Tooltip title="Rotar a la derecha">
            <div
              className="rotate-icon"
              onClick={() =>
                setRotation((val) => {
                  if (!isEmpty(val)) {
                    if (val === "translate-Y-top") {
                      return "translate-X-reverse";
                    } else if (val === "translate-X-reverse") {
                      return "translate-Y-down";
                    } else if (val === "normal") {
                      return "translate-Y-top";
                    } else {
                      return "normal";
                    }
                  } else {
                    return "translate-Y-top";
                  }
                })
              }
            >
              <RotateRight />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="scrollable-column-img">
        <div className="rotation-wrapper-outer">
          <div className="rotation-wrapper-inner">
            <img
              style={{
                width: `${imgWidth}%`,
              }}
              className={`element-to-rotate ${rotation}`}
              id="my-image-id"
              src={getImg()}
              alt="comanda"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderMap = () => {
    return (
      <div className="overflow-auto">
        <MapWithAddress
          onConfirmAddress={onConfirmAddress}
          markers={[marker]}
          center={{
            lng: parseFloat((orderState.locationRef || {}).longitude),
            lat: parseFloat((orderState.locationRef || {}).latitude),
          }}
          zoom={17}
        />
      </div>
    );
  };

  const renderView = () => {
    if (deleteOrderIsLoading || deleteOrderDone) {
      return <></>;
    }
    if (!isEmpty(error)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intetrlo más tarde!
        </Alert>
      );
    }

    if (isOrderBlocked) {
      return <Alert severity="error">Orden ya no disponible</Alert>;
    }

    return (
      <BlockUi tag="div" blocking={updateOrderIsLoading}>
        {showConfirmationDialog && (
          <ConfirmationDialog
            text="Está seguro de querer eliminar la orden?"
            onCancel={onCancelDeletion}
            onConfirm={onDelete}
          />
        )}
        {showStayInForm && (
          <ConfirmationDialog
            text="El formulario esta a punto de cerrarse por inactividad, desea permanecer la sesión activa?"
            onConfirm={onStayInForm}
            onCancel={removeUserFromForm}
          />
        )}
        <div>
          <div className="col-5 vertical-top comanda-img">
            <AppBar position="static">
              <Tabs
                value={tabIndex}
                onChange={(e, newValue) => setTabIndex(newValue)}
                aria-label="simple tabs example"
              >
                <Tab label="Foto" />
                <Tab label="Mapa" />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
              {renderImage()}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {renderMap()}
            </TabPanel>
          </div>
          <div className="col-7">
            <>{renderUserInForm()}</>
            <div>{renderForm()}</div>
          </div>
        </div>
      </BlockUi>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <NavBar title="modificar orden" /> */}
      <div className="container">
        {isLoaded ? renderView() : renderLoading()}
      </div>
    </ThemeProvider>
  );
};

export default EditOrder;
