import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { connect, useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { ROLES } from "../../constants/constants";
import * as actions from "../../logic/actions/auth";
import * as selectors from "../../logic/reducers";
import companyActions from "../../logic/actions/company";
import arrayHelper from "../../helpers/arrayHelper";
import { snackBarConst } from "../../constants/constants";
import { useSnackbar } from "notistack";
import generator from "generate-password";

import { getCompanies } from "../../logic/selectors/company";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © DELIVERY LAB "}

      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const CreateUsers = ({
  createdUsers,
  startCreatingUser,
  completedCreatedUser,
  errorCreatedUser,
}) => {
  const companies = useSelector(getCompanies);
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState("");
  const [company, setCompany] = React.useState(null);
  const [getBranch, setGetBranch] = React.useState(null);
  const [sendBranch, setSendBranch] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(companyActions.getCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUser = (event) => {
    setUsers(event.target.value);
  };

  const schema = yup.object({
    firstName: yup.string().required("Ingrese un Nombre"),
    lastName: yup.string().required("Ingrese un Apellido"),
    email: yup
      .string()
      .email("Ingrese un correo válido")
      .required("Ingrese un correo electronico"),
  });
  let password = "";
  password = generator.generate({
    length: 10,
    numbers: true,
  });
  const handleChangeCompany = (event) => {
    console.log(event.target.value);

    setCompany(event.target.value);
  };

  useEffect(() => {
    const branch = arrayHelper.searchBranch();
    branch.then((response) => {
      let filterBranch = response.filter(
        (branchFilter) => branchFilter._idCompany === company?.id
      );
      setGetBranch(filterBranch);
    });
  }, [company, setGetBranch]);

  const handleChangeBranch = (event) => {
    setSendBranch(event.target.value);
  };

  useEffect(() => {
    createdUsers(null);
    if (completedCreatedUser) {
      enqueueSnackbar("Usuario creado correctamente", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    }
    // console.log(errorCreatedUser);
    if (errorCreatedUser) {
      enqueueSnackbar("HUBO UN ERROR AL CREAR EL USUARIO", {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    }
  }, [completedCreatedUser, errorCreatedUser, enqueueSnackbar, createdUsers]);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#F88B1E" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            CREAR USUARIO
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              onSubmit={(values, { resetForm }) => {
                let props = {
                  ...values,
                  role: users,
                  branch: sendBranch,
                  company: company,
                  password: password,
                };
                createdUsers(props);
                resetForm({ values: "" });
              }}
              validationSchema={schema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                values,
                touched,
              }) => (
                <form>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="Primer nombre"
                      name="firstName"
                      autoComplete="firstName"
                      autoFocus
                      value={values.firstName}
                      error={errors.firstName && touched.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.firstName &&
                        touched.firstName &&
                        errors.firstName
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lastName"
                      label="Primer Apellido"
                      name="lastName"
                      autoComplete="lastName"
                      autoFocus
                      value={values.lastName}
                      error={errors.lastName && touched.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.lastName && touched.lastName && errors.lastName
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={values.email}
                      error={errors.email && touched.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && errors.email}
                    />
                    <TextField
                      label="CONTRASEÑA"
                      id="password"
                      name="password"
                      fullWidth
                      value={password}
                      defaultValue={password}
                      onChange={(e) => handleChangeUser(e)}
                      disabled={true}
                    />
                    <Box sx={{ minWidth: 120 }} style={{ paddingTop: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel>ROL DE USUARIO</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={users}
                          label="USUARIOS"
                          onChange={handleChangeUser}
                        >
                          <MenuItem value={"CONTROLLER"}>CONTROLADOR</MenuItem>
                          <MenuItem value={"ADMINISTRATOR"}>
                            ADMINISTRADOR
                          </MenuItem>
                          <MenuItem value={"USER"}>USUARIO</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {users === ROLES.ADMINISTRATOR && (
                      <Box
                        sx={{ minWidth: 120 }}
                        style={{ paddingTop: "20px" }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>EMPRESA</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={company}
                            label="EMPRESA"
                            onChange={handleChangeCompany}
                          >
                            {companies.map((el) => (
                              <MenuItem value={el}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {users === ROLES.USER && (
                      <div>
                        <Box
                          sx={{ minWidth: 120 }}
                          style={{ paddingTop: "20px" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>EMPRESA</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={company}
                              label="EMPRESA"
                              onChange={handleChangeCompany}
                            >
                              {companies.map((el) => (
                                <MenuItem value={el}>{el.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        {(company !== null) & (getBranch !== null) ? (
                          <Box
                            sx={{ minWidth: 120 }}
                            style={{ paddingTop: "20px" }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>SUCURSAL</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                value={sendBranch}
                                label="SUCURSAL"
                                onChange={handleChangeBranch}
                              >
                                {getBranch.map((branch) => (
                                  <MenuItem value={branch}>
                                    {branch._branch}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          <Box
                            sx={{ minWidth: 120 }}
                            style={{ paddingTop: "20px" }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>SUCURSAL</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                value={sendBranch}
                                label="SUCURSAL"
                                onChange={handleChangeBranch}
                                disabled={true}
                              >
                                {getBranch.map((branch) => (
                                  <MenuItem value={branch}>
                                    {branch._branch}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      </div>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Crear cuenta
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default connect(
  (state) => ({
    startCreatingUser: selectors.creatingTokenAuth(state),
    completedCreatedUser: selectors.createdUserAuth(state),
    errorCreatedUser: selectors.createdUserFailedAuth(state),
  }),
  (dispatch) => ({
    createdUsers(values) {
      dispatch(actions.startingCreatingAuth(values));
    },
  })
)(CreateUsers);
