import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
} from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { BikerModel } from "../../models";
import Rating from "@material-ui/lab/Rating";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import { getController } from "../../logic/reducers";

const useStyles = makeStyles((theme) => ({
  rating: {
    alignItems: "center",
    display: "flex",
    width: "97%",
  },
  ratingBody: {
    margin: "auto",
    textAlign: "center",
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: "Very Satisfied",
  },
};
function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};
const EditBikerModal = ({ open, handleClose, details = new BikerModel() }) => {
  const [state, setState] = useState({
    message: "",
    rating: 2,
  });
  const dispatch = useDispatch();
  const controller = useSelector(getController);
  const classes = useStyles();
  const onChange = (e) => {
    console.log(`${e.target.id}: `, e.target.value);
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };
  const onRating = (e) => {
    setState({
      ...state,
      rating: e.target.value,
    });
  };
  const onSubmit = () => {
    dispatch(
      actions.pilotActions.createBikerRatingStarted({
        bikerId: details.id,
        controllerId: controller.id,
        ...state,
      })
    );
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Agregar un comentario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Que te pareció el servicio que brindó ${details.name} el día de hoy?`}
        </DialogContentText>
        <div className={classes.rating}>
          <div className={classes.ratingBody}>
            <Rating
              name="rating"
              id="rating"
              defaultValue={2}
              getLabelText={(value) => customIcons[value].label}
              IconContainerComponent={IconContainer}
              onChange={onRating}
            />
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="message"
          label="Comentario"
          type="message"
          onChange={onChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Comentar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditBikerModal;
