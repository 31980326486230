import React, { useState, useRef } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";
import mapStyles from "./mapStyle";
import { compose, withProps } from "recompose";
import lodash from "lodash";
import { BIKER_ICON, DELIVERY_ICON } from "../../../constants/constants";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

const MapRender = compose(
  withScriptjs,
  withGoogleMap,
  withProps((props) => {
    const [mapOpts, setMapOpts] = useState({});
    const d = {
      ...[
        ["poStart", lodash.get(props, "pilot.actualPosition") || {}],
        ["poEnd", lodash.get(props, "data.locationRef") || {}],
      ].reduce(
        (p, [k, v]) => ({
          ...p,
          [k]: { lat: v.latitude || 0, lng: v.longitude || 0 },
        }),
        {}
      ),
      ...props,
      mapOpts: {
        ...mapOpts,
        ...props.mapOpts,
        map: {
          defaultZoom: 9,
          ...(mapOpts || {}).map,
          ...(props.mapOpts || {}).map,
        },
      },
    };
    if (typeof d.setMapOpts !== "function") d.setMapOpts = setMapOpts;
    d.mapOpts.map.defaultCenter = d.mapOpts.map.center ||
      d.mapOpts.map.defaultCente || {
        lat: (d.poStart.lat + d.poEnd.lat) / 2,
        lng: (d.poStart.lng + d.poEnd.lng) / 2,
      };
    return d;
  })
)((props) => {
  //* Empty
  if (props.pilot === null) return <div>espere un momento</div>;
  //* Vars
  let ref = useRef(null);
  const [directions, setDirections] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);

  try {
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: props.poStart,
        destination: props.poEnd,
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status === "OK") {
          if (
            !directions ||
            !lodash.isEqual(
              directions.geocoded_waypoints,
              result.geocoded_waypoints
            )
          ) {
            setDirections(result);
            const duration = result.routes[0].legs[0].duration.value;
            setDeliveryTime(duration);
          }
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  } catch (error) {
    console.error(`error fetching directions`, error);
  }
  //* On Map Change
  const onMapChange = (_eventName) => () => {
    //* In Case you want to do some action when the map change
    // const MAP = "__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
    // const data = ref.current.context[MAP];
    // props.setMapOpts({
    //   ...props.mapOpts,
    //   map: {
    //     ...props.mapOpts.map,
    //     center: Object.entries(data.center || {}).reduce(
    //       (p, [k, v]) => ({ ...p, [k]: typeof v === "function" ? v() : v }),
    //       {}
    //     ),
    //     zoom: data.zoom,
    //   },
    // });
  };
  //* Biker Data
  //todo only for dev
  // console.info(
  //   `\n»» Biker: https://console.firebase.google.com/u/0/project/twowheelstogo-572d7/firestore/data/~2Fbikers~2F${props.pilot.id}`,
  //   `\n••••••| ${new Date().toLocaleString()} |••••••\n`
  // );

  const currentTimeMilliseconds = Date.now();
  const arrivalTimeMillis = currentTimeMilliseconds + deliveryTime * 1000;
  const arrivalTime = new Date(arrivalTimeMillis);
  const formattedArrivalTime = moment(arrivalTime).format("h:mm a");

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "1rem",
          textAlign: "center",
        }}
      >
        <Typography component="span" variant="subtitle2" color="textPrimary">
          TIEMPO APROXIMADO DE ARRIBO
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ alignItems: "center", color: "#f26724" }}
        >
          <strong>
            {formattedArrivalTime === "" ? "CALCULANDO" : formattedArrivalTime}{" "}
          </strong>
        </Typography>
      </div>
      <div>
        <GoogleMap
          id="map"
          ref={ref}
          defaultOptions={{ styles: mapStyles }}
          zoom={13}
          center={{
            lat: props.mapOpts.map.defaultCenter.lat,
            lng: props.mapOpts.map.defaultCenter.lng,
          }}
          onCenterChanged={onMapChange("onCenterChanged")}
          onZoomChanged={onMapChange("onZoomChanged")}
          {...props.mapOpts.map}
        >
          {directions && (
            <DirectionsRenderer
              defaultOptions={{
                suppressMarkers: true,
                draggable: true,
                preserveViewport: true,
              }}
              directions={directions}
            />
          )}
          <Marker
            position={props.poEnd}
            // icon={{
            //   url:
            //     "https://firebasestorage.googleapis.com/v0/b/imagenesproyect.appspot.com/o/home_customer.svg?alt=media&token=bfeda40a-8988-48ed-8691-139833a4d7ef",
            //   scaledSize: new window.google.maps.Size(50, 50),
            // }}
            icon={DELIVERY_ICON}
          />
          <Marker
            position={props.poStart}
            // icon={{
            //   url:
            //     "https://firebasestorage.googleapis.com/v0/b/imagenesproyect.appspot.com/o/biker_marker.svg?alt=media&token=9de880c1-8df0-4374-86ee-a8f780560b0c",
            //   scaledSize: new window.google.maps.Size(50, 50),
            // }}
            icon={BIKER_ICON}
          />
        </GoogleMap>
      </div>
    </>
  );
});

export default MapRender;
