import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as actionsOrder from "../../logic/actions/orders";
import * as selectors from "../../logic/reducers";
import * as actionPilot from "../../logic/actions/pilots";
import * as actionCompanie from "../../logic/actions/company";
import Grid from "@material-ui/core/Grid";
import theme from "../../assets/theme";
import { ThemeProvider } from "@material-ui/core";
import Progressbar from "../LoginShare/components/progressbar";
import ErrorView from "./components/errorLink";
// import Canceld from "./components/cancelLink";
// import VideoTimeCompanies from "./components/linkVideos";
import RutaOrder from "./components/linkruta";
// import Navbar from "./components";
// import EntregadaLink from "./components/linkentrega";

const ShareViews = ({
  pilotCompleted,
  fetchLinkWhitTime,
  timeBiker,
  fetchLinkOrder,
  pilotStarted,
  pilotError,
  fetchLinkCompaniCompanie,
  orderLoading,
  completedOrder,
  errorOrder,
  fetchLinkPilot,
  fetchLinkCompani,
}) => {
  const { id } = useParams();
  useEffect(() => {
    fetchLinkOrder(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if(completedOrder !== null ){
      fetchLinkPilot(completedOrder?.alias.id);
      fetchLinkCompani(completedOrder?.company.id);
      fetchLinkWhitTime(completedOrder?.alias.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedOrder]);

  const idBiker = completedOrder !== null ? completedOrder?.alias.id : ""

  const getTimbeByRoutes = (idOrder, idBiker) => {
    const getTime = timeBiker;
    let indexDeliveryOrder = getTime?.route.findIndex((x) => x.id === idOrder);

    let data = getTime?.route[indexDeliveryOrder];
    return {
      time: data?.time,
      distance: data?.distance,
      date: data?.date,
    };
  };
  console.info(`\n»» ShareViews`, {completedOrder, pilotCompleted}, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        {orderLoading === true ? (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            display="flex"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={12}>
              <Progressbar />
            </Grid>
          </Grid>
        ) : (
          <div>
            {completedOrder === null ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={12}>
                  <div>
                    <Progressbar></Progressbar>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div>
                {errorOrder === true && (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                  >
                    <Grid item xs={12}>
                      <div>
                        <ErrorView />
                      </div>
                    </Grid>
                  </Grid>
                )}
                {completedOrder?.status && (
                  <div>
                    <div>
                      <RutaOrder
                        time={getTimbeByRoutes(id, idBiker)}
                        data={completedOrder}
                        biker={pilotCompleted}
                        params={fetchLinkCompaniCompanie}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </ThemeProvider>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    orderLoading: selectors.getIsFetchingOrderLInkStarted(state),
    completedOrder: selectors.getIsFetchingOrderLinkCompleted(state),
    errorOrder: selectors.getIsFetchingOrderLinkError(state),

    pilotStarted: selectors.getPilotLinkStarted(state),
    pilotCompleted: selectors.getPilotLinkCompleted(state),
    pilotError: selectors.getPilotLinkFailed(state),

    fetchLinkCompaniStarted: selectors.fetchingLinkCompaniesStarted(state),
    fetchLinkCompaniCompanie: selectors.fetchingLinkCompaniesCompleted(state),
    fetchLinkCompanieFailed: selectors.fetchingLinkCompaniesFailed(state),
    //TIME BIKER
    timeBiker: selectors.getIsFetchingLinkOrderTimeCompleted(state),
  }),
  (dispatch) => ({
    fetchLinkOrder(id) {
      dispatch(actionsOrder.startedOrderLink(id));
    },
    fetchLinkPilot(id) {
      dispatch(actionPilot.pilotGetLinkStarted(id));
    },
    fetchLinkCompani(id) {
      dispatch(actionCompanie.getCompaniesLinkStarted(id));
    },
    fetchLinkWhitTime(id) {
      dispatch(actionsOrder.linkGetOrderWhitTimerBikerStarted(id));
    },
  })
)(ShareViews);
