/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import ImageUploader from "react-images-upload";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import {
  isCreateBikerLoading,
  isBikerCreated,
  getCreateBikerError,
} from "../../logic/selectors/bikers";
import BikerForm from "./BikerForm";
import { emptyBiker, defaultFormFields } from "../../constants/bikerConstants";
import { ROUTES, snackBarConst } from "../../constants/constants";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
  phone: yup.string().required("Teléfono es requerido"),
  company: yup
    .object()
    .shape({ text: yup.string().required("La compañía es requerida") })
    .required(),
  branch: yup
    .object()
    .shape({ text: yup.string().required("La sucursal es requerida") })
    .required(),
});

const CreateBiker = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isLoading = useSelector(isCreateBikerLoading);
  const isCreated = useSelector(isBikerCreated);
  const error = useSelector(getCreateBikerError);
  const [biker, setBiker] = useState(emptyBiker);
  const [image, setImage] = useState();
  const btnList = [
    {
      id: "cancel-biker",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.push(ROUTES.BIKERS);
        return;
      },
    },
    {
      id: "create-new-company",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear 2wheeler",
    },
  ];

  useOnCreatingCompany(isCreated, error, isLoading, history, enqueueSnackbar);

  const onSubmit = (values) => {
    // dispatch(companyActions.createCompany(values));
  };

  const renderImage = () => {
    return (
      <div className="padding-lg">
        <ImageUploader
          withIcon={true}
          buttonText="Cargar imagen"
          onChange={(pic) => setImage(pic)}
          imgExtension={[".jpg", ".png"]}
          maxFileSize={5242880}
          withPreview
          singleImage
        />
      </div>
    );
  };

  return (
    <div title="Crear 2Wheeler">
      <BlockUi tag="div" blocking={isLoading}>
        <div className="display-flex">
          <div className="col-5">{renderImage()}</div>
          <div className="col-7">
            <BikerForm
              formFields={defaultFormFields.map((formField) => ({
                ...formField,
              }))}
              biker={biker}
              btnList={btnList}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              setBiker={setBiker}
            />
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

const useOnCreatingCompany = (
  isCreated,
  error,
  isLoading,
  history,
  enqueueSnackbar
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isCreated) {
        enqueueSnackbar("2Wheeler creado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        history.push(ROUTES.COMPANY);
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al crear al 2Wheeler, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [isCreated, error, isLoading]);
};

export default CreateBiker;
