import axios from "axios";
import { API_URL } from "../../constants/constants";
const TOKEN = "MTAwNzMyMjgyMDQyMzk4NzIzMA.GS9vUe.MqaJ2YymMhAOFicf_PO6yyK3J4d71jM0MNvsc4";
const CHANNEL_ID = "1006968621660123157";

const createIssue = async ({ file, metadata }) => {
  let formData = new FormData();
  let date = new Date();
  let blob = await fetch(file).then((res) => res.blob());
  blob.name = `${blob.name}.png`;
  blob.lastModifiedDate = date;

  formData.append("file", blob);
  formData.append("metadata", metadata);
  metadata.file = file;
  return (
    await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      url: `${API_URL}api/issues/post-message`,
      data: metadata,
    })
  ).data;
};

const sendMessageAndImage = async (content, imageUrl) => {
  const imageRes = await fetch(imageUrl).then((res) => res.body);
  let formdata = new FormData();
  formdata.append("files[0]", imageRes, "chart.png");
  formdata.append(
    "payload_json",
    JSON.stringify({
      content,
    })
  );
  const response = await fetch(
    `https://discord.com/api/v9/channels/${CHANNEL_ID}/messages`,
    {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bot ${TOKEN}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

export { createIssue, sendMessageAndImage };
