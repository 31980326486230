import { combineReducers } from 'redux'
import  * as types  from '../types/company'


const companiesStartedLink = ( state = false, action ) =>{
    switch (action.type) {
        case types.GET_COMPANIES_LINK_STARTED:{
            return true
        }
        case types.GET_COMPANIES_LINK_COMPLETED:{
            return false
        }
        case types.GET_COMPANIES_LINK_FAILED:{
            return false
        }
        default:{ return state }
    }
} 


  const companiesLinkCompleted = ( state = null, action ) => {
    switch (action.type) {
      case types.GET_COMPANIES_LINK_COMPLETED: {
        return action.payload.companies
      }
      default:{return state}
    }
  }
  
  const companiesLinkError = ( state = false, action ) => {
    switch (action.type) {
      case types.GET_COMPANIES_LINK_STARTED:{
        return false
      }
  
      case types.GET_COMPANIES_LINK_COMPLETED:{
        return false
      }
  
      case types.GET_COMPANIES_LINK_FAILED:{
        return true
      }
  
      default:{return state}
    }
  } 

const listenerCompanies = combineReducers({
    companiesStartedLink, 
    companiesLinkCompleted, 
    companiesLinkError, 
})

export default listenerCompanies;

  
export const fetchingLinkCompaniesStarted =  ( state ) => state.companiesStartedLink; 
export const fetchingLinkCompaniesCompleted =  ( state ) => state.companiesLinkCompleted; 
export const fetchingLinkCompaniesFailed = ( state ) => state.companiesLinkError; 
