import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import ImageUploader from "react-images-upload";
import companyActions from "../../logic/actions/company";
import {
  isCreateCompanyLoading,
  isCompanyCreated,
  getCreateCompanyError,
} from "../../logic/selectors/company";
import CompanyForm from "./CompanyForm";
import {
  emptyCompany,
  defaultFormFields,
} from "../../constants/companyConstants";
import { ROUTES, snackBarConst } from "../../constants/constants";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
});

const CreateCompany = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(isCreateCompanyLoading);
  const isCreated = useSelector(isCompanyCreated);
  const error = useSelector(getCreateCompanyError);
  const [company, setCompany] = useState(emptyCompany);
  const [image, setImage] = useState();
  const btnList = [
    {
      id: "cancel-company",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.push(ROUTES.COMPANY);
        return;
      },
    },
    {
      id: "create-new-company",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear compañía",
    },
  ];

  useOnCreatingCompany(
    isCreated,
    error,
    isLoading,
    history,
    enqueueSnackbar,
    dispatch
  );

  const onSubmit = (values) => {
    dispatch(companyActions.createCompany({ ...values, image }));
  };

  return (
    <div title="Crear Compañía">
      <BlockUi tag="div" blocking={isLoading}>
        <div>
          <div className="col-4 vertical-top">
            <ImageUploader
              withIcon={true}
              buttonText="Cargar imagen"
              onChange={(pic) => setImage(pic)}
              imgExtension={[".jpg", ".png"]}
              maxFileSize={5242880}
              withPreview
              singleImage
            />
          </div>
          <div className="col-8">
            <CompanyForm
              formFields={defaultFormFields.map((formField) => ({
                ...formField,
              }))}
              company={company}
              btnList={btnList}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              setCompany={setCompany}
              forceSetState
            />
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

const useOnCreatingCompany = (
  isCreated,
  error,
  isLoading,
  history,
  enqueueSnackbar,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isCreated) {
        enqueueSnackbar("Compañía creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(companyActions.clearAll());
        history.push(ROUTES.COMPANY);
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al crear la compañía, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, error, isLoading]);
};

export default CreateCompany;
