import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import DynamicForm from "../Design/Form/DynamicForm";
import { pilotActions } from "../../logic/actions/pilots";
import {
  snackBarConst,
  INPUT_TYPE,
  bikerAlert as bikerAlertConst,
} from "../../constants/constants";
import {
  isEditBikerAlertLoading,
  isBikerAlertEdited,
  getEditBikerAlertError,
} from "../../logic/selectors/bikers";

const validationSchema = yup.object({
  biker: yup
    .object()
    .shape({ text: yup.string().required("El agente es requerido") })
    .required(),
  alert: yup
    .object()
    .shape({ text: yup.string().required("La alerta es requerida") })
    .required(),
});

export default ({ onModalClose, bikerAlert, bikers }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isLoading = useSelector(isEditBikerAlertLoading);
  const isUpdated = useSelector(isBikerAlertEdited);
  const error = useSelector(getEditBikerAlertError);
  const [data, setData] = useState({
    biker: bikerAlert.biker,
    alert: bikerAlert.alert,
  });
  const [formFields, setFormFields] = useState([
    {
      label: "Agente",
      name: "biker",
      cssClasses: "col-12",
      fullWidth: true,
      inputType: INPUT_TYPE.FILTERABLE_LIST,
    },
    {
      label: "Alerta",
      name: "alert",
      cssClasses: "col-12",
      fullWidth: true,
      inputType: INPUT_TYPE.FILTERABLE_LIST,
    },
  ]);

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "aceptar",
    },
  ];

  useEffect(() => {
    setFormFields((values) => {
      let newValues = [...values];
      newValues[0].options = (bikers || []).map((biker) => ({
        value: biker.id,
        text: biker.name,
      }));
      let alertOptions = [];
      Object.keys(bikerAlertConst).forEach((key) => {
        alertOptions = [
          ...alertOptions,
          {
            value: bikerAlertConst[key],
            text: bikerAlertConst[key],
          },
        ];
      });
      newValues[1].options = alertOptions;
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnUpdateBikerAlert(
    isUpdated,
    error,
    isLoading,
    enqueueSnackbar,
    onModalClose,
    dispatch
  );

  const onSubmit = (values) => {
    dispatch(
      pilotActions.editBikerAlert(values.biker.value, values.alert.value)
    );
  };

  return (
    <div className="modal-auto-height">
      <div className="modal-container-auto-height">
        <div className="modal-title">
          <div className="modal-title-text">ALERTAS</div>
        </div>
        <div className="modal-body all-upper-case">
          <BlockUi tag="div" blocking={isLoading}>
            <DynamicForm
              obj={data}
              fields={formFields}
              validationSchema={validationSchema}
              buttons={btnList}
              onSubmit={onSubmit}
              btnAlignment="right"
              setState={setData}
              btnCssClass="modal-footer"
            />
          </BlockUi>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

const useOnUpdateBikerAlert = (
  isCreated,
  error,
  isLoading,
  enqueueSnackbar,
  onModalClose,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isCreated) {
        enqueueSnackbar("Alerta modificada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(pilotActions.editBikerAlertClear());
        onModalClose();
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al modificar la alerta, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, error, isLoading]);
};
