import React from "react";
import { Typography, Grid,Tooltip,Box } from "@material-ui/core";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import SportsMotorsportsSharpIcon from "@mui/icons-material/SportsMotorsportsSharp";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import RoomSharpIcon from "@mui/icons-material/RoomSharp";
import ShoppingBagSharpIcon from "@mui/icons-material/ShoppingBagSharp";
import DangerousSharpIcon from "@mui/icons-material/DangerousOutlined";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import {useStyles} from "./CompanyTableStyle"

const FormPrincipal = () => {
    const classes = useStyles();
    return (
        <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Box className={classes.boxStylePrincipal}>
          <div className={classes.boxStyle}>
            <div>
              <Typography className={classes.textTitle}>
                ESTATUS DE LA ORDEN
              </Typography>
              <div className={classes.positionText}>
                <div className={classes.spacingFirtsTextSubtitle}>
                  <Typography className={classes.subTitle}>EN:</Typography>
                </div>
                <div className={classes.spacingSecondTextInformation}>
                  <div className={classes.titleAlls}>
                    <Typography className={classes.textMeaning}>
                      EN COLA
                    </Typography>
                    <ShoppingCartTwoToneIcon />
                  </div>
                </div>
              </div>
              <div className={classes.positionText}>
                <div className={classes.spacingFirtsTextSubtitle}>
                  <Typography className={classes.subTitle}>PA:</Typography>
                </div>
                <div className={classes.spacingSecondTextInformation}>
                  <div className={classes.titleAlls}>
                    <Typography className={classes.textMeaning}>
                      PILOTO ASIGNADO
                    </Typography>
                    <SportsMotorsportsSharpIcon />
                  </div>
                </div>
              </div>
              <div className={classes.positionText}>
                <div className={classes.spacingFirtsTextSubtitle}>
                  <Typography className={classes.subTitle}>PR:</Typography>
                </div>
                <div className={classes.spacingSecondTextInformation}>
                  <div className={classes.titleAlls}>
                    <Typography className={classes.textMeaning}>
                      PILOTO RECIBIENDO
                    </Typography>
                    <ReceiptLongOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className={classes.positionText}>
                <div className={classes.spacingFirtsTextSubtitle}>
                  <Typography className={classes.subTitle}>ER:</Typography>
                </div>
                <div className={classes.spacingSecondTextInformation}>
                  <div className={classes.titleOe}>
                    <Typography className={classes.textMeaning}>
                      EN RUTA
                    </Typography>
                    <RoomSharpIcon />
                  </div>
                </div>
              </div>
              <div className={classes.positionText}>
                <div className={classes.spacingFirtsTextSubtitle}>
                  <Typography className={classes.subTitle}>OE:</Typography>
                </div>
                <div className={classes.spacingSecondTextInformation}>
                  <div className={classes.titleOe}>
                    <Typography className={classes.textMeaning}>
                        {" "} ORDEN ENTREGADA
                    </Typography>
                    <ShoppingBagSharpIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "50%",
              margin: "5px",
              padding: "5px",
            }}
          >
            <Typography className={classes.textTitle}>
              {" "}
              PROCESOS DE LA ORDEN REALIZADOS
            </Typography>
            <div className={classes.spacingSecondTextInformation}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "center",
                }}
              >
                <DangerousSharpIcon
                  style={{ color: "red", width: "30px", height: "auto" }}
                />
                <Typography
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {"NO REALIZADO"}
                </Typography>
              </div>
            </div>
            <div className={classes.spacingSecondTextInformation}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "center",
                }}
              >
                <CheckCircleSharpIcon
                  style={{ color: "green", width: "30px", height: "auto" }}
                />
                <Typography
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {"REALIZADO"}
                </Typography>
              </div>
            </div>
          </div>
        </Box>
      </div>
    )
}
const cellRenderEc = ({ data }) => {
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        {data.stateTime["En cola"] === undefined ||
          data.stateTime["En cola"] === null ? (
          <Tooltip title="EN: NO REALIZADO">
            <DangerousSharpIcon
              style={{ color: "red", width: "25px", height: "auto" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="EN: REALIZADO">
            <CheckCircleSharpIcon
              style={{ color: "green", width: "25px", height: "auto" }}
            />
          </Tooltip>
        )}
      </Grid>
    );
};

const headerCellComponentEN = () => {
    return (
      <Tooltip title="EN COLA">
        <ShoppingCartTwoToneIcon
          style={{ color: "#F88B1E", width: "25px", height: "auto" }}
        />
      </Tooltip>
    );
  };


  const cellRenderPa = ({ data }) => {
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        {data.stateTime["Asignado"] === undefined ||
          data.stateTime["Asignado"] === null ? (
          <Tooltip title="PA: NO REALIZADO">
            <DangerousSharpIcon
              style={{ color: "red", width: "25px", height: "auto" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="PA: REALIZADO">
            <CheckCircleSharpIcon
              style={{ color: "green", width: "25px", height: "auto" }}
            />
          </Tooltip>
        )}
      </Grid>
    );
  };


  const headerCellComponentPA = () => {
    return (
      <Tooltip title="PILOTO ASIGNADO">
        <SportsMotorsportsSharpIcon
          style={{ color: "#F88B1E", width: "25px", height: "auto" }}
        />
      </Tooltip>
    );
  };

  const cellRenderPr = ({ data }) => {
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        {data.stateTime["Recibiendo"] === undefined ||
          data.stateTime["Recibiendo"] === null ? (
          <Tooltip title="PR: REALIZADO">
            <DangerousSharpIcon
              style={{ color: "red", width: "25px", height: "auto" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="PR: REALIZADO">
            <CheckCircleSharpIcon
              style={{ color: "green", width: "25px", height: "auto" }}
            />
          </Tooltip>
        )}
      </Grid>
    );
  };

  const headerCellComponentPR = () => {
    return (
      <Tooltip title="RECIBIENDO">
        <ReceiptLongOutlinedIcon
          style={{ color: "#F88B1E", width: "25px", height: "auto" }}
        />
      </Tooltip>
    );
  };


  

  const cellRenderEr = ({ data }) => {
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        {data.stateTime["En ruta"] === undefined ||
          data.stateTime["En ruta"] === null ? (
          <Tooltip title="ER: NO REALIZADO">
            <DangerousSharpIcon
              style={{ color: "red", width: "25px", height: "auto" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="ER: REALIZADO">
            <CheckCircleSharpIcon
              style={{ color: "green", width: "25px", height: "auto" }}
            />
          </Tooltip>
        )}
      </Grid>
    );
  };


  const headerCellComponentER = () => {
    return (
      <Tooltip title="EN RUTA">
        <RoomSharpIcon
          style={{ color: "#F88B1E", width: "25px", height: "auto" }}
        />
      </Tooltip>
    );
  };

  const cellRenderOe = ({ data }) => {
    return (
      <Grid
        container
        direction="column"
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        {data.stateTime.Entregado === undefined ||
          data.stateTime.Entregado === null ? (
          <Tooltip title="OE: NO RELIZADO">
            <DangerousSharpIcon
              style={{ color: "red", width: "25px", height: "auto" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="OE: RELIZADO">
            <CheckCircleSharpIcon
              style={{ color: "green", width: "25px", height: "auto" }}
            />
          </Tooltip>
        )}
      </Grid>
    );
  };

  const headerCellComponentOE = () => {
    return (
      <Tooltip title="ENTREGADO">
        <ShoppingBagSharpIcon
          style={{ color: "#F88B1E", width: "25px", height: "auto" }}
        />
      </Tooltip>
    );
  };

export {
    cellRenderEc,
    headerCellComponentEN,
    cellRenderPa,
    headerCellComponentPA,
    headerCellComponentPR,
    cellRenderPr,
    cellRenderEr,
    headerCellComponentER,
    cellRenderOe,    
    headerCellComponentOE,
    FormPrincipal
}