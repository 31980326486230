import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../../containers/navs/breadcrumb";
import {
  BarChartComponent,
  LineChartComponent,
  PieChartComponent,
  MonthlyBarChart,
} from "../../containers/charts";
import { ResumeCard } from "../../containers/cards";
import { Grid, Box, Paper, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Directions, AssignmentTurnedIn, Close } from "@material-ui/icons";
import * as selectors from "../../logic/selectors/bikers";
import * as orderSelectors from "../../logic/selectors/orders";
import * as actions from "../../logic/actions/pilots";
import * as companyActions from "../../logic/actions/company";
import * as orderActions from "../../logic/actions/orders";
import * as branchSelectors from "../../logic/selectors/branch";
import * as branchActions from "../../logic/actions/branches";
import { useDispatch, useSelector } from "react-redux";
import { orderStatus, orderAlert } from "../../constants/constants";
import { store } from "../App";
import { getCompanies } from "../../logic/selectors/company";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

const arrayStatus = [
  orderStatus.NEW,
  orderStatus.GOING,
  orderStatus.ON_HOLD,
  orderStatus.PENDING,
  orderStatus.ACCEPTED,
  orderStatus.ASSIGNED,
  orderStatus.CANCELLED,
  orderStatus.DELIVERED,
  orderStatus.RECEIVING,
];
const useStyles = makeStyles((theme) => ({
  graphBody: {
    alignItems: "center",
    display: "flex",
    width: "97%",
    height: "auto",
  },
  content: {
    margin: "auto",
    textAlign: "center",
    height: 300,
  },
}));
const CompanyResumeView = () => {
  const classes = useStyles();
  const history = useHistory();
  const bikerList = useSelector(selectors.getBikersByStatus);
  const fetchAllOrders = useSelector(orderSelectors.allOrders);
  // console.info('CompanyResumeView ---> fetchAllorders',fetchAllOrders)
  // const [pilotsWithOrders,setPilotsWithOrders] = useState([]);
  // const [companiesWithOrders, setCompaniesWithOrders] = useState([]);
  const [branchesWithOrders, setBranchesWithOrders] = useState([]);
  // const companyList = useSelector(companySelectors.getCompanies);
  const branchList = useSelector(branchSelectors.getBranches);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bikerList.length === 0) {
      dispatch(actions.pilotActions.fetchPilotsByStatusStarted("ACTIVE"));
    }
    dispatch(companyActions.default.getCompanies());
    dispatch(branchActions.branchActions.getBranches());
    dispatch(
      orderActions.startFetchingOrders({
        statusFilter: [
          orderStatus.GOING,
          orderStatus.ON_HOLD,
          orderStatus.PENDING,
          orderStatus.ACCEPTED,
          orderStatus.ASSIGNED,
          orderStatus.RECEIVING,
          orderStatus.DELIVERED,
          orderStatus.CANCELLED,
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companies = useSelector(getCompanies);
  useEffect(() => {
    if (branchList !== undefined)
      setBranchesWithOrders(
        orderSelectors.branchesWithOrders(store.getState(), branchList)
      );
  }, [branchList]);

  // useEffect(()=>{
  //     setCompaniesWithOrders(orderSelectors.companiesWithOrders(store.getState(),companyList))
  //     setPilotsWithOrders(orderSelectors.pilotsWithOrders(store.getState(),bikerList))
  // },[fetchAllOrders])
  return (
    <Fragment>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RESÚMEN DE HOY"
      />
      {companies.length === 0 ? (
        <Grid container direction="column" alignContent="center" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
              <CircularProgress style={{ color: "orange" }} />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <div>
          {companies[0].assistance !== undefined &&
            companies[0].assistance === true ? (
            <iframe
              width="100%"
              height="800px"
              src={companies[0].dataStudio}
              frameborder="0"
              style={{
                border: 0,
                fontFamily: "Oswald, sans-serif",
                fontSize: "15px",
              }}
              allowfullscreen
              title="POLLO BRUJO"
            />
          ) : (
            <Grid container spacing={2}>
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={12} md={6} sm={6} lg={4}>
                  <ResumeCard
                    title="ORDENES ENTREGADAS"
                    count={
                      fetchAllOrders.filter(
                        (element) => element.status === orderStatus.DELIVERED
                      ).length
                    }
                    Icon={AssignmentTurnedIn}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={4}>
                  <ResumeCard
                    title="ORDENES EN RUTA"
                    count={
                      fetchAllOrders.filter(
                        (element) => element.status === orderStatus.GOING
                      ).length
                    }
                    Icon={Directions}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={4}>
                  <ResumeCard
                    title="ORDENES CANCELADAS"
                    count={
                      fetchAllOrders.filter(
                        (element) => element.status === orderStatus.CANCELLED
                      ).length
                    }
                    Icon={Close}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} spacing={1}>
                <Paper>
                  <Box padding={1} className={classes.graphBody}>
                    <div className={classes.content}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color="textSecondary"
                      >
                        TIEMPOS DE ENTREGA
                      </Typography>
                      <PieChartComponent
                        data={[
                          {
                            name: "0-20 MINS",
                            value: fetchAllOrders.filter(
                              (order) => order.alert === orderAlert.ON_TIME
                            ).length,
                          },
                          {
                            name: "20-45 MINS",
                            value: fetchAllOrders.filter(
                              (order) => order.alert === orderAlert.VERIFY
                            ).length,
                          },
                          {
                            name: "MÁS DE 45 MINS",
                            value: fetchAllOrders.filter(
                              (order) =>
                                order.alert === orderAlert.LATE ||
                                order.alert === orderAlert.TO_BE_LATE
                            ).length,
                          },
                        ]}
                      />
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} spacing={2} lg={8}>
                <Paper>
                  <Box padding={1} className={classes.graphBody}>
                    <div style={{ width: "100%", height: 300, padding: 10 }}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color="textSecondary"
                      >
                        ESTADOS DE ENTREGA
                      </Typography>
                      <br></br>
                      <div style={{ height: "80%" }}>
                        <BarChartComponent
                          data={arrayStatus.map((key) => {
                            return {
                              name: key,
                              ordenes: fetchAllOrders.filter(
                                (element) => element.status === key
                              ).length,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Paper>
                  <Box padding={1} className={classes.graphBody}>
                    <div style={{ width: "100%", height: 300, padding: 10 }}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color="textSecondary"
                      >
                        ENTREGAS MENSUALES
                      </Typography>
                      <br></br>
                      <div style={{ height: "80%" }}>
                        <MonthlyBarChart
                          data={Array.from(new Array(31)).map((item, index) => {
                            return {
                              name: `día\n ${index + 1}`,
                              entregas: fetchAllOrders.filter((element) => {

                                let date = new Date(
                                  element.createdAt.seconds * 1000
                                );
                                return (
                                  date.getDate() === index + 1 &&
                                  element.status !== orderStatus.CANCELLED 
                                  && element.status !== orderStatus.ON_HOLD
                                  && element.status !== orderStatus.NEW
                                  && element.status !== orderStatus.GOING
                                  && element.status !== orderStatus.PENDING
                                  && element.status !== orderStatus.ACCEPTED
                                  && element.status !== orderStatus.ASSIGNED
                                  && element.status !== orderStatus.CANCELLED
                                  && element.status !== orderStatus.RECEIVING
                                );
                              }).length,
                             
                            };
                          })}
                        />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Paper>
                  <Box padding={1} className={classes.graphBody}>
                    <div style={{ width: "100%", height: 300, padding: 10 }}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color="textSecondary"
                      >
                        ENTREGAS DIARIAS POR SUCURSAL
                      </Typography>
                      <br></br>
                      <div style={{ height: "80%" }}>
                        <LineChartComponent
                          data={branchesWithOrders.map((element) => {
                            return {
                              name: element.name,
                              Entregado: fetchAllOrders.filter(
                                (value) =>
                                  value.branch.id === element.branchId &&
                                  value.status === orderStatus.DELIVERED
                              ).length,
                              Pendiente: fetchAllOrders.filter(
                                (value) =>
                                  value.branch.id === element.branchId &&
                                  value.status !== orderStatus.DELIVERED &&
                                  value.status !== orderStatus.CANCELLED
                              ).length,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </Fragment>
  );
};
export default CompanyResumeView;
