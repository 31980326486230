import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import isEmpty from "lodash/isEmpty";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#F88B1E",
    backgroundColor: "#000",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const DataTable = ({
  headers,
  rows,
  align = "center",
  usePagination = false,
  pageSize = 15,
}) => {
  const [filters, setFilters] = useState({});
  const [filteredOrders, setFilteredOrders] = useState();
  const [pagination, setPagination] = useState({
    data: [],
    currentPage: 1,
    pageCount: 0,
  });

  const getData = (data, page) => {
    let results = [];
    let startIndex = 0;
    if (page > 1) {
      startIndex = (page - 1) * pageSize;
    }
    for (let i = startIndex; i < data.length; i++) {
      results = results.concat([
        {
          ...data[i],
        },
      ]);
      if (i === page * pageSize - 1) {
        break;
      }
    }
    return results;
  };

  useEffect(() => {
    setFilteredOrders(rows);
  }, [rows]);

  useEffect(() => {
    if (!isEmpty(filteredOrders)) {
      const pageCount = Math.ceil(filteredOrders.length / pageSize);
      setPagination((val) => ({
        ...val,
        data: usePagination
          ? getData(filteredOrders, pagination.currentPage)
          : filteredOrders,
        pageCount,
      }));
    } else {
      setPagination((val) => ({
        ...val,
        data: [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOrders]);

  const getColumnText = (row, header) => {
    if (!isEmpty(header.nestedId)) {
      let response = row;
      try {
        header.nestedId.forEach((item) => {
          response = response[item];
        });
      } catch (e) {
        response = "";
      }
      return response;
    }
    return row[header.id];
  };

  const handleChangePage = (e, page) => {
    setPagination((val) => ({
      ...val,
      data: getData(filteredOrders, page),
      currentPage: page,
    }));
  };

  const onFilterChanged = (text, header) => {
    const filtersToApply = {
      ...filters,
      [`${header.id}`]: text,
    };
    applyFilters(filtersToApply);
  };

  const applyFilters = (filtersToApply) => {
    let customOrdres = [...rows];
    Object.keys(filtersToApply).forEach((key) => {
      if (!isEmpty(filtersToApply[key])) {
        customOrdres = customOrdres.filter((order) =>
          (order[key] || "")
            .toUpperCase()
            .startsWith((filtersToApply[key] || "").toUpperCase())
        );
      }
    });
    setFilteredOrders(customOrdres);
    setFilters(filtersToApply);
  };

  const getFilterRow = () => {
    const shouldFilter = Boolean(headers.find((header) => header.filter));
    if (!shouldFilter) {
      return <></>;
    }
    return (
      <TableRow>
        {headers.map((header) => (
          <StyledTableCell
            {...header.style}
            key={`filter-${header.id}`}
            align={align}
          >
            {header.filter ? (
              <TextField
                value={filters[header.id]}
                placeholder={header.name}
                onChange={(e) => onFilterChanged(e.target.value, header)}
              />
            ) : (
              <></>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table
        style={{
          textTransform: "uppercase",
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <StyledTableCell
                {...header.style}
                key={`${uuidv4()}-header-${header.id}`}
                align={align}
              >
                {`${header.name}`.toUpperCase()}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getFilterRow()}
          {!isEmpty(pagination.data) ? (
            pagination.data.map((row, i) => (
              <TableRow key={`${uuidv4()}-row-${row.id}`}>
                {headers.map((header) => (
                  <TableCell
                    key={`${uuidv4()}-row-cell-${header.id}-${row.id}`}
                    align="left"
                  >
                    {getColumnText(row, header)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={headers.length}>
                NO HAY INFORMACIÓN PARA MOSTRAR
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {usePagination && (
          <TableFooter>
            <TableCell colSpan={headers.length}>
              <Pagination
                count={pagination.pageCount}
                color="primary"
                onChange={handleChangePage}
              />
            </TableCell>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataTable;
