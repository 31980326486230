import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import { orderActions } from "../../logic/actions/orders";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import {
  snackBarConst,
  PROVIDER_TYPE,
  INPUT_TYPE,
  orderStatus,
} from "../../constants/constants";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";
import {
  getCreateOrderDone,
  getCreateOrderError,
  getCreateOrderIsLoading,
} from "../../logic/selectors/orders";
import { getAuthEmail,getController } from '../../logic/reducers';

const validationSchema = yup.object({
  orderId: yup.string().required("Orden es requerido"),
  address: yup.string().required("Direccion es requerida"),
  name: yup.string().required("Cliente es requerido"),
});



export default ({ onModalClose, branchData }) => {
const formFields = [
    {
        label: 'ORIGEN',
        name: 'branch_id',
        cssClasses: "col-12",
        inputType: INPUT_TYPE.FILTERABLE_LIST,
        fullWidth: true, 
        options: 
            branchData.map((values)=> ({
                value: values.branchId,
                text: values.name
            }))
    },
    {
        label: 'DESTINO',
        name: 'address',
        cssClasses: "col-12",
        inputType: INPUT_TYPE.FILTERABLE_LIST,
        fullWidth: true, 
        options: 
            branchData.map((values)=> ({
                value: values.branchId,
                text: values.name
            }))
    },
    {
        label: "NUMERO DE TRASLADO",
        name: "orderId",
        cssClasses: "col-12",
        fullWidth: true,
    },
    {
        label: "SOLICITANTE DE TRASLADO",
        name: "name",
        cssClasses: "col-12",
        fullWidth: true,
    },
    {
        label: "Teléfono",
        name: "phone",
        cssClasses: "col-12",
        fullWidth: true,
        type: "number",
    },
    {
        label: "INDICACIONES DE TRASLADO",
        name: "nota",
        cssClasses: "col-12",
        fullWidth: true,
    },
  
    ];
 
  // const idGenerator = new uidgen();
  const dispatch = useDispatch();
  const createOrderDone = useSelector(getCreateOrderDone);
  const createOrderError = useSelector(getCreateOrderError);
  const email = useSelector(getAuthEmail);
  const controller = useSelector(getController);
  const isLoadingcreateOrder = useSelector(getCreateOrderIsLoading);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState({
    company_id: branchData[0].company,
    creatorOrder:email,
    creatorId:controller.id,
    orderId: "",
    address: "",
    nota: "",
    name: "",
    phone: "",
    typeOrder: "TRASLADO",
  });

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "CREAR TRASLADO",
    },
  ];
  // is modal create order
  useOnCreatingOrder(
    createOrderDone,
    createOrderError,
    isLoadingcreateOrder,
    dispatch,
    enqueueSnackbar,
    onModalClose
  );

  const onSubmit = (values) => {
    const orderData = {
      creatorOrder:email,
      creatorId:controller.id,
      name: values.name,
      company_id: branchData[0].companyId,
      typeOrder: values.typeOrder,
      branch_id: values.branch_id.value,
      address: values.address.text,
      phone: values.phone,
      orderId: values.orderId,
      nota: values.nota,
      provider: PROVIDER_TYPE.BRANCH,
      status: orderStatus.ON_HOLD,
      without_biker: "Yes",
    };
    dispatch(orderActions.createOrder(orderData));
  };

  return (
    <div className="modal-container modal-container-create-order">
    <div className="modal-title">
        <div className="modal-title-text">
          <CompareArrowsIcon style = { 
            {
              width: '50px',
              height: 'auto'
            }
          }/>
        </div>
    </div>
      <div className="modal-body all-upper-case">
        <BlockUi tag="div" blocking={isLoadingcreateOrder}>
          <DynamicForm
            obj={order}
            fields={formFields}
            validationSchema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            setState={setOrder}
            btnCssClass="modal-footer"
            forceSetState
          />
        </BlockUi>
      </div>
    </div>
  );
};

const useOnCreatingOrder = (
  createOrderDone,
  createOrderError,
  isLoadingcreateOrder,
  dispatch,
  enqueueSnackbar,
  onModalClose
) => {
  useEffect(() => {
    if (!isLoadingcreateOrder) {
      if (createOrderDone) {
        enqueueSnackbar("Orden creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearCreateOrder());
        onModalClose();
      } else if (!isEmpty(createOrderError)) {
        enqueueSnackbar(
          "Hubo un error al crear la orden, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderDone, createOrderError, isLoadingcreateOrder]);
};
