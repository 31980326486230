import { firebaseFirestore } from "./config";

export const getZone = async (id) => {
  try {
    const response = await firebaseFirestore.collection("zone").get();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

export const thieftAlert = async (callback) => {
  try {
    let day = new Date();
    let year = day.getFullYear();
    let date = day.getDate();

    let query = await firebaseFirestore
      .collection("robo")
      .doc(`${year}`)
      .collection(`${date}`);

    query.onSnapshot((snapshot) => {
      const alerts = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        data.description = "Robo";
        alerts.push(data);
      });
      callback(alerts);
    });
  } catch (error) {
    console.error(`Function thieftAlert: ${error.message}`);
  }
};

export const extorsionAlert = async (callback) => {
  try {
    let day = new Date();
    let year = day.getFullYear();
    let date = day.getDate();

    let query = await firebaseFirestore
      .collection("extorsion")
      .doc(`${year}`)
      .collection(`${date}`);

    query.onSnapshot((snapshot) => {
      const alerts = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        data.description = "Extorsion";
        alerts.push(data);
      });
      callback(alerts);
    });
  } catch (error) {
    console.error(`Function thieftAlert: ${error.message}`);
  }
};