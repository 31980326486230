/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import queryString from "query-string";
import theme from "../../assets/theme";
import Map from "../Map/Map";
import dateHelper from "../../helpers/dateHelper";
import * as selectors from "../../logic/reducers";
import * as branchActions from "../../logic/actions/branches";
import * as pilotActions from "../../logic/actions/pilots";
import * as orderActions from "../../logic/actions/orders";
import isEmpty from "lodash/isEmpty";
import emptyAlert from "../../assets/img/empty_alerts.png";
import bikerHelper from "../../helpers/bikerHelper";
import {
  DELIVERY_ICON,
  BIKER_ICON,
  BRANCH_ICON,
  BRANCH_ALERTS,
  HOME_CUSTOMER,
  AVERAGE_TIME,
  INCIDENTS_IMG,
  bikerAlert,
  orderAlert,
  orderStatus,
  BIKER_STATUS_OPTIONS,
} from "../../constants/constants";
import { FormControlLabel, Switch } from "@mui/material";

const Alerts = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const branches = useSelector(selectors.getBranchOffices);
  const bikers = useSelector(selectors.getPilots);
  const allOrders = useSelector(selectors.getOrders);
  const [branchesMarkers, setBranchesMarkers] = useState([]);
  const [ordersMarkers, setOrdersMarkers] = useState([]);
  const [bikersMarkers, setBikersMarkers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [abandonedOrders, setAbandonedOrders] = useState([]);
  // Alerts Data
  const [nonBikerOrders, setNonBikerOrders] = useState({});
  const [bikersWithAlerts, setBikersWithAlerts] = useState([]);
  const [averageTime, setAverageTime] = useState("00:00:00");
  const [height, setHeight] = useState(750);
  const [filteredBikersMarkers, setFilteredBikersMarkers] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [center] = useState({ lat: 14.587461, lng: -90.514048 });

  const [checked, setChecked] = useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  
  useEffect(() => {
    const values = queryString.parse(search);
    const startedFilters = {
      branchId: values.sucursal,
      isActive: "Y",
      statusFilter: [
        orderStatus.DELIVERED,
        orderStatus.GOING,
        orderStatus.ACCEPTED,
        orderStatus.ON_HOLD,
        orderStatus.PENDING,
        orderStatus.CANCELLED,
      ],
      comodines: true,
    };
    dispatch(branchActions.clearBranchOffices());
    dispatch(orderActions.clearOrderSubscription());
    dispatch(pilotActions.clearPilotsSubscription());
    dispatch(branchActions.startFetchingBranchOffices(startedFilters));
    dispatch(pilotActions.startFetchingPilots(startedFilters));
    dispatch(orderActions.startFetchingOrders(startedFilters));
    if (window.innerHeight > 750) {
      setHeight(window.innerHeight - 64);
    }
  }, []);

  const onMarkerClick = (fn, id) => {
    fn((val) => {
      if (isEmpty(val)) {
        return val;
      }
      const markerIndex = val.findIndex((item) => item.id === id);
      if (markerIndex >= 0) {
        val[markerIndex] = {
          ...val[markerIndex],
          showInfoWindow: !val[markerIndex].showInfoWindow,
        };
      }
      return [...val];
    });
  };

  const getBranchAlert = (alerts) => {
    return alerts.map((alert, i) => (
      <>
        <div key={`alert-${i}`} className="branch-alerts-item-title">
          <Typography variant="body">{alert.name}</Typography>
        </div>
        <div className="branch-alerts-item-img">
          <img src={BRANCH_ALERTS} alt={`Alerts ${alert.orders}`} />
          <span>
            <Typography variant="subtitle1">{alert.orders}</Typography>
          </span>
        </div>
      </>
    ));
  };

  const getCompanyAlerts = () => {
    let response = {};
    Object.keys(nonBikerOrders).forEach((key) => {
      response[nonBikerOrders[key].company] = [
        ...(response[nonBikerOrders[key].company] || []),
        {
          ...nonBikerOrders[key],
        },
      ];
    });
    return Object.keys(response).map((key) => (
      <div key={key} className="branch-alerts-item">
        <div className="branch-alerts-item-title">
          <Typography variant="subtitle1">{key.toUpperCase()}</Typography>
        </div>
        {getBranchAlert(response[key])}
      </div>
    ));
  };

  useEffect(() => {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(1);
    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);
    setOrders(
      allOrders.filter((order) => order.status !== orderStatus.CANCELLED)
    );
    setAbandonedOrders(
      allOrders.filter((order) => {
        const orderTimestamp = new Date(
          order.createdAt.seconds * 1000
        ).getTime();
        return (
          !isNaN(parseInt(order.time)) &&
          parseInt(order.time) / 3600 >= 2 &&
          order.status !== orderStatus.DELIVERED &&
          orderTimestamp > startDay.getTime() &&
          orderTimestamp < endDay.getTime()
        );
      })
    );
  }, [allOrders]);

  useSubscribeOrders(
    orders,
    setOrdersMarkers,
    setNonBikerOrders,
    onMarkerClick,
    setAverageTime,
    checked
  );
  useSubscribeBranches(orders, branches, setBranchesMarkers, onMarkerClick);
  useSubscribeBikers(
    bikers,
    setBikersWithAlerts,
    onMarkerClick,
    setBikersMarkers,
    setFilteredBikersMarkers,
    checked
  );

  return (
    <ThemeProvider theme={theme}>
      {/* <NavBar title="mapa" /> */}
      <div className="position-relative">
        <div
          className="branch-alerts"
          style={{
            height: `${height - 195}px`,
          }}
        >
          <div className="branch-alert-title branch-restaurant">
            <div className="branch-alert-img">
              <img src={BRANCH_ICON} alt="Restaurante" />
            </div>
            <div className="branch-alert-text">
              <Typography variant="subtitle1">EMPRESA</Typography>
            </div>
          </div>
          <div>
            {Object.keys(nonBikerOrders).length > 0 ? (
              <div>{getCompanyAlerts()}</div>
            ) : (
              <EmptyView />
            )}
          </div>
        </div>
        <div className="all-alerts">
          <div className="position-relative">
            <div className="all-alerts-item available-bikers">
              <div className="all-alerts-container">
                <div className="position-relative">
                  <div className="branch-alerts-bottom-img">
                    <img src={BIKER_ICON} alt="2Wheeler" />
                  </div>
                  <div className="branch-alerts-bottom-title">
                    <Typography variant="subtitle1">
                      MOTORISTAS DISPONIBLES
                    </Typography>
                  </div>
                  <div className="branch-alerts-bottom-text">
                    <h1>
                      {
                        bikers.filter(
                          (biker) =>
                            (biker.status || "").toLowerCase() ===
                            BIKER_STATUS_OPTIONS.AVAILABLE.value.toLowerCase()
                        ).length
                      }
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-alerts-item orders-to-attend">
              <div className="all-alerts-container">
                <div className="position-relative">
                  <div className="branch-alerts-bottom-img">
                    <img src={BRANCH_ALERTS} alt="Ordenes por Atender" />
                  </div>
                  <div className="branch-alerts-bottom-title black">
                    <Typography variant="subtitle1">
                      ORDENES POR ATENDER
                    </Typography>
                  </div>
                  <div className="branch-alerts-bottom-text">
                    <h1>
                      {Object.keys(nonBikerOrders)
                        .map((key) => nonBikerOrders[key].orders)
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        )}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-alerts-item orders-to-be-late">
              <div className="all-alerts-container">
                <div className="position-relative">
                  <div className="branch-alerts-bottom-img orders-to-be-late-img">
                    <img src={HOME_CUSTOMER} alt="Ordenes por llegar tarde" />
                  </div>
                  <div className="branch-alerts-bottom-title">
                    <Typography variant="subtitle1">
                      ORDENES POR LLEGAR TARDE
                    </Typography>
                  </div>
                  <div className="branch-alerts-bottom-text">
                    <h1>
                      {
                        orders.filter(
                          (order) =>
                            order.status === orderStatus.GOING &&
                            (order.alert === orderAlert.TO_BE_LATE ||
                              order.alert === orderAlert.LATE)
                        ).length
                      }
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-alerts-item incidents">
              <div className="all-alerts-container">
                <div className="position-relative">
                  <div className="branch-alerts-bottom-img">
                    <img src={INCIDENTS_IMG} alt="Incidentes" />
                  </div>
                  <div className="branch-alerts-bottom-title">
                    <Typography variant="subtitle1">INCIDENTES</Typography>
                  </div>
                  <div className="branch-alerts-bottom-text">
                    <h1>{bikersWithAlerts.length}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-alerts-item average-delivery-time">
              <div className="all-alerts-container">
                <div className="position-relative">
                  <div className="branch-alerts-bottom-img">
                    <img src={AVERAGE_TIME} alt="Tiempo Promedio de Entrega" />
                  </div>
                  <div className="branch-alerts-bottom-title black">
                    <Typography variant="subtitle1">
                      TIEMPO PROMEDIO DE ENTREGAS
                    </Typography>
                  </div>
                  <div className="branch-alerts-bottom-text right-5">
                    <h1>{averageTime}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="twowheelers-alerts"
          style={{
            height: `${height - 195}px`,
          }}
        >
          <div className="branch-alert-title branch-2wheeler">
            <div className="branch-alert-img">
              <img src={BIKER_ICON} alt="2Wheeler" />
            </div>
            <div className="branch-alert-text">
              <Typography variant="subtitle1">2WHEELERS</Typography>
              <FormControlLabel
                control={
                  <Switch
                    name="Proyectos"
                    checked={checked}
                    onChange={handleChange}
                    color="warning"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={checked ? "PROYECTOS" : "POLLO BRUJO"}
              />
            </div>
          </div>
          <div>
            {bikersWithAlerts.length > 0 ? (
              checked ? (
                bikersWithAlerts
                  .filter((biker) => biker.Pertenece === "PROYECTOS")
                  .map((biker) => {
                    return (
                      <div key={biker.id} className="branch-alerts-item">
                        <div className="branch-alerts-item-title">
                          <Typography variant="subtitle1">
                            {biker.name}
                          </Typography>
                        </div>
                        <div className="branch-alerts-item-img">
                          <img src={biker.img} alt="Alert" />
                        </div>
                      </div>
                    );
                  })
              ) : (
                bikersWithAlerts
                  .filter((biker) => biker.Pertenece === "POLLO BRUJO")
                  .map((biker) => {
                    return (
                      <div key={biker.id} className="branch-alerts-item">
                        <div className="branch-alerts-item-title">
                          <Typography variant="subtitle1">
                            {biker.name}
                          </Typography>
                        </div>
                        <div className="branch-alerts-item-img">
                          <img src={biker.img} alt="Alert" />
                        </div>
                      </div>
                    );
                  })
              )
            ) : (
              <EmptyView />
            )}
          </div>
        </div>
        {/* {!isEmpty(controller) && controller.role === ROLES.CONTROLLER && (
          <div className="alert-abandoned-orders">
            <Typography variant="subtitle1">ORDENES ABANDONADAS</Typography>
            <Typography variant="subtitle1">
              {(abandonedOrders || []).length}
            </Typography>
          </div>
        )} */}
        <Map
          markers={[
            ...filteredBikersMarkers,
            ...ordersMarkers,
            ...branchesMarkers,
          ]}
          center={center}
          zoom={zoom}
          setZoom={setZoom}
          height={`${height}px`}
        />
      </div>
    </ThemeProvider>
  );
};

const getNonBikerOrders = (orders) => {
  const nonBikerOrders = {};
  (orders || []).forEach((order) => {
    if (
      isEmpty(order.alias) ||
      (!isEmpty(order.alias) &&
        (isEmpty(order.alias.id) || isEmpty(order.alias.name)))
    ) {
      if (isEmpty(nonBikerOrders[order.branch.id])) {
        nonBikerOrders[order.branch.id] = {
          ...order.branch,
          company: !isEmpty(order.company) ? order.company.name : "",
          orders: 1,
        };
      } else {
        nonBikerOrders[order.branch.id] = {
          ...nonBikerOrders[order.branch.id],
          orders: nonBikerOrders[order.branch.id].orders + 1,
        };
      }
    }
  });
  return nonBikerOrders;
};

const useSubscribeOrders = (
  orders,
  setOrdersMarkers,
  setNonBikerOrders,
  onMarkerClick,
  setAverageTime,
  checked
) => {
  useEffect(() => {
    if (!isEmpty(orders)) {
      let averageTime = 0;
      orders
        .filter((order) => order.status === orderStatus.DELIVERED)
        .forEach((order) => {
          if (!isEmpty(order.deliveredAt) && !isEmpty(order.createdAt)) {
            averageTime += order.deliveredAt.seconds - order.createdAt.seconds;
          }
        });
      averageTime =
        averageTime /
        orders.filter((order) => order.status === orderStatus.DELIVERED).length;
      if (
        averageTime !== null &&
        averageTime !== undefined &&
        !isNaN(averageTime)
      ) {
        setAverageTime(dateHelper.secondsToHms(averageTime));
      }
      setNonBikerOrders(getNonBikerOrders(orders));
      setOrdersMarkers(
        orders
          .filter(
            (order) =>
              !isEmpty(order.locationRef) &&
              order.status === orderStatus.GOING &&
              (order.alert === orderAlert.TO_BE_LATE ||
                order.alert === orderAlert.LATE)
          )
          .map((order) => ({
            id: order.id,
            icon: DELIVERY_ICON,
            position: {
              latitude: order.locationRef.latitude,
              longitude: order.locationRef.longitude,
            },
            showInfoWindow: false,
            infoWindow: (
              <>
                <Typography variant="subtitle1">
                  {`ID ORDEN: ${order.orderId}`}
                </Typography>
                <Divider />
                <Typography variant="body">{`CLIENTE: ${order.name}`}</Typography>
                <Divider />
                <Typography variant="body">
                  {`AGENTE: ${order.alias.name}`}
                </Typography>
              </>
            ),
            onClick: () => {
              onMarkerClick(setOrdersMarkers, order.id);
            },
          }))
      );
    }
  }, [orders]);
};

const useSubscribeBranches = (
  orders,
  branches,
  setBranchesMarkers,
  onMarkerClick
) => {
  useEffect(() => {
    if (!isEmpty(branches) && !isEmpty(orders)) {
      let data = [];
      branches
        .filter(
          (branch) =>
            !isEmpty(branch) &&
            orders.find(
              (order) =>
                order.branch.id === branch.id &&
                (isEmpty(order.alias) ||
                  (!isEmpty(order.alias) &&
                    (isEmpty(order.alias.id) || isEmpty(order.alias.name))))
            )
        )
        .forEach((branch) => {
          if (!isEmpty(branch)) {
            data = data.concat([
              {
                id: branch.id,
                icon: BRANCH_ICON,
                position: {
                  latitude: branch.lat,
                  longitude: branch.lng,
                },
                showInfoWindow: false,
                infoWindow: (
                  <>
                    <Typography variant="subtitle1">{branch.name}</Typography>
                    <Divider />
                    <Typography variant="body">
                      {`COMPAÑÍA: ${branch.company}`}
                    </Typography>
                  </>
                ),
                onClick: () => {
                  onMarkerClick(setBranchesMarkers, branch.id);
                },
              },
            ]);
          }
        });
      setBranchesMarkers(data);
    } else {
      setBranchesMarkers([]);
    }
  }, [branches]);
};
const EmptyView = () => {
  return (
    <div
      style={{
        height: "600px",
        margin: 0,
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <img src={emptyAlert} width={200} alt="alerts"></img>
      <Typography variant="h5" component="h5">
        NO HAY INFORMACIÓN
      </Typography>
    </div>
  );
};

const useSubscribeBikers = (
  bikers,
  setBikersWithAlerts,
  onMarkerClick,
  setBikersMarkers,
  setFilteredBikersMarkers,
  checked
) => {
  useEffect(() => {
    if (!isEmpty(bikers)) {
      if (checked) {
        const bikersMarkers = bikers
          .filter(
            (biker) => biker.Pertenece === "PROYECTOS"
            // !isEmpty(biker.alert) &&
            // biker.alert.toUpperCase() !==
              // bikerAlert.NO_PROBLEMS.toUpperCase() 
            // biker.alert.toUpperCase() !== bikerAlert.ALL_OK.toUpperCase()
          )
          .map((biker, i) => ({
            type: biker.type,
            id: biker.id,
            isClusterer: true,
            icon: BIKER_ICON,
            position: {
              latitude: biker.actualPosition.latitude,
              longitude: biker.actualPosition.longitude,
            },
            showInfoWindow: false,
            infoWindow: (
              <>
                <Typography variant="subtitle1">
                  {`AGENTE: ${biker.name}`}
                </Typography>
                <Divider />
                {biker.type === "WILDCARD" ? (
                  <>
                    <Typography variant="body">{`COMODIN`}</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body">
                      {`COMPAÑIA: ${biker.company}`}
                    </Typography>
                    <Divider />
                    <Typography variant="body">
                      {`SUCURSAL: ${biker.SUCURSAL}`}
                    </Typography>
                  </>
                )}
                <Divider />
                <Typography variant="body">
                  {`PROBLEMA: ${biker.alert}`}
                </Typography>
              </>
            ),
            onClick: () => {
              onMarkerClick(setFilteredBikersMarkers, biker.id);
            },
          }));
        setBikersWithAlerts(bikerHelper.getBikersWithAlerts(bikers));
        setBikersMarkers(bikersMarkers);
        setFilteredBikersMarkers(bikersMarkers);
      } else if (!checked) {
        const bikersMarkers = bikers
          .filter(
            (biker) => biker.Pertenece === "POLLO BRUJO"
            // !isEmpty(biker.alert) &&
            // biker.alert.toUpperCase() !==
            //   bikerAlert.NO_PROBLEMS.toUpperCase() &&
            // biker.alert.toUpperCase() !== bikerAlert.ALL_OK.toUpperCase()
          )
          .map((biker, i) => ({
            type: biker.type,
            id: biker.id,
            isClusterer: true,
            icon: BIKER_ICON,
            position: {
              latitude: biker.actualPosition.latitude,
              longitude: biker.actualPosition.longitude,
            },
            showInfoWindow: false,
            infoWindow: (
              <>
                <Typography variant="subtitle1">
                  {`AGENTE: ${biker.name}`}
                </Typography>
                <Divider />
                {biker.type === "WILDCARD" ? (
                  <>
                    <Typography variant="body">{`COMODIN`}</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body">
                      {`COMPAÑIA: ${biker.company}`}
                    </Typography>
                    <Divider />
                    <Typography variant="body">
                      {`SUCURSAL: ${biker.SUCURSAL}`}
                    </Typography>
                  </>
                )}
                <Divider />
                <Typography variant="body">
                  {`PROBLEMA: ${biker.alert}`}
                </Typography>
              </>
            ),
            onClick: () => {
              onMarkerClick(setFilteredBikersMarkers, biker.id);
            },
          }));
        setBikersWithAlerts(bikerHelper.getBikersWithAlerts(bikers));
        setBikersMarkers(bikersMarkers);
        setFilteredBikersMarkers(bikersMarkers);
      }
    }
  }, [bikers]);
};

export default Alerts;
