import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
} from "@material-ui/core";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      maxLines: 1,
    },
  },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    maxLines: 1,
  },
}))(TableCell);
const RenderColumns = ({ columns }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((cell) => {
          return <TableCell align="left">{cell.name.toUpperCase()}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};
const RenderRows = ({ rows, columns }) => {
  return (
    <TableBody>
      {rows.map((element) => {
        return (
          <StyledTableRow hover={true} style={{ wordWrap: "break-word" }}>
            {columns.map((row) => {
              return (
                <StyledTableCell>
                  {element[row.value].toString().toUpperCase()}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
const SingleDenseTable = ({ rows, columns }) => {
  return (
    <TableContainer>
      <Table size="small" stickyHeader aria-label="sticky table">
        <RenderColumns columns={columns} />
        <RenderRows rows={rows} columns={columns} />
      </Table>
    </TableContainer>
  );
};
export default SingleDenseTable;
