import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import BranchChart from "./BranchChart";
import { orderStatus } from "../../constants/constants";

const BranchDay = ({ orders }) => {
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [goingOrders, setGoingOrders] = useState([]);
  const [toRestaurantOrders, setToRestaurantOrders] = useState([]);

  useEffect(() => {
    if (!isEmpty(orders)) {
      setDeliveredOrders(
        orders.filter((order) => order.status === orderStatus.DELIVERED)
      );
      setGoingOrders(
        orders.filter((order) => order.status === orderStatus.GOING)
      );
      setToRestaurantOrders(
        orders.filter((order) => order.status === orderStatus.ACCEPTED)
      );
    }
  }, [orders]);

  return (
    <div>
      <div className="col-12">
        <BranchChart
          data={[
            {
              label: `ENTREGADAS ${deliveredOrders.length}`,
              y: deliveredOrders.length,
              color: '#9BC53D',
            },
            {
              label: `EN RUTA ${goingOrders.length}`,
              y: goingOrders.length,
              color: '#FDE74C',
            },
            {
              label: `HACIA SUCURSAL ${toRestaurantOrders.length}`,
              y: toRestaurantOrders.length,
              color: '#5BC0EB',
            },
          ]}
          max={orders.length}
        />
      </div>
    </div>
  );
};

export default BranchDay;
