/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";
import CircularProcess from "@material-ui/core/CircularProgress";
import dateHelper from "../../helpers/dateHelper";
import Expandable from "../Design/Expandable";
import DataTable from "../Design/DataTable";
import Alert from "@material-ui/lab/Alert";
import { getReportOrders } from "../../firebase/orders";
import * as actionsOrder from "../../logic/actions/orders";
import * as selectors from "../../logic/reducers";
import moment from "moment";
import { jsPDF } from "jspdf";
import { connect } from "react-redux";
import { isEmpty, result } from "lodash";
import {
  INPUT_TYPE,
  ON_TIME_ORDERS,
  ON_TIME_ORDERS_ALL,
  orderStatus,
} from "../../constants/constants";
import tagCommentsService from "../../logic/services/tagCommentsService";
import "jspdf-autotable";
import distance from "@react-google-maps/api";

const bikersIn = {
  value: "bikersIn",
  text: "Entradas de motoristas",
};

const ordersDeliveryTime = {
  value: "ordersDeliveryTime",
  text: "Tiempos de entrega de pedidos",
};

const ordersDeliveredByBiker = {
  value: "ordersDeliveredByBiker",
  text: "Pedidos entregados por motorista",
};

const serviceQualification = {
  value: "serviceQualification",
  text: "Calificación de servicio",
};

const distanceByBiker = {
  value: "distanceByBiker",
  text: "Kilometrajes recorridos de los motorsitas",
};

const reportTypes = [
  // bikersIn,
  ordersDeliveryTime,
  ordersDeliveredByBiker,
  // serviceQualification,
  distanceByBiker,
];

const getMinDate = () => {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 30);
  return moment(minDate).format("YYYY-MM-DD");
};

const getMaxDate = () => {
  const maxDate = new Date();
  return moment(maxDate).format("YYYY-MM-DD");
};

const formFields = [
  {
    label: "Tipo de Reporte",
    autofocus: true,
    name: "reportType",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [...reportTypes],
  },
  {
    label: "Fecha de Inicio",
    autofocus: true,
    inputProps: {
      min: getMinDate(),
      max: getMaxDate(),
    },
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "Fecha de Fin",
    autofocus: true,
    inputProps: {
      min: getMinDate(),
      max: getMaxDate(),
    },
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
];

const validationSchema = yup.object({});

const CompanyReports = ({ bikers, companyId, fetchLinkWhitTime, timeBiker }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [filterError, setFilterError] = useState("");
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [filters, setFilters] = useState({
    reportType: "",
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  const getUpdatedResults = async () => {
    const startDate = new Date(`${filters.startDate} 00:00:00`);
    const endDate = new Date(`${filters.endDate} 23:59:59`);
    let customHeaders = [];
    let customRows = [];
    try {
      let orders = await getReportOrders(
        "",
        companyId,
        startDate,
        endDate,
        orderStatus.DELIVERED
      );
      orders = orders.filter((order) => order.company.id === companyId);
      if (filters.reportType === ordersDeliveryTime) {
        customHeaders = [
          {
            id: "orderId",
            name: "ORDEN",
          },
          {
            id: "typeOrder",
            name: "TIPO DE ORDEN",
          },
          {
            id: "biker",
            name: "2wheeler",
          },
          {
            id: "inRoute",
            name: "En Ruta",
          },
          {
            id: "deliveredTime",
            name: "Entregada",
          },
          {
            id: "deliveryTime",
            name: "Tiempo de entrega",
          },
        ];

        customRows = orders.map((order) => {
          let deliveryTime = "";
          let inRoute = "";
          let deliveredTime = "";
          let bikerToHome = "";
          if (!isEmpty(order.createdAt)) {
            if (!isEmpty(order.deliveredAt)) {
              deliveryTime = dateHelper.secondsToHms(
                order.deliveredAt - order.createdAt
              );
            } else if (!isEmpty(order.deliveryDate)) {
              deliveryTime = dateHelper.secondsToHms(
                order.deliveredAt - order.createdAt
              );
            }
          }
          if (!isEmpty(order.stateTime)) {
            if (!isEmpty(order.stateTime["En ruta"])) {
              inRoute = moment(
                new Date(order.stateTime["En ruta"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
            if (!isEmpty(order.stateTime["Entregado"])) {
              deliveredTime = moment(
                new Date(order.stateTime["Entregado"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
          }
          return {
            orderId: (order.orderId || "").toUpperCase(),
            typeOrder: (order.typeOrder || "").toUpperCase(),
            branch: (order.branch.name || "").toUpperCase(),
            biker: order.alias.name,
            inRoute,
            deliveryTime,
            deliveredTime,
            bikerToHome,
          };
        });
      } else if (filters.reportType === ordersDeliveredByBiker) {
        customHeaders = [
          {
            id: "orderId",
            name: "ORDEN",
          },
          {
            id: "biker",
            name: "2wheeler",
          },

          {
            id: "branch",
            name: "Tienda",
          },
          {
            id: "inRoute",
            name: "En Ruta",
          },
          {
            id: "deliveredTime",
            name: "Entregada",
          },
          {
            id: "deliveryTime",
            name: "Tiempo de entrega",
          },
          {
            id: "onTime",
            name: "En Tiempo",
          },
        ];
        const onTimeOrder = ON_TIME_ORDERS.find((val) => val.id === companyId);
        customRows = orders.map((order) => {
          let deliveryTime = "";
          let inRoute = "";
          let deliveredTime = "";
          let bikerToHome = "";
          let onTime = "";
          let momentDate = "";
          if (!isEmpty(order.createdAt) && !isEmpty(order.deliveredAt)) {
            deliveryTime = dateHelper.secondsToHms(
              order.deliveredAt - order.createdAt
            );
            let dateNew = new Date(order.stateTime.Entregado.seconds * 1000);
            momentDate = moment(dateNew).format("LLLL");
            if (!isEmpty(onTimeOrder)) {
              const circleTime = onTimeOrder.time.find((val) => {
                if (val.id === ON_TIME_ORDERS_ALL) {
                  return val;
                }
                return val.id === (order.circle || { id: 1 }).id;
              });
              if (order.deliveredAt - order.createdAt < circleTime.time) {
                onTime = "Si";
              } else {
                onTime = "No";
              }
            }
          }
          if (!isEmpty(order.stateTime)) {
            if (!isEmpty(order.stateTime["En ruta"])) {
              inRoute = moment(
                new Date(order.stateTime["En ruta"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
            if (!isEmpty(order.stateTime["Entregado"])) {
              deliveredTime = moment(
                new Date(order.stateTime["Entregado"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
          }
          return {
            orderId: (order.orderId || "").toUpperCase(),
            biker: (order.alias.name || "").toUpperCase(),
            branch: (order.branch.name || "").toUpperCase(),
            deliveryTime,
            inRoute,
            deliveredTime,
            bikerToHome,
            onTime,
          };
        });
      }
    } catch (e) {
      console.log("something went wrong");
    }
    return {
      customRows,
      customHeaders,
    };
  };

  const getTagComments = async () => {
    let customHeaders = [];
    let customRows = [];
    try {
      const res = await tagCommentsService.getTagCommentsByCompanyId(companyId);
      customHeaders = [
        {
          id: "tag",
          name: "Tipo",
        },
        {
          id: "name",
          name: "Comentario",
        },
      ];
      customRows = res.map((row) => ({
        tag: row.tag,
        name: row.name,
      }));
    } catch (e) {
      console.log("something went wrong");
    }
    return {
      customRows,
      customHeaders,
    };
  };

  const onSubmit = async (timesBikers) => {
    setFilterError("");
    setIsDone(false);
    const startDate = new Date(`${filters.startDate} 00:00:00`);
    const endDate = new Date(`${filters.endDate} 23:59:59`);
    let customHeaders = [];
    let customRows = [];

    if (filters.reportType === distanceByBiker) {
      try {
        let orders = await getReportOrders(
          "",
          companyId,
          startDate,
          endDate,
          orderStatus.DELIVERED
        );
        orders = orders.filter((order) => order.company.id === companyId);
        customHeaders = [
          {
            id: "orderId",
            name: "ORDEN",
          },
          {
            id: "biker",
            name: "2wheeler",
          },
          {
            id: "distance",
            name: "Kilometros Lugar",
          },
          {
            id: "deliveryTime",
            name: "Tiempo de entrega",
          },
          {
            id:"address",
            name: "Dirección de Entrega"
          },
          {
            id: "phone",
            name: "TELEFONO PILOTO",
          },
        ];

        customRows = orders.map((order) => {
          let deliveryTime = "";
          let inRoute = "";
          let deliveredTime = "";
          let bikerToHome = "";
          if (!isEmpty(order.createdAt)) {
            if (!isEmpty(order.deliveredAt)) {
              deliveryTime = dateHelper.secondsToHms(
                order.deliveredAt - order.createdAt
              );
            } else if (!isEmpty(order.deliveryDate)) {
              deliveryTime = dateHelper.secondsToHms(
                order.deliveredAt - order.createdAt
              );
            }
          }
          if (!isEmpty(order.stateTime)) {
            if (!isEmpty(order.stateTime["En ruta"])) {
              inRoute = moment(
                new Date(order.stateTime["En ruta"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
            if (!isEmpty(order.stateTime["Entregado"])) {
              deliveredTime = moment(
                new Date(order.stateTime["Entregado"].seconds * 1000)
              ).format("DD-MM-YYYY HH:mm:ss");
            }
          }
          return {
            orderId: (order.orderId || "").toUpperCase(),
            tourPilot: (order.tourPilot || "").toUpperCase(),
            phone: (order.phone || "+502 00000 000"),
            typeOrder: (order.typeOrder || "").toUpperCase(),
            branch: (order.branch.name || "").toUpperCase(),
            biker: order.alias.name,
            inRoute,
            deliveryTime,
            address: (order.address ||"NO TIENE DIRECCIÓN"),
            deliveredTime,
            distance: (order.distanceTotal||"CALCULANDO"),
            bikerToHome,
          }
        });
      } catch (error) {
        console.info('error', error.message)
      }

    } else if (filters.reportType === serviceQualification) {
      setIsLoading(true);
      const res = await getTagComments();
      customRows = res.customRows;
      customHeaders = res.customHeaders;
      setIsLoading(false);
    } else {
      if (!isEmpty(filters.startDate) && !isEmpty(filters.endDate)) {
        const startDate = new Date(`${filters.startDate} 00:00:00`);
        const endDate = new Date(`${filters.endDate} 00:00:00`);
        if (startDate > endDate) {
          setFilterError("Fecha de inicio debe ser antes que la fecha final");
          return;
        }
      }
      setIsLoading(true);
      const res = await getUpdatedResults();
      customRows = res.customRows;
      customHeaders = res.customHeaders;
      setIsLoading(false);
    }
    setRows(customRows);
    setHeaders(customHeaders);
    setIsDone(true);
  };

  const getText = (text) => {
    return `${text}`
      .replaceAll(",", "-")
      .replaceAll(";", "-")
      .replaceAll("#", "No.")
      .replaceAll("\n", "");
  };

  const generateCSV = () => {
    const rowsTouse = rows;
    const allHeaders = headers;
    const csvRows = rowsTouse.map((row) => {
      let data = [];
      allHeaders.forEach((header) => {
        Object.keys(row).forEach((key) => {
          if (header.id === key) {
            data = data.concat([getText(row[key])]);
          }
        });
      });
      return data;
    });
    const csvHeaders = allHeaders.map((header) => header.name);
    const csvInfo = [[...csvHeaders], ...csvRows];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvInfo.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "reporte.csv");
    link.click();
  };

  const generatePDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Tiempos de entrega de pedidos";
    const pdfHeaders = [headers.map((header) => header.name)];

    const pdfRows = rows.map((row) => {
      let data = [];
      headers.forEach((header) => {
        Object.keys(row).forEach((key) => {
          if (header.id === key) {
            data = data.concat([getText(row[key])]);
          }
        });
      });
      return data;
    });

    let content = {
      startY: 50,
      head: pdfHeaders,
      body: pdfRows,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const renderView = () => (
    <>
      {!isEmpty(filterError) && (
        <>
          <Alert severity="error">{filterError}</Alert>
          <br />
        </>
      )}
      <Expandable title="Filtros" defaultExpanded>
        <DynamicForm
          obj={filters}
          setState={setFilters}
          fields={customFormFields}
          schema={validationSchema}
          buttons={btnList}
          onSubmit={() => onSubmit(timeBiker)}
          btnAlignment="right"
          formCssClasses="text-capitalize"
          forceSetState
        />
      </Expandable>
      <br />

      <br />
      {!isEmpty(filters.reportType) && isDone && (
        <>
          <div>
            <Button
              size="small"
              type="button"
              variant="outlined"
              color="secondary"
              className="margin-right-sm"
              onClick={generateCSV}
            >
              Descargar CSV
            </Button>
            <Button
              size="small"
              type="button"
              variant="outlined"
              color="secondary"
              onClick={generatePDF}
            >
              Descargar PDF
            </Button>
          </div>
          <br />
          <br />
          <div id="data-table-print">
            <DataTable
              usePagination
              align="left"
              headers={headers}
              rows={rows}
            />
          </div>
        </>
      )}
    </>
  );

  useEffect(() => {
    if (!isEmpty(filters.reportType)) {
      if (
        // filters.reportType === distanceByBiker ||
        filters.reportType === serviceQualification ||
        filters.reportType === bikersIn
      ) {
        setCustomFormFields([formFields[0]]);
      } else {
        setCustomFormFields([...formFields]);
      }
    }
  }, [filters.reportType]);

  return (
    <div className="no-print">
      <div>
        {isLoading ? <CircularProcess color="secondary" /> : renderView()}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    timeBiker: selectors.getIsFetchingLinkOrderTimeCompleted(state),
    searchTime: selectors.getIsFetchingLinkOrderTimeStarted(state),
  }),
  (dispatch) => ({
    fetchLinkWhitTime(id) {
      dispatch(actionsOrder.linkGetOrderWhitTimerBikerStarted(id));
    },
  })
)(CompanyReports);
