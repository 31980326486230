import React from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
export default function Chatbot() {
  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#EF6C00",
    headerFontColor: "#fff",
    headerFontSize: "12px",
    botBubbleColor: "#EF6C00",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        bubbleStyle={{ fontSize: "13px" }}
        floating={true}
        steps={[
          {
            id: "hello-world",
            message: "HOLA SOY ROBOT QUBIT!, ¿En que te puedo apoyar?",
            trigger: "2",
          },
          {
            id: "2",
            options: [
              { value: 1, label: "Tengo un inconveniente", trigger: "3" },
            ],
          },
          {
            id: "3",
            message:
              "Puedes indicarme tu inconveniente, seleccionado una opción",
            trigger: "4",
          },
          {
            id: "4",
            component: (
              <div>
                <div> Opción 1: No se crear una orden </div>
                <div>
                  {" "}
                  Opción 2: En donde puedo ver las ordenes entregadas.{" "}
                </div>
                <div>
                  {" "}
                  Opción 3: Como puedo quien es el piloto asignado de la orden.{" "}
                </div>
                <div> Opción 4: la otra opcion</div>
                <div> Opción 5: otra opcion otra opcion </div>
              </div>
            ),
            trigger: "5",
          },
          {
            id: "5",
            options: [
              { value: 1, label: "opcion 1", trigger: "6" },
              { value: 2, label: "opcion 2", trigger: "6" },
              { value: 3, label: "opcion 3", trigger: "6" },
              { value: 4, label: "opcion 4", trigger: "6" },
              { value: 5, label: "opcion 5", trigger: "6" },
            ],
          },
          {
            id: "6",
            message: "aquí van las diferentes respuestas",
          },
        ]}
      />
    </ThemeProvider>
  );
}
