import React, { useState } from "react";
import * as yup from "yup";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../assets/theme";
import Map from "./Map";
import DynamicForm from "../Design/Form/DynamicForm";
import {
  DEPARTAMENT_TYPE,
  MUNICIPALITY_TYPE,
  ZONE_TYPE,
} from "../../constants/constants";
import { isEmpty } from "lodash";

const formFields = [
  {
    label: "Dirección",
    autofocus: true,
    name: "address",
    cssClasses: "col-12 margin-top-md margin-bottom-none",
    fullWidth: true,
  },
  {
    label: "Latitude",
    autofocus: true,
    cssClasses: "col-6 margin-top-md margin-bottom-none",
    name: "latitude",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Longitude",
    autofocus: true,
    cssClasses: "col-6 margin-top-md margin-bottom-none",
    name: "longitude",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Departamento",
    autofocus: true,
    cssClasses: "col-12 margin-top-md margin-bottom-none",
    name: "depto",
    fullWidth: true,
  },
  {
    label: "Municipalidad",
    autofocus: true,
    cssClasses: "col-6 margin-top-md margin-bottom-xsm",
    name: "munic",
    fullWidth: true,
  },
  {
    label: "Zona",
    autofocus: true,
    cssClasses: "col-6 margin-top-md margin-bottom-xsm",
    name: "zone",
    fullWidth: true,
  },
];

const validationSchema = yup.object({});

export default (props) => {
  const [markers, setMarkers] = useState(props.markers);
  const [center, setCenter] = useState(props.center);
  const [isAddressSearched, setIsAddressSearched] = useState();
  const [address, setAddress] = useState({
    address: undefined,
    latitude: undefined,
    longitude: undefined,
    depto: undefined,
    munic: undefined,
    zone: undefined,
  });
  // eslint-disable-next-line no-unused-vars
  const [btnList, setBtnList] = useState([
    {
      id: "search-for-address",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "seleccionar",
    },
  ]);

  const onMarkerDragEnd = (newPosition, marker) => {
    setAddress((val) => ({
      ...val,
      latitude: newPosition.latLng.lat(),
      longitude: newPosition.latLng.lng(),
    }));
    setMarkers((val) => {
      return [
        {
          ...val[0],
          position: {
            longitude: newPosition.latLng.lng(),
            latitude: newPosition.latLng.lat(),
          },
        },
      ];
    });
    setCenter({
      lng: newPosition.latLng.lng(),
      lat: newPosition.latLng.lat(),
    });
  };

  const onSubmit = (values) => {
    if (props.onConfirmAddress) {
      props.onConfirmAddress(values);
    }
  };

  const getZone = (zone) => {
    if (isEmpty(zone)) {
      return undefined;
    }
    const splitedZone = zone.long_name.toUpperCase().split(" ");
    if (isEmpty(splitedZone)) {
      return undefined;
    }
    return splitedZone.find((val) => {
      const intVal = parseInt(val);
      if (isNaN(intVal)) return false;
      return true;
    });
  };

  const onPlaceSelected = (place) => {
    setIsAddressSearched(true);
    window.scrollTo(0, 0);
    const depto = place.address_components.find((component) =>
      component.types.find((type) => type === DEPARTAMENT_TYPE)
    );
    const munic = place.address_components.find((component) =>
      component.types.find((type) => type === MUNICIPALITY_TYPE)
    );
    const zone = place.address_components.find((component) =>
      component.types.find((type) => type === ZONE_TYPE)
    );
    setAddress({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      depto: !isEmpty(depto) ? depto.long_name : undefined,
      munic: !isEmpty(munic) ? munic.long_name : undefined,
      zone: getZone(zone),
    });
    setMarkers([
      {
        position: {
          longitude: place.geometry.location.lng(),
          latitude: place.geometry.location.lat(),
        },
        showInfoWindow: false,
        infoWindow: place.formatted_address,
        draggable: true,
        onDragEnd: onMarkerDragEnd,
      },
    ]);
    setCenter({
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {isAddressSearched && (
        <div>
          <DynamicForm
            obj={address}
            fields={formFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            formCssClasses="scrollable-column all-upper-case"
            btnAlignment="right"
          />
        </div>
      )}
      <div>
        <Map
          {...props}
          center={center}
          markers={markers}
          onPlaceSelected={onPlaceSelected}
          showPlaceSearch
        />
      </div>
    </ThemeProvider>
  );
};
