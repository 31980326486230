// Get branches
export const getBranches = state => state.branches.fetchBranchesByCompanyId.branches;
export const getBranchesError = state => state.branches.fetchBranchesByCompanyId.error;
export const getAreBranchesLoading = state => state.branches.fetchBranchesByCompanyId.isLoading;

// Create branches
export const isLoadingCreateBranch = state => state.branches.createBranch.isLoading;
export const getErrorCreateBranch = state => state.branches.createBranch.error;
export const isDoneCreateBranch = state => state.branches.createBranch.done;

// Delete branches
export const isLoadingDeleteBranch = state => state.branches.deleteBranch.isLoading;
export const getErrorDeleteBranch = state => state.branches.deleteBranch.error;
export const isDoneDeleteBranch = state => state.branches.deleteBranch.done;
