/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modifyUserInForm } from "../../logic/actions/orders";
import { Prompt } from "react-router";
import { ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Paper, Box } from "@material-ui/core";
import CircularProcess from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { orderAlert } from "../../constants/constants";
import CreateOrderButton from "../Order/CreateOrderButton";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/orders";
import theme from "../../assets/theme";
import dateHelper from "../../helpers/dateHelper";
import filterHelper from "../../helpers/filterHelper";
import BreadCrumb from "../../containers/navs/breadcrumb";
import SingleTableView from "../../containers/tables/singleTableView";
import { orderStatus } from "../../constants/constants";
import { ALL_ORDERS_COLUMNS } from "../../constants/tableConstants.js";
import ResponsiveOrder from "./ResponsiveOrder";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { inRouteOrdersFilterActions } from "../../logic/actions/filters";
import { getInRouteOrdersFilters } from "../../logic/selectors/filters";

const OrderIshop = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectors.getOrders);
  const changes = useSelector(selectors.getOrderChanges);
  const isSubscribeLoading = useSelector(selectors.getIsSubscribeLoading);
  const history = useHistory();
  const [onTime, setOnTime] = useState(0);
  const [check, setCheck] = useState(0);
  const [almostLate, setAlmostLate] = useState(0);
  const [late, setLate] = useState(0);
  const [ordersTimeOut, setOrdersTimeOut] = useState();
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.down("xs"));

  const savedFilterModel = useSelector(getInRouteOrdersFilters);
  const [filterModel, setFilterModel] = useState([]);
  const [filterModelToUse, setFilterModelToUse] = useState([]);
  const [isFilterConfigured, setIsFilterConfigured] = useState(false);

  const checkAlert = () => {
    clearTimeout(ordersTimeOut);
    setOrdersTimeOut(
      setTimeout(() => {
        orders
          .filter(
            (order) =>
              order.status !== orderStatus.CANCELLED &&
              order.status !== orderStatus.DELIVERED &&
              order.company.name === "ISHOP GUATEMALA"
          )
          .forEach((data) => {
            if (
              !isEmpty(data.createdAt) &&
              data.createdAt.seconds !== undefined
            ) {
              data.time = dateHelper.getTimeOnRoute(
                new Date(data.createdAt.seconds * 1000).getTime()
              );
              data.alert = dateHelper.getAlert(data.time / 60);
              dispatch(actions.modifyingOrder(data, data.id, true));
              if (
                !isEmpty(data.modifyingBy) &&
                !isEmpty(data.modifyingBy.expiresAt)
              ) {
                const currDate = new Date().getTime();
                const expiresAt = new Date(
                  data.modifyingBy.expiresAt.seconds * 1000
                ).getTime();
                if (currDate >= expiresAt) {
                  dispatch(actions.modifyUserInForm(data.id, true));
                }
              }
            }
          });
        checkAlert();
      }, 30 * 1000)
    );
  };

  useEffect(() => {
    dispatch(actions.clearOrderSubscription());
    dispatch(
      actions.startFetchingOrders({
        statusFilter: [
          orderStatus.GOING,
          orderStatus.ON_HOLD,
          orderStatus.PENDING,
          orderStatus.ACCEPTED,
          orderStatus.ASSIGNED,
          orderStatus.RECEIVING,
          orderStatus.CANCELLED,
        ],
      })
    );
  }, []);

  useEffect(() => {
    setOnTime(0);
    setCheck(0);
    setAlmostLate(0);
    setLate(0);
    orders
      .filter(
        (order) =>
          order.status !== orderStatus.CANCELLED &&
          order.status !== orderStatus.DELIVERED &&
          order.company.name === "ISHOP GUATEMALA"
      )
      // eslint-disable-next-line array-callback-return
      .map((order) => {
        if (order.alert === orderAlert.ON_TIME) {
          setOnTime((val) => val + 1);
        } else if (order.alert === orderAlert.VERIFY) {
          setCheck((val) => val + 1);
        } else if (order.alert === orderAlert.TO_BE_LATE) {
          setAlmostLate((val) => val + 1);
        } else if (order.alert === orderAlert.LATE) {
          setLate((val) => val + 1);
        }
      });
    checkAlert();
  }, [orders]);

  useEffect(() => {
    changes.map((change) => {
      return dispatch(actions.removingChange(change));
    });
  }, [changes]);

  const clearAllTimeouts = () => {
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  };
  const navigateToPage = (params) => {
    clearTimeout(ordersTimeOut);
    if (params?.field === "biker") {
      setOrdersTimeOut(null);
      clearAllTimeouts();
      history.push(`/biker/${params.row.alias.id}`);
    } else if (params?.field === "removeModifyingBy") {
      dispatch(modifyUserInForm(params.row.id, true));
    } else if (params?.field === "SMS/GMAIL") {
      setOrdersTimeOut(null);
      clearAllTimeouts();
      if (params.row.emailSended) {
        history.push(`/Share/order/${params.row.id}`);
      } else if (params.row.smsSend) {
        history.push(`/Share/order/${params.row.id}`);
      }
    } else {
      history.push(`/edit-order/${params.row.id}`);
      // window.location.reload();
    }
  };

  const getRows = () => {
    const rows = orders
      .filter(
        (order) =>
          order.status !== orderStatus.CANCELLED &&
          order.status !== orderStatus.DELIVERED &&
          order.status !== orderStatus.ON_HOLD &&
          order.company.name === "ISHOP GUATEMALA"
      )
      .map((order) => ({
        ...order,
        createdDate: !isEmpty(order.createdAt)
          ? `${order.createdAt.seconds}`
          : "",
      }));
    const onHoldOrders = orders.filter(
      (order) =>
        order.status === orderStatus.ON_HOLD &&
        order.company.name === "ISHOP GUATEMALA"
    );
    return onHoldOrders.concat([...rows]).map((order) => ({
      ...order,
      removeModifyingBy:
        !isEmpty(order.modifyingBy) &&
        !isEmpty(order.modifyingBy.name) &&
        !isEmpty(order.modifyingBy.id)
          ? "Eliminar"
          : "",
    }));
  };

  useEffect(() => {
    if (!isFilterConfigured) {
      if (!isEmpty(savedFilterModel)) {
        setFilterModel(savedFilterModel);
        setFilterModelToUse(savedFilterModel);
        setIsFilterConfigured(true);
      }
    }
  }, [savedFilterModel, isFilterConfigured]);

  const startDay = new Date();
  const endDay = new Date();
  startDay.setHours(0);
  startDay.setMinutes(0);
  startDay.setSeconds(1);
  endDay.setHours(23);
  endDay.setMinutes(59);
  endDay.setSeconds(59);

  return (
    <div>
      <Prompt
        message={(location, action) => {
          dispatch(
            inRouteOrdersFilterActions.onFilterSave(
              filterModelToUse.map((filter) => ({
                columnField: filter.columnField,
                operatorValue: filter.operatorValue,
                value: filter.value,
              }))
            )
          );
          return true;
        }}
      />
      <ThemeProvider theme={theme}>
        {isSubscribeLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90vw",
              height: "90vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProcess color="secondary" />
          </div>
        ) : (
          <>
            <div>
              <BreadCrumb
                match={{ path: history.location.pathname }}
                title="TODAS LAS ORDENES ISHOP GUATEMALA"
              />
            </div>
            <div>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12}>
                  <div className="reduced-padding">
                    <Paper>
                      <Box padding={1}>
                        {!matches && (
                          <>
                            <SingleTableView
                              rows={getRows()}
                              onCellClick={navigateToPage}
                              columns={[
                                ...ALL_ORDERS_COLUMNS,
                                {
                                  field: "removeModifyingBy",
                                  headerName: "ELIMINAR MODIFICANDO",
                                  width: 200,
                                  renderCell: (param) =>
                                    !isEmpty(param) && !isEmpty(param.value) ? (
                                      <div className="link">{param.value}</div>
                                    ) : (
                                      ""
                                    ),
                                },
                              ]}
                              onFilterChange={(filterModelItems) => {
                                filterHelper.onFilterChange(
                                  orders,
                                  filterModelItems
                                );
                                if (!isEmpty(filterModelItems)) {
                                  if (!isEmpty(filterModel)) {
                                    setFilterModel(undefined);
                                  } else {
                                    setFilterModelToUse(filterModelItems);
                                  }
                                }
                              }}
                              filterModel={
                                !isEmpty(filterModel)
                                  ? { items: [...filterModel] }
                                  : undefined
                              }
                            />
                            <div className="table-footer">
                              <div>
                                <Grid container>
                                  <Grid item xs={4}>
                                    <small>{`ORDENES EN RUTA: ${
                                      orders.filter(
                                        (order) =>
                                          order.status !==
                                            orderStatus.CANCELLED &&
                                          order.status !==
                                            orderStatus.DELIVERED &&
                                          order.company.name ===
                                            "ISHOP GUATEMALA"
                                      ).length
                                    }`}</small>
                                  </Grid>
                                  {!matches && (
                                    <Grid item xs={3}>
                                      <small>{`ORDENES SIN MOTORISTA: ${
                                        orders
                                          .filter(
                                            (order) =>
                                              order.status !==
                                                orderStatus.CANCELLED &&
                                              order.status !==
                                                orderStatus.DELIVERED
                                          )
                                          .filter(
                                            (element) =>
                                              element.alias == null ||
                                              element.alias.name === undefined
                                          ).length
                                      }`}</small>
                                    </Grid>
                                  )}
                                  <Grid item xs={2}>
                                    <small>{`ORDENES SIN COORDENADAS: ${
                                      orders.filter(
                                        (order) =>
                                          !isEmpty(order.locationRef) &&
                                          order.locationRef.latitude === 14 &&
                                          order.locationRef.longitude === 14
                                      ).length
                                    }`}</small>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <small>{`ORDENES SIN DIGITAR: ${
                                      orders.filter(
                                        (order) =>
                                          (order.name || "").toUpperCase() ===
                                            "ESPERANDO..." &&
                                          (
                                            order.orderId || ""
                                          ).toUpperCase() === "ESPERANDO..."
                                      ).length
                                    }`}</small>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="footer">
                                <CreateOrderButton />
                              </div>
                            </div>
                          </>
                        )}
                        {matches && (
                          <ResponsiveOrder
                            orders={orders
                              .filter(
                                (order) =>
                                  order.status !== orderStatus.CANCELLED &&
                                  order.status !== orderStatus.DELIVERED &&
                                  order.company.name === "ISHOP GUATEMALA"
                              )
                              .map((order) => ({
                                ...order,
                                createdDate: !isEmpty(order.createdAt)
                                  ? `${order.createdAt.seconds}`
                                  : "",
                              }))}
                            onClick={navigateToPage}
                          />
                        )}
                      </Box>
                    </Paper>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </ThemeProvider>
    </div>
  );
};

export default OrderIshop;
