import { combineReducers } from 'redux';

import auth, * as authselectors from './auth';
import orders, * as ordersSelectors from './orders';
import pilots, * as pilotsSelectors from './pilots';
import branches, * as branchesSelectors from './branches';
import usersPilot, * as userSelectors from "./user";
import company, * as useCompany from './company';
import issues from "./issues";
import filters from "./filters";
import companyLink, * as companiesSelector  from './companylink'; 
const reducer = combineReducers({
    auth,
    orders,
    pilots,
    branches,
    company,
    issues,
    filters,
    companyLink,
    usersPilot
});

export default reducer;

export const gettingAccountUser = (state) => userSelectors.gettingAccountUser(state.usersPilot);
export const getAccountUser= (state) => userSelectors.getAccountUser(state.usersPilot);
export const gettingAccountUserFail = (state) => userSelectors.gettingAccountUserFail(state.usersPilot);

// GETTING ALL ORDERS 

export const gettingOrdes = (state) => userSelectors.gettingOrdes(state.usersPilot);
export const getAllOrders = (state) => userSelectors.getAllOrders(state.usersPilot);
export const getAllOrdersFailed = (state) => userSelectors.getAllOrdersFailed(state.usersPilot);

// USERS CONTROLLERS AND BIKERS 

export const passwordUpdateBikers = (state) => userSelectors.passwordUpdateBikers(state.usersPilot);
export const passwordUpdatedBikers = (state) => userSelectors.passwordUpdatedBikers(state.usersPilot); 
export const passwordUpdateBikersFailed = (state)=> userSelectors.passwordUpdateBikersFailed(state.usersPilot)

export const userImagePilotCreating = (state) => userSelectors.userImagePilotCreating(state.usersPilot);
export const userImagePilotCreated = (state) => userSelectors.userImagePilotCreated(state.usersPilot); 
export const userImagePilotCreatingFaild = (state)=> userSelectors.userImagePilotCreatingFaild(state.usersPilot)

export const userPilotCreating = (state) => userSelectors.userPilotCreating(state.usersPilot);
export const userPilotCreated = (state) => userSelectors.userPilotCreated(state.usersPilot); 
export const userPilotCreatingFaild = (state)=> userSelectors.userPilotCreatingFaild(state.usersPilot);

export const updatePasswordController = (state) => userSelectors.updatePasswordController(state.usersPilot);
export const passwordControllerCompleted = (state) => userSelectors.passwordControllerCompleted(state.usersPilot); 
export const updatePasswordFailed = (state)=> userSelectors.updatePasswordFailed(state.usersPilot);

export const updateInformationController = (state) => userSelectors.updateInformationController(state.usersPilot);
export const updateInfo = (state) => userSelectors.updateInfo(state.usersPilot); 
export const updateInfoFailed = (state)=> userSelectors.updateInfoFailed(state.usersPilot);

export const findControllerAndUserEmail = (state) => userSelectors.findControllerAndUserEmail(state.usersPilot);
export const foundControllerAndUserEmail= (state) => userSelectors.foundControllerAndUserEmail(state.usersPilot);
export const failedControllerAndUserEmail = (state) => userSelectors.failedControllerAndUserEmail(state.usersPilot);

//  AUTHENTICATION SELECTORS
export const getAPIToken = state => authselectors.getAPIToken(state.auth);
export const getAuthToken = state => authselectors.getAuthToken(state.auth);
export const getAuthEmail = state => authselectors.getAuthEmail(state.auth);
export const getController = state => authselectors.getController(state.auth);
export const getIsAuthenticated = state => authselectors.getIsAuthenticated(state.auth);
export const getIsAuthenticating = state => authselectors.getIsAuthenticating(state.auth);
export const getAuthenticationError = state => authselectors.getAuthenticationError(state.auth);


export const creatingTokenAuth = state => authselectors.creatingTokenAuth(state.auth);
export const createdUserAuth = state => authselectors.createdUserAuth(state.auth);
export const createdUserFailedAuth = state => authselectors.createdUserFailedAuth(state.auth);

//  ORDERS SELECTORS
export const getOrder = (state, id) => ordersSelectors.getOrder(state.orders, id);
export const getOrders = state => ordersSelectors.getOrders(state.orders);
export const getIsSubscribeLoading = state => ordersSelectors.getIsSubscribeLoading(state.orders);
export const getHistoryOrder = (state, id) => ordersSelectors.getHistoryOrder(state.orders, id);
export const getHistoryOrders = state => ordersSelectors.getHistoryOrders(state.orders);
export const getOrderChanges = state => ordersSelectors.getOrderChanges(state.orders);
export const getSelectedOrder = state => ordersSelectors.getSelectedOrder(state.orders);
export const getIsFetchingOrders = state => ordersSelectors.getIsFetching(state.orders);
export const getSingleOrder = state => ordersSelectors.getSingleOrder(state.orders);
export const getIsFetchingSingleOrder = state => ordersSelectors.getIsFetchingSinlgeOrder(state.orders)
//  PILOTS SELECTORS;
export const getPilot = (state, id) => pilotsSelectors.getPilot(state.pilots, id);
export const getPilots = (state) => pilotsSelectors.getPilots(state.pilots);
export const getSelectedPilot = state => pilotsSelectors.getSelectedPilot(state.pilots);
export const getIsFetchingPilots = state => pilotsSelectors.getIsFetchingPilots(state.pilots);
export const getPilotsError = state => pilotsSelectors.getPilotsError(state.pilots);
export const getIsFetchingSinglePilot = state => pilotsSelectors.getIsFetchingSinglePilot(state.pilots);
export const getSinglePilot = state => pilotsSelectors.getSinglePilot(state.pilots);
export const fetchPilotsByStatus = state => pilotsSelectors.fetchPilotsByStatus(state.pilots);
//  BRANCHES SELECTORS
export const getBranchOffice = (state, id) => branchesSelectors.getBranchOffice(state.branches, id);
export const getBranchOffices = state => branchesSelectors.getBranchOffices(state.branches);
export const getSelectedBranchOffice = state => branchesSelectors.getSelectedBranchOffice(state.branches);
export const getIsFetchingBranchOffices = state => branchesSelectors.getIsFetchingBranchOffices(state.branches);
export const getBranchOfficesError = state => branchesSelectors.getBranchOfficesError(state.branches);
//COMPANIES LINK 

export const fetchingLinkCompaniesStarted = state => companiesSelector.fetchingLinkCompaniesStarted(state.companyLink);
export const fetchingLinkCompaniesCompleted = state => companiesSelector.fetchingLinkCompaniesCompleted(state.companyLink);
export const fetchingLinkCompaniesFailed = state => companiesSelector.fetchingLinkCompaniesFailed(state.companyLink);


export const updateIsFetchingOrderLinkStarted = state => ordersSelectors.updateIsFetchingOrderLinkStarted(state.orders)
export const updateIsFetchingOrderLinkError = state => ordersSelectors.updateIsFetchingOrderLinkError(state.orders)

//ORDERS LINK 
export const getIsFetchingOrderLInkStarted =  state  => ordersSelectors.getIsFetchingOrderLInkStarted(state.orders);
export const getIsFetchingOrderLinkCompleted = state => {
    // console.info(`\n»» src/logic/reducers/index.js: getIsFetchingOrderLinkCompleted`, state.orders, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
    return ordersSelectors.getIsFetchingOrderLinkCompleted(state.orders)
};
export const getIsFetchingOrderLinkError = state => ordersSelectors.getIsFetchingOrderLinkError(state.orders)
//PILOTS SELECTOR LINK; 
export const getPilotLinkStarted =  state  => {
    // console.info(`\n»» src/logic/reducers/index.js: getPilotLinkStarted`, state, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
    return pilotsSelectors.getPilotLinkStarted(state.pilots)
};
export const getPilotLinkCompleted  = state => {
    // console.info(`\n»» src/logic/reducers/index.js: getPilotLinkCompleted`, state, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
    return pilotsSelectors.getPilotLinkCompleted(state.pilots)
};
export const getPilotLinkFailed = state => pilotsSelectors.getPilotLinkFailed(state.pilots); 
// TIME LINK
export const getIsFetchingLinkOrderTimeStarted = state => ordersSelectors.getIsFetchingLinkOrderTimeStarted(state.orders);
export const getIsFetchingLinkOrderTimeCompleted = state => ordersSelectors.getIsFetchingLinkOrderTimeCompleted(state.orders)
export const getIsFetchingLinkOrderTimeFailed =  state  => ordersSelectors.getIsFetchingLinkOrderTimeFailed(state.orders);
// RATING GET
export const getPilotRatingStarted = state => pilotsSelectors.getPilotRatingStarted(state.pilots);
export const getPilotRatingCompleted = state => pilotsSelectors.getPilotRatingCompleted(state.pilots);
export const getPilotRatingError = state => pilotsSelectors.getPilotRatingError(state.pilots);
// PILOT SCHEDULES DAYS 
export const getPilotSchedulesStarted = state => pilotsSelectors.getPilotSchedulesStarted(state.pilots);
export const getPilotSchedulesCompleted = state => pilotsSelectors.getPilotSchedulesCompleted(state.pilots);
export const getPilotSchedulesError = state => pilotsSelectors.getPilotSchedulesError(state.pilots)

// PILOT SCHEDULES MONTH
export const getPilotSchedulesMonthStarted = state => pilotsSelectors.getPilotSchedulesMonthStarted(state.pilots);
export const getPilotSchedulesMonthCompleted = state => pilotsSelectors.getPilotSchedulesMonthCompleted(state.pilots);
export const getPilotSchedulesMonthFailed = state => pilotsSelectors.getPilotSchedulesMonthFailed(state.pilots);


export const creatingRakingInit = (state) => pilotsSelectors.creatingRakingInit(state.pilots);
export const creatingRakingFinish = (state)=> pilotsSelectors.creatingRakingFinish(state.pilots);
export const creatingRakingFailed = (state) => pilotsSelectors.creatingRakingFailed(state.pilots);

export const fetchingBikerBranch = (state) => pilotsSelectors.fetchingBikerBranch(state.pilots);
export const fetchedBikerBranch = (state)=> pilotsSelectors.fetchedBikerBranch(state.pilots);
export const fetchedErrorBikerBranch = (state) => pilotsSelectors.fetchedErrorBikerBranch(state.pilots);

/*
    fetchingBikerSchedule
    fetchedBikerSchedule
    fetchedErrorBikerSchedule
*/
export const fetchingScheduleBiker = (state) => pilotsSelectors.fetchingBikerSchedule(state.pilots);
export const fetchedScheduleBiker = (state)=> pilotsSelectors.fetchedBikerSchedule(state.pilots);
export const fetchedErrorScheduleBiker = (state) => pilotsSelectors.fetchedErrorBikerSchedule(state.pilots);

export const gettingCompany = (state) => useCompany.gettingCompany(state.company)
export const getCompany= (state) => useCompany.getCompany(state.company)
export const gettingCompanyFailed = (state) => useCompany.gettingCompanyFailed(state.company)

/* SMS
    gettingSMS
    getSMS
    gettingSMSFail
*/

export const gettingSMS = (state) => userSelectors.gettingSMS(state.usersPilot);
export const getSMS= (state) => userSelectors.getSMS(state.usersPilot);
export const gettingSMSFail = (state) => userSelectors.gettingSMSFail(state.usersPilot);

/* BIKER
    gettingBikerDeliveriesStart
    getBikerDeliveriesAllStart
    gettingBikerDeliveriesFailed
*/

export const gettingBikerDeliveriesStart = (state) => userSelectors.gettingBikerDeliveriesStart(state.usersPilot);
export const getBikerDeliveriesAllStart= (state) => userSelectors.getBikerDeliveriesAllStart(state.usersPilot);
export const gettingBikerDeliveriesFailed = (state) => userSelectors.gettingBikerDeliveriesFailed(state.usersPilot);

/* BIKER
   gettingOrderDeliveryStart
    getOrderDeliveryAllStart
    gettingOrderDeliveryFailed
*/

export const gettingOrderDeliveryStart = (state) => userSelectors.gettingOrderDeliveryStart(state.usersPilot);
export const getOrderDeliveryAllStart= (state) => userSelectors.getOrderDeliveryAllStart(state.usersPilot);
export const gettingOrderDeliveryFailed = (state) => userSelectors.gettingOrderDeliveryFailed(state.usersPilot);

/* GMAIL
    gettingGmail
    getGmail
    gettingGmailFail
*/

export const gettingGmail = (state) => userSelectors.gettingGmail(state.usersPilot);
export const getGmail= (state) => userSelectors.getGmail(state.usersPilot);
export const gettingGmailFail = (state) => userSelectors.gettingGmailFail(state.usersPilot);

/*
    fetchingAssistantBiker
    fetchedAssistantBiker
    fetchedErrorAssistantBiker
*/
export const fetchingAssistanBiker = (state) => pilotsSelectors.fetchingAssistantBiker(state.pilots);
export const fetchedAssistanBiker = (state)=> pilotsSelectors.fetchedAssistantBiker(state.pilots);
export const fetchedErrorAssistanBiker = (state) => pilotsSelectors.fetchedErrorAssistantBiker(state.pilots);

/*
    fetchingUpdateAssistence
    fetchedUpdateAssistence
    fetchedErrorUpdateAssistence
*/
export const fetchingUpdateAssistence = (state) => pilotsSelectors.fetchingUpdateAssistence(state.pilots);
export const fetchedUpdateAssistence = (state)=> pilotsSelectors.fetchedUpdateAssistence(state.pilots);
export const fetchedErrorUpdateAssistence = (state) => pilotsSelectors.fetchedErrorUpdateAssistence(state.pilots);

/* 
    gettingAllOrdersStart
    getAllOrdersComplete
    gettingAllOrdersFailed
*/

export const gettingAllOrdersStart = (state) => ordersSelectors.gettingAllOrdersStart(state.orders);
export const getAllOrdersComplete= (state) => ordersSelectors.getAllOrdersComplete(state.orders);
export const gettingAllOrdersFailed = (state) => ordersSelectors.gettingAllOrdersFailed(state.orders);