import { combineReducers } from "redux";
import omit from "lodash/omit";
import union from "lodash/union";
import * as types from "../types/pilots";
import { VIEW_STATE, BIKER_RATING_STATUS } from "../../constants/constants";

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.PILOTS_FETCH_COMPLETED: {
      const { pilot, pilotId } = action.payload;

      return {
        ...state,
        [pilotId]: pilot,
      };
    }

    case types.PILOT_ADDED: {
      const { pilot, pilotId } = action.payload;

      return {
        ...state,
        [pilotId]: pilot,
      };
    }

    case types.PILOT_CLEAR:
    case types.PILOTS_FETCH_STARTED: {
      return {};
    }

    case types.PILOT_EDITED: {
      const { pilot, pilotId } = action.payload;

      return {
        ...state,
        [pilotId]: {
          ...state[pilotId],
          ...pilot,
        },
      };
    }

    case types.PILOT_REMOVED: {
      return omit(state, action.payload.pilotId);
    }

    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case types.PILOTS_FETCH_COMPLETED: {
      return union(action.payload.order);
    }

    case types.PILOT_ADDED: {
      return [...state, action.payload.pilotId];
    }

    case types.PILOT_REMOVED: {
      return state.filter((id) => id !== action.payload.pilotId);
    }

    case types.PILOT_CLEAR:
    case types.PILOTS_FETCH_STARTED: {
      return [];
    }

    default: {
      return state;
    }
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_PILOT: {
      return action.payload;
    }

    case types.DESELECT_PILOT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.PILOTS_FETCH_STARTED: {
      return true;
    }

    case types.PILOTS_FETCH_COMPLETED: {
      return false;
    }

    case types.PILOTS_FETCH_FAILED: {
      return false;
    }

    case types.SELECT_PILOT: {
      return false;
    }

    case types.DESELECT_PILOT: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case types.PILOTS_FETCH_STARTED: {
      return null;
    }

    case types.PILOTS_FETCH_COMPLETED: {
      return null;
    }

    case types.PILOTS_FETCH_FAILED: {
      return action.payload;
    }

    case types.SELECT_PILOT: {
      return null;
    }

    case types.DESELECT_PILOT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const initialState = {
  isLoading: false,
  biker: undefined,
  error: undefined,
};

const getPilotByPilotId = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PILOT_BY_PILOT_ID:
      return {
        ...state,
        isLoading: true,
        biker: undefined,
        error: undefined,
      };
    case types.GET_PILOT_BY_PILOT_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        biker: action.payload.biker,
      };
    case types.GET_PILOT_BY_PILOT_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.CLEAR_PILOT_BY_ID_DATA:
      return {
        ...state,
        isLoading: false,
        biker: undefined,
        error: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};

const getPilotsInitialState = {
  isLoading: false,
  bikerList: [],
  error: undefined,
};

const getPilotList = (state = getPilotsInitialState, action) => {
  switch (action.type) {
    case types.GET_PILOTS:
      return {
        ...state,
        isLoading: true,
        bikerList: [],
        error: undefined,
      };
    case types.GET_PILOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bikerList: action.payload.bikerList,
      };
    case types.GET_PILOTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.CLEAR_PILOT_DATA:
      return {
        ...state,
        isLoading: false,
        bikerList: [],
        error: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};

const subscribPilotByPilotIdInitialState = {
  error: undefined,
  orders: [],
  isLoading: false,
};

const subscribPilotByPilotId = (
  state = subscribPilotByPilotIdInitialState,
  action
) => {
  switch (action.type) {
    case types.CLEAR_SUBSCRIBE_PILOT_BY_PILOT_ID: {
      return {
        ...state,
        biker: undefined,
        error: undefined,
        isLoading: false,
      };
    }
    case types.SUBSCRIBE_PILOT_BY_PILOT_ID: {
      return {
        ...state,
        biker: undefined,
        error: undefined,
        isLoading: true,
      };
    }
    case types.SUBSCRIBE_PILOT_BY_PILOT_ID_SUCCESS: {
      return {
        ...state,
        biker: action.payload.biker,
        isLoading: false,
      };
    }
    case types.SUBSCRIBE_PILOT_BY_PILOT_ID_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const singlePilot = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_SINGLE_PILOT_COMPLETED: {
      return action.payload.pilot;
    }
    default: {
      return state;
    }
  }
};

const isFetchingSinglePilot = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SINGLE_PILOT_STARTED: {
      return true;
    }
    case types.FETCH_SINGLE_PILOT_FAILED: {
      return false;
    }
    case types.FETCH_SINGLE_PILOT_COMPLETED: {
      return false;
    }
    default: {
      return state;
    }
  }
};
const createBikerInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const createBiker = (state = createBikerInitialState, action) => {
  switch (action.type) {
    case types.BIKER_CREATE_STARTED:
      return {
        ...state,
        ...createBikerInitialState,
        isLoading: true,
      };
    case types.BIKER_CREATE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BIKER_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

const editBikerInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const editBiker = (state = editBikerInitialState, action) => {
  switch (action.type) {
    case types.BIKER_EDIT_CLEAR: {
      return {
        ...state,
        ...editBikerInitialState,
      };
    }
    case types.BIKER_EDIT_STARTED:
      return {
        ...state,
        ...editBikerInitialState,
        isLoading: true,
      };
    case types.BIKER_EDIT_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BIKER_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

const deleteBikerInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const deleteBiker = (state = deleteBikerInitialState, action) => {
  switch (action.type) {
    case types.BIKER_DELETE_CLEAR: {
      return {
        ...state,
        ...deleteBikerInitialState,
      };
    }
    case types.BIKER_DELETE_STARTED:
      return {
        ...state,
        ...deleteBikerInitialState,
        isLoading: true,
      };
    case types.BIKER_DELETE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BIKER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

const BIKER_BY_STATUS_INITSTATE = {
  data: [],
  viewState: VIEW_STATE.EMPTY,
};
const getPilotsByStatus = (state = BIKER_BY_STATUS_INITSTATE, action) => {
  switch (action.type) {
    case types.BIKERS_BY_STATUS_STARTED:
      return {
        ...state,
        viewState: VIEW_STATE.LOADING,
      };
    case types.BIKERS_BY_STATUS_COMPLETED:
      return {
        ...state,
        viewState: VIEW_STATE.LOADED,
        data: action.payload.data,
      };
    case types.BIKERS_BY_STATUS_EMPTY:
      return {
        ...state,
        viewState: VIEW_STATE.EMPTY,
      };
    case types.BIKERS_BY_STATUS_FAILED:
      return {
        ...state,
        viewState: VIEW_STATE.ERROR,
      };
    default:
      return state;
  }
};
const authorizePilot = (
  state = {
    loading: false,
    error: false,
    completed: false,
  },
  action
) => {
  switch (action.type) {
    case types.AUTHORIZE_PILOT_STARTED:
      return {
        ...state,
        loading: true,
        completed: false,
        error: false,
      };
    case types.AUTHORIZE_PILOT_COMPLETED:
      return {
        ...state,
        loading: false,
        completed: true,
        error: false,
      };
    case types.AUTHORIZE_PILOT_FAILED:
      return {
        ...state,
        loading: false,
        completed: false,
        error: true,
      };
    default:
      return state;
  }
};

const editBikerAlertInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const editBikerAlert = (state = editBikerAlertInitialState, action) => {
  switch (action.type) {
    case types.BIKER_EDIT_ALERT_CLEAR: {
      return {
        ...state,
        ...editBikerAlertInitialState,
      };
    }
    case types.BIKER_EDIT_ALERT_STARTED:
      return {
        ...state,
        ...editBikerAlertInitialState,
        isLoading: true,
      };
    case types.BIKER_EDIT_ALERT_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BIKER_EDIT_ALERT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export const viewState = {
  ...BIKER_RATING_STATUS,
};
const ratingBiker = (state = BIKER_RATING_STATUS.WAITING, action) => {
  switch (action.type) {
    case types.CREATE_BIKER_RATING_STARTED:
      return BIKER_RATING_STATUS.LOADING;
    case types.CREATE_BIKER_RATING_COMPLETED:
      return BIKER_RATING_STATUS.COMPLETED;
    case types.CREATE_BIKER_RATING_FAILED:
      return BIKER_RATING_STATUS.FAILED;
    case types.CLEAR_BIKER_RATING_STARTED:
      return BIKER_RATING_STATUS.WAITING;
    default:
      return state;
  }
};
const pilotLinkStarted = (state = false, action) => {
  switch (action.type) {
    case types.GET_PILOT_LINK_STARTED: {
      return true;
    }
    case types.GET_PILOT_LINK_COMPLETD: {
      return false;
    }
    case types.GET_PILOT_LINK_FAILED: {
      return false;
    }
    default: {
      return state ;
    }
  }
};

const pilotLinkCompleted = (state = null, action) => {
  switch (action.type) {
    case types.GET_PILOT_LINK_COMPLETD: {
      return action.payload.pilot || state;
    }
    default: {
      return state;
    }
  }
};

const pilotLinkError = (state = false, action) => {
  switch (action.type) {
    case types.GET_PILOT_LINK_STARTED: {
      return false;
    }
    case types.GET_PILOT_LINK_COMPLETD: {
      return false;
    }
    case types.GET_PILOT_LINK_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};


const pilotRatingStarted = (state = false, action) => {
  switch (action.type) {
    case types.GET_BIKER_RATING_STARTED:{
      return true
    }
    
    case types.GET_BIKER_RATING_COMPLETED:{
      return false
    }
  
    case types.GET_BIKER_RATING_FAILED:{
      return false
    }
    default: {
      return state; 
    } 
  }
}

const pilotRatingCompleted = (state = null, action) => {
  switch (action.type) {
    case types.GET_BIKER_RATING_COMPLETED:{
      return action.payload.bikerRating;
    }
    default: {
      return state; 
    } 
  }
}


const pilotRatingFailed = (state = false, action) => {
  switch (action.type) {
    case types.GET_BIKER_RATING_STARTED:{
      return false
    }
    
    case types.GET_BIKER_RATING_COMPLETED:{
      return false
    }
  
    case types.GET_BIKER_RATING_FAILED:{
      return true
    }
    default: {
      return state; 
    } 
  }
}

const pilotSchedulesStarted = (state = false, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_STARTED:{
      return true
    }
    
    case types.GET_SCHEDULES_COMPLETED:{
      return false
    }
  
    case types.GET_SCHEDULES_FAILED:{
      return false
    }
    default: {
      return state; 
    } 
  }
}


const pilotSchedulestFailed= (state = false, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_STARTED:{
      return false
    }
    
    case types.GET_SCHEDULES_COMPLETED:{
      return false
    }
  
    case types.GET_SCHEDULES_FAILED:{
      return true
    }
    default: {
      return state; 
    } 
  }
}

const pilotSchedulestCompleted = (state = null, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_COMPLETED:{
      return action.payload.schedules || state;
    }
  
    default:{
      return state;
    }
  }
}

const getSchedulesMonth = (state = false, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_MONTH_STARTED:{
      return true
    }
      
    case types.GET_SCHEDULES_MONTH_COMPLETED:{
      return false
    }
    
    case types.GET_SCHEDULES_MONTH_FAILED:{
      return false
    }

    default:{
      return state;
    }
  }
}

const schedulesMonthSuccess = (state = null, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_MONTH_COMPLETED:{
      return action.payload.assistance || state;
    }
    default:{
      return state
    }
  }
}

const schedulesMonthFailed = (state = false, action) => {
  switch (action.type) {
    case types.GET_SCHEDULES_MONTH_STARTED:{
      return false
    }

    case types.GET_SCHEDULES_MONTH_COMPLETED:{
      return false
    }
      
    case types.GET_SCHEDULES_MONTH_FAILED:{
      return true
    }
    
    default:{
      return state
    }
      
  }
}


const rakingStartedBikers = (state = false, action) => {
  switch (action.type) {
    case types.CREATING_RANKING_STARTED:{
      return true
    }

    case types.CREATING_RANKING_COMPLETED:{
      return false
    }
      
    case types.CREATING_RANKING_FAILED:{
      return false
    }
    
    default:{
      return state
    }
      
  }
}



const rakingCompletedBikers = (state = null, action) => {
  switch (action.type) {
    case types.CREATING_RANKING_COMPLETED:{
      return action.payload.request
    }
    
    default:{
      return state
    }
      
  }
}


const rakingFailedBikers = (state = false, action) => {
  switch (action.type) {
    case types.CREATING_RANKING_STARTED:{
      return false
    }

    case types.CREATING_RANKING_COMPLETED:{
      return false
    }
      
    case types.CREATING_RANKING_FAILED:{
      return true
    }
    
    default:{
      return state
    }
      
  }
}


const fetchBikerBranchInit = (state = false, action) => {
  switch (action.type) {
    case types.GET_BIKER_BRANCH_STARTED:{
      return true
    }

    case types.GET_BIKER_BRANCH_COMPLETED:{
      return false
    }
      
    case types.GET_BIKER_BRANCH_FAILED:{
      return false
    }
    
    default:{
      return state
    }
      
  }
}


const fetchBikerBranch = (state = null, action) => {
  switch (action.type) {

    case types.GET_BIKER_BRANCH_COMPLETED:{
      return action.payload.biker
    }
    
    default:{
      return state
    }
      
  }
}

const fetchErrorBikerBranch = (state = false, action) => {
  switch (action.type) {
    case types.GET_BIKER_BRANCH_STARTED:{
      return false
    }

    case types.GET_BIKER_BRANCH_COMPLETED:{
      return false
    }
      
    case types.GET_BIKER_BRANCH_FAILED:{
      return true
    }
    
    default:{
      return state
    }
      
  }
};

/*
  GET_SCHEDULE_STARTED
  GET_SCHEDULE_COMPLETED
  GET_SCHEDULE_FAILED

*/

const fetchBikerScheduleInit = (state = false, action) => {
  switch (action.type) {
    case types.GET_SCHEDULE_STARTED:{
      return true
    }

    case types.GET_SCHEDULE_COMPLETED:{
      return false
    }
      
    case types.GET_SCHEDULE_FAILED:{
      return false
    }
    
    default:{
      return state
    }
      
  }
}

const fetchBikerSchedule = (state = null, action) => {
  switch (action.type) {
    case types.GET_SCHEDULE_COMPLETED:{
      return action.payload.biker
    }
    
    default:{
      return state
    }
      
  }
}

const fetchErrorBikerSchedule = (state = false, action) => {
    switch (action.type) {
      case types.GET_SCHEDULE_FAILED: {
        return action.payload.e;
      }
      default: {
        return state;
      }
    }
      
}

/*
  GET_ASSISTENT_BIKER_STARTED
  GET_ASSISTENT_BIKER_COMPLETED
  GET_ASSISTENT_BIKER_FAILED
*/

const fetchAssitenBikerInit = (state = false, action) => {
  switch (action.type) {
    case types.GET_ASSISTENT_BIKER_STARTED:{
      return true
    }

    case types.GET_ASSISTENT_BIKER_COMPLETED:{
      return false
    }
      
    case types.GET_ASSISTENT_BIKER_FAILED:{
      return false
    }
    
    default:{
      return state
    }
      
  }
}

const fetchAssitenBiker = (state = null, action) => {
  switch (action.type) {
    case types.GET_ASSISTENT_BIKER_COMPLETED:{
      return action.payload.biker
    }
    
    default:{
      return state
    }
      
  }
}

const fetchErrorAssitenBiker = (state = false, action) => {
    switch (action.type) {
      case types.GET_ASSISTENT_BIKER_FAILED: {
        return action.payload.e;
      }
      default: {
        return state;
      }
    }     
}

/*
  GET_UPDATE_ASSISTENCE_BIKER_STARTED
  GET_UPDATE_ASSISTENCE_BIKER_COMPLETED
  GET_UPDATE_ASSISTENCE_BIKER_FAILED
*/

const fetchUpdateAssistentInit = (state = false, action) => {
  switch (action.type) {
    case types.GET_UPDATE_ASSISTENCE_BIKER_STARTED:{
      return true
    }

    case types.GET_UPDATE_ASSISTENCE_BIKER_COMPLETED:{
      return false
    }
      
    case types.GET_ASSISTENT_BIKER_FAILED:{
      return false
    }
    
    default:{
      return state
    }
      
  }
}

const fetchUpdateAssistent = (state = null, action) => {
  switch (action.type) {
    case types.GET_UPDATE_ASSISTENCE_BIKER_COMPLETED:{
      return action.payload.biker
    }
    
    default:{
      return state
    }
      
  }
}

const fetchErrorUpdateAssistent = (state = false, action) => {
    switch (action.type) {
      case types.GET_UPDATE_ASSISTENCE_BIKER_FAILED: {
        return action.payload.e;
      }
      default: {
        return state;
      }
    }
}


const pilots = combineReducers({
  schedulesMonthFailed,
  schedulesMonthSuccess,
  getSchedulesMonth,
  pilotRatingCompleted,
  pilotSchedulesStarted,
  pilotSchedulestCompleted,
  pilotSchedulestFailed,
  pilotRatingFailed,
  pilotRatingStarted,
  editBikerAlert,
  editBiker,
  deleteBiker,
  createBiker,
  byId,
  order, 
  selected,
  isFetching,
  error,
  getPilotByPilotId,
  getPilotList,
  subscribPilotByPilotId,
  singlePilot,
  isFetchingSinglePilot,
  getPilotsByStatus,
  authorizePilot,
  ratingBiker,
  pilotLinkError,
  pilotLinkCompleted,
  pilotLinkStarted,

  //raking pilots created
  rakingStartedBikers,
  rakingCompletedBikers,
  rakingFailedBikers,

  // get biker only branch 
  fetchBikerBranch,
  fetchBikerBranchInit,
  fetchErrorBikerBranch,

  // get schedule
  fetchBikerScheduleInit,
  fetchBikerSchedule,
  fetchErrorBikerSchedule,

  // get assitant
  fetchAssitenBikerInit,
  fetchAssitenBiker,
  fetchErrorAssitenBiker,

  // update assistence
  fetchUpdateAssistentInit,
  fetchUpdateAssistent,
  fetchErrorUpdateAssistent
  
});

export default pilots;

//  SELECTORS;
export const getPilot = (state, id) => state.byId[id];
export const getPilots = (state) =>
  state.order.map((id) => getPilot(state, id));
export const getSelectedPilot = (state) => state.selected;
export const getIsFetchingPilots = (state) => state.isFetching;
export const getPilotsError = (state) => state.error;
export const getIsFetchingSinglePilot = (state) => state.isFetchingSinglePilot;
export const getSinglePilot = (state) => state.singlePilot;
export const fetchPilotsByStatus = (state) => state.getPilotsByStatus;

// LINK PILOT SELECTORS
export const getPilotLinkStarted = (state) => state.pilotLinkStarted;
export const getPilotLinkCompleted = (state) => state.pilotLinkCompleted;
export const getPilotLinkFailed = (state) => state.pilotLinkError;

export const getPilotRatingStarted = (state) => state.pilotRatingStarted;
export const getPilotRatingCompleted = (state) => state.pilotRatingCompleted;
export const getPilotRatingError = (state) =>  state.pilotRatingFailed;

export const getPilotSchedulesStarted = (state) =>  state.pilotSchedulesStarted;
export const getPilotSchedulesCompleted = (state) =>  state.pilotSchedulestCompleted;
export const getPilotSchedulesError = (state) =>  state.pilotSchedulestFailed;

export const getPilotSchedulesMonthStarted = (state) => state.getSchedulesMonth;
export const getPilotSchedulesMonthCompleted = (state) =>  state.schedulesMonthSuccess;
export const getPilotSchedulesMonthFailed = (state) =>  state.schedulesMonthFailed;

// CREATING RATING BIKER

export const creatingRakingInit = (state) => state.rakingStartedBikers;
export const creatingRakingFinish = (state)=>state.rakingCompletedBikers;
export const creatingRakingFailed = (state) => state.rakingFailedBikers;

// GETTING BIKER ONLY BRANCH  

export const fetchingBikerBranch = (state)=> state.fetchBikerBranchInit;
export const fetchedBikerBranch = (state)=> state.fetchBikerBranch;
export const fetchedErrorBikerBranch = (state)=> state.fetchErrorBikerBranch;

/*
  fetchBikerScheduleInit
  fetchBikerSchedule
  fetchErrorBikerSchedule
*/
export const fetchingBikerSchedule = (state)=> state.fetchBikerScheduleInit;
export const fetchedBikerSchedule = (state)=> state.fetchBikerSchedule;
export const fetchedErrorBikerSchedule = (state)=> state.fetchErrorBikerSchedule;

/**
  fetchAssitenBikerInit
  fetchAssitenBiker
  fetchErrorAssitenBiker
 */
export const fetchingAssistantBiker = (state)=> state.fetchAssitenBikerInit;
export const fetchedAssistantBiker = (state)=> state.fetchAssitenBiker;
export const fetchedErrorAssistantBiker = (state)=> state.fetchErrorAssitenBiker;

/*
  fetchUpdateAssistentInit
  fetchUpdateAssistent
  fetchErrorUpdateAssistent
*/
export const fetchingUpdateAssistence = (state)=> state.fetchUpdateAssistentInit;
export const fetchedUpdateAssistence = (state)=> state.fetchUpdateAssistent;
export const fetchedErrorUpdateAssistence = (state)=> state.fetchErrorUpdateAssistent;