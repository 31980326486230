/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import * as actions from "../../logic/actions/issues";
import * as selectors from "../../logic/selectors/issues";
import { useDispatch, useSelector } from "react-redux";
import { getController, getAuthEmail } from "../../logic/reducers";
const IssueReportDialog = ({ open, handleClose, capture }) => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const uploaded = useSelector(selectors.postIssueCompleted);
  const loading = useSelector(selectors.postIssueLoading);
  const error = useSelector(selectors.postIssueError);
  const controller = useSelector(getController);
  const email = useSelector(getAuthEmail);
  const onSave = () => {
    dispatch(
      actions.initCreatingIssue({
        file: capture,
        userName: controller.name,
        branchId: controller?.branchId,
        companyId: controller?.companyId,
        email: email,
        role: controller.role,
        comment: message,
      })
    );
  };
  useEffect(() => {
    if (uploaded || error) {
      handleClose();
    }
  }, [uploaded, error]);
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Reportar un problema</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {`Quieres dejar una sugerencia? deja aquí tu comentario para ayudarnos a mejorar`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="comment"
          label="Escribe aquí"
          type="comment"
          onChange={handleChange}
          fullWidth
        />
        <div style={{ width: "100%" }}>
          <img src={capture} width="100%" alt="imageCapture"/>
        </div>
      </DialogContent>
      <DialogActions>
        {!loading && (
          <div>
            <Button color="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button color="secondary" onClick={onSave}>
              Enviar
            </Button>
          </div>
        )}
        {loading && <p>espere un momento...</p>}
      </DialogActions>
    </Dialog>
  );
};
export default IssueReportDialog;
