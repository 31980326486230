import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  DataGrid,
  Column,
  HeaderFilter,
  StateStoring,
  FilterRow,
  FilterPanel,
  Export,
  SearchPanel,
  Pager,
  Scrolling,
  Paging,
  Editing,
} from "devextreme-react/data-grid";
import {
  getPricingConfig,
  updatePricingConfig,
} from "../../logic/services/pricingService";
import { Form } from "devextreme-react";
import { GroupItem, SimpleItem } from "devextreme-react/form";

const allowedPageSizes = [10, 20, 50];

const DashboardCircleByCompany = ({ companyId }) => {
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPricingData = async () => {
      try {
        const data = await getPricingConfig(companyId);
        const formattedData = formatPricingData({ companies: data });
        setPricingData(formattedData);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadPricingData();
  }, [companyId]);

  const formatPricingData = ({ companies }) => {
    if (!companies.pricingConfig || companies.pricingConfig.length === 0) {
      return [
        {
          companyName: companies.name,
          circle: "-",
          minDistance: "-",
          maxDistance: "-",
          cost: "-",
        },
      ];
    }
    return companies.pricingConfig.map((config, index) => ({
      companyName: index === 0 ? companies.name : "",
      circle: config.circle,
      cost: config.cost,
      maxDistance: config.maxDistance,
      minDistance: config.minDistance,
    }));
  };

  const handleRowInsert = async (e) => {
    try {
      const { data } = e;

      const newConfig = {
        pricingConfig: {
          id: companyId,
          pricingConfig: [
            {
              circle: data.circle,
              cost: Number(data.cost),
              minDistance: Number(data.minDistance),
              maxDistance: Number(data.maxDistance),
            },
          ],
        },
      };

      await updatePricingConfig(newConfig);
      // Actualizar el estado con la nueva configuración
      setPricingData([
        ...pricingData,
        { ...data, id: `${data.companyId}-${data.circle}`, companyId: companyId },
      ]);
    } catch (error) {
      console.error("Error adding pricing data:", error);
    }
  };

  const DataGridPricingConfig = ({ pricingData }) => (
    <DataGrid
      keyExpr="companyName"
      dataSource={pricingData}
      width={"100%"}
      showBorders={true}
      rowAlternationEnabled={true}
      noDataText="NO HAY INFORMACIÓN"
      repaintChangesOnly={true}
      columnAutoWidth={true}
      editing={{
        mode: "form",
        allowAdding: true,
        texts: {
          addRow: "AGREGAR",
        },
      }}
      onRowInserted={handleRowInsert}
    >
      <HeaderFilter visible={true} />
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey="PricingTableByCompany"
      />
      <SearchPanel visible={true} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        showNavigationButtons={true}
      />
      <FilterRow visible={false} />
      <FilterPanel
        visible={true}
        texts={{
          createFilter: "CREAR FILTRO",
          clearFilter: "ELIMINAR FILTRO",
          filterEnabledHint: "CREACIÓN DE FILTROS",
        }}
      />
      <Scrolling
        columnRenderingMode="virtual"
        showScrollbar="onHover"
        scrollByContent
      />
      <Editing
        mode="form"
        allowUpdating={true}
        texts={{ editRow: "" }}
        useIcons={true}
      >
        <Form labelLocation="top">
          <GroupItem>
            <SimpleItem dataField="circle" />
            <SimpleItem dataField="minDistance" />
            <SimpleItem dataField="maxDistance" />
            <SimpleItem dataField="cost" />
          </GroupItem>
        </Form>
        <Form />
      </Editing>
      <Paging defaultPageSize={20} />
      <Column
        dataField="companyName"
        caption={"Nombre de la Empresa".toUpperCase()}
        alignment={"center"}
        allowEditing={false}
      />
      <Column
        dataField="circle"
        caption={"Circulo".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
      />
      <Column
        dataField="minDistance"
        caption={"Distancia Min (km)".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
      />
      <Column
        dataField="maxDistance"
        caption={"Distancia Max (km)".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
      />
      <Column
        dataField="cost"
        caption={"Costo (Q)".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
        format={{
          type: "currency",
          precision: 2,
          currency: "GTQ",
          formatter: (value) =>
            value !== "-" ? `Q${value.toFixed(2)}` : value,
        }}
      />
      <Export enabled={true} allowExportSelectedData={true} />
    </DataGrid>
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <DataGridPricingConfig pricingData={pricingData} />
      )}
    </>
  );
};

export default DashboardCircleByCompany;
