import React from "react";
import {
  Typography,
  Grid,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import "./index.css";
import WarningIcon from "@material-ui/icons/Warning";
const ErrorView = () => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Lato",
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/logotipos%2F404%20error.PNG?alt=media&token=69f40e0f-37d3-4ebf-bef8-6b453bd0d984"
          className="contenedor"
          alt = "employee"
        />
        <Grid item xs={12}>
          <WarningIcon
            style={{
              color: "#F44336",
              width: "35px",
              height: "auto",
            }}
          />
        </Grid>
        <Typography
          style={{
            color: "#5499C7",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            textAlign: "center",
            fontWeight: 600,
          }}
          variant="subtitle1"
        >
          LA ORDEN INGRESADA NO FUE ENCONTRADA, COMUNIQUESE CON PBX
        </Typography>
      </div>
    </ThemeProvider>
  );
};

export default ErrorView;
