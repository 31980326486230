import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CheckCircleOutline as DeliveredIcon,
  DirectionsOutlined as InRouteIcon,
  Close as Cancelled,
} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const TopPilots = ({ ordersDay }) => {
  const [topCount, setTopCount] = useState(5);
  const ordersDeliveries = ordersDay.filter(
    (order) => order.status === "Entregado" || order.status === "En ruta" || order.status === "Cancelado"
  );

  const topPilotsbyDay = () => {
    const pilotOrders = {};
    ordersDeliveries.forEach((order) => {
      const pilotName = order.alias?.name.toUpperCase() || "Unassigned";
      if (!pilotOrders[pilotName]) {
        pilotOrders[pilotName] = { delivered: 0, inRoute: 0, cancelled: 0 };
      }
      if (order.status === "Entregado") {
        pilotOrders[pilotName].delivered += 1;
      } else if (order.status === "En ruta") {
        pilotOrders[pilotName].inRoute += 1;
      } else if (order.status === "Cancelado") {
        pilotOrders[pilotName].cancelled += 1;
      }
    });

    const topPilots = Object.entries(pilotOrders)
      .sort((a, b) => b[1].delivered - a[1].delivered)
      .slice(0, 10);

    return topPilots;
  };
  
  const topPilots = topPilotsbyDay(ordersDay).slice(0, topCount);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <CardContent style={{ flex: "1 0 auto", textAlign: "center" }}>
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          {`Top ${topCount} Ordenes por Piloto`.toUpperCase()}
        </Typography>
        <Box display="flex" justifyContent="center" mb={2}>
          <ButtonGroup
            color="secondary"
            variant="outlined"
            aria-label="outlined secondary button group"
          >
            <Button
              variant={topCount === 5 ? "contained" : "outlined"}
              onClick={() => setTopCount(5)}
            >
              Top 5
            </Button>
            <Button
              variant={topCount === 10 ? "contained" : "outlined"}
              onClick={() => setTopCount(10)}
            >
              Top 10
            </Button>
          </ButtonGroup>
        </Box>
        <List>
          {topPilots.map(([pilot, data], index) => (
            <div key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#f26724" }}>
                    {pilot.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={pilot} />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box display="flex" alignItems="center">
                    <Tooltip title={`Entregadas: ${data.delivered}`}>
                      <span>
                        <IconButton
                          size="small"
                          disabled={data.delivered === 0}
                        >
                          <DeliveredIcon
                            color={data.delivered > 0 ? "success" : "disabled"}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography variant="body2">{data.delivered}</Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box display="flex" alignItems="center">
                    <Tooltip title={`En ruta: ${data.inRoute}`}>
                      <span>
                        <IconButton size="small" disabled={data.inRoute === 0}>
                          <InRouteIcon
                            color={data.inRoute > 0 ? "primary" : "disabled"}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography variant="body2">{data.inRoute}</Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box display="flex" alignItems="center">
                    <Tooltip title={`Cancelado: ${data.cancelled}`}>
                      <span>
                        <IconButton
                          size="small"
                          disabled={data.cancelled === 0}
                        >
                          <Cancelled
                            color={data.cancelled > 0 ? "error" : "disabled"}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography variant="body2">{data.cancelled}</Typography>
                  </Box>
                </Stack>
              </ListItem>
              {index < topPilots.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default TopPilots;
