import React, { useState } from "react";
import * as yup from "yup";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import DynamicForm from "../Design/Form/DynamicForm";
import { updateBikerStatus } from "../../firebase/pilots";
import {
  snackBarConst,
  INPUT_TYPE,
  BIKER_STATUS_OPTIONS,
} from "../../constants/constants";
import { isEmpty } from "lodash";

const validationSchema = yup.object({
  status: yup
    .object()
    .shape({ text: yup.string().required("El estado es requerido") })
    .required(),
});

export default ({ onModalClose, biker }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    status:
      !isEmpty(biker) && !isEmpty(biker.status)
        ? {
            value: biker.status.toLowerCase(),
            text:
              biker.status.toLowerCase() ===
              BIKER_STATUS_OPTIONS.AVAILABLE.value.toLowerCase()
                ? BIKER_STATUS_OPTIONS.AVAILABLE.text
                : BIKER_STATUS_OPTIONS.UNAVAILABLE.text,
          }
        : {},
  });
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formFields, setFormFields] = useState([
    {
      label: "Estado",
      name: "status",
      cssClasses: "col-12",
      fullWidth: true,
      inputType: INPUT_TYPE.FILTERABLE_LIST,
      options: [
        {
          ...BIKER_STATUS_OPTIONS.AVAILABLE,
        },
        {
          ...BIKER_STATUS_OPTIONS.UNAVAILABLE,
        },
      ],
    },
  ]);

  const btnList = [
    {
      id: "cancel-biker",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-biker",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "aceptar",
    },
  ];

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (!isEmpty(values.status)) {
      try {
        await updateBikerStatus(biker.id, values.status.value);
        enqueueSnackbar("El estado del piloto fue actualizado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        onModalClose();
      } catch (e) {
        enqueueSnackbar("Hubo un error al actualizar el estado del piloto", {
          variant: "warning",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="modal-auto-height">
      <div className="modal-container-auto-height">
        <div className="modal-title">
          <div className="modal-title-text">ESTADO DEL PILOTO</div>
        </div>
        <div className="modal-body all-upper-case">
          <BlockUi tag="div" blocking={isLoading}>
            <DynamicForm
              obj={data}
              fields={formFields}
              validationSchema={validationSchema}
              buttons={btnList}
              onSubmit={onSubmit}
              btnAlignment="right"
              setState={setData}
              btnCssClass="modal-footer"
            />
          </BlockUi>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
