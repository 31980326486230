import React from 'react';
import {Card,CardContent,makeStyles,Typography,Avatar} from "@material-ui/core";
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
      height:150,
      display:'flex'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    content: {
        flex: '1 0 auto',
        textAlign:'center'
      },
    icon:{
        width:100,
        margin:'auto'
    },
    pos: {
      marginBottom: 12,
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor:theme.palette.primary.main
      },
  }));
const ResumeCard = ({title,count,Icon}) =>{
    const classes = useStyles();
    return(
        <Card className={classes.root} variant="outlined">
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5" color="textSecondary">
                  {title.toUpperCase()}
              </Typography>
              <Typography variant="h3">
                {count}
              </Typography>
            </CardContent>
            <div className={classes.icon}>
                <Avatar className={classes.large}>
                    <Icon/>
                </Avatar>
            </div>
        </Card>
    );
}
export default ResumeCard;