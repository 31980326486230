import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import { orderActions } from "../../logic/actions/orders";
import {
  snackBarConst,
  PROVIDER_TYPE,
  INPUT_TYPE,
  orderStatus,
} from "../../constants/constants";
import Alert from "@material-ui/lab/Alert";
import CreateIcon from "@material-ui/icons/Create";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";
import {
  getCreateOrderDone,
  getCreateOrderError,
  getCreateOrderIsLoading,
} from "../../logic/selectors/orders";
import addressService from "../../logic/services/addressService";

const validationSchema = yup.object({
  orderId: yup.string().required("Orden es requerido"),
  zona: yup.string().required("La zona es requerida"),
  address: yup.string().required("Direccion es requerida"),
  name: yup.string().required("Cliente es requerido"),
  phone: yup.number().required("Télefono es requerido").typeError("Télefono debe ser un valor númerico"),
  payMethod: yup
    .object()
    .shape({ text: yup.string().required("Forma de Pago es requerida") })
    .required(),
  country: yup
    .object()
    .shape({ text: yup.string().required("Pais es requerido") })
    .required(),
  departament: yup
    .object()
    .shape({ text: yup.string().required("Departamento es requerido") })
    .required(),
  city: yup
    .object()
    .shape({ text: yup.string().required("Ciudad es requerido") })
    .required(),
});

const formFields = [
  {
    label: "Orden",
    name: "orderId",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Cliente",
    name: "name",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Direccion",
    name: "address",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "País",
    name: "country",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
  },
  {
    label: "Departamento",
    name: "departament",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Ciudad",
    name: "city",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Zona",
    name: "zona",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Teléfono",
    name: "phone",
    cssClasses: "col-12",
    fullWidth: true,
    type: "number",
  },
  {
    label: "Observaciones",
    name: "nota",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Forma de Pago",
    name: "payMethod",
    cssClasses: "col-12",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    fullWidth: true,
    options: [
      {
        value: "cash",
        text: "Efectivo",
      },
      {
        value: "credit-card",
        text: "Tarjeta de Crédito",
      },
      {
        value: "visa-link",
        text: "Visa en Link",
      },
    ],
  },
  {
    label: "Agendar",
    name: "toBeDeliveredAt",
    cssClasses: "col-12",
    fullWidth: true,
    type: "date",
  },
  {
    label: "Prioritario",
    name: "isPriority",
    cssClasses: "col-12",
    fullWidth: true,
    inputType: INPUT_TYPE.CHECKBOX,
  },
];

export const emptyBranch = {
  orderId: "",
  name: "",
  address: "",
  zona: "",
  country: {},
  departament: {},
  city: {},
  phone: 0,
  nota: "",
  payMethod: {},
  toBeDeliveredAt: "",
  isPriority: false,
};

export default ({ onModalClose, branchData }) => {
  const dispatch = useDispatch();
  const createOrderDone = useSelector(getCreateOrderDone);
  const createOrderError = useSelector(getCreateOrderError);
  const isLoadingcreateOrder = useSelector(getCreateOrderIsLoading);
  const { enqueueSnackbar } = useSnackbar();
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [customErrors, setCustomErrors] = useState([]);
  const [branch, setBranch] = useState(emptyBranch);
  const [isStarted, setIsStarted] = useState(false);
  const isEditing = false;

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear orden",
    },
  ];
  // is modal create order
  useOnCreatingOrder(
    createOrderDone,
    createOrderError,
    isLoadingcreateOrder,
    dispatch,
    enqueueSnackbar,
    onModalClose
  );

  const onSubmit = (values) => {
    const orderData = {
      ...values,
      country: !isEmpty(values.country) ? values.country.text : "",
      departament: !isEmpty(values.departament) ? values.departament.text : "",
      city: !isEmpty(values.city) ? values.city.text : "",
      payMethod: !isEmpty(values.payMethod) ? values.payMethod.value : "",
      company_id: branchData.companyId,
      branch_id: branchData.branchId,
      provider: PROVIDER_TYPE.BRANCH,
      status: orderStatus.ON_HOLD,
      without_biker: "Yes",
      isPriority: values.isPriority ? "Yes" : "No",
    };
    dispatch(orderActions.createOrder(orderData));
  };

  // new requeried fields
  const clearOptions = (regions = false, cities = false) => {
    setCustomFormFields((vals) => {
      let newValues = [...vals];
      if (regions) {
        setBranch((br) => ({
          ...br,
          departament: {},
        }));
        const fieldIndex = newValues.findIndex(
          (field) => field.name === "departament"
        );
        newValues[fieldIndex].options = [];
      }
      if (cities) {
        setBranch((br) => ({
          ...br,
          city: {},
        }));
        const fieldIndex = newValues.findIndex(
          (field) => field.name === "city"
        );
        newValues[fieldIndex].options = [];
      }
      return [...newValues];
    });
  };

  const updateFormFields = (fieldName, options, onValueChanged) => {
    setCustomFormFields((vals) => {
      let newValues = [...vals];
      const fieldIndex = newValues.findIndex(
        (field) => field.name === fieldName
      );
      newValues[fieldIndex].options = (options || []).map((opt) => ({
        value: opt.id,
        text: opt.name,
      }));
      newValues[fieldIndex].isLoading = false;
      if (onValueChanged) {
        newValues[fieldIndex].onValueChanged = onValueChanged;
      }
      return [...newValues];
    });
  };

  const loadCities = async (region, country) => {
    try {
      const response = await addressService.getCitiesByRegionIdByCountryCode(
        region,
        country
      );
      updateFormFields("city", response);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de ciudades, por favor, intenta más tarde",
      ]);
    }
  };

  const onRegionSelected = async (region) => {
    if (isEmpty(region)) {
      clearOptions(false, true);
      return;
    }
    if (branch.departament.value !== region.value) {
      clearOptions(false, true);
    }
    await loadCities(region.value, branch.country.value);
  };

  const loadRegions = async (country) => {
    try {
      const response = await addressService.getRegionsByCountryCode(country);
      updateFormFields("departament", response, onRegionSelected);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de regiones, por favor, intenta más tarde",
      ]);
    }
  };

  const onCountrySelected = async (country) => {
    if (isEmpty(country)) {
      clearOptions(true, true);
      return;
    }
    if (branch.country.value !== country.value) {
      clearOptions(true, true);
    }
    await loadRegions(country.value);
  };

  const getCountries = async () => {
    try {
      const response = await addressService.getCountries();
      updateFormFields("country", response, onCountrySelected);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de países, por favor, intenta más tarde",
      ]);
    }
  };

  const loadAddressInformation = async () => {
    await getCountries();
    await loadRegions(branch.country.value);
    await loadCities(branch.departament.value, branch.country.value);
  };

  useEffect(() => {
    if (!isEditing) {
      getCountries();
      setIsStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(branch) && isEditing && !isStarted) {
      loadAddressInformation();
      setIsStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  return (
    <div className="modal-container modal-container-create-order">
      <div className="modal-title">
        <div className="modal-title-text">
          <CreateIcon
            style={{
              width: "40px",
              height: "auto",
            }}
          />
        </div>
      </div>
      <div className="modal-body-new all-upper-case">
        <BlockUi tag="div" blocking={isLoadingcreateOrder}>
          {!isEmpty(customErrors) && (
            <div className="custom-errors">
              {customErrors.map((customError, i) => (
                <Alert key={`custom-error-${i}`} severity="error">
                  {customError}
                </Alert>
              ))}
            </div>
          )}
          <DynamicForm
            obj={branch}
            fields={customFormFields}
            validationSchema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            setState={setBranch}
            btnCssClass="modal-footer"
            forceSetState
          />
        </BlockUi>
      </div>
    </div>
  );
};

const useOnCreatingOrder = (
  createOrderDone,
  createOrderError,
  isLoadingcreateOrder,
  dispatch,
  enqueueSnackbar,
  onModalClose
) => {
  useEffect(() => {
    if (!isLoadingcreateOrder) {
      if (createOrderDone) {
        enqueueSnackbar("Orden creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearCreateOrder());
        onModalClose();
      } else if (!isEmpty(createOrderError)) {
        enqueueSnackbar(
          "Hubo un error al crear la orden, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderDone, createOrderError, isLoadingcreateOrder]);
};
