import { isEmpty } from "lodash";
import { firebaseFirestore } from "./config";

const db = firebaseFirestore;
const collection = "branches";

export const getAllBranchOffices = async ({ branchId, companyId } = {}) => {
  try {
    let query = db.collection(collection);
    if (!isEmpty(branchId)) {
      query = query.where("branchId", "==", branchId);
    }
    if (!isEmpty(companyId)) {
      query = query.where("companyId", "==", companyId);
    }
    const branchOffices = await query.get();

    let branchOfficesArray = [];
    await branchOffices.docs.forEach((branchOffice) => {
      let meanwhile = branchOffice.data();
      meanwhile["id"] = branchOffice.id;
      branchOfficesArray.push(meanwhile);
    });

    let brachOfficesNormalizer = {};
    let branchOfficeById = [];

    brachOfficesNormalizer["order"] = branchOfficesArray.map(
      (branchOffice) => branchOffice.id
    );
    branchOfficesArray.map(
      (branchOffice) => (branchOfficeById[branchOffice.id] = branchOffice)
    );
    brachOfficesNormalizer["byId"] = branchOfficeById;
    brachOfficesNormalizer["array"] = branchOfficesArray;

    return {
      branchOffices: brachOfficesNormalizer,
      error: null,
    };
  } catch (error) {
    console.log("FIREBASE BRANCHOFFICE ERROR --> ", error);
    let code = error.code;
    let message = error.message;

    return {
      branchOffices: null,
      error: {
        code: code,
        message: message,
      },
    };
  }
};

export const getBranches = async ({ companyId, branchId } = {}) => {
  let query = db.collection(collection);
  if (!isEmpty(companyId)) {
    query = query.where("companyId", "==", companyId);
  }
  if (!isEmpty(branchId)) {
    query = query.where("branchId", "==", branchId);
  }
  const branchOffices = await query.get();

  let branchOfficesArray = [];
  await branchOffices.docs.forEach((branchOffice) => {
    let meanwhile = branchOffice.data();
    meanwhile["id"] = branchOffice.id;
    branchOfficesArray = branchOfficesArray.concat([meanwhile]);
  });

  return branchOfficesArray;
};
