/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import CircularProcess from "@material-ui/core/CircularProgress";
import { orderActions } from "../../logic/actions/orders";
import Theme from "../Theme/Theme";
import { getOrder, getError, getIsLoading } from "../../logic/selectors/orders";
import { isEmpty } from "lodash";

const Order = ({ match: { params }, location: { search } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector(getError);
  const order = useSelector(getOrder);
  const isOrderLoading = useSelector(getIsLoading);
  const [isLoaded, setIsLoaded] = useState(false);
  const queryParams = queryString.parse(search);

  useOnLoad(params.orderId, dispatch);
  useOnOrderLoaded(order, error, isOrderLoading, isLoaded, setIsLoaded);

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  const renderBackButton = () => {
    if (isEmpty(queryParams.back)) {
      return <></>;
    }
    return (
      <div className="margin-bottom-md">
        <Button
          size="small"
          type="button"
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Regresar
        </Button>
      </div>
    );
  };

  const renderView = () => {
    if (!isEmpty(error)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intetrlo más tarde!
        </Alert>
      );
    }
    if (isEmpty(order)) {
      return <Alert severity="warning">Obteniendo información...</Alert>;
    }
    return (
      <div className="col-6">
        {renderBackButton()}
        <div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Orden</strong>
            </div>
            <div className="col-7">{order.orderId}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>2Wheeler</strong>
            </div>
            <div className="col-7">
              {isEmpty(order.alias) ? "N/A" : order.alias.name}
            </div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Cliente</strong>
            </div>
            <div className="col-7">{order.name}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Dirección</strong>
            </div>
            <div className="col-7">{order.address}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Departamento</strong>
            </div>
            <div className="col-7">{order.depto}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Municipio</strong>
            </div>
            <div className="col-7">{order.munic}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Zona</strong>
            </div>
            <div className="col-7">{order.zone}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Observaciones</strong>
            </div>
            <div className="col-7">{order.nota}</div>
          </div>
          <div className="margin-bottom-sm pilot-data-display">
            <div className="col-5">
              <strong>Teléfono</strong>
            </div>
            <div className="col-7">{order.phone}</div>
          </div>
        </div>
      </div>
    );
  };

  if (!isEmpty(queryParams.back)) {
    return <div>{isLoaded ? renderView() : renderLoading()}</div>;
  }

  return (
    <Theme title={`Order ${!isEmpty(order) && `#${order.orderId}`}`}>
      {isLoaded ? renderView() : renderLoading()}
    </Theme>
  );
};

const useOnOrderLoaded = (
  order,
  error,
  isOrderLoading,
  isLoaded,
  setIsLoaded
) => {
  useEffect(() => {
    if (!isLoaded && !isOrderLoading) {
      setIsLoaded(true);
    }
  }, [order, error, isOrderLoading, isLoaded]);
};

const useOnLoad = (orderId, dispatch) => {
  useEffect(() => {
    dispatch(orderActions.getOrderByOrderId(orderId));
  }, []);
};

export default Order;
