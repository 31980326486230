import React from "react";
import { CanvasJSChart } from "canvasjs-react-charts";

export default ({ data, max }) => {
  const options = {
    animationEnabled: true,
    title: {
      text: `TOTAL ${max}`,
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {result}",
        dataPoints: data,
      },
    ],
  };
  return (
    <div className="relative-pos">
      <CanvasJSChart options={options} />
      <div className="canvas-js-hidden" />
    </div>
  );
};
