import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import BreadCrumb from "../../containers/navs/breadcrumb";
import {
  DataGrid,
  Column,
  HeaderFilter,
  StateStoring,
  Paging,
  Pager,
  Scrolling,
  Editing,
  Form,
  Export,
  SearchPanel,
  FilterRow,
  FilterPanel,
} from "devextreme-react/data-grid";
import { SimpleItem, GroupItem } from "devextreme-react/form";
import {
  getAllPricingConfigs,
  updatePricingConfig,
} from "../../logic/services/pricingService";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

const allowedPageSizes = [10, 20, 50];

const DashboardCircleByCompany = () => {
  const [pricingData, setPricingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPricingData = async () => {
      try {
        const data = await getAllPricingConfigs();
        const formattedData = formatPricingData(data);
        setPricingData(formattedData);
      } catch (error) {
        console.error("Error loading pricing data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPricingData();
  }, []);

  const formatPricingData = (companies) => {
    return companies.flatMap((company) => {
      if (!company.pricingConfig || company.pricingConfig.length === 0) {
        return {
          companyName: company.name,
          circle: "-",
          minDistance: "-",
          maxDistance: "-",
          cost: "-",
          id: `${company.companyId}-1`,
          companyId: company.companyId, // ID único para la empresa
        };
      }

      return company.pricingConfig.map((config) => ({
        companyName: company.name,
        circle: config.circle,
        cost: config.cost,
        maxDistance: config.maxDistance,
        minDistance: config.minDistance,
        id: `${company.companyId}-${config.circle}`, // ID único para la combinación empresa-círculo
        companyId: company.companyId,
      }));
    });
  };

  const handleRowUpdate = async (e) => {
    try {
      const { data, key } = e;

      const updatedConfig = {
        pricingConfig: {
          id: data.companyId,
          pricingConfig: [
            {
              circle: data.circle,
              cost: Number(data.cost),
              minDistance: Number(data.minDistance),
              maxDistance: Number(data.maxDistance),
            },
          ],
        },
      };

      await updatePricingConfig(updatedConfig);

      setPricingData((prevData) =>
        prevData.map((item) => (item.id === key ? { ...item, ...data } : item))
      );
    } catch (error) {
      console.error("Error updating pricing data:", error);
    }
  };

  const renderEditButton = () => (
    <button className="dx-link dx-link-edit">
      <SettingsApplicationsIcon />
    </button>
  );

  const DataGridPricingConfig = ({ pricingData }) => (
    <DataGrid
      keyExpr="id"
      dataSource={pricingData}
      width={"100%"}
      showBorders={true}
      rowAlternationEnabled={true}
      noDataText="NO HAY INFORMACIÓN"
      repaintChangesOnly={true}
      columnAutoWidth={true}
      onRowUpdated={handleRowUpdate}
    >
      <HeaderFilter visible={true} />
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey="PricingTableByCompany"
      />
      <SearchPanel visible={true} />
      <Paging defaultPageSize={20} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        showNavigationButtons={true}
      />
      <FilterRow visible={false} />
      <Scrolling
        columnRenderingMode="virtual"
        showScrollbar="onHover"
        scrollByContent
      />
      <FilterPanel
        visible={true}
        texts={{
          createFilter: "CREAR FILTRO",
          clearFilter: "ELIMINAR FILTRO",
          filterEnabledHint: "CREACIÓN DE FILTROS",
        }}
      />
      <Editing
        mode="form"
        allowUpdating={true}
        texts={{ editRow: "" }}
        useIcons={true}
        buttons={[
          {
            name: "edit",
            render: renderEditButton,
          },
        ]}
      >
        <Form labelLocation="top">
          <GroupItem>
            <SimpleItem dataField="circle" />
            <SimpleItem dataField="minDistance" />
            <SimpleItem dataField="maxDistance" />
            <SimpleItem dataField="cost" />
          </GroupItem>
        </Form>
        <Form />
      </Editing>
      <Export enabled={true} allowExportSelectedData={true} />
      <Column
        dataField="companyName"
        caption={"Nombre de la Empresa".toUpperCase()}
        alignment={"center"}
        allowEditing={false}
      />
      <Column
        dataField="circle"
        caption={"Círculo".toUpperCase()}
        alignment={"center"}
      />
      <Column
        dataField="minDistance"
        caption={"Distancia Min (km)".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
      />
      <Column
        dataField="maxDistance"
        caption={"Distancia Max (km)".toUpperCase()}
        alignment={"center"}
        editorType="dxNumberBox"
      />
      <Column
        dataField="cost"
        caption={"Costo (Q)".toUpperCase()}
        alignment={"center"}
        format={{
          type: "currency",
          precision: 2,
          currency: "GTQ",
          formatter: (value) =>
            value !== "-" ? `Q${value.toFixed(2)}` : value,
        }}
        editorType="dxNumberBox" // Editor numérico
      />
    </DataGrid>
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <BreadCrumb
            match={{ path: "/" }}
            title={"Todos los Círculos de las empresa".toUpperCase()}
          />
          <DataGridPricingConfig pricingData={pricingData} />
        </>
      )}
    </>
  );
};

export default DashboardCircleByCompany;
