import React from "react";
import BreadCrumb from "../../containers/navs/breadcrumb";
import DailyPerformance from "./DailyPerformance";
import { useHistory } from "react-router-dom";

const DailyPerformancePage = () => {
  const history = useHistory();

  return (
    <div title="rendimiento diario">
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RESÚMEN DE HOY"
      />
      <DailyPerformance />
    </div>
  );
};

export default DailyPerformancePage;
