import React from "react";
import Divider from "@material-ui/core/Divider";

const BikerRaitingModal = ({ onModalClose, biker }) => {
  return (
    <div className="modal-container modal-container-height">
      <div className="modal-title">
        <div className="modal-title-text">{biker.name}</div>
        <div className="close" onClick={() => onModalClose()}>
          x
        </div>
      </div>
      <div className="modal-body">
        {biker.raitings.map((raiting) => (
          <>
            <div className="margin-top-sm margin-bottom-sm">
              {/* <div>
              <div className="col-4">De</div>
              <div className="col-8">{raiting.from}</div>
            </div> */}
              <div>
                <div className="col-4">COMENTARIO :</div>
                <div className="col-8">{raiting.message}</div>
              </div>
              <div>
                <div className="col-4">CALIFICACIÓN :</div>
                <div className="col-8">{raiting.rating}</div>
              </div>
            </div>
            <Divider />
          </>
        ))}
      </div>
    </div>
  );
};

export default BikerRaitingModal;
