import React from "react";
import Modal from "@material-ui/core/Modal";

export default ({ isOpened, onClose, body }) => {
  const [open, setOpen] = React.useState(isOpened);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
