import {
  CANCELLED_ORDERS_ON_SAVE_FILTERS,
  CANCELLED_ORDERS_ON_REMOVE_FILTERS,
  ABANDONED_ORDERS_ON_SAVE_FILTERS,
  ABANDONED_ORDERS_ON_REMOVE_FILTERS,
  DELIVERED_ORDERS_ON_SAVE_FILTERS,
  DELIVERED_ORDERS_ON_REMOVE_FILTERS,
  IN_ROUTE_ORDERS_ON_SAVE_FILTERS,
  IN_ROUTE_ORDERS_ON_REMOVE_FILTERS,
  DELETED_ORDERS_ON_SAVE_FILTERS,
  DELETED_ORDERS_ON_REMOVE_FILTERS,
  RE_AGEND_ORDERS_ON_SAVE_FILTERS,
  RE_AGEND_ORDERS_ON_REMOVE_FILTERS,
} from "../types/filters";

export const cancelledOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: CANCELLED_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: CANCELLED_ORDERS_ON_REMOVE_FILTERS,
  }),
};

export const abandonedOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: ABANDONED_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: ABANDONED_ORDERS_ON_REMOVE_FILTERS,
  }),
};

export const deliveredOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: DELIVERED_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: DELIVERED_ORDERS_ON_REMOVE_FILTERS,
  }),
};

export const inRouteOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: IN_ROUTE_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: IN_ROUTE_ORDERS_ON_REMOVE_FILTERS,
  }),
};

export const deletedOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: DELETED_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: DELETED_ORDERS_ON_REMOVE_FILTERS,
  }),
};

export const reAgendOrdersFilterActions = {
  onFilterSave: (filters) => ({
    type: RE_AGEND_ORDERS_ON_SAVE_FILTERS,
    payload: [...filters],
  }),
  clearFilters: () => ({
    type: RE_AGEND_ORDERS_ON_REMOVE_FILTERS,
  }),
};
