//  FETCH PILOTS
export const PILOTS_FETCH_STARTED = 'PILOTS_FETCH_STARTED';
export const PILOTS_FETCH_COMPLETED = 'PILOTS_FETCH_COMPLETED';
export const PILOTS_FETCH_FAILED = 'PILOTS_FETCH_FAILED';

// GET PILOT BY PILOT ID
export const GET_PILOT_BY_PILOT_ID = 'GET_PILOT_BY_PILOT_ID';
export const GET_PILOT_BY_PILOT_ID_SUCCESS = 'GET_PILOT_BY_PILOT_ID_SUCCESS';
export const GET_PILOT_BY_PILOT_ID_FAILURE = 'GET_PILOT_BY_PILOT_ID_FAILURE';
export const CLEAR_PILOT_BY_ID_DATA = 'CLEAR_PILOT_BY_ID_DATA';

// SUBSCRIBE PILOT BY PILOT ID
export const SUBSCRIBE_PILOT_BY_PILOT_ID = 'SUBSCRIBE_PILOT_BY_PILOT_ID';
export const SUBSCRIBE_PILOT_BY_PILOT_ID_SUCCESS = 'SUBSCRIBE_PILOT_BY_PILOT_ID_SUCCESS';
export const SUBSCRIBE_PILOT_BY_PILOT_ID_FAILURE = 'GET_PILOT_BY_PILOT_ID_FAILURE';
export const CLEAR_SUBSCRIBE_PILOT_BY_PILOT_ID = 'CLEAR_SUBSCRIBE_PILOT_BY_PILOT_ID';


// GET PILOT BY PILOT ID
export const GET_PILOTS = 'GET_PILOTS';
export const GET_PILOTS_SUCCESS = 'GET_PILOTS_SUCCESS';
export const GET_PILOTS_FAILURE = 'GET_PILOTS_FAILURE';
export const CLEAR_PILOT_DATA = 'CLEAR_PILOT_DATA';

//  ADD PILOT
export const PILOT_ADDED = 'PILOT_ADDED';
export const PILOT_CLEAR = 'PILOT_CLEAR';

//  EDIT PILOT
export const PILOT_EDITED = 'PILOT_EDITED';

//  REMOVE PILOT
export const PILOT_REMOVED = 'PILOT_REMOVED';

//  SELECT A SPECIFIC PILOT
export const SELECT_PILOT = 'SELECT_PILOT';

//  DESELECT A PILOT
export const DESELECT_PILOT = 'DESELECT_PILOT';

export const FETCH_SINGLE_PILOT_STARTED = 'FETCH_SINGLE_PILOT_STARTED';
export const FETCH_SINGLE_PILOT_COMPLETED = 'FETCH_SINGLE_PILOT_COMPLETED';
export const FETCH_SINGLE_PILOT_FAILED = 'FETCH_SINGLE_PILOT_FAILED';
//  CREATE BIKER
export const BIKER_CREATE_STARTED = 'BIKER_CREATE_STARTED';
export const BIKER_CREATE_COMPLETED = 'BIKER_CREATE_COMPLETED';
export const BIKER_CREATE_FAILED = 'BIKER_CREATE_FAILED';
//  EDIT BIKER
export const BIKER_EDIT_STARTED = 'BIKER_EDIT_STARTED';
export const BIKER_EDIT_COMPLETED = 'BIKER_EDIT_COMPLETED';
export const BIKER_EDIT_FAILED = 'BIKER_EDIT_FAILED';
export const BIKER_EDIT_CLEAR = 'BIKER_EDIT_CLEAR';
//  DELETE BIKER
export const BIKER_DELETE_STARTED = 'BIKER_DELETE_STARTED';
export const BIKER_DELETE_COMPLETED = 'BIKER_DELETE_COMPLETED';
export const BIKER_DELETE_FAILED = 'BIKER_DELETE_FAILED';
export const BIKER_DELETE_CLEAR = 'BIKER_DELETE_CLEAR';

//  FETCH PILOTS BY STATUS
export const BIKERS_BY_STATUS_STARTED='BIKERS_BY_STATUS_STARTED';
export const BIKERS_BY_STATUS_COMPLETED='BIKERS_BY_STATUS_COMPLETED';
export const BIKERS_BY_STATUS_FAILED='BIKERS_BY_STATUS_FAILED';
export const BIKERS_BY_STATUS_EMPTY='BIKERS_BY_STATUS_EMPTY';

//  AUTHORIZE PILOTS
export const AUTHORIZE_PILOT_STARTED='AUTHORIZE_PILOT_STARTED';
export const AUTHORIZE_PILOT_COMPLETED='AUTHORIZE_PILOT_COMPLETED';
export const AUTHORIZE_PILOT_FAILED='AUTHORIZE_PILOT_FAILED';

//  EDIT BIKER
export const BIKER_EDIT_ALERT_STARTED = 'BIKER_EDIT_ALERT_STARTED';
export const BIKER_EDIT_ALERT_COMPLETED = 'BIKER_EDIT_ALERT_COMPLETED';
export const BIKER_EDIT_ALERT_FAILED = 'BIKER_EDIT_ALERT_FAILED';
export const BIKER_EDIT_ALERT_CLEAR = 'BIKER_EDIT_ALERT_CLEAR';

// CREATE RATING PER BIKER
export const CREATE_BIKER_RATING_STARTED = 'CREATE_BIKER_RATING_STARTED';
export const CLEAR_BIKER_RATING_STARTED = 'CLEAR_BIKER_RATING_STARTED';
export const CREATE_BIKER_RATING_COMPLETED = 'CREATE_BIKER_RATING_COMPLETED';
export const CREATE_BIKER_RATING_FAILED = 'CREATE_BIKER_RATING_FAILED';

// GET RATING PER BIKER 
export const GET_BIKER_RATING_STARTED = 'GET_BIKER_RATING_STARTED';
export const GET_BIKER_RATING_COMPLETED = 'GET_BIKER_RATING_COMPLETED';
export const GET_BIKER_RATING_FAILED = 'GET_BIKER_RATING_FAILED';

// GET PILOT LINK
export const GET_PILOT_LINK_STARTED = 'GET_PILOT_LINK_STARTED';
export const GET_PILOT_LINK_COMPLETD = 'GET_PILOT_LINK_COMPLETD';
export const GET_PILOT_LINK_FAILED = 'GET_PILOT_LINK_FAILED';

export const GET_SCHEDULES_STARTED = 'GET_SCHEDULES_STARTED';
export const GET_SCHEDULES_COMPLETED ='GET_SCHEDULES_COMPLETED';
export const GET_SCHEDULES_FAILED = 'GET_SCHEDULES_FAILED';


// GET BIKER SCHEDULES IN THE MONTH 

export const GET_SCHEDULES_MONTH_COMPLETED = "GET_SCHEDULES_MONTH_COMPLETED";
export const GET_SCHEDULES_MONTH_STARTED = "GET_SCHEDULES_MONTH_STARTED";
export const GET_SCHEDULES_MONTH_FAILED = "GET_SCHEDULES_MONTH_FAILED";

// CREATING RANKING PILOTS

export const CREATING_RANKING_STARTED = "CREATING_RANKING_STARTED"
export const CREATING_RANKING_COMPLETED  = "CREATING_RANKING_COMPLETED" 
export const CREATING_RANKING_FAILED  = "CREATING_RANKING_FAILED"

// GETTING BIKER ONLY BRANCH SEND 
export const GET_BIKER_BRANCH_STARTED = "GET_BIKER_BRANCH_STARTED" 
export const GET_BIKER_BRANCH_COMPLETED = "GET_BIKER_BRANCH_COMPLETED"
export const GET_BIKER_BRANCH_FAILED = "GET_BIKER_BRANCH_FAILED"

// GETTING SCHEDULES
export const GET_SCHEDULE_STARTED = "GET_SCHEDULE_STARTED" 
export const GET_SCHEDULE_COMPLETED = "GET_SCHEDULE_COMPLETED"
export const GET_SCHEDULE_FAILED = "GET_SCHEDULE_FAILED"

// GETTING ASSISTENCE
export const GET_ASSISTENT_BIKER_STARTED = "GET_ASSISTENT_BIKER_STARTED" 
export const GET_ASSISTENT_BIKER_COMPLETED = "GET_ASSISTENT_BIKER_COMPLETED"
export const GET_ASSISTENT_BIKER_FAILED = "GET_ASSISTENT_BIKER_FAILED"

// UPDATE ASSISTENCE BIKER
export const GET_UPDATE_ASSISTENCE_BIKER_STARTED = "GET_UPDATE_ASSISTENCE_BIKER_STARTED" 
export const GET_UPDATE_ASSISTENCE_BIKER_COMPLETED = "GET_UPDATE_ASSISTENCE_BIKER_COMPLETED"
export const GET_UPDATE_ASSISTENCE_BIKER_FAILED = "GET_UPDATE_ASSISTENCE_BIKER_FAILED"