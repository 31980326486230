import { store } from "../components/App";
import * as selectors from "../logic/reducers";
import { getSubscribeBiker } from "../logic/selectors/bikers";
import * as actions from "../logic/actions/pilots";
import { isEmpty } from "lodash";
import { firebaseFirestore } from "./config";

const db = firebaseFirestore;
const collection = "bikers";

export const getPilotByPilotId = async (id) => {
  const pilot = await db
    .collection(collection)
    .doc(`${id}`)
    .get();
  const biker = pilot.data();
  if (!biker) return biker;
  biker.id = pilot.id;
  return {
    biker,
  };
};
export const createRatingComment = async ({
  bikerId,
  message,
  rating,
  controllerId,
}) => {
  let date = new Date();
  await db.collection("rating").add({
    message: message,
    rating: rating,
    createdAt: date,
    from: controllerId,
    to: bikerId,
  });
  await db
    .collection(collection)
    .doc(bikerId)
    .update({ qualified: true });
};
export const subscribePilotByPilotId = async (docId) => {
  db.collection(collection)
    .doc(`${docId}`)
    .onSnapshot((observer) => {
      const pilot = observer.data();
      if (isEmpty(pilot)) {
        store.dispatch(
          actions.pilotActions.getPilotByPilotIdFailure("pilot not found")
        );
        return;
      }
      const id = observer.id;
      if (docId === id) {
        const currState = store.getState();
        const currBiker = getSubscribeBiker(currState);
        if (
          isEmpty(currBiker) ||
          (!isEmpty(currBiker) && id === currBiker.id)
        ) {
          store.dispatch(
            actions.pilotActions.subscribePilotByPilotIdSuccess({
              ...pilot,
              id,
            })
          );
        }
      }
    });
};

export const getPilots = async (filter = {}) => {
  try {
    let query = await db.collection(collection);
    if (!isEmpty(filter.branchId)) {
      query = query.where("branchId", "==", filter.branchId);
    }
    if (!isEmpty(filter.companyId)) {
      query = query.where("companyId", "==", filter.companyId);
    }
    const pilots = await query.get();
    let pilotsArray = [];
    await pilots.docs.forEach((pilot) => {
      let meanwhile = pilot.data();
      meanwhile["id"] = pilot.id;

      pilotsArray.push(meanwhile);
    });
    return pilotsArray;
  } catch (error) {
    console.log("FIREBASE PILOT ERROR --> ", error);
    return {
      users: null,
      error: error,
    };
  }
};

export const subscribePilost2 = async (filter = {}) => {
  let query = db.collection("deliveries");

  if (!isEmpty(filter.branchId)) {
    query = query.where("branch.id", "==", filter.branchId);
  }

  if (!isEmpty(filter.status)) {
    query = query.where("status", "==", filter.status);
  }
  query.onSnapshot((observer) => {
    observer.docChanges().forEach((change) => {
      const pilotData = change.doc.data();
      let shouldAdd = true;
      if (filter.comodines && !isEmpty(filter.branchId)) {
        if (
          !isEmpty(pilotData.branchId) &&
          filter.branchId !== pilotData.branchId
        ) {
          shouldAdd = false;
        }
      }
      if (shouldAdd) {
        if (change.type === "added") {
          const pilot = change.doc.data().alias;
          const id = pilot.id;
          const existing = selectors.getPilot(store.getState(), id);
          if (existing === undefined) {
            if (pilot.actualPosition !== undefined) {
              store.dispatch(actions.addingPilot(pilot, id));
            }
          }
        }
        if (change.type === "modified") {
          const pilot = change.doc.data().alias;
          const id = pilot.id;
          store.dispatch(actions.editingPilot(pilot, id));
        }
      }
      if (change.type === "removed") {
        const pilot = change.doc.data().alias;
        const id = pilot.id;
        store.dispatch(actions.removingPilot(pilot, id));
      }
    });
  });
};

export const subscribePilots = async (filter = {}) => {
  let query = db.collection(collection);
  if (!isEmpty(filter.status)) {
    query = query.where("status", "==", filter.status);
  }
  if (!isEmpty(filter.branchId) && !filter.comodines) {
    query = query.where("branches", "array-contains", filter.branchId);
  }
  if (!isEmpty(filter.isActive)) {
    // console.log("filter.isActive", filter.isActive);
    // query = query.where(
    //   "isActive",
    //   "==",
    //   filter.isActive === "Y" ? "true" : "false"
    // );
  }
  query.onSnapshot((observer) => {
    observer.docChanges().forEach((change) => {
      // const pilotData = change.doc.data();
      let shouldAdd = true;
      if (filter.comodines && !isEmpty(filter.branchId)) {
        //filtrar por sucursal
        // if (
        //   !isEmpty(pilotData.branchId) &&
        //   filter.branchId !== pilotData.branchId
        // ) {
        //   shouldAdd = false;
        // }
      }
      if (shouldAdd) {
        if (change.type === "added") {
          const id = change.doc.id;
          const pilot = change.doc.data();
          const existing = selectors.getPilot(store.getState(), id);
          pilot["id"] = id;

          if (existing === undefined) {
            if (pilot.actualPosition !== undefined) {
              store.dispatch(actions.addingPilot(pilot, id));
            }
          }
        }
        if (change.type === "modified") {
          const id = change.doc.id;
          const pilot = change.doc.data();

          store.dispatch(actions.editingPilot(pilot, id));
        }
      }
      if (change.type === "removed") {
        const id = change.doc.id;
        const pilot = change.doc.data();

        store.dispatch(actions.removingPilot(pilot, id));
      }
    });
  });
};

export const getTrakingPilot = async (id) => {
  try {
    let finalPilot = undefined;
    await db
      .collection("bikers")
      .doc(id)
      .onSnapshot((snap) => {
        if (snap.exists) {
          finalPilot = snap.data();
          store.dispatch(actions.completeFetchingSinglePilot(finalPilot));
        } else {
          store.dispatch(actions.faildFetchingSinglePilot("error"));
        }
      });
  } catch (error) {
    console.log(error);
    return {
      pilot: null,
      error: error,
    };
  }
};

export const unSubscribePilots = async () => {
  db.collection(collection).onSnapshot(() => {});
};

export const updatePilot = async (
  id,
  name,
  phone,
  type,
  branch,
  company,
  image,
  dpi,
  transportId,
  pertenece
) => {
  //console.table([{"id": id}, [{data: name,phone,type}]]);
  const bikerToUpdate = {
    name,
    phone,
    dpi: !isEmpty(dpi) ? dpi : 0,
    transportId: !isEmpty(transportId) ? transportId : 0,
    type: !isEmpty(type) ? type.value : "",
    companyId: !isEmpty(company) ? company.value : "",
    company: !isEmpty(company) ? company.text : "",
    branchId: !isEmpty(branch) ? branch.value : "",
    SUCURSAL: !isEmpty(branch) ? branch.text : "",
    Pertenece: !isEmpty(pertenece) ? pertenece.value : "",
  };
  if (!isEmpty(image)) {
    bikerToUpdate["pic"] = image;
  }
  await firebaseFirestore
    .collection(collection)
    .doc(id)
    .update(bikerToUpdate);
};

export const updateBikerAlert = async (id, alert) => {
  await firebaseFirestore
    .collection(collection)
    .doc(id)
    .update({
      alert,
    });
};

export const removePilotByPilotId = async (pilotId) => {
  console.log("LOGS: pilotId", pilotId);
  return await db
    .collection(collection)
    .doc(pilotId)
    .delete();
};

export const updateBikerStatus = async (id, status) => {
  await firebaseFirestore
    .collection(collection)
    .doc(id)
    .update({
      status,
    });
};

export const getPilotLink = async (id) => {
  let data;
  try {
    await db
      .collection(collection)
      .doc(id)
      .onSnapshot((snap) => {
        data = snap.data();
        let tmp = { id: snap.id, ...data };
        //data.id = snap.id || "";
        store.dispatch(actions.pilotGetLinkCompleted(tmp));
      });
  } catch (error) {
    return {
      error,
    };
  }
};

export const getCheckIn = async (companyId) => {
  try {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    let date = `${month + 1}-${day}`;
    let data = await db
      .collection("checkin")
      .doc(year.toString())
      .collection(date)
      .where("branch.companyId", "==", companyId)
      .get();

    let array = [];
    data.forEach((doc) => {
      let data = {
        ...doc.data(),
        id: doc.id,
      };
      array.push(data);
    });
    return array;
  } catch (error) {
    console.info("this the error", "this that error", error.message);
    throw new Error("THIS THE ERROR");
  }
};

export const getCheckInById = async (id) => {
  try {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const dateString = `${month}-${day}`;

    const doc = await db
      .collection("checkin")
      .doc(year.toString())
      .collection(dateString)
      .doc(id)
      .get();

    if (!doc.exists) {
      throw new Error(`No existe ningún documento con el ID ${id}`);
    }

    return {
      ...doc.data(),
      id: doc.id,
    };
  } catch (error) {
    console.error("Error al obtener el check-in por ID:", error.message);
    throw error;
  }
};

export const updateCheckInById = async (id, data) => {
  try {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const dateString = `${month}-${day}`;

    await db
      .collection("checkin")
      .doc(year.toString())
      .collection(dateString)
      .doc(id)
      .update(data);
  } catch (error) {
    console.error("Error al actualizar el documento:", error);
    throw error;
  }
};