import {orderStatus} from "../../constants/constants"
// Get Order
export const getOrder = state => state.orders.orderByOrderId.order;
export const getError = state => state.orders.orderByOrderId.error;
export const getIsLoading = state => state.orders.orderByOrderId.isLoading;
export const getOrderCount = state => state.orders.order.length;
// Update Order
export const getUpdateOrderDone = state => state.orders.updateOrder.done;
export const getUpdateOrderError = state => state.orders.updateOrder.error;
export const getUpdateOrderIsLoading = state => state.orders.updateOrder.isLoading;

// Subscribe
export const getOrdersByBikerId = state => state.orders.ordersByBikerId.orders || [];
export const getOrdersByBikerIdByOrderId = (state, orderId) => 
  (state.orders.ordersByBikerId.orders || []).find(order => order.id === orderId);

// TOKEN
export const getToken = state => state.orders.token.token;

// Delete Order
export const getDeleteOrderDone = state => state.orders.deleteOrder.done;
export const getDeleteOrderError = state => state.orders.deleteOrder.error;
export const getDeleteOrderIsLoading = state => state.orders.deleteOrder.isLoading;

// Create Order
export const getCreateOrderDone = state => state.orders.createOrder.done;
export const getCreateOrderError = state => state.orders.createOrder.error;
export const getCreateOrderIsLoading = state => state.orders.createOrder.isLoading;

// getOrdersWithTimeByBikerId
export const getOrdersWithTime = state => state.orders.getOrdersWithTimeByBikerId.orders;
export const getOrdersWithTimeIsFetching = state => state.orders.getOrdersWithTimeByBikerId.isFetching;
const getHistoryOrders = state => state.orders.order.map((id)=>{
  // console.log('getHistory',state.orders.byId[id])
  const order = state.orders.byId[id];
    return {
      ...order,
      time: `${order.time}`,
    };
})
const getMonthlyOrders = state => state.orders.monthlyOrder.map((id)=>{
  const order = state.orders.monthlyById[id];
  return{
    ...order,
    time: `${order.time}`,
  }
});
//history orders
export const deliveredOrders = state => {
  // console.info(`\n»» src/logic/selectors/orders.js: deliveredOrders`, getHistoryOrders(state).filter((element)=>element.status===orderStatus.DELIVERED), `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
  return getHistoryOrders(state).filter((element)=>element.status===orderStatus.DELIVERED)
}
export const goingOrders = state => getHistoryOrders(state).filter((element)=>element.status===orderStatus.GOING)
export const canceledOrders = state => getHistoryOrders(state).filter((element)=>element.status===orderStatus.CANCELLED)
export const ordersByBikerId = (state,bikerId) => getHistoryOrders(state).filter((element)=>element.alias.id===bikerId);
export const allOrders = state => {
  // console.info(`\n»» src/logic/selectors/orders.js: deliveredOrders`, getHistoryOrders(state), `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
  return getHistoryOrders(state);
};
export const pilotsWithOrders = (state,pilots) => pilots.filter((pilot)=>getHistoryOrders(state).filter((element)=>element.alias.id===pilot.uid).length>0);
export const bikersWithOrders = (state,pilots) => pilots.filter((pilot)=>getHistoryOrders(state).filter((element)=>element.alias.id===pilot.id).length>0);
export const companiesWithOrders = (state,companies) => companies.filter((company)=>getHistoryOrders(state).filter((element)=>element.company.id===company.id).length>0);
export const branchesWithOrders = (state,branches) => branches.filter((branch)=>getHistoryOrders(state).filter((element)=>element.branch.id===branch.id).length>0);
export const monthlyOrders = (state) => getMonthlyOrders(state);

