import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProcess from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import AdminButton from "../Design/AdminButton";
import * as branchActions from "../../logic/actions/branches";
import companyActions from "../../logic/actions/company";
import {
  getCompanies,
  getAreCompaniesLoading,
} from "../../logic/selectors/company";
import { ROUTES } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { CompanyCard } from "../../containers/cards";
import {
  Grid,
  makeStyles,
  InputBase,
  Paper,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));
const Companies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Company
  const companies = useSelector(getCompanies);
  const filteredCompanies = companies.filter((element) => {
    return element.name?.toUpperCase().includes(search.toUpperCase());
  });
  const areCompaniesLoading = useSelector(getAreCompaniesLoading);
  useOnLoad(setIsLoading, dispatch);
  useOnDataLoaded(
    isLoading,
    setData,
    setIsLoading,
    companies,
    areCompaniesLoading
  );
  const onSearch = (value) => {
    setSearch(value);
  };
  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  // const renderCompany = (company, i) => (
  //   <div
  //     className={`col-3 company-resume-container`}
  //     key={`company-resume-${company.id}`}
  //   >
  //     <h3
  //       className={`cursor-pointer ${
  //         isEmpty(company.logo) ? "company-resume-text" : ""
  //       }`}
  //       onClick={() => history.push(`${ROUTES.COMPANY_BRANCHES}/${company.id}`)}
  //     >
  //       {company.name}
  //     </h3>
  //     {!isEmpty(company.logo) && (
  //       <img
  //         name="imagen"
  //         className="cursor-pointer"
  //         onClick={() =>
  //           history.push(`${ROUTES.COMPANY_BRANCHES}/${company.id}`)
  //         }
  //         alt="imagen"
  //         src={company.logo}
  //       />
  //     )}
  //   </div>
  // );

  const renderView = () => {
    if (isEmpty(data)) {
      return <div>No hay información a mostrar</div>;
    }
    return (
      <>
        <div className={classes.header}>
          <AdminButton route={ROUTES.CREATE_COMPANY} text="nueva compañía" />
          <Searcher onButton={onSearch} />
        </div>
        <br></br>
        {/* {data.map((company, i) => renderCompany(company, i))} */}
        <Grid container spacing={2}>
          {filteredCompanies.map((company) => (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <CompanyCard details={company} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <div title="Compañías">
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="Compañías"
      />
      {isLoading ? renderLoading() : renderView()}
    </div>
  );
};

const useOnDataLoaded = (
  isLoading,
  setData,
  setIsLoading,
  companies,
  areCompaniesLoading
) => {
  useEffect(() => {
    if (isLoading && !areCompaniesLoading) {
      if (!isEmpty(companies)) {
        setData(companies);
      } else {
        setData([]);
      }
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, companies, areCompaniesLoading]);
};

const useOnLoad = (setIsLoading, dispatch) => {
  useEffect(() => {
    dispatch(companyActions.clearCompanies());
    dispatch(branchActions.branchActions.clearBranches());
    dispatch(companyActions.getCompanies());
    setIsLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
const Searcher = ({ onButton }) => {
  const classes = useStyles();
  const onChange = (e) => {
    onButton(e.target.value);
  };
  return (
    <Paper component="form" className={classes.search}>
      <InputBase
        className={classes.input}
        onChange={onChange}
        placeholder="Buscar Compañía"
        inputProps={{ "aria-label": "buscar compañía" }}
      />
      <div className={classes.iconButton}>
        <Search />
      </div>
    </Paper>
  );
};

export default Companies;
