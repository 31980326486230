import React, { useEffect, useState } from "react";
import moment from "moment";

const BranchClock = () => {
  const [currentTime, setCurrentTime] = useState(
    moment().format("DD-MM-YYYY HH:mm:ss")
  );

  const updateTime = () => {
    setCurrentTime(moment().format("DD-MM-YYYY HH:mm:ss"));
  };

  useEffect(() => {
    const intervale = setInterval(updateTime, 1000);
    return () => clearInterval(intervale);
  }, []);

  return <>{currentTime}</>;
};

export default BranchClock;
