/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import Alert from "@material-ui/lab/Alert";
import ImageUploader from "react-images-upload";
import * as yup from "yup";
import { useSnackbar } from "notistack";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import isEmpty from "lodash/isEmpty";
import { pilotActions } from "../../logic/actions/pilots";
import {
  getSubscribeBiker,
  getSubscribeBikerError,
  isEditBikerLoading,
  isBikerEdited,
  getEditBikerError,
  isDeleteBikerLoading,
  isBikerDeleted,
  getDeleteBikerError,
} from "../../logic/selectors/bikers";
import BikerForm from "./BikerForm";
import { defaultFormFields } from "../../constants/bikerConstants";
import {
  ROUTES,
  BIKER_DEFAULT,
  snackBarConst,
  BIKER_STATUS,
} from "../../constants/constants";
import bikerService from "../../logic/services/bikerService";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
  phone: yup.string().required("Teléfono es requerido"),
  dpi: yup.string().optional(""),
});

const EditBiker = ({ match: { params } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const subscribedBiker = useSelector(getSubscribeBiker);
  const subscribedBikerError = useSelector(getSubscribeBikerError);
  const isBikerEditing = useSelector(isEditBikerLoading);
  const isBikerEditingError = useSelector(getEditBikerError);
  const isBikerEditingDone = useSelector(isBikerEdited);
  const isBikerDeleting = useSelector(isDeleteBikerLoading);
  const isBikerDeletingError = useSelector(getDeleteBikerError);
  const isBikerDeletingDone = useSelector(isBikerDeleted);

  const dispatch = useDispatch();
  const [biker, setBiker] = useState();
  const [bikerMongo, setBikerMongo] = useState();
  const [isBikerMongo, setIsBikerMongo] = useState(false);
  const [changeBikerStatusError, setChangeBikerStatusError] = useState("");
  const [image, setImage] = useState();
  // eslint-disable-next-line no-unused-vars
  const [formFields, setFormFields] = useState(defaultFormFields);
  const btnList = [
    {
      id: "cancel-biker",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.push(ROUTES.BIKERS);
        return;
      },
    },
    {
      id: "delete-biker",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "Eliminar",
      onClick: () => {
        dispatch(pilotActions.deleteBiker(params.bikerId, bikerMongo.id));
      },
    },
    {
      id: "create-new-company",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "EDITAR",
    },
  ];

  const fetchBikerMongo = async (bikerId) => {
    try {
      const bikerResponse = await bikerService.getBikerByBikerId(bikerId);
      setBikerMongo(bikerResponse);
    } catch (e) {
      console.log("ERROR FETCHING BIKER", e);
    }
  };

  useOnLoad(params, fetchBikerMongo, dispatch);
  useOnDataLoaded(subscribedBiker, subscribedBikerError, setBiker);
  useOnUpdatingBiker(
    isBikerEditingDone,
    isBikerEditingError,
    isBikerEditing,
    history,
    enqueueSnackbar,
    dispatch
  );
  useOnDeletingBiker(
    isBikerDeletingDone,
    isBikerDeletingError,
    isBikerDeleting,
    history,
    enqueueSnackbar,
    dispatch
  );
  // console.table([{"data":params}])
  const onSubmit = (values) => {
    dispatch(
      pilotActions.editBiker({ id: params.bikerId, ...values }, image)
    );
  };

  const onDeactivateBiker = async () => {
    setIsBikerMongo(true);
    setChangeBikerStatusError("");
    try {
      const status =
        bikerMongo.status === BIKER_STATUS.ACTIVE
          ? BIKER_STATUS.INACTIVE
          : BIKER_STATUS.ACTIVE;
      await bikerService.changeBikerStatus(
        bikerMongo.id,
        bikerMongo.uid,
        status
      );
      await fetchBikerMongo(bikerMongo.id);
    } catch (e) {
      if (!isEmpty(e.response.data.message)) {
        setChangeBikerStatusError(e.response.data.message);
      } else {
        setChangeBikerStatusError(
          "Ha ocurrido un error cambiando el estado del piloto!"
        );
      }
    }
    setIsBikerMongo(false);
  };

  const renderBody = () => {
    if (isEmpty(subscribedBiker) || isEmpty(biker) ) {
      return <></>;
    }
    if (!isEmpty(subscribedBikerError) || !isEmpty(isBikerEditingError)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intetrlo más tarde!
        </Alert>
      );
    }
    return (
      <BlockUi
        tag="div"
        blocking={isBikerEditing || isBikerMongo || isBikerDeleting}
      >
        <div className="display-flex">
          <div className="col-5">{renderImage()}</div>
          <div className="col-7">
            <div className="text-center margin-bottom-lg">
              {!isEmpty(changeBikerStatusError) && (
                <Alert severity="error">{changeBikerStatusError}</Alert>
              )}
          
            </div>

            <BikerForm
              formFields={formFields}
              biker={biker}
              btnList={btnList}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              setBiker={setBiker}
            />
          </div>
        </div>
      </BlockUi>
    );
  };

  const renderImage = () => {
    return (
      <div className="padding-lg">
        <div>
          <div className="text-center two-wheeler-pic">
            <img src={biker.pic ? biker.pic : BIKER_DEFAULT} alt="2Wheeler" />
          </div>
        </div>
        <div>
          <ImageUploader
            withIcon={true}
            buttonText="Cargar imagen"
            onChange={(pic) => setImage(pic)}
            imgExtension={[".jpg", ".png"]}
            maxFileSize={5242880}
            withPreview
            singleImage
          />
        </div>
      </div>
    );
  };

  return (
    <div
      title={isEmpty(subscribedBiker) ? "EDITAR" : `${subscribedBiker.name}`}
    >
      {renderBody()}
    </div>
  );
};

const getType = (type) => {
  // console.log(type)
  if (type === "WILDCARD") return "COMODIN";
  if (type === "AVAILABLE") return "DISPONIBLE";
  if (type === "PERMANENT") return "PERMANENTE";
};

const getPertenece = (type) => {
  // console.log(type)
  if (type === "PROYECTOS") return "PROYECTOS";
  if (type === "POLLO BRUJO") return "POLLO BRUJO";
  if (type === "ISHOP") return "ISHOP";
}

const useOnDataLoaded = (subscribedBiker, subscribedBikerError, setBiker) => {
  useEffect(() => {
    if (isEmpty(subscribedBikerError) && !isEmpty(subscribedBiker)) {
      setBiker({
        phone: subscribedBiker.phone,
        pic: subscribedBiker.pic,
        name: subscribedBiker.name,
        dpi: subscribedBiker.dpi,
        transportId: subscribedBiker.transportId,
        type: {
          value: subscribedBiker.type,
          text: getType(subscribedBiker.type),
        },
        company: !isEmpty(subscribedBiker.companyId)
          ? {
              value: subscribedBiker.companyId,
              text: subscribedBiker.company,
            }
          : {},
        branch: !isEmpty(subscribedBiker.branchId)
          ? {
              value: subscribedBiker.branchId,
              text: subscribedBiker.SUCURSAL,
            }
          : {},
        pertenece: {
            value: subscribedBiker.Pertenece,
            text: getPertenece(subscribedBiker.Pertenece),
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedBiker, subscribedBikerError]);
};

const useOnLoad = (params, fetchBikerMongo, dispatch) => {
  useEffect(() => {
    if (isEmpty(params.bikerId)) {
      return;
    }
    dispatch(pilotActions.subscribePilotByPilotId(params.bikerId, true));
    fetchBikerMongo(params.bikerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useOnUpdatingBiker = (
  isUpdated,
  error,
  isLoading,
  history,
  enqueueSnackbar,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isUpdated) {
        enqueueSnackbar("Piloto modificado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(pilotActions.clearBikerEdition());
        history.push(ROUTES.BIKERS);
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al actualizar el piloto, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, error, isLoading]);
};

const useOnDeletingBiker = (
  isDeleted,
  error,
  isLoading,
  history,
  enqueueSnackbar,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isDeleted) {
        enqueueSnackbar("Piloto eliminado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(pilotActions.clearBikerEdition());
        history.push(ROUTES.BIKERS);
        window.location.reload(true)
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al eliminar el piloto, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted, error, isLoading]);
};

export default EditBiker;
