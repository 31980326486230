import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import { snackBarConst, INPUT_TYPE } from "../../constants/constants";
import {
  getBikerList,
  getIsBikerListLoading,
} from "../../logic/selectors/bikers";
import { pilotActions } from "../../logic/actions/pilots";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";
import orderService from "../../logic/services/orders";

const validationSchema = yup.object({
  alias: yup
    .object()
    .shape({ text: yup.string().required("El agente es requerido") })
    .required(),
  // zone: yup.number().typeError("Zona debe ser un valor númerico"),
});

export default ({
  onModalClose,
  orderToModify,
  isOnlyBikerAllowed = false,
  setOrders,
}) => {
  const dispatch = useDispatch();
  // Biker
  const bikers = useSelector(getBikerList);
  const isBikerListLoading = useSelector(getIsBikerListLoading);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [assingError, setAssingError] = useState();
  const [formFields, setFormFields] = useState(
    [
      {
        label: "Agente",
        name: "alias",
        cssClasses: "col-12",
        fullWidth: true,
        inputType: INPUT_TYPE.FILTERABLE_LIST,
        isLoading: true,
      },
    ].concat(
      isOnlyBikerAllowed
        ? []
        : [
            {
              label: "Departamento",
              name: "depto",
              cssClasses: "col-12",
              fullWidth: true,
              inputType: INPUT_TYPE.FIELD,
            },
            {
              label: "Municipio",
              name: "munic",
              cssClasses: "col-12",
              fullWidth: true,
              inputType: INPUT_TYPE.FIELD,
            },
            {
              label: "Zona",
              name: "zone",
              cssClasses: "col-12",
              fullWidth: true,
              inputType: INPUT_TYPE.FIELD,
            },
          ]
    )
  );
  const [order, setOrder] = useState({
    alias: {},
    munic: !isOnlyBikerAllowed ? orderToModify.fields.munic : "",
    depto: !isOnlyBikerAllowed ? orderToModify.fields.depto : "",
    zone: !isOnlyBikerAllowed
      ? !isNaN(parseInt(orderToModify.fields.zone))
        ? orderToModify.fields.zone
        : ""
      : "",
  });

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "guardar",
    },
  ];

  useOnLoad(orderToModify, isOnlyBikerAllowed, dispatch);
  useOnAgentsLoaded(bikers, isBikerListLoading, setFormFields);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      let newOrderToModify = { ...orderToModify };
      let newOrder = {};
      if (!isOnlyBikerAllowed) {
        newOrder = {
          ...newOrderToModify.fields,
          alias_id: values.alias.value,
          munic: values.munic,
          depto: values.depto,
          zone: values.zone,
        };
      } else {
        newOrder = {
          alias_id: values.alias.value,
        }
      }
      newOrder = {
        ...newOrder,
        updatedAt: undefined,
        orderPic: undefined,
        receivedPic: undefined,
        alert: undefined,
        modifyingByName: undefined,
        modifyingBy: undefined,
        createdAt: undefined,
        locationRef: undefined,
        company: undefined,
        branch: undefined,
        biker: undefined,
      };
      const updatedOrder = await orderService.editOrder(
        newOrderToModify.id,
        newOrder,
        true
      );
      enqueueSnackbar("Ordenes modificadas correctamente", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
      if (setOrders) {
        setOrders((vals) => {
          let newOrders = [...vals];
          const orderIndex = newOrders.findIndex(
            // TODO: Modify by id
            (order) => order.fields.orderId === updatedOrder.body.orderId
          );
          if (orderIndex > -1) {
            newOrders[orderIndex].fields.biker = updatedOrder.body.alias.name;
            newOrders[orderIndex].fields.depto = updatedOrder.body.depto;
            newOrders[orderIndex].fields.munic = updatedOrder.body.munic;
            newOrders[orderIndex].fields.zone = updatedOrder.body.zone;
          }
          return newOrders;
        });
      }
      onModalClose();
    } catch (e) {
      setAssingError("Ha ocurrido un error asignando el piloto");
      enqueueSnackbar(
        "Ha ocurrido un error asignando el piloto a las ordenes",
        {
          variant: "warning",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        }
      );
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`modal-container ${
        isOnlyBikerAllowed
          ? "modal-container-assign-only-biker"
          : "modal-container-assign-biker"
      }`}
    >
      <div className="modal-title">
        <div className="modal-title-text">Asignar Piloto</div>
      </div>
      <div className="modal-body all-upper-case">
        <BlockUi tag="div" blocking={isLoading}>
          <DynamicForm
            obj={order}
            fields={formFields}
            validationSchema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            setState={setOrder}
            btnCssClass="modal-footer"
          />
        </BlockUi>
      </div>
    </div>
  );
};

const useOnLoad = (orderToModify, isOnlyBikerAllowed, dispatch) => {
  useEffect(() => {
    dispatch(pilotActions.clearPilotsData());
    if (!isEmpty(orderToModify)) {
      dispatch(
        pilotActions.getPilots({
          branchId: !isOnlyBikerAllowed
            ? orderToModify.fields.branch.id
            : orderToModify.branch.id,
          useService: true,
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useOnAgentsLoaded = (bikers, isBikerListLoading, setFormFields) => {
  useEffect(() => {
    if (!isBikerListLoading && !isEmpty(bikers)) {
      setFormFields((fields) => [
        {
          ...fields[0],
          isLoading: false,
          options: (bikers || []).map((biker) => ({
            value: biker.id,
            text: biker.name,
          })),
        },
        ...fields.slice(1, fields.length),
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikers, isBikerListLoading]);
};
