import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import BreadCrumb from "../../containers/navs/breadcrumb";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import DataGrid, {
    Column,
    Grouping,
    Pager,
    Paging,
    SearchPanel,
    StateStoring,
    FilterPanel,
    TotalItem,
    Summary,
    FilterRow,
    HeaderFilter,
    Export,
  Scrolling

  } from "devextreme-react/data-grid";

const DashboardAccounts = ({ gettingAccounts, init, account, failed }) => {
  useEffect(() => {
    gettingAccounts();
  }, [gettingAccounts]);

  const pageSizes = [10, 25, 50, 100];
  
  // console.log(`init: ${init}`);
  // console.log(`account: ${account}`);
  // console.log(`error: ${failed}`);
  
  let dictonary = {};

  if (account) {
    account.forEach((e) => {
      if (dictonary[e.data.company?.name]) {
        dictonary[e.data.company?.name] += 1;
      } else {
        dictonary[e.data.company?.name] = 1;
      }
    });
  }

  const dict = Object.entries(dictonary).map(([k, v]) => {
    return {
      name: k,
      value: v,
    };
  });
  
  // console.log(dict);

  return (
    <>
      <div>
        <BreadCrumb match={{ path: "/" }} title=" CANTIDAD DE USUARIOS" />
      </div>
      <ResponsiveContainer width="100%" height="30%">
        <BarChart
          width={500}
          height={300}
          data={dict}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name" fontSize={'10px'}/>
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="rgb(31, 68, 161)" />
        </BarChart>
      </ResponsiveContainer>
  
      <DataGrid
        keyExpr="name"
        dataSource={dict}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        noDataText="NO HAY INFORMACIÓN"
      >
        <Column dataField="name" caption="EMPRESA" />
        <Column dataField="value" caption="CANTIDAD" />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Grouping autoExpandAll={false} />
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="name"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="dashboardAccounts"
        />
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </>
  );
};

/* return to reducer 
    gettingAccountUser
    getAccountUser
    gettingAccountUserFail 
*/
const {
  gettingAccountUser,
  getAccountUser,
  gettingAccountUserFail,
} = selectors;

export default connect(
  (state) => ({
    init: gettingAccountUser(state),
    account: getAccountUser(state),
    failed: gettingAccountUserFail(state),
  }),
  (dispatch) => ({
    gettingAccounts() {
      dispatch(actions.gettingUserAccount());
    },
  })
)(DashboardAccounts);
