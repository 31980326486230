import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Grid from "@material-ui/core/Grid";
import them from "../../../assets/theme";
import Bikerpic from "../../../assets/img/biker-default-pic.png";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Biker from "../../../assets/img/biker.svg";
import { ThemeProvider } from "@material-ui/core/styles";
import "./view.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px 10px",
    borderTopColor: `#03A9F4`,
  },
  secondaryTail: {
    backgroundColor: "#F4D03F",
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={them}>
      {/* // 2Wheeler ASIGNADO */}

      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary"></Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <img src={Bikerpic} width="35px" height="35px" alt ="imagen"></img>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper className={classes.paper} variant="outlined">
              <Typography variant="body2" className={classes.typography} />
              <center>
                <CheckIcon
                  widht="10px"
                  heigth="10px"
                  style={{ color: "#2ECC71" }}
                />
              </center>
              <center> 2Wheeler Asignado </center>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        {/* // ORDEN PREPARADA */}

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary"></Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/icons%2Fcomida%20para%20orden.svg?alt=media&token=b2d46b2b-b59c-457e-9c81-2e1722e588bf"
              width="30px"
              height="30px"
              alt ="pilot"
            ></img>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              elevation={5}
              style={{ borderTopColor: "blue" }}
              className={classes.paper}
              variant="outlined"
            >
              <Typography variant="body2" className={classes.typography} />
              <center>
                <CheckIcon
                  widht="5px"
                  heigth="5"
                  style={{ color: "#42A5F5" }}
                />
              </center>
              <center>Orden preparada</center>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <img
              src={Biker}
              style={{ paddingRight: "40%" }}
              width="45px"
              height="45px"
              alt = "biker"
            ></img>
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              elevation={4}
              variant="outlined"
              style={{ borderTopColor: "blue" }}
              className={classes.paper}
            >
              <Typography variant="body2" className={classes.typography} />
              <center>
                <PriorityHighIcon style={{ color: "orange" }} />
              </center>
              <Grid
                container
                direction="row"
                alignContent="center"
                justify="center"
              >
                <Grid item xs={7} ms={10}>
                  <div className="spinner-box">
                    <div className="pulse-container">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{ paddingTop: "-120px" }}>
                <center>Orden por llegar</center>
              </div>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </ThemeProvider>
  );
}
