import React from "react";
import { adminMenu } from "../../constants/menu";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.info[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: "grey",
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    // color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    // color: theme.palette.primary.light,
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    // color: theme.palette.primary.light,
  },
 
}));
const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon className={classes.labelIcon} />
          <Typography
            style={{
              fontFamily: "Oswald, sans-serif",
              fontSize: '18px'
            }}
            variant="body2"
            className={classes.labelText}
          >
            {labelText}
          </Typography>
          <Typography
            style={{
              fontFamily: "Oswald, sans-serif",
              fontSize: '18px'
            }}
            variant="caption"
            color="inherit"
          >
            {labelInfo}
          </Typography>
        </div>
      }
      style={
        {
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }
      }
      classes={
        {
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }
      }
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  // labelIcon: PropTypes.elementType.isRequired,
  // labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const clearAllTimeouts = () => {
  let id = window.setTimeout(function () {}, 0);
  while (id--) {
    window.clearTimeout(id);
  }
};

const RenderMenuItemList = ({ data = adminMenu }) => {
  const history = useHistory();
  const goToPage = (url) => {
    clearAllTimeouts();
    history.push(url);
  };
  return (
    <div>
      {data.map((element) => (
        <StyledTreeItem
          nodeId={element.id}
          labelText={element.title}
          labelIcon={element.icon}
          color="#e3742f"
          bgColor="#fcefe3"
          onClick={() => goToPage(element?.to)}
        >
          {element.subItems?.map((subItem) => (
            <StyledTreeItem
              nodeId={subItem.id}
              labelText={subItem.title}
              labelIcon={subItem.icon}
              color="#e3742f"
              bgColor="#fcefe3"
              onClick={() => goToPage(subItem?.to)}
            />
          ))}
        </StyledTreeItem>
      ))}
    </div>
  );
};
export default RenderMenuItemList;
