import React from "react";

const Card = ({ title, body, bodyCssClases, cssClases, titleCssClases }) => {
  return (
    <div className={`card ${cssClases}`}>
      <div className={`card-title ${titleCssClases}`}>{title}</div>
      <div className={`card-body ${bodyCssClases}`}>{body}</div>
    </div>
  );
};

export default Card;
