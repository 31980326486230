import React from "react";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default ({ field, form, ...props }) => {
  const { touched, errors } = form;

  const getCssClass = () => {
    let cssClass = "";
    if (!isEmpty(props.cssClass)) {
      Object.keys(props.cssClass).forEach((key) => {
        if (!isEmpty(field.value) && key === field.value.value) {
          cssClass = props.cssClass[key];
        }
      });
    }
    return cssClass;
  };

  return (
    <div
      className={`text-field vertical-top ${props.fullWidth && "full-width"} ${
        props.cssClasses
      }`}
    >
      <Autocomplete
        {...props}
        className={props.cssClass ? getCssClass() : ""}
        value={
          field.value === undefined || field.value === null ? null : field.value
        }
        onChange={async (event, newValue) => {
          props.formikProps.setFieldValue(field.name, newValue);
          if (props.setState) {
            props.setState((val) => ({
              ...val,
              [field.name]: newValue,
            }));
          }
          if (props.onValueChanged) {
            await props.onValueChanged(newValue);
          }
        }}
        getOptionLabel={(opt) => opt.text || ""}
        id="controllable-states-demo"
        options={props.options}
        getOptionSelected={(option, value) => {
          if (isEmpty(value) && isEmpty(option.value)) {
            return true;
          }
          return option.value === value.value;
        }}
        renderInput={(params) => (
          <TextField {...params} label={props.label} variant="outlined" />
        )}
      />
      {touched && !isEmpty(errors) && !isEmpty(errors[field.name]) && (
        <div className="text-field-errors">
          {Object.keys(errors)
            .filter((val) => val === field.name)
            .map((err) => (
              <div key={err} className="text-field-error">
                {errors[err].text}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
