import isEmpty from "lodash/isEmpty";
import { put, takeEvery, fork } from "redux-saga/effects";
import companyFirebaseService, { getCompanieLink } from "../../firebase/company";
import { uploadImage, getImageUrl, removeImage } from "../../firebase/image";
import {
  COMPANIES_FETCH_STARTED,
  COMPANY_CREATE_STARTED,
  COMPANY_MODIFY_STARTED,
  COMPANY_DELETE_STARTED,
  GET_COMPANIES_LINK_STARTED,
  GETTING_COMPANY_STARTED
} from "../types/company";
import { FIRESTORAGE } from "../../constants/constants";
import companyActions, { getCompaniesLinkCompleted, getCompaniesLinkFailed } from "../actions/company";
import companyService from "../services/companyService";

import * as actions from "../actions/company";

function* fetchCompanies(action) {
  try {
    const { companyId } = action.payload;
    const response = yield companyFirebaseService.getCompanies(companyId);
    // const response = yield companyService.getCompanies();
    yield put(companyActions.getCompaniesSuccess(response));
  } catch (error) {
    console.log("ERROR", error.message);
    yield put(companyActions.getCompaniesFailure(error.message));
  }
}

export function* watchFetchCompanies() {
  yield takeEvery(COMPANIES_FETCH_STARTED, fetchCompanies);
}

function* createCompany(action) {
  let uploadedImage;
  try {
    const {
      payload: { image, name, description, sms, email, emails },
    } = action;
    let companyImage;
    if (!isEmpty(image)) {
      uploadedImage = yield uploadImage(FIRESTORAGE.COMPANY, image[0]);
      companyImage = yield getImageUrl(uploadedImage);
    }
    yield companyService.createCompany(
      name,
      description,
      companyImage,
      sms,
      email,
      emails
    );
    yield put(companyActions.createCompanySuccess());
  } catch (error) {
    console.log("ERROR", error.message);
    if (!isEmpty(uploadedImage)) {
      yield removeImage(uploadedImage);
    }
    yield put(companyActions.createCompanyFailure(error.message));
  }
}
export function* watchCreateCompany() {
  yield takeEvery(COMPANY_CREATE_STARTED, createCompany);
}

function* modifyCompany(action) {
  let uploadedImage;
  try {
    const {
      payload: { image, name, description, id, sms, email, emails },
    } = action;
    let companyImage;
    if (!isEmpty(image)) {
      uploadedImage = yield uploadImage(FIRESTORAGE.COMPANY, image[0]);
      companyImage = yield getImageUrl(uploadedImage);
    }
    yield companyService.modifyCompany(
      id,
      name,
      description,
      companyImage,
      sms,
      email,
      emails
    );
    yield put(companyActions.modifyCompanySuccess());
  } catch (error) {
    console.log("ERROR", error.message);
    if (!isEmpty(uploadedImage)) {
      yield removeImage(uploadedImage);
    }
    yield put(companyActions.modifyCompanyFailure(error.message));
  }
}

export function* watchModifyCompany() {
  yield takeEvery(COMPANY_MODIFY_STARTED, modifyCompany);
}

function* deleteCompany(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield companyService.deleteCompany(id);
    yield put(companyActions.deleteCompanySuccess());
  } catch (error) {
    yield put(companyActions.deleteCompanyFailure(error.message));
  }
}

export function* watchDeleteCompany() {
  yield takeEvery(COMPANY_DELETE_STARTED, deleteCompany);
}

export default function* companySagas() {
  yield fork(watchFetchCompanies);
  yield fork(watchCreateCompany);
  yield fork(watchModifyCompany);
  yield fork(watchDeleteCompany);
}


function* companiesLink(action) {
  try{
    let response = yield( getCompanieLink(action.payload.id) )
    if(response.error != null ) {
        yield put(getCompaniesLinkFailed(response.error))
      }else{
        yield put(getCompaniesLinkCompleted(response.company))
      }
  }catch(error){
  }
} 
export function* watchLinkSingleCompanie(){
  yield takeEvery(
    GET_COMPANIES_LINK_STARTED,
    companiesLink 
  )
}

/*
    SUMMARY COMPANY
*/

function* gettingCompanySummary(){
  let response = yield companyService.gettingCompany();
  const {data, error} = response;  
  if(!error){
      yield put(actions.getCompaniesSummaryCompleted(data))
  } else{
      yield put(actions.getCompaniesSummaryFailed(error))
  }
}

export function* watchGettingCompanySummary(){
  yield takeEvery(GETTING_COMPANY_STARTED, gettingCompanySummary);
}
