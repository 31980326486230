/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getController } from "../../../logic/reducers";
import {
  getBikers,
  getCompanyResume,
  getStatusBikers,
} from "../../../firebase/orders";
import SingleMapLocation from "./SingleMapLocation";
import { GOOGLE_API_KEY } from "../../../constants/constants";
import BreadCrumb from "../../../containers/navs/breadcrumb";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.css";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles(() => ({
  mapContainer: {
    flexBasis: "100%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    marginBottom: "10px",
    marginTop: "20px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const BikerCompany = ({ match: { params } }) => {
  const history = useHistory();
  const classes = useStyles();
  const [motoristaData, setMotoristaData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [statusBikers, setStatusBikers] = useState([]);
  const [motoristaDataWithStatus, setMotoristaDataWithStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBiker, setSelectedBiker] = useState(null);
  const controller = useSelector(getController);
  const [isMapVisible, setIsMapVisible] = useState(false);

  const fetchBikersData = async () => {
    try {
      const querySnapshot = await getBikers();
      querySnapshot.onSnapshot((snapshot) => {
        const filteredData = snapshot.docs
          .filter(
            (doc) =>
              doc.data().companyId === controller.companyId &&
              doc.data().branchId === params.branchId
          )
          .map((doc) => ({ ...doc.data(), id: doc.id }));

        setMotoristaData(filteredData);
        setIsLoading(false);
      });
    } catch (error) {
      console.error(`Error fetching bikers: ${error}`);
      setIsLoading(false);
    }
  };

  const fetchCompaniesData = async () => {
    try {
      const querySnapshot = await getCompanyResume();
      querySnapshot.onSnapshot((snapshot) => {
        const filteredData = snapshot.docs
          .filter((doc) => doc.id === controller.companyId)
          .map((doc) => ({ ...doc.data(), id: doc.id }));

        setCompanies(filteredData);
        setIsLoading(false);
      });
    } catch (error) {
      console.error(`Error fetching bikers: ${error}`);
      setIsLoading(false);
    }
  };

  const fetchStatusForMotoristas = async () => {
    try {
      const querySnapshot = await getStatusBikers();
      querySnapshot.onSnapshot((snapshot) => {
        const statusData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStatusBikers(statusData);
      });
    } catch (error) {
      console.error(`Error fetching status data: ${error}`);
    }
  };

  useEffect(() => {
    fetchBikersData();
    fetchCompaniesData();
    fetchStatusForMotoristas();
  }, []);

  useEffect(() => {
    if (motoristaData.length > 0 && statusBikers.length > 0) {
      const motoristasWithStatus = motoristaData.map((biker) => {
        const statusBiker = statusBikers.find(
          (status) => status.id === biker.id
        );
        return {
          ...biker,
          deliveryStatus: statusBiker ? statusBiker.deliveryStatus : "N/A",
          goToStore: statusBiker ? statusBiker.goToStore : "N/A",
        };
      });
      setMotoristaDataWithStatus(motoristasWithStatus);
    }
  }, [motoristaData, statusBikers]);

  const trackBiker = (id) => {
    if (isMapVisible) {
      setIsMapVisible(false);
      setSelectedBiker(null);
    } else {
      const selectedBikerData = motoristaDataWithStatus.find(
        (biker) => biker.id === id
      );
      setSelectedBiker(selectedBikerData);
      setIsMapVisible(true);
    }
  };

  return (
    <div>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RASTREO DE PILOTOS"
      />
      <div>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {companies[0]?.assistance !== undefined &&
            companies[0]?.assistance === true ? (
              <>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>NOMBRE</th>
                        <th>COMPAÑIA</th>
                        <th>SUCURSAL</th>
                        <th>TELEFONO</th>
                        <th>EMAIL</th>
                        <th>REGRESO</th>
                        <th>ESTADO</th>
                        <th>RASTREO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {motoristaDataWithStatus.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.name?.toUpperCase()}</td>
                          <td>{data.company?.toUpperCase()}</td>
                          <td>{data.SUCURSAL?.toUpperCase()}</td>
                          <td>{data.phone}</td>
                          <td>{data.email?.toUpperCase()}</td>
                          <td>
                            {typeof data.goToStore === "boolean"
                              ? data.goToStore
                                ? "REGRESANDO"
                                : "TRABAJANDO"
                              : data.goToStore || "N/A"}
                          </td>
                          <td>
                            {typeof data.deliveryStatus === "boolean" ? (
                              data.deliveryStatus ? (
                                <DoNotDisturbAltIcon style={{ color: "red", fontWeight: "bold" }}/>
                              ) : (
                                <CheckCircleIcon style={{ color: "green", fontWeight: "bold" }}/>
                              )
                            ) : (
                              data.deliveryStatus || "N/A"
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                trackBiker(data.id);
                              }}
                              // disabled={
                              //   !(
                              //     (data.goToStore === true ||
                              //       data.goToStore === "true" ||
                              //       data.goToStore === undefined ||
                              //       data.goToStore === "N/A") &&
                              //     data.deliveryStatus !== "N/A" &&
                              //       data.deliveryStatus !== undefined
                              //   )
                              // }
                              style={{
                                padding: "8px 16px",
                                backgroundColor: "#f26724",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                transition: "background-color 0.3s",
                                fontWeight: "bold",
                              }}
                              // style={{
                              //   cursor: "pointer",
                              //   backgroundColor:
                              //     data.goToStore !== undefined &&
                              //     data.goToStore !== "N/A"
                              //       ? data.goToStore
                              //         ? "green"
                              //         : "red"
                              //       : "gray",
                              //   color: "#fff",
                              //   padding: "5px 10px",
                              //   borderRadius: "5px",
                              //   border: "none",
                              //   outline: "none",
                              // }}
                            >
                              RASTREAR
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {isMapVisible && (
                  <div className={classes.mapContainer}>
                    <SingleMapLocation
                      selectedBiker={selectedBiker}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `750px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                )}
              </>
            ) : (
              <div>
                <h1>No hay datos</h1>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BikerCompany;
