import * as types from "../types/branches";
import { getController } from "../reducers";
import isEmpty from "lodash/isEmpty";

export const startFetchingBranchOffices = ({ branchId } = {}) => (
  dispatch,
  getState
) => {
  const currentState = getState();
  const controller = getController(currentState);
  dispatch({
    type: types.BRANCH_OFFICES_FETCH_STARTED,
    payload: {
      filters: {
        branchId: isEmpty(branchId) ? controller.branchId : branchId,
        companyId: controller.companyId,
      },
    },
  });
};

export const completeFetchingBranchOffices = (entities, order) => ({
  type: types.BRANCH_OFFICES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const clearBranchOffices = () => ({
  type: types.BRANCH_OFFICES_CLEAR,
});

export const failFetchingBranchOffices = (error) => ({
  type: types.BRANCH_OFFICES_FETCH_FAILED,
  payload: {
    error,
  },
});

//  SELECT A SPECIFIC BRANCH OFFICE
export const selectingBranchOffice = (branchOffice) => ({
  type: types.BRANCH_OFFICE_SELECTED,
  payload: {
    branchOffice,
  },
});

//  DESELECT A BRANCH OFFICE
export const deselectingBranchOffice = () => ({
  type: types.BRANCH_OFFICE_DESELECTED,
});

const getBranches = ({ companyId } = {}) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  dispatch({
    type: types.BRANCHES_FETCH_STARTED,
    payload: {
      filters: {
        companyId,
        branchId: controller.branchId,
      },
    },
  });
};

const getBranchesSuccess = (branches) => ({
  type: types.BRANCHES_FETCH_COMPLETED,
  payload: {
    branches,
  },
});

const getBranchesFailure = (error) => ({
  type: types.BRANCHES_FETCH_FAILED,
  payload: {
    error,
  },
});

const clearBranches = () => ({
  type: types.CLEAR_BRANCHES,
});

const createBranch = (branch) => ({
  type: types.BRANCH_CREATE_STARTED,
  payload: {
    branch,
  },
});

const createBranchSuccess = () => ({
  type: types.BRANCH_CREATE_COMPLETED,
});

const createBranchFailure = (error) => ({
  type: types.BRANCH_CREATE_FAILED,
  payload: {
    error,
  },
});

const deleteBranch = (id) => ({
  type: types.BRANCH_DELETE_STARTED,
  payload: {
    id,
  },
});

const deleteBranchSuccess = () => ({
  type: types.BRANCH_DELETE_COMPLETED,
});

const deleteBranchFailure = (error) => ({
  type: types.BRANCH_DELETE_FAILED,
  payload: {
    error,
  },
});

const clearBranchStatuses = () => ({
  type: types.CLEAR_BRANCH_STATUSES,
});

export const branchActions = {
  getBranches,
  getBranchesSuccess,
  getBranchesFailure,
  clearBranches,
  createBranch,
  createBranchSuccess,
  createBranchFailure,
  clearBranchStatuses,
  deleteBranch,
  deleteBranchSuccess,
  deleteBranchFailure,
};
