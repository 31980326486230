/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { getController } from "../../logic/reducers";
import { getOrderResume, getCompanyResume } from "../../firebase/orders";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import "./Design/styles.css";
// design
import ProgressOrders from "./Design/ProgressOrders";
import GraphicCompany from "./Design/GraphicCompany";
import TopPilots from "./Design/TopPilots";
import LastOrders from "./Design/LastOrders";
import TopCompany from "./Design/TopCompany";
// iconos
import Grid from "@material-ui/core/Grid";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import EventIcon from "@mui/icons-material/Event";
import Directions from "@material-ui/icons/Directions";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const BranchResume = () => {
  const history = useHistory();
  const classes = useStyles();
  const controller = useSelector(getController);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [previousMonth, setPreviousMonth] = useState(new Date().getMonth());
  const [today, setToday] = useState(new Date());
  /* datos para guardar las ordenes tanto como del dia como la de los meses */
  const [ordersDay, setOrdersDay] = useState([]);
  const [ordersMonth, setOrdersMonth] = useState([]);
  const [ordersBeforeMonth, setordersBeforeMonth] = useState([]);
  const [orderPreviousDayMonth, setOrderPreviousDayMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    // obtener compania
    let isMounted = true;
    getOrdersDay(isMounted);
    getOrdersMonth(isMounted);
    getOrdersBeforeMonth();
    getOrderDayPrevousMonth();
  }, []);

  const getOrdersDay = async (isMounted) => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    setIsLoading(true);
    try {
      const query = await getOrderResume(startDate, endDate);
      query.onSnapshot((snapshot) => {
        if (isMounted) {
          const allOrders = snapshot.docs
            .filter((orderDoc) => {
              if (orderDoc.data()?.branch?.id === undefined) {
                return false;
              } else {
                return orderDoc.data()?.branch?.id === controller.branchId;
              }
            })
            .map((orderDoc) => {
              const orderData = orderDoc.data();
              return { id: orderDoc.id, ...orderData };
            });
          setIsLoading(false);
          setOrdersDay(allOrders);
        }
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (isMounted) {
        // Check if the component is still mounted
        setIsLoading(false);
      }
    }
  };

  const getOrdersMonth = async (isMounted) => {
    const startDate = new Date(new Date().getFullYear(), month - 1, 1);
    const endDate = new Date(new Date().getFullYear(), month, 0, 23, 59, 59);

    setIsLoading(true);

    try {
      const query = await getOrderResume(startDate, endDate);
      query.onSnapshot((snapshot) => {
        if (isMounted) {
          const allOrders = snapshot.docs
            .filter((orderDoc) => {
              if (orderDoc.data()?.branch?.id === undefined) {
                return false;
              } else {
                return orderDoc.data()?.branch?.id === controller.branchId;
              }
            })
            .map((orderDoc) => {
              const orderData = orderDoc.data();
              return { id: orderDoc.id, ...orderData };
            });
          setIsLoading(false);
          setOrdersMonth(allOrders);
        }
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (isMounted) {
        // Check if the component is still mounted
        setIsLoading(false);
      }
    }
  };

  const getOrdersBeforeMonth = async () => {
    const startDate = new Date(new Date().getFullYear(), previousMonth - 1, 1);
    const endDate = new Date(
      new Date().getFullYear(),
      today.getMonth() - 1,
      today.getDate(),
      23,
      59,
      59
    );

    try {
      const query = await getOrderResume(startDate, endDate);
      const snapshot = await query.get();
      const allOrders = snapshot.docs
        .filter((orderDoc) => {
          if (orderDoc.data()?.branch?.id === undefined) {
            return false;
          } else {
            return orderDoc.data()?.branch?.id === controller.branchId;
          }
        })
        .map((orderDoc) => ({
          id: orderDoc.id,
          ...orderDoc.data(),
        }));
      setordersBeforeMonth(allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  const getOrderDayPrevousMonth = async () => {
    const previousDayMonth = today.getMonth() - 1;
    const year = today.getFullYear();

    // Asegúrate de manejar el caso especial de enero (mes 0)
    const startDate = new Date(
      year,
      previousDayMonth,
      today.getDate(),
      0,
      0,
      0
    );
    const endDate = new Date(
      year,
      previousDayMonth,
      today.getDate(),
      23,
      59,
      59
    );
    try {
      const query = await getOrderResume(startDate, endDate);
      const snapshot = await query.get();
      const allOrders = snapshot.docs
        .filter((orderDoc) => {
          if (orderDoc.data()?.branch?.id === undefined) {
            return false;
          } else {
            return orderDoc.data()?.branch?.id === controller.branchId;
          }
        })
        .map((orderDoc) => ({
          id: orderDoc.id,
          ...orderDoc.data(),
        }));

      setOrderPreviousDayMonth(allOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  // variables para enviar a las tarjetas
  const deliveredOrders = ordersDay.filter(
    (order) => order.status === "Entregado"
  );
  // ordens en ruta
  const onRouteOrders = ordersDay.filter((order) => order.status === "En ruta");
  // ordenes entregadas al  mes
  const deliveredCurrentMonth = ordersMonth.filter(
    (order) => order.status === "Entregado"
  );
  // ordenes entregadas el mes anterior y dia actual
  const deliveredOrderPreviousMonth = orderPreviousDayMonth.filter(
    (order) => order.status === "Entregado"
  );
  // ordenes entreegadas el mes anterior
  const deliveredOrderLastMonth = ordersBeforeMonth.filter(
    (order) => order.status === "Entregado"
  );

  // diferencia de ordenes al dia
  const differenceOrdersDay =
    deliveredOrderPreviousMonth.length !== 0
      ? Math.min(
          ((deliveredOrders.length - deliveredOrderPreviousMonth.length) /
            deliveredOrderPreviousMonth.length) *
            100,
          100
        )
      : 0;

  const differenceOrdersMonth =
    deliveredOrderLastMonth.length !== 0
      ? Math.min(
          ((deliveredCurrentMonth.length - deliveredOrderLastMonth.length) /
            deliveredOrderLastMonth.length) *
            100,
          100
        )
      : 0;

  return (
    <div>
      {!isLoading ? (
        <div>
          <BreadCrumb match={{ path: "/" }} title="RESÚMEN DE HOY" />
          <div>
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12} md={6} sm={6} lg={4}>
                <ProgressOrders
                  difference={differenceOrdersDay}
                  title="Ordenes entregadas"
                  count={deliveredOrders.length}
                  Icon={BackupTableIcon}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={4}>
                <ProgressOrders
                  difference={0}
                  title={"Ordenes en Ruta"}
                  count={onRouteOrders.length}
                  Icon={Directions}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={4}>
                <ProgressOrders
                  difference={differenceOrdersMonth}
                  title="Ordenes del mes"
                  count={deliveredCurrentMonth.length}
                  Icon={EventIcon}
                />
              </Grid>
            </Grid>
            {/* ultimaas ordenes entregadas */}
            <Grid item xs={12} md={12}>
              <LastOrders ordersDay={deliveredOrders} />
            </Grid>
            {/* grafica para la comparacion */}
            <Grid item xs={12} md={12}>
              <GraphicCompany ordersDay={deliveredOrders} />
            </Grid>

            {/* Tops de los ordenes */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TopPilots ordersDay={deliveredOrders} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TopCompany ordersDay={deliveredOrders} />
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default BranchResume;
