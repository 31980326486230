import React, { Fragment, useEffect } from "react";
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  SearchPanel,
  Export,
} from "devextreme-react/data-grid";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import * as selector from "../../logic/reducers";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { useHistory } from "react-router-dom";

import { isEmpty } from "lodash";

const CompanyAssistanceBiker = ({
  fetchAssistanceMonths,
  startMonths,
  completedMonths,
}) => {
  const [months, setMonths] = React.useState("");
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  useEffect(() => {
    fetchAssistanceMonths(months);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [months]);

  const handleChange = (event) => {
    setMonths(event.target.value);
  };

  const allowedPageSizes = [5, 10, 20, 40, 50];
  const history = useHistory();

  if (completedMonths) {
    // eslint-disable-next-line array-callback-return
    completedMonths.map((data) => {
      if (data.branch.name === "pollo brujo zona 1") {
        data.branch.name = "ZONA 1";
      } else if (data.branch.name === "vista hermosa pollo brujo") {
        data.branch.name = "VISTA HERMOSA";
      } else if (data.branch.name === "	unicentro") {
        data.branch.name = "UNICENTRO";
      } else if (data.branch.name === "sucursal zona 9") {
        data.branch.name = "ZONA 9";
      } else if (data.branch.name === "sucursal portales") {
        data.branch.name = "PORTALES";
      } else if (data.branch.name === "unicentro") {
        data.branch.name = "UNICENTRO";
      } else if (data.branch.name === "sucursal gran via") {
        data.branch.name = "GRAN VIA";
      } else if (data.branch.name === "Vistares") {
        data.branch.name = "VISTARES";
      } else if (data.branch.name === "metro sur") {
        data.branch.name = "METRO SUR";
      } else if (data.branch.name === "kalu pollo brujo ") {
        data.branch.name = "KALU";
      } else if (data.branch.name === "San Lucas") {
        data.branch.name = "SAN LUCAS";
      } else if (data.branch.name === "zona 12") {
        data.branch.name = "ZONA 12";
      } else if (data.branch.name === "sucursal gran plaza") {
        data.branch.name = "GRAN PLAZA";
      }

      /* si existe el createdAt */
      if (data.createdAt) {
        const createdDate = new Date(
          (data.createdAt.seconds + data.createdAt.nanoseconds / 1000000000) *
            1000
        );
        data.createdAt = createdDate;
      } else {
        data.createdAt = "NUEVO FORMATO";
      }

      let initJourneyDate = new Date(data.initJourney.seconds * 1000);
      let endJourneyDate = new Date(data.endJourney.seconds * 1000);
      data.initJourney = initJourneyDate;
      data.endJourney = endJourneyDate;

      if (!isEmpty(data.statusTimes)) {
        if (!isEmpty(data.statusTimes.Iniciado)) {
          const initJourneyPilot = new Date(
            (data.statusTimes.Iniciado.seconds + 21600) * 1000
          );
          data.initJourneyBiker = initJourneyPilot;
        } else {
          data.initJourneyBiker = "NO INICIO";
        }

        if (!isEmpty(data.statusTimes.Completado)) {
          const endJourneyPilot = new Date(
            (data.statusTimes.Completado.seconds + 21600) * 1000
          );
          data.endJourneyBiker = endJourneyPilot;
          /* horas trabajadas */
          let hours = endJourneyDate.getHours() - initJourneyDate.getHours();
          data.hours = hours;
        } else {
          data.endJourneyBiker = "NO INICIO";
        }
      } else {
        data.initJourneyBiker = "NO INICIO";
        data.endJourneyBiker = "NO INICIO";
        data.hours = "NO INICIO";
      }
    });
  }

  const cellRenderStatus = ({ data }) => {
    if (data.status === "Pendiente") {
      return (
        <div className="order-notification order-alert-late">{data.status}</div>
      );
    } else if (data.status === "Iniciado") {
      return (
        <div className="order-notification order-alert-verify">
          {data.status}
        </div>
      );
    } else if (data.status === "Completado") {
      return (
        <div className="order-notification order-alert-on-time">
          {data.status}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="HORARIOS MOTORISTA POR MES"
      />
      <Box sx={{ minWidth: 120 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
        <InputLabel id="demo-simple-select-label">MESES ASISTENCIA DE PILOTO</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={months}
            label="MESES ASISTENCIA DE PILOTO"
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            <MenuItem value={0}>ENERO</MenuItem>
            <MenuItem value={1}>FEBRERO</MenuItem>
            <MenuItem value={2}>MARZO</MenuItem>
            <MenuItem value={3}>ABRIL</MenuItem>
            <MenuItem value={4}>MAYO</MenuItem>
            <MenuItem value={5}>JUNIO</MenuItem>
            <MenuItem value={6}>JULIO</MenuItem>
            <MenuItem value={7}>AGOSTO</MenuItem>
            <MenuItem value={8}>SEPTIEMBRE</MenuItem>
            <MenuItem value={9}>OCTUBRE</MenuItem>
            <MenuItem value={10}>NOVIEMBRE</MenuItem>
            <MenuItem value={11}>DICIEMBRE</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {startMonths ? (
        <CircularProgress disableShrink />
      ) : (
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={completedMonths}
          showBorders={true}
          width={"100%"}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          noDataText="SIN DATOS DE ASISTENCIA MENSUAL"
        >
          <Pager
            showPageSizeSelector={true}
            displayMode={true}
            allowedPageSizes={allowedPageSizes}
            showInfo={true}
            showNavigationButtons={true}
          />
          <FilterRow visible={true} />
          <SearchPanel visible={true} placeholder="BUSCAR" width={240} />
          <Export enabled={true} />
          <Paging enabled={true} defaultPageSize={20} />
          <Column dataField="alias.name" caption="NOMBRE" width={"auto"} />
          <Column dataField="branch.name" caption="SUCURSAL" width={"auto"} />
          <Column
            dataField="createdAt"
            caption="HORA DE CREACION"
            width={"auto"}
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
          />
          <Column
            dataField="initJourney"
            caption="HORA INICIO"
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
            width={"auto"}
          />
          <Column
            dataField="endJourney"
            caption="HORA FINAL"
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
            width={"auto"}
          />
          <Column
            dataField="status"
            caption="ESTATUS"
            allowEditing={false}
            cellRender={cellRenderStatus}
          />
          <Column
            dataField="hours"
            caption="HORA TRABAJADAS"
            width={"auto"}
            allowEditing={false}
          />
          <Column
            dataField="initJourneyBiker"
            caption="HORA INICIADO"
            allowEditing={false}
            format="dd/MM/yyyy HH:mm"
            dataType="datetime"
          />
          <Column
            dataField="endJourneyBiker"
            caption="HORA FINALIZADO"
            allowEditing={false}
            format="dd/MM/yyyy HH:mm"
            dataType="datetime"
          />
        </DataGrid>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    startMonths: selector.getPilotSchedulesMonthStarted(state),
    completedMonths: selector.getPilotSchedulesMonthCompleted(state),
    pilotStarted: selector.getPilotLinkStarted(state),
    pilotCompleted: selector.getPilotLinkCompleted(state),
  }),
  (dispatch) => ({
    fetchAssistanceMonths(months) {
      dispatch(actions.pilotSchedulesMonthsStarted(months));
    },
    fetchingPilots(id) {
      dispatch(actions.pilotGetLinkStarted(id));
    },
  })
)(CompanyAssistanceBiker);
