import * as types from "../types/auth";

//  FETCH USERS
export const startingAuthentication = (email, password) => ({
  type: types.AUTHENTICATION_STARTED,
  payload: {
    email,
    password,
  },
});

export const completingAuthentication = (token, user, controller, email, apiToken) => ({
  type: types.AUTHENTICATION_COMPLETED,
  payload: {
    token,
    user,
    controller,
    email,
    apiToken
  },
});

export const failingAuthentication = (error) => ({
  type: types.AUTHENTICATION_FAILED,
  payload: {
    error,
  },
});

//  LOG OUT USER
export const logOut = () => ({
  type: types.LOGOUT,
});

export const startingCreatingAuth = (values) => ({
  type: types.CREATING_USER_STARTED,
  payload: {
    values
  }
});

export const completedCreatingAuth = (datos) => ({
  type: types.CREATING_USER_COMPLETED,
  payload: {
    datos
  }
});

export const failedCreatingAuth = (error) => ({
  type: types.CREATING_USER_FAILED,
  payload: {
    error
  }
});