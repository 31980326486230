//  FETCH ALL BRANCH OFFICES
export const BRANCH_OFFICES_FETCH_STARTED = 'BRANCH_OFFICES_FETCH_STARTED';
export const BRANCH_OFFICES_FETCH_COMPLETED = 'BRANCH_OFFICES_FETCH_COMPLETED';
export const BRANCH_OFFICES_CLEAR = 'BRANCH_OFFICES_CLEAR';
export const BRANCH_OFFICES_FETCH_FAILED = 'BRANCH_OFFICES_FETCH_FAILED';

//  SELECT A SPECIFIC BRANCH OFFICE
export const BRANCH_OFFICE_SELECTED = 'BRANCH_OFFICE_SELECTED';

//  DESELECT A SPECIFIC BRANCH OFFICE
export const BRANCH_OFFICE_DESELECTED = 'BRANCH_OFFICE_DESELECTED';

//  FETCH BRANCHES
export const BRANCHES_FETCH_STARTED = 'BRANCHES_FETCH_STARTED';
export const BRANCHES_FETCH_COMPLETED = 'BRANCHES_FETCH_COMPLETED';
export const BRANCHES_FETCH_FAILED = 'BRANCHES_FETCH_FAILED';
export const CLEAR_BRANCHES = 'CLEAR_BRANCHES';

//  CREATE BRANCH
export const BRANCH_CREATE_STARTED = 'BRANCH_CREATE_STARTED';
export const BRANCH_CREATE_COMPLETED = 'BRANCH_CREATE_COMPLETED';
export const BRANCH_CREATE_FAILED = 'BRANCH_CREATE_FAILED';

//  DELETE BRANCH
export const BRANCH_DELETE_STARTED = 'BRANCH_DELETE_STARTED';
export const BRANCH_DELETE_COMPLETED = 'BRANCH_DELETE_COMPLETED';
export const BRANCH_DELETE_FAILED = 'BRANCH_DELETE_FAILED';

export const CLEAR_BRANCH_STATUSES = 'CLEAR_BRANCH_STATUSES';
