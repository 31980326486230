import { INPUT_TYPE, STATUS_OPTIONS } from "./constants";

export const emptyOrder = {
  address: "",
  alert: "",
  alias: {},
  branch: {},
  company: {},
  locationRef: {},
  locationEnd: {},
  typeOrder: "ORDEN CLIENTE",
  name: "", // client
  nota: "", // observaciones
  orderObservations: "",
  orderId: undefined, // orden
  orderPic: "",
  provider: undefined,
  phone: undefined,
  status: "",
  time: undefined,
  depto: "",
  munic: "",
  zone: "",
  payMethod: "",
};

export const emptyBranch = {
  orderId: "",
  name: "",
  address: "",
  depto: "",
  munic: "",
  zone: "",
  phone: 0,
  nota: "",
  payMethod: {},
  toBeDeliveredAt: "",
  locationRef: {},
  distanceRef: 0,
  costRef: 0,
  isPriority: false,
};

export const defaultFormFieldsOrderBranch = [
  {
    label: "Orden",
    name: "orderId",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Cliente",
    name: "name",
    fullWidth: true,
    cssClasses: "col-12",
  },
  {
    label: "Dirección",
    name: "address",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Departamento",
    name: "depto",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
  },
  {
    label: "Municipio",
    name: "munic",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
  },

  {
    label: "Zona",
    name: "zone",
    fullWidth: true,
    cssClasses: "col-4",
    type: "number",
  },
  {
    label: "Teléfono",
    cssClasses: "col-12",
    name: "phone",
    inputType: INPUT_TYPE.FIELD,
    fullWidth: true,
    type: "number",
  },
  {
    label: "Observaciones",
    name: "nota",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Observaciones de la Orden",
    name: "orderObservations",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Forma de Pago",
    name: "payMethod",
    cssClasses: "col-12",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    fullWidth: true,
    options: [
      {
        value: "cash",
        text: "Efectivo",
      },
      {
        value: "credit-card",
        text: "Tarjeta de Crédito",
      },
      {
        value: "visa-link",
        text: "Visa en Link",
      },
    ],
  },
  {
    label: "Agendar",
    name: "toBeDeliveredAt",
    cssClasses: "col-12",
    fullWidth: true,
    type: "date",
  },
  {
    label: "Latitud Sugerida",
    name: "locationRef.latitude",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Longitud Sugerida",
    name: "locationRef.longitude",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Distancia",
    name: "distanceRef",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Costo",
    name: "costRef",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Prioritario",
    name: "isPriority",
    cssClasses: "col-12",
    fullWidth: true,
    inputType: INPUT_TYPE.CHECKBOX,
  },
];

export const defaultFormFields = [
  {
    label: "Compañía",
    name: "company",
    cssClasses: "col-6",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
    fullWidth: true,
  },
  {
    label: "Sucursal",
    cssClasses: "col-6",
    name: "branch",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    fullWidth: true,
  },

  {
    label: "Orden",
    name: "orderId",
    cssClasses: "col-6",
    fullWidth: true,
  },
  {
    label: "Agente",
    name: "alias",
    cssClasses: "col-6",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Cliente",
    name: "name",
    fullWidth: true,
    cssClasses: "col-12",
  },
  {
    label: "Dirección",
    name: "address",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Departamento",
    name: "depto",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
    // inputType: INPUT_TYPE.FILTERABLE_LIST,
    // isLoading: true,
  },
  {
    label: "Municipio",
    name: "munic",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
    // inputType: INPUT_TYPE.FILTERABLE_LIST,
  },

  {
    label: "Zona",
    name: "zone",
    fullWidth: true,
    cssClasses: "col-4",
    type: "number",
  },
  {
    label: "Teléfono",
    cssClasses: "col-12",
    name: "phone",
    inputType: INPUT_TYPE.FIELD,
    fullWidth: true,
    type: "number",
  },
  {
    label: "Observaciones",
    name: "nota",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Observaciones de la Orden",
    name: "orderObservations",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Forma de Pago",
    name: "payMethod",
    cssClasses: "col-12",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    fullWidth: true,
    options: [
      {
        value: "cash",
        text: "Efectivo",
      },
      {
        value: "credit-card",
        text: "Tarjeta de Crédito",
      },
      {
        value: "visa-link",
        text: "Visa en Link",
      },
    ],
  },
  {
    label: "Latitud Sugerida",
    name: "locationRef.latitude",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Longitud Sugerida",
    name: "locationRef.longitude",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
];

export const editDefaultFormFields = [
  {
    label: "Compañía",
    name: "company",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    cssClasses: "col-6 margin-top-md",
    fullWidth: true,
    isLoading: true,
  },
  {
    label: "Sucursal",
    name: "branch",
    cssClasses: "col-6 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
  },
  {
    label: "Estado",
    autofocus: true,
    name: "status",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    ...STATUS_OPTIONS,
  },
  {
    label: "Hora de Comanda",
    name: "createdAt",
    type: "datetime-local",
    cssClasses: "col-4",
    inputType: INPUT_TYPE.DATE_TIME,
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Agente",
    name: "alias",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
  },
  {
    label: "TIPO DE ENTREGA",
    name: "typeOrder",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Orden",
    name: "orderId",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Hora de Entrega",
    name: "deliveredAt",
    type: "datetime-local",
    fullWidth: true,
    cssClasses: "col-12",
    disabled: true,
  },
  {
    label: "Forma de Pago",
    name: "payMethod",
    cssClasses: "col-12",
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    fullWidth: true,
    options: [
      {
        value: "cash",
        text: "Efectivo",
      },
      {
        value: "credit-card",
        text: "Tarjeta de Crédito",
      },
      {
        value: "visa-link",
        text: "Visa en Link",
      },
    ],
    disabled: false,
  },
  {
    label: "Cliente",
    name: "name",
    fullWidth: true,
    cssClasses: "col-12",
  },
  {
    label: "Dirección",
    name: "address",
    fullWidth: true,
    multiline: true,
    cssClasses: "col-12",
  },
  {
    label: "Departamento",
    name: "depto",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
    // inputType: INPUT_TYPE.FILTERABLE_LIST,
    // isLoading: true,
  },
  {
    label: "Municipio",
    name: "munic",
    fullWidth: true,
    cssClasses: "col-4 text-capitalize",
    // inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Zona",
    name: "zone",
    fullWidth: true,
    cssClasses: "col-4",
    type: "number",
  },
  {
    label: "Teléfono",
    cssClasses: "col-12",
    name: "phone",
    inputType: INPUT_TYPE.FIELD,
    fullWidth: true,
    type: "number",
  },
  {
    label: "Observaciones",
    name: "nota",
    fullWidth: true,
    multiline: true,
    cssClasses: "col-12",
  },
  {
    label: "Observaciones de la Orden",
    name: "orderObservations",
    fullWidth: true,
    cssClasses: "col-12",
    multiline: true,
  },
  {
    label: "Proveedor",
    name: "provider",
    fullWidth: true,
    multiline: true,
    cssClasses: "col-12",
    disabled: true,
  },
  {
    label: "Latitud Sugerida",
    name: "locationRef.latitude",
    cssClasses: "col-3",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Longitud Sugerida",
    name: "locationRef.longitude",
    cssClasses: "col-3",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Latitud de Entrega",
    name: "locationEnd.latitude",
    cssClasses: "col-3",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Longitud de Entrega",
    name: "locationEnd.longitude",
    cssClasses: "col-3",
    fullWidth: true,
    disabled: true,
  },

  {
    label: "Tiempo en Ruta",
    name: "time",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
  {
    label: "Alertas",
    name: "alert",
    cssClasses: "col-6",
    fullWidth: true,
    disabled: true,
  },
];

export const SINGLE_TABLE_ORDER_HEADERS = [
  { name: "ALERTA", value: "alert" },
  { name: "ORDEN", value: "orderId" },
  { name: "CLIENTE", value: "name" },
  { name: "ESTADO", value: "status" },
  { name: "EMPRESA", value: "company.name" },
  { name: "AGENTE", value: "alias.name" },
  { name: "DIRECCIÓN", value: "address" },
  { name: "TIEMPO EN RUTA", value: "time" },
  { name: "CIRCULO", value: "createdDate" },
];
