import React, { useState } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import logo from "../../assets/img/logo.png";
import theme from "../../assets/theme";
import * as actions from "../../logic/actions/auth";
import { useDispatch } from "react-redux";
const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    letterSpacing: "3px",
  },
  list: {
    marginTop: theme.spacing(3),
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logo: {
    marginTop: theme.spacing(6),
  },

  hide: {
    display: "none",
  },
}));
const XsNavBar = ({ handleDrawerOpen, open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuIcon = () => {
    dispatch(actions.logOut());
    history.push("/login");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              style={{
                width: "60px",
                height: "auto",
                marginRight: "10px",
                cursor: "pointer",
              }}
              alt="logo"
            />
            <div className={classes.title}></div>
            <Fragment>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleMenuIcon()}>
                  <ListItemIcon>
                    <MeetingRoom fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar Sesión" />
                </MenuItem>
              </Menu>
            </Fragment>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Fragment>
  );
};
export default XsNavBar;
