/* eslint-disable no-unused-vars */
import axios from "axios";
import { firebase, storage } from "../../firebase/config"
import { isEmpty } from "lodash";
import { DEV_API_URL } from "../../constants/constants";

const service = "companies-service";

const createCompany = async (
  name,
  description,
  logoUrl,
  sms,
  email,
  emails
) => {
  const response = await axios.post(`${DEV_API_URL}/${service}/companies`, {
    name,
    description: isEmpty(description) ? undefined : description,
    enable: true,
    logoUrl,
    sms,
    email,
    emails,
  });
  return response.data;
};
const deleteCompany = async (id) => {
  const response = await axios.patch(
    `${DEV_API_URL}/${service}/companies/${id}`,
    {
      enable: false,
    }
  );
  return response.data;
};

const modifyCompany = async (
  id,
  name,
  description,
  logoUrl,
  sms,
  email,
  emails
) => {
  const response = await axios.patch(
    `${DEV_API_URL}/${service}/companies/${id}`,
    {
      name,
      description: isEmpty(description) ? undefined : description,
      logoUrl,
      sms,
      email,
      emails,
      enable: true,
    }
  );
  return response.data;
};

const getCompanies = async () => {
  const response = await axios.get(
    `${DEV_API_URL}/${service}/companies?limit=10000`
  );
  return response.data;
};

const getCompaniById = async (id) => {
  const response = await axios.get(`${DEV_API_URL}/${service}/${id}`);
  return response.data;
};

const gettingCompany = async () => {
  try {

      const data = firebase.firestore().collection("companies");
      const getCompany = await data.get();
      let array = [];
      getCompany.forEach((doc)=> {
          array.push({id: doc.id,data:doc.data()})
      })
      //console.log(array);
      if(array.length>0){
          return{
              data: array,
              error: null
          }
      }
  } catch (error) {
      console.log(error.message);
      return{
          data: null,
          error
      };
  }
}


const companyService = {
  getCompanies,
  getCompaniById,
  createCompany,
  modifyCompany,
  deleteCompany,
  gettingCompany
};

export default companyService;
