import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { INPUT_TYPE, orderStatus } from "../../constants/constants";
import arrayHelper from "../../helpers/arrayHelper";
import Alert from "@material-ui/lab/Alert";
import {
  getBikerList,
  getIsBikerListLoading,
} from "../../logic/selectors/bikers";
import {
  getCompanies,
  getAreCompaniesLoading,
} from "../../logic/selectors/company";
import {
  getBranches,
  getAreBranchesLoading,
} from "../../logic/selectors/branch";
import isEmpty from "lodash/isEmpty";
import Modal from "../Design/Modal";
import ObservationsModal from "../Order/ObservationsModal";
import { pilotActions } from "../../logic/actions/pilots";
import { branchActions } from "../../logic/actions/branches";
import companyActions from "../../logic/actions/company";
import DynamicForm from "../Design/Form/DynamicForm";

const OrderForm = ({
  defaultFormFields,
  order,
  setOrder,
  btnList,
  onSubmit,
  handleChange,
  validationSchema,
  closeBikers = false,
  useService = false,
  isEditing = false,
  prevOrder,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [cancelledOrder, setCancelledOrder] = useState({});
  const [isFetching, setIsFetching] = useState({
    bikers: false,
    companies: false,
    branches: false,
  });
  const [customErrors, setCustomErrors] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [formFields, setFormFields] = useState([...defaultFormFields]);
  // Biker
  const bikers = useSelector(getBikerList);
  const isBikerListLoading = useSelector(getIsBikerListLoading);
  // Company
  const companies = useSelector(getCompanies);
  const areCompaniesLoading = useSelector(getAreCompaniesLoading);
  // Branches
  const branches = useSelector(getBranches);
  const areBranchesLoading = useSelector(getAreBranchesLoading);

  const onBranchSelected = (branch) => {
    const bikerField = formFields.find((el) => el.name === "alias");
    bikerField.inputType = INPUT_TYPE.FILTERABLE_LIST;
    bikerField.options = [];
    dispatch(pilotActions.clearPilotsData());
    if (!isEmpty(branch)) {
      dispatch(
        pilotActions.getPilots({
          branchId: branch.value,
          closeBikers,
          useService,
        })
      );
      setIsFetching((val) => ({
        ...val,
        bikers: true,
      }));
    }
    setOrder((val) => ({
      ...val,
      branch,
      alias: {},
    }));
  };

  const onCompanySelected = (company) => {
    const branchField = formFields.find((el) => el.name === "branch");
    branchField.inputType = INPUT_TYPE.FILTERABLE_LIST;
    branchField.options = [];
    const bikerField = formFields.find((el) => el.name === "alias");
    bikerField.options = [];
    dispatch(branchActions.clearBranches());
    dispatch(pilotActions.clearPilotsData());
    if (!isEmpty(company)) {
      dispatch(
        branchActions.getBranches({
          companyId: company.value,
        })
      );
      setIsFetching((val) => ({
        ...val,
        branches: true,
      }));
    }
    setOrder((val) => ({
      ...val,
      company,
      branch: {},
      alias: {},
    }));
  };

  useEffect(() => {
    let isBranchFetching = false;
    let isBikerLoading = false;
    dispatch(pilotActions.clearPilotsData());
    dispatch(companyActions.clearCompanies());
    dispatch(branchActions.clearBranches());
    dispatch(companyActions.getCompanies());
    if (!isEmpty(order.branch)) {
      dispatch(
        branchActions.getBranches({
          companyId: order.company.value,
        })
      );
      isBranchFetching = true;
    }
    if (!isEmpty(order.branch)) {
      dispatch(
        pilotActions.getPilots({
          branchId: order.branch.value,
          closeBikers,
          useService,
        })
      );
      isBikerLoading = true;
    }
    setIsFetching({
      bikers: isBikerLoading,
      companies: true,
      branches: isBranchFetching,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnAgentsLoaded(
    bikers,
    isBikerListLoading,
    isFetching,
    setIsFetching,
    formFields,
    order
  );
  useOnCompaniesLoaded(
    companies,
    areCompaniesLoading,
    isFetching,
    setIsFetching,
    formFields,
    onCompanySelected,
    order
  );
  useOnBranchesLoaded(
    branches,
    areBranchesLoading,
    isFetching,
    setIsFetching,
    formFields,
    order,
    onBranchSelected
  );

  const onFormSubmitted = (values) => {
    if (
      isEmpty(values.locationRef) ||
      (!isEmpty(values.locationRef) &&
        (values.locationRef.latitude === 0 ||
          values.locationRef.longitude === 0))
    ) {
      setCustomErrors([
        "Debes ingresar una latitude y longitude, usa la pestaña de Mapa para seleccionar el lugar de destino",
      ]);
      window.scrollTo(0, 0);
      return;
    }
    setCustomErrors([]);
    let _createdAt = "";
    if (!isEmpty(prevOrder) && prevOrder.status === orderStatus.CANCELLED) {
      _createdAt = values.createdAt;
    }

    if(!isEmpty(prevOrder)&& prevOrder.status === orderStatus.RE_AGEND){
      _createdAt = values.createdAt;
    }
    
    delete values.createdAt;
    delete values.deliveredAt;
    delete values.canceledAt;
    let locationRef;
    if (!isEmpty(values.locationRef)) {
      locationRef = {
        latitude: values.locationRef.latitude,
        longitude: values.locationRef.longitude,
      };
    }

    const editedOrder = {
      ...values,
      locationRef,
      _createdAt,
    };

    if (!isEmpty(editedOrder.alias)) {
      editedOrder.alias_id = editedOrder.alias.value;
      delete editedOrder.alias;
    }
    if (!isEmpty(editedOrder.status)) {
      editedOrder.status = editedOrder.status.value;
    }
    if (!isEmpty(editedOrder.branch)) {
      editedOrder.branch_id = editedOrder.branch.value;
      delete editedOrder.branch;
    }

    if (!isEmpty(editedOrder.company)) {
      editedOrder.company_id = editedOrder.company.value;
      delete editedOrder.company;
    }
    if (!isEmpty(editedOrder.locationRef)) {
      editedOrder.origin = `${editedOrder.locationRef.latitude},${editedOrder.locationRef.longitude}`;
      delete editedOrder.locationRef;
    }
    if (isEditing && editedOrder.status === orderStatus.CANCELLED) {
      delete editedOrder.alias_id;
      setCancelledOrder(editedOrder);
    } else {
      onSubmit(editedOrder);
    }
  };

  const onModalClose = () => {
    setCancelledOrder({});
  };

  const onModalSubmit = (orderToCancel) => {
    onSubmit(orderToCancel, false);
    setCancelledOrder({});
  };

  return (
    <>
      {!isEmpty(cancelledOrder) && (
        <Modal
          isOpened={!isEmpty(cancelledOrder)}
          onClose={onModalClose}
          body={
            <ObservationsModal
              onModalClose={onModalClose}
              onModalSubmit={onModalSubmit}
              cancelledOrder={cancelledOrder}
            />
          }
        />
      )}
      {!isEmpty(customErrors) && (
        <div className="custom-errors">
          {customErrors.map((customError, i) => (
            <Alert key={`custom-error-${i}`} severity="error">
              {customError}
            </Alert>
          ))}
        </div>
      )}
      <DynamicForm
        obj={order}
        fields={formFields}
        validationSchema={validationSchema}
        buttons={btnList}
        handleChange={handleChange}
        onSubmit={onFormSubmitted}
        btnAlignment="right"
        setState={setOrder}
        {...rest}
      />
    </>
  );
};

const useOnBranchesLoaded = (
  branches,
  areBranchesLoading,
  isFetching,
  setIsFetching,
  formFields,
  order,
  onBranchSelected
) => {
  useEffect(() => {
    if (isFetching.branches && !areBranchesLoading) {
      setIsFetching((val) => ({
        ...val,
        branches: false,
      }));
      const branchField = formFields.find((el) => el.name === "branch");
      if (!isEmpty(branches)) {
        branchField.isLoading = false;
        branchField.onValueChanged = onBranchSelected;
        branchField.options = branches.map((branch) => ({
          value: branch.id,
          text: branch.name,
        }));
        branchField.options = arrayHelper.addElementToOptions(
          branchField,
          order.branch
        );
      } else {
        branchField.isLoading = false;
        branchField.inputType = INPUT_TYPE.FIELD;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, areBranchesLoading, isFetching]);
};

const useOnCompaniesLoaded = (
  companies,
  areCompaniesLoading,
  isFetching,
  setIsFetching,
  formFields,
  onCompanySelected,
  order
) => {
  useEffect(() => {
    if (isFetching.companies && !areCompaniesLoading) {
      setIsFetching((val) => ({
        ...val,
        companies: false,
      }));
      const companyFieldIndex = formFields.findIndex(
        (el) => el.name === "company"
      );
      if (!isEmpty(companies)) {
        formFields[companyFieldIndex].isLoading = false;
        formFields[companyFieldIndex].onValueChanged = onCompanySelected;
        formFields[companyFieldIndex].options = companies.map((company) => ({
          value: company.id,
          text: company.name,
        }));
        formFields[companyFieldIndex].options = arrayHelper.addElementToOptions(
          formFields[companyFieldIndex],
          order.company
        );
      } else {
        formFields[companyFieldIndex].isLoading = false;
        formFields[companyFieldIndex].inputType = INPUT_TYPE.FIELD;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, areCompaniesLoading, isFetching]);
};

const useOnAgentsLoaded = (
  bikers,
  isBikerListLoading,
  isFetching,
  setIsFetching,
  formFields,
  order
) => {
  useEffect(() => {
    if (isFetching.bikers && !isBikerListLoading) {
      setIsFetching((val) => ({
        ...val,
        bikers: false,
      }));
      const bikerField = formFields.find((el) => el.name === "alias");
      if (!isEmpty(bikers)) {
        bikerField.isLoading = false;
        bikerField.options = (bikers || []).map((biker) => ({
          value: biker.id,
          text: biker.name,
        }));
        bikerField.options = arrayHelper.addElementToOptions(
          bikerField,
          order.alias
        );
      } else {
        bikerField.isLoading = false;
        bikerField.inputType = INPUT_TYPE.FIELD;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikers, isBikerListLoading, isFetching]);
};

export default OrderForm;