import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
// import { ROLES } from "../../constants/constants";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import companyActions from "../../logic/actions/company";
// import arrayHelper from "../../helpers/arrayHelper";
import { snackBarConst } from "../../constants/constants";
import { useSnackbar } from "notistack";
import {isEmpty} from "lodash"
// import { getCompanies } from "../../logic/selectors/company";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © DELIVERY LAB '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const CreateUsers = ({
    findEmail,
    findEmailAndPilot,
    foundEmail,
    failedEmail,
    startUpdateUser,
    completedUpdateUser,
    failedUpdateUser,
    updateController,
}) => {
    const dispatch = useDispatch();
    const [emailSearch, setEmailSearch] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(companyActions.getCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = yup.object({
        password: yup.string().required("Ingrese contraseña")
    });
    useEffect(() => {
         if (foundEmail !== null && foundEmail !== "Error when you try getting emails" && foundEmail.value !== undefined) {
             enqueueSnackbar("Usuario Encontrado", {
                 variant: "success",
                 preventDuplicate: true,
                 anchorOrigin: {
                     ...snackBarConst,
                 },
             });
         }else if(foundEmail === 'Error when you try getting emails'){
             enqueueSnackbar("Error al encontrar los usuarios", {
                 variant: "warning",
                 preventDuplicate: true,
                 anchorOrigin: {
                     ...snackBarConst,
                 },
             });
         }
         if(foundEmail){
            if(foundEmail.value===undefined){
                enqueueSnackbar("No es un usuario Verificar", {
                    variant: "error",
                    preventDuplicate: true,
                    anchorOrigin: {
                        ...snackBarConst,
                    },
                });
            }}
         if (completedUpdateUser !== 'Error when you try update user' && completedUpdateUser !== null) {
             enqueueSnackbar("Usuario creado correctamente", {
                 variant: "success",
                 preventDuplicate: true,
                 anchorOrigin: {
                     ...snackBarConst,
                 },
             });
         } else if (completedUpdateUser === 'Error when you try update user') {
             enqueueSnackbar(
                 "Hubo un error al Actualizar al usuario",
                 {
                     variant: "warning",
                     preventDuplicate: true,
                     anchorOrigin: {
                         ...snackBarConst,
                     },
                 }
             );
        }
    }, [foundEmail, failedEmail, enqueueSnackbar,setEmailSearch,findEmail,completedUpdateUser])


    const SearchEmail = (e) => {
        setEmailSearch(e.target.value);
    };

    const onClickSearchEmail = async (e) => {
        await findEmail({emailSearch, type: 'bikers'});
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#F88B1E' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        ACTUALIZAR CONTRASEÑA
                    </Typography>
                    <Typography variant='subtitle2'>
                        PILOTO
                    </Typography>
                    <div style={{ paddingTop: '20px', width: '100%' }}>
                        <TextField fullWidth label="CORREO" placeholder='BUSCAR CORREO' type='email' onChange={SearchEmail} />
                    </div>
                    <div style={{ width: '100%', paddingTop: '20px' }}>
                        <Button fullWidth variant="contained" color="primary" onClick={onClickSearchEmail}> BUSCAR CORREO </Button>
                    </div>
                    <div>
                       {
                           foundEmail !== "Error when you try getting emails" && foundEmail !== null && foundEmail.value !== undefined &&(
                            <div>
                            <div style={{textAlign:'center',padding:'20px'}}>DATOS USUARIO</div>
                            <TextField style={{marginTop:'10px'}} label="NOMBRE ACTUAL" placeholder="NOMBRE ACTUAL" fullWidth disabled value={isEmpty(foundEmail)?"":foundEmail?.value.name}/>
                            <TextField style={{marginTop:'10px'}} label="EMPRESA" placeholder="EMPRESA" fullWidth disabled value={isEmpty(foundEmail)?"":foundEmail?.value.company}/>
                            <TextField style={{marginTop:'10px'}} label="TELEFONO" placeholder="TELEFONO" fullWidth disabled value={isEmpty(foundEmail)?"":foundEmail?.value.phone}/>
                            </div>
                           )
                       }
                    </div>
                    {
                        findEmailAndPilot ? (
                            <div> Buscando ... </div>
                        ) : (

                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <div style={{textAlign:'center'}}> ACTUALIZAR CONTRASEÑA </div>
                                <h6 style={{textAlign:'center',color:'#D6D6D6'}}>{`SI ACTUALIZARA ( LLENAR TODOS LOS DATOS )`}</h6>
                                <Formik
                                    initialValues={{
                                        password: "",
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        let props = {
                                            ...values,
                                            uid: isEmpty(foundEmail)?"":foundEmail.email.data.uid,
                                        };
                                        updateController(props);
                                        resetForm({ values: "" })
                                    }}
                                    validationSchema={schema}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        values,
                                        touched,
                                    }) => (
                                        <form>
                                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="password"
                                                    label="Contraseña"
                                                    name="password"
                                                    autoComplete="password"
                                                    autoFocus
                                                    value={values.password}
                                                    error={errors.password && touched.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={(errors.password && touched.password) && errors.password}
                                                />
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                   Actualizar Cuenta
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        )
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

export default connect(
    (state) => ({
        // Update Data
        startUpdateUser: selectors.passwordUpdateBikers(state),
        completedUpdateUser: selectors.passwordUpdatedBikers(state),
        failedUpdateUser: selectors.passwordUpdateBikersFailed(state),
        // Getting Data
        findEmailAndPilot: selectors.findControllerAndUserEmail(state),
        foundEmail: selectors.foundControllerAndUserEmail(state),
        failedEmail: selectors.failedControllerAndUserEmail(state)
    }),
    (dispatch) => ({
        updateController(values) {
            dispatch(actions.updatePasswordBiker(values));
        },

        findEmail(email) {
            dispatch(actions.findEmail(email));
        }
    })
)(CreateUsers);