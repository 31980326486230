import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { CheckCircleOutline as DeliveredIcon } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const Paymethod = ({ ordersDay }) => {
  const [topCount, setTopCount] = useState(5);
  const ordersDeliveries = ordersDay.filter(
    (order) => order.status === "Entregado"
  );

  const sortedDeliveredOrders = ordersDeliveries.sort(
    (a, b) => b.deliveredAt.seconds - a.deliveredAt.seconds
  );

  const formatPaymethod = (payMethod) => {
    if (payMethod === "visa-link") {
      return "VISA LINK";
    } else if (payMethod === "credit-card") {
      return "TARJETA DE CRÉDITO";
    } else if (payMethod === "cash") {
      return "EFECTIVO";
    } else {
      return "NO TIENE METODO DE PAGO";
    }
  };

  const orders = () => {
    const orders = {};
    sortedDeliveredOrders.forEach((order) => {
      const payMethod = formatPaymethod(order.payMethod);
      if (!orders[payMethod]) {
        orders[payMethod] = { delivered: 0 };
      }
      orders[payMethod].delivered += 1;
    });

    const topOrders = Object.entries(orders)
      .sort((a, b) => b[1].delivered - a[1].delivered)
      .slice(0, 10);
    return topOrders;
  };

  const topOrder = orders(ordersDay).slice(0, topCount);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <CardContent style={{ flex: "1 0 auto", textAlign: "center" }}>
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          METODOS DE PAGO
        </Typography>
        <Box display="flex" justifyContent="center" mb={2}>
          <ButtonGroup
            color="secondary"
            variant="outlined"
            aria-label="outlined secondary button group"
            disabled={false}
          >
            <Button
              variant={topCount === 5 ? "contained" : "outlined"}
              onClick={() => setTopCount(5)}
            >
              PAGO
            </Button>
            <Button
              variant={topCount === 10 ? "contained" : "outlined"}
              onClick={() => setTopCount(10)}
            >
              PAGO
            </Button>
          </ButtonGroup>
        </Box>
        <List>
          {topOrder.map(([payMethod, data], index) => (
            <div key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Typography variant="body2">
                    <Avatar style={{ backgroundColor: "#f26724" }}>
                      {payMethod.charAt(0)}
                    </Avatar>
                  </Typography>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="body2">{payMethod}</Typography>
                </ListItemText>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box display="flex" alignItems="center">
                    <Tooltip title={`Entregadas: ${data.delivered}`}>
                      <span>
                        <IconButton
                          size="small"
                          disabled={data.delivered === 0}
                        >
                          <DeliveredIcon
                            color={data.delivered > 0 ? "success" : "disabled"}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography variant="body2">{data.delivered}</Typography>
                  </Box>
                </Stack>
              </ListItem>
              {index < topOrder.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Paymethod;
