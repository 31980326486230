import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { connect, useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { ROLES } from "../../constants/constants";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import companyActions from "../../logic/actions/company";
import arrayHelper from "../../helpers/arrayHelper";
import { snackBarConst } from "../../constants/constants";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";
import { getCompanies } from "../../logic/selectors/company";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © DELIVERY LAB "}

      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const CreateUsers = ({
  findEmail,
  findEmailAndPilot,
  foundEmail,
  failedEmail,
  startUpdateUser,
  completedUpdateUser,
  failedUpdateUser,
  updateController,
}) => {
  const companies = useSelector(getCompanies);
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState("");
  const [company, setCompany] = React.useState({ id: "", name: "" });
  const [getBranch, setGetBranch] = React.useState(null);
  const [sendBranch, setSendBranch] = React.useState("");
  const [emailSearch, setEmailSearch] = React.useState("");
  const [enabled, setEnabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(companyActions.getCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeUser = (event) => {
    setUsers(event.target.value);
  };

  const schema = yup.object({
    firstName: yup.string().required("Ingrese un Nombre"),
    lastName: yup.string().required("Ingrese un Apellido"),
  });

  const handleChangeCompany = (event) => {
    // console.log(event.target.value);
    setCompany(event.target.value);
  };

  useEffect(() => {
    const branch = arrayHelper.searchBranch();
    branch.then((response) => {
      let filterBranch = response.filter(
        (branchFilter) => branchFilter._idCompany === company.id
      );
      setGetBranch(filterBranch);
    });
  }, [company, setGetBranch]);

  const handleChangeBranch = (event) => {
    // console.log(event.target.value)
    setSendBranch(event.target.value);
  };

  useEffect(() => {
    if (
      foundEmail !== "Error when you try getting emails" &&
      foundEmail !== null
    ) {
      setEnabled(false);
    } else if (foundEmail === "Error when you try getting emails") {
      setEnabled(true);
    }

    if (
      foundEmail !== null &&
      foundEmail !== "Error when you try getting emails"
    ) {
      enqueueSnackbar("Usuario Encontrado", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    } else if (foundEmail === "Error when you try getting emails") {
      enqueueSnackbar("Error al encontrar los usuarios", {
        variant: "warning",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    }
    if (foundEmail) {
      if (foundEmail.value === undefined) {
        enqueueSnackbar("No es un usuario Verificar", {
          variant: "error",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
      }
    }
    if (
      completedUpdateUser !== "Error when you try update the controller" &&
      completedUpdateUser !== null
    ) {
      enqueueSnackbar("Usuario actualizado correctamente", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    } else if (
      completedUpdateUser === "Error when you try update the controller"
    ) {
      enqueueSnackbar("Hubo un error al Actualizar al usuario", {
        variant: "warning",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
      });
    }
  }, [
    foundEmail,
    failedEmail,
    enqueueSnackbar,
    setEmailSearch,
    findEmail,
    completedUpdateUser,
  ]);

  const SearchEmail = (e) => {
    setEmailSearch(e.target.value);
  };

  const onClickSearchEmail = async (e) => {
    await findEmail({ emailSearch, type: "controllers" });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#F88B1E" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ACTUALIZAR DATOS
          </Typography>
          <Typography variant="subtitle2">CONTROLADOR O USUARIO</Typography>
          <div style={{ paddingTop: "20px", width: "100%" }}>
            <TextField
              fullWidth
              label="CORREO"
              placeholder="BUSCAR CORREO"
              type="email"
              onChange={SearchEmail}
            />
          </div>
          <div style={{ width: "100%", paddingTop: "20px" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClickSearchEmail}
            >
              {" "}
              BUSCAR CORREO{" "}
            </Button>
          </div>
          <div>
            {foundEmail !== "Error when you try getting emails" &&
              foundEmail !== null &&
              foundEmail.value !== undefined && (
                <div>
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    DATOS USUARIO
                  </div>
                  <TextField
                    style={{ marginTop: "10px" }}
                    label="NOMBRE"
                    placeholder="NOMBRE"
                    fullWidth
                    disabled
                    value={
                      isEmpty(foundEmail) ? "" : foundEmail?.value.firstName
                    }
                  />
                  <TextField
                    style={{ marginTop: "10px" }}
                    label="APELLIDO"
                    placeholder="APELLIDO"
                    fullWidth
                    disabled
                    value={
                      isEmpty(foundEmail) ? "" : foundEmail?.value.lastName
                    }
                  />
                  <TextField
                    style={{ marginTop: "10px" }}
                    label="ROLE"
                    placeholder="ROLE"
                    fullWidth
                    disabled
                    value={isEmpty(foundEmail) ? "" : foundEmail?.value.role}
                  />
                </div>
              )}
          </div>
          {findEmailAndPilot ? (
            <div> Buscando ... </div>
          ) : (
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <div style={{ textAlign: "center" }}> DATOS A ACTUALIZAR </div>
              <h6
                style={{ textAlign: "center", color: "#D6D6D6" }}
              >{`SI ACTUALIZARA ( LLENAR TODOS LOS DATOS )`}</h6>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  let props = {
                    ...values,
                    role: users,
                    uid: isEmpty(foundEmail) ? "" : foundEmail.email.data.uid,
                    branch: sendBranch,
                    company,
                  };
                  updateController(props);
                  resetForm({ values: "" });
                }}
                validationSchema={schema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  errors,
                  values,
                  touched,
                }) => (
                  <form>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="Primer nombre"
                        name="firstName"
                        autoComplete="firstName"
                        autoFocus
                        value={values.firstName}
                        error={errors.firstName && touched.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={enabled}
                        helperText={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        // defaultValue={foundEmail !== null || foundEmail !== 'Error when you try getting emails' ? foundEmail.value.lastName : ""}
                        label="Primer Apellido"
                        name="lastName"
                        autoComplete="lastName"
                        disabled={enabled}
                        autoFocus
                        value={values.lastName}
                        error={errors.lastName && touched.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.lastName && touched.lastName && errors.lastName
                        }
                      />
                      <Box
                        sx={{ minWidth: 120 }}
                        style={{ paddingTop: "20px" }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>ROL DE USUARIO</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={users}
                            label="USUARIOS"
                            onChange={handleChangeUser}
                            disabled={enabled}
                            // defaultValue={foundEmail !== null || foundEmail !== 'Error when you try getting emails' ? foundEmail.value.role : ""}
                          >
                            <MenuItem value={"CONTROLLER"}>
                              CONTROLADOR
                            </MenuItem>
                            <MenuItem value={"ADMINISTRATOR"}>
                              ADMINISTRADOR
                            </MenuItem>
                            <MenuItem value={"USER"}>USUARIO</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {users === ROLES.ADMINISTRATOR && (
                        <Box
                          sx={{ minWidth: 120 }}
                          style={{ paddingTop: "20px" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>EMPRESA</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={company}
                              label="EMPRESA"
                              onChange={handleChangeCompany}
                              disabled={enabled}
                            >
                              {companies.map((el) => (
                                <MenuItem value={el}>{el.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {users === ROLES.DEMO && (
                        <Box
                          sx={{ minWidth: 120 }}
                          style={{ paddingTop: "20px" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>EMPRESA</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={company}
                              label="EMPRESA"
                              onChange={handleChangeCompany}
                              disabled={enabled}
                            >
                              {companies.map((el) => (
                                <MenuItem value={el}>{el.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {users === ROLES.USER && (
                        <div>
                          <Box
                            sx={{ minWidth: 120 }}
                            style={{ paddingTop: "20px" }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>EMPRESA</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                value={company}
                                label="EMPRESA"
                                onChange={handleChangeCompany}
                                disabled={enabled}
                              >
                                {companies.map((el) => (
                                  <MenuItem value={el}>{el.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                            <Box
                              sx={{ minWidth: 120 }}
                              style={{ paddingTop: "20px" }}
                            >
                              <FormControl fullWidth>
                                <InputLabel>SUCURSAL</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  fullWidth
                                  value={sendBranch}
                                  label="SUCURSAL"
                                  onChange={handleChangeBranch}
                                  disabled={enabled}
                                >
                                  {getBranch.map((branch) => (
                                    <MenuItem value={branch}>
                                      {branch._branch}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                        </div>
                      )}
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Actualizar Cuenta
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default connect(
  (state) => ({
    // Update Data
    startUpdateUser: selectors.updateInformationController(state),
    completedUpdateUser: selectors.updateInfo(state),
    failedUpdateUser: selectors.updateInfoFailed(state),
    // Getting Data
    findEmailAndPilot: selectors.findControllerAndUserEmail(state),
    foundEmail: selectors.foundControllerAndUserEmail(state),
    failedEmail: selectors.failedControllerAndUserEmail(state),
  }),
  (dispatch) => ({
    updateController(values) {
      dispatch(actions.updateInfoController(values));
    },

    findEmail(email) {
      dispatch(actions.findEmail(email));
    },
  })
)(CreateUsers);
