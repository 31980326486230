import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { isEmpty } from "lodash"

const useStyles = makeStyles((theme) => ({
  titleAllValues: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 800,
    display: "table-cell",
    verticalAlign: "middle",
    letterSpacing: "1px",
  },
  textInformation: {
    fontFamily: "Oswald, sans-serif",
    letterSpacing: "1px",
    paddingLeft: "5px",
  },
}));

export default function DetailInformation(props) {
  const classes = useStyles();
  let value = props.data.data;
  return (
    <div className="task-info">
      <div className="info">
        <div>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              NOMBRE PILOTO:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.name !== undefined ? value?.name : "NO TIENE DPI"}
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              DPI PILOTO:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.dpi !== undefined ? value?.dpi : "NO TIENE DPI"}
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              TELEFONO:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.phone !== undefined ? value?.phone : "NO TIENE TELEFONO"}
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              EMPRESA ASIGNADA:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.company !== undefined ? value?.company : "NO TIENE EMPRESA ASIGNADA"}
            </Typography>
          </Grid>
        </div>
      </div>
      <div className="secondsinformation">
        <div className="taskProgress">
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              ESTATUS PILOTO:
            </Typography>
            <Typography className={classes.textInformation}>
              ACTIVO
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              SUCURSAL ASIGNADA:
            </Typography>
            <Typography className={classes.textInformation}>
              {!isEmpty(value.SUCURSAL) ? value?.SUCURSAL.toUpperCase() : "NO TIENE SUCURSAL"}
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              PLACAS DE PILOTO:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.transportId !== undefined ? value?.transportId : "NO TIENE PLACAS"}
            </Typography>
          </Grid>
          <Grid
            style={{ paddingBottom: "8px" }}
            container
            justifyContent="flex-start"
          >
            <Typography
              inline="true"
              variant="body1"
              className={classes.titleAllValues}
            >
              LUGAR DE TRABAJO:
            </Typography>
            <Typography className={classes.textInformation}>
              {value?.transportId !== undefined ? value?.Pertenece : "NO ESTA TRABAJANDO"}
            </Typography>
          </Grid>
        </div>
      </div>
      <div className="progress">
        <span id="taskProgress">PILOTO</span>
        {value?.pic !== undefined ? (
          <img
            src={value?.pic}
            style={{ width: "100px", height: "auto", alignSelf: "center",paddingTop:'5px' }}
            alt="PILOTO"
          ></img>
        ) : (
          "NO TIENE IMAGEN"
        )}
      </div>
    </div>
  );
}
