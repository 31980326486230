/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import DataGrid, {
  HeaderFilter,
  SearchPanel,
  Export,
  Paging,
  Pager,
  FilterPanel,
  StateStoring,
  FilterBuilderPopup,
  Column,
  MasterDetail,
  Summary,
  TotalItem,
  Scrolling,
} from "devextreme-react/data-grid";
import { useSelector } from "react-redux";
import { Template } from "devextreme-react/core/template";
import { Button } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { ROUTES, BIKER_STATUS, snackBarConst } from "../../constants/constants";
import bikerService from "../../logic/services/bikerService";
import {
  getCurrentAuthStateCompleted,
  getCurrentAuthStateError,
  getCurrentAuthStateLoading,
} from "../../logic/selectors/bikers";
import { useSnackbar } from "notistack";
import DetailInformation from "./BikerDetailInformation";
import moment from "moment";

export default function BikerActiveTable(props) {
  const allowedPageSizes = [20, 30, 40];
  const history = useHistory();
  const [bikersToDeactivate, setBikersToDeactivate] = useState([]);
  const [changeBikerStatusError, setChangeBikerStatusError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [deactivatedBikers, setDeactivatedBikers] = useState([]);
  const [bikersToActivate, setBikersToActivate] = useState([]);
  const isAuthStateCompleted = useSelector(getCurrentAuthStateCompleted);
  const isAuthStateError = useSelector(getCurrentAuthStateError);
  const isAuthStateLoading = useSelector(getCurrentAuthStateLoading);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isAuthStateLoading) {
      if (isAuthStateCompleted) {
        enqueueSnackbar("El piloto ha sido autorizado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        // history.push(ROUTES.BIKERS);
      } else if (isAuthStateError) {
        enqueueSnackbar(
          "Error al autorizar al piloto, por favor intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [
    isAuthStateLoading,
    isAuthStateCompleted,
    isAuthStateError,
    enqueueSnackbar,
  ]);

  const fetchInactiveBikers = async () => {
    try {
      const response = await bikerService.getBikersByStatus(
        BIKER_STATUS.INACTIVE
      );
      setDeactivatedBikers(response);
    } catch (e) {
      console.log("ERROR FETCHING UNACTIVE PILOTS");
    }
  };

  const redirectTo = (data) => {
    history.push(`${ROUTES.BIKER_MODIFY}/${data.id}`);
  };

  const redirectToView = (data) => {
    history.push(`${ROUTES.BIKER}/${data.id}`);
  };

  const onChangeStatus = async (bikers, status) => {
    setChangeBikerStatusError("");
    try {
      const promises = await bikers.map(async (biker) => {
        return bikerService.changeBikerStatus(
          status === BIKER_STATUS.INACTIVE ? biker.mongoId : biker.id,
          status === BIKER_STATUS.INACTIVE ? biker.id : biker.uid,
          status
        );
      });
      await Promise.all(promises);
      await fetchInactiveBikers();
    } catch (e) {
      window.scrollTo(0, 0);
      if (!isEmpty(e.response.data.message)) {
        setChangeBikerStatusError(e.response.data.message);
      } else {
        setChangeBikerStatusError(
          "Ha ocurrido un error cambiando el estado del piloto!"
        );
      }
    }
  };

  const cellRenderBiker = ({ data }) => {
    return (
      <div className="biker-link">
        <div onClick={() => redirectTo(data)}>MODIFICAR</div>
      </div>
    );
  };

  const cellViewBiker = ({ data }) => {
    return (
      <div className="biker-link">
        <div onClick={() => redirectToView(data)}>VER</div>
      </div>
    );
  };

  const onSelectedRowKeys = (e) => {};

  const renderGridCell = ({ data }) => {
    const information = {
      dpi: data.dpi,
      name: data.name,
      phone: data.phone,
      pic: data.pic,
      placa: data.transportId,
    };

    if (information.dpi === undefined || information.dpi === 0) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          NO COMPLETADA
        </div>
      );
    }

    if (information.name === undefined) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          NO COMPLETADA
        </div>
      );
    }

    if (information.phone === undefined) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          NO COMPLETADA
        </div>
      );
    }

    if (information.phone === undefined) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          NO COMPLETADA
        </div>
      );
    }

    if (information.placa === undefined) {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          NO COMPLETADA
        </div>
      );
    }

    if (
      information.pic !== undefined &&
      information.phone !== undefined &&
      information.name !== undefined &&
      information.dpi !== undefined &&
      information.placa !== undefined
    ) {
      return (
        <div
          style={{
            color: "green",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {" "}
          COMPLETADA{" "}
        </div>
      );
    }
  };

  const onSelectionChanged = async (data) => {
    const selectRows = data.selectedRowsData;
    if (props.biker === "bikerIsActive") {
      await setBikersToDeactivate(selectRows);
    } else {
      await setBikersToActivate(selectRows);
    }
  };

  const EmployeeCell = (values) => {
    const imagePic = values.data.pic;
    return (
      <div>
        {isEmpty(imagePic) ? (
          <div>NO TIENE IMAGEN</div>
        ) : (
          <img className="imgPicTable" src={imagePic} alt="employee"></img>
        )}
      </div>
    );
  };

  const calculateDate = (data) => {
    if (data.data.lastOrder) {
      let dateInit = new Date().getTime();
      let dataGetting = new Date(
        data.data.lastOrder.date.seconds * 1000
      ).getTime();
      let dateLess = dateInit - 1296000000;

      if (dateLess <= dataGetting) {
        return (
          <div style={{ color: "green", fontWeight: 500, fontSize: "14px" }}>
            ACTIVO
          </div>
        );
      } else {
        return (
          <div style={{ color: "red", fontWeight: 500, fontSize: "14px" }}>
            INACTIVO
          </div>
        );
      }
    } else {
      return (
        <div style={{ color: "red", fontWeight: 500, fontSize: "14px" }}>
          INACTIVO SIN ORDENES RECIENTE
        </div>
      );
    }
  };

  const calculateDateForFiltering = (data) => {
    if (data.lastOrder) {
      let dateInit = new Date().getTime();
      let dataGetting = new Date(data.lastOrder.date.seconds * 1000).getTime();
      let dateLess = dateInit - 1296000000;

      if (dateLess <= dataGetting) {
        return "ACTIVO";
      } else {
        return "INACTIVO";
      }
    } else {
      return "INACTIVO SIN ORDENES RECIENTE";
    }
  };

  const cellRenderStatus = ({ data }) => {
    if (data.company === "POLLO BRUJO") {
      return data.SUCURSAL.toUpperCase();
    } else {
      return "";
    }
  };

  const cellRenderName = (data) => {
    return data.data.name.toUpperCase();
  };

  const cellRenderNameFiltering = (data) => {
    return data.name.toUpperCase();
  }

  return (
    <Fragment>
      {!isEmpty(changeBikerStatusError) && (
        <>
          <Alert severity="error">{changeBikerStatusError}</Alert>
          <br />
        </>
      )}
      <div style={{ paddingLeft: "20px", paddingTop: "15px" }}>
        {/* {props.biker === "bikerIsActive" && (
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="secondary"
            disabled={isEmpty(bikersToDeactivate)}
            onClick={async () => {
              await onChangeStatus(bikersToDeactivate, BIKER_STATUS.INACTIVE);
              setBikersToDeactivate([]);
            }}
          >
            Desactivar Pilotos
          </Button>
        )} */}
        {props.biker === "bikerIsNotActive" && (
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="secondary"
            disabled={isEmpty(bikersToActivate)}
            onClick={async () => {
              await onChangeStatus(bikersToActivate, BIKER_STATUS.ACTIVE);
              setBikersToActivate([]);
            }}
          >
            Activar Pilotos
          </Button>
        )}
      </div>
      <div
        style={{
          padding: "20px",
        }}
      >
        <DataGrid
          id="DataGridActive"
          keyExpr="id"
          dataSource={props.rows}
          showBorders={true}
          width={"100%"}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          repaintChangesOnly={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          // selectedRowKeys={onSelectedRowKeys}
          onSelectionChanged={onSelectionChanged}
        >
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <FilterPanel
            visible={true}
            texts={{
              createFilter: "CREAR FILTRO",
              clearFilter: "ELIMINAR FILTRO",
              filterEnabledHint: "CREACIÓN DE FILTROS",
            }}
          />
          <FilterBuilderPopup title="FILTROS" />
          <Paging enabled={true} defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            displayMode={true}
            allowedPageSizes={allowedPageSizes}
            showInfo={true}
            showNavigationButtons={true}
          />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="bikerIsActive"
          />
          <HeaderFilter visible={true} />
          {/* <Selection mode="multiple" cssClass="cell-highlighted" /> */}
          {/* {props.biker === "bikerIsActive" && (
            <Column caption="ID" dataField="id"/>
          )} */}
          {props.biker === "bikerIsActive" && (
            <Column
              caption="NOMBRE"
              dataField="name"
              cellRender={(cellInfo) => cellRenderName(cellInfo)}
              calculateCellValue={(data) => cellRenderNameFiltering(data)}
            />
          )}
          {/* {props.biker === "bikerIsActive" && (
            <Column caption="EMPRESA" dataField="company" />
          )} */}
          {props.biker === "bikerIsActive" && (
            <Column
              caption="EMPRESA"
              dataField="Pertenece"
              alignment="center"
            />
          )}
          {props.biker === "bikerIsActive" && (
            <Column
              caption="SUCURSAL"
              dataField=""
              alignment="center"
              cellRender={cellRenderStatus}
            />
          )}
          {props.biker === "bikerIsActive" && (
            <MasterDetail
              cssClass="cell-highlighted"
              enabled={true}
              component={DetailInformation}
            />
          )}
          {props.biker === "bikerIsActive" && (
            <Column
              dataField="id"
              caption="INFORMACIÓN"
              alignment="center"
              width={"auto"}
              allowEditing={false}
              cellRender={renderGridCell}
            />
          )}
          {props.biker === "bikerIsActive" && (
            <Column
              dataField="id"
              caption=""
              cellRender={cellRenderBiker}
              allowEditing={false}
              alignment="center"
            />
          )}
          {props.biker === "bikerIsActive" && (
            <Column
              dataField="id"
              caption=""
              cellRender={cellViewBiker}
              allowEditing={false}
              alignment="center"
            />
          )}
          {props.biker === "bikerIsActive" && (
            <Column
              dataField="pic"
              caption="FOTO"
              alignment="center"
              allowSorting={true}
              minWidth={200}
              cellTemplate="employeeTemplate"
            />
          )}

          {props.biker === "bikerIsNotActive" && (
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="bikerIsNotActive"
            />
          )}

          {props.biker === "bikerIsNotActive" && (
            <Column caption="NOMBRE" dataField="firstName" />
          )}
          {props.biker === "bikerIsNotActive" && (
            <Column caption="APELLIDO" dataField="lastName" />
          )}

          <Column caption="CORREO" dataField="email" />

          <Column
            caption="REGISTRO DE ULTIMO USO"
            dataField="lastOrder"
            cellRender={(cellInfo) => calculateDate(cellInfo)}
            calculateCellValue={(data) => calculateDateForFiltering(data)}
            width={"auto"}
            alignment="center"
            allowEditing={false}
          />
          <Scrolling
            columnRenderingMode="virtual"
            showScrollbar="onHover"
            scrollByContent
          />
          <Template name="employeeTemplate" render={EmployeeCell} />
          <Export enabled={true} allowExportSelectedData={true} />

          <Summary recalculateWhileEditing={true}>
            <TotalItem
              column="name"
              displayFormat="PILOTOS: {0}"
              summaryType="count"
            />
          </Summary>
        </DataGrid>
      </div>
    </Fragment>
  );
}
