import { combineReducers } from "redux";
import union from "lodash/union";
import * as types from "../types/branches";

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.BRANCH_OFFICES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;

      const newState = { ...state };
      order.forEach((id) => {
        newState[id] = {
          ...entities[id],
        };
      });
      return newState;
    }
    case types.BRANCH_OFFICES_CLEAR:
    case types.BRANCH_OFFICES_FETCH_STARTED: {
      return {};
    }

    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case types.BRANCH_OFFICES_FETCH_COMPLETED: {
      return union(action.payload.order);
    }

    default: {
      return state;
    }
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case types.BRANCH_OFFICE_SELECTED: {
      return action.payload;
    }

    case types.BRANCH_OFFICE_DESELECTED: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.BRANCH_OFFICES_FETCH_STARTED: {
      return true;
    }

    case types.BRANCH_OFFICES_FETCH_COMPLETED: {
      return false;
    }

    case types.BRANCH_OFFICES_FETCH_FAILED: {
      return false;
    }

    case types.BRANCH_OFFICE_SELECTED: {
      return false;
    }

    case types.BRANCH_OFFICE_DESELECTED: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case types.BRANCH_OFFICES_FETCH_STARTED: {
      return null;
    }

    case types.BRANCH_OFFICES_FETCH_COMPLETED: {
      return null;
    }

    case types.BRANCH_OFFICES_FETCH_FAILED: {
      return action.payload;
    }

    case types.BRANCH_OFFICE_SELECTED: {
      return null;
    }

    case types.BRANCH_OFFICE_DESELECTED: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const fetchBranchesByCompanyIdInitialState = {
  companies: [],
  isLoading: false,
  error: null,
};

const fetchBranchesByCompanyId = (
  state = fetchBranchesByCompanyIdInitialState,
  action
) => {
  switch (action.type) {
    case types.BRANCHES_FETCH_STARTED:
      return {
        ...state,
        ...fetchBranchesByCompanyIdInitialState,
        isLoading: true,
      };
    case types.BRANCHES_FETCH_COMPLETED:
      return {
        ...state,
        branches: action.payload.branches,
        isLoading: false,
      };
    case types.BRANCHES_FETCH_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    case types.CLEAR_BRANCHES:
      return {
        ...state,
        ...fetchBranchesByCompanyIdInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};

const createBranchInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const createBranch = (state = createBranchInitialState, action) => {
  switch (action.type) {
    case types.BRANCH_CREATE_STARTED:
      return {
        ...state,
        ...createBranchInitialState,
        isLoading: true,
      };
    case types.BRANCH_CREATE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BRANCH_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.CLEAR_BRANCH_STATUSES:
      return {
        ...state,
        ...createBranchInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};

const deleteBranchInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const deleteBranch = (state = deleteBranchInitialState, action) => {
  switch (action.type) {
    case types.BRANCH_DELETE_STARTED:
      return {
        ...state,
        ...deleteBranchInitialState,
        isLoading: true,
      };
    case types.BRANCH_DELETE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case types.BRANCH_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.CLEAR_BRANCH_STATUSES:
      return {
        ...state,
        ...deleteBranchInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};

const branches = combineReducers({
  deleteBranch,
  createBranch,
  byId,
  order,
  selected,
  isFetching,
  error,
  fetchBranchesByCompanyId,
});

export default branches;

//  SELECTORS
export const getBranchOffice = (state, id) => state.byId[id];
export const getBranchOffices = (state) =>
  state.order.map((id) => getBranchOffice(state, id));
export const getSelectedBranchOffice = (state) => state.selected;
export const getIsFetchingBranchOffices = (state) => state.isFetching;
export const getBranchOfficesError = (state) => state.error;
