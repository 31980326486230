import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import CircularProcess from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { pilotActions } from "../../logic/actions/pilots";
import { orderActions } from "../../logic/actions/orders";
import {
  getSubscribeBiker,
  getIsBikerSubscribeLoading,
  getSubscribeBikerError,
} from "../../logic/selectors/bikers";
import {
  getOrdersByBikerId,
  getOrdersWithTime,
} from "../../logic/selectors/orders";
import {
  BIKER_ICON,
  DELIVERY_ICON,
  BIKER_DEFAULT,
  orderStatus,
} from "../../constants/constants";
import Map from "../Map/Map";
import Expandable from "../Design/Expandable";
import DataTable from "../Design/DataTable";
import theme from "../../assets/theme";
import { Button } from "@material-ui/core";
import dateHelper from "../../helpers/dateHelper";

const ABC = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const TYPES = {
  BIKER_LOCATION: "BIKER_LOCATION",
  PLACE_LOCATION: "PLACE_LOCATION",
  BIKER_AND_ORDER_LOCATION: "BIKER_AND_ORDER_LOCATION",
  BIKER_AND_ORDER_AND_RASTAURANT_LOCATION:
    "BIKER_AND_ORDER_AND_RASTAURANT_LOCATION",
};

const Biker = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const bikerError = useSelector(getSubscribeBikerError);
  const biker = useSelector(getSubscribeBiker);
  const isLBikeroading = useSelector(getIsBikerSubscribeLoading);
  const pilotOrders = useSelector(getOrdersByBikerId);
  const ordersWithTime = useSelector(getOrdersWithTime);
  const [isInitialActionDispatched, setIsInitialActionDispatched] = useState(
    false
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalDeliveryTime, setTotalDeliveryTime] = useState("00:00:00");
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [center, setCenter] = useState();
  const [preserveViewport, setPreserveViewport] = useState(false);

  useEffect(() => {
    if (!isEmpty(biker) && isInitialActionDispatched) {
      // if (isCalculatedRouteOptime == false) {
      //   if (!isEmpty(pilotOrders)) {
      //     setIsCalculatedRouteOptime(true);
      //   }
      dispatch(orderActions.getOrdersWithTimeByBikerId(biker, pilotOrders));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pilotOrders, biker]);

  useEffect(() => {
    if (!isEmpty(markers)) {
      let markersToSet = [...markers];
      if (isEmpty(ordersWithTime)) {
        markersToSet = [markersToSet[0]];
      } else {
        const newOrderMarkers = markers
          .slice(1, markers.length)
          .map((marker) => {
            let position = marker.position;
            const orderWithTime = ordersWithTime.find(
              (order) => order.id === marker.id
            );
            if (!isEmpty(orderWithTime)) {
              position = {
                latitude: orderWithTime.locationRef.latitude,
                longitude: orderWithTime.locationRef.longitude,
              };
            }
            return {
              ...marker,
              position,
            };
          });
        markersToSet = [markersToSet[0], ...newOrderMarkers];
      }
      setMarkers(markersToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersWithTime]);

  useEffect(() => {
    if (!isLBikeroading && (!isEmpty(bikerError) || !isEmpty(biker))) {
      if (!isEmpty(biker)) {
        setMarkers((val) => {
          if (isEmpty(val)) {
            return [
              {
                id: biker.id,
                type: TYPES.BIKER_LOCATION,
                icon: BIKER_ICON,
                position: {
                  latitude: (biker.actualPosition || '19.20').latitude,
                  longitude: (biker.actualPosition || '-95.8').longitude,
                },
              },
            ];
          } else {
            val[0] = {
              ...val[0],
              position: {
                latitude: biker.actualPosition.latitude,
                longitude: biker.actualPosition.longitude,
              },
            };
            return val;
          }
        });
        if (isEmpty(center)) {
          setCenter({
            lat: (biker.actualPosition || '19.20').latitude,
            lng: (biker.actualPosition || '-95.8').longitude,
          });
        }
      }
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikerError, biker, isLBikeroading]);

  useEffect(() => {
    setMarkers([]);
    dispatch(pilotActions.clearSubscribeByPilotId(params.bikerId));
    dispatch(orderActions.clearOrdersByBikerId());
    dispatch(pilotActions.subscribePilotByPilotId(params.bikerId));
    dispatch(orderActions.subscribeOrdersByBikerId(params.bikerId));
    setIsInitialActionDispatched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newTime = 0;
    if (!isEmpty(ordersWithTime)) {
      newTime = ordersWithTime[ordersWithTime.length - 1].time;
    }
    setTotalDeliveryTime(newTime);
  }, [ordersWithTime]);

  const renderMap = () => {
    return (
      <Map
        markers={markers}
        center={center}
        zoom={zoom}
        setZoom={setZoom}
        preserveViewport={preserveViewport}
        setPreserveViewport={setPreserveViewport}
        shouldCreateRoute
      />
    );
  };

  const getArriveAt = (order) => {
    return !isEmpty(order.date) && !isEmpty(order.time)
      ? moment(
          new Date(
            new Date(order.date).getTime() +
              dateHelper.hmsToSeconds(order.time) * 1000
          )
        ).format("DD-MM-YYYY HH:mm:ss")
      : "";
  };

  const renderInRoute = () => {
    return (
      <DataTable
        headers={[
          {
            id: "point",
            name: "",
          },
          {
            id: "orderId",
            name: "Orden",
          },
          {
            id: "name",
            name: "cliente",
          },
          {
            id: "address",
            name: "Dirección",
          },
          {
            id: "arriveAt",
            name: "Tiempo",
          },
          {
            id: "status",
            name: "Estado",
          },
          {
            id: "actions",
            name: "",
          },
        ]}
        rows={(ordersWithTime || []).map((order, i) => {
          return {
            ...order,
            arriveAt: getArriveAt(order),
            point: ABC[i + 1],
            actions: (
              <>
                {!isEmpty(order.locationRef) &&
                !isEmpty(biker.actualPosition) ? (
                  <Button
                    size="small"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      const isGoing = order.status === orderStatus.GOING;
                      setPreserveViewport(false);
                      setZoom(14);
                      setMarkers([
                        {
                          id: biker.id,
                          type: TYPES.BIKER_LOCATION,
                          icon: BIKER_ICON,
                          position: {
                            latitude: biker.actualPosition.latitude,
                            longitude: biker.actualPosition.longitude,
                          },
                        },
                        {
                          id: order.id,
                          type: TYPES.PLACE_LOCATION,
                          icon: isGoing ? DELIVERY_ICON : undefined,
                          position: {
                            latitude: isGoing
                              ? order.locationRef.latitude
                              : order.branch.lat,
                            longitude: isGoing
                              ? order.locationRef.longitude
                              : order.branch.lng,
                          },
                        },
                      ]);
                    }}
                  >
                    Rastrear
                  </Button>
                ) : (
                  <span>No se encontraron coordenadas</span>
                )}
              </>
            ),
          };
        })}
      />
    );
  };

  const onMarkerClick = (marker, i) => {
    setMarkers((markers) => {
      const newMarkers = [...markers];
      newMarkers[i + 1] = { ...marker, showInfoWindow: !marker.showInfoWindow };
      return newMarkers;
    });
  };

  const renderView = () => {
    if (isLBikeroading || isEmpty(biker)) return <></>;
    if (!isEmpty(bikerError)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }
    return (
      <div>
        <div className="col-6">
          <div>
            <div className="col-4 text-center two-wheeler-pic">
              <img src={biker.pic ? biker.pic : BIKER_DEFAULT} alt="2Wheeler" />
            </div>
            <div className="col-8">
              <div className="margin-bottom-sm pilot-data-display">
                <div className="col-5">
                  <strong>Nombre del Piloto</strong>
                </div>
                <div className="col-7">{biker.name}</div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="col-5">
                  <strong>Teléfono del Piloto</strong>
                </div>
                <div className="col-7">
                  {isNaN(biker.phone) ? "N/A" : biker.phone}
                </div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="margin-bottom-sm">
                  <div className="col-5">
                    <strong>Estado del Piloto</strong>
                  </div>
                  <div className="col-7">
                    {biker.status === "available"
                      ? "Disponible"
                      : "No disponible"}
                  </div>
                </div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="margin-bottom-sm">
                  <div className="col-5">
                    <strong>Tipo</strong>
                  </div>
                  <div className="col-7">
                    {biker.type === "WILDCARD" ? "COMODIN" : biker.type}
                  </div>
                </div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="margin-bottom-sm">
                  <div className="col-5">
                    <strong>Compañía</strong>
                  </div>
                  <div className="col-7">{biker.company}</div>
                </div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="margin-bottom-sm">
                  <div className="col-5">
                    <strong>Sucursal</strong>
                  </div>
                  <div className="col-7">{biker.SUCURSAL}</div>
                </div>
              </div>
              <div className="margin-bottom-sm pilot-data-display">
                <div className="margin-bottom-sm">
                  <div className="col-5">
                    <strong>Lugar de trabajo</strong>
                  </div>
                  <div className="col-7">{biker.Pertenece}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="margin-right-md">
            <Button
              size="small"
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setPreserveViewport(false);
                if (!isEmpty(ordersWithTime)) {
                  setZoom(14);
                  setMarkers([
                    {
                      id: biker.id,
                      type: TYPES.BIKER_LOCATION,
                      icon: BIKER_ICON,
                      position: {
                        latitude: biker.actualPosition.latitude,
                        longitude: biker.actualPosition.longitude,
                      },
                    },
                    ...ordersWithTime.map((order, i) => ({
                      id: order.id,
                      type: TYPES.BIKER_AND_ORDER_LOCATION,
                      icon: DELIVERY_ICON,
                      position: {
                        latitude: order.locationRef.latitude,
                        longitude: order.locationRef.longitude,
                      },
                      showInfoWindow: true,
                      infoWindow: ABC[i + 1],
                      onClick: (mark) => onMarkerClick(mark, i),
                    })),
                  ]);
                }
              }}
            >
              ruta optima
            </Button>
            <div>Tiempo total de entrega: {totalDeliveryTime}</div>
            <Expandable title="Ordenes en Ruta" defaultExpanded>
              {renderInRoute()}
            </Expandable>
          </div>
        </div>
        <div className="col-6 vertical-top overflow-auto">{renderMap()}</div>
      </div>
    );
  };

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      {/* <NavBar title={`${!isEmpty(biker) ? biker.name : "PILOTO"}`} /> */}
      <div className="container">
        {isLoaded ? renderView() : renderLoading()}
      </div>
    </ThemeProvider>
  );
};

export default Biker;
