import { INPUT_TYPE } from "./constants";

export const emptyBranch = {
  name: "",
  description: "",
  address: "",
  country: {},
  region: {},
  city: {},
};

export const defaultFormFields = [
  {
    label: "Nombre",
    name: "name",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Descripción",
    name: "description",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "Dirección",
    name: "address",
    cssClasses: "col-12",
    fullWidth: true,
  },
  {
    label: "País",
    name: "country",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    isLoading: true,
  },
  {
    label: "Región",
    name: "region",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
  {
    label: "Ciudad",
    name: "city",
    cssClasses: "col-4",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
  },
];
