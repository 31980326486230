/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import CircularProcess from "@material-ui/core/CircularProgress";
import isEmpty from "lodash/isEmpty";
import Map from "../Map/Map";
import {
  orderStatus,
  BIKER_ICON,
  DELIVERY_ICON,
  BRANCH_ICON,
} from "../../constants/constants";

import NavBar from "../Share";

const OrderMap = ({ onModalClose, order, branch }) => {
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(12);
  const [isLoading, setIsLoading] = useState(true);
  const [center, setCenter] = useState();
  const [preserveViewport, setPreserveViewport] = useState(false);

  useEffect(() => {
    setCenter({
      lat: order.alias.actualPosition.latitude,
      lng: order.alias.actualPosition.longitude,
    });
    let currentMarkers = [
      {
        id: order.alias.id,
        icon: BIKER_ICON,
        position: {
          latitude: order.alias.actualPosition.latitude,
          longitude: order.alias.actualPosition.longitude,
        },
      },
    ];
    if (
      order.status === orderStatus.ACCEPTED &&
      branch?.position !== undefined
    ) {
      currentMarkers = [
        ...currentMarkers,
        {
          id: branch.id,
          icon: BRANCH_ICON,
          position: {
            latitude: branch.lat,
            longitude: branch.lng,
          },
        },
      ];
    } else {
      currentMarkers = [
        ...currentMarkers,
        {
          id: order.id,
          icon: DELIVERY_ICON,
          position: {
            latitude: order.locationRef.latitude,
            longitude: order.locationRef.longitude,
          },
        },
      ];
    }
    setMarkers(currentMarkers);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  // console.log({
  //   markers,
  //   center,
  //   zoom,
  //   setZoom,
  //   preserveViewport,
  //   setPreserveViewport,
  // });

  return (
    <div className="modal-container modal-container-height">
      <div className="modal-title">
        <div className="modal-title-text">
          RASTREA LA ORDEN
          {!isEmpty(order.orderIdText) && `#${order.orderIdText}`}
        </div>
        <div className="close" onClick={() => onModalClose()}>
          x
        </div>
      </div>
      <div>
        {isLoading ? (
          <div className="progress-indicator">
            <CircularProcess />
          </div>
        ) : (
          <>
            <Map
              markers={markers}
              center={center}
              zoom={zoom}
              setZoom={setZoom}
              preserveViewport={preserveViewport}
              setPreserveViewport={setPreserveViewport}
              height="400px"
              shouldCreateRoute
            />

            <NavBar />
          </>
        )}
      </div>
    </div>
  );
};

export default OrderMap;
