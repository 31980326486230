import { getController } from "../reducers";
import isEmpty from "lodash/isEmpty";
import {
  COMPANIES_FETCH_STARTED,
  COMPANIES_FETCH_COMPLETED,
  COMPANIES_FETCH_FAILED,
  CLEAR_COMPANIES,
  COMPANY_CREATE_STARTED,
  COMPANY_CREATE_COMPLETED,
  COMPANY_CREATE_FAILED,
  COMPANY_MODIFY_STARTED,
  COMPANY_MODIFY_COMPLETED,
  COMPANY_MODIFY_FAILED,
  COMPANY_DELETE_STARTED,
  COMPANY_DELETE_COMPLETED,
  COMPANY_DELETE_FAILED,
  COMPANY_CLEAR_ALL,
  GET_COMPANIES_LINK_STARTED,
  GET_COMPANIES_LINK_COMPLETED,
  GET_COMPANIES_LINK_FAILED,
  GETTING_COMPANY_STARTED,
  GETTING_COMPANY_COMPLETED,
  GETTING_COMPANY_FAILED
} from "../types/company";

const getCompanies = ({ companyId } = {}) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  dispatch({
    type: COMPANIES_FETCH_STARTED,
    payload: {
      companyId: isEmpty(companyId) ? controller.companyId : companyId,
    },
  });
};

const getCompaniesSuccess = (companies) => ({
  type: COMPANIES_FETCH_COMPLETED,
  payload: {
    companies,
  },
});

const getCompaniesFailure = (error) => ({
  type: COMPANIES_FETCH_FAILED,
  payload: {
    error,
  },
});

const clearCompanies = () => ({
  type: CLEAR_COMPANIES,
});

const createCompany = ({ name, description, image } = {}) => ({
  type: COMPANY_CREATE_STARTED,
  payload: {
    image,
    name,
    description,
  },
});

const createCompanySuccess = () => ({
  type: COMPANY_CREATE_COMPLETED,
});

const createCompanyFailure = (error) => ({
  type: COMPANY_CREATE_FAILED,
  payload: {
    error,
  },
});

const modifyCompany = ({
  id,
  name,
  description,
  image,
  email,
  sms,
  emails,
} = {}) => ({
  type: COMPANY_MODIFY_STARTED,
  payload: {
    id,
    image,
    name,
    description,
    email,
    sms,
    emails,
  },
});

const modifyCompanySuccess = () => ({
  type: COMPANY_MODIFY_COMPLETED,
});

const modifyCompanyFailure = (error) => ({
  type: COMPANY_MODIFY_FAILED,
  payload: {
    error,
  },
});

const deleteCompany = (id) => ({
  type: COMPANY_DELETE_STARTED,
  payload: {
    id,
  },
});

const deleteCompanySuccess = () => ({
  type: COMPANY_DELETE_COMPLETED,
});

const deleteCompanyFailure = (error) => ({
  type: COMPANY_DELETE_FAILED,
  payload: {
    error,
  },
});

const clearAll = () => ({
  type: COMPANY_CLEAR_ALL,
});



export const getCompaniesLinkStarted = ( id ) => ({
  type: GET_COMPANIES_LINK_STARTED,
  payload:{
    id
  }
}) 

export const getCompaniesLinkCompleted = ( companies ) => ({
  type: GET_COMPANIES_LINK_COMPLETED,
  payload: {
    companies
  }
})

export const getCompaniesLinkFailed = error => ({
  type: GET_COMPANIES_LINK_FAILED, 
  payload: {
    error
  }
})

/* SUMMARY COMPANY */
export const getCompaniesSummaryStarted = (id) => ({
  type: GETTING_COMPANY_STARTED,
  payload:{
    id
  }
}) 

export const getCompaniesSummaryCompleted = (companies) => ({
  type: GETTING_COMPANY_COMPLETED,
  payload: {
    companies
  }
})

export const getCompaniesSummaryFailed = error => ({
  type: GETTING_COMPANY_FAILED, 
  payload: {
    error
  }
})

const companyActions = {
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFailure,
  clearCompanies,
  createCompany,
  createCompanySuccess,
  createCompanyFailure,
  clearAll,
  modifyCompany,
  modifyCompanySuccess,
  modifyCompanyFailure,
  deleteCompany,
  deleteCompanySuccess,
  deleteCompanyFailure,
};

export default companyActions;
