import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        secondary: {
            light: '#fdff58',
            main: '#f26724',
            // main: '#20419a',
            dark: '#90cc00',
            constrastText: '#232122',
        },
        primary:{
            light: '#484848',
            main: '#1a161d',
            dark: '#1a161d',
            constrastText: '#ffffff',
        },
        background:{
            // default:'#f4f6f8',
            // default: '#F4F6F7',
            default: '#FBFCFC',
            paper:"#ffffff"
        }
    },
    shape: {
        borderRadius: 8,
    },
    text: {
        primary: 'rgba(35, 33, 34, 1)',
        secondary: 'rgba(255, 255, 255, 1)',
    },
    typography: {
        fontSize: 14,
        fontFamily: "Lato",
        fontWeightBold: 800,
        button: {
            fontFamily: "Lato",
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: '0px',
            
        }
    },
});

export default theme;