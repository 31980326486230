import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
const MonthlyBarChart = ({ data }) => {

  return (
    <ResponsiveContainer>
      <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" fontSize={8} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="entregas" fill="#f26724" />
      </BarChart>
    </ResponsiveContainer>
  );
}
export default MonthlyBarChart;