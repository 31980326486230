import React from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import { PropertiesPanel } from "devextreme-react/diagram";

const labels = {
  0.5: "MUY MAL",
  1: "MUY MAL",
  1.5: "MAL",
  2: "MAL",
  2.5: "BUENO",
  3: "BUENO",
  3.5: "BUENISIMO",
  4: "BUENISIMO+",
  4.5: "EXCELENTE",
  5: "EXCELENTE+",
};

export default function HoverRating(props) {
  const [value, setValue] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [hover, setHover] = React.useState(-1);
  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justify="center"
      direction="row"
    >
      <Rating
        name="hover-feedback"
        value={value}
        precision={0.5}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          // setHover(newHover);
          props.changeRanking(newHover);
        }}
      />
      {value !== null && (
        <Box style={{ color: "#000" }} ml={2}>
          {labels[hover !== -1 ? hover : value]}
        </Box>
      )}
    </Grid>
  );
}
