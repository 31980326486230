import React, { Fragment } from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import "../index.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    position: "absolute",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const ComponenteLink = (props) => {
  // console.log(props)
  const date = new Date();
  const classes = useStyles();
  const newYear = moment(date).format("YYYY");
  let video = !props.videoParams ? "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/videos%2Fpexels-kindel-media-6867876.mp4?alt=media&token=dc736cfb-1c29-43b4-bb30-96c7577edc76" : props.videoParams;
  let subLogo = !props.subLogo ? "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/logotipos%2FDLlogo2.png?alt=media&token=24fc77df-61df-4aaf-9c42-0f533a281aa8" : props.subLogo;
  return (
    <Fragment>
      <div>
      
          <body className="header-video-content">
            <section>
              <div>
                <video
                  className="header-video header-video-opacity"
                  src={video}
                  autoPlay="autoPlay"
                  playsInline={true}
                  muted
                  loop
                />
              </div>
            </section>
            <center>
              <img
                style={{ paddingTop: "5vh" }}
                src={props.imageLogo}
                width="200px"
                height="auto"
                alt="logotipos"
              />
            </center>
            <Grid
              container
              spacing={0}
              align="center"
              justify="center"
              alignContent="center"
              direction="row"
              style={{ minHeight: "60vh" }}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    textAlign: "justify",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    fontFamily: `${
                      props.params?.fontVideo === undefined
                        ? "Lato"
                        : props.params?.fontVideo
                    }`,
                  }}
                >{`${
                  props.params?.companyPhrase === undefined
                    ? "...ESPERE"
                    : props.params?.companyPhrase
                }`}</Typography>
              </Grid>
            </Grid>
            <div className={classes.header}>
              {props.params?.name === "POLLO BRUJO" ? (
                ""
              ) : (
                <img
                  className={classes.img}
                  src={
                  subLogo
                  }
                  width="auto"
                  style={{ margin: "auto", paddingBottom: "5px" }}
                  height="60"
                  alt="subLogo"
                ></img>
              )}
              <br />
              <Typography
                variant="subtitle2"
                style={{ color: "#EAEDED", marginTop: "-10px", opacity: "0.7" }}
              >
                {`Copyright © QubitSystems${newYear} `}
              </Typography>
            </div>
          </body>
   
      </div>
    </Fragment>
  );
};

export default ComponenteLink;
