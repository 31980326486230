import moment from "moment";

const getDateOrUndefined = (date) =>
  date !== null && date !== undefined
    ? moment(date.seconds * 1000).format("YYYY-MM-DD[T]HH:mm")
    : null;

const getTimeOnRoute = (createdAtSeconds) => {
  let date = new Date(createdAtSeconds);
  let now = new Date();

  return Math.round((now - date) / 1000);
};

const getAlert = (timeOnRoute) => {
  if (timeOnRoute < 0) return "Espere...";
  const alerts = [
    { name: "En Tiempo", min: 0, max: 20 },
    { name: "Verificar", min: 20, max: 30 },
    { name: "Por llegar Tarde", min: 30, max: 45 },
    { name: "Tarde", min: 45, max: Number.MAX_SAFE_INTEGER },
  ];
  let _alert = alerts.find(
    (alert) => timeOnRoute >= alert.min && timeOnRoute < alert.max
  );

  if (_alert) {
    return _alert.name;
  } else {
    return "SIN ALERTA";
  }
};

const secondsToHms = (d) => {
  const numb = Number(d);
  if (!isNaN(numb)) {
    const h = Math.floor(numb / 3600);
    const m = Math.floor((numb % 3600) / 60);
    const s = Math.floor((numb % 3600) % 60);

    let hDisplay = h > 0 ? h + ":" : "00:";
    let mDisplay = m > 0 ? m + ":" : "00:";
    let sDisplay = s > 0 ? s : "00";
    if (hDisplay.toString().length === 2) {
      hDisplay = `${0}${hDisplay}`;
    }
    if (mDisplay.toString().length === 2) {
      mDisplay = `${0}${mDisplay}`;
    }
    if (sDisplay.toString().length === 1) {
      sDisplay = `${0}${sDisplay}`;
    }
    return `${hDisplay}${mDisplay}${sDisplay}`;
  }
  return 0;
};

const hmsToSeconds = (string) => {
  const splitedTime = string.split(":");
  return (
    parseInt(splitedTime[0]) * 60 * 60 +
    parseInt(splitedTime[1]) * 60 +
    parseInt(splitedTime[2])
  );
};

const dateHelper = {
  getDateOrUndefined,
  getTimeOnRoute,
  getAlert,
  secondsToHms,
  hmsToSeconds,
};

export default dateHelper;
