import React from "react";
import CircularProcess from "@material-ui/core/CircularProgress";

export default ({ cssClasses, fullWidth, text }) => {
  return (
    <div
      className={`text-field vertical-top text-center ${fullWidth &&
        "full-width"} ${cssClasses}`}
    >
      <div className="font-size-sm">{text}</div>
      <CircularProcess size={25} />
    </div>
  );
};
