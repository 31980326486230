import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import companyActions from "../../logic/actions/company";
import { snackBarConst } from "../../constants/constants";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash"


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © DELIVERY LAB '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const CreateUsers = ({
    findEmail,
    findEmailAndPilot,
    foundEmail,
    failedEmail,
    startUpdateUser,
    completedUpdateUser,
    failedUpdateUser,
    updateController,
    successPass,
    updatePassword
}) => {
    const dispatch = useDispatch();
    const [emailSearch, setEmailSearch] = React.useState("");
    const [enabled, setEnabled] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(companyActions.getCompanies());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const schema = yup.object({
        password: yup.string().min(10).required("Ingrese Contraseña"),
    });


    useEffect(() => {
        if (foundEmail !== "Error when you try getting emails" && foundEmail !== null) {
            setEnabled(false);
        } else if (foundEmail === "Error when you try getting emails") {
            setEnabled(true);
        };

        if (foundEmail !== null && foundEmail !== "Error when you try getting emails") {
            enqueueSnackbar("Usuario Encontrado", {
                variant: "success",
                preventDuplicate: true,
                anchorOrigin: {
                    ...snackBarConst,
                },
            });
        } else if (foundEmail === 'Error when you try getting emails') {
            enqueueSnackbar("Error al encontrar los usuarios", {
                variant: "warning",
                preventDuplicate: true,
                anchorOrigin: {
                    ...snackBarConst,
                },
            });
        };

        if (foundEmail) {
            if (foundEmail.value === undefined) {
                enqueueSnackbar("Error al encontrar los usuarios", {
                    variant: "warning",
                    preventDuplicate: true,
                    anchorOrigin: {
                        ...snackBarConst,
                    },
                });
            };
        }

        if (successPass !== 'Error when you try update the controller' && successPass !== null) {
            enqueueSnackbar("Contraseña Cambiada Correctamente", {
                variant: "success",
                preventDuplicate: true,
                anchorOrigin: {
                    ...snackBarConst,
                },
            });
        } else if (successPass === 'Error when you try update the controller') {
            enqueueSnackbar(
                "Hubo un error al Actualizar la contraseña",
                {
                    variant: "warning",
                    preventDuplicate: true,
                    anchorOrigin: {
                        ...snackBarConst,
                    },
                }
            );
        }

    }, [foundEmail, failedEmail, enqueueSnackbar, setEmailSearch, findEmail, successPass, completedUpdateUser])

    const SearchEmail = (e) => {
        setEmailSearch(e.target.value);
    };

    const onClickSearchEmail = async (e) => {
        await findEmail({ emailSearch, type: "controllers" });
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#F88B1E' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        ACTUALIZAR DATOS
                    </Typography>
                    <Typography variant='subtitle2'>
                        CONTROLADOR O USUARIO
                    </Typography>
                    <div style={{ paddingTop: '20px', width: '100%' }}>
                        <TextField fullWidth label="CORREO" placeholder='BUSCAR CORREO' type='email' onChange={SearchEmail} />
                    </div>
                    <div style={{ width: '100%', paddingTop: '20px' }}>
                        <Button fullWidth variant="contained" color="primary" onClick={onClickSearchEmail}> BUSCAR CORREO </Button>
                    </div>
                    {
                        foundEmail !== "Error when you try update the controller" && foundEmail !== null && foundEmail.value !== undefined && (
                            <div>
                                <div style={{ textAlign: 'center', padding: '20px' }}>DATOS USUARIO</div>
                                <TextField style={{ marginTop: '10px' }} label="NOMBRE ACTUAL" placeholder="NOMBRE ACTUAL" fullWidth disabled value={isEmpty(foundEmail) ? "" : foundEmail?.value.firstName} />
                                <TextField style={{ marginTop: '10px' }} label="EMPRESA" placeholder="EMPRESA" fullWidth disabled value={isEmpty(foundEmail) ? "" : foundEmail?.value.lastName} />
                                <TextField style={{ marginTop: '10px' }} label="TELEFONO" placeholder="TELEFONO" fullWidth disabled value={isEmpty(foundEmail) ? "" : foundEmail?.value.role} />
                            </div>
                        )
                    }
                    {
                        findEmailAndPilot ? (
                            <div> Buscando ... </div>
                        ) : (

                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <div style={{ textAlign: 'center' }}> DATOS A ACTUALIZAR </div>
                                <h6 style={{ textAlign: 'center', color: '#D6D6D6' }}>{`SI ACTUALIZARA ( LLENAR TODOS LOS DATOS )`}</h6>
                                <Formik
                                    initialValues={{
                                        password: ""
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        let props = {
                                            ...values,
                                            uid: isEmpty(foundEmail) ? "" : foundEmail.email.data.uid,
                                        };
                                        updatePassword(props);
                                        resetForm({ values: "" })
                                    }}
                                    validationSchema={schema}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        values,
                                        touched,
                                    }) => (
                                        <form>
                                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="password"
                                                    label="CONTRAÑSE NUEVA"
                                                    name="password"
                                                    autoComplete="password"
                                                    autoFocus
                                                    value={values.password}
                                                    error={errors.password && touched.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={enabled}
                                                    helperText={(errors.password && touched.password) && errors.password}
                                                />
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    Actualizar Cuenta
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        )
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

export default connect(
    (state) => ({
        // Update Pasword
        updateInit: selectors.updatePasswordController(state),
        successPass: selectors.passwordControllerCompleted(state),
        // Getting Data
        findEmailAndPilot: selectors.findControllerAndUserEmail(state),
        foundEmail: selectors.foundControllerAndUserEmail(state),
        failedEmail: selectors.failedControllerAndUserEmail(state)
    }),
    (dispatch) => ({
        findEmail(email) {
            dispatch(actions.findEmail(email));
        },
        updatePassword(password) {
            dispatch(actions.updatePasswordController(password))
        }
    })
)(CreateUsers);