import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import BreadCrumb from "../../containers/navs/breadcrumb";

import DataGrid, {
  Column,
  Grouping,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  Export,
  FilterPanel,
  TotalItem,
  Summary,
  Selection,
  FilterRow,
  HeaderFilter,
  Scrolling
} from "devextreme-react/data-grid";

const SummaryAccountUser = ({ gettingAccounts, init, account, failed }) => {
  useEffect(() => {
    gettingAccounts();
  }, [gettingAccounts]);

  const pageSizes = [10, 25, 50, 100];

  return (
    <>
      <div>
        <BreadCrumb match={{ path: "/" }} title=" USUARIOS" />
      </div>
      <DataGrid
        keyExpr="id"
        dataSource={account}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        noDataText="NO HAY INFORMACIÓN"
      >
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Grouping autoExpandAll={false} />
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="data.firstName"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="accounts"
        />
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

        <Selection mode="single" />
        <Column dataField="data.firstName" caption="Nombre" />
        <Column dataField="data.lastName" caption="Apellido" />
        <Column dataField="data.role" caption="Rol" />
        <Column dataField="data.company.name" caption="Compañias" />
        <Column dataField="data.branch.name" caption="Sucursal" />

        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </>
  );
};

/* return to reducer 
    gettingAccountUser
    getAccountUser
    gettingAccountUserFail 
*/
const {
  gettingAccountUser,
  getAccountUser,
  gettingAccountUserFail,
} = selectors;

export default connect(
  (state) => ({
    init: gettingAccountUser(state),
    account: getAccountUser(state),
    failed: gettingAccountUserFail(state),
  }),
  (dispatch) => ({
    gettingAccounts() {
      dispatch(actions.gettingUserAccount());
    },
  })
)(SummaryAccountUser);
