//  AUTHENTICATE USER
export const AUTHENTICATION_STARTED = 'AUTHENTICATION_STARTED';
export const AUTHENTICATION_COMPLETED = 'AUTHENTICATION_COMPLETED';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

//  LOG OUT USER 
export const LOGOUT = 'LOGOUT';


export const CREATING_USER_STARTED = "CREATING_USER_STARTED";
export const CREATING_USER_COMPLETED = "CREATING_USER_COMPLETED";
export const CREATING_USER_FAILED = "CREATING_USER_FAILED";
