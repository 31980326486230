import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import Alert from "@material-ui/lab/Alert";
import validationHelper from "../../helpers/validationHelper";
import { useSnackbar } from "notistack";
import ImageUploader from "react-images-upload";
import DynamicForm from "../Design/Form/DynamicForm";
import companyActions from "../../logic/actions/company";
import { defaultFormFields } from "../../constants/companyConstants";
import { snackBarConst } from "../../constants/constants";
import {
  isModifyCompanyLoading,
  isModifyDone,
  getModifyError,
} from "../../logic/selectors/company";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
});

export default ({ onModalClose, company }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isModifyCompanyLoading);
  const { enqueueSnackbar } = useSnackbar();
  const isUpdated = useSelector(isModifyDone);
  const error = useSelector(getModifyError);
  const [customErrors, setCustomErrors] = useState([]);

  const [image, setImage] = useState();
  const [data, setData] = useState({
    name: company.name,
    description: company.description,
    sms: company.sms,
    email: company.email,
    emails: !isEmpty(company.emails) ? company.emails.join("\n") : "",
  });

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "modificar",
    },
  ];

  useOnUpdatingCompany(
    isUpdated,
    error,
    isLoading,
    enqueueSnackbar,
    onModalClose,
    dispatch
  );

  const onSubmit = (values) => {
    let emails = [];
    if (!isEmpty(values.emails)) {
      const emailsToValidate = values.emails.split("\n");
      if (!isEmpty(emailsToValidate)) {
        let invalidEmails = [];
        emailsToValidate
          .filter((email) => !isEmpty(email))
          .forEach((email) => {
            if (validationHelper.validateEmail(email)) {
              emails = [...emails, email];
            } else {
              invalidEmails = [...invalidEmails, email];
            }
          });
        if (!isEmpty(invalidEmails)) {
          setCustomErrors([
            `Por favor verifica los siguientes correos: ${invalidEmails.join(
              ", "
            )}`,
          ]);
          return;
        }
      }
    } else {
      if (values.email) {
        setCustomErrors(["Por favor, ingresa por lo menos un correo"]);
        return;
      }
    }
    dispatch(
      companyActions.modifyCompany({
        ...values,
        id: company.id,
        emails,
        image,
      })
    );
  };

  const renderImage = () => {
    return (
      <div>
        {!isEmpty(company.logo) && (
          <div>
            <div className="text-center two-wheeler-pic">
              <img src={company.logo} alt="2Wheeler" />
            </div>
          </div>
        )}
        <div>
          <ImageUploader
            withIcon={true}
            buttonText="Cargar imagen"
            onChange={(pic) => setImage(pic)}
            imgExtension={[".jpg", ".png"]}
            maxFileSize={5242880}
            withPreview
            singleImage
          />
        </div>
      </div>
    );
  };

  return (
    <div className="modal-auto-height">
      <div className="modal-container-auto-height">
        <div className="modal-title">
          <div className="modal-title-text">Modificar {company.name}</div>
        </div>
        <div className="modal-body all-upper-case">
          <BlockUi tag="div" blocking={isLoading}>
            <div className="col-4">{renderImage()}</div>
            <div className="col-8">
              {!isEmpty(customErrors) && (
                <div className="custom-errors">
                  {customErrors.map((customError, i) => (
                    <Alert key={`custom-error-${i}`} severity="error">
                      {customError}
                    </Alert>
                  ))}
                </div>
              )}
              <DynamicForm
                obj={data}
                fields={defaultFormFields}
                validationSchema={validationSchema}
                buttons={btnList}
                onSubmit={onSubmit}
                btnAlignment="right"
                setState={setData}
                btnCssClass="modal-footer"
              />
            </div>
          </BlockUi>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

const useOnUpdatingCompany = (
  isCreated,
  error,
  isLoading,
  enqueueSnackbar,
  onModalClose,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isCreated) {
        enqueueSnackbar("Compañía modificada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(companyActions.clearAll());
        window.location.reload();
        onModalClose();
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al modificar la compañía, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, error, isLoading]);
};
