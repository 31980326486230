/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { ThemeProvider } from "@material-ui/core/styles";
import { orderStatus } from "../../constants/constants";
import Grid from "@material-ui/core/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { ROUTES } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/orders";
import theme from "../../assets/theme";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { Paper, Box } from "@material-ui/core";
import { DELIVERED_ORDERS_COLUMNS } from "../../constants/tableConstants";
import SingleTableView from "../../containers/tables/singleTableView";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ResponsiveOrderList from "../AllOrders/ResponsiveOrderList";
import { cancelledOrdersFilterActions } from "../../logic/actions/filters";
import { getCancelledOrdersFilters } from "../../logic/selectors/filters";
import moment from "moment";
moment.locale("es");

const CanceledOrders = ({
  historyOrders,
  onLoad,
  clearOrderEdition,
  isLoading,
}) => {
  const [days, setDays] = useState("0");
  const history = useHistory();
  const uiTheme = useTheme();
  const [avgTime, setAvgTime] = useState(0);
  const [orders, setOrders] = useState([]);

  const dispatch = useDispatch();
  const savedFilterModel = useSelector(getCancelledOrdersFilters);
  const [filterModel, setFilterModel] = useState([]);
  const [filterModelToUse, setFilterModelToUse] = useState([]);
  const [isFilterConfigured, setIsFilterConfigured] = useState(false);

  const matches = useMediaQuery(uiTheme.breakpoints.down("xs"));
  useEffect(onLoad, []);

  useEffect(() => {
    setOrders(historyOrders);
  }, [historyOrders]);

  const selectedRow = (event) => {
    if (event.row.id !== undefined) {
      clearOrderEdition();
      history.push(
        `${ROUTES.EDIT_ORDER}/${event.row.id}?delivered=true&state=forgotten`
      );
      window.location.reload();
    }
  };

  const onFilterChange = (filterModelItems) => {
    let avg = 0;
    if (!isEmpty(filterModelItems)) {
      let filteredOrders = [...orders];
      filterModelItems.forEach((filterModel) => {
        if (!isEmpty(filterModel.value)) {
          filteredOrders = filteredOrders.filter((order) => {
            if (filterModel.operatorValue === "contains") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .includes((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "startsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .startsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "endsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .endsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "equals") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .localeCompare((filterModel.value || "").toLowerCase());
            }
            return true;
          });
        }
      });
      avg =
        (filteredOrders || []).reduce((acc, current) => acc + current.time, 0) /
        filteredOrders.length;
    } else {
      avg =
        (orders || []).reduce((acc, current) => acc + current.time, 0) /
        orders.length;
    }
    if (!isEmpty(filterModelItems)) {
      if (!isEmpty(filterModel)) {
        setFilterModel(undefined);
      } else {
        setFilterModelToUse(filterModelItems);
      }
    }
    setAvgTime(avg);
  };

  useEffect(() => {
    const avg =
      (orders || []).reduce((acc, current) => acc + current.time, 0) /
      orders.length;
    setAvgTime(avg);
  }, [orders]);

  useEffect(() => {
    if (!isFilterConfigured) {
      if (!isEmpty(savedFilterModel)) {
        setFilterModel(savedFilterModel);
        setFilterModelToUse(savedFilterModel);
        setIsFilterConfigured(true);
      }
    }
  }, [savedFilterModel, isFilterConfigured]);

  useEffect(() => {
    dispatch(
      actions.startFetchingHistoryOrders({
        statusFilter: [orderStatus.CANCELLED],
        days,
      })
    );
  }, [days]);

  return (
    <Fragment>
      <Prompt
        message={(location, action) => {
          dispatch(
            cancelledOrdersFilterActions.onFilterSave(
              filterModelToUse.map((filter) => ({
                columnField: filter.columnField,
                operatorValue: filter.operatorValue,
                value: filter.value,
              }))
            )
          );
          return true;
        }}
      />
      <ThemeProvider theme={theme}>
        <BreadCrumb
          match={{ path: history.location.pathname }}
          title="ORDENES CANCELADAS"
        />
        <div className="container">
          <div className="container-body">
            <BlockUi tag="div" blocking={isLoading}>
              <FormControl component="fieldset">
                <FormLabel component="legend">ORDENES CANCELADAS DE:</FormLabel>
                <RadioGroup
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="SOLO HOY"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="AYER Y HOY"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="DIAS ANTERIORES"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <Grid container>
                <Grid item xs={12}>
                  <Paper>
                    <Box padding={1}>
                      {!matches && (
                        <SingleTableView
                          rows={orders.map((order) => ({
                            ...order,
                          }))}
                          onCellClick={selectedRow}
                          onFilterChange={onFilterChange}
                          columns={[
                            {
                              field: "canceledAt",
                              headerName: "FECHA DE CANCELACIÓN",
                              width: 250,
                              valueGetter: (params) => {
                                const canceledAt = params.row.canceledAt;
                                if (canceledAt && canceledAt.seconds) {
                                  const dateInMillis =
                                    canceledAt.seconds * 1000 +
                                    canceledAt.nanoseconds / 1e6;

                                  const formattedDate = moment(dateInMillis).format('DD/MM/YYYY, HH:mm:ss a');

                                  return formattedDate;
                                } else {
                                  return "";
                                }
                              },
                            },
                            ...DELIVERED_ORDERS_COLUMNS,
                          ]}
                          filterModel={
                            !isEmpty(filterModel)
                              ? { items: [...filterModel] }
                              : undefined
                          }
                        />
                      )}
                      {matches && (
                        <ResponsiveOrderList
                          orders={orders.map((order) => ({
                            ...order,
                            createdDate: !isEmpty(order.createdAt)
                              ? `${order.createdAt.seconds}`
                              : "",
                          }))}
                          onClick={selectedRow}
                        />
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <div className="table-footer">
                <div>
                  <Grid container>
                    <Grid item xs={3}>
                      <small>{`Ordenes canceladas: ${orders.length}`}</small>
                    </Grid>
                    <Grid item xs={3}>
                    <small>{`Tiempo promedio de entrega: ${(
                      avgTime || 0
                    ).toFixed(2)} min`}</small>
                  </Grid>
                  </Grid>
                </div>
              </div>
            </BlockUi>
          </div>
        </div>
      </ThemeProvider>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: selectors.getIsAuthenticated(state),
    isLoading: selectors.getIsFetchingOrders(state),
    historyOrders: selectors.getHistoryOrders(state),
  }),
  (dispatch) => ({
    onLoad() {
      dispatch(
        actions.startFetchingHistoryOrders({
          statusFilter: [orderStatus.CANCELLED],
        })
      );
    },
    clearOrderEdition() {
      dispatch(actions.orderActions.clearOrderEdition());
    },
  })
)(CanceledOrders);
