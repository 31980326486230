// Get companies
export const getCompanies = state => {
  // console.info(`\n»» src/logic/selectors/company.js: getCompanies`, state.company.fetchCompanies.companies, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
  return state.company.fetchCompanies.companies
};

export const getCompanyError = (state) => state.company.fetchCompanies.error;
export const getAreCompaniesLoading = (state) =>
  state.company.fetchCompanies.isLoading;

// Create company
export const isCreateCompanyLoading = (state) =>
  state.company.createCompany.isLoading;
export const isCompanyCreated = (state) => state.company.createCompany.done;
export const getCreateCompanyError = (state) =>
  state.company.createCompany.error;

// Modify company
export const isModifyCompanyLoading = (state) =>
  state.company.modifyCompany.isLoading;
export const isModifyDone = (state) => state.company.modifyCompany.done;
export const getModifyError = (state) => state.company.modifyCompany.error;

// Delete company
export const isDeleteCompanyLoading = (state) =>
  state.company.deleteCompany.isLoading;
export const isDeleteDone = (state) => state.company.deleteCompany.done;
export const getDeleteError = (state) => state.company.deleteCompany.error;
