import React from "react";
import {
  makeStyles,
  Drawer,
  useTheme,
  Avatar,
  Divider,
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useSelector } from "react-redux";
import { RenderMenuItemList } from "../items";
import {
  adminMenu,
  branchMenu,
  companyMenu,
  ownerMenu,
  demoMenu,
  requestsMenu,
} from "../../constants/menu";
import { ROLES } from "../../constants/constants";
import { getController, getAuthEmail } from "../../logic/reducers";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nameText: {
    fontSize: "20px",
    fontWeight: "normal",
    fontFamily: "Lato",
    color: "#565656",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerContainer: {
    overflow: "auto",
  },
  root: {
    flexGrow: 1,
    maxWidth: 400,
    fontSize: "32px",
  },
  large: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.primary.light,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const itemsByRole = ({ controller }) => {
  switch (controller.role) {
    case ROLES.ADMINISTRATOR:
      return companyMenu;
    case ROLES.CONTROLLER:
      return adminMenu;
    case ROLES.REQUEST:
      return requestsMenu;
    case ROLES.USER:
      return branchMenu({ branchId: controller.branchId });
    case ROLES.OWNER:
      return ownerMenu;
    case ROLES.DEMO:
      return demoMenu;
    default:
      return adminMenu;
  }
};
const UserComponent = ({ details, email }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      }}
    >
      <br></br>
      <br></br>
      <Avatar
        alt="Remy Sharp"
        className={classes.large}
        style={{ color: "white", backgroundColor: "#F88B1E" }}
      >
        {details.name[0]}
      </Avatar>
      <Typography className={classes.nameText} variant="subtitle1">
        {details.name}
      </Typography>
      {"\n"}
      <Typography
        style={{
          fontSize: "12px",
        }}
        variant="subtitle2"
      >
        {email}
      </Typography>
      <br></br>
      <br></br>
    </div>
  );
};

const SideBar = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const controller = useSelector(getController);
  const email = useSelector(getAuthEmail);
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      color="primary"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <DehazeIcon /> : <DehazeIcon />}
        </IconButton>
      </div>
      <Divider />
      <UserComponent details={controller} email={email} />
      <div className={classes.drawerContainer}>
        <hr></hr>
        <TreeView
          className={classes.root}
          defaultExpanded={["3"]}
          defaultCollapseIcon={
            <ArrowDropDownIcon style={{ color: "#F88B1E" }} />
          }
          defaultExpandIcon={<ArrowRightIcon style={{ color: "#F88B1E" }} />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <RenderMenuItemList data={itemsByRole({ controller: controller })} />
        </TreeView>
      </div>
    </Drawer>
  );
};
export default SideBar;
