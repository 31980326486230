/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import { firebase, storage } from "../../firebase/config";
import axios from "axios";
import { v4 as uid } from "uuid";
import moment from "moment";

const updatePasswordControllerServices = async ({ info }) => {
  try {
    let config = {
      url:
        "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/update-controller-password",
      method: "post",
      data: {
        uid: info.uid,
        password: info.password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    let res = await axios(config);
    return {
      data: res.data,
      error: null,
    };
  } catch (error) {
    console.log("error", error.message);
    return {
      data: null,
      error,
    };
  }
};

const updateInfoControllerServices = async ({ info }) => {
  try {
    // console.log(info)
    let config = {
      url:
        "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/update-controller-info",
      method: "post",
      data: {
        firstName: info.firstName,
        lastName: info.lastName,
        role: info.role,
        branch: {
          id: info.branch._id,
          name: info.branch._branch
        },
        uid: info.uid,
        company: {
          id: info.company.id,
          name: info.company.name
        },
        pic: info.pic,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    let response = await axios(config);

    return {
      data: response.data.Actualizado,
      e: null,
    };
  } catch (e) {
    console.log("error", e.message);
    return {
      data: null,
      e,
    };
  }
};

const findEmailAuth = async (info) => {
  // console.log(info);
  try {
    let config = {
      url:
        "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/find-user",
      method: "post",
      data: { email: info.email.emailSearch },
      headers: {
        "Content-Type": "application/json",
      },
    };

    let res = await axios(config);
    let valuesCollection = await firebase
      .firestore()
      .collection(info.email.type)
      .doc(res.data.data.uid)
      .get();

    return {
      data: {
        email: res.data,
        value: valuesCollection.data(),
      },
    };
  } catch (error) {
    console.error("e", error.message);
    return {
      error,
    };
  }
};

const createdUsersPilotsServices = async ({ info }) => {
  try {
    console.log(info);
    let config = {
      url:
        "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/create-user-biker",
      method: "POST",
      data: info,
      headers: {
        "Content-Type": "application/json",
      },
    };

    let res = await axios(config);

    return {
      data: res.data,
    };
  } catch (e) {
    console.log(e.message);
    return {
      e,
    };
  }
};

const createdImage = async ({ pic }) => {
  try {
    let document = storage.ref(`imagenes/${uid()}`).put(pic);
    let image = await document;
    let urls;
    if (image) {
      urls = await storage
        .ref(`imagenes/${image.metadata.name}`)
        .getDownloadURL();
    }

    return {
      data: urls,
    };
  } catch (error) {
    console.log(error);
  }
};

const passwordPilot = async ({ info }) => {
  try {
    console.log(info);
    let config = {
      url:
        "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/update-password-biker",
      method: "POST",
      data: info,
      headers: {
        "Content-Type": "application/json",
      },
    };

    let res = await axios(config);

    return {
      data: res.data,
    };
  } catch (e) {
    console.log(e.message);
    return {
      e,
    };
  }
};

const gettingOrdersAll = async () => {
  try {
    const dataAll = firebase.firestore().collection("deliveries");
    const snap = await dataAll.get();
    let array = [];
    snap.forEach((doc) => {
      array.push({ data: doc.data(), id: doc.id });
    });
    console.table(["valores", array]);
    return {
      data: array,
    };
  } catch (error) {
    console.error("The error message", error.message);
  }
};

const gettingAccountsUsersAndControllers = async () => {
  try {
    const data = firebase.firestore().collection("controllers");
    const getUsers = await data.get();
    let array = [];
    getUsers.forEach((doc) => {
      array.push({ id: doc.id, data: doc.data() });
    });
    // console.log(array);
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

const gettingSMSReports = async (startDate, endDate) => {
  try {
    const data = firebase.firestore().collection("smsSends");
    const getSms = await data.orderBy("createAtSms", "desc").get();
    let array = [];
    getSms.docs.forEach((doc) => {
      let infoSms = doc.data();
      const dateSms = new Date(infoSms.createAtSms.seconds * 1000).getTime();
      // console.log(`dateSms: ${dateSms} ${startDate} ${endDate}`);

      if (dateSms > startDate && dateSms < endDate) {
        array.push({ id: doc.id, data: doc.data() });
        //console.log("Si");
      }
    });
    //console.log(array);
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
    //console.log(array)
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

const gettingBikerDeliveries = async () => {
  try {
    const data = firebase.firestore().collection("bikers");
    const getBiker = await data.get();
    let array = [];
    getBiker.forEach((doc) => {
      array.push({ id: doc.id,data:doc.data().name});
    });
    // console.log(array);
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

const gettingBikerOrders = async (startDate, endDate) => {
  try {
    const data = firebase.firestore().collection("deliveries");
    //const getOrder = await data.get();
    const getOrder = await data.orderBy("updatedAt", "desc").get();
    let array = [];
    getOrder.forEach((doc) => {
      let info = doc.data();
      const dateDeliveries = new Date(info.updatedAt.seconds * 1000).getTime();
      // console.log(`date: ${dateDeliveries} ${startDate} ${endDate}`);
      if (dateDeliveries > startDate && dateDeliveries < endDate) {
        array.push({ id: doc.id, data: doc.data() });
        //console.log("Si");
      }
    });
    // console.log(array);
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

const gettingGmailReportData = async (startDate, endDate) => {
  try {
    const data = firebase.firestore().collection("emailSends");
    const getGmail = await data.orderBy("createAtEmail", "desc").get();
    let array = [];
    getGmail.docs.forEach((doc) => {
      let infoGmail = doc.data();
      const dateGmail = new Date(infoGmail.createAtEmail.seconds * 1000).getTime();
      // console.log(`dateSms: ${dateSms} ${startDate} ${endDate}`);
      if (dateGmail > startDate && dateGmail < endDate) {
        array.push({ id: doc.id, data: doc.data() });
        //console.log("Si");
      }
    });
    //console.log(array);
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
    //console.log(array)
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

export {
  gettingAccountsUsersAndControllers,
  updateInfoControllerServices,
  findEmailAuth,
  updatePasswordControllerServices,
  createdUsersPilotsServices,
  createdImage,
  passwordPilot,
  gettingOrdersAll,
  gettingSMSReports,
  gettingBikerDeliveries,
  gettingBikerOrders,
  gettingGmailReportData
};
