import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
  selectLabel: {
    fontSize: "1.2rem",
  },
  select: {
    fontSize: "1rem",
  },
}));

const BikerList = ({ motoristas, selectedMotorista, onMotoristaClick }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="biker-select-label" className={classes.selectLabel}>
        Selecciona un Motorista
      </InputLabel>
      <Select
        labelId="biker-select-label"
        id="biker-select"
        label="Selecciona un Motorista"
        value={selectedMotorista ? selectedMotorista.id : ""}
        onChange={(e) =>
          onMotoristaClick(
            motoristas.find((motorista) => motorista.id === e.target.value)
          )
        }
        className={classes.select}
      >
        {motoristas.map((motorista) => (
          <MenuItem key={motorista.id} value={motorista.id}>
            {motorista.name.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BikerList;
