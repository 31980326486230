import React from "react";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Avatar from "@material-ui/core/Avatar";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const useStyles = makeStyles((theme) => ({
  count: {
    fontSize: "32px",
    fontWeight: "bold",
  },
  title: {
    fontSize: "18px",
    marginRight: "10px",
    color: "#777B7E",
  },
  avatar: {
    backgroundColor: "#FFF",
    width: "56px",
    height: "56px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  icon: {
    fontSize: "36px",
    color: "#007bff",
  },
  difference: {
    fontSize: "18px",
  },
  root: {
    minWidth: 275,
    height: 150,
    display: "flex",
  },
  content: {
    flex: "1 0 auto",
    textAlign: "center",
  },
}));

const ProgressOrders = ({ difference, title, count, Icon }) => {
  const styles = useStyles();
  return (
    <Card className={styles.root} variant="outlined">
      <CardContent className={styles.content}>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography component="h5" variant="h5" color="textSecondary">
              {title.toUpperCase()}
            </Typography>
            <Typography variant="h4" className={styles.count}>
              {count}
            </Typography>
          </Stack>
          <Avatar className={styles.avatar}>
            <SvgIcon>
              <Icon className={styles.icon} />
            </SvgIcon>
          </Avatar>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 2 }}>
          {difference !== 0 && (
            <>
              {difference < 0 ? (
                <>
                  <SvgIcon color="error" fontSize="small">
                    <ArrowDownwardIcon />
                  </SvgIcon>
                  <Typography
                    color="error"
                    variant="body2"
                    className={styles.difference}
                  >
                    {Math.abs(difference.toFixed(2))}%
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {"Desde el mes pasado".toUpperCase()}
                  </Typography>
                </>
              ) : (
                <>
                  <SvgIcon style={{ color: "#28a745" }} fontSize="small">
                    <ArrowUpwardIcon />
                  </SvgIcon>
                  <Typography
                    style={{ color: "#28a745" }}
                    variant="body2"
                    className={styles.difference}
                  >
                    {difference.toFixed(1)}%
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {"Desde el mes pasado".toUpperCase()}
                  </Typography>
                </>
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProgressOrders;
