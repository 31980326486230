import { v4 as uuidv4 } from "uuid";
import { storage } from "./config";

export const uploadImage = async (route, file) => {
  const uploadedFile = await storage.ref(`${route}/${uuidv4()}`).put(file);
  return uploadedFile;
};

export const getImageUrl = async (uploadedFile) => {
  const url = await uploadedFile.ref.getDownloadURL();
  return url;
};

export const removeImage = async (uploadedFile) => {
  await uploadedFile.ref.delete();
};
