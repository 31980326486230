import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import { CheckCircleOutline as DeliveredIcon } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import StoreIcon from "@mui/icons-material/Store";

const TopCompany = ({ ordersDay }) => {
  const [topCount, setTopCount] = useState(5);

  const ordersDeliveries = ordersDay.filter(
    (order) => order.status === "Entregado"
  );

  const topCompanyDay = () => {
    const companyOrders = {};
    ordersDeliveries.forEach((order) => {
      const companyName = order.company.name.toUpperCase() || "Unassigned";
      if (!companyOrders[companyName]) {
        companyOrders[companyName] = { delivered: 0 };
      }
      // hacemos el conteo de ordenes entregadas por companis
      if (order.status === "Entregado") {
        companyOrders[companyName].delivered += 1;
      }
    });

    const topCompanies = Object.entries(companyOrders)
      .sort((a, b) => b[1].delivered - a[1].delivered)
      .slice(0, 10);

    return topCompanies;
  };

  const topCompanies = topCompanyDay(ordersDay).slice(0, topCount);

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <CardContent style={{ flex: "1 0 auto", textAlign: "center" }}>
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          {`Top ${topCount} Ordenes por Compañias`.toUpperCase()}
        </Typography>
        <Box display="flex" justifyContent="center" mb={2}>
          <ButtonGroup
            color="secondary"
            variant="outlined"
            aria-label="outlined secondary button group"
          >
            <Button
              variant={topCount === 5 ? "contained" : "outlined"}
              onClick={() => setTopCount(5)}
            >
              Top 5
            </Button>
            <Button
              variant={topCount === 10 ? "contained" : "outlined"}
              onClick={() => setTopCount(10)}
            >
              Top 10
            </Button>
          </ButtonGroup>
        </Box>
        <List>
          {topCompanies.map(([company, data], index) => (
            <div key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#1F44A1" }}>
                    <StoreIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={company} />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box display="flex" alignItems="center">
                    <Tooltip title={`Entregadas: ${data.delivered}`}>
                      <span>
                        <IconButton
                          size="small"
                          disabled={data.delivered === 0}
                        >
                          <DeliveredIcon
                            color={data.delivered > 0 ? "success" : "disabled"}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography variant="body2">{data.delivered}</Typography>
                  </Box>
                </Stack>
              </ListItem>
              {index < topCompanies.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default TopCompany;
