import React from "react";
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "../containers/navs/navbar";
import SideBar from "../containers/navs/sidebar";
import { ThemeProvider } from "@material-ui/core";
import theme from "../assets/theme";
import clsx from "clsx";
import { XsNavBar, XsSideBar } from "../containers/navs";
import { Alarm } from "@material-ui/icons";
import BranchClock from "../components/Branch/BranchClock";
import { ScreenCapture } from "react-screen-capture";
import { useScreenshot } from "use-screenshot-hook";
import { IssueReportDialog } from "../containers/dialogs";
const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    width:'100%',
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  xsContent: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const AppLayout = ({ children }) => {
  const uiTheme = useTheme();
  const matches = useMediaQuery(uiTheme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [screenCapture, setScreenCapture] = React.useState("");
  const { image, takeScreenshot, isLoading, clear } = useScreenshot();
  const [issueOpen, setIssueOpen] = React.useState(false);
  const classes = useStyles();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    clear();
  };
  const handleScreenCapture = (capture) => {
    setScreenCapture(capture);
    setIssueOpen(true);
  };

  const issueHandleClose = () => {
    setIssueOpen(false);
  };
  const take = () => {
    takeScreenshot();
    setIssueOpen(true);
  };
  if (matches) {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <>
            <XsNavBar handleDrawerOpen={handleDrawerOpen} />
            <XsSideBar
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              open={open}
            />
            <main className={classes.xsContent}>
              <div className={classes.drawerHeader}>
                {/* <div className="display-inline-block margin-right-md branch-resume-cards">
                <div className="display-inline-flex">
                  <div className="display-inline-block chip-avatar">
                    <Avatar className={classes.default}>
                      <Alarm style={{ fontSize: 20 }} />
                    </Avatar>
                  </div>
                  <span className="branch-resume-card-text">
                    <BranchClock />
                  </span>
                </div>
              </div> */}
              </div>
              {children}
            </main>
          </>
        </div>
      </ThemeProvider>
    );
  }
  return (
    <ScreenCapture onEndCapture={handleScreenCapture}>
      {({ onStartCapture }) => (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <>
              <NavBar
                handleDrawerOpen={handleDrawerOpen}
                open={open}
                onStartCapture={take}
              />
              <SideBar handleDrawerClose={handleDrawerClose} open={open} />
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                <br></br>
                <br></br>
                <div className={classes.drawerHeader}>
                  <div className="display-inline-block margin-right-md branch-resume-cards">
                    <div className="display-inline-flex">
                      <div className="display-inline-block chip-avatar">
                        <Avatar className={classes.default}>
                          <Alarm style={{ fontSize: 20 }} />
                        </Avatar>
                      </div>
                      <span className="branch-resume-card-text">
                        <BranchClock />
                      </span>
                    </div>
                  </div>
                </div>
                {children}
              </main>
            </>
          </div>
          <IssueReportDialog
            open={issueOpen && !isLoading}
            handleClose={issueHandleClose}
            capture={image}
          />
        </ThemeProvider>
      )}
    </ScreenCapture>
  );
};
export default AppLayout;
