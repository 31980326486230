/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import logo from "../../assets/img/DLlogo.png";
import CircularProgress from "./components/progressbar";
import { red } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Copyright from "./components/copyright";
import theme from "../../assets/theme";
import ErrorIcon from "@material-ui/icons/Error";
import { firebase } from "../../firebase/config";
import swal from "sweetalert";

let unsuscribe;

const mostrarAlert = () => {
  swal({
    title: "Error",
    text: "El pin que ha ingresado es incorrecto",
    icon: "warning",
  }).then((respuesta) => {
    if (respuesta) {
      swal({ text: `Tiene solo tiene 3 intentos` });
    }
  });
};

const alertIntentos = () => {
  swal({
    title: "Error",
    text: "Ha usado sus tres intentos porfavor comunicarse a PBX",
    icon: "warning",
  });
};
class ShareLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      value: "",
      errors: "",
      disabled: false,
      name: "",
      pinAsignacion: "",
      attempts: "",
      doesExist: false,
      intents: 0,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  updateFirebase = async () => {
    const a = this.state.intents;
    const { id } = this.props.match.params;
    await firebase
      .firestore()
      .collection("deliveries")
      .doc(id)
      .update({
        attempts: a,
        dato: 0,
      });
  };

  handleSubmit(event) {
    event.preventDefault();
    mostrarAlert();
    this.updateFirebase();
    this.onUserRegister();

    if (this.state.intents >= 3) {
      alertIntentos();
      this.setState({
        disabled: true,
      });
    } else {
      this.setState({
        disabled: false,
      });
    }
    this.setState({
      intents: this.state.intents + 1,
    });
  }

  initFecth = async () => {
    const { id } = this.props.match.params;
    await firebase
      .firestore()
      .collection("deliveries")
      .doc(id)
      .get()
      .then((result) => {
        if (result.exists) {
          this.setState({
            pin: result.data(),
            id: id,
            name: result.data().name,
            doesExist: true,
            intents:
              result.data().attempts !== undefined ? result.data().attempts : 0,
          });
          this.streamBiker(result.data().pin);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      loading: true,
    });
  };

  streamBiker = async (alias) => {
    unsuscribe = await firebase
      .firestore()
      .collection("biker")
      .doc(alias)
      .onSnapshot((snap) => {
        if (snap.exists) {
          this.setState({
            pin: snap.data(),
          });
        }
      });
  };

  onUserRegister = () => {
    const { id } = this.props.match.params;
    if (this.state.pin.pin === this.state.value && this.state.intents < 3) {
      const continuar = () => {
        swal(
          "Ingreso correctamente",
          " " + this.state.name,
          " Bienvenido",
          "success"
        );
      };
      continuar();
      this.props.history.push(`/Share/order/${id}`);
    } else if (this.state.intents >= 3) {
      alertIntentos();
    } else {
      mostrarAlert();
    }
  };

  componentDidMount() {
    this.initFecth();
  }
  render() {
    return (
      <div>
        {!this.state.loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={10} lg={5}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : this.state.doesExist ? (
          <div>
            {
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={10} lg={5}>
                  <img
                    src={logo}
                    style={{ width: "100%", height: "auto" }}
                    alt="Logo"
                  />
                  <p></p>
                  <center>
                    <Typography component="h1" variant="h5"></Typography>
                  </center>
                  <form noValidate={null}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      validate={this.validatePassword}
                      label="INGRESAR PIN"
                      disabled={this.state.disabled}
                      value={this.state.value}
                      onChange={this.handleChange}
                      style={{ margin: "10px" }}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={this.state.disabled}
                      type="submit"
                      value={this.state.pin}
                      className={`btn-shadow btn-multiple-state ${
                        this.props.loading ? "show-spinner" : ""
                      }`}
                      onClick={this.handleSubmit}
                      style={{ margin: "10px" }}
                    >
                      VERIFICA TU ORDEN
                    </Button>
                  </form>
                </Grid>
              </Grid>
            }
          </div>
        ) : (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={10} lg={5}>
              <div style={{ backgroundColor: "#FDFEFE" }}>
                <center>
                  <img src={logo} width="600" height="150" alt="logotipo"></img>
                </center>
                <p></p>
                <center>
                  <Typography component="h1" variant="h5">
                    <p></p>
                    <center>
                      <ErrorIcon
                        size="30px"
                        style={{ color: red[500], fontSize: 40 }}
                      />
                    </center>
                  </Typography>
                </center>
                <center>
                  <Typography color="primary">
                    TU NUMERO DE ORDEN NO EXISTE,
                  </Typography>
                </center>
                <ThemeProvider theme={theme}>
                  <center>
                    <Typography color="primary">
                      PARA MAS INFORMACIÓN COMUNICARSE CON PBX
                    </Typography>
                  </center>
                </ThemeProvider>
              </div>
            </Grid>
          </Grid>
        )}
        <Copyright />
      </div>
    );
  }
}

export default ShareLogin;
