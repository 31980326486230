import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const COLORS = ["#7DFF00", "#F7B700", "#E70000", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const PieChartComponent = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const [all, setAll] = useState(0);

  useEffect(() => {
    if (!isEmpty(data)) {
      const allOrders = data.reduce(
        (acc, currValue) => currValue.value + acc,
        0
      );
      setAll(allOrders);
    }
  }, [data]);

  return (
    <div className="relative-pos">
      <PieChart width={440} height={250}>
        <Pie
          labelLine={true}
          label={renderCustomizedLabel}
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#f26724"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div className="resume-pie-chart-labels">
        {data.map((entry, index) => (
          <div key={`label-${index}`}>
            <div
              className="resume-pie-chart-box-color"
              style={{
                background: COLORS[index % COLORS.length],
              }}
            />
            <div className="resume-pie-chart-box-text">{entry.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default PieChartComponent;
