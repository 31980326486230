import axios from "axios";

export const creatingUser = async(values) => {
  console.log(values);
  try {
    let data = {
      firstName:values.firstName,
      lastName: values.lastName,
      email : values.email,
      role : values.role,
      password : values.password,
      company:{
        id: values.company ? values.company.id : "",
        name: values.company ? values.company.name : "",
      },
      branch:{
        id: values.branch ? values.branch._id : "",
        name: values.branch ? values.branch._branch : ""
      }
    }

  console.log("datos de la orden",data);
    
   
  
      var config = {
        method: 'post',
        url: 'https://us-central1-twowheelstogo-572d7.cloudfunctions.net/createdUsers/created-controller',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      let response = await axios(config);
      return {
        data: response.data,
        error: null
      }
    } catch (error) {
      return {
        data: null,
        error: error.message
      }; 
    }
  }