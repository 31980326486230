import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    overflowX: "auto",
  },
  card: {
    flex: "1 0 calc(20% - 16px)", // Cambiar el ancho mínimo a 20%
    maxWidth: "300px", // Limitar el ancho máximo
    margin: "8px",
    position: "relative",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    width: "300px", // Fija el ancho y el alto de la tarjeta
    height: "200px", // Fija el alto de la tarjeta
  },
  stateCard: {
    background: "#28a745",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
  },
  cardContent: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  clientText: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  orderText: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "8px 0",
    textAlign: "center",
  },
  dateText: {
    fontSize: "12px",
    color: "#777",
    textAlign: "center",
  },
}));

const LastOrders = ({ ordersDay }) => {
  const classes = useStyles();

  const ordersDeliveries = ordersDay.filter(
    (order) => order.status === "Entregado"
  );
  // Ordenar las órdenes por la fecha de entrega en orden descendente (las más recientes primero)
  const sortedDeliveredOrders = ordersDeliveries.sort(
    (a, b) => b.deliveredAt.seconds - a.deliveredAt.seconds
  );

  const formatDate = (date) => {
    return moment(date.seconds * 1000).format("l h:mm a");
  };
  // Tomar las últimas 5 órdenes entregadas
  const lastFiveDeliveredOrders = sortedDeliveredOrders.slice(0, 5);

  return (
    <div>
      {!isEmpty(lastFiveDeliveredOrders) ? (
        <Card
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            alignItems: "center",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            color="textSecondary"
            align="center"
            gutterBottom
          >
            {`Últimas órdenes entregadas`.toUpperCase()}
          </Typography>
          <Box className={classes.cardContainer}>
            {lastFiveDeliveredOrders.map((order) => (
              <Card key={order.id} variant="outlined" className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={classes.clientText}
                  >
                    {`Cliente: ${order.name}`.toUpperCase()}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.orderText}
                  >
                    {`Orden ${order.orderId}`.toUpperCase()}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.orderText}
                  >
                    {`${order.company.name}`.toUpperCase()}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.orderText}
                  >
                    {`Entrega: ${formatDate(order.deliveredAt)}`.toUpperCase()}
                  </Typography>
                  <div className={classes.stateCard}>
                    {order.status.toUpperCase()}
                  </div>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Card>
      ) : (
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          {`NO HAY ÓRDENES ENTREGADAS`.toUpperCase()}
        </Typography>
      )}
    </div>
  );
};

export default LastOrders;
