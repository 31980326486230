class OrderModel{
    constructor(props){
        this.id=props.id;
        this.address=props.address;
        this.alert=props.alert;
        this.branch=props.branch;
        this.circle=props.circle;
        this.company=props.company;
        this.country_code=props.country_code;
        this.createdAt=props.createdAt;
        this.depto=props.depto;
        this.locationRef=props.locationRef;
        this.munic=props.munic;
        this.name=props.name;
        this.nota=props.nota;
        this.orderId=props.orderId;
        this.phone=props.phone;
        this.provider=props.provider;
        this.stateTime=props.stateTime;
        this.status=props.status;
        this.time=props.time;
        this.updatedAt=props.updatedAt;
    }
}
export default OrderModel;