/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import * as yup from "yup";

import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import moment from "moment";

import { getController } from "../../logic/reducers";
import { INPUT_TYPE } from "../../constants/constants";

import DynamicForm from "../Design/Form/DynamicForm";
import isEmpty from "lodash/isEmpty";

import BreadCrumb from "../../containers/navs/breadcrumb";

import CircularProcess from "@material-ui/core/CircularProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import Alert from "@material-ui/lab/Alert";

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  Export,
  FilterPanel,
  TotalItem,
  Summary,
  Selection,
  HeaderFilter,
  Scrolling,
  FilterRow
} from "devextreme-react/data-grid";

const formFields = [
  {
    label: "FECHA DE INICIO",
    autofocus: true,
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "FECHA FINAL",
    autofocus: true,
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
];

const validationSchema = yup.object({});

const SmsReport = ({ init, sms, failed, gettingSMSReport }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filters, setFilters] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [filterError, setFilterError] = useState("");
  const [rows, setRows] = useState([]);

  const pageSizes = [10, 25, 50, 100];

  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  useEffect(() => {
    //console.log(`sms: ${sms}`);
  }, [sms]);

  // console.log(sms);

  const applyFilters = (currFilters) => {
    // console.log(`currFilters: ${JSON.stringify(currFilters)}`);
    setRows(currFilters);
  };

  const onSubmit = async (values) => {
    setFilterError("");
    if (!isEmpty(values.startDate) && !isEmpty(values.endDate)) {
      const startDate = new Date(`${filters.startDate} 00:00:00`).getTime();
      const endDate = new Date(`${filters.endDate} 23:59:59`).getTime();
      gettingSMSReport(startDate, endDate);

      if (startDate > endDate) {
        setFilterError("Fecha de inicio debe ser antes que la fecha final");
        return;
      }
    }
    applyFilters(values);
  };

  const renderView = () => {
    if (hasError) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }

    const newDateFormat = ({ data }) => {
      //console.log(data.data);
      const dateFormNew = moment(data.data.createAtSms.seconds * 1000).format(
        "L"
      );
      return (
        <>
          <div>{dateFormNew}</div>
        </>
      );
    };

    return (
      <>
        {!isEmpty(filterError) && (
          <>
            <Alert severity="error">{filterError}</Alert>
            <br />
          </>
        )}
        <div>
          <BreadCrumb match={{ path: "/" }} title="REPORTE SMS" />
        </div>
        <DynamicForm
          obj={filters}
          setState={setFilters}
          fields={customFormFields}
          schema={validationSchema}
          buttons={btnList}
          onSubmit={onSubmit}
          btnAlignment="right"
          forceSetState
        />
        {init ? (
          <CircularProgress disableShrink />
        ) : (
          <>
            <DataGrid
          keyExpr="id"
          dataSource={sms}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          noDataText="NO HAY INFORMACIÓN"
        >
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Grouping autoExpandAll={false} />
          <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
          <Paging defaultPageSize={10} />
          <Summary recalculateWhileEditing={true}>
            <TotalItem
              column="data.company.name"
              displayFormat="ORDENES: {0}"
              summaryType="count"
            />
          </Summary>
          <FilterPanel
            visible={true}
            texts={{
              createFilter: "CREAR FILTRO",
              clearFilter: "ELIMINAR FILTRO",
              filterEnabledHint: "CREACIÓN DE FILTROS",
            }}
          />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="reportSms"
          />
          <Selection mode="single" />
          <Column dataField="data.orderId" caption="No. Orden"/>
          <Column dataField="data.client" caption="Cliente"/>
          <Column dataField="data.address" caption="Direccion"/>
          <Column dataField="data.company.name" caption="Compania" />
          <Column
            dataField="data.createAtSms.seconds"
            caption="Fecha"
            cellRender={newDateFormat}
          />
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
          </>
        )}
      </>
    );
  };

  return (
    <div title="Reporte" cssClass="page-container">
      <div className="container-body">
        {isLoading ? <CircularProcess color="secondary" /> : renderView()}
      </div>
    </div>
  );
};

const { gettingSMS, getSMS, gettingSMSFail } = selectors;

export default connect(
  (state) => ({
    init: gettingSMS(state),
    sms: getSMS(state),
    failed: gettingSMSFail(state),
  }),
  (dispatch) => ({
    gettingSMSReport(startDate, endDate) {
      dispatch(actions.gettingSms(startDate, endDate));
    },
  })
)(SmsReport);
