import { firebaseFirestore } from "./config";
import isEmpty from "lodash/isEmpty";
import { getSubscribeBiker } from "../logic/selectors/bikers";
import isArray from "lodash/isArray";
import { store } from "../components/App";
import * as selectors from "../logic/reducers";
import { getOrdersByBikerIdByOrderId } from "../logic/selectors/orders";
import { orderStatus, orderAlert } from "../constants/constants";
import dateHelper from "../helpers/dateHelper";
import * as actions from "../logic/actions/orders";
import { v4 as uuidV4 } from "uuid";
const db = firebaseFirestore;
const collection = "deliveries";
// const debugs = {};

export const subscribeGetOrdersByBikerId = async (bikerId) => {
  const startDay = new Date();
  const endDay = new Date();
  startDay.setHours(0);
  startDay.setMinutes(0);
  startDay.setSeconds(1);
  endDay.setHours(23);
  endDay.setMinutes(59);
  endDay.setSeconds(59);
  db.collection(collection)
    .where("alias.id", "==", bikerId)
    .where("status", "in", [
      orderStatus.GOING,
      orderStatus.ACCEPTED,
      orderStatus.ASSIGNED,
      orderStatus.RECEIVING,
    ])
    // .where("hash", "==", "orderStatus.GOING+orderStatus.ACCEPTED+orderStatus.ASSIGNED+orderStatus.RECEIVING+bikerId")
    .onSnapshot((observer) => {
      observer.docChanges().forEach((change) => {
        /*
        * When you want to save a object { with many data inside }
        save  a string like this "orderStatus.GOING+orderStatus.ACCEPTED+orderStatus.ASSIGNED+orderStatus.RECEIVING+bikerId"
        When you want to get the data you don't neet to make a lot of "where condition"
        .where("hash", "==", "orderStatus.GOING+orderStatus.ACCEPTED+orderStatus.ASSIGNED+orderStatus.RECEIVING+bikerId")
         */
        /**
         * Table_With_detail :
         *  _id: "this is id"
         *  children1:
         *    another_children:2
         * Table_indexeses :
         *  _id: "this is id"
         *
         * if you want to Node _id
         *
         * orders:
         *  "XID":
         *      id: "XID"
         *      name: "..."
         *       ...
         *  "YID":
         *      id: "YID"
         *      name: "..."
         *       ...
         * order_progress: ["XID", "YID"]
         * SELECT * from orders
         * SELECT id from orders
         */
        let shouldAdd = true;
        const orderToValidate = change.doc.data();
        if (
          !isEmpty(orderToValidate.createdAt) &&
          orderToValidate.createdAt.seconds !== undefined
        ) {
          const orderTimestamp = new Date(
            orderToValidate.createdAt.seconds * 1000
          ).getTime();
          if (
            !(
              orderTimestamp > startDay.getTime() &&
              orderTimestamp < endDay.getTime()
            )
          ) {
            shouldAdd = false;
          }
        }
        const currState = store.getState();
        const currBiker = getSubscribeBiker(currState);
        if (
          !isEmpty(currBiker) &&
          !isEmpty(orderToValidate.alias) &&
          orderToValidate.alias.id !== currBiker.id
        ) {
          shouldAdd = false;
        }
        if (shouldAdd) {
          if (change.type === "added") {
            const id = change.doc.id;
            const order = change.doc.data();
            const existing = getOrdersByBikerIdByOrderId(store.getState(), id);
            if (existing === undefined || existing === null) {
              order["id"] = id;
              if (!isEmpty(order) && isEmpty(order.alias)) {
                order.alias = {
                  name: undefined,
                  id: undefined,
                };
              }
              store.dispatch(
                actions.orderActions.subscribeOrdersByBikerIdChanges(order)
              );
            }
          }

          if (change.type === "modified") {
            const id = change.doc.id;
            const order = change.doc.data();

            order["id"] = id;
            if (!isEmpty(order) && isEmpty(order.alias)) {
              order.alias = {
                name: undefined,
                id: undefined,
              };
            }

            store.dispatch(
              actions.orderActions.subscribeOrdersByBikerIdChanges(order)
            );
          }
        }

        if (change.type === "removed") {
          const id = change.doc.id;
          const order = change.doc.data();
          order["id"] = id;
          store.dispatch(
            actions.orderActions.subscribeOrdersByBikerIdChanges(order, true)
          );
        }
      });
    });
};

export const getOrderByOrderId = async (orderId) => {
  db.collection(collection)
    .doc(orderId)
    .onSnapshot((observer) => {
      const order = observer.data();
      if (!isEmpty(order) && isEmpty(order.alias)) {
        order.alias = {
          name: undefined,
          id: undefined,
        };
      }
      if (isEmpty(order)) {
        store.dispatch(
          actions.orderActions.getOrderByOrderIdFailure("order not found")
        );
        return;
      }
      const id = observer.id;

      store.dispatch(
        actions.orderActions.getOrderByOrderIdSuccess({
          ...order,
          id,
        })
      );
    });
};

export const removeOrderByOrderId = async (orderId) => {
  return await db
    .collection(collection)
    .doc(orderId)
    .delete();
};

export const getOrders = async (
  statusFilter,
  branchId,
  companyId,
  allOrders = false,
  days = 0
) => {
  try {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setDate(startDay.getDate() - days);
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(1);
    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);
    let query = await db.collection(collection);

    if (!isEmpty(companyId)) {
      query = query.where("company.id", "==", companyId);
    }
    if (!isEmpty(branchId)) {
      query = query.where("branch.id", "==", branchId);
    }

    if (isArray(statusFilter)) {
      query = query.where("status", "in", statusFilter);
    } else {
      query = query.where(
        "status",
        "==",
        !isEmpty(statusFilter) ? statusFilter : "Entregado"
      );
    }
    const orders = await query.orderBy("createdAt", "desc").get();

    let ordersArray = [];
    await orders.docs.forEach((delivery) => {
      const orderToValidate = delivery.data();
      if (
        !isEmpty(orderToValidate.createdAt) &&
        orderToValidate.createdAt.seconds !== undefined
      ) {
        const orderTimestamp = new Date(
          orderToValidate.createdAt.seconds * 1000
        ).getTime();

        if (
          (orderTimestamp > startDay.getTime() &&
            orderTimestamp < endDay.getTime()) ||
          allOrders
        ) {
          let meanwhile = delivery.data();
          meanwhile["id"] = delivery.id;
          if (isEmpty(meanwhile.alias)) {
            meanwhile.alias = {
              name: undefined,
              id: undefined,
            };
          }
          if (
            !isEmpty(meanwhile.createdAt) &&
            meanwhile.createdAt.seconds !== undefined
          ) {
            if (
              meanwhile.status === orderStatus.DELIVERED &&
              !isEmpty(meanwhile.deliveredAt) &&
              meanwhile.deliveredAt.seconds !== undefined
            ) {
              meanwhile.time =
                meanwhile.deliveredAt.seconds - meanwhile.createdAt.seconds;
            } else if (
              meanwhile.status === orderStatus.CANCELLED &&
              !isEmpty(meanwhile.canceledAt) &&
              meanwhile.canceledAt.seconds !== undefined
            ) {
              meanwhile.time =
                meanwhile.canceledAt.seconds - meanwhile.createdAt.seconds;
            }
            meanwhile.alert = dateHelper.getAlert(meanwhile.time / 60);
          }
          ordersArray.push(meanwhile);
        }
      }
    });
    let ordersNormalizer = {};
    let deliveryById = [];
    ordersNormalizer["order"] = ordersArray.map((delivery) => delivery.id);
    ordersArray.map((delivery) => (deliveryById[delivery.id] = delivery));
    ordersNormalizer["byId"] = deliveryById;
    ordersNormalizer["array"] = ordersArray;
    return {
      orders: ordersNormalizer,
      error: null,
    };
  } catch (error) {
    console.log("FIREBASE ORDERS ERROR --> ", error);
    return {
      users: null,
      error: error,
    };
  }
};

export const updateOrders = async (order) => {
  try {
    let orderDoc = await firebaseFirestore.collection(collection).doc(order.id);
    // let orderId = orderDoc.id;

    await orderDoc.update(order);
    if (!isEmpty(order) && isEmpty(order.alias)) {
      order.alias = {
        name: undefined,
        id: undefined,
      };
    }
    return {
      order: order,
      error: null,
    };
  } catch (error) {
    console.log(error);
    return {
      order: null,
      error: error,
    };
  }
};
export const subscribeOrdersByMonth = async (filter = {}) => {
  let query = db.collection(collection);

  if (!isEmpty(filter.companyId)) {
    query = query.where("company.id", "==", filter.companyId);
  }
  if (!isEmpty(filter.branchId)) {
    query = query.where("branch.id", "==", filter.branchId);
  }

  if (isArray(filter.statusFilter) || isEmpty(filter.statusFilter)) {
    query = query.where(
      "status",
      "in",
      !isEmpty(filter.statusFilter)
        ? filter.statusFilter
        : [
            orderStatus.GOING,
            orderStatus.ON_HOLD,
            orderStatus.PENDING,
            orderStatus.ACCEPTED,
            orderStatus.ASSIGNED,
            orderStatus.RECEIVING,
            orderStatus.DELIVERED,
            orderStatus.CANCELLED,
            orderStatus.RE_AGEND,
          ]
    );
  } else {
    query = query.where("status", "==", orderStatus.GOING);
    query = query.where(
      "alert",
      "array-contains",
      !isEmpty(filter.alertFilter)
        ? filter.alertFilter
        : [
            orderAlert.TO_BE_LATE,
            orderAlert.ON_TIME,
            orderAlert.LATE,
            orderAlert.VERIFY,
          ]
    );
  }

  const actualDate = new Date();
  query.onSnapshot((observer) => {
    // eslint-disable-next-line array-callback-return
    observer.docChanges().map((change) => {
      const order = change.doc.data();
      let shouldAdd = true;
      const orderTimestamp = new Date(order.createdAt.seconds * 1000);
      if (
        !(
          orderTimestamp.getMonth() === actualDate.getMonth() &&
          orderTimestamp.getFullYear() === actualDate.getFullYear()
        )
      )
        shouldAdd = false;
      if (shouldAdd) {
        if (change.type === "added") {
          console.log("added");
          const id = change.doc.id;
          const order = change.doc.data();
          const existing = selectors.getOrder(store.getState(), id);
          if (existing === undefined || existing === null) {
            order["id"] = id;
            order["circleValue"] = order.circle ? order.circle.id : 0;
            if (
              !isEmpty(order.createdAt) &&
              order.createdAt.seconds !== undefined
            ) {
              order.time = dateHelper.getTimeOnRoute(
                new Date(order.createdAt.seconds * 1000).getTime()
              );
              order.alert = dateHelper.getAlert(order.time / 60);
            }
            if (!isEmpty(order) && isEmpty(order.alias)) {
              order.alias = {
                name: undefined,
                id: undefined,
              };
            }
            if (!isEmpty(order) && isEmpty(order.modifyingBy)) {
              order.modifyingBy = {
                name: "",
                id: "",
              };
            }
            order.modifyingByName = order.modifyingBy.name;
            store.dispatch(actions.addingMonthlyOrder(order, id));
          }
        }

        if (change.type === "modified") {
          const id = change.doc.id;
          const order = change.doc.data();

          order["id"] = id;
          if (!isEmpty(order) && isEmpty(order.alias)) {
            order.alias = {
              name: undefined,
              id: undefined,
            };
          }
          if (!isEmpty(order) && isEmpty(order.modifyingBy)) {
            order.modifyingBy = {
              name: "",
              id: "",
            };
          }
          order.modifyingByName = order.modifyingBy.name;
          const existing = selectors.getOrder(store.getState(), id);
          if (existing) {
            store.dispatch(actions.modifyingMonthlyOrder(order, id));
          } else {
            // TODO: PROBLEMS
            store.dispatch(actions.removingMonthlyOrder(order, id));
          }
        }
      }

      if (change.type === "removed") {
        const id = change.doc.id;
        const order = change.doc.data();

        store.dispatch(actions.removingMonthlyOrder(order, id));
      }
    });
    store.dispatch(actions.setShouldRunTimeout(true));
  });
};
export const subscribeOrders = async (filter = {}) => {
  let query = db.collection(collection);

  const statusFilter = filter.statusFilter || [
    orderStatus.GOING,
    orderStatus.ON_HOLD,
    orderStatus.PENDING,
    orderStatus.ACCEPTED,
    orderStatus.ASSIGNED,
    orderStatus.RECEIVING,
  ];

  const alertFilter = filter.alertFilter || [
    orderAlert.TO_BE_LATE,
    orderAlert.ON_TIME,
    orderAlert.LATE,
    orderAlert.VERIFY,
  ];

  if (!isEmpty(filter.companyId)) {
    query = query.where("company.id", "==", filter.companyId);
  }
  if (!isEmpty(filter.branchId)) {
    query = query.where("branch.id", "==", filter.branchId);
  }

  if (isArray(filter.statusFilter) || isEmpty(filter.statusFilter)) {
    // query = query.where("status", "in", statusFilter);
    query = query.where(
      "status",
      "in",
      !isEmpty(filter.statusFilter) ? filter.statusFilter : statusFilter
    );
  } else {
    query = query.where("status", "==", orderStatus.GOING);
    query = query.where(
      "alert",
      "in",
      !isEmpty(filter.alertFilter) ? filter.alertFilter : alertFilter
    );
  }

  const startDay = new Date();
  startDay.setHours(0, 0, 1);
  const endDay = new Date();
  endDay.setHours(23, 59, 59);

  // console.log("startDay", startDay);
  // console.log("endDay", endDay);
  query.onSnapshot((observer) => {
    store.dispatch(actions.setShouldRunTimeout(false));

    observer.docChanges().forEach((change) => {
      let shouldAdd = true;
      const order = change.doc.data();
      if (!isEmpty(order.createdAt) && order.createdAt.seconds !== undefined) {
        const orderTimestamp = new Date(
          order.createdAt.seconds * 1000
        ).getTime();
        if (
          !(
            orderTimestamp >= startDay.getTime() &&
            orderTimestamp <= endDay.getTime()
          )
        ) {
          shouldAdd = false;
        }
      }

      if (shouldAdd) {
        if (!isEmpty(filter.companyIdList)) {
          const order = change.doc.data();
          if (!isEmpty(order.company)) {
            if (!isEmpty(order.company.id)) {
              shouldAdd = Boolean(
                filter.companyIdList.find(
                  (companyId) => companyId === order.company.id
                )
              );
            } else {
              shouldAdd = false;
            }
          } else {
            shouldAdd = false;
          }
        }
      }

      if (shouldAdd) {
        if (isEmpty(order.company)) {
          console.log(order);
        }
        const id = change.doc.id;
        order.id = id;
        order.companyId = order.company.id;
        order.circleValue = order.circle?.id || 0;
        order.time = dateHelper.getTimeOnRoute(
          new Date(order.createdAt.seconds * 1000).getTime()
        );
        order.alert = dateHelper.getAlert(order.time / 60);
        order.alias = order.alias || { name: undefined, id: undefined };
        order.modifyingBy = order.modifyingBy || { name: "", id: "" };
        order.modifyingByName = order.modifyingBy.name;
        const existing = selectors.getOrder(store.getState(), id);
        if (existing === undefined || existing === null) {
          if (change.type === "added") {
            store.dispatch(actions.addingOrder(order, id));
          }
        }
        if (change.type === "modified") {
          store.dispatch(actions.modifyingOrder(order, id));
        } else if (change.type === "removed") {
          store.dispatch(actions.removingOrder(order, id));
        }
      }
    });
  });

  store.dispatch(actions.setShouldRunTimeout(true));
};

export const getTrackingOrder = async (id) => {
  try {
    let order = await db
      .collection(collection)
      .doc(id)
      .get();
    let finalOrder = order.data();

    return {
      order: finalOrder,
      error: null,
    };
  } catch (error) {
    console.log(error);
    return {
      order: null,
      error: error,
    };
  }
};
export const fetchCompanies = async () =>
  (await db.collection("companies").get()).docs.map((element) =>
    element.data()
  );
export const getReportOrders = async (
  branchId,
  companyId,
  startDate,
  endDate,
  orderStatusFilter,
  //TODO
  startAt = 2
) => {
  // console.log(`startDate: ${startDate}`);
  // console.log(`endDate: ${endDate}`);

  let query = await db
    .collection(collection)
    .where("createdAt", ">", startDate)
    .where("createdAt", "<", endDate);

  if (!isEmpty(companyId)) {
    query = query.where("company.id", "==", companyId);
  }
  if (!isEmpty(branchId)) {
    query = query.where("branch.id", "==", branchId);
  }
  if (!isEmpty(orderStatusFilter)) {
    query = query.where("status", "==", orderStatusFilter);
  }

  const orders = await query
    .orderBy("createdAt", "desc")
    .startAt([2, 4])
    .get();

  let ordersArray = [];
  await orders.docs.forEach((delivery) => {
    const orderToValidate = delivery.data();
    if (
      !isEmpty(orderToValidate.createdAt) &&
      orderToValidate.createdAt.seconds !== undefined
    ) {
      const orderTimestamp = new Date(
        orderToValidate.createdAt.seconds * 1000
      ).getTime();
      if (
        orderTimestamp > startDate.getTime() &&
        orderTimestamp < endDate.getTime()
      ) {
        let meanwhile = delivery.data();
        meanwhile["id"] = delivery.id;
        if (isEmpty(meanwhile.alias)) {
          meanwhile.alias = {
            name: undefined,
            id: undefined,
          };
        }

        ordersArray = [...ordersArray, meanwhile];
      }
    }
  });
  return ordersArray;
};

export const unSubscribeOrders = async () => {
  db.collection(collection).onSnapshot(() => {});
};
export const getOrderLink = async (id) => {
  try {
    await db
      .collection(collection)
      .doc(id)
      .onSnapshot((snap) => {
        if (snap.exists) {
          const data = { id: snap.id, ...snap.data() };
          store.dispatch(actions.completedFetchingLink(data));
        } else {
          store.dispatch(actions.completedFetchingLink(null));
        }
      });
  } catch (error) {
    console.log("error");
  }
};

export const isUpdateLink = async (id, order) => {
  await db
    .collection("RatedByCustomer")
    .doc(uuidV4())
    .set({
      orderId: id,
      calification: order.Calification,
      feedbackBiker: order.value,
    });

  // return getOrderLink(id);
};

export const getOrderResume = async (startDate, endDate) => {
  return await db
    .collection(collection)
    .where("createdAt", ">", startDate)
    .where("createdAt", "<", endDate)
    // .where("status", "==", orderStatus.DELIVERED)
    .orderBy("createdAt", "desc")
    .startAt([2, 4]);
};

export const getCompanyResume = async () => {
  return await db.collection("companies");
}

export const getBikers = async () => {
  return await db.collection("bikers");
}

export const getBranch = async () => {
  return await db.collection("branches");
}

export const getStatusBikers = async () => {
  return await db.collection("status");
}