import React from "react";
import isEmpty from "lodash/isEmpty";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

export default ({ field, form, ...props }) => {
  const { touched, errors } = form;
  return (
    <div
      className={`text-field vertical-top ${props.fullWidth && "full-width"} ${
        props.cssClasses
      }`}
    >
      <FormControl variant="outlined" className="full-width">
        <InputLabel labelId={`select-${field.name}`}>{props.label}</InputLabel>
        <Select
          labelId={`select-${field.name}`}
          {...field}
          {...props}
          label={props.label}
          onChange={(e) => {
            props.formikProps.setFieldValue(field.name, e.target.value);
            if (props.setState) {
              props.setState((val) => ({
                ...val,
                [field.name]: e.target.value,
              }));
            }
          }}
        >
          <MenuItem value="">Ninguno</MenuItem>
          {props.options.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {touched && !isEmpty(errors) && !isEmpty(errors[field.name]) && (
        <div className="text-field-errors">
          {Object.keys(errors)
            .filter((val) => val === field.name)
            .map((err) => (
              <div key={err} className="text-field-error">
                {errors[err]}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
