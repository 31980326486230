import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../Design/DataTable";
import * as pilotActions from "../../logic/actions/pilots";
import Modal from "../Design/Modal";
import Button from "@material-ui/core/Button";
import AlertModal from "./AlertModal";
import AdminButton from "../Design/AdminButton";
import * as selectors from "../../logic/reducers";
import { bikerAlert as bikerAlertConst } from "../../constants/constants";
import bikerHelper from "../../helpers/bikerHelper";

const AlertList = () => {
  const dispatch = useDispatch();
  const bikers = useSelector(selectors.getPilots);
  const [bikerAlert, setBikerAlert] = useState({
    biker: {},
    alert: {},
    isOpened: false,
  });

  useOnLoad(dispatch);

  const renderView = () => {
    return (
      <div className="page-container">
        <div className="container-body">
          <AdminButton>
            <Button
              size="small"
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => {
                dispatch(pilotActions.pilotActions.editBikerAlertClear());
                setBikerAlert({
                  biker: {},
                  alert: {},
                  isOpened: true,
                });
              }}
            >
              Agregar alerta
            </Button>
          </AdminButton>
          <br />
          <DataTable
            usePagination
            align="left"
            headers={[
              {
                id: "name",
                name: "2Wheeler",
                style: {
                  width: "20%",
                },
              },
              {
                id: "alert",
                name: "Alerta",
                style: {
                  width: "20%",
                },
              },
              {
                id: "company",
                name: "Compañía",
                style: {
                  width: "20%",
                },
              },
              {
                id: "SUCURSAL",
                name: "Sucursal",
                style: {
                  width: "20%",
                },
              },
              {
                id: "modify",
                name: "",
                style: {
                  width: "10%",
                },
              },
              {
                id: "delete",
                name: "",
                style: {
                  width: "10%",
                },
              },
            ]}
            rows={bikerHelper
              .getBikersWithAlerts(bikers || [])
              .map((biker) => ({
                ...biker,
                modify: (
                  <div className="company-branches-links">
                    <div
                      onClick={() => {
                        dispatch(
                          pilotActions.pilotActions.editBikerAlertClear()
                        );
                        setBikerAlert({
                          biker: {
                            value: biker.id,
                            text: biker.name,
                          },
                          alert: {
                            value: biker.alert,
                            text: biker.alert,
                          },
                          isOpened: true,
                        });
                      }}
                    >
                      Modificar
                    </div>
                  </div>
                ),
                delete: (
                  <div className="company-branches-links">
                    <div
                      onClick={() => {
                        dispatch(
                          pilotActions.pilotActions.editBikerAlert(
                            biker.id,
                            bikerAlertConst.ALL_OK
                          )
                        );
                      }}
                    >
                      Eliminar
                    </div>
                  </div>
                ),
              }))}
          />
        </div>
      </div>
    );
  };

  const onModalClose = () => {
    setBikerAlert({
      biker: {},
      alert: {},
      isOpened: false,
    });
  };

  return (
    <div>
      {bikerAlert.isOpened && (
        <Modal
          isOpened={bikerAlert.isOpened}
          onClose={onModalClose}
          body={
            <AlertModal
              onModalClose={onModalClose}
              bikerAlert={bikerAlert}
              bikers={bikers}
            />
          }
        />
      )}
      {renderView()}
    </div>
  );
};

const useOnLoad = (dispatch) => {
  useEffect(() => {
    const startedFilters = {
      comodines: true,
    };
    dispatch(pilotActions.clearPilotsSubscription());
    dispatch(pilotActions.startFetchingPilots(startedFilters));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default AlertList;
