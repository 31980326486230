/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles.css";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LinearProgress from "@material-ui/core/LinearProgress";

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#f26724",
  },
}))(LinearProgress);

const Stepped = ({ orders_data }) => {
  const states = Object.keys(orders_data.stateTime).sort((a, b) => {
    const timeA = orders_data.stateTime[a];
    const timeB = orders_data.stateTime[b];
    const timestampA = timeA.seconds * 1000 + timeA.nanoseconds / 1000000;
    const timestampB = timeB.seconds * 1000 + timeB.nanoseconds / 1000000;
    return timestampA - timestampB;
  });

  const status = orders_data.status;
  const orderId = orders_data.orderId;
  const [activateStep, setActivateStep] = useState(
    states.indexOf(status) !== -1 ? states.indexOf(status) : 0
  );
  const [loadingProgress, setLoadingProgress] = useState(0);
  const filteredStates = states.filter((state) =>
    ["En cola", "Asignado", "En ruta"].includes(state)
  );

  const formatTime = (timestamp) => {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;

    const combinedMilliseconds = seconds * 1000 + nanoseconds / 1000000;
    const formattedDate = moment(combinedMilliseconds).format("hh:mm A");

    return formattedDate;
  };

  const formatDate = (timestamp) => {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;

    const combinedMilliseconds = seconds * 1000 + nanoseconds / 1000000;
    const formattedDate = moment(combinedMilliseconds).format("L");

    return formattedDate;
  };

  // para cargar la barra de progreso
  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingProgress((prevProgress) =>
        prevProgress >= activateStep * (100 / states.length)
          ? prevProgress
          : prevProgress + 10
      );
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [activateStep, states.length]);

  const getStepContent = (state, index) => {
    const isCompleted = index < activateStep;

    return (
      <Step key={state} completed={isCompleted}>
        <StepLabel
          StepIconProps={{
            icon:
              state !== status ? (
                <CheckBoxIcon />
              ) : (
                <div className="spinner-box-custom">
                  <div className="pulse-container-custom">
                    <div className="pulse-bubble-custom pulse-bubble-1-custom"></div>
                    <div className="pulse-bubble-custom pulse-bubble-2-custom"></div>
                    <div className="pulse-bubble-custom pulse-bubble-3-custom"></div>
                  </div>
                </div>
              ),
          }}
          style={{
            border: "1px solid #ddd",
            borderRadius: "5px",
            padding: "10px",
            boxShadow: isCompleted ? "0px 1px 3px rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="subtitle1">{state}</Typography>
              <Typography variant="body2">
                {formatDate(orders_data.stateTime[state])}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {formatTime(orders_data.stateTime[state])}
              </Typography>
            </div>
          </div>
        </StepLabel>
      </Step>
    );
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "2rem" }}>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "0.5rem",
            }}
          >
            SEGUIMIENTO DE ORDEN
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ textTransform: "uppercase" }}
          >
            Mantente informado sobre el estado de tu pedido
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem",
            textAlign: "center",
          }}
        >
          <div style={{ marginBottom: "1rem" }}>
            <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                Empresa:
              </span>
              <span style={{ fontWeight: "normal" }}>
                {orders_data.company.name}
              </span>
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
              <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                Número de Orden:
              </span>
              <span style={{ fontWeight: "normal" }}>{orderId}</span>
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: status === "En ruta" ? "#f26724" : "#20419a",
              padding: "0.5rem",
              borderRadius: "5px",
              color: "#fff",
              marginBottom: "1rem",
            }}
          >
            {status}
          </div>
        </div>
        <CustomLinearProgress
          variant="determinate"
          // value={activateStep * (100 / states.length)}
          value={loadingProgress}
          style={{ marginTop: "1.5rem" }}
        />
        <Stepper activeStep={activateStep} orientation="vertical">
          {filteredStates.map((state, index) => getStepContent(state, index))}
        </Stepper>
      </Paper>
    </Container>
  );
};

export default Stepped;
