import React, { Fragment, useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { orderStatus } from "../../constants/constants";
import Grid from "@material-ui/core/Grid";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-material.css";
// import "devextreme/dist/css/dx.material.orange.light.compact.css";
import { ROUTES } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/orders";
import theme from "../../assets/theme";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { Paper, Box } from "@material-ui/core";
import { DELIVERED_ORDERS_COLUMNS } from "../../constants/tableConstants";
import SingleTableView from "../../containers/tables/singleTableView";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ResponsiveOrderList from "../AllOrders/ResponsiveOrderList";
import { abandonedOrdersFilterActions } from "../../logic/actions/filters";
import { getAbandonedOrdersFilters } from "../../logic/selectors/filters";

const AbandonedOrders = ({ historyOrders, onLoad, clearOrderEdition }) => {
  const history = useHistory();
  const uiTheme = useTheme();
  const [avgTime, setAvgTime] = useState(0);
  const [orders, setOrders] = useState([]);

  const dispatch = useDispatch();
  const savedFilterModel = useSelector(getAbandonedOrdersFilters);
  const [filterModel, setFilterModel] = useState([]);
  const [filterModelToUse, setFilterModelToUse] = useState([]);
  const [isFilterConfigured, setIsFilterConfigured] = useState(false);

  const matches = useMediaQuery(uiTheme.breakpoints.down("xs"));
  useEffect(onLoad, []);

  useEffect(() => {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(1);
    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);
    const abandoned = (historyOrders || []).filter(
      (order) =>  
      {
        const orderTimestamp = new Date(
          order.createdAt.seconds * 1000
        ).getTime()
        return  !isNaN(parseInt(order.time)) &&
        parseInt(order.time) / 3600 >= 2 &&
        order.status !== orderStatus.DELIVERED && orderTimestamp > startDay.getTime() &&
        orderTimestamp < endDay.getTime(); 
      }
    );
    setOrders(abandoned);
  }, [historyOrders]);

  const selectedRow = (event) => {
    if (event.row.id !== undefined) {
      clearOrderEdition();
      history.push(
        `${ROUTES.EDIT_ORDER}/${event.row.id}?delivered=true&state=forgotten`
      );
    }
  };

  const onFilterChange = (filterModelItems) => {
    let avg = 0;
    if (!isEmpty(filterModelItems)) {
      let filteredOrders = [...orders];
      filterModelItems.forEach((filterModel) => {
        if (!isEmpty(filterModel.value)) {
          filteredOrders = filteredOrders.filter((order) => {
            if (filterModel.operatorValue === "contains") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .includes((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "startsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .startsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "endsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .endsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "equals") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .localeCompare((filterModel.value || "").toLowerCase());
            }
            return true;
          });
        }
      });
      avg =
        (filteredOrders || []).reduce((acc, current) => acc + current.time, 0) /
        filteredOrders.length;
    } else {
      avg =
        (orders || []).reduce((acc, current) => acc + current.time, 0) /
        orders.length;
    }
    if (!isEmpty(filterModelItems)) {
      if (!isEmpty(filterModel)) {
        setFilterModel(undefined);
      } else {
        setFilterModelToUse(filterModelItems);
      }
    }
    setAvgTime(avg);
  };

  useEffect(() => {
    const avg =
      (orders || []).reduce((acc, current) => acc + current.time, 0) /
      orders.length;
    setAvgTime(avg);
  }, [orders]);

  useEffect(() => {
    if (!isFilterConfigured) {
      if (!isEmpty(savedFilterModel)) {
        setFilterModel(savedFilterModel);
        setFilterModelToUse(savedFilterModel);
        setIsFilterConfigured(true);
      }
    }
  }, [savedFilterModel, isFilterConfigured]);

  return (
    <Fragment>
      <Prompt
        message={(location, action) => {
          dispatch(
            abandonedOrdersFilterActions.onFilterSave(
              filterModelToUse.map((filter) => ({
                columnField: filter.columnField,
                operatorValue: filter.operatorValue,
                value: filter.value,
              }))
            )
          );
          return true;
        }}
      />
      <ThemeProvider theme={theme}>
        {/* <NavBar title="ÓRDENES ENTREGADAS" /> */}
        <BreadCrumb
          match={{ path: history.location.pathname }}
          title="ORDENES ABANDONADAS"
        />

        <div className="container">
          <div className="container-body">
            <Grid container>
              <Grid item xs={12}>
                <Paper>
                  <Box padding={1}>
                    {!matches && (
                      <SingleTableView
                        rows={orders.map((order) => ({
                          ...order,
                          createdDate: !isEmpty(order.createdAt) ? 
                            new Date(order.createdAt.seconds*1000)
                          : "", 
                        }))}
                        onCellClick={selectedRow}
                        onFilterChange={onFilterChange}
                        columns={DELIVERED_ORDERS_COLUMNS}
                        filterModel={
                          !isEmpty(filterModel)
                            ? { items: [...filterModel] }
                            : undefined
                        }
                      />
                    )}
                    {matches && (
                      <ResponsiveOrderList
                        orders={orders.map((order) => ({
                          ...order,
                          createdDate: !isEmpty(order.createdAt)
                            ? `${order.createdAt.seconds}`
                            : "",
                        }))}
                        onClick={selectedRow}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <div className="table-footer">
              <div>
                <Grid container>
                  <Grid item xs={3}>
                    <small>{`Ordenes Abandonadas: ${orders.length}`}</small>
                  </Grid>
                  <Grid item xs={3}>
                    <small>{`Tiempo promedio de entrega: ${avgTime.toFixed(
                      2
                    )} min`}</small>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: selectors.getIsAuthenticated(state),
    isLoading: selectors.getIsFetchingOrders(state),
    historyOrders: selectors.getHistoryOrders(state),
  }),
  (dispatch) => ({
    onLoad() {
      dispatch(
        actions.startFetchingHistoryOrders({
          statusFilter: [orderStatus.DELIVERED, orderStatus.CANCELLED],
          allOrders: true,
        })
      );
    },
    clearOrderEdition() {
      dispatch(actions.orderActions.clearOrderEdition());
    },
  })
)(AbandonedOrders);
