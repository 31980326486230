/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const NavBar = ({ imageLogo }) => {
  const sizeLogo = imageLogo?.sizeLogo;
  const logo =
    imageLogo?.logo ||
    "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/companies%2F61cf1f78-4ef0-4062-a8c1-4f44aa6afeac?alt=media&token=43348534-3399-45d7-883e-b784eba46ec3";
  // const color = imageLogo?.logoColors || "#20419a";
  const color = "#FFFFFF";

  return (
    <>
      <AppBar
        position="fixed"
        style={{ backgroundColor: color, height: "64px" }}
      >
        <Toolbar>
          <img
            src={logo}
            // style={{ width: "100px", height: "auto" }}
            style={{ width: "50px", height: "auto", marginRight: "0px" }}
            alt="logotype"
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({})
)(NavBar);
