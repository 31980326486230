import React, { useEffect } from "react";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/company";
import * as selectors from "../../logic/reducers";
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DataGrid, {
  Column,
  Grouping,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  Export,
  FilterPanel,
  TotalItem,
  Summary,
  Selection,
  HeaderFilter,
  FilterRow,
  Scrolling
} from "devextreme-react/data-grid";
import { CheckBox } from 'devextreme-react/check-box';
const SummaryCompany = ({ companies, gettingCompanies }) => {
  useEffect(() => {
    gettingCompanies();
  }, [gettingCompanies]);

  const pageSizes = [10, 25, 50, 100];

  // console.log(`init: ${init}`);
  // console.log(`account: ${companies}`);
  // console.log(`error: ${failed}`);

  const companyLogo = ({ data }) => {
    // console.log(data);
    return <img src={data.data.logo} alt="logo" width="100px" height="auto" />;
  };

  const emailRender = ({data}) => {
    if(data.data.email){
      return <CheckBox defaultValue={true}/>
    }else{
      return <CheckBox defaultValue={false}/>

    }
  }
  // let dictonary = {};
  // if(companies){
  //   companies.forEach((e)=>{
  //     if(dictonary[e.data.name]){
  //       dictonary[e.data.name]+=1;
  //     }else {
  //       dictonary[e.data.name]+=1
  //     }
  //   })
  // }

  return (
    <>
      {/* <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={companies}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data.name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer> */}
      <div>
        <BreadCrumb match={{ path: "/" }} title=" CANTIDAD DE COMPANIAS" />
      </div>
      <DataGrid
        keyExpr="id"
        dataSource={companies}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        noDataText="NO HAY INFORMACIÓN"
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <HeaderFilter visible={true} />
        <Grouping autoExpandAll={false} />
        <Pager
          showPageSizeSelector={true}
          displayMode={true}
          allowedPageSizes={pageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Paging defaultPageSize={10} />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="data.name"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="companies"
        />
        <Selection mode="single" />
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

        <Column dataField="data.name" caption="Nombre" />
        <Column dataField="data.logo" caption="Logo" cellRender={companyLogo} />
        <Column dataField="data.description" caption="Descripcion" />
        <Column dataField="data.email" caption="Correo" cellRender={emailRender} />
        <Column dataField="data.sms" caption="SMS" />

        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    
    </>
  );
};

const { gettingCompany, getCompany, gettingCompanyFailed } = selectors;

export default connect(
  (state) => ({
    init: gettingCompany(state),
    companies: getCompany(state),
    failed: gettingCompanyFailed(state),
  }),
  (dispatch) => ({
    gettingCompanies() {
      dispatch(actions.getCompaniesSummaryStarted());
    },
  })
)(SummaryCompany);
