import React from "react";
import Expandable from "../Design/Expandable";
import Card from "../Design/Card";
import { isEmpty } from "lodash";

const getRandomColor = () => {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const TopCompanies = ({ orders, top = 10, useExpandable = true }) => {
  const renderTop = () => {
    let companies = [];
    orders.forEach((order) => {
      if (!isEmpty(order) && !isEmpty(order.company)) {
        const companyIndex = companies.findIndex(
          (company) => company.id === order.company.id
        );
        if (companyIndex > -1) {
          companies[companyIndex] = {
            ...companies[companyIndex],
            quantity: companies[companyIndex].quantity + 1,
          };
        } else {
          companies = [
            ...companies,
            {
              id: order.company.id,
              name: order.company.name,
              quantity: 1,
            },
          ];
        }
      }
    });
    return companies
      .sort((a, b) => {
        if (a.quantity > b.quantity) {
          return -1;
        }
        if (a.quantity < b.quantity) {
          return 1;
        }
        return 0;
      })
      .slice(0, top)
      .map((company, i) => (
        <div
          className="card-container card-container-5 no-border-bottom"
          style={{ background: getRandomColor() }}
          key={company.id}
        >
          <Card
            bodyCssClases="sumary-body text-center"
            titleCssClases="text-center padding-top-sm padding-bottom-sm"
            cssClases="no-padding"
            noPadding
            title={
              <div className="uppercase margin-bottom-md bold">{`${i + 1}: ${
                company.name
              }`}</div>
            }
            body={`Ordenes Entregadas: ${company.quantity}`}
          />
        </div>
      ));
  };

  const renderView = () => {
    if (useExpandable) {
      return (
        <Expandable title={`Top ${top} - Empresas`}>{renderTop()}</Expandable>
      );
    }
    return renderTop();
  };

  return <>{renderView()}</>;
};

export default TopCompanies;
