import axios from "axios";
import { API_URL } from "../../constants/constants";
import { firebase } from "../../firebase/config";

const getToken = async () => {
  return (
    await axios.post(
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAEPbEe8MPq4XT4ZJ9NTLDEw4QtRIBSC2k",
      {
        email: "controlador@mrb.gt",
        password: "MRB2020",
        returnSecureToken: true,
      }
    )
  ).data;
};

const changeBikerInOrder = async (orderId, bikerId, token) => {
  return (
    await axios.patch(
      `${API_URL}app/changebiker/orders/${orderId}`,
      {
        uid: bikerId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data;
};

const createOrder = async (order) => {
  let url = `${API_URL}api/deliveries`;
  if (order.without_biker) {
    url = `${url}?notify_biker=false&without_biker=true&calculate_circle=true`;
    delete order.without_biker;
  } else {
    url = `${url}?notify_biker=true`;
  }
  return (await axios.post(url, order)).data;
};

const editOrder = async (id, order, notifyBiker = false) => {
  // console.log( ` LOG: order Edit ${JSON.stringify(order)}`)
  delete order.companyId;
  delete order.locationEnd;
  return (
    await axios.patch(
      `${API_URL}api/deliveries/${id}?notify_biker=${notifyBiker}&calculate_circle=true`,
      order
    )
  ).data;
};

const editUsersInFormOrder = async (
  orderId,
  userId,
  userName,
  expiresAt,
  shouldDelete = false
) => {
  return (
    await axios.patch(
      `${API_URL}api/deliveries/${orderId}/editing?delete=${shouldDelete}&calculate_circle=false`,
      {
        modifyingBy: shouldDelete ? {} : {
          id: userId,
          name: userName,
          expiresAt,
        },
      }
    )
  ).data;
};

const uploadCSVFile = async (file, token) => {
  let bodyFormData = new FormData();
  bodyFormData.append("file", file);
  const res = await axios.post(
    `${API_URL}api/deliveries/upload-file`,
    bodyFormData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data.orders;
};

const gettingOrders = async () => {
  try {
    const data = firebase.firestore().collection("deliveries");
    const getOrder = await data.orderBy("updatedAt", "desc").get();
    let array = [];
    getOrder.forEach((doc) => {
      let info = doc.data();
      array.push({ 
        id: doc.id, 
        data: info 
      });
    });
    if (array.length > 0) {
      return {
        data: array,
        error: null,
      };
    }
  } catch (error) {
    console.log(error.message);
    return {
      data: null,
      error,
    };
  }
};

const orderService = {
  getToken,
  changeBikerInOrder,
  editUsersInFormOrder,
  createOrder,
  editOrder,
  uploadCSVFile,
  gettingOrders
};

export default orderService;
