/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { getBranch } from "../../../firebase/orders";
import { BIKER_ICON, DELIVERY_ICON } from "../../../constants/constants";
import mapTheme from "../../../assets/mapTheme";

const SingleMapLocation = withScriptjs(
  withGoogleMap(({ selectedBiker }) => {
    const DEFAULT_LATITUDE = 14.60845364379672;
    const DEFAULT_LONGITUDE = -90.51841937485972;

    const containerStyle = {
      width: "100%",
      height: "750px",
      position: "relative",
      marginBottom: "20px",
    };
    const [center, setCenter] = useState({
      lat: 14.60845364379672,
      lng: -90.51841937485972,
    });
    const [coordBranch, setCoordBranch] = useState([]);

    const getBranchLat = async () => {
      try {
        const querySnapshot = await getBranch();
        querySnapshot.onSnapshot((snapshot) => {
          const filteredData = snapshot.docs
            .filter((doc) => doc.data().branchId === selectedBiker.branchId)
            .map((doc) => ({ ...doc.data(), id: doc.id }));
          setCoordBranch(filteredData);
        });
      } catch (error) {
        console.error(`Error fetching bikers: ${error}`);
      }
    };

    useEffect(() => {
      if (selectedBiker && selectedBiker.actualPosition) {
        const { _lat, _long } = selectedBiker.actualPosition;
        if (_lat !== undefined && _long !== undefined) {
          setCenter({
            lat: _lat,
            lng: _long,
          });
        }
      }
      getBranchLat();
    }, [selectedBiker]);

    return (
      <div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          options={{
            styles: mapTheme,
          }}
        >
          {selectedBiker && selectedBiker.actualPosition && (
            <Marker
              key={selectedBiker.id}
              position={{
                lat: selectedBiker.actualPosition._lat || DEFAULT_LATITUDE,
                lng: selectedBiker.actualPosition._long || DEFAULT_LONGITUDE,
              }}
              icon={BIKER_ICON}
            />
          )}

          {coordBranch.length > 0 && (
            <Marker
              position={{
                lat: coordBranch[0].latitude || DEFAULT_LATITUDE,
                lng: coordBranch[0].longitude || DEFAULT_LONGITUDE,
              }}
              icon={DELIVERY_ICON}
              title={`Sucursal: ${coordBranch[0].branchName}`}
            />
          )}
        </GoogleMap>
      </div>
    );
  })
);

export default SingleMapLocation;
