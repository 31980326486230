import { combineReducers } from "redux";
import omit from "lodash/omit";
import union from "lodash/union";
import * as types from "../types/orders";
import { isEmpty } from "lodash";

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.ORDER_ADDED: {
      const { order, orderId } = action.payload;

      return {
        ...state,
        [orderId]: order,
      };
    }

    case types.ORDER_MODIFIED: {
      const { order, orderId } = action.payload;
      let actionPayload = {};

      if (action.payload.onlyTimeAlert) {
        actionPayload = {
          ...state[orderId],
          time: action.payload.order.time,
          alert: action.payload.order.alert,
        };
      } else {
        actionPayload = {
          ...order,
        };
      }

      return {
        ...state,
        [orderId]: actionPayload,
      };
    }
    case types.ORDER_REMOVED: {
      return omit(state, action.payload.orderId);
    }

    case types.CLEAR_ORDERS_SUBSCRIPTION:
    case types.FETCH_ORDERS_STARTED: {
      return {};
    }

    case types.SET_FETCH_ORDERS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default: {
      return state;
    }
  }
};
const monthlyById = (state = {}, action) => {
  switch (action.type) {
    case types.MONTHLY_ORDER_ADDED: {
      const { order, orderId } = action.payload;

      return {
        ...state,
        [orderId]: order,
      };
    }

    case types.MONTHLY_ORDER_MODIFIED: {
      const { order, orderId } = action.payload;

      return {
        ...state,
        [orderId]: order,
      };
    }

    case types.MONTHLY_ORDER_REMOVED: {
      return omit(state, action.payload.orderId);
    }

    case types.CLEAR_ORDERS_SUBSCRIPTION:
    case types.FETCH_ORDERS_STARTED: {
      return {};
    }

    case types.SET_FETCH_ORDERS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default: {
      return state;
    }
  }
};
const monthlyOrder = (state = [], action) => {
  switch (action.type) {
    case types.MONTHLY_ORDER_ADDED: {
      return [...state, action.payload.orderId];
    }

    case types.MONTHLY_ORDER_REMOVED: {
      return state.filter((id) => id !== action.payload.orderId);
    }

    case types.CLEAR_ORDERS_SUBSCRIPTION:
    case types.FETCH_ORDERS_STARTED: {
      return [];
    }

    default: {
      return state;
    }
  }
};
const order = (state = [], action) => {
  switch (action.type) {
    case types.ORDER_ADDED: {
      return [...state, action.payload.orderId];
    }

    case types.ORDER_REMOVED: {
      return state.filter((id) => id !== action.payload.orderId);
    }

    case types.CLEAR_ORDERS_SUBSCRIPTION:
    case types.FETCH_ORDERS_STARTED: {
      return [];
    }

    default: {
      return state;
    }
  }
};

const historyById = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_HISTORY_ORDERS_COMPLETED: {
      const { entities, order } = action.payload;

      const newState = { ...state };
      order.forEach((id) => {
        newState[id] = {
          ...entities[id],
        };
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};

const historyOrder = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_HISTORY_ORDERS_COMPLETED: {
      return union(action.payload.order);
    }

    default: {
      return state;
    }
  }
};

const orderChanges = (state = [], action) => {
  switch (action.type) {
    case types.ORDER_ADDED: {
      const actionType = action.type;
      const actionPayload = action.payload.order;

      const changesPollo = {
        [actionType]: actionPayload,
      };

      return [...state, changesPollo];
    }

    case types.ORDER_MODIFIED: {
      const actionType = action.type;
      const actionPayload = action.payload.order;

      const changesPollo = {
        [actionType]: actionPayload,
      };

      return [...state, changesPollo];
    }

    case types.ORDER_REMOVED: {
      const actionType = action.type;
      const actionPayload = action.payload.order;

      const changesPollo = {
        [actionType]: actionPayload,
      };

      return [...state, changesPollo];
    }

    case types.ORDER_CHANGE_REMOVED: {
      // console.log(action.payload)
      return state.filter((change) => change !== action.payload.change);
    }

    default: {
      return state;
    }
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case types.ORDER_SELECTED: {
      return action.payload.order;
    }

    case types.ORDER_DESELECTED: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_HISTORY_ORDERS_STARTED: {
      return true;
    }

    case types.FETCH_HISTORY_ORDERS_COMPLETED: {
      return false;
    }

    case types.FETCH_HISTORY_ORDERS_FAILED: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_FAILED: {
      return action.payload;
    }

    case types.FETCH_HISTORY_ORDERS_FAILED: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

const initialState = {
  order: undefined,
  error: undefined,
  isLoading: false,
};

const orderByOrderId = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_ORDER_EDITION: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        order: undefined,
      };
    }
    case types.GET_ORDER_BY_ID: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        order: undefined,
      };
    }
    case types.GET_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        order: action.payload.response,
      };
    }
    case types.GET_ORDER_BY_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const updateOrderInitialState = {
  isLoading: false,
  error: undefined,
  done: false,
};

const updateOrder = (state = updateOrderInitialState, action) => {
  switch (action.type) {
    case types.CLEAR_ORDER_EDITION: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        done: false,
      };
    }
    case types.EDIT_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        done: false,
      };
    }
    case types.EDIT_ORDER_COMPLETED: {
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    }
    case types.EDIT_ORDER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const ordersByBikerIdInitialState = {
  error: undefined,
  orders: [],
};

const ordersByBikerId = (state = ordersByBikerIdInitialState, action) => {
  switch (action.type) {
    case types.SUBSCRIBE_ORDRES_BY_PILOT_ID:
    case types.CLEAR_ORDERS_BY_PILOT_ID: {
      return {
        ...state,
        orders: [],
      };
    }
    case types.SUBSCRIBE_ORDRES_BY_PILOT_ID_CHANGE: {
      return {
        ...state,
        orders: [...action.payload.orders],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const tokenInitialState = {
  error: undefined,
  token: undefined,
};

const token = (state = tokenInitialState, action) => {
  switch (action.type) {
    case types.SET_API_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
        error: undefined,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const deleteOrderInitialState = {
  error: undefined,
  isLoading: false,
  done: false,
};

const deleteOrder = (state = deleteOrderInitialState, action) => {
  switch (action.type) {
    case types.CLEAR_ORDER_EDITION: {
      return {
        ...state,
        isLoading: false,
        done: false,
        error: undefined,
      };
    }
    case types.DELETE_ORDER: {
      return {
        ...state,
        isLoading: true,
        done: false,
        error: undefined,
      };
    }
    case types.DELETE_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    }
    case types.DELETE_ORDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
const singleOrder = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_SINGLE_ORDER_COMPLETED: {
      return action.payload.order;
    }
    default: {
      return state;
    }
  }
};

const isFetchingSingleOrder = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SINGLE_ORDER_STARTED: {
      return true;
    }
    case types.FETCH_SINGLE_ORDER_COMPLETED: {
      return false;
    }
    case types.FETCH_SINGLE_ORDER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};
const createOrderInitialState = {
  error: undefined,
  isLoading: false,
  done: false,
};

const createOrder = (state = createOrderInitialState, action) => {
  switch (action.type) {
    case types.CLEAR_CREATE_ORDER: {
      return {
        ...state,
        ...createOrderInitialState,
      };
    }
    case types.CREATE_ORDER: {
      return {
        ...state,
        isLoading: true,
        done: false,
        error: undefined,
      };
    }
    case types.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    }
    case types.CREATE_ORDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const getOrdersWithTimeByBikerIdInitialState = {
  orders: [],
  isFetching: false,
};

const getOrdersWithTimeByBikerId = (
  state = getOrdersWithTimeByBikerIdInitialState,
  action
) => {
  switch (action.type) {
    case types.GET_ORDRES_WITH_TIME_BY_PILOT_ID: {
      return {
        ...state,
        orders: [],
        // isFetching: true,
      };
    }
    case types.CLEAR_ORDERS_BY_PILOT_ID: {
      return {
        ...state,
        orders: [],
      };
    }
    case types.GET_ORDRES_WITH_TIME_BY_PILOT_ID_SUCCESS: {
      return {
        ...state,
        orders: action.payload.orders,
        isFetching: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
const initialOrdersLink = (state = false, action) => {
  switch (action.type) {
    case types.GET_ORDER_LINK_STARTED: {
      return true;
    }
    case types.GET_ORDER_LINK_COMPLETED: {
      return false;
    }
    case types.GET_ORDER_LINK_FAILED: {
      return false;
    }
    default: {
      return {
        state,
      };
    }
  }
};

const completedOrdersLink = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_LINK_COMPLETED: {
      return action.payload.order;
    }
    case types.UPDATE_ORDER_LINK_COMPLETED: {
      return action.payload.order;
    }
    default: {
      return state;
    }
  }
};

const errorOrdersLink = (state = false, action) => {
  switch (action.type) {
    case types.GET_ORDER_LINK_STARTED: {
      return false;
    }
    case types.GET_ORDER_LINK_COMPLETED: {
      return false;
    }
    case types.GET_ORDER_LINK_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const isFetchingOrderLinkTimeStarted = (state = false, action) => {
  switch (action.type) {
    case types.GET_ORDER_WITH_TIME_STARTED: {
      return true;
    }
    case types.GET_ORDER_WITH_TIME_COMPLETED: {
      return false;
    }

    case types.GET_ORDER_WITH_TIME_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingOrderLinkTimeCompleted = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_WITH_TIME_COMPLETED: {
      return action.payload.bikerTime;
    }
    default: {
      return state;
    }
  }
};

const isFetchingOrderLinkTimeFailed = (state = false, action) => {
  switch (action.type) {
    case types.GET_ORDER_WITH_TIME_STARTED: {
      return false;
    }
    case types.GET_ORDER_WITH_TIME_COMPLETED: {
      return false;
    }
    case types.GET_ORDER_WITH_TIME_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

/*
  ALL ORDERS
    GET_ORDER_ALL_ORDER_STARTED
    GET_ORDER_ALL_ORDER_COMPLETED
    GET_ORDER_ALL_ORDER_FAILED
 */

const gettingAllOrders = (state = false, action) => {
  switch (action.type) {
    case types.GET_ORDER_ALL_ORDER_STARTED: {
      return true;
    }
    case types.GET_ORDER_ALL_ORDER_COMPLETED: {
      return false;
    }
    case types.GET_ORDER_ALL_ORDER_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getAllOrders = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_ALL_ORDER_COMPLETED: {
      return action.payload.orders;
    }
    default: {
      return state;
    }
  }
};

const gettingAllOrdersFail = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_ALL_ORDER_FAILED: {
      return action.payload.e;
    }
    default: {
      return state;
    }
  }
};

const orders = combineReducers({
  getOrdersWithTimeByBikerId,
  deleteOrder,
  token,
  byId,
  order,
  historyById,
  historyOrder,
  orderChanges,
  selected,
  isFetching,
  error,
  orderByOrderId,
  updateOrder,
  ordersByBikerId,
  singleOrder,
  isFetchingSingleOrder,
  createOrder,
  monthlyById,
  monthlyOrder,
  initialOrdersLink,
  completedOrdersLink,
  errorOrdersLink,
  isFetchingOrderLinkTimeStarted,
  isFetchingOrderLinkTimeCompleted,
  isFetchingOrderLinkTimeFailed,
  gettingAllOrders,
  getAllOrders,
  gettingAllOrdersFail
});

export default orders;

const compare = (a, b) => {
  if (isEmpty(a.createdAt)) {
    return 0;
  }
  if (a.createdAt.seconds > b.createdAt.seconds) {
    return -1;
  }
  if (a.createdAt.seconds < b.createdAt.seconds) {
    return 1;
  }
  return 0;
};

//  SELECTORS
export const getOrder = (state, id) => state.byId[id];
export const getOrders = (state) => {
  const response = state.order.map((id) => {
    const order = getOrder(state, id);
    return {
      ...order,
      time: `${order.time}`,
    };
  });
  return response.sort(compare);
};
export const getHistoryOrder = (state, id) => state.historyById[id];
export const getHistoryOrders = (state) =>
  state.historyOrder.map((id) => getHistoryOrder(state, id));
export const getOrderChanges = (state) => state.orderChanges;
export const getSelectedOrder = (state) => state.selected;
export const getIsFetching = (state) => state.isFetching;

export const getIsSubscribeLoading = (state) => state.byId.isLoading;
export const getSingleOrder = (state) => state.singleOrder;
export const getIsFetchingSinlgeOrder = (state) => state.isFetchingSingleOrder;

export const getIsFetchingOrderLInkStarted = (state) => state.initialOrdersLink;
export const getIsFetchingOrderLinkCompleted = (state) =>
  state.completedOrdersLink;
export const getIsFetchingOrderLinkError = (state) => state.errorOrdersLink;

export const updateIsFetchingOrderLinkStarted = (state) =>
  state.isFetchinStartedLink;
export const updateIsFetchingOrderLinkError = (state) =>
  state.isFetchinErrorLink;

export const getIsFetchingLinkOrderTimeStarted = (state) =>
  state.isFetchingOrderLinkTimeStarted;
export const getIsFetchingLinkOrderTimeCompleted = (state) =>
  state.isFetchingOrderLinkTimeCompleted;
export const getIsFetchingLinkOrderTimeFailed = (state) =>
  state.isFetchingOrderLinkTimeFailed;
 
/*
  gettingAllOrders
  getAllOrders
  gettingAllOrdersFail
*/

export const gettingAllOrdersStart = (state) => state.gettingAllOrders;
export const getAllOrdersComplete = (state) => state.getAllOrders;
export const gettingAllOrdersFailed = (state) => state.gettingAllOrdersFail;
