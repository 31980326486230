import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Circle,
  useLoadScript,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY, ROBBERY_IMG } from "../../constants/constants";
import { extorsionAlert, getZone, thieftAlert } from "../../firebase/zone";
// import mapTheme from "../../assets/mapTheme";
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";

const MapContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "750px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "300px",
  },
}));

const OverlayContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  width: "300px",
  maxHeight: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  overflowY: "auto",
  zIndex: 10,
  boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
  [theme.breakpoints.down("md")]: {
    width: "200px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));

const options = {
  fillColor: "#FF7F7F",
  fillOpacity: 0.4,
  strokeColor: "#FF7F7F",
  strokeOpacity: 0.7,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const MapRequest = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const [zones, setZones] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 14.63185284740086,
    lng: -90.51007596643181,
  });

  const dataZone = async () => {
    const response = await getZone();
    const zonesData = response.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    zonesData.forEach((zone) => {
      if (isNaN(zone.lat) || isNaN(zone.lng)) {
        if (typeof zone.lat === "string" || typeof zone.lng === "string") {
          console.error(
            "Datos inválidos de Firestore (lat o lng es string): ",
            zone
          );
        } else {
          console.error("Datos inválidos de Firestore: ", zone);
        }
      }
    });

    setZones(zonesData);
  };

  const dataRobo = () => {
    return new Promise((resolve, reject) => {
      try {
        thieftAlert((alerts) => {
          if (alerts.length === 0) {
            console.warn("No se encontraron alertas de robo.");
            resolve([]);
          } else {
            const roboData = alerts.map((alert) => ({
              ...alert,
              id: alert.id,
            }));
            resolve(roboData);
          }
        });
      } catch (error) {
        console.error("Error fetching theft alert data: ", error);
        reject(error);
      }
    });
  };

  const dataExtorsion = () => {
    return new Promise((resolve, reject) => {
      try {
        extorsionAlert((alerts) => {
          if (alerts.length === 0) {
            console.warn("No se encontraron alertas de extorsión.");
            resolve([]);
          } else {
            const extorsionData = alerts.map((alert) => ({
              ...alert,
              id: alert.id,
            }));
            resolve(extorsionData);
          }
        });
      } catch (error) {
        console.error("Error fetching extorsion alert data: ", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    Promise.all([dataRobo(), dataExtorsion()])
      .then((results) => {
        const combinedAlerts = [...results[0], ...results[1]];
        setAlertData(combinedAlerts);
      })
      .catch((error) => {
        console.error("Error combining alert data: ", error);
      });

    dataZone();
  }, []);

  useEffect(() => {
    if (!isEmpty(alertData)) {
      const latitude = alertData.map((accident) =>
        parseFloat(accident.position.latitude)
      );
      const longitude = alertData.map((accident) =>
        parseFloat(accident.position.longitude)
      );
      const centerLat = latitude.reduce((a, b) => a + b, 0) / latitude.length;
      const centerLng = longitude.reduce((a, b) => a + b, 0) / longitude.length;
      setMapCenter({ lat: centerLat, lng: centerLng });
    }
  }, [alertData]);

  if (loadError) {
    return <div>Error al cargar los mapas</div>;
  }

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <MapContainer>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          zoom={14}
          center={mapCenter}
          // options={{
          //   styles: mapTheme,
          // }}
        >
          {alertData.length > 0 &&
            alertData.map((accident) => (
              <Marker
                key={accident.id}
                position={{
                  lat: accident.position.latitude,
                  lng: accident.position.longitude,
                }}
                onClick={() => setSelectedAccident(accident)}
                icon={{
                  url: ROBBERY_IMG,
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
              />
            ))}
          {selectedAccident && (
            <InfoWindow
              position={{
                lat: selectedAccident.position.latitude,
                lng: selectedAccident.position.longitude,
              }}
              onCloseClick={() => setSelectedAccident(null)}
            >
              <Box>
                <Typography variant="h6">
                  {selectedAccident.description}
                </Typography>
              </Box>
            </InfoWindow>
          )}
          {zones.map((coord) => (
            <React.Fragment key={coord.id}>
              <Circle
                center={{ lat: coord.lat, lng: coord.lng }}
                radius={200}
                options={options}
              />
              {/* <Marker position={{ lat: coord.lat, lng: coord.lng }} /> */}
            </React.Fragment>
          ))}
        </GoogleMap>
        <OverlayContainer>
          <Card>
            <CardContent>
              <Typography variant="h6">Lista de Incidentes</Typography>
              <List>
                {alertData.map((accident) => (
                  <ListItem
                    key={accident.id}
                    onClick={() => {
                      setSelectedAccident(accident);
                    }}
                  >
                    <ListItemText
                      style={{
                        textTransform: "uppercase",
                      }}
                      primary={accident.description}
                      secondary={
                        <>
                          {moment(accident.time).format("YYYY-MM-DD HH:mm:ss")}
                          <br />
                          <strong>A: </strong> {accident.username}
                          <br />
                          <strong>Compañía: </strong> accident.company
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </OverlayContainer>
      </MapContainer>
    </>
  );
};

export default MapRequest;
