import React, { Fragment, useState } from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Grid,
  withStyles,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";
import Stepper from "./stepper";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import DataClient from "../../../assets/img/branch_alerts.svg";
import TiempoPromedio from "../../../assets/img/TIEMPO_PROMEDIO.svg";
import Branch from "../../../assets/img/branch.svg";
import isEmpty from "lodash/isEmpty";
import "./view.css";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 8,
    paddingTop: "100%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(200deg)",
  },
  avatar: {
    backgroundColor: orange[600],
  },
  appBar: {
    position: "relative",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RecipeReviewCard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImageOpener] = useState(false);
 

  const timeOrderFullFive = () => {
    // console.log('tiempo',props.time)
    const timeByOrder = props.time?.time;
    const getDateTimeBYOrder = new Date();
    const getById = getDateTimeBYOrder.getTime();
    const newDate = getById / 1000 + timeByOrder;
    let mometGetOrder = moment((newDate - 300) * 1000).format("h:mm a");
    return mometGetOrder;
  };
  const timeOrderFullTen = () => {
    const timeByOrder = props.time?.time;
    const getDateTimeBYOrder = new Date();
    const getById = getDateTimeBYOrder.getTime();
    const newDate = getById / 1000 + timeByOrder;
    let mometGetOrder = moment((newDate + 900) * 1000).format("h:mm a");
    return mometGetOrder;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImagenOpener = () => {
    setOpenImageOpener(true);
  };

  const handleImageClose = () => {
    setOpenImageOpener(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="row"
        alignContent="flex-end"
        justify="space-evenly"
        style={{ borderColor: "#000", minHeight: "0vh", font: "lato" }}
      >
        <Grid item xs={12} ms={6}>
          <Button
            variant="outlined"
            style={{
              width: "100vw",
              height: "60px",
              top: "0vh",
              backgroundColor: "#20419a",
              color: "#ffff",
            }}
            onClick={handleClickOpen}
            fullWidth
          >
            DETALLE DE TU ORDEN
          </Button>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <div id="d-wrapper">
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ paddingLeft: "15px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="overline">
                    {`TU ORDEN DE ${
                      isEmpty(props.data.company.name)
                        ? "SIN COMPANIA"
                        : props.data?.company.name
                    }`}
                  </Typography>
                </Toolbar>
              </AppBar>
              <List>
                <div className="zig-zag-bottom">
                  <List style={{ paddingTop: "20px" }} className={classes.root}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar
                        style={{ alignItems: "center" }}
                        className={classes.badge}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#D6EAF8",
                            borderColor: "blue",
                            width: "50px",
                            height: "50px",
                          }}
                        >
                          <img
                            src={TiempoPromedio}
                            width="40px"
                            height="auto"
                            alt = "l"
                          ></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>
                        <center>
                          <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                          >
                            TIEMPO APROXIMADO DE ARRIBO
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ opacity: 0.7, alignContent: "center" }}
                          >
                            <strong>
                              {` 
                                    ${
                                      timeOrderFullFive() === "Invalid date"
                                        ? "CALCULANDO"
                                        : timeOrderFullFive()
                                    } 
                                      - ${
                                        timeOrderFullTen() === "Invalid date"
                                          ? "CALCULANDO"
                                          : timeOrderFullTen()
                                      }
                                  `}
                            </strong>
                          </Typography>
                        </center>
                      </ListItemText>
                    </ListItem>
                  </List>
                </div>
                <Stepper />
                <div style={{ paddingTop: "15%" }}>
                  <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <div>
                          <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar>
                              <img
                                src={props.pilot?.imageDelivery}
                                width="40px"
                                height="auto"
                                alt ="pilot"
                              />
                            </Avatar>
                          </StyledBadge>
                        </div>
                      </ListItemAvatar>
                      <ListItemText
                        secondary={
                          <Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              Tu 2Wheeler:
                            </Typography>
                            {` ${
                              isEmpty(props.data?.alias.name)
                                ? "ESPERANDO PILOTO"
                                : props.data?.alias.name
                            }`}
                            <br></br>
                            {`${
                              !isEmpty(props.pilot?.transportId)
                                ? `PLACA: ${props.pilot?.transportId}`
                                : ""
                            }`}
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
                <div className="zig-zag-top">
                  <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar style={{ color: "565656" }}>
                        <Avatar
                          style={{
                            backgroundColor: "#D6EAF8",
                            borderColor: "blue",
                            width: "50px",
                            height: "50px",
                          }}
                        >
                          <img src={Branch} width="40px" height="auto" alt="branch"></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary={
                          <Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              Tu orden de:
                            </Typography>
                            {` ${
                              props.data?.company.name === undefined ||
                              props.data?.company.name === null
                                ? "SIN COMPANIA"
                                : props.data?.company.name
                            }`}
                            <br></br>
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                  <div style={{ paddingTop: "15px" }}>
                    <List>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <img
                            src={DataClient}
                            width="40px"
                            height="auto"
                            alt = "logo"
                          ></img>
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            <Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                Detalle de tu orden:<br></br>
                              </Typography>
                              {`No. Orden: 
                                ${
                                  isEmpty(props.data?.orderId) ||
                                  props.data?.orderId === "Esperando..."
                                    ? "Sin datos de la orden"
                                    : props.data?.orderId
                                }`}
                              <br></br>
                              {`${
                                isEmpty(props.data?.address) ||
                                props.data?.address === "Esperando..."
                                  ? "Sin dirección"
                                  : props.data?.address
                              }`}
                              <br></br>
                              {`${
                                isEmpty(props.data?.nota) ||
                                props.data?.nota === "..." ||
                                props.data?.nota === "Esperando..."
                                  ? "SIN OBSERVACIONES"
                                  : props.data?.nota
                              }`}
                              <br></br>
                              <strong>{`DELIVERYLAB`}</strong>
                              <br></br>
                            </Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <Divider />
                  </div>
                  <center style={{ bottom: 0, paddingTop: "10px" }}>
                    <Typography variant="body2" style={{ color: "#ABB2B9" }}>
                      Copyright © TwoWheels2go
                    </Typography>
                  </center>
                </div>
              </List>
            </div>
          </Dialog>
          <Dialog
            open={openImage}
            onClose={handleImageClose}
            style={{ borderRadius: "50%" }}
          >
            <DialogContent>
              <img
                src={props.pilot?.imageDelivery}
                style={{
                  width: "50px",
                  height: "auto",
                  borderRadius: "50%",
                  maxWidth: "450px",
                }}
                alt="imagen"
                onClick={handleImagenOpener}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};
export default RecipeReviewCard;
