/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import DynamicForm from "../Design/Form/DynamicForm";
import Alert from "@material-ui/lab/Alert";
import { branchActions } from "../../logic/actions/branches";
import arrayHelper from "../../helpers/arrayHelper";
import companyActions from "../../logic/actions/company";
import {
  getCompanies,
  getAreCompaniesLoading,
} from "../../logic/selectors/company";
import {
  getBranches,
  getAreBranchesLoading,
} from "../../logic/selectors/branch";

const BikerForm = ({
  formFields,
  biker,
  setBiker,
  btnList,
  onSubmit,
  validationSchema,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [customErrors, setCustomErrors] = useState([]);
  const [isFetching, setIsFetching] = useState({
    companies: false,
    branches: false,
  });
  // Company
  const companies = useSelector(getCompanies);
  const areCompaniesLoading = useSelector(getAreCompaniesLoading);
  // Branches
  const branches = useSelector(getBranches);
  const areBranchesLoading = useSelector(getAreBranchesLoading);

  const onCompanySelected = (company) => {
    const formFields = [...customFormFields];
    const companyFieldIndex = formFields.findIndex(
      (el) => el.name === "branch"
    );
    formFields[companyFieldIndex].options = [];
    setBiker((val) => ({
      ...val,
      branch: {},
    }));
    dispatch(branchActions.clearBranches());
    if (!isEmpty(company)) {
      formFields[companyFieldIndex].isLoading = true;
      dispatch(
        branchActions.getBranches({
          companyId: company.value,
        })
      );
      setIsFetching((val) => ({
        ...val,
        branches: true,
      }));
    } else {
      formFields[companyFieldIndex].isLoading = false;
    }
    setCustomFormFields([...formFields]);
  };

  const onTypeChanged = (val) => {
    if (!isEmpty(val)) {
      if (val.value === "PERMANENT") {
        // setCustomFormFields((values) =>
        //   values.map((val) => ({
        //     ...val,
        //     disabled: false,
        //   }))
        // );
      } else if (val.value === "WILDCARD") {
        // setCustomFormFields((values) =>
        //   values.map((val) => ({
        //     ...val,
        //     disabled: val.name === "company" || val.name === "branch",
        //   }))
        // );
      }
    }
  };

  useOnLoad(
    biker,
    setIsFetching,
    dispatch,
    customFormFields,
    setCustomFormFields,
    onTypeChanged
  );
  useOnCompaniesLoaded(
    companies,
    areCompaniesLoading,
    isFetching,
    setIsFetching,
    customFormFields,
    setCustomFormFields,
    onCompanySelected,
    biker
  );

  useOnBranchesLoaded(
    branches,
    areBranchesLoading,
    isFetching,
    setIsFetching,
    customFormFields,
    setCustomFormFields,
    biker
  );

  const onFormSubmit = (values) => {
    // if (isEmpty(values.dpi)) {
      // setCustomErrors(["El DPI es requerido"]);
    //   return;
    // } else i
    // if (!validationHelper.isCUIValid(values.dpi)) {
    //   setCustomErrors(["El DPI no es un número de DPI válido"]);
    //   return;
    // }
    if (isEmpty(values.type)) {
      setCustomErrors(["Tipo de motorista es requerido"]);
      return;
    }
    if (
      values.type.value === "PERMANENT" &&
      (isEmpty(values.company) || isEmpty(values.branch))
    ) {
      setCustomErrors(["Compañía y sucursal son requeridos"]);
      return;
    }
    setCustomErrors([]);
    const newValues = { ...values };
    delete newValues.pic;
    // if (newValues.type.value === "WILDCARD") {
    //   delete newValues.company;
    //   delete newValues.branch;
    // }
    onSubmit(newValues);
  };

  return (
    <>
      {!isEmpty(customErrors) && (
        <div className="custom-errors">
          {customErrors.map((customError, i) => (
            <Alert key={`custom-error-${i}`} severity="error">
              {customError}
            </Alert>
          ))}
        </div>
      )}
      <DynamicForm
        obj={biker}
        fields={customFormFields}
        validationSchema={validationSchema}
        buttons={btnList}
        onSubmit={onFormSubmit}
        btnAlignment="right"
        setState={setBiker}
        formCssClasses="all-upper-case"
        {...rest}
      />
    </>
  );
};

const useOnLoad = (
  biker,
  setIsFetching,
  dispatch,
  customFormFields,
  setCustomFormFields,
  onTypeChanged
) => {
  useEffect(() => {
    let isBranchFetching = false;
    dispatch(companyActions.clearCompanies());
    dispatch(branchActions.clearBranches());
    dispatch(companyActions.getCompanies());
    if (!isEmpty(biker.company)) {
      dispatch(
        branchActions.getBranches({
          companyId: biker.company.value,
        })
      );
      isBranchFetching = true;
    }
    setIsFetching({
      companies: true,
      branches: isBranchFetching,
    });
    setCustomFormFields((values) =>
      values.map((customFormField) => ({
        ...customFormField,
        onValueChanged:
          customFormField.name === "type"
            ? onTypeChanged
            : customFormField.onValueChanged,
      }))
    );
  }, []);
};

const useOnBranchesLoaded = (
  branches,
  areBranchesLoading,
  isFetching,
  setIsFetching,
  customFormFields,
  setCustomFormFields,
  biker
) => {
  useEffect(() => {
    if (isFetching.branches && !areBranchesLoading) {
      setIsFetching((val) => ({
        ...val,
        branches: false,
      }));
      const formFields = [...customFormFields];
      const fieldIndex = formFields.findIndex((el) => el.name === "branch");
      formFields[fieldIndex].isLoading = false;
      formFields[fieldIndex].options = (branches || []).map((branch) => ({
        value: branch.id,
        text: branch.name,
      }));
      formFields[fieldIndex].options = arrayHelper.addElementToOptions(
        formFields[fieldIndex],
        {
          value: biker.branchId,
          text: biker.SUCURSAL,
        }
      );
      setCustomFormFields([...formFields]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, areBranchesLoading, isFetching]);
};

const useOnCompaniesLoaded = (
  companies,
  areCompaniesLoading,
  isFetching,
  setIsFetching,
  customFormFields,
  setCustomFormFields,
  onCompanySelected,
  biker
) => {
  useEffect(() => {
    if (isFetching.companies && !areCompaniesLoading) {
      setIsFetching((val) => ({
        ...val,
        companies: false,
      }));
      const formFields = [...customFormFields];
      const fieldIndex = formFields.findIndex((el) => el.name === "company");
      formFields[fieldIndex].isLoading = false;
      formFields[fieldIndex].onValueChanged = onCompanySelected;
      formFields[fieldIndex].options = (companies || []).map((company) => ({
        value: company.id,
        text: company.name,
      }));
      setCustomFormFields([...formFields]);
      formFields[fieldIndex].options = arrayHelper.addElementToOptions(
        formFields[fieldIndex],
        {
          value: biker.companyId,
          text: biker.company,
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, areCompaniesLoading, isFetching]);
};

export default BikerForm;
