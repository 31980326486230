import React, { useState } from "react";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";

const validationSchema = yup.object({
  orderObservations: yup.string().required("Observaciones son requeridas"),
});

const formFields = [
  {
    label: "Motivo de cancelación",
    name: "orderObservations",
    cssClasses: "col-12",
    fullWidth: true,
  },
];

export default ({ onModalClose, onModalSubmit, cancelledOrder }) => {
  const [data, setData] = useState({
    orderObservations: cancelledOrder.orderObservations,
  });

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar orden",
    },
  ];

  const onSubmit = (values) => {
    const order = {
      ...cancelledOrder,
      orderObservations: values.orderObservations,
    };
    onModalSubmit(order);
  };

  return (
    <div className="modal-container modal-container-observations">
      <div className="modal-title">
        <div className="modal-title-text">Cancelar Orden</div>
      </div>
      <div className="modal-body all-upper-case">
        <DynamicForm
          obj={data}
          fields={formFields}
          validationSchema={validationSchema}
          buttons={btnList}
          onSubmit={onSubmit}
          btnAlignment="right"
          setState={setData}
          btnCssClass="modal-footer"
        />
      </div>
    </div>
  );
};
