import {createIssue} from "../services/issueServices";
import {put,takeEvery,call} from "redux-saga/effects";
import {creatingIssueCompleted,creatingIssueFailed} from "../actions/issues";
import {CREATING_ISSUE_STARTED} from "../types/issues";
function* initPostingIssue({payload}){
    const {file,companyId,userName,branchId,email,comment,role} = payload;
    try {
        let response = yield call(createIssue,{file,metadata:{companyId,userName,branchId,email,comment,role}})
        console.log(response);
        yield put(creatingIssueCompleted())
    } catch (error) {
        console.error(error);
        yield put(creatingIssueFailed())
    }
}
export function* watchCreatingIssue(){
    yield takeEvery(CREATING_ISSUE_STARTED,initPostingIssue)
}