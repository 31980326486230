import { fork, all } from "redux-saga/effects";

import { watchAuthenticateUser,watchCreatingUserDelivery} from "./auth";
import {
  watchFetchPilots,
  watcherFetchPilotByPilotId,
  watcherFetchPilotList,
  watcherSubscriptPilotByPilotId,
  watchFetchSinglePilot,
  watchEditBiker,
  watchFetchPilotsByStatus,
  watchAuthorizePilot,
  watchDeleteBiker,
  watchEditBikerAlert,
  watchCreateBikerRating,
  watchLinkSinglePilotLink,
  watchBikerRating,
  watchSchedulesPilot,
  watchSchedulesPilotMonths,
  watchSchedulesGetBikerOnlyBranch,
  watchSchedulesBikerRankingCreating,
  watchSchedulesGetBikerSchedule,
  watchGetAssistenceBiker,
  watchGetUpdateAssistenceBiker
} from "./pilots";
import { watchFetchBranchOffices, watchFetchBranches, watchCreateBranch, watchDeleteBranch } from "./branches";
import {
  watchFetchOrders,
  watchFetchMonthlyOrders,
  watchEditOrders,
  watchEditOrderUsersInForm,
  watchFetchHistoryOrders,
  watchFetchOrderByOrderId,
  watchFetchOrdersByBikerId,
  watchDeleteOrderByOrderId,
  watchCreateOrder,
  watchGetOrdersWithTime,
  watchFetchSingleOrder,
  watchLinkOrderWhitTime,
  watchLinkSinlgeOrder,
  watchLinkUpdateSigleOrder,
  watchGettingOrdersSummary
} from "./orders";
import companySagas, { watchGettingCompanySummary, watchLinkSingleCompanie} from "./company";
import {
  watchFindEmailSaga,
  watchCreatedUsersPilots,
  watchUpdateInfoController,
  watchPasswordController,
  watchCreatedImagesPilots,
  watchUpdatePasswordSaga,
  watchGetOrders,
  watchGettingUserAccountSummary,
  watchGettingSMSReport, 
  watchGettingBikersDeliveries,
  watchGettingOrders,
  watchGettingGmailReport
} from "./user"
import {watchCreatingIssue} from "./issues";
function* mainSaga() {
  yield all([
    // users 
    fork(watchGetOrders),
    fork(watchUpdatePasswordSaga),
    fork(watchCreatedImagesPilots),
    fork(watchCreatedUsersPilots),
    fork(watchPasswordController),
    fork(watchFindEmailSaga),
    fork(watchUpdateInfoController),
    // Schedules pilots
    fork(watchSchedulesPilot),
    fork(watchCreateBikerRating),
    //  AUTH SAGAS
    fork(watchAuthenticateUser),
    fork(watchCreatingUserDelivery),
    //  PILOTS SAGAS
    fork(watchFetchPilots),
    fork(watcherFetchPilotByPilotId),
    fork(watcherFetchPilotList),
    fork(watcherSubscriptPilotByPilotId),
    fork(watchFetchSinglePilot),
    fork(watchGetOrdersWithTime),
    fork(watchCreateOrder),
    fork(watchBikerRating),    
    fork(watchEditBiker),
    fork(watchDeleteBiker),
    fork(watchEditBikerAlert),
    fork(watchFetchPilotsByStatus),
    fork(watchAuthorizePilot),
    fork(watchSchedulesPilotMonths),
    //  ORDERS SAGAS
    fork(watchFetchOrders),
    fork(watchFetchMonthlyOrders),
    fork(watchFetchSingleOrder),
    fork(watchEditOrders),
    fork(watchEditOrderUsersInForm),
    fork(watchFetchHistoryOrders),
    fork(watchFetchOrderByOrderId),
    fork(watchFetchOrdersByBikerId),
    fork(watchDeleteOrderByOrderId),

    //  BRANCHES SAGAS
    fork(watchFetchBranchOffices),
    fork(watchFetchBranches),
    fork(watchCreateBranch),
    fork(watchDeleteBranch),
    fork(watchLinkSingleCompanie),
    // ORDER LINK
    fork(watchLinkSinlgeOrder),
    fork(watchLinkSinglePilotLink),
    fork(watchLinkUpdateSigleOrder),
    fork(watchLinkOrderWhitTime),
    //  COMPANY SAGAS
    fork(companySagas),

    //  ISSUE SAGAS
    fork(watchCreatingIssue),
    fork(watchSchedulesBikerRankingCreating),
    fork(watchSchedulesGetBikerOnlyBranch),

    /* USERR ACCOUNT SAGA */
    fork(watchGettingUserAccountSummary),

    fork(watchGettingCompanySummary),

    fork(watchGettingSMSReport),

    fork(watchGettingBikersDeliveries),

    fork(watchGettingOrders),

    fork(watchSchedulesGetBikerSchedule),
    /* gmail report */
    fork(watchGettingGmailReport),

    /* Assistence biker */
    fork(watchGetAssistenceBiker),

    /* Update Assistence */
    fork(watchGetUpdateAssistenceBiker),

    /* get all orders summary */
    fork(watchGettingOrdersSummary)

  ]);
}

export default mainSaga;
