/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import BlockUi from "react-block-ui";
import { useSnackbar } from "notistack";
import { orderActions } from "../../logic/actions/orders";
import {
  snackBarConst,
  PROVIDER_TYPE,
  INPUT_TYPE,
  orderStatus,
} from "../../constants/constants";
import * as yup from "yup";
import DynamicForm from "../Design/Form/DynamicForm";
import {
  getCreateOrderDone,
  getCreateOrderError,
  getCreateOrderIsLoading,
} from "../../logic/selectors/orders";
import { getAuthEmail,getController } from '../../logic/reducers';


const validationSchema = yup.object({
  orderId: yup.string().required("Orden es requerido"),
  address: yup.string().required("Direccion es requerida"),
  name: yup.string().required("Cliente es requerido"),
  phone: yup.number().typeError("Télefono debe ser un valor númerico"),
});





export default ({ onModalClose, branchData }) => {
const formFields = [
    // {
    //   label: 'TIPO DE ORDEN',
    //   name: 'typeOrder',
    //   cssClasses: "col-12",
    //   fullWidth: true, 
    //   disabled: true,
    // },
    // {
    //   label: 'COMPANIA',
    //   name: 'company_id',
    //   cssClasses: "col-12",
    //   fullWidth: true, 
    //   disabled: true,
    // },
    {
      label: 'SUCURSAL',
      name: 'branch_id',
      cssClasses: "col-12",
      inputType: INPUT_TYPE.FILTERABLE_LIST,
      fullWidth: true, 
      options: 
        branchData.map((values)=> ({
          value: values.branchId,
          text: values.name
      }))
    },
    {
      label: "Orden",
      name: "orderId",
      cssClasses: "col-12",
      fullWidth: true,
    },
    {
        label: "Cliente",
        name: "name",
        cssClasses: "col-12",
        fullWidth: true,
    },
    {
        label: "Direccion",
        name: "address",
        cssClasses: "col-12",
        fullWidth: true,
    },
    {
        label: "Teléfono",
        name: "phone",
        cssClasses: "col-12",
        fullWidth: true,
        type: "number",
    },
    {
        label: "Observaciones",
        name: "nota",
        cssClasses: "col-12",
        fullWidth: true,
    },
    {
        label: "Forma de Pago",
        name: "payMethod",
        cssClasses: "col-12",
        inputType: INPUT_TYPE.FILTERABLE_LIST,
        fullWidth: true,
        options: [
        {
            value: "cash",
            text: "Efectivo",
        },
        {
            value: "credit-card",
            text: "Tarjeta de Crédito",
        },
        {
            value: "visa-link",
            text: "Visa en Link",
        },
        ],
    },
    {
        label: "Agendar",
        name: "toBeDeliveredAt",
        cssClasses: "col-12",
        fullWidth: true,
        type: "date",
    },
    {
        label: "Prioritario",
        name: "isPriority",
        cssClasses: "col-12",
        fullWidth: true,
        inputType: INPUT_TYPE.CHECKBOX,
    },
    ];

  const dispatch = useDispatch();
  const createOrderDone = useSelector(getCreateOrderDone);
  const createOrderError = useSelector(getCreateOrderError);
  const isLoadingcreateOrder = useSelector(getCreateOrderIsLoading);
  const email = useSelector(getAuthEmail);
  const controller = useSelector(getController);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState({
    orderId: "",
    company_id: branchData[0].company,
    creatorOrder:email,
    creatorId:controller.id,
    typeOrder: 'ORDEN CLIENTE',
    address: "",
    name: "",
    phone: "",
    nota: "",
    payMethod: "",
    toBeDeliveredAt: "",
    isPriority: false,
  });

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        onModalClose();
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear orden",
    },
  ];
  // is modal create order
  useOnCreatingOrder(
    createOrderDone,
    createOrderError,
    isLoadingcreateOrder,
    dispatch,
    enqueueSnackbar,
    onModalClose
  );

  const onSubmit = (values) => {
    const orderData = {
      creatorOrder: values.creatorOrder,
      creatorId: values.creatorId,
      typeOrder: values.typeOrder,
      address: values.address,
      branch_id: values.branch_id.value,
      company_id: branchData[0].companyId,
      isPriority: values.isPriority,
      name: values.name,
      nota: values.nota,
      orderId: values.orderId,
      payMethod: values.payMethod,
      phone: values.phone,
      payMethod: !isEmpty(values.payMethod) ? values.payMethod.value : "",
      provider: PROVIDER_TYPE.BRANCH,
      status: orderStatus.ON_HOLD,
      without_biker: "Yes",
      isPriority: values.isPriority ? "Yes" : "No",
    };
    dispatch(orderActions.createOrder(orderData));
  };

  return (
    <div className="modal-container-general modal-container-create-order-general">
    <div className="modal-body all-upper-case">
      <BlockUi tag="div" blocking={isLoadingcreateOrder}>
        <DynamicForm
          obj={order}
          fields={formFields}
          validationSchema={validationSchema}
          buttons={btnList}
          onSubmit={onSubmit}
          btnAlignment="right"
          setState={setOrder}
          btnCssClass="modal-footer"
          forceSetState
        />
      </BlockUi>
    </div>
  </div>
  );
};

const useOnCreatingOrder = (
  createOrderDone,
  createOrderError,
  isLoadingcreateOrder,
  dispatch,
  enqueueSnackbar,
  onModalClose
) => {
  useEffect(() => {
    if (!isLoadingcreateOrder) {
      if (createOrderDone) {
        enqueueSnackbar("Orden creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearCreateOrder());
        onModalClose();
      } else if (!isEmpty(createOrderError)) {
        enqueueSnackbar(
          "Hubo un error al crear la orden, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [createOrderDone, createOrderError, isLoadingcreateOrder]);
};
