/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCheckIn, getPilotByPilotId } from "../../firebase/pilots";
import BreadCrumb from "../../containers/navs/breadcrumb";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Column,
  Export,
  Paging,
  StateStoring,
  SearchPanel,
  Pager,
  Selection,
  Scrolling,
  Sorting,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

const NewSchedule = ({ companyId }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const allowedPageSizes = [5, 10, 20, 50, 100];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const checkInData = await getCheckIn(companyId);
        const pilotPromises = checkInData.map(async (checkIn) => {
          const pilotData = await getPilotByPilotId(checkIn.id);
          return { ...checkIn, name: pilotData.biker.name };
        });
        const pilotsData = await Promise.all(pilotPromises);
        setData(pilotsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [companyId]);

  const cellRenderBiker = ({ data }) => {
    return (
      <Typography
        style={{
          color: "black",
          weight: "10px",
          borderRadius: "20px",
          height: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Ubuntu, sans-serif",
          fontSize: "12px",
        }}
      >
        {data.name.toUpperCase()}
      </Typography>
    );
  };

  const cellRenderCompany = ({ data }) => {
    return (
      <Typography
        style={{
          color: "black",
          weight: "10px",
          borderRadius: "20px",
          height: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Ubuntu, sans-serif",
          fontSize: "12px",
        }}
      >
        {data.branch.companyName.toUpperCase()}
      </Typography>
    );
  };

  const cellRenderBranch = ({ data }) => {
    return (
      <Typography
        style={{
          color: "black",
          weight: "10px",
          borderRadius: "20px",
          height: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Ubuntu, sans-serif",
          fontSize: "12px",
        }}
      >
        {data.branch.branchName.toUpperCase()}
      </Typography>
    );
  };

  const cellRenderCheckIn = ({ data }) => {
    if (!isEmpty(data.checkinTime)) {
      const checkoutDate = new Date(data.checkinTime).toLocaleDateString();
      const checkoutHour = new Date(data.checkinTime).toLocaleTimeString();
      return (
        <Typography
          style={{
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          {checkoutDate + " " + checkoutHour}
        </Typography>
      );
    }
    return (
      <Typography
        style={{
          fontWeight: "bold",
          color: "black",
          weight: "10px",
          borderRadius: "20px",
          height: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Ubuntu, sans-serif",
          fontSize: "12px",
        }}
      >
        NO HA INICIADO
      </Typography>
    );
  };

  const cellRenderCheckOut = ({ data }) => {
    if (!isEmpty(data.checkOutTime)) {
      const checkoutDate = new Date(data.checkOutTime).toLocaleDateString();
      const checkoutHour = new Date(data.checkOutTime).toLocaleTimeString();
      return (
        <Typography
          style={{
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          {checkoutDate + " " + checkoutHour}
        </Typography>
      );
    }
    return (
      <Typography
        style={{
          fontWeight: "bold",
          color: "black",
          weight: "10px",
          borderRadius: "20px",
          height: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#D6EAF8",
          fontFamily: "Ubuntu, sans-serif",
          fontSize: "12px",
        }}
      >
        PENDIENTE
      </Typography>
    );
  };

  const cellRenderApprovedIn = ({ data }) => {
    if (!data.checkInApproved) {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#E59866",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          NO APROBADO
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#58D68D",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          APROBADO
        </Typography>
      );
    }
  };

  const cellRenderApprovedOut = ({ data }) => {
    if (!data.checkOutApproved) {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#E59866",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          NO APROBADO
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#58D68D",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          APROBADO
        </Typography>
      );
    }
  };

  return (
    <>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="ASISTENCIA DE MOTORISTAS"
      />
      <div>
        <DataGrid
          id="gridContainer"
          keyExpr="id"
          dataSource={data}
          width={"100%"}
          showBorders={true}
          rowAlternationEnabled={true}
          noDataText="NO HAY INFORMACIÓN"
          repaintChangesOnly={true}
        >
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="reportOrders"
          />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} placeholder="BUSCAR" width={240} />
          <Pager
            allowedPageSizes={allowedPageSizes}
            showPageSizeSelector={true}
            displayMode={true}
            showInfo={true}
            showNavigationButtons={true}
          />

          <Selection mode="single" />
          <Scrolling columnRenderingMode="virtual" />
          <Sorting mode="none" />
          <LoadPanel enabled={false} />
          <Scrolling mode="infinite" />
          <Paging enabled={true} defaultPageSize={20} />

          <Column
            caption="PILOTO"
            dataField="name"
            cellRender={cellRenderBiker}
          />
          <Column
            caption="COMPAÑIA"
            dataField="branch.companyName"
            cellRender={cellRenderCompany}
          />
          <Column
            caption="SUCURSAL"
            dataField="branch.branchName"
            cellRender={cellRenderBranch}
          />
          <Column caption="INGRESO" cellRender={cellRenderCheckIn} />
          <Column
            caption="ENTRADA APROBADA"
            cellRender={cellRenderApprovedIn}
          />
          <Column caption="SALIDA" cellRender={cellRenderCheckOut} />
          <Column
            caption="SALIDA APROBADA"
            cellRender={cellRenderApprovedOut}
          />
          <Export enabled={true} />
        </DataGrid>
      </div>
    </>
  );
};

export default NewSchedule;
