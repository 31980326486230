import { firebaseFirestore } from "./config";

export async function fetchRatingsOfToday() {
  const startDay = new Date();
  const endDay = new Date();
  startDay.setHours(0);
  startDay.setMinutes(0);
  startDay.setSeconds(1);
  endDay.setHours(23);
  endDay.setMinutes(59);
  endDay.setSeconds(59);
  let response = await firebaseFirestore.collection("rating").get();
  return response.docs
    .map((element) => element.data())
    .filter((element) => {
      let createdAt = new Date(element.createdAt.seconds * 1000);
      return createdAt > startDay.getTime() && createdAt < endDay.getTime();
    });
}

export async function fetchAllRaitings() {
  let response = await firebaseFirestore.collection("rating").get();
  return response.docs.map((element) => element.data());
}
