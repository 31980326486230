import axios from "axios";
import { DEV_API_URL } from "../../constants/constants";
import { firebaseFirestore, incrementFirebase,firebase} from "../../firebase/config";
import { store } from "../../components/App";
import * as actions from "../../logic/actions/pilots";
import { RandomUtil } from "../../utils";
import { isEmpty } from "lodash";

const db = firebaseFirestore;
const API = DEV_API_URL;
const bikersAPI = `${API}/security/users`;

const getBikersByFilters = async (filters) => {
  const response = await axios.get(
    `${bikersAPI}/available?branchId=${filters.branchId}`
  );
  return response.data.data;
};

const getAllBikers = async () => {
  const response = await axios.get(`${bikersAPI}/available`);
  return response.data.data;
};

const getBikersByStatus = async (status) => {
  const response = await axios.get(`${bikersAPI}?status=${status}&limit=100`);
  return response.data.data;
};
const getBikerByBikerId = async (bikerId) => {
  // console.log(bikerId)
  const datos = firebase.firestore().collection("bikers").doc(bikerId);
  const snap = await datos.get()
  // console.log(snap.data())
  return snap.data(); 
};

const changeBikerStatus = async (mongoId, uid, status) => {
  await axios.patch(`${bikersAPI}/${mongoId}/uid/${uid}`, {
    status,
  });
};
const changeBikerType = async (uid, type) => {
  await axios.patch(`${bikersAPI}/uid/${uid}/type/${type}`);
};

const getOptimalRouteByBikerIds = async (bikerIds) => {
  return (
    await axios.patch(`${API}/orders-service/routes`, {
      ids: bikerIds,
    })
  ).data;
};

const createComment = async (
  bikerId,
  branchId,
  nameBiker,
  rating,
  message,
  issue,
  type,
  branch,
  dpi,
  transportId,
  phone
) => {
  const bikerData = {
    nameBiker: nameBiker,
    active: true,
    dpi: dpi === undefined ? 0 : dpi,
    transportId,
    phone,
    totalComments: 1,
    accumStarts: rating,
  };
  const dataComment = {
    branchId: branchId,
    branch,
    stars: rating,
    comment: message === "" ? "SIN COMENTARIO" : message,
    type,
    issue,
    date: new Date(),
  };
  try {
    const pilotRef = db.collection("ratingPilots").doc(bikerId);
    let doc = await pilotRef.get();
    if (doc.exists) {
      await pilotRef.update({
        totalComments: incrementFirebase(1),
        accumStarts: incrementFirebase(rating),
      });
    } else {
      await pilotRef.set(bikerData);
    }
    doc = await pilotRef.get();
    let uidTmp = await doc.data();
    await pilotRef
      .collection("comments")
      .doc(uidTmp.totalComments.toString())
      .set(dataComment);
  } catch (error) {
    throw new Error("HA OCURRIDO UN ERROR AL SUBIR LA CALIFICACIÓN");
  }
};

const getCommentsByBikerIds = async () => {
  try {
    let array = [];
    await db.collection("ratingPilots").onSnapshot((snap) => {
      snap.docChanges().forEach(async (change) => {
        let data = {};
        data = change.doc.data();
        data.id = change.doc.id;
        data.avarage = data.accumStarts / data.totalComments;
        array.push(data);
        const randomNumber = RandomUtil.randomBetween(1, data.totalComments);
        const refComment = await db
          .collection("ratingPilots")
          .doc(change.doc.id)
          .collection("comments")
          .doc(randomNumber.toString())
          .get();
        if (refComment.exists) {
          const comment = await refComment.data();
          data.comment = comment;
        } else {
          data.comment = {
            branch: "",
            branchId: "",
            comment: "",
            date: new Date(),
            issue: "",
            start: 0,
            type: "BRANCH",
          };
        }
        store.dispatch(actions.pilotRatingGetCompleted(array));
      });
    });
  } catch (error) {
    return error;
  }
};

const getPilotSchedulesPilot = async (paramsBranch) => {
  try {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(1);
    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);
    let array = [];
    let bikers = await db.collection("schedules").where('company.name', '==', 'POLLO BRUJO').get();
    /* recorremos los datos */
    bikers.forEach((e) => {
      let schedulesAdd = true;
      let timeSchedules = ''
      const data = e.data();
      if(!isEmpty(data.initJourney)){
        timeSchedules = new Date(data.initJourney.seconds * 1000).getTime();
      };
      if (!(timeSchedules > startDay.getTime() && timeSchedules < endDay.getTime())){
        schedulesAdd = false;
      }
      if(schedulesAdd){
        let dataReturn = {
          ...e.data(),
          id: e.id
        };
        array.push(dataReturn);
      }
    });
    return {
      data: array,
      error: null,
    }
  } catch (error) {
    console.info(`This is de error: ${error}`);
    throw new Error("THIS THE ERROR")
  }
};

const bikerServiceShedulesMonths = async (month) => {
  try {
    let array = [];
    const getSchedule = await db.collection("schedules");
    const snapshot = await getSchedule.where("company.name", "==", "POLLO BRUJO").get();
    snapshot.forEach(async (doc) => {
      const now = new Date();
      const year = now.getFullYear();
      const firstDay = new Date(year, month, 1, 0, 0, 0);
      const lastDay = new Date(year, month + 1, 0, 11, 59, 59);
      let schedulesAdd = true;
      let timeSchedules = "";
      const data = doc.data();

      /* formato viejo de horario */
      if (!isEmpty(data.createdAt)) {
        timeSchedules = new Date(data.createdAt?.seconds * 1000).getTime();

      }
      /* nueva versionamiento horarios */ 
      else if (!isEmpty(data.initJourney)) {
        timeSchedules = new Date(data.initJourney.seconds * 1000).getTime();
      }
      if (!(timeSchedules > firstDay.getTime() && timeSchedules < lastDay.getTime())) {
        schedulesAdd = false;
      }
      if (schedulesAdd) {
        let bikerSchedules = {
          ...doc.data(),
          id: doc.id
        };
        array.push(bikerSchedules);
      }
    });
    return array;
  } catch (error) {
    console.log("error");
  }
};

const bikerServicesRanking = async (props) => {
  //console.log("bikerServicesRanking----->", props)
  try {
    props.createdAt = new Date()
    let year = new Date().getFullYear(); 
    let month = new Date().getMonth();
    await db.collection("bikersRanking")
    .doc(year.toString())
    .collection(month.toString())
    .doc(props.id)
    .set({ props });

    return {
      data: "success",
      error: null,
    }

  } catch (error) {
    console.info("this the error", "this that error",error.message);
    throw new Error("THIS THE ERROR")
  }
}


const getOnlyBikerBranch = async () => {
  try {
    let bikers = await db
      .collection("bikers").get();
    let array = [];
    bikers.forEach((e) => {
      let data = {
        ...e.data(),
        id: e.id
      }
      array.push(data)
    });
    return {
      data: array,
      error: null,
    }
  } catch (error) {
    console.info("this the error", "this that error");
    throw new Error("THIS THE ERROR")
  }
}

const getScheduleBiker = async () => {
  try {
    let bikers = await db
      .collection("schedules").get();
    let array = [];
    bikers.forEach((e) => {
      let data = {
        ...e.data(),
        id: e.id
      }
      array.push(data)
    });
    return {
      data: array,
      error: null,
    }
  } catch (error) {
    console.info("this the error", "this that error");
    throw new Error("THIS THE ERROR")
  }
}

const getAssistanceBiker = async () => {
  try {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(1);
    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59);
    let array = [];
    let bikers = await db.collection("schedules").where('company.name', '==', 'POLLO BRUJO').get();
    /* recorremos los datos */
    bikers.forEach((e) => {
      let schedulesAdd = true;
      let timeSchedules = ''
      const data = e.data();
      if(!isEmpty(data.initJourney)){
        timeSchedules = new Date(data.initJourney.seconds * 1000).getTime();
      };
      if (!(timeSchedules > startDay.getTime() && timeSchedules < endDay.getTime())){
        schedulesAdd = false;
      }
      if(schedulesAdd){
        let dataReturn = {
          ...e.data(),
          id: e.id
        };
        array.push(dataReturn);
      }
    });
    return {
      data: array,
      error: null,
    }
  } catch (error) {
    console.info("this the error", "this that error");
    throw new Error("THIS THE ERROR")
  }
}

const getUpateAssistanceBiker = async (date) => {
  try {
    let updateData = db.collection("schedules").doc(date.id)
    await updateData.update(date.data)
    return {
      data:'succesFull',
      error: null
    }
  } catch (error) {
    throw new Error("THIS THE ERROR")
  }
}


const bikerService = {
  bikerServiceShedulesMonths,
  getCommentsByBikerIds,
  getPilotSchedulesPilot,
  createComment,
  getBikersByFilters,
  getBikersByStatus,
  getBikerByBikerId,
  changeBikerStatus,
  changeBikerType,
  getOptimalRouteByBikerIds,
  getAllBikers,

  // biker creating ranking 
  bikerServicesRanking,
  getOnlyBikerBranch,
  getScheduleBiker,
  getAssistanceBiker,
  getUpateAssistanceBiker
};

export default bikerService;
