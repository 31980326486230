//  FETCH COMPANIES
export const COMPANIES_FETCH_STARTED = "COMPANIES_FETCH_STARTED";
export const COMPANIES_FETCH_COMPLETED = "COMPANIES_FETCH_COMPLETED";
export const COMPANIES_FETCH_FAILED = "COMPANIES_FETCH_FAILED";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";

//  CREATE COMPANIES
export const COMPANY_CREATE_STARTED = "COMPANY_CREATE_STARTED";
export const COMPANY_CREATE_COMPLETED = "COMPANY_CREATE_COMPLETED";
export const COMPANY_CREATE_FAILED = "COMPANY_CREATE_FAILED";

//  MODIFY COMPANIES
export const COMPANY_MODIFY_STARTED = "COMPANY_MODIFY_STARTED";
export const COMPANY_MODIFY_COMPLETED = "COMPANY_MODIFY_COMPLETED";
export const COMPANY_MODIFY_FAILED = "COMPANY_MODIFY_FAILED";

//  DELETE COMPANIES
export const COMPANY_DELETE_STARTED = "COMPANY_DELETE_STARTED";
export const COMPANY_DELETE_COMPLETED = "COMPANY_DELETE_COMPLETED";
export const COMPANY_DELETE_FAILED = "COMPANY_DELETE_FAILED";

export const COMPANY_CLEAR_ALL = "COMPANY_CLEAR_ALL";


// GET COMPANIES LINK

export const GET_COMPANIES_LINK_STARTED = "GET_COMPANIES_LINK_STARTED";
export const GET_COMPANIES_LINK_COMPLETED = "GET_COMPANIES_LINK_COMPLETED";
export const GET_COMPANIES_LINK_FAILED = "GET_COMPANIES_LINK_FAILED"

export const GETTING_COMPANY_STARTED = 'GETTING_COMPANY_STARTED';
export const GETTING_COMPANY_COMPLETED = 'GETTING_COMPANY_COMPLETED';
export const GETTING_COMPANY_FAILED = 'GETTING_COMPANY_AILED';