import React from "react";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import TextField from "./TextField";
import SelectField from "./SelectField";
import LoadingField from "./LoadingField";
import FilterableList from "./FilterableList";
import { INPUT_TYPE } from "../../../constants/constants";
import CheckboxField from "./CheckboxField";

const DynamicForm = ({
  obj,
  otherActions,
  fields,
  validationSchema,
  buttons,
  btnAlignment,
  btnCssClass,
  onSubmit,
  handleChange,
  formCssClasses,
  setState,
  forceSetState = false,
  buttonsTop = false,
}) => {
  const initialValues = { ...obj };
  const renderButtons = () => (
    <div className={`btn-alignment-${btnAlignment} ${btnCssClass}`}>
      {!isEmpty(otherActions) && renderOtherActions()}
      {!isEmpty(buttons) ? (
        buttons.map((btn) => (
          <div key={btn.id} className={btn.cssClasses}>
            <Button
              type={btn.type}
              variant={btn.variant}
              color={btn.color}
              disabled={btn.disabled}
              onClick={(e) => (btn.onClick ? btn.onClick(obj, e) : null)}
            >
              {btn.text}
            </Button>
          </div>
        ))
      ) : (
        <div className="padding-l-xsm padding-r-xsm">
          <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button>
        </div>
      )}
    </div>
  );

  const renderOtherActions = () => (
    <div className="display-inline-block float-left margin-left-sm">
      {otherActions.map((action) => action.component)}
    </div>
  );

  const getInputField = (inputType) => {
    switch (inputType) {
      case INPUT_TYPE.SELECT:
        return SelectField;
      case INPUT_TYPE.FILTERABLE_LIST:
        return FilterableList;
      case INPUT_TYPE.CHECKBOX:
        return CheckboxField;
      // case INPUT_TYPE.DATE_TIME:
      //   return DateTimePicker;
      default:
        return TextField;
    }
  };

  const renderField = (val, formikProps) => {
    if (val.isLoading) {
      return (
        <LoadingField
          cssClasses={val.cssClasses}
          fullWidth={val.fullWidth}
          text={`CARGANDO INFORMACIÓN ${val.label}`}
        />
      );
    }
    return (
      <Field
        key={val.name}
        id={val.name}
        cssClasses={val.cssClasses}
        onValueChanged={val.onValueChanged}
        placeholder={val.placeholder}
        name={val.name}
        label={val.label}
        disabled={val.disabled}
        variant={val.variant}
        autoFocus={val.autoFocus}
        multiline={val.multiline}
        multiple={val.multiple}
        min={val.min}
        max={val.max}
        fullWidth={val.fullWidth}
        type={val.type}
        cssClass={val.cssClass}
        inputProps={val.inputProps}
        options={val.options || []}
        formikProps={formikProps}
        component={getInputField(val.inputType)}
        setState={setState}
        forceSetState={forceSetState}
        handleChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          {buttonsTop && renderButtons()}
          <div className={`${formCssClasses}`}>
            {fields.map((val) => renderField(val, formikProps))}
          </div>
          {!buttonsTop && renderButtons()}
        </Form>
      )}
    </Formik>
  );
};

export default DynamicForm;
