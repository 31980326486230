import React from "react";

const Expandable = ({
  title,
  children,
  defaultExpanded,
  headerCssClass,
  expandLeft = false,
  onExpanded = () => {},
}) => {

  return (
    <div className="expandable">
      <div className="expandable-title">{title}</div>
      { <div>{children}</div>}
    </div>
  );
};

Expandable.defaultProps = {
  defaultExpanded: false,
};

export default Expandable;
