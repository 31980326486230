import { put, takeEvery } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import authService from "../services/authServices";
import { signIn, getAuthUser } from "../../firebase/auth";
import * as actions from "../actions/auth";
import * as types from "../types/auth";
import * as sub from "../../firebase/subscribe";
import { creatingUser } from "../../firebase/users";
function* authenticateUser(action) {
  try {
    const { email, password } = action.payload;

    const response = yield signIn(email, password);

    if (response.error === null) {
      const controller = yield getAuthUser();
      if (isEmpty(controller)) {
        throw new Error("No controlador encontrado");
      }
      const apiToken = yield authService.getAuthToken(response.user.uid);
      yield put(
        actions.completingAuthentication(
          response.token,
          response.user,
          controller,
          email,
          apiToken
        )
      );
    } else {
      yield put(actions.failingAuthentication(response.error.message));
    }
  } catch (error) {
    console.log("ERROR --> ", error);
    yield put(
      actions.failingAuthentication(
        "An error ocurred while authenticating the user"
      )
    );
  }
}

export function* watchAuthenticateUser() {
  yield takeEvery(types.AUTHENTICATION_STARTED, authenticateUser);
}

function* logOutUser(action) {
  try {
    yield put(actions.logOut());
    sub.unSubscribeToFirebase();
  } catch (error) {
    console.log("Error while signing out user");
  }
}

export function* watchLogOutUser() {
  yield takeEvery(types.LOGOUT, logOutUser);
}

function* createdUsersDelivery(action) {
  try {
    // console.log(action.payload);
    let { values } = action.payload;
    if (values !== null) {
      // console.log("si entra");
      const response = yield creatingUser(values);
      let { data, error } = response;
      if (data) {
        yield put(actions.completedCreatingAuth(data));
      } else {
        yield put(actions.failedCreatingAuth(error));
      }
    } else {
      yield put(actions.completedCreatingAuth(null));
      yield put(actions.failedCreatingAuth(null));
    }
  } catch (error) {
    console.log("upps error in creating user", error);
  }
}

export function* watchCreatingUserDelivery() {
  yield takeEvery(types.CREATING_USER_STARTED, createdUsersDelivery);
}
