/* eslint-disable no-extend-native */
/* eslint-disable no-array-constructor */
import React from "react";
import DataGridOnRoute, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  StateStoring,
  FilterPanel,
  HeaderFilter,
  Summary,
  TotalItem,
  Selection,
  FilterRow,
  Export,
  Scrolling
} from "devextreme-react/data-grid";
import { isEmpty } from "lodash";
import moment from "moment";
import { Typography } from "@material-ui/core";
import onExporting from "../Utils/downloadsFiles";

export default function CompanyTableOnDelivered({ rows }) {
  const allowedPageSizes = [10, 20, 50];

  const cellRenderTime = ({ data }) => {
    let stateTimeDelivered = isEmpty(data.stateTime.Entregado)
      ? "00:00:00"
      : moment(data.stateTime.Entregado.seconds * 1000).format("LT");
    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <Typography
          style={{ fontFamily: "Open Sans, sans-serif", fontSize: "14px" }}
        >
          {stateTimeDelivered}
        </Typography>
      </div>
    );
  };

  return (
    <DataGridOnRoute
      id="dataGridOnDelivered"
      keyExpr="id"
      width={"100%"}
      dataSource={rows}
      rowAlternationEnabled={true}
      showBorders={true}
      noDataText="NO HAY INFORMACIÓN"
      onExporting={(e)=>onExporting(e,'entregadas')}
    >
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey="companyOrdersOnDelivered"
      />
      <FilterRow visible={true} />
      <SearchPanel visible={true} placeholder="BUSCAR..." />
      <HeaderFilter visible={true} />
      <Paging enabled={true} defaultPageSize={20} />
      <Pager
        showPageSizeSelector={true}
        displayMode={true}
        allowedPageSizes={allowedPageSizes}
        showInfo={true}
        showNavigationButtons={true}
      />
      <Selection mode="single" />
      <Summary recalculateWhileEditing={true}>
        <TotalItem
          column="orderId"
          displayFormat="ORDENES: {0}"
          summaryType="count"
        />
      </Summary>
      <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />
      <FilterPanel
        visible={true}
        texts={{
          createFilter: "CREAR FILTRO",
          clearFilter: "ELIMINAR FILTRO",
          filterEnabledHint: "CREACIÓN DE FILTROS",
        }}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="orderId"
        caption="ORDEN No."
        width={"auto"}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="address"
        caption="DIRECCIÓN"
        width={"auto"}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="typeOrder"
        caption="TIPO ORDEN"
        width={"auto"}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="branch.name"
        caption="SUCURSAL"
        width={"auto"}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="alias.name"
        caption="PILOTO"
        width={"auto"}
      />
      <Column
        cssClass="cell-highlightedOnCompany"
        dataField="totalDeliveredTime"
        caption="TIEMPO ENTREGA"
        width={"auto"}
      />
      <Column
        dataField="stateTime.Entregado.seconds"
        caption="HORA ENTREGA"
        width={"auto"}
        defaultSortOrder="desc"
        cellRender={cellRenderTime}
      />
      <Export enabled={true} allowExportSelectedData={true} />
    </DataGridOnRoute>
  );
}
// orden , cliente , tipo de entrega, direccion , sucursal, agente, hora de entrega
