// Get Biker
export const getBiker = state => state.pilots.getPilotByPilotId.biker;
export const getBikerError = state => state.pilots.getPilotByPilotId.error;
export const getIsBikerLoading = state => state.pilots.getPilotByPilotId.isLoading;

// Subscribe Biker
export const getSubscribeBiker = state => state.pilots.subscribPilotByPilotId.biker;
export const getSubscribeBikerError = state => state.pilots.subscribPilotByPilotId.error;
export const getIsBikerSubscribeLoading = state => state.pilots.subscribPilotByPilotId.isLoading;

// Get Biker List
export const getBikerList = state => state.pilots.getPilotList.bikerList;
export const getBikersError = state => state.pilots.getPilotList.error;
export const getIsBikerListLoading = state => state.pilots.getPilotList.isLoading;
//Get biker list by status
export const getBikersByStatus = state => {
    // console.info(`\n»» src/logic/selectors/bikers.js: getBikersByStatus`, state.pilots.getPilotsByStatus.data, `\n••••••| ${new Date().toLocaleString()} |••••••\n`);
    return state.pilots.getPilotsByStatus.data
};
export const getBikersByStatusViewState = state => state.pilots.getPilotsByStatus.viewState;
//Get status of biker authorization
export const getCurrentAuthStateLoading = state => state.pilots.authorizePilot.loading
export const getCurrentAuthStateCompleted = state => state.pilots.authorizePilot.completed
export const getCurrentAuthStateError = state => state.pilots.authorizePilot.error
// Create Biker
export const isCreateBikerLoading = state => state.pilots.createBiker.isLoading;
export const isBikerCreated = state => state.pilots.createBiker.done;
export const getCreateBikerError = state => state.pilots.createBiker.error;

// Edit Biker
export const isEditBikerLoading = state => state.pilots.editBiker.isLoading;
export const isBikerEdited = state => state.pilots.editBiker.done;
export const getEditBikerError = state => state.pilots.editBiker.error;

// Delete Biker
export const isDeleteBikerLoading = state => state.pilots.deleteBiker.isLoading;
export const isBikerDeleted = state => state.pilots.deleteBiker.done;
export const getDeleteBikerError = state => state.pilots.deleteBiker.error;

// Edit Biker Alert
export const isEditBikerAlertLoading = state => state.pilots.editBikerAlert.isLoading;
export const isBikerAlertEdited = state => state.pilots.editBikerAlert.done;
export const getEditBikerAlertError = state => state.pilots.editBikerAlert.error;

export const bikerTestSelector = state => state.pilots;
export const getAllPilots = state => state.pilots.order.map((id)=> state.pilots.byId[id])

// RATING PILOT STATE
export const bikerRatingState = state => state.pilots.ratingBiker;

