import React from "react";
import CardLink from "./viewCard";
import MapWithAMarker from "../map/googleMaps";
import "./index.css";
const LinkRuta = (props) => {
  // console.log('link ruta',props)
  return (
    <div style={{ height: "100vh", width: "auto" }}>
      <div
        style={{
          backgroundColor: "orange",
          display: "flex",
          justiyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div>
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3FPeuS4S-c2F_6Z83jULWD7MUfg1gBAw&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div className="map-item" />}
            containerElement={<div className="" style={{ zIndex: -2 }} />}
            mapElement={
              <div
                style={{ height: "92vh", width: "auto", position: "fixed" }}
              />
            }
            pilot={props.biker}
            data={props.data}
          />
        </div>
        <div style={{ position: "fixed", bottom: 0 }}>
          <CardLink time={props.time} data={props.data} pilot={props.biker} />
        </div>
      </div>
    </div>
  );
};
export default LinkRuta;
