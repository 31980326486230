import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { useHistory } from "react-router-dom";
import mapTheme from "../../assets/mapTheme";
import Typography from "@mui/material/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import { BIKER_ICON, DELIVERY_ICON } from "../../constants/constants";

const MapLocation = withScriptjs(
  withGoogleMap(({ bikers, selectedBiker }) => {
    const [selectedCenter, setSelectedCenter] = useState(null);
    const history = useHistory();

    const DEFAULT_LATITUDE = 14.60845364379672;
    const DEFAULT_LONGITUDE = -90.51841937485972;

    const containerStyle = {
      width: "100%",
      height: "750px",
    };

    const [center, setCenter] = useState({
      lat: 14.60845364379672,
      lng: -90.51841937485972,
    });

    const redirectTo = (data) => {
      history.push(`ver-mas/${data.id}`);
    };

    useEffect(() => {
      if (selectedBiker && selectedBiker.actualPosition) {
        const { _lat, _long } = selectedBiker.actualPosition;
        if (_lat !== undefined && _long !== undefined) {
          setCenter({
            lat: _lat,
            lng: _long,
          });
        }
      }
    }, [selectedBiker]);

    return (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          options={{
            styles: mapTheme,
          }}
        >
          {bikers.map((biker) => {
            if (biker.actualPosition) {
              return (
                <Marker
                  key={biker.id}
                  position={{
                    lat: biker.actualPosition._lat || DEFAULT_LATITUDE,
                    lng: biker.actualPosition._long || DEFAULT_LONGITUDE,
                  }}
                  visible={selectedBiker ? selectedBiker.id === biker.id : true}
                  icon={BIKER_ICON}
                  onClick={() => {
                    setSelectedCenter(biker);
                  }}
                />
              );
            }
            return null; //
          })}
          {/* Agregar un marcador para las oficinas centrales */}
          {selectedBiker !== null && (
            <Marker
              position={{ lat: 14.60845364379672, lng: -90.51841937485972 }}
              icon={DELIVERY_ICON}
            />
          )}

          {selectedCenter && (
            <InfoWindow
              position={{
                lat: selectedCenter.actualPosition._lat,
                lng: selectedCenter.actualPosition._long,
              }}
              onCloseClick={() => setSelectedCenter(null)}
            >
              <Container>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Nombre:
                  </span>
                  <span
                    style={{
                      fontWeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {`${selectedCenter.name}`.toUpperCase()}
                  </span>
                </Typography>
                <Divider />
                <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Compañía:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {`${selectedCenter.company}`.toUpperCase()}
                  </span>
                </Typography>
                <Divider />
                <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Sucursal:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {`${selectedCenter.SUCURSAL}`.toUpperCase()}
                  </span>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => redirectTo(selectedCenter)}
                  style={{ marginTop: "16px", marginBottom: "16px" }}
                >
                  Ver más
                </Button>
              </Container>
            </InfoWindow>
          )}
        </GoogleMap>
      </>
    );
  })
);

export default MapLocation;
