import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CircularProcess from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import DataTable from "../Design/DataTable";
import BreadCrumb from "../../containers/navs/breadcrumb";
const BikerList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  useOnLoad(dispatch);

  const renderView = () => {
    return (
      <DataTable
        usePagination
        align="left"
        headers={[
          {
            id: "name",
            name: "Nombre",
          },
        ]}
        rows={[]}
      />
    );
  };

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  return (
    <div title="Usuarios">
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="Listado de Usuarios"
      />
      {isLoading ? renderLoading() : renderView()}
    </div>
  );
};

const useOnLoad = (dispatch) => {
  useEffect(() => {}, []);
};

export default BikerList;
