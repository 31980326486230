import firebase from "firebase";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";

const RUN_EMULATOR = false;
const firebaseConfig = {
  apiKey: "AIzaSyAEPbEe8MPq4XT4ZJ9NTLDEw4QtRIBSC2k",
  authDomain: "twowheelstogo-572d7.firebaseapp.com",
  databaseURL: "https://twowheelstogo-572d7.firebaseio.com",
  projectId: "twowheelstogo-572d7",
  storageBucket: "twowheelstogo-572d7.appspot.com",
  messagingSenderId: "656921807060",
  appId: "1:656921807060:web:d63050dd94fb4113ca5341",
  measurementId: "G-3WGSN109BW",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();
const firebaseFirestore = firebase.firestore();
const incrementFirebase = firebase.firestore.FieldValue.increment;
const storage = firebase.storage();
const firebaseAnalitycs = firebase.analytics();
if (RUN_EMULATOR) {
  // firebaseFirestore.useEmualtor("localhost",8080)
  console.log("running as localhost");
  firebaseFirestore.settings({ host: "localhost:8080", ssl: false });
}
export {
  firebaseApp,
  firebaseAnalitycs,
  firebaseAuth,
  firebaseFirestore,
  firebase,
  storage,
  incrementFirebase
};
