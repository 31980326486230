import React from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getController } from "../../logic/reducers";

const AdminButton = ({ route, text, action, cssClasses = "", children }) => {
  const controller = useSelector(getController);
  const history = useHistory();

  if (isEmpty(controller)) return <></>;
  if (!isEmpty(controller.companyId) || !isEmpty(controller.branchId))
    return <></>;

  return (
    <div className={`create-order-button-orders ${cssClasses}`}>
      {children ? (
        children
      ) : (
        <button
          onClick={() => {
            if (isEmpty(action)) {
              history.push(route);
            } else {
              action();
            }
          }}
          type="button"
          className="create-new-order-btn btn btn-success btn-success-hover"
        >
          {text.toUpperCase()}
        </button>
      )}
    </div>
  );
};

export default AdminButton;
