import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CircularProcess from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DataTable from "../Design/DataTable";
import Modal from "../Design/Modal";
import AssignBikerModal from "../Order/AssignBikerModal";
import CreateOrderModal from "../Order/CreateOrderModal";
import * as pilotActions from "../../logic/actions/pilots";
import * as orderActions from "../../logic/actions/orders";
import * as actions from "../../logic/actions/company";
import dateHelper from "../../helpers/dateHelper";
import * as selectors from "../../logic/reducers";
import * as branchActions from "../../logic/actions/branches";
import OrdersResume from "../Order/OrdersResume";
import Button from "@material-ui/core/Button";
import { orderStatus, ROUTES } from "../../constants/constants";
import BranchDay from "./BranchDay";
import BranchGeneralResume from "./BranchGeneralResume";
import Copyright from "../Copyright/Copyright";
import ordersHelper from "../../helpers/ordersHelper";
import RatingPerBikerView from "./RatingPerBikerView";
import BikerStatus from "./BikerStatus";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { SingleTableView } from "../../containers/tables";
import DataGridControlBiker from "../PilotAssistance";
import { Paper, Box } from "@material-ui/core";
import { connect } from "react-redux";

import BranchBikerQualify from "./BranchBikerQualify";

const VIEW = {
  ON_HOLD: "EN COLA",
  ORDERS: "ORDERS",
  DELIVERED_ORDERS: "DELIVERED_ORDERS",
  DAY_SUMMARY: "DAY_SUMMARY",
  GENERAL_SUMMARY: "GENERAL_SUMMARY",
  RATING: "RATING",
  BIKER_STATUS: "BIKER_STATUS",
  BIKER_CONTROL: "BIKER_CONTROL",
  BIKER_QUALIFY: "BIKER_QUALIFY",
};

const CALCULATED_TIME = {
  CALCULATING: "Calculando",
  ERROR: "Error",
  NA: "N/A",
};

const useStyles = makeStyles((theme) => ({
  default: {
    color: "#000",
    backgroundColor: "#fff",
  },
}));

const BranchResume = ({ match: { params }, fetchingCompanies, getCompany }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areOrdersCleared, setAreOrdersCleared] = useState(false);
  const [branch, setBranch] = useState({});
  const [ordersTimeOut, setOrdersTimeOut] = useState();
  const [ordersWithDistance, setOrdersWithDistance] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [nonBikerOrders, setNonBikerOrders] = useState([]);
  const [orderToModify, setOrderToModify] = useState({});
  const [averageTime, setAverageTime] = useState("00:00:00");
  const [isLoading, setIsLoading] = useState(true);
  const [customView, setCustomView] = useState(VIEW.ORDERS);
  const bikers = useSelector(selectors.getPilots);
  const orders = useSelector(selectors.getOrders);
  const branches = useSelector(selectors.getBranchOffices);
  const headers = [
    { field: "orderId", headerName: "ORDEN", width: 160 },
    { field: "aliasName", headerName: "2WHEELER", width: 200 },
    { field: "name", headerName: "CLIENTE", width: 200 },
    { field: "deliveredAt", headerName: "HORA DE ENTREGA", width: 200 },
    { field: "circle", headerName: "CIRCULO", width: 200 },
    { field: "time", headerName: "tiempo total", width: 200 },
  ];
  useOnDataLoaded(
    branches,
    setBranch,
    ordersWithDistance,
    setOrdersWithDistance,
    setIsLoading,
    orders,
    bikers,
    setAverageTime,
    ordersHelper.getArrivalTime,
    setNonBikerOrders,
    areOrdersCleared
  );
  useOnOrdersCleared(
    params,
    orders,
    areOrdersCleared,
    setAreOrdersCleared,
    dispatch,
    bikers
  );

  useOnLoad(params, dispatch);
  const getOrdersWithClickableOrderId = (statusFilter) => {
    let response = [];
    (ordersWithDistance || []).forEach((order) => {
      if (order.status === statusFilter) {
        response = response.concat([
          {
            ...order,
            circle:
              ((order.circle || {}).id ? order.circle.id : order.circle) || 0,
            orderIdText: order.orderId,
            orderId: (
              <div className="company-branches-links">
                <div
                  onClick={() =>
                    history.push(`${ROUTES.ORDER}/${order.id}?back=true`)
                  }
                >
                  {order.orderId}
                </div>
              </div>
            ),
          },
        ]);
      }
    });
    return response;
  };
  const getOrdersWithClickableOrderIds = (
    statusFilter,
    secondStatus,
    thirdStatus
  ) => {
    let response = [];
    (ordersWithDistance || []).forEach((order) => {
      if (
        order.status === statusFilter ||
        order.status === secondStatus ||
        order.status === thirdStatus
      ) {
        response = response.concat([
          {
            ...order,
            circle:
              ((order.circle || {}).id ? order.circle.id : order.circle) || 0,
            orderIdText: order.orderId,
            orderId: (
              <div className="company-branches-links">
                <div
                  onClick={() =>
                    history.push(`${ROUTES.ORDER}/${order.id}?back=true`)
                  }
                >
                  {order.orderId}
                </div>
              </div>
            ),
          },
        ]);
      }
    });
    return response;
  };

  const sortByTimeInSeconds = (a, b) => {
    if (a.timeInSeconds < b.timeInSeconds) {
      return -1;
    }
    if (a.timeInSeconds > b.timeInSeconds) {
      return 1;
    }
    return 0;
  };

  const renderView = () => {
    if (customView === VIEW.BIKER_STATUS) {
      return (
        <BikerStatus
          branchId={params.branchId}
          bikers={bikers}
          setOrdersTimeOut={setOrdersTimeOut}
          ordersTimeOut={ordersTimeOut}
          orders={orders.filter((order) => order.status === orderStatus.GOING)}
        />
      );
    }
    if (customView === VIEW.RATING) {
      return <RatingPerBikerView branchId={params.branchId} />;
    }
    if (customView === VIEW.ON_HOLD) {
      return (
        <DataTable
          align="left"
          headers={[
            {
              id: "orderId",
              name: "orden",
              style: {
                width: "20%",
              },
            },
            {
              id: "name",
              name: "cliente",
              style: {
                width: "20%",
              },
            },
            {
              id: "createdAt",
              name: "Hora creación",
              style: {
                width: "20%",
              },
            },
            {
              id: "nota",
              name: "descripcion",
              style: {
                width: "20%",
              },
            },
          ]}
          rows={orders
            .filter(
              (element) =>
                element.status === orderStatus.ON_HOLD &&
                element.branch.id === params.branchId
            )
            .map((order) => {
              console.log(order);
              const shouldDisplayName =
                !isEmpty(order.alias) && !isEmpty(order.alias.name);
              return {
                ...order,
                bikerAssign: (
                  <div
                    className={`${
                      !shouldDisplayName
                        ? "company-branches-links text-left-imp"
                        : ""
                    }`}
                  >
                    <div
                      onClick={() => {
                        if (!shouldDisplayName) {
                          setOrderToModify(order);
                        }
                      }}
                    >
                      {shouldDisplayName ? order.alias.name : "Asignar piloto"}
                    </div>
                  </div>
                ),
                createdAt: moment(
                  new Date(order.createdAt.seconds * 1000)
                ).format("DD-MM-YYYY HH:mm:ss"),
              };
            })}
        />
      );
    }
    if (customView === VIEW.DELIVERED_ORDERS) {
      const deliveredOrders = (orders || [])
        .filter((order) => order.status === orderStatus.DELIVERED)
        .map((order) => {
          let deliveredAt = order.deliveredAt;
          let time = dateHelper.secondsToHms(order.time);
          let timeInSeconds = 0;
          if (!isEmpty(deliveredAt)) {
            timeInSeconds = order.deliveredAt.seconds;
            deliveredAt = moment(
              new Date(order.deliveredAt.seconds * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
            if (!isEmpty(order.createdAt)) {
              time = dateHelper.secondsToHms(
                order.deliveredAt.seconds - order.createdAt.seconds
              );
            }
          } else if (!isEmpty(order.createdAt) && !isEmpty(order.time)) {
            timeInSeconds = order.createdAt.seconds + parseInt(order.time);
            deliveredAt = moment(
              new Date((order.createdAt.seconds + parseInt(order.time)) * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          return {
            ...order,
            deliveredAt,
            time,
            timeInSeconds,
            aliasName: (order.alias || {}).name,
            circle:
              (order.circle && order.circle.id
                ? order.circle.id
                : order.circle) || 0,
          };
        })
        .sort(sortByTimeInSeconds);
      // return <BranchDeliveredOrders deliveredOrders={deliveredOrders} />;
      return (
        <Paper>
          <Box padding={1}>
            <SingleTableView rows={deliveredOrders} columns={headers} />
          </Box>
        </Paper>
      );
    }
    if (customView === VIEW.DAY_SUMMARY) {
      return (
        <BranchDay
          orders={(orders || []).filter(
            (order) =>
              order.status === orderStatus.GOING ||
              order.status === orderStatus.DELIVERED ||
              order.status === orderStatus.ACCEPTED
          )}
        />
      );
    }
    if (customView === VIEW.GENERAL_SUMMARY) {
      return (
        <BranchGeneralResume
          orders={(orders || []).filter(
            (order) =>
              order.status === orderStatus.GOING ||
              order.status === orderStatus.ON_HOLD ||
              order.status === orderStatus.PENDING ||
              order.status === orderStatus.ACCEPTED
          )}
        />
      );
    }

    if (customView === VIEW.BIKER_CONTROL) {
      return <DataGridControlBiker paramsBranch={branch} />;
    }

    if (customView === VIEW.BIKER_QUALIFY) {
      return <BranchBikerQualify paramsBranch={branch} />;
    }
    return (
      <>
        {getCompany && getCompany.name === "POLLO BRUJO" ? (
          <div className="col-6 vertical-top">
            <OrdersResume
              branch={branch}
              headerCssClass="full-width order-status-going"
              title="En ruta"
              orders={getOrdersWithClickableOrderId(orderStatus.GOING)}
            />
          </div>
        ) : (
          <div>
            {getCompany && (
              <div>
                <div className="col-6 vertical-top">
                  <OrdersResume
                    branch={branch}
                    headerCssClass="full-width order-status-delivered"
                    title="Hacia SUCURSAL"
                    orders={getOrdersWithClickableOrderIds(
                      orderStatus.ACCEPTED,
                      orderStatus.ASSIGNED,
                      orderStatus.RECEIVING
                    )}
                    useTime={false}
                    showTime
                  />
                </div>
                <div className="col-6 vertical-top margin-left-sm branch-resume-table-view">
                  <OrdersResume
                    branch={branch}
                    headerCssClass="full-width order-status-going"
                    title="En ruta"
                    orders={getOrdersWithClickableOrderId(orderStatus.GOING)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const clearAllTimeouts = () => {
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  };

  const renderButton = (text, currentView) => (
    <Button
      size="small"
      type="button"
      variant={customView === currentView ? null : "outlined"}
      color="secondary"
      onClick={() => {
        setOrdersTimeOut(null);
        clearAllTimeouts();
        setCustomView(currentView);
      }}
    >
      {text}
    </Button>
  );

  const onModalClose = () => {
    setIsModalOpen(false);
    setOrderToModify({});
  };

  useEffect(() => {
    fetchingCompanies(branch.companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch.companyId]);

  return (
    <div
      title={
        !isEmpty(branch) ? branch.name.toUpperCase() : "resumen de sucursal"
      }
      cssClass="page-container"
    >
      <div className="container-body">
        {isLoading ? (
          <CircularProcess color="secondary" />
        ) : (
          <div>
            <BreadCrumb
              match={{ path: history.location.pathname }}
              title={`${branches
                .filter((element) => element?.branchId === params.branchId)[0]
                ?.name.toUpperCase()}`}
            />
            {!isEmpty(orderToModify) && (
              <AssignBikerModal
                onModalClose={onModalClose}
                orderToModify={{ ...orderToModify }}
                isOnlyBikerAllowed
              />
            )}
            {isModalOpen && (
              <Modal
                isOpened={isModalOpen}
                onClose={onModalClose}
                body={
                  <CreateOrderModal
                    onModalClose={onModalClose}
                    branchData={branch}
                    companyData={getCompany}
                  />
                }
              />
            )}
            <div
              style={{
                fontFamily: "Oswald, sans-serif",
                fontSize: "24px",
              }}
            >
              <div className="col-12 branch-resume-container">
                
                { getCompany &&  getCompany.name === "POLLO BRUJO" ? '':renderButton("En Cola", VIEW.ON_HOLD)}
                {renderButton("Ordenes", VIEW.ORDERS)}
                {renderButton("Entregadas", VIEW.DELIVERED_ORDERS)}
                {/* {renderButton("CALIFICACIONES",VIEW.BIKER_QUALIFY)} */}
                {/* 
                {
                  getCompany?.assistance === true  ? ( 
                    renderButton("CONTROL PILOTOS", VIEW.BIKER_CONTROL) 
                  ) : (
                    ""
                  )
                }
                 */}
                {renderButton("PILOTOS EN SUCURSAL", VIEW.RATING)}
                {/* {renderButton("Disponibilidad", VIEW.BIKER_STATUS)} */}
              </div>
            </div>
            <div className="margin-bottom-md">
              {customView === VIEW.DELIVERED_ORDERS && (
                <>
                  <div className="display-inline-block margin-right-md branch-resume-cards">
                    <div className="display-inline-flex">
                      <div className="display-inline-block chip-avatar">
                        <Avatar className={classes.default}>
                          <HourglassEmpty style={{ fontSize: 20 }} />
                        </Avatar>
                      </div>
                      <span className="branch-resume-card-text">
                        <span className="margin-right-sm">
                          TIEMPO DE ENTREGA PROMEDIO
                        </span>
                        <span>{averageTime}</span>
                      </span>
                    </div>
                  </div>
                  <div className="display-inline-block branch-resume-cards">
                    <div className="display-inline-flex">
                      <div className="display-inline-block chip-avatar">
                        <Avatar className={classes.default}>
                          <CardGiftcard style={{ fontSize: 20 }} />
                        </Avatar>
                      </div>
                      <span className="branch-resume-card-text">
                        <span className="margin-right-sm">
                          Cantidad de Ordenes:{" "}
                        </span>
                        <span>
                          {
                            (orders || []).filter(
                              (order) => order.status === orderStatus.DELIVERED
                            ).length
                          }
                        </span>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="branch-view">{renderView()}</div>
          </div>
        )}
      </div>
      <div className="footer">
        <Copyright />
        <div className="floating-action-button">
          <Tooltip title="CREAR ORDEN CLIENTE">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setIsModalOpen(true)}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const getNonBikerOrders = (orders) => {
  let nonBikerOrders = [];
  (orders || []).forEach((order) => {
    if (
      isEmpty(order.alias) ||
      (!isEmpty(order.alias) &&
        (isEmpty(order.alias.id) || isEmpty(order.alias.name)))
    ) {
      nonBikerOrders = [...nonBikerOrders, order];
    }
  });
  return nonBikerOrders;
};

const getBikerOrders = (orders) => {
  let bikersWithOrders = [];
  (orders || []).forEach((order) => {
    if (
      !isEmpty(order.alias) &&
      !isEmpty(order.alias.id) &&
      !isEmpty(order.alias.name)
    ) {
      if (!bikersWithOrders.find((biker) => biker === order.alias.id)) {
        bikersWithOrders = [...bikersWithOrders, order.alias.id];
      }
    }
  });
  return bikersWithOrders;
};

const useOnDataLoaded = (
  branches,
  setBranch,
  ordersWithDistance,
  setOrdersWithDistance,
  setIsLoading,
  orders,
  bikers,
  setAverageTime,
  getArrivalTime,
  setNonBikerOrders,
  areOrdersCleared
) => {
  useEffect(() => {
    if (!isEmpty(branches) && !isEmpty(branches[0]) && areOrdersCleared) {
      setBranch(branches[0]);
      let ordersToSet = [];
      let averageTime = 0;
      orders
        .filter((order) => order.branch.id === branches[0].id)
        .forEach((order) => {
          if (!isEmpty(order)) {
            if (order.status === orderStatus.DELIVERED) {
              if (!isEmpty(order.createdAt)) {
                if (!isEmpty(order.deliveredAt)) {
                  averageTime +=
                    order.deliveredAt.seconds - order.createdAt.seconds;
                } else if (!isEmpty(order.time)) {
                  averageTime += parseInt(order.time);
                }
              }
            } else {
              let alias = order.alias;
              if (!isEmpty(order.alias)) {
                alias = bikers.find((biker) => biker.id === order.alias.id);
              }
              let isTimeCalculated = false;
              let time = CALCULATED_TIME.CALCULATING;
              if (!isEmpty(ordersWithDistance)) {
                const orderToCheck = ordersWithDistance.find(
                  (orderWithDistance) => orderWithDistance.id === order.id
                );
                if (!isEmpty(orderToCheck) && orderToCheck.isTimeCalculated) {
                  if (order.status !== orderToCheck.status) {
                    isTimeCalculated = false;
                    time = "Calculando";
                  } else {
                    isTimeCalculated = true;
                    time = orderToCheck.time;
                  }
                }
              }
              ordersToSet = ordersToSet.concat([
                {
                  ...order,
                  alias,
                  isTimeCalculated,
                  time,
                },
              ]);
            }
          }
        });
      averageTime =
        averageTime /
        orders.filter((order) => order.status === orderStatus.DELIVERED).length;
      if (averageTime !== null && averageTime !== undefined) {
        setAverageTime(dateHelper.secondsToHms(averageTime));
      }

      setOrdersWithDistance(ordersToSet);
      getArrivalTime(
        getBikerOrders(ordersToSet),
        ordersToSet,
        setOrdersWithDistance
      );
      setNonBikerOrders(getNonBikerOrders(ordersToSet));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, branches, bikers]);
};

const useOnOrdersCleared = (
  params,
  orders,
  areOrdersCleared,
  setAreOrdersCleared,
  dispatch,
  bikers
) => {
  useEffect(() => {
    if (!areOrdersCleared && isEmpty(orders)) {
      const startedFilters = {
        branchId: params.branchId,
        statusFilter: [
          orderStatus.DELIVERED,
          orderStatus.GOING,
          orderStatus.ACCEPTED,
          orderStatus.ON_HOLD,
          orderStatus.PENDING,
          orderStatus.RECEIVING,
          orderStatus.ASSIGNED,
        ],
        comodines: true,
      };
      dispatch(branchActions.startFetchingBranchOffices(startedFilters));
      if (bikers.length === 0)
        dispatch(pilotActions.startFetchingPilots(startedFilters));
      dispatch(orderActions.startFetchingOrders(startedFilters));
      setAreOrdersCleared(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);
};

const useOnLoad = (params, dispatch) => {
  useEffect(() => {
    dispatch(orderActions.clearOrderSubscription());
    dispatch(pilotActions.clearPilotsSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default connect(
  (state) => ({
    getCompany: selectors.fetchingLinkCompaniesCompleted(state),
  }),
  (dispatch) => ({
    fetchingCompanies(id) {
      dispatch(actions.getCompaniesLinkStarted(id));
    },
  })
)(BranchResume);
