import React from "react";
import DataTable from "../Design/DataTable";
import { useDispatch } from "react-redux";
import { pilotActions } from "../../logic/actions/pilots";
import { BIKER_TYPE, BIKER_STATUS } from "../../constants/constants";
const headers = [
  {
    id: "firstName",
    name: "Primer Nombre",
  },
  {
    id: "lastName",
    name: "Segundo Nombre",
  },
  {
    id: "update",
    name: "",
  },
];
const BikerListByStatus = ({ data, loading = false }) => {
  const dispatch = useDispatch();
  return (
    <div className="biker-list">
      <DataTable
        headers={headers}
        rows={data.map((element) => ({
          ...element,
          update: (
            <div className="company-branches-links">
              <div
                onClick={() => {
                  if (!loading) {
                    let params = {
                      ...element,
                      type: BIKER_TYPE.WILDCARD,
                      status: BIKER_STATUS.ACTIVE,
                    };
                    dispatch(pilotActions.authorizePilotStarted(params));
                  }
                }}
              >
                {!loading ? "AUTORIZAR PILOTO" : "..."}
              </div>
            </div>
          ),
        }))}
      />
    </div>
  );
};
export default BikerListByStatus;
