import axios from "axios";
import { DEV_API_URL } from "../../constants/constants";
const circlesAPI = `${DEV_API_URL}/companies-service/circles/companies`;

const getCirclesByCompanyId = async (companyId) => {
  const response = await axios.get(`${circlesAPI}/${companyId}`);
  return response.data;
};

const circleService = {
  getCirclesByCompanyId,
};

export default circleService;
