import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import NavBar from "../NavBar";
import theme from "../../assets/theme";

const Theme = ({ children, title, cssClass }) => {
  return (
    <ThemeProvider theme={theme}>
      <NavBar title={title} />
      {/* <div className={`container ${cssClass}`}>{children}</div> */}
    </ThemeProvider>
  );
};

export default Theme;
