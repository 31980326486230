import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../assets/theme";
import Map from "./Map";
import { SELECT_MAP_POINTS } from "../../constants/constants";
import { isEmpty } from "lodash";

export default (props) => {
  const [markers, setMarkers] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [center, setCenter] = useState(props.center);

  const onPlaceSelected = (place) => {
    const newCenter = {
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    };
    setMarkers(getNewMarkers(newCenter, onMarkerDragEnd));
    setCenter(newCenter);
  };

  const onMarkerDragEnd = (newPosition, marker) => {
    const lng = newPosition.latLng.lng();
    const lat = newPosition.latLng.lat();
    setMarkers((val) => {
      let newValues = [...val];
      const index = newValues.findIndex(
        (o) => o.type.value === marker.type.value
      );
      if (index > -1) {
        newValues[index] = {
          ...newValues[index],
          position: {
            longitude: lng,
            latitude: lat,
          },
        };
      }
      return [...newValues];
    });
    if (marker.type === SELECT_MAP_POINTS.BRANCH) {
      const newCenter = {
        lng,
        lat,
      };
      setMarkers(getNewMarkers(newCenter, onMarkerDragEnd));
      setCenter(newCenter);
    }
  };

  useEffect(() => {
    if (!isStarted) {
      if (!isEmpty(props.prevMarkers) && props.prevMarkers.length === 5) {
        setMarkers(
          props.prevMarkers.map((marker) => ({
            ...marker,
            draggable: true,
            onDragEnd: onMarkerDragEnd,
          }))
        );
      } else {
        setMarkers(getNewMarkers(center, onMarkerDragEnd));
      }
    }
    setIsStarted(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prevMarkers]);

  useEffect(() => {
    if (isStarted) {
      if (props.onMarkersChanged) {
        props.onMarkersChanged(markers);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Map
          {...props}
          center={center}
          markers={markers}
          onPlaceSelected={onPlaceSelected}
          showPlaceSearch
        />
      </div>
    </ThemeProvider>
  );
};

const generateMarker = (lng, lat, mapPointOption, onMarkerDragEnd) => ({
  position: {
    longitude: lng,
    latitude: lat,
  },
  showInfoWindow: true,
  infoWindow: mapPointOption.text,
  draggable: true,
  type: mapPointOption,
  onDragEnd: onMarkerDragEnd,
});

const getNewMarkers = (center, onMarkerDragEnd) => [
  {
    ...generateMarker(
      center.lng,
      center.lat,
      SELECT_MAP_POINTS.BRANCH,
      onMarkerDragEnd
    ),
  },
  {
    ...generateMarker(
      center.lng - 0.02277976455689,
      center.lat + 0.02209727581,
      SELECT_MAP_POINTS.POLYGON_1,
      onMarkerDragEnd
    ),
  },
  {
    ...generateMarker(
      center.lng + 0.02297107276917,
      center.lat + 0.0229916173808,
      SELECT_MAP_POINTS.POLYGON_2,
      onMarkerDragEnd
    ),
  },
  {
    ...generateMarker(
      center.lng - 0.02297107276917,
      center.lat - 0.0229916173808,
      SELECT_MAP_POINTS.POLYGON_3,
      onMarkerDragEnd
    ),
  },
  {
    ...generateMarker(
      center.lng + 0.02277976455689,
      center.lat - 0.02209727581,
      SELECT_MAP_POINTS.POLYGON_4,
      onMarkerDragEnd
    ),
  },
];
