import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { INPUT_TYPE } from "../../constants/constants";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/user";
import * as yup from "yup";
import BreadCrumb from "../../containers/navs/breadcrumb";
import DynamicForm from "../Design/Form/DynamicForm";
import isEmpty from "lodash/isEmpty";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataGrid, {
  Column,
  Grouping,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Export,
  Summary,
  TotalItem,
  Scrolling
} from "devextreme-react/data-grid";

const formFields = [
  {
    label: "FECHA DE INICIO",
    autofocus: true,
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "FECHA FINAL",
    autofocus: true,
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "CANTIDAD",
    autofocus: true,
    name: "qty",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "text",
  },
];

const validationSchema = yup.object({ qty: 0 });

const SummaryBikers = ({ gettingBiker, gettingOrders, biker, delivery, initOrder }) => {
  const history = useHistory();
  const [customField] = useState(formFields);
  const [filters, setFilters] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    qty: 0,
  });
  const [filterError, setFilterError] = useState("");
  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  useEffect(() => {
    gettingBiker();
  }, [gettingBiker, delivery]);

  const [rows, setRows] = useState([]);

  const pageSizes = [10, 25, 50];

  const getData = () => {
    let dictonary = {};

    if (delivery) {
      delivery.forEach((e) => {
        if (e.data?.status === "Entregado") {
          if (dictonary[e.data.alias?.id]) {
            dictonary[e.data.alias?.id] += 1;
          } else {
            dictonary[e.data.alias?.id] = 1;
          }
        }
      });
    }

    // console.log(dictonary)

    let data = [];

    data = Object.entries(dictonary).map(([key, value]) => {
      let newBikers = biker.find((e) => {
        return e.id === key;
      });
      return {
        id: key,
        name: newBikers?.data,
        value: value,
      };
    });

    const newData = data.filter((v) => v.value >= rows.qty);
    // console.log(dictonary);
    return newData;
  };

  const applyFilters = (valueFilters) => {
    setRows(valueFilters);
    // console.log(rows);
  };
  const onSubmit = async (values) => {
    //console.log(values);
    setFilterError("");
    if (!isEmpty(values.startDate) && !isEmpty(values.endDate)) {
      const startDate = new Date(`${filters.startDate} 00:00:00`).getTime();
      const endDate = new Date(`${filters.endDate} 23:59:59`).getTime();
      gettingOrders(startDate, endDate);

      if (startDate > endDate) {
        setFilterError("Fecha de inicio debe ser antes que la fecha final");
        return;
      }

      if (isEmpty(values.qty)) {
        setFilterError("Ingrese una cantidad valida");
        return;
      }
    }
    applyFilters(values);
  };

  return (
    <>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RESÚMEN DE LOS MOTORISTAS"
      />
      {!isEmpty(filterError) && (
        <>
          <Alert severity="error">{filterError}</Alert>
          <br />
        </>
      )}
      <DynamicForm
        obj={filters}
        setState={setFilters}
        fields={customField}
        schema={validationSchema}
        buttons={btnList}
        onSubmit={onSubmit}
        btnAlignment="right"
        forceSetState
      />

    {initOrder ? (
          <CircularProgress disableShrink />
      ) : (
      <DataGrid
        keyExpr="id"
        dataSource={getData()}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        noDataText="NO HAY INFORMACIÓN"
      >
        <Column dataField="name" caption="MOTOCICLISTA" />
        <Column dataField="value" caption="CANTIDAD" />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Grouping autoExpandAll={false} />
        
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="name"
            displayFormat="Motoristas: {0}"
            summaryType="count"
          />
        </Summary>
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <Pager
          showPageSizeSelector={true}
          displayMode={true}
          allowedPageSizes={pageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

        <Paging defaultPageSize={10} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="summary-biker"
        />
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>)}
    </>
  );
};

const {
  gettingBikerDeliveriesStart,
  getBikerDeliveriesAllStart,
  gettingBikerDeliveriesFailed,
  gettingOrderDeliveryStart,
  getOrderDeliveryAllStart,
  gettingOrderDeliveryFailed,
} = selectors;

export default connect(
  (state) => ({
    init: gettingBikerDeliveriesStart(state),
    biker: getBikerDeliveriesAllStart(state),
    failed: gettingBikerDeliveriesFailed(state),

    initOrder: gettingOrderDeliveryStart(state),
    delivery: getOrderDeliveryAllStart(state),
    failedOrder: gettingOrderDeliveryFailed(state),
  }),
  (dispatch) => ({
    gettingBiker() {
      dispatch(actions.gettinDeliveriesBiker());
    },
    gettingOrders(startDate, endDate) {
      dispatch(actions.gettinOrdersSummary(startDate, endDate));
    },
  })
)(SummaryBikers);
