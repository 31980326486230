import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import Map from "../Map/Map";
import { Divider, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Grid, Card, CardContent, makeStyles, Avatar } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import Modal from "../Design/Modal";
import BikerPositionModal from "../Biker/BikerPositionModal";
import bikerService from "../../logic/services/bikerService";
import dateHelper from "../../helpers/dateHelper";
import { BIKER_ICON } from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 200,
    height: 250,
    position: "relative",
  },
  img: {
    zIndex: 10,
    margin: "auto",
    marginBottom: "-20px",
  },
  logo: {
    zIndex: 10,
    margin: "auto",
  },
  header: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: 80,
  },
  content: {
    flexDirection: "column",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    top: 80,
    right: 0,
    width: "100%",
    height: 130,
  },
  bikerDetails: {
    textAlign: "center",
  },
  shapeLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    clipPath: "polygon(0 0, 80% 0, 35% 50%, 0 12%)",
  },
  shapeTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    clipPath: "polygon(0 12%, 0 86%, 35% 50%)",
    backgroundColor: "#808080",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    objectFit: "fill",
    padding: "20px",
    backgroundColor: "white",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

const BikerStatus = ({
  orders,
  branchId,
  bikers,
  setOrdersTimeOut,
  ordersTimeOut,
}) => {
  const classes = useStyles();
  const [bikerStatusList, setBikerStatusList] = useState([]);
  const [bikertoCheckId, setBikertoCheckId] = useState("");
  const [error, setError] = useState("");


  const getBikerOrders = (biker) =>
    orders.filter((order) => order.alias.id === biker.id);

  const getBikerTime = (biker) => {
    const bikerOrdres = getBikerOrders(biker);
    const backHomeTime = Math.ceil(biker.backHome.time / 60);
    if (biker.inRoute) {
      let content = [];
      bikerOrdres.forEach((bikerOrder) => {
        if (!isEmpty(bikerOrder.createdAt)) {
          bikerOrder.time = dateHelper.getTimeOnRoute(
            new Date(bikerOrder.createdAt.seconds * 1000).getTime()
          );
          bikerOrder.alert = dateHelper.getAlert(bikerOrder.time / 60);
        }
        let className = "";
        if (bikerOrder.alert === "Tarde") {
          className = "red-background-light";
        } else if (bikerOrder.alert === "Por llegar Tarde") {
          className = "orange-background-light";
        }
        content = [
          ...content,
          <div className={className}>
            <div className="display-inline-block margin-right-sm">
              {bikerOrder.orderId}:
            </div>
            <div className="display-inline-block">{bikerOrder.name}</div>
          </div>,
        ];
      });
      return (
        <>
          {`En Ruta`}
          <br />
          {content}
        </>
      );
    }
    const now = moment(); // Se obtiene la fecha actual
    let time = moment(biker.backHome.date); // Se obtiene el tiempo que fue calculado
    time = time.add(biker.backHome.time - 60, "SECONDS"); // Se calcula el tiempo pronosticado que debe estar en sucursal con el offset de 60 segundos
    const isInBranch = time.diff(now) <= 0; //cheque la diferencia y si es negativo quiere decir que estar en sucursal
    if (isInBranch) {
      const timeInStore = Math.ceil(now.diff(time) / 60);
      let messageToDisplay = `${timeInStore} ${
        timeInStore === 1 ? "minuto" : "minutos"
      }`;
      if (timeInStore > 60) {
        messageToDisplay = "Mucho tiempo";
      }
      return (
        <>
          {`En Tienda`}
          <br />
          {messageToDisplay}
        </>
      );
    }
    return (
      <>
        {`A menos de`}
        <br />
        {`${backHomeTime} ${backHomeTime === 1 ? "minuto" : "minutos"}`}
      </>
    );
  };

  const loadBikerTimes = async () => {
    try {
      let bikerIds = [];
      bikers
        .filter((biker) => getBikerOrders(biker).length === 0)
        .forEach((biker) => {
          bikerIds = [...bikerIds, biker.id];
        });
      const bikerTimes = await bikerService.getOptimalRouteByBikerIds(bikerIds);
      let bikerToHome = [];
      bikerTimes.forEach((biker) => {
        if (!isEmpty(biker) && !isEmpty(biker.backHome)) {
          const bikerInfo = bikers.find((bik) => bik.id === biker.biker);
          bikerToHome = [
            ...bikerToHome,
            {
              ...bikerInfo,
              inRoute: false,
              backHome: biker.backHome,
            },
          ];
        }
      });
      let inRouteBikers = [];
      bikers
        .filter(
          (biker) =>
            biker.branchId === branchId && getBikerOrders(biker).length > 0
        )
        .forEach((biker) => {
          inRouteBikers = [
            ...inRouteBikers,
            {
              ...biker,
              inRoute: true,
              backHome: {
                date: "2021-03-18T21:38:16.304Z",
                distance: 10,
                time: 60,
              },
            },
          ];
        });
      bikerToHome = bikerToHome.sort((a, b) => {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      });
      setBikerStatusList(bikerToHome.concat(inRouteBikers));
    } catch (e) {
      setError("loadingBikerTimes");
    }
  };

  const startUpdatingTimes = async () => {
    clearTimeout(ordersTimeOut);
    setOrdersTimeOut(
      setTimeout(() => {
        loadBikerTimes();
        startUpdatingTimes();
      }, 60 * 1000)
    );
  };

  const onInit = async () => {
    await loadBikerTimes();
    startUpdatingTimes();
  };

  useEffect(() => {
    if (!isEmpty(bikers)) {
      onInit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBikerToCheck = () => {
    const biker = bikers.find((biker) => biker.id === bikertoCheckId);
    if (isEmpty(biker)) return <></>;
    return (
      <Modal
        onClose={() => setBikertoCheckId("")}
        body={
          <BikerPositionModal
            onModalClose={() => setBikertoCheckId("")}
            biker={biker}
          />
        }
        isOpened
      />
    );
    // eslint-disable-next-line no-unreachable
    return (
      <Map
        markers={[
          {
            id: biker.id,
            icon: BIKER_ICON,
            position: {
              latitude: biker.actualPosition.latitude,
              longitude: biker.actualPosition.longitude,
            },
            showInfoWindow: false,
          },
        ]}
        center={{
          lat: biker.actualPosition.latitude,
          lng: biker.actualPosition.longitude,
        }}
        zoom={14}
        // setZoom={setZoom}
        // height={`${height}px`}
      />
    );
  };

  const renderView = () => {
    if (!isEmpty(error)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }
    if (isEmpty(bikerStatusList)) {
      return <>No hay información a mostrar</>;
    }
    return (
      <Grid container spacing={1}>
        {!isEmpty(bikertoCheckId) && renderBikerToCheck()}
        {bikerStatusList.map((biker) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={2}
            key={`biker-status-item-${biker.id}`}
          >
            <Card>
              <div className={classes.root}>
                <div className={classes.header}>
                  <div className={classes.circle} />
                  <div className={classes.shapeLeft} />
                  <div className={classes.shapeTop} />
                  <div className="full-width">
                    <Avatar className={`${classes.orange} margin-auto`}>
                      {biker.name.substring(0, 1)}
                    </Avatar>
                  </div>
                </div>
                <CardContent className={classes.content}>
                  <div className="full-width text-center">
                    <Typography variant="subtitle1">{biker.name}</Typography>
                    <Divider />
                    <br />
                    <br />
                    <Typography variant="body">
                      {getBikerTime(biker)}
                    </Typography>
                    <br />
                    <div
                      className="link"
                      onClick={() => setBikertoCheckId(biker.id)}
                    >
                      Rastrear
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return <div>{renderView()}</div>;
};

export default BikerStatus;
