import React from "react";
import {
  makeStyles,
  List,
  Typography,
  ListItem,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import { ListTileItem } from "../items";
import PropTypes from "prop-types";
import { deepOrange } from "@material-ui/core/colors";
import ReactStars from "react-rating-stars-component";
const useStyles = makeStyles((theme) => ({
  leadingProps: {
    width: 30,
    fontSize: 15,
    fontWeight: "w500",
    color: "#565656",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
  },
}));
const TrailingComponent = ({ value }) => {
  return (
    <ReactStars
      count={5}
      size={24}
      activeColor="#ffd700"
      value={value}
      edit={false}
    />
  );
};
const RenderTitle = () => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemText secondary={"Pilotos"} />
      <div>
        <ListItemText secondary={"Calificación"} />
      </div>
    </ListItem>
  );
};
const LeadingComponent = ({ content }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.leadingProps} variant="body1" component="h6">
      {content}
    </Typography>
  );
};
const RatingBikerList = (props) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <List className={classes.root}>
      <RenderTitle />
      {(data || []).map((element, index) => (
        <ListTileItem
          key={index}
          going={false}
          title={element.name}
          subtitle={element.message}
          Icon={
            <Avatar className={classes.orange}>
              {element.name[0].toUpperCase()}
            </Avatar>
          }
          Leading={<LeadingComponent content={index + 1} />}
          Trailing={<TrailingComponent value={element.rating} />}
        />
      ))}
    </List>
  );
};
RatingBikerList.propTypes = {
  /**Array of data that will be displayed */
  data: PropTypes.array.isRequired,
};
RatingBikerList.defaultProps = {
  data: [],
};
export default RatingBikerList;
