import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import isEmpty from "lodash/isEmpty";
import DynamicForm from "../Design/Form/DynamicForm";
import MapWithPerymeter from "../Map/MapWithPerymeter";
import addressService from "../../logic/services/addressService";
import { SELECT_MAP_POINTS } from "../../constants/constants";

const BranchForm = ({
  formFields,
  branch,
  setBranch,
  btnList,
  onSubmit,
  validationSchema,
  prevMarkers = [],
  isEditing = false,
  ...rest
}) => {
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [customErrors, setCustomErrors] = useState([]);
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [center] = useState({ lat: 14.587461, lng: -90.514048 });

  const clearOptions = (regions = false, cities = false) => {
    setCustomFormFields((vals) => {
      let newValues = [...vals];
      if (regions) {
        setBranch((br) => ({
          ...br,
          region: {},
        }));
        const fieldIndex = newValues.findIndex(
          (field) => field.name === "region"
        );
        newValues[fieldIndex].options = [];
      }
      if (cities) {
        setBranch((br) => ({
          ...br,
          city: {},
        }));
        const fieldIndex = newValues.findIndex(
          (field) => field.name === "city"
        );
        newValues[fieldIndex].options = [];
      }
      return [...newValues];
    });
  };

  const updateFormFields = (fieldName, options, onValueChanged) => {
    setCustomFormFields((vals) => {
      let newValues = [...vals];
      const fieldIndex = newValues.findIndex(
        (field) => field.name === fieldName
      );
      newValues[fieldIndex].options = (options || []).map((opt) => ({
        value: opt.id,
        text: opt.name,
      }));
      newValues[fieldIndex].isLoading = false;
      if (onValueChanged) {
        newValues[fieldIndex].onValueChanged = onValueChanged;
      }
      return [...newValues];
    });
  };

  const loadCities = async (region, country) => {
    try {
      const response = await addressService.getCitiesByRegionIdByCountryCode(
        region,
        country
      );
      updateFormFields("city", response);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de ciudades, por favor, intenta más tarde",
      ]);
    }
  };

  const onRegionSelected = async (region) => {
    if (isEmpty(region)) {
      clearOptions(false, true);
      return;
    }
    if (branch.region.value !== region.value) {
      clearOptions(false, true);
    }
    await loadCities(region.value, branch.country.value);
  };

  const loadRegions = async (country) => {
    try {
      const response = await addressService.getRegionsByCountryCode(country);
      updateFormFields("region", response, onRegionSelected);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de regiones, por favor, intenta más tarde",
      ]);
    }
  };

  const onCountrySelected = async (country) => {
    if (isEmpty(country)) {
      clearOptions(true, true);
      return;
    }
    if (branch.country.value !== country.value) {
      clearOptions(true, true);
    }
    await loadRegions(country.value);
  };

  const getCountries = async () => {
    try {
      const response = await addressService.getCountries();
      updateFormFields("country", response, onCountrySelected);
    } catch (e) {
      setCustomErrors([
        "Hubo un error al obtener la lista de países, por favor, intenta más tarde",
      ]);
    }
  };

  const loadAddressInformation = async () => {
    await getCountries();
    await loadRegions(branch.country.value);
    await loadCities(branch.region.value, branch.country.value);
  };

  useEffect(() => {
    if (!isEditing) {
      getCountries();
      setIsStarted(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(branch) && isEditing && !isStarted) {
      loadAddressInformation();
      setIsStarted(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  const getPositionFromMarker = (markers, pointToLook) =>
    markers.find((marker) => marker.type === pointToLook).position;

  const onFormSubmit = (values) => {
    const pointCoordinates = getPositionFromMarker(
      currentMarkers,
      SELECT_MAP_POINTS.BRANCH
    );
    const polygon1 = getPositionFromMarker(
      currentMarkers,
      SELECT_MAP_POINTS.POLYGON_1
    );
    const polygon2 = getPositionFromMarker(
      currentMarkers,
      SELECT_MAP_POINTS.POLYGON_2
    );
    const polygon3 = getPositionFromMarker(
      currentMarkers,
      SELECT_MAP_POINTS.POLYGON_3
    );
    const polygon4 = getPositionFromMarker(
      currentMarkers,
      SELECT_MAP_POINTS.POLYGON_4
    );
    onSubmit({
      name: values.name,
      description: values.description,
      address: values.address,
      CountryId: values.country.value,
      RegionId: values.region.value,
      CityId: values.city.value,
      point: {
        type: "Point",
        coordinates: [pointCoordinates.latitude, pointCoordinates.longitude],
      },
      perimeter: {
        type: "Polygon",
        coordinates: [
          [
            [polygon1.latitude, polygon1.longitude],
            [polygon2.latitude, polygon2.longitude],
            [polygon3.latitude, polygon3.longitude],
            [polygon4.latitude, polygon4.longitude],
            [polygon1.latitude, polygon1.longitude],
          ],
        ],
      },
      enable: true,
    });
  };

  const onMarkersChanged = (newMarkers) => {
    setCurrentMarkers(newMarkers);
  };

  const renderMap = () => {
    return (
      <div className="">
        <MapWithPerymeter
          onMarkersChanged={onMarkersChanged}
          prevMarkers={prevMarkers}
          center={center}
          zoom={14}
          isEditing={isEditing}
        />
      </div>
    );
  };

  return (
    <>
      <div className="display-flex">
        <div className="col-5">{renderMap()}</div>
        <div className="col-7">
          {!isEmpty(customErrors) && (
            <div className="custom-errors">
              {customErrors.map((customError, i) => (
                <Alert key={`custom-error-${i}`} severity="error">
                  {customError}
                </Alert>
              ))}
            </div>
          )}
          <DynamicForm
            obj={branch}
            fields={customFormFields}
            validationSchema={validationSchema}
            buttons={btnList}
            onSubmit={onFormSubmit}
            btnAlignment="right"
            setState={setBranch}
            formCssClasses="all-upper-case"
            forceSetState
            {...rest}
          />
        </div>
      </div>
    </>
  );
};

export default BranchForm;
