/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApprovedCheck from "./ApprovedCheck";
import { getCheckInById, getPilotByPilotId } from "../../firebase/pilots";

const ApprovedIndex = () => {
  const [checkIns, setCheckIns] = useState([]);
  const { id, check } = useParams();

  useEffect(() => {
    const fetchCheckIns = async () => {
      try {
        const checkInsData = await getCheckInById(id);
        const pilotData = await getPilotByPilotId(checkInsData.id);
        setCheckIns({ ...checkInsData, name: pilotData.biker.name });
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCheckIns();
  }, []);

  return (
    <div>
      {check === "checkin" ? (
        <ApprovedCheck
          id={id}
          check={check}
          checkIns={checkIns}
          setCheckIns={setCheckIns}
          dataToChange={{ checkInApproved: true }}
        />
      ) : check === "checkout" ? (
        <ApprovedCheck
          id={id}
          check={check}
          checkIns={checkIns}
          setCheckIns={setCheckIns}
          dataToChange={{ checkOutApproved: true }}
        />
      ) : null}
    </div>
  );
};

export default ApprovedIndex;
