import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/constants";

const CreateOrderButton = () => {
  const history = useHistory();

  return (
    <div className="floating-action-button">
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => history.push(ROUTES.CREATE_ORDER)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default CreateOrderButton;
