import axios from "axios";
import { DEV_API_URL } from "../../constants/constants";

const authAPI = `${DEV_API_URL}/security/branches/login`;

const getAuthToken = async (uuid) => {
  const response = await axios.post(authAPI, {
    uuid,
  });
  return response.data.token.token;
};

const authService = {
  getAuthToken,
};

export default authService;
