import React from "react";
import { updateCheckInById } from "../../firebase/pilots";
import { Button, Card, Container, Typography } from "@material-ui/core";
import CircularProcess from "@material-ui/core/CircularProgress";

const ApprovedCheck = ({ id, setCheckIns, checkIns, dataToChange, check }) => {
  const checkData = check === "checkin" ? "ENTRADA" : "SALIDA";
  const handleClickCheck = async () => {
    try {
      const newData = { ...checkIns, ...dataToChange };
      await updateCheckInById(id, newData);

      setCheckIns((prevCheckIn) => ({
        ...prevCheckIn,
        ...dataToChange,
      }));
      alert(
        `Se aprobo la ${checkData}  correctamente para el motorista ${checkIns.name}`.toUpperCase()
      );
    } catch (error) {
      console.error("Error al actualizar approved:", error);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: 20 }}>
      {checkIns ? (
        <Card>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ color: "#000", fontWeight: "bold" }}
          >
            {`APROBAR LA ${checkData} DEL MOTORISTA`}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: "#000" }}
          >
            Nombre: {checkIns.name}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: "#000" }}
          >
            {check === 'checkin' ? `Entrada: ${new Date(checkIns.checkinTime).toLocaleTimeString()}` : `Salida: ${new Date(checkIns.checkOutTime).toLocaleTimeString()}`}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: "#000" }}
          >
            Compañia: {checkIns.branch?.companyName}
          </Typography>
          <Typography variant="body1" align="center" style={{ color: "#000" }}>
            Sucursal: {checkIns.branch?.branchName}
          </Typography>
          <Typography variant="body1" align="center" style={{ color: "#000" }}>
            Direccion: {checkIns.branch?.address}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClickCheck}
            style={{ marginTop: 20 }}
            disabled={check === 'checkin' ? checkIns.checkInApproved : checkIns.checkOutApproved}
          >
            Aprobar Solicitud
          </Button>
        </Card>
      ) : (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProcess />
        </div>
      )}
    </Container>
  );
};

export default ApprovedCheck;
