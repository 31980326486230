import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  makeStyles,
  Divider,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  leading: {},
  trailing: {},
  title: {},
  subtitle: {},
}));
const ListTileItem = (props, ref) => {
  const classes = useStyles();
  const { title, subtitle, Trailing, Leading, Icon, going } = props;
  return (
    <div className={classes.root}>
      <ListItem
        dense={true}
        style={{
          borderLeft: going && "3px solid #ffa533",
          padding: 10,
        }}
      >
        <div className={classes.leading}>{React.cloneElement(Leading)}</div>
        <ListItemAvatar>{React.cloneElement(Icon)}</ListItemAvatar>
        <ListItemText primary={title} secondary={subtitle} />
        <div className={classes.trailing}>{React.cloneElement(Trailing)}</div>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </div>
  );
};
ListTileItem.propTypes = {
  /**Item title */
  title: PropTypes.node.isRequired,
  /**Item Subtitle (optional) */
  subtitle: PropTypes.string,
  /**Component that´s placed after title */
  Trailing: PropTypes.node,
  /**Component that´s placed before title */
  Leading: PropTypes.node,
  /**Icon placed before title */
  Icon: PropTypes.node,
  going: PropTypes.bool,
};
ListTileItem.defaultProps = {
  title: <div></div>,
  subtitle: "",
  Trailing: <div></div>,
  Leading: <div></div>,
  Icon: <div></div>,
  going: false,
};
export default ListTileItem;
