import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "../Design/DataTable";
import isEmpty from "lodash/isEmpty";
import Modal from "../Design/Modal";
import OrderStatusModal from "./OrderStatusModal";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/orders";

const OrderStatus = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectors.getOrders);
  const [orderToModify, setOrderToModify] = useState({});

  useEffect(() => {
    dispatch(actions.clearOrderSubscription());
    dispatch(actions.startFetchingOrders());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalClose = () => {
    setOrderToModify({});
  };

  return (
    <div>
      {!isEmpty(orderToModify) && (
        <Modal
          isOpened={!isEmpty(orderToModify)}
          onClose={onModalClose}
          body={
            <OrderStatusModal onModalClose={onModalClose} order={orderToModify} />
          }
        />
      )}
      <DataTable
        usePagination
        align="left"
        headers={[
          {
            id: "status",
            name: "Estado",
            style: {
              width: "20%",
            },
          },
          {
            id: "orderId",
            name: "Orden",
            style: {
              width: "20%",
            },
          },
          {
            id: "name",
            name: "Cliente",
            style: {
              width: "20%",
            },
          },
          {
            id: "companyName",
            name: "Compañía",
            style: {
              width: "20%",
            },
          },
          {
            id: "modify",
            name: "",
            style: {
              width: "10%",
            },
          },
        ]}
        rows={(orders || []).map((order) => ({
          ...order,
          companyName: !isEmpty(order.company) ? order.company.name : "",
          modify: (
            <div className="company-branches-links">
              <div onClick={() => setOrderToModify(order)}>
                Modificar Estado
              </div>
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default OrderStatus;
