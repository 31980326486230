import React, { useEffect, useRef } from "react";
import { BIKER_ICON, DELIVERY_ICON } from "../../constants/constants";
import mapTheme from "../../assets/mapTheme";

const OrderTrackerMap = ({
  bikerLocation,
  orderLocation,
  order,
  onModalClose,
}) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  const convertToLatLng = (geoPoint) => {
    return {
      lat: geoPoint._lat,
      lng: geoPoint._long,
    };
  };

  useEffect(() => {
    if (bikerLocation && orderLocation && window.google) {
      const bikerLatLng = convertToLatLng(bikerLocation);
      const orderLatLng = convertToLatLng(orderLocation);
      const center = {
        lat: bikerLatLng.lat,
        lng: bikerLatLng.lng,
      };

      mapInstance.current = new window.google.maps.Map(mapRef.current, {
        center,
        zoom: 12,
        styles: mapTheme,
      });

      const bikerIcon = {
        url: BIKER_ICON,
        scaledSize: new window.google.maps.Size(40, 40),
      };

      const orderIcon = {
        url: DELIVERY_ICON,
        scaledSize: new window.google.maps.Size(40, 40),
      };

      new window.google.maps.Marker({
        position: bikerLatLng,
        map: mapInstance.current,
        icon: bikerIcon,
      });

      new window.google.maps.Marker({
        position: orderLatLng,
        map: mapInstance.current,
        icon: orderIcon,
      });
    }
  }, [bikerLocation, orderLocation]);

  return (
    <div className="modal-container modal-container-height">
      <div style={{ width: "100%", height: "400px" }} ref={mapRef}></div>
      <div className="modal-title">
        <div className="modal-title-text">
          RASTREA LA ORDEN {order.orderId}
        </div>
        <button
          onClick={onModalClose}
          style={{
            padding: "8px 16px",
            backgroundColor: "#007BFF",
            color: "#FFF",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default OrderTrackerMap;
