import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import BranchForm from "./BranchForm";
import { SELECT_MAP_POINTS } from "../../constants/constants";
import { defaultFormFields } from "../../constants/branchConstants";
import { snackBarConst } from "../../constants/constants";
import branchService from "../../logic/services/branchServices";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
  address: yup.string().required("Dirección es requerida"),
  country: yup
    .object()
    .shape({ text: yup.string().required("El país es requerido") })
    .required(),
  region: yup
    .object()
    .shape({ text: yup.string().required("La región es requerida") })
    .required(),
  city: yup
    .object()
    .shape({ text: yup.string().required("La ciudad es requerida") })
    .required(),
});

const BranchModify = ({ match: { params } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [branch, setBranch] = useState({});
  const [prevMarkers, setPrevMarkers] = useState([]);
  const btnList = [
    {
      id: "cancel-branch",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.goBack();
        return;
      },
    },
    {
      id: "create-new-company",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "modificar sucursal",
    },
  ];

  const generateMarker = (lat, lng, mapPointOption) => ({
    position: {
      longitude: lng,
      latitude: lat,
    },
    showInfoWindow: true,
    infoWindow: mapPointOption.text,
    draggable: false,
    type: mapPointOption,
  });

  const onLoad = async (branchId) => {
    try {
      const branchRes = await branchService.getBranchByBranchId(branchId);
      setBranch({
        name: branchRes.name,
        description: branchRes.description,
        address: branchRes.address,
        country: {
          value: branchRes.CountryId,
          text: branchRes.countryName,
        },
        region: {
          value: branchRes.RegionId,
          text: branchRes.regionName,
        },
        city: {
          value: branchRes.CityId,
          text: branchRes.cityName,
        },
      });
      setPrevMarkers([
        {
          ...generateMarker(
            branchRes.point.coordinates[0],
            branchRes.point.coordinates[1],
            SELECT_MAP_POINTS.BRANCH
          ),
        },
        {
          ...generateMarker(
            branchRes.perimeter.coordinates[0][0][0],
            branchRes.perimeter.coordinates[0][0][1],
            SELECT_MAP_POINTS.POLYGON_1
          ),
        },
        {
          ...generateMarker(
            branchRes.perimeter.coordinates[0][1][0],
            branchRes.perimeter.coordinates[0][1][1],
            SELECT_MAP_POINTS.POLYGON_2
          ),
        },
        {
          ...generateMarker(
            branchRes.perimeter.coordinates[0][2][0],
            branchRes.perimeter.coordinates[0][2][1],
            SELECT_MAP_POINTS.POLYGON_3
          ),
        },
        {
          ...generateMarker(
            branchRes.perimeter.coordinates[0][3][0],
            branchRes.perimeter.coordinates[0][3][1],
            SELECT_MAP_POINTS.POLYGON_4
          ),
        },
      ]);
    } catch (e) {
      enqueueSnackbar(
        "Hubo un error al obtener la información de la sucursal, por favor, intente más tarde",
        {
          variant: "warning",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        }
      );
    }
    setIsLoading(false);
  };

  useOnLoad(params.branchId, onLoad);

  const onSubmit = async (branchToCreate) => {
    setIsLoading(true);
    try {
      
      const {
        name,
        description,
        address,
        CountryId,
        RegionId,
        CityId,
        point,
        perimeter,
      } = branchToCreate;
      await branchService.modifyBranch(
        params.branchId,
        name,
        description,
        address,
        CountryId,
        RegionId,
        CityId,
        point,
        perimeter,
        params.companyId
      );
      enqueueSnackbar("Sucursal actualizada correctamente", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          ...snackBarConst,
        },
        onClick: history.goBack(), 
      });
    } catch (e) {
      enqueueSnackbar(
        "Hubo un error al actualizar la sucursal, por favor, intenta más tarde",
        {
          variant: "warning",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        }
      );
    }
    setIsLoading(false);
  };

  return (
    <div title="modificar sucursal">
      <BlockUi tag="div" blocking={isLoading}>
        <BranchForm
          formFields={defaultFormFields.map((formField) => ({
            ...formField,
          }))}
          branch={branch}
          btnList={btnList}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          setBranch={setBranch}
          prevMarkers={prevMarkers}
          isEditing
        />
      </BlockUi>
    </div>
  );
};

const useOnLoad = (branchId, onLoad) => {
  useEffect(() => {
    if (!isEmpty(branchId)) {
      onLoad(branchId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default BranchModify;
