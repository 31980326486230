/* eslint-disable no-unused-vars */
import { useHistory } from "react-router-dom";
import { INPUT_TYPE } from "../../constants/constants";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/user";
import moment from "moment";
import BreadCrumb from "../../containers/navs/breadcrumb";
import DynamicForm from "../Design/Form/DynamicForm";
import isEmpty from "lodash/isEmpty";
import Alert from "@material-ui/lab/Alert";
import DataGrid, {
  Column,
  Grouping,
  Pager,
  Paging,
  SearchPanel,
  StateStoring,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Export,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CircularProgress from "@material-ui/core/CircularProgress";

const formFields = [
  {
    label: "FECHA DE INICIO",
    autofocus: true,
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "FECHA FINAL",
    autofocus: true,
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
];

const validationSchema = yup.object({});
const SummaryEmails = ({ init, gmail, failed, gettingGmailReports }) => {
  const history = useHistory();
  const [customField] = useState(formFields);
  const [filters, setFilters] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [filterError, setFilterError] = useState("");
  const [rows, setRows] = useState([]);
  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  const pageSizes = [10, 25, 50];
  useEffect(() => {}, [gmail]);

  const applyFilters = (valueFilters) => {
    setRows(valueFilters);
  };

  const onSubmit = async (values) => {
    setFilterError("");
    if (!isEmpty(values.startDate) && !isEmpty(values.endDate)) {
      const startDate = new Date(`${filters.startDate} 00:00:00`).getTime();
      const endDate = new Date(`${filters.endDate} 23:59:59`).getTime();
      gettingGmailReports(startDate, endDate);

      if (startDate > endDate) {
        setFilterError("Fecha de inicio debe ser antes que la fecha final");
        return;
      }
    }
    applyFilters(values);
  };

  const getDataGmail = () => {
    let sumData = {};
    if (gmail) {
      gmail.forEach((e) => {
        if (sumData[e.data.company.company.name]) {
          sumData[e.data.company.company.name] += 1;
        } else {
          sumData[e.data.company.company.name] = 1;
        }
      });
    }

    let data = [];
    data = Object.entries(sumData).map(([key, value]) => {
      return {
        name: key,
        value,
      };
    });
    return data;
  };

  return (
    <>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RESUMEN DE GMAILS"
      />
      {!isEmpty(filterError) && (
        <>
          <Alert severity="error">{filterError}</Alert>
          <br />
        </>
      )}
      <DynamicForm
        obj={filters}
        setState={setFilters}
        fields={customField}
        schema={validationSchema}
        buttons={btnList}
        onSubmit={onSubmit}
        btnAlignment="right"
        forceSetState
      />
      {init ? (
        <CircularProgress disableShrink />
      ) : (
        <>
          <ResponsiveContainer width="100%" height="30%">
            <BarChart
              width={500}
              height={300}
              data={getDataGmail()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" fontSize={"10px"} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="rgb(248, 139, 30)" />
            </BarChart>
          </ResponsiveContainer>
          <DataGrid
            dataSource={getDataGmail()}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            noDataText="NO HAY INFORMACIÓN"
          >
            <Column dataField="name" caption="COMPAÑIA" />
            <Column dataField="value" caption="CANTIDAD" />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={false} />

            <Summary recalculateWhileEditing={true}>
              <TotalItem
                column="name"
                displayFormat="Compañia: {0}"
                summaryType="count"
              />
            </Summary>
            <FilterPanel
              visible={true}
              texts={{
                createFilter: "CREAR FILTRO",
                clearFilter: "ELIMINAR FILTRO",
                filterEnabledHint: "CREACIÓN DE FILTROS",
              }}
            />
            <Pager
              showPageSizeSelector={true}
              displayMode={true}
              allowedPageSizes={pageSizes}
              showInfo={true}
              showNavigationButtons={true}
            />
            <Paging defaultPageSize={10} />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="summary-gmail"
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </>
      )}
    </>
  );
};

const { gettingGmail, getGmail, gettingGmailFail } = selectors;

export default connect(
  (state) => ({
    init: gettingGmail(state),
    gmail: getGmail(state),
    failed: gettingGmailFail(state),
  }),
  (dispatch) => ({
    gettingGmailReports(startDate, endDate) {
      dispatch(actions.gettingGmail(startDate, endDate));
    },
  })
)(SummaryEmails);
