import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { orderStatus } from "../constants/constants";
import bikerService from "../logic/services/bikerService";
import dateHelper from "../helpers/dateHelper";
import Axios from "axios";
const getOrdersArrivalTime = async (biker, values) => {
  if (!isEmpty(biker) && !isEmpty(values)) {
    try {
      const ordersResponse = await bikerService.getOptimalRouteByBikerIds([
        biker.id,
      ]);
      if (!isEmpty(ordersResponse)) {
        let dataToSet = [];
        ordersResponse.forEach((orderRes) => {
          orderRes.route.forEach((route) => {
            const order = values.find((ord) => ord.id === route.id);
            if (!isEmpty(order)) {
              let time = order.time;
              if (route.time != null) {
                time = dateHelper.secondsToHms(route.time + 10 * 60);
              }
              dataToSet = dataToSet.concat([
                {
                  ...order,
                  date: route.date,
                  time,
                },
              ]);
            }
          });
        });
        return dataToSet;
      }
    } catch (e) {
      // doSomething
    }
    return values;
  }
  return [];
};

const getArrivalTime = async (bikers, values, setData) => {                                                  
  let isTimeCalculated = true;
  let ordersResponse = []
  if(bikers.length !== 0 ){
    ordersResponse = await bikerService.getOptimalRouteByBikerIds(bikers);
  }
  const newValues = values.map(async (order) => {
    let calculatedTime = order.time;
    if (!order.isTimeCalculated) {
      if (!isEmpty(order.alias) && !isEmpty(order.locationRef)) {
        try {
          if (!isEmpty(order.createdAt) && !isEmpty(ordersResponse)) {
            if (!isEmpty(ordersResponse)) {
              let foundOrderResponse;
              let foundOrder;
              ordersResponse.forEach((orderRes) => {
                if (!isEmpty(orderRes.route)) {
                  orderRes.route.forEach((route) => {
                    if (route.id === order.id) {
                      foundOrder = route;
                      foundOrderResponse = orderRes;
                    }
                  });
                }
              });
              // const foundOrder =
              if (!isEmpty(foundOrder) && !isEmpty(foundOrderResponse)) {
                if (
                  !isEmpty(foundOrderResponse.route) &&
                  order.status !== orderStatus.ACCEPTED
                ) {
                  // Adding 10 minutes to travel
                  const bikerToOrder =
                    (foundOrder.time + 600) * 1000 +
                    Date.parse(foundOrder.date);
                  calculatedTime = bikerToOrder;
                } else if (order.status === orderStatus.ACCEPTED) {
                  if (!isEmpty(foundOrderResponse.backHome)) {
                    // Adding 3 minutes to travel
                    const bikerToBranch =
                      (foundOrderResponse.backHome.time + 180) * 1000 +
                      Date.parse(foundOrderResponse.backHome.date);
                    calculatedTime = bikerToBranch;
                  } else {
                    calculatedTime = "En Tienda";
                  }
                }
              }
            }
          } else {
            throw new Error("Calculate time manually");
          }
        } catch (e) {
          // Adding 20 minutes
          const date = moment(
            new Date((order.createdAt.seconds + 1200) * 1000)
          ).format("HH:mm:ss");
          calculatedTime = date;
          isTimeCalculated = false;
        }
      } else {
        isTimeCalculated = false;
        calculatedTime = "SIN PILOTO";
      }
    }
    return {
      ...order,
      time: calculatedTime,
      isTimeCalculated,
    };
  });
  const response = await Promise.all(newValues);
  setData(response);
};

const ordersHelper = {
  getArrivalTime,
  getOrdersArrivalTime,
};
export const linkOrderWithTime = async (id) => {
  try {
    let getTime = await Axios({
      url: "https://devapi.twowheelstogo.com/orders-service/routes",
      method: "PATCH",
      data: {
        ids: [`${id}`],
      },
    });
    return {
      id: getTime.data,
      error: null,
    };
  } catch (error) {
    return {
      error,
      id: null,
    };
  }
};

export default ordersHelper;
