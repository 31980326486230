import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: "20px",
  },
}));

const GraphicCompany = ({ ordersDay }) => {
  const classes = useStyles();

  const processData = (orders) => {
    // Crear un objeto para contar órdenes por hora
    const ordersByHour = {};

    // Iterar a través de las órdenes y contar por hora
    orders.forEach((order) => {
      // Obtener la hora de entrega (puedes ajustar esto según tus datos reales)
      const deliveredHour = order.deliveredAt.toDate().getHours();
      const deliveredMinute = order.deliveredAt.toDate().getMinutes();
      const hourString = `${deliveredHour}:${
        deliveredMinute < 30 ? "00" : "30"
      }`; // Redondear al intervalo de 30 minutos

      // Incrementar el contador para esa hora
      if (!ordersByHour[hourString]) {
        ordersByHour[hourString] = 0;
      }
      ordersByHour[hourString]++;
    });

    // Convertir el objeto en un arreglo de datos para Recharts
    const data = Object.keys(ordersByHour).map((hourString) => ({
      hour: hourString,
      orders: ordersByHour[hourString],
    }));

    // Ordenar el arreglo por hora en orden ascendente
    data.sort((a, b) => {
      const [hourA, minuteA] = a.hour.split(":").map(Number);
      const [hourB, minuteB] = b.hour.split(":").map(Number);

      if (hourA === hourB) {
        return minuteA - minuteB;
      }
      return hourA - hourB;
    });

    return data;
  };

  const data = processData(ordersDay);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          {"Ordenes entregadas por hora".toUpperCase()}
        </Typography>
      </CardContent>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="orders"
            name="Órdenes Entregadas"
            stroke="#FF5733"
            activeDot={{ r: 8 }}
            dot={{ strokeWidth: 3, fill: "#FF5733" }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default GraphicCompany;
