import { firebaseFirestore } from "./config";
import isEmpty from "lodash/isEmpty";
const db = firebaseFirestore;
const collection = "companies";

export const getCompanies = async (companyId) => {
  let query = db.collection(collection);
  let response = [];

  if (!isEmpty(companyId)) {
    const delivery = await query.doc(companyId).get();
    const meanwhile = delivery.data();
    meanwhile["id"] = delivery.id;
    response = response.concat([meanwhile]);
  } else {
    query = query.limit(250);
    query = await query.get();
    await query.docs.forEach((delivery) => {
      const meanwhile = delivery.data();
      meanwhile["id"] = delivery.id;

      response = response.concat([meanwhile]);
    });
  }

  return response;
};

export const createCompany = async (id, name) => {
  const response = await db.collection(collection).doc(id).set({
    name,
  });
  return response;
};

const companyFirebaseService = {
  getCompanies,
  createCompany,
};

export default companyFirebaseService;

export const getCompanieLink = async (id) => {
  try {
    let orderCompani = await db.collection(collection).doc(id).get();
    return {
      company: orderCompani.data(),
      error: null,
    };
  } catch (error) {
    return {
      error,
      company: null,
    };
  }
};
