import axios from "axios";
import { API_URL } from "../../constants/constants";

// const API_URL = "http://127.0.0.1:4040/twowheelstogo-572d7/us-central1/api";

// Obtener pricingConfig para una empresa específica
export const getPricingConfig = async (companyId) => {
  try {
    const response = await axios.get(`${API_URL}api/pricingConfig/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching pricingConfig:", error);
    throw error;
  }
};

// Obtener todos los pricingConfigs
export const getAllPricingConfigs = async () => {
  try {
    const response = await axios.get(`${API_URL}api/allPricingConfig`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all pricing configs:", error);
    throw error;
  }
};

// Crear o actualizar pricingConfig para una empresa específica
export const addPricingConfig = async ({ pricingConfig }) => {
  try {
    const response = await axios.post(
      `${API_URL}api/pricingConfig`,
      pricingConfig
    );
    return response.data;
  } catch (error) {
    console.error("Error creating or updating pricingConfig:", error);
    throw error;
  }
};

// Actualizar pricingConfig para una empresa específica
export const updatePricingConfig = async ({ pricingConfig }) => {
  try {
    const response = await axios.post(
      `${API_URL}api/pricingConfig`,
      pricingConfig
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating pricingConfig:",
      error.response?.data || error.message
    );
    throw error;
  }
};
