import React from "react";
import { CompanyModel } from "../../models";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 200,
    height: 250,
    position: "relative",
  },
  img: {
    zIndex: 10,
    margin: "auto",
    marginBottom: "-20px",
  },
  logo: {
    zIndex: 10,
    margin: "auto",
  },
  header: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: 80,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  content: {
    flexDirection: "column",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    top: 80,
    right: 0,
    width: "100%",
    height: 130,
  },
  bikerDetails: {
    textAlign: "center",
  },
  button: {
    margin: "auto",
  },
  shapeLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    clipPath: "polygon(0 0, 80% 0, 35% 50%, 0 12%)",
  },
  shapeTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    clipPath: "polygon(0 12%, 0 86%, 35% 50%)",
    backgroundColor: "#808080",
  },
  ellipse: {
    background: "rgba(242,103,36,0.18)",
    width: "120%",
    height: "100px",
    borderRadius: "50%",
    position: "absolute",
    bottom: "-50px",
    left: "-10%",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    objectFit: "fill",
    padding: "20px",
    backgroundColor: "white",
  },
}));
const CompanyCard = ({ details = new CompanyModel() }) => {
  const classes = useStyles();
  const history = useHistory();
  const onClick = () => {
    history.push(`${ROUTES.COMPANY_BRANCHES}/${details.id}`);
  };
  return (
    <Card>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.circle} />
          <div className={classes.shapeLeft} />
          <div className={classes.shapeTop} />
          {/* <div className={classes.img}>
        <Avatar className={classes.large} alt={details.name} src={details.logo}/>
                </div> */}
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#ffffff",
              margin: "auto",
              zIndex: 10,
              fontFamily: "Lato",
            }}
          >
            {details.name}
          </p>
        </div>
        <CardContent className={classes.content}>
          <img
            src={details.logo}
            alt={details.name}
            height="90px"
            className={classes.logo}
          />
          {/* <p style={{fontSize:'18px',fontWeight:'600',color:"#808080"}}>{details.name}</p>
                <small>{details.description}</small> */}
        </CardContent>
        <div className={classes.footer}>
          <div className={classes.ellipse} />
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={onClick}
          >
            Ver Empresa
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default CompanyCard;
