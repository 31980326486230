import axios from "axios";
import { isEmpty } from "lodash";
import { DEV_API_URL } from "../../constants/constants";

const service = "companies-service";

const createBranch = async (
  name,
  description,
  address,
  CountryId,
  RegionId,
  CityId,
  point,
  perimeter,
  CompanyId
) => {
  const response = await axios.post(
    `${DEV_API_URL}/${service}/branch-offices`,
    {
      name,
      description: isEmpty(description) ? undefined : description,
      address,
      CountryId,
      RegionId,
      CityId,
      point,
      perimeter,
      CompanyId,
      enable: true,
    }
  );
  return response.data;
};

const deleteBranch = async (id) => {
  const response = await axios.patch(
    `${DEV_API_URL}/${service}/branch-offices/${id}`,
    {
      enable: false,
    }
  );
  return response.data;
};

const modifyBranch = async (
  id,
  name,
  description,
  address,
  CountryId,
  RegionId,
  CityId,
  point,
  perimeter,
  CompanyId
) => {
  console.log(perimeter)
  const response = await axios.patch(
    `${DEV_API_URL}/${service}/branch-offices/${id}`,
    {
      name,
      description: isEmpty(description) ? undefined : description,
      address,
      CountryId,
      RegionId,
      CityId,
      point,
      perimeter,
      CompanyId,
      enable: true,
    }
  );
  return response.data;
};

const getBranchByBranchId = async (id) => {
  const response = await axios.get(
    `${DEV_API_URL}/${service}/branch-offices/${id}`
  );
  return response.data;
};

const branchService = {
  getBranchByBranchId,
  createBranch,
  modifyBranch,
  deleteBranch,
};

export default branchService;
