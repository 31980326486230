import { put, takeEvery } from "redux-saga/effects";
import {
    updateInfoControllerServices,
    findEmailAuth,
    updatePasswordControllerServices,
    createdUsersPilotsServices,
    createdImage,
    passwordPilot,
    gettingOrdersAll,
    gettingAccountsUsersAndControllers,
    gettingSMSReports,
    gettingBikerDeliveries, 
    gettingBikerOrders,
    gettingGmailReportData
} from "../services/usersServices"
import * as types from "../types/user";
import * as actions from "../actions/user";

//////////////////////////////////////
// update controller info this is the saga
//////////////////////////////////////
function* updateInfoSaga(action){
    try {
        let res = yield updateInfoControllerServices(action.payload);
        const {data} = res
        if(data){
            yield put(actions.infoController(data))
        }else{
            yield put(actions.infoController('Error when you try update the controller'))
        }
    } catch (error) {
        yield put(actions.infoFailedController);
    }
}

export function* watchUpdateInfoController(){
    yield takeEvery(types.UPDATE_INFO_CONTROLLER_STARTED, updateInfoSaga)
}

//////////////////////////////////////////////
// find email controller and pilot saga()
//////////////////////////////////////////////

function* findEmailSaga(action){
    try {
        let response = yield findEmailAuth(action.payload);
        const {data} = response;  
        if(data){
            yield put(actions.emailFound(data))
        }else{
            yield put(actions.emailFound('Error when you try getting emails'))
        }
    } catch (error) {
        yield put(actions.emailFailedFind('Error when you try getting emails'));
    }
}

export function* watchFindEmailSaga(){
    yield takeEvery(types.FIND_EMAIL_AUTH_STARTED, findEmailSaga);
}

function* updatePasswordController(action){
    try {
        let response = yield updatePasswordControllerServices(action.payload);
        const {data} = response;  
        if(data){
            yield put(actions.passwordController(data))
        }else{
            yield put(actions.passwordController('Error when you try update the controller'))
        }
    } catch (error) {
        yield put(actions.passwordFailedController('Error when you try update the controller'));
    }
}

export function* watchPasswordController(){
    yield takeEvery(types.UPDATE_PASSWORD_CONTROLLER_STARTED, updatePasswordController);
}



function* createdUsersPilots(action){
    try {
        let response = yield createdUsersPilotsServices(action.payload);
        const {data} = response;  
        if(data){
            yield put(actions.craetedPilot(data))
        }else{
            yield put(actions.craetedPilot('Error when you try creating Bikers'))
        }
    } catch (error) {
        yield put(actions.creatingFailed('Error when you try creating Bikers'));
    }
}

export function* watchCreatedUsersPilots(){
    yield takeEvery(types.CREATE_USER_PILOT_STARTED, createdUsersPilots);
}

/// creating image in firebsae


function* createdImagePilots(action){
    try {
        let response = yield createdImage(action.payload);
        const {data} = response;  
        if(data){
            yield put(actions.createdImage(data))
        }else{
            yield put(actions.createdImage('Error when you try created Image user'))
        }
    } catch (error) {
        yield put(actions.creatingFailed('Error when you created Image the user'));
    }
}

export function* watchCreatedImagesPilots(){
    yield takeEvery(types.CREATED_IMAGE_STARTED, createdImagePilots);
}

/// update password


function* updatePasswordSaga(action){
    try {
        let response = yield passwordPilot(action.payload);
        const {data} = response;  
        if(data){
            yield put(actions.passwordBiker(data))
        }else{
            yield put(actions.passwordBiker('Error when you try update user'))
        }
    } catch (error) {
        yield put(actions.passwordFailedBiker('Error when you try update user'));
    }
}

export function* watchUpdatePasswordSaga(){
    yield takeEvery(types.UPDATE_PASSWORD_PILOT_STARTED, updatePasswordSaga);
}


/////////////////////////
// get all orders
/////////////////////////

function* getAllOrdesSaga(action){
    try {
        let response = yield gettingOrdersAll();
        const {data} = response;  
        if(data){
            yield put(actions.getOrders(data))
        }else{
            yield put(actions.getOrders('Error when you try update user'))
        }
    } catch (error) {
        yield put(actions.getOrdersFailed('Error when you try update user'));
    }
}

export function* watchGetOrders(){
    yield takeEvery(types.GETTING_ORDERS_STARTED, getAllOrdesSaga);
}

/*
    ACCOUNT USER ACCOUNT
*/

function* gettingUserAccountSummary(){
    let response = yield gettingAccountsUsersAndControllers();
    const {data, error} = response;  
    if(!error){
        yield put(actions.getUserAccount(data))
    } else{
        yield put(actions.getUserAccountFailed(error))
    }
}

export function* watchGettingUserAccountSummary(){
    yield takeEvery(types.GETTING_USER_ACCOUNT_STARTED, gettingUserAccountSummary);
}

/*
    SMS
*/

function* gettingSMSReport(action){
    let {startDate, endDate} = action.payload;
    let response = yield gettingSMSReports(startDate, endDate);
    const {data, error} = response;
    if(!error){
        yield put(actions.getSms(data))
    } else{
        yield put(actions.getSmsFailed(error))
    }
}

export function* watchGettingSMSReport(){
    yield takeEvery(types.GETTING_SMS_STARTED, gettingSMSReport);
}

/*
    SUMMARY BIKERS DELIVERIES
*/

function* gettingBikersDeliveries(){
    let response = yield gettingBikerDeliveries();
    const {data, error} = response;
    if(!error){
        yield put(actions.getDeliveriesBiker(data))
    } else{
        yield put(actions.getDeliveriesBikerFailed(error))
    }
}

export function* watchGettingBikersDeliveries(){
    yield takeEvery(types.GETTING_SUMMARY_DELIVERIES_BIKER_STARTED, gettingBikersDeliveries);
}

function* gettingOrders(action){
    let {startDate, endDate} = action.payload;
    let response = yield gettingBikerOrders(startDate, endDate);
    const {data, error} = response;
    if(!error){
        yield put(actions.getOrdersSummary(data))
    } else{
        yield put(actions.getOrdersSummaryFailed(error))
    }
}

export function* watchGettingOrders(){
    yield takeEvery(types.GETTING_SUMMARY_ORDERS_STARTED, gettingOrders);
}

/*
    GMAIL
*/

function* gettingGmailReport(action){
    let {startDate, endDate} = action.payload;
    let response = yield gettingGmailReportData(startDate, endDate);
    const {data, error} = response;
    if(!error){
        yield put(actions.getGmail(data))
    } else{
        yield put(actions.getGmailFailed(error))
    }
}

export function* watchGettingGmailReport(){
    yield takeEvery(types.GETTING_SUMMARY_GMAILS_STARTED, gettingGmailReport);
}