class BikerModel {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.phone = props.phone;
    this.mongoId = props.mongoId;
    this.actualPosition = props.actualPosition;
    this.actualResume = props.actualResume;
    this.alert = props.alert;
    this.branchId = props.branchId;
    this.company = props.company;
    this.companyId = props.companyId;
    this.counters = props.counters;
    this.odometer = props.odometer;
    this.pushToken = props.pushToken;
    this.total_traveled = props.total_traveled;
    this.type = props.type;
    this.qualified = props.qualified;
    this.raitings = props.raitings;
  }
}
export default BikerModel;
