import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    textMeaning: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "16px",
    },
    textTitle: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "20px",
      paddingBottom: "20px",
      paddingTop: "5px",
      textAlign: "left",
      fontWeight: 600,
    },
    subTitle: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "16px",
      fontWeight: 700,
    },
    positionText: {
      display: "flex",
      flexDirection: "row",
    },
    spacingFirtsTextSubtitle: {
      width: "6%",
    },
    spacingSecondTextInformation: {
      width: "40%",
    },
    titleOe:{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",  
    },
    titleAlls:{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
    },
    boxStyle:{
        width: "50%",
        height: "200px",
        margin: "5px",
        padding: "5px",
        borderRight: "2px solid #AEB6BF",
    },
    boxStylePrincipal:{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#dcdcdc",
        borderRadius: "20px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px"
    }
  }));


  export {
    useStyles
  }