/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Design/Card";
import TopCompanies from "../Company/TopCompanies";
import TopBikers from "../Biker/TopBikers";
import TopCircle from "../Circle/TopCircle";
import DataTable from "../Design/DataTable";
import Expandable from "../Design/Expandable";
import * as actions from "../../logic/actions/orders";
import * as selectors from "../../logic/reducers";
import { getController } from "../../logic/reducers";
import { orderStatus } from "../../constants/constants";
import dateHelper from "../../helpers/dateHelper";
import { isEmpty } from "lodash";

const getRandomColor = () => {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const DailyPerformance = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectors.getHistoryOrders);
  const controller = useSelector(getController);
  const [dailyPerformanceData, setDailyPerformanceData] = useState({});

  useOnLoad(dispatch);
  useOnOrdersLoaded(orders, setDailyPerformanceData);

  const renderCard = (key, title, body) => (
    <div
      className="card-container card-container-5 no-border-bottom vertical-top"
      style={{ background: getRandomColor() }}
      key={key}
    >
      <Card
        bodyCssClases="sumary-body text-center"
        titleCssClases="text-center padding-top-sm padding-bottom-sm"
        cssClases="no-padding"
        noPadding
        title={<div className="uppercase margin-bottom-md bold">{title}</div>}
        body={body}
      />
    </div>
  );

  const renderCompanies = () => {
    if (isEmpty(dailyPerformanceData)) return <></>;
    return Object.keys(dailyPerformanceData).map((key) => {
      return renderCard(
        key,
        dailyPerformanceData[key].companyName,
        <>
          <div>{`Cantidad de Pilotos: ${dailyPerformanceData[key].bikers.length}`}</div>
          <div>{`Ordenes Por Piloto: ${(
            dailyPerformanceData[key].orders.length /
            dailyPerformanceData[key].bikers.length
          ).toFixed(2)}`}</div>
          <div>{`Cantidad de Ordenes Entregadas: ${dailyPerformanceData[key].orders.length}`}</div>
          <br />
          <br />
        </>
      );
    });
  };

  const renderBikers = () => {
    return Object.keys(dailyPerformanceData).map((key) => {
      const dataTable = (
        <DataTable
          headers={[
            {
              id: "name",
              name: "2WHEELER",
              style: {
                width: "33.3333%",
              },
            },

            {
              id: "quantity",
              name: "CANTIDAD DE ORDENES",
              style: {
                width: "33.3333%",
              },
            },
            {
              id: "average",
              name: "TIEMPO DE ENTREGA PROMEDIO",
              style: {
                width: "33.3333%",
              },
            },
          ]}
          rows={dailyPerformanceData[key].bikers.map((biker) => ({
            ...biker,
            average: biker.average
              ? dateHelper.secondsToHms(biker.average / biker.quantity)
              : "00:00:00",
          }))}
          align="left"
        />
      );
      if (!isEmpty(controller.companyId) || !isEmpty(controller.branchId)) {
        return (
          <div key={`controller-${key}`}>
            <br />
            {dataTable}
            <br />
          </div>
        );
      }
      return (
        <Expandable
          key={`expandable-${key}`}
          title={`Pilotos: ${dailyPerformanceData[key].companyName}`}
          defaultExpanded={false}
        >
          {dataTable}
        </Expandable>
      );
    });
  };

  return (
    <>
      {isEmpty(controller.companyId) && isEmpty(controller.branchId) && (
        <TopCompanies orders={orders} />
      )}
      <TopBikers orders={orders} />
      <TopCircle orders={orders} />
      {renderCompanies()}
      {renderBikers()}
    </>
  );
};

const useOnOrdersLoaded = (orders, setDailyPerformanceData) => {
  useEffect(() => {
    if (!isEmpty(orders)) {
      const performance = {};
      orders.forEach((order) => {
        if (!isEmpty(order) && !isEmpty(order.company)) {
          let bikers = (performance[order.company.id] || {}).bikers || [];
          let orders = (performance[order.company.id] || {}).orders || [];
          const bikerIndex = bikers.findIndex(
            (biker) => biker.id === order.alias.id
          );
          let orderTime = 0;
          if (!isEmpty(order.deliveredAt) && !isEmpty(order.createdAt)) {
            orderTime = order.deliveredAt.seconds - order.createdAt.seconds;
          }
          if (bikerIndex < 0) {
            bikers = [
              ...bikers,
              {
                ...order.alias,
                average: orderTime,
                quantity: 1,
              },
            ];
          } else {
            bikers[bikerIndex] = {
              ...bikers[bikerIndex],
              average: bikers[bikerIndex].average + orderTime,
              quantity: bikers[bikerIndex].quantity + 1,
            };
          }
          orders = [
            ...orders,
            {
              ...order,
            },
          ];
          performance[order.company.id] = {
            ...performance[order.company.id],
            bikers,
            orders,
            companyName: order.company.name,
          };
        }
      });
      setDailyPerformanceData(performance);
    }
  }, [orders]);
};

const useOnLoad = (dispatch) => {
  useEffect(() => {
    dispatch(
      actions.startFetchingHistoryOrders({
        statusFilter: orderStatus.DELIVERED,
      })
    );
  }, []);
};

export default DailyPerformance;
