import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getIsAuthenticated } from "../../logic/reducers";
function RedirectToLogin() {
  return <Redirect to="/login" />;
}

function PrivateRoute({ Component, ...rest }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  if (!isAuthenticated) {
    return <Route component={RedirectToLogin} />;
  }
  // return <Route {...rest} render={(props) => <Component {...props} />} />
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default PrivateRoute;
