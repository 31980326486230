/* eslint-disable default-case */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import Expandable from "../Design/Expandable";
import DynamicForm from "../Design/Form/DynamicForm";
import DataTable from "../Design/DataTable";
import BlockUi from "react-block-ui";
import Alert from "@material-ui/lab/Alert";
import { Button } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { getAPIToken } from "../../logic/reducers";
import orderService from "../../logic/services/orders";
import AssignBikerModal from "./AssignBikerModal";
import { uploadImage } from "../../firebase/image";
import { INPUT_TYPE, FIRESTORAGE } from "../../constants/constants";

const FILTERS = {
  DEPTO: {
    value: "depto",
    text: "Departamento",
  },
  MUNIC: {
    value: "munic",
    text: "Municipio",
  },
  ZONE: {
    value: "zone",
    text: "Zona",
  },
};

const formFields = [
  {
    label: "Agrupar por",
    autofocus: true,
    name: "groupBy",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [FILTERS.DEPTO, FILTERS.MUNIC, FILTERS.ZONE],
  },
];

const validationSchema = yup.object({});

const OrderImport = () => {
  const apiToken = useSelector(getAPIToken);
  const [file, setFile] = useState();
  const [customError, setCustomError] = useState();
  const [customSuccess, setCustomSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderToModify, setOrderToModify] = useState({});
  const [filters, setFilters] = useState({
    groupBy: FILTERS.DEPTO,
  });
  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "Agrupar",
    },
  ]);
  const [orders, setOrders] = useState([]);
  const [columns, setColumns] = useState();
  const [groupedOrders, setGroupedOrders] = useState([]);

  const onFileChanged = async (e) => {
    var regex = new RegExp("(.*?).(csv)$");
    if (!isEmpty(e.target.files)) {
      const file = e.target.files[0];
      if (!regex.test(file.name.toLowerCase())) {
        setCustomError("El archivo a importar debe ser tipo CSV");
        return;
      }
      setCustomError("");
      setFile(e.target.files[0]);

      const fileText = (await e.target.files[0].text()).split("\n");
      let dataTableRows = [];
      const dataTableColumns = fileText[0].split(",");
      // eslint-disable-next-line array-callback-return
      fileText.slice(1, fileText.length).map((rowText, rowIndex) => {
        const colText = rowText.trim().split(",");
        const customRow = {};
        for (let i = 0; i < dataTableColumns.length; i++) {
          customRow[dataTableColumns[i]] = colText[i];
        }
        dataTableRows = [...dataTableRows, customRow];
      });
      setOrders([]);
      setColumns(
        dataTableColumns.map((column) => ({
          id: column,
          name: column,
        }))
      );
      setRows(dataTableRows);
    }
  };

  const onUploadFile = async () => {
    setIsLoading(true);
    try {
      await uploadImage(FIRESTORAGE.ORDER_IMPORT, file);
      const orderResponse = await orderService.uploadCSVFile(file, apiToken);
      setCustomError();
      setFile();
      setRows([]);
      setColumns([]);
      setOrders(orderResponse);
      applyGrouping(filters, orderResponse);
      setCustomSuccess("Archivo subido con exito");
    } catch (e) {
      setCustomError("Algo ha salido mal, por favor intentelo mas tarde");
    }
    setIsLoading(false);
  };

  const renderGroupDataTable = (ordersToUse) => {
    return (
      <DataTable
        headers={[
          {
            id: "name",
            name: "Cliente",
          },
          {
            id: "address",
            name: "Direccion",
          },
          {
            id: "nota",
            name: "Descripcion",
          },
          {
            id: "biker",
            name: "2Wheeler",
          },
          {
            id: "depto",
            name: "Departamento",
          },
          {
            id: "munic",
            name: "Municipio",
          },
          {
            id: "zone",
            name: "Zona",
          },
          {
            id: "modify",
            name: "",
          },
        ]}
        rows={ordersToUse.map((order) => ({
          ...order.fields,
          modify: (
            <div className="company-branches-links">
              <div
                onClick={() => {
                  setOrderToModify(order);
                  setIsModalOpen(true);
                }}
              >
                Modificar
              </div>
            </div>
          ),
          id: order.id,
        }))}
      />
    );
  };

  const renderGroups = () => {
    if (isEmpty(groupedOrders)) return <></>;
    return Object.keys(groupedOrders).map((group) => (
      <Expandable
        key={`${group}-data-table`}
        title={group.toUpperCase()}
        defaultExpanded
      >
        {renderGroupDataTable(groupedOrders[group])}
        <br />
      </Expandable>
    ));
  };

  const renderDataTable = () => {
    if (!file || isEmpty(columns) || isEmpty(rows)) {
      return <></>;
    }
    return (
      <div>
        <div>
          <br />
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onUploadFile}
          >
              SUBIR ARCHIVO 
          </Button>
        </div>
        <div>
          <br />
          <DataTable headers={columns} rows={rows} />
        </div>
      </div>
    );
  };

  const applyGrouping = (filters, ordersToUse) => {
    const ordersToSet = {};
    ordersToUse.forEach((order) => {
      switch (filters.groupBy.value) {
        case FILTERS.DEPTO.value: {
          if (isEmpty(ordersToSet[order.fields.depto])) {
            ordersToSet[order.fields.depto] = [order];
            break;
          }
          ordersToSet[order.fields.depto] = [
            ...ordersToSet[order.fields.depto],
            order,
          ];
          break;
        }
        case FILTERS.MUNIC.value: {
          if (isEmpty(ordersToSet[order.fields.munic])) {
            ordersToSet[order.fields.munic] = [order];
            break;
          }
          ordersToSet[order.fields.munic] = [
            ...ordersToSet[order.fields.munic],
            order,
          ];
          break;
        }
        case FILTERS.ZONE.value: {
          const textToUse =
            order.fields.zone.toString() === "0"
              ? "Sin zona"
              : order.fields.zone;
          if (isEmpty(ordersToSet[textToUse])) {
            ordersToSet[textToUse] = [order];
            break;
          }
          ordersToSet[textToUse] = [...ordersToSet[textToUse], order];
          break;
        }
      }
    });
    setGroupedOrders(ordersToSet);
  };

  const onSubmit = (values) => {
    setFilters(values);
    applyGrouping(values, orders);
  };

  const renderCreatedOrders = () => {
    if (isEmpty(groupedOrders)) return <></>;
    return (
      <div>
        <div>
          <br />
          <DynamicForm
            obj={filters}
            fields={formFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
          />
          <br />
          {renderGroups()}
        </div>
      </div>
    );
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div title="IMPORTAR ORDENES">
      {isModalOpen && (
        <AssignBikerModal
          onModalClose={onModalClose}
          orderToModify={{ ...orderToModify }}
          setOrders={setOrders}
        />
      )}
      <BlockUi tag="div" blocking={isLoading}>
        <div>
          {!isEmpty(customError) && (
            <>
              <Alert severity="error">{customError}</Alert>
              <br />
            </>
          )}
          {!isEmpty(customSuccess) && (
            <>
              <Alert severity="success">{customSuccess}</Alert>
              <br />
            </>
          )}
          <input type="file" onChange={onFileChanged} />
        </div>
        {renderDataTable()}
        {renderCreatedOrders()}
      </BlockUi>
    </div>
  );
};

export default OrderImport;
