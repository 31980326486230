/* eslint-disable no-dupe-keys */
import React, { Fragment, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getController } from "../../logic/reducers";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Warning from "@material-ui/icons/Warning";
import Public from "@material-ui/icons/Public";
import Business from "@material-ui/icons/Business";
import AddIcon from "@material-ui/icons/Add";
import GroupIcon from "@material-ui/icons/Group";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import DescriptionIcon from "@material-ui/icons/Description";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DirectionsIcon from "@material-ui/icons/Directions";
import TodayIcon from "@material-ui/icons/Today";
import theme from "../../assets/theme";
import DLlogo from "../../assets/img/DLlogo.png";
import logo from "../../assets/img/logo.png";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/auth";
import { ROLES, ROUTES } from "../../constants/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    letterSpacing: "3px",
  },
  list: {
    marginTop: theme.spacing(3),
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
  logo: {
    marginTop: theme.spacing(6),
  },
}));

const navBarItems = [
  {
    id: "order-statuses",
    label: "CATALOGOS",
    route: ROUTES.CATALOG.ALL_CATALOGS,
    icon: <DeviceHubIcon />,
  },
  {
    id: "all-orders",
    label: "ÓRDENES EN RUTA",
    route: ROUTES.ALL_ORDERS,
    icon: <DirectionsIcon />,
  },
  {
    id: "alerts",
    label: "ALERTAS",
    route: ROUTES.ALERTS.HOME,
    icon: <Warning />,
  },
  {
    id: "history-orders",
    label: "ÓRDENES ENTREGADAS",
    route: ROUTES.HISTORY_ORDERS,
    icon: <AssignmentTurnedInIcon />,
  },
  {
    id: "map",
    label: "MAPA",
    route: ROUTES.MAP,
    icon: <Public />,
  },
  {
    id: "companies-resume",
    label: "COMPAÑÍAS",
    route: ROUTES.COMPANY,
    icon: <Business />,
  },

  {
    id: "bikers",
    label: "2WHEELERS",
    route: ROUTES.BIKERS,
    icon: <MotorcycleIcon />,
  },
  {
    id: "import-orders",
    label: "IMPORTAR ORDENES",
    route: ROUTES.ORDER_IMPORT,
    icon: <AddIcon />,
  },
  {
    id: "report-view",
    label: "REPORTE DE ORDENES",
    route: ROUTES.REPORT_VIEW,
    icon: <DescriptionIcon />,
  },
  {
    id: "daily-performance",
    label: "RENDIMIENTO DIARIO",
    route: ROUTES.DAILY_PERFORMANCE,
    icon: <TodayIcon />,
  },
  {
    id: "users",
    label: "USUARIOS",
    route: ROUTES.USERS.LIST,
    icon: <GroupIcon />,
  },

]; 

const navBarItemsFiltered = [
  {
    id: "alerts",
    label: "ALERTAS",
    route: ROUTES.ALERTS.HOME,
    icon: <Warning />,
  },
  {
    id: "companies-resume",
    label: "COMPAÑÍAS",
    route: ROUTES.COMPANY,
    icon: <Business />,
  },
  {
    id: "daily-performance",
    label: "RENDIMIENTO DIARIO",
    route: ROUTES.DAILY_PERFORMANCE,
    icon: <TodayIcon />,
  },
  // {
  //   id: "import-orders",
  //   label: "IMPORTAR ORDENES",
  //   route: ROUTES.ORDER_IMPORT,
  //   icon: <AddIcon />,
  // },
];

const NavBar = ({ isAuthenticated, handleLogOut, title, authEmail }) => {
  let history = useHistory();
  const classes = useStyles();
  const controller = useSelector(getController);
  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setSidebar(!sidebar);
  };

  const goToPage = (redirectTo) => {
    if (!isEmpty(redirectTo)) {
      if (redirectTo === ROUTES.ALL_ORDERS) {
        if (!isEmpty(authEmail)) {
          if (
            authEmail.split("@cbc.co").length > 1 ||
            authEmail.split("@onelinkbpo.com").length > 1 ||
            !isEmpty(controller.companyId) ||
            !isEmpty(controller.branchId)
          ) {
            history.push("/dashboards/company-resume");
            return;
          }
        }
        history.push("/all-orders");
        return;
      }
      history.push(redirectTo);
    }
  };

  const handleMenuIcon = () => {
    handleLogOut();
    goToPage();
  };

  const getMenuByEmail = () => {
    if (!isEmpty(authEmail)) {
      if (
        authEmail.split("@cbc.co").length > 1 ||
        authEmail.split("@onelinkbpo.com").length > 1
      ) {
        return navBarItemsFiltered;
      }
    }
    if (isEmpty(controller.companyId) && isEmpty(controller.branchId)) {
      if (controller.role === ROLES.ADMINISTRATOR || controller.role === ROLES.DEMO) {
        return navBarItems;
      } else if (controller.role === ROLES.CONTROLLER) {
        return navBarItems;
      }
    } else {
      if (controller.role === ROLES.ADMINISTRATOR || controller.role === ROLES.DEMO) {
        return navBarItemsFiltered.concat([
          {
            id: "report-view",
            label: "REPORTE DE ORDENES",
            route: ROUTES.REPORT_VIEW,
            icon: <DescriptionIcon />,
          },
        ]);
      } else if (controller.role === ROLES.CONTROLLER || controller.role === ROLES.USER) {
        return navBarItemsFiltered;
      }
    }
    return [];
  };

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            {isAuthenticated && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => handleClick()}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={logo}
              style={{
                width: "35px",
                height: "auto",
                marginRight: "10px",
                cursor: "pointer",
              }}
              alt="logo"
              onClick={() => goToPage(ROUTES.ALL_ORDERS)}
            />
            <Typography
              variant="h6"
              className={`${classes.title} cursor-pointer`}
            >
              <strong onClick={() => goToPage(ROUTES.ALL_ORDERS)}>
                DELIVERY LAB
              </strong>
            </Typography>
            {!isEmpty(title) && (
              <Typography variant="h6" className={classes.title}>
                <strong className="page-title navbar-title">{title}</strong>
              </Typography>
            )}
            {!isAuthenticated && (
              <Button onClick={() => goToPage(ROUTES.LOGIN)} color="inherit">
                Iniciar Sesión
              </Button>
            )}
            {isAuthenticated && (
              <Fragment>
                <IconButton onClick={handleMenuClick} color="inherit">
                  <AccountCircle />
                </IconButton>

                <Menu
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleMenuIcon()}>
                    <ListItemIcon>
                      <MeetingRoom fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar Sesión" />
                  </MenuItem>
                </Menu>
              </Fragment>
            )}
          </Toolbar>
        </AppBar>

        {isAuthenticated &&(
          <Drawer anchor="left" open={sidebar} onClose={() => handleClick()}>
          <img
            src={DLlogo}
            style={{ width: "250px", height: "auto" }}
            className={classes.logo}
            alt="logo"
          />
          <div
            className={classes.list}
            role="presentation"
            onClick={() => setSidebar(!sidebar)}
          >
            <List>
              <Divider />
              {getMenuByEmail().map((navBarItem) => (
                <React.Fragment key={navBarItem.id}>
                  <ListItem button onClick={() => goToPage(navBarItem.route)}>
                    <ListItemIcon>{navBarItem.icon}</ListItemIcon>
                    <ListItemText primary={navBarItem.label} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </div>
        </Drawer>
        )}
      </ThemeProvider>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: selectors.getIsAuthenticated(state),
    authEmail: selectors.getAuthEmail(state),
  }),
  (dispatch) => ({
    handleLogOut() {
      dispatch(actions.logOut());
    },
  })
)(NavBar);
