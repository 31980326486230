import isEmpty from "lodash/isEmpty";
import {
  MECHANIC_FAILURE,
  TIRE_FAIL,
  OUT_OF_GAS,
  ACCIDENT,
  ROBBERY_IMG,
  LOST_IMG,
  bikerAlert,
} from "../constants/constants";

const getBikersWithAlerts = (bikers) => {
  let bikersWithAlerts = [];
  (bikers || []).forEach((biker) => {
    if (!isEmpty(biker.alert)) {
      switch (biker.alert.toUpperCase()) {
        case bikerAlert.MECHANIC_FAILURE.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: MECHANIC_FAILURE,
            },
          ];
          break;
        }
        case bikerAlert.TIRE_FAIL.toUpperCase():
        case bikerAlert.TIRE_FAIL_2.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: TIRE_FAIL,
            },
          ];
          break;
        }
        case bikerAlert.OUT_OF_GAS.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: OUT_OF_GAS,
            },
          ];
          break;
        }
        case bikerAlert.ACCIDENT.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: ACCIDENT,
            },
          ];
          break;
        }
        case bikerAlert.LOST.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: LOST_IMG,
            },
          ];
          break;
        }
        case bikerAlert.THIEF.toUpperCase(): {
          bikersWithAlerts = [
            ...bikersWithAlerts,
            {
              ...biker,
              img: ROBBERY_IMG,
            },
          ];
          break;
        }
        default:
          break;
      }
    }
  });
  return bikersWithAlerts;
};

const bikerHelper = {
  getBikersWithAlerts,
};

export default bikerHelper;
