import { combineReducers } from "redux";

import * as types from "../types/auth";

const token = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return null;
    }

    case types.AUTHENTICATION_COMPLETED: {
      return action.payload.token;
    }

    case types.AUTHENTICATION_FAILED: {
      return null;
    }

    case types.LOGOUT: {
      return null;
    }

    default: {
      return state;
    }
  }
};

const isAuthenticating = (state = false, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return true;
    }

    case types.AUTHENTICATION_COMPLETED: {
      return false;
    }

    case types.AUTHENTICATION_FAILED: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return null;
    }

    case types.AUTHENTICATION_COMPLETED: {
      return null;
    }

    case types.AUTHENTICATION_FAILED: {
      return action.payload.error;
    }

    default: {
      return state;
    }
  }
};

const controller = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return {};
    }
    case types.AUTHENTICATION_COMPLETED: {
      return action.payload.controller;
    }
    case types.AUTHENTICATION_FAILED: {
      return {};
    }

    case types.LOGOUT: {
      return {};
    }

    default: {
      return state;
    }
  }
};

const email = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return {};
    }
    case types.AUTHENTICATION_COMPLETED: {
      return action.payload.email;
    }
    case types.AUTHENTICATION_FAILED: {
      return {};
    }

    case types.LOGOUT: {
      return {};
    }

    default: {
      return state;
    }
  }
};

const apiToken = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return {};
    }
    case types.AUTHENTICATION_COMPLETED: {
      return action.payload.apiToken;
    }
    case types.AUTHENTICATION_FAILED: {
      return {};
    }

    case types.LOGOUT: {
      return {};
    }

    default: {
      return state;
    }
  }
};

const generateToken = (state = false, action) => {
  switch (action.type) {
    case types.CREATING_USER_STARTED: {
      return true
    }
    case types.CREATING_USER_COMPLETED: {
      return false
    }
    case types.CREATING_USER_FAILED: {
      return false
    }
    default: {
      return state
    }
  }
}

const createdUser = (state = null, action) => {
  switch (action.type) {
    case types.CREATING_USER_COMPLETED: {
      return action.payload.datos;
    }
    default: {
      return state
    }
  }
}

const createdUserFailed = (state = false, action) => {
  // switch (action.type) {
  //   case types.CREATING_USER_STARTED: {
  //     return false
  //   }
  //   case types.CREATING_USER_COMPLETED: {
  //     return false
  //   }
  //   case types.CREATING_USER_FAILED: {
  //     return true
  //   }
  //   default: {
  //     return state
  //   }
  // }
  switch (action.type) {
    case types.CREATING_USER_FAILED:{
        return action.payload.error
    }
    default:{
        return state
    }
}
}

const auth = combineReducers({
  token,
  isAuthenticating,
  error,
  controller,
  email,
  apiToken,
  generateToken,
  createdUser,
  createdUserFailed
});

export default auth;

//  SELECTORS
export const getAPIToken = (state) => state.apiToken;
export const getAuthToken = (state) => state.token;
export const getAuthEmail = (state) => state.email;
export const getController = (state) => state.controller;
export const getIsAuthenticated = (state) => (state.token ? true : false);
export const getIsAuthenticating = (state) => state.isAuthenticating;
export const getAuthenticationError = (state) => state.error;

// Creating Users

export const creatingTokenAuth = (state) => state.generateToken;
export const createdUserAuth = (state) => state.createdUser;
export const createdUserFailedAuth = (state) => state.createdUserFailed;
