import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { Paper, Box } from "@material-ui/core";
import theme from "../../assets/theme";
import { CustomBikerCard } from "../../containers/cards";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import BlockUi from "react-block-ui";
import { fetchAllRaitings } from "../../firebase/rating";
import { getPilotByPilotId } from "../../firebase/pilots";
import isEmpty from "lodash/isEmpty";

const BikerQualification = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [bikerList, setBikerList] = useState([]);
  const loadData = async () => {
    setIsLoading(true);
    try {
      const bikerIdList = {};
      const ratings = await fetchAllRaitings();
      (ratings || []).forEach((rating) => {
        if (isEmpty(bikerIdList[rating.to])) {
          bikerIdList[rating.to] = [
            {
              ...rating,
            },
          ];
        } else {
          bikerIdList[rating.to] = [
            ...bikerIdList[rating.to],
            {
              ...rating,
            },
          ];
        }
      });
      let promises = [];
      Object.keys(bikerIdList)
        .map((bikerId) => bikerId)
        .forEach((bikerId) => {
          promises = [...promises, getPilotByPilotId(bikerId)];
        });
      const response = await Promise.all(promises);
      if (!isEmpty(response)) {
        setBikerList(
          response
            .filter((biker) => !isEmpty(biker))
            .map((biker) => {
              const raitings = bikerIdList[biker.biker.id];
              return { ...biker.biker, raitings };
            })
        );
      }
    } catch (e) {
      console.log("UN ERROR HA OCURRIDO", e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="CALIFICACIÓN DE PILOTOS"
      />

      <div className="container">
        <div className="container-body">
          <Grid container>
            <Grid item xs={12}>
              <Paper>
                <Box padding={1}>
                  <BlockUi tag="div" blocking={isLoading}>
                    <Grid container>
                      {(bikerList || []).map((biker, index) => {
                        return (
                          <Grid item sm={12} md={4} lg={4} key={index}>
                            <Box padding={1}>
                              <CustomBikerCard
                                details={biker}
                                key={index}
                                branchId={biker.branchId}
                                allowForm={false}
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </BlockUi>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default BikerQualification;
