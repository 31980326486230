/* eslint-disable no-unused-vars */
import isEmpty from "lodash/isEmpty";
import { firebaseAuth, firebaseFirestore } from "./config";


const db = firebaseFirestore;
const collection = "controllers";

export const signIn = async (email, password) => {
  try {
    let tokenId = null;

    let response = await firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );
    let user = await firebaseAuth.currentUser;
    tokenId = await firebaseAuth.currentUser.getIdToken(true);

    return {
      user: {
        email: user.email,
        uid: user.uid,
      },
      token: tokenId,
      error: null,
    };
  } catch (error) {
    let code = error.code;
    let message = error.message;

    return {
      user: null,
      token: null,
      error: {
        code,
        message,
      },
    };
  }
};

export const logOut = async () => {
  try {
    let response = await firebaseAuth.signOut();

    console.log("log out successful --> ");
  } catch (error) {
    console.log(error.message);
  }
};

export const getAuthUser = async () => {
  try {
    const currentUser = await firebaseAuth.currentUser;
    if (isEmpty(currentUser)) throw new Error("No user");
    const controller = await db
      .collection(collection)
      .doc(currentUser.uid)
      .get();
    const controllerData = controller.data();
    return {
      id: controller.id,
      companyId: controllerData.companyId,
      branchId: controllerData.branchId,
      type: controllerData.type,
      role: controllerData.role,
      name: `${controllerData.firstName} ${controllerData.lastName}`,
    };
  } catch (e) {
    console.log("e", e);
  }
};

