import axios from 'axios';
import { DEV_API_URL } from '../../constants/constants';

const countryCode_GT = 1;

const getCountries = async () => {
  const response = await axios.get(`${DEV_API_URL}/catalogs-service/countries?limit=1000`);
  return (response.data).data;
};

const getRegionsByCountryCode = async (countryCode = countryCode_GT) => {
  const response = await axios.get(`${DEV_API_URL}/catalogs-service/regions/countries/${countryCode}?limit=1000`);
  return (response.data).data;
};

const getCitiesByRegionIdByCountryCode = async (regionId, countryCode = countryCode_GT) => {
  const response = await axios.get(`${DEV_API_URL}/catalogs-service/cities/countries/${countryCode}/regions/${regionId}?limit=1000`);
  return (response.data).data;
};

const addressService = {
  getCountries,
  getRegionsByCountryCode,
  getCitiesByRegionIdByCountryCode,
};

export default addressService;
