/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import * as action from "../../../logic/actions/orders";
import Qualify from "./qualify";
import * as selectors from "../../../logic/reducers";
import {
  Grid,
  Typography,
  TextField,
  Button,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import "./index.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import swal from "sweetalert";
import { useParams } from "react-router-dom";


const EntregaLink = (props) => {
  const [Calification, setCalification] = useState(0);
  const [value, setValue] = React.useState("");
  const {id} = useParams();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const order = props.data;
  let json = {}
  json.Calification = Calification; 
  json.value = value; 
  const onClick = () => {
 
    props.fetchingOrderUpdate(id,json)
    swal(
      "Calificación enviada " + props.data?.name.toUpperCase(),
      " GRACIAS POR ELEGIRNOS",
      "success"
    );
  };

  const theme = createMuiTheme({
    typography: {
      fontFamily: "Lato",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {props.data?.Calification !== undefined && (
        <body
          style={{
            backgroundColor: `${
              props.imageLogo?.name === "POLLO BRUJO" ? "#000" : ""
            }`,
          }}
        >
          <div>
            <Grid
              container
              justify="center"
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
              >
                <Grid item xs={10} md={10} lg={8}>
                  <img
                    width="50"
                    height="auto"
                    src={`${props.imageLogo?.logo}`}
                    className="contenedorDelivery"
                    alt="logotipoempresa"
                  />
                  <center>
                    <CheckCircleIcon
                      style={{
                        color: "green",
                        paddingTop: "20px",
                        marginBottom: "15px",
                        width: "35px",
                        height: "auto",
                      }}
                    />
                  </center>
                  <strong>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: `${
                          props.imageLogo?.name === "POLLO BRUJO"
                            ? "#FFF"
                            : "#2980B9"
                        }`,
                        textAlign: "center",
                      }}
                    >
                      TU PEDIDO FUE ENTREGADO, FUE UN GUSTO ATENDERTE
                    </Typography>
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </body>
      )}
      {props.data?.Calification === undefined && (
        <Grid
          container
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            alignContent="center"
          >
            <Grid item xs={10} md={10} lg={10}>
              <img
                src={`${props.imageLogo?.logo}`}
                className="contenedorDelivery"
                alt="imageLogo"
              />
              <strong>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#000",
                    textAlign: "center",
                    marginBottom: "10px",
                    fontWeight: `${
                      props.imageLogo?.fontFinish === undefined
                        ? "500"
                        : props.imageLogo?.fontFinish
                    }`,
                  }}
                >{`${
                  props.imageLogo?.companyPhraseDelivered === undefined
                    ? "¿COMO CALIFICA EL SERVICIO?"
                    : props.imageLogo?.companyPhraseDelivered
                }`}</Typography>
              </strong>
              <Qualify changeRanking={setCalification} />
              <TextField
                id="outlined-multiline-static"
                label="Tu opinión(opcional)"
                multiline
                fullWidth
                rows={4}
                value={value}
                onChange={handleChange}
                variant="outlined"
                aligh="center"
                style={{
                  marginBottom: "15px",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              ></TextField>
              <Button
                fullWidth
                style={{ alignItems: "center" }}
                variant="contained"
                color="primary"
                type="submit"
                onClick={onClick}
              >
                ENVIAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  );
};

export default connect(
  (state) => ({
    update: selectors.getIsFetchingOrderLinkCompleted(state),
  }),
  (dispatch) => ({
    fetchingOrderUpdate(id, order) {
      dispatch(action.updateLinkStarted(id, order));
    },
  })
)(EntregaLink);
