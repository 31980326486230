import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
  MarkerClusterer,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import isEmpty from "lodash/isEmpty";
import { ThemeProvider } from "@material-ui/core/styles";
// import MapDirectionsRenderer from "./MapDirectionsRender";
import theme from "../../assets/theme";
import mapTheme from "../../assets/mapTheme";
import { GOOGLE_API_KEY } from "../../constants/constants";

const Map = ({
  markers,
  center,
  zoom,
  setZoom = () => {},
  preserveViewport = false,
  setPreserveViewport = () => {},
  onPlaceSelected,
  showPlaceSearch,
  height = "750px",
}) => {
  const [searchBox, setSearchBox] = useState();
  function handleZoomChanged() {
    if (this.getZoom() !== undefined) {
      setZoom(this.getZoom());
      if (!preserveViewport) {
        setTimeout(() => {
          setPreserveViewport(true);
        }, 3000);
      }
    }
  }

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = (e) => {
    if (onPlaceSelected) {
      const place = searchBox.getPlaces()[0];
      if (place) {
        if (!isEmpty(place.address_components)) {
          onPlaceSelected(place);
        } else {
          try {
            const geoCoder = new window.google.maps.Geocoder();
            geoCoder.geocode({ placeId: place.place_id }, (results, status) => {
              if (status === "OK") {
                onPlaceSelected(results[0]);
              }
            });
          } catch (e) {
            console.log("NO GOOGLES MAPS", e);
          }
        }
      }
    }
  };

  const getMarker = (marker, i, clusterer) => {
    return (
      <Marker
        key={`${marker.id}-${i}`}
        clusterer={clusterer}
        position={{
          lat: parseFloat(marker.position.latitude),
          lng: parseFloat(marker.position.longitude),
        }}
        icon={marker.icon}
        draggable={marker.draggable}
        onClick={() => (marker.onClick ? marker.onClick(marker) : null)}
        onDragEnd={(dragEvent) =>
          marker.onDragEnd ? marker.onDragEnd(dragEvent, marker) : null
        }
      >
        {marker.showInfoWindow && (
          <InfoWindow
            pixelOffset={"0"}
            position={{
              lat: parseFloat(marker.position.latitude),
              lng: parseFloat(marker.position.longitude),
            }}
            onCloseClick={() =>
              marker.onClick ? marker.onClick(marker) : null
            }
          >
            <div>{marker.infoWindow}</div>
          </InfoWindow>
        )}
      </Marker>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      {/* <LoadScript googleMapsApiKey="AIzaSyBsbuaZ4GRNZkquHV2W2wyo9Zume7N_hzc"> */}
      <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height,
          }}
          zoom={zoom}
          center={center}
          options={{
            styles: mapTheme,
          }}
          onZoomChanged={handleZoomChanged}
        >
          {showPlaceSearch && (
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="text"
                placeholder="Buscar dirección"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-120px",
                }}
              />
            </StandaloneSearchBox>
          )}
          {/* {markers.length > 1 && shouldCreateRoute && (
            <MapDirectionsRenderer
              places={markers}
              travelMode={"DRIVING"}
              preserveViewport={preserveViewport}
            />
          )} */}
          {markers
            .filter((markers) => !markers.isClusterer)
            .map((marker, i) => getMarker(marker, i))}
          {markers.filter((markers) => markers.isClusterer).length > 0 && (
            <MarkerClusterer>
              {(clusterer) =>
                markers
                  .filter((markers) => markers.isClusterer)
                  .map((marker, i) => getMarker(marker, i, clusterer))
              }
            </MarkerClusterer>
          )}
        </GoogleMap>
      </LoadScript>
    </ThemeProvider>
  );
};

export default Map;
