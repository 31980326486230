import {
  Receipt,
  CalendarTodayOutlined,
  DirectionsOutlined,
  AssignmentTurnedInOutlined,
  FolderOutlined,
  WarningOutlined,
  BuildOutlined,
  DeviceHubOutlined,
  DescriptionOutlined,
  AlarmOffOutlined,
  BlockOutlined,
  DeleteOutline,
  TodayOutlined,
  SportsMotorsportsOutlined,
  AssignmentIndOutlined,
  AddCircleOutline,
  RotateLeft,
  RestorePage,
  People,
  SportsMotorsports,
  Description,
  PermContactCalendar,
  Business,
  Assessment,
  Sms,
  Chat,
  Book,
  MailOutlineOutlined,
  DirectionsBike,
  PublicOutlined,
  GpsFixed,
  Toll,
} from "@material-ui/icons";

const adminMenu = [
  {
    id: "1",
    title: "RESÚMEN DE HOY",
    to: "/resume",
    icon: CalendarTodayOutlined,
  },
  // {
  //   id: "15",
  //   title: "ASISTENCIA",
  //   to: "/pilot-assistance",
  //   icon: AssignmentIndOutlined,
  // },
  {
    id: "2",
    title: "ORDENES",
    icon: Receipt,
    subItems: [
      {
        id: "3",
        title: "EN RUTA",
        to: "/all-orders",
        icon: DirectionsOutlined,
      },
      {
        id: "96",
        title: "EN RUTA ISHOP",
        to: "/orders-ishop-guatemala",
        icon: DirectionsOutlined,
      },
      // {
      //   id: "95",
      //   title: "EN RUTA SAN MARTIN",
      //   to: "/orders-san-martin",
      //   icon: DirectionsOutlined,
      // },
      {
        id: "18",
        title: "EN RUTA POLLO BRUJO",
        to: "/all-orders-pollo-brujo",
        icon: DirectionsOutlined,
      },
      {
        id: "4",
        title: "ENTREGADAS",
        to: "/history-orders",
        icon: AssignmentTurnedInOutlined,
      },
      {
        id: "13",
        title: "ABANDONADAS",
        to: "/abandoned-orders",
        icon: AlarmOffOutlined,
      },
      {
        id: "14",
        title: "CANCELADAS",
        to: "/canceled-orders",
        icon: BlockOutlined,
      },
      {
        id: "15",
        title: "ELIMINADAS",
        to: "/deleted-orders",
        icon: DeleteOutline,
      },
      {
        id: "16",
        title: "REAGENDADAS",
        to: "/reagend-orders",
        icon: TodayOutlined,
      },
    ],
  },
  {
    id: "5",
    title: "ALERTAS",
    to: "/alerts",
    icon: WarningOutlined,
  },
  // {
  //   id: "6",
  //   title: "MAPA",
  //   to: "/map",
  //   icon: PublicOutlined,
  // },
  {
    id: "6",
    title: "LOCALIZACION DE MOTORISTAS",
    to: "/biker-location",
    icon: GpsFixed,
  },
  {
    id: "8",
    title: "ADMINISTRACIÓN",
    icon: FolderOutlined,
    subItems: [
      {
        id: "9",
        title: "COMPAÑÍAS",
        to: "/companies",
        icon: BuildOutlined,
      },
      {
        id: "10",
        title: "CATÁLOGOS",
        to: "/catalogs",
        icon: DeviceHubOutlined,
      },
      {
        id: "12",
        title: "CIRCULOS",
        to: "/circles",
        icon: Toll,
      },
      // {
      //   id: "11",
      //   title: "USUARIOS",
      //   to: "/users",
      //   icon: SupervisedUserCircleOutlined,
      // },

      {
        id: "13",
        title: "REPORTE DE ORDENES",
        to: "/report",
        icon: DescriptionOutlined,
      },
      {
        id: "14",
        title: "IMPORTAR ORDENES",
        to: "/order-import",
        icon: DescriptionOutlined,
      },
      {
        id: "69",
        title: "HORARIOS MOTORISTAS",
        to: "/schedules-biker",
        icon: DirectionsBike,
      },
    ],
  },
  {
    id: "17",
    title: "CONTROLADORES-USUARIOS",
    icon: People,
    subItems: [
      {
        id: "50",
        title: "CREACIÓN",
        to: "/create",
        icon: AddCircleOutline,
      },
      {
        id: "19",
        title: "DATOS",
        to: "/controller-update-info",
        icon: RestorePage,
      },
      {
        id: "20",
        title: "CONTRASEÑA",
        to: "/controller-update-pass",
        icon: RotateLeft,
      },
    ],
  },
  {
    id: "21",
    title: "PILOTOS",
    icon: SportsMotorsports,
    subItems: [
      {
        id: "22",
        title: "CREACIÓN",
        to: "/pilot-create",
        icon: AddCircleOutline,
      },
      {
        id: "23",
        title: "CONTRASEÑA",
        to: "/pilot-update-pass",
        icon: RotateLeft,
      },
      {
        id: "7-1",
        title: "LISTA DE PILOTOS",
        to: "/bikers",
        icon: SportsMotorsportsOutlined,
      },
    ],
  },
];

const companyMenu = [
  {
    id: "1",
    title: "RENDIMIENTO DIARO",
    to: "/dashboards/company-resume",
    icon: CalendarTodayOutlined,
  },
  {
    id: "2",
    title: "ALERTAS",
    to: "/alerts",
    icon: WarningOutlined,
  },
  {
    id: "3",
    title: "SUCURSALES",
    to: "/companies",
    icon: BuildOutlined,
  },
];

const branchMenu = ({ branchId = "" }) => [
  {
    id: "1",
    title: "RENDIMIENTO DIARIO",
    to: `/dashboards/branch-resume/${branchId}`,
    icon: CalendarTodayOutlined,
  },
  {
    id: "2",
    title: "ORDENES",
    to: `/branch/${branchId}`,
    icon: AssignmentIndOutlined,
  },
  {
    id: "3",
    title: "LOCALIZACIÓN DE MOTORISTAS",
    to: `/localization-biker/${branchId}`,
    icon: GpsFixed,
  },
];

const ownerMenu = [
  {
    id: "1",
    title: "RESÚMEN DE HOY",
    to: "/resume",
    icon: CalendarTodayOutlined,
  },
  // {
  //   id: "15",
  //   title: "ASISTENCIA",
  //   to: "/pilot-assistance",
  //   icon: AssignmentIndOutlined,
  // },
  {
    id: "2",
    title: "ORDENES",
    icon: Receipt,
    subItems: [
      {
        id: "3",
        title: "EN RUTA",
        to: "/all-orders",
        icon: DirectionsOutlined,
      },
      {
        id: "96",
        title: "EN RUTA ISHOP",
        to: "/orders-ishop-guatemala",
        icon: DirectionsOutlined,
      },
      // {
      //   id: "95",
      //   title: "EN RUTA SAN MARTIN",
      //   to: "/orders-san-martin",
      //   icon: DirectionsOutlined,
      // },
      {
        id: "18",
        title: "EN RUTA POLLO BRUJO",
        to: "/all-orders-pollo-brujo",
        icon: DirectionsOutlined,
      },
      {
        id: "4",
        title: "ENTREGADAS",
        to: "/history-orders",
        icon: AssignmentTurnedInOutlined,
      },
      {
        id: "13",
        title: "ABANDONADAS",
        to: "/abandoned-orders",
        icon: AlarmOffOutlined,
      },
      {
        id: "14",
        title: "CANCELADAS",
        to: "/canceled-orders",
        icon: BlockOutlined,
      },
      {
        id: "15",
        title: "ELIMINADAS",
        to: "/deleted-orders",
        icon: DeleteOutline,
      },
      {
        id: "16",
        title: "REAGENDADAS",
        to: "/reagend-orders",
        icon: TodayOutlined,
      },
    ],
  },
  {
    id: "5",
    title: "ALERTAS",
    to: "/alerts",
    icon: WarningOutlined,
  },
  {
    id: "6",
    title: "MAPA",
    to: "/map",
    icon: PublicOutlined,
  },
  {
    id: "7",
    title: "LOCALIZACION DE MOTORISTAS",
    to: "/biker-location",
    icon: GpsFixed,
  },
  {
    id: "8",
    title: "ADMINISTRACIÓN",
    icon: FolderOutlined,
    subItems: [
      {
        id: "9",
        title: "COMPAÑÍAS",
        to: "/companies",
        icon: BuildOutlined,
      },
      {
        id: "12",
        title: "CIRCULOS",
        to: "/circles",
        icon: Toll,
      },
      {
        id: "10",
        title: "CATÁLOGOS",
        to: "/catalogs",
        icon: DeviceHubOutlined,
      },
      // {
      //   id: "11",
      //   title: "USUARIOS",
      //   to: "/users",
      //   icon: SupervisedUserCircleOutlined,
      // },

      {
        id: "13",
        title: "REPORTE DE ORDENES",
        to: "/report",
        icon: DescriptionOutlined,
      },
      {
        id: "14",
        title: "IMPORTAR ORDENES",
        to: "/order-import",
        icon: DescriptionOutlined,
      },
      {
        id: "69",
        title: "HORARIOS MOTORISTAS",
        to: "/schedules-biker",
        icon: DirectionsBike,
      },
    ],
  },
  {
    id: "17",
    title: "CONTROLADORES-USUARIOS",
    icon: People,
    subItems: [
      {
        id: "50",
        title: "CREACIÓN",
        to: "/create",
        icon: AddCircleOutline,
      },
      {
        id: "19",
        title: "DATOS",
        to: "/controller-update-info",
        icon: RestorePage,
      },
      {
        id: "20",
        title: "CONTRASEÑA",
        to: "/controller-update-pass",
        icon: RotateLeft,
      },
    ],
  },
  {
    id: "21",
    title: "PILOTOS",
    icon: SportsMotorsports,
    subItems: [
      {
        id: "22",
        title: "CREACIÓN",
        to: "/pilot-create",
        icon: AddCircleOutline,
      },
      {
        id: "23",
        title: "CONTRASEÑA",
        to: "/pilot-update-pass",
        icon: RotateLeft,
      },
      {
        id: "7-1",
        title: "LISTA DE PILOTOS",
        to: "/bikers",
        icon: SportsMotorsportsOutlined,
      },
    ],
  },
  {
    id: "26",
    title: "RESUMENES",
    icon: Description,
    subItems: [
      {
        id: "27",
        title: "REPORTE COMPANIAS",
        to: "/summary-company",
        icon: Business,
      },
      {
        id: "28",
        title: "REPORTE USUARIOS",
        to: "/summary-account-user",
        icon: PermContactCalendar,
      },
      {
        id: "29",
        title: "DASHBOARD USER",
        to: "/dashboard-account",
        icon: Assessment,
      },
      {
        id: "31",
        title: "REPORTE SMS",
        to: "/report-sms",
        icon: Sms,
      },
      {
        id: "32",
        title: "DASHBOARD SMS",
        to: "/dashboard-sms",
        icon: Chat,
      },
      {
        id: "33",
        title: "REPORTE DE MOTORISTAS",
        to: "/reporte-motorista",
        icon: Book,
      },
      {
        id: "34",
        title: "REPORTE DE GMAIL",
        to: "/reporte-gmail",
        icon: MailOutlineOutlined,
      },
      // {
      //   id: "35",
      //   title: "DASHBOARD DE GMAIL",
      //   to: "/dashboard-gmail",
      //   icon: AllInboxOutlined,
      // }
    ],
  },
];

const demoMenu = [
  // {
  //   id: "1",
  //   title: "RENDIMIENTO DIARO",
  //   to: "/dashboards/company-resume",
  //   icon: CalendarTodayOutlined,
  // },
  {
    id: "2",
    title: "ALERTAS",
    to: "/alerts",
    icon: WarningOutlined,
  },
  {
    id: "12",
    title: "ORDENES",
    icon: Receipt,
    subItems: [
      {
        id: "3",
        title: "EN RUTA",
        to: "/all-orders",
        icon: DirectionsOutlined,
      },
    ],
  },
  // {
  //   id: "3",
  //   title: "SUCURSALES",
  //   to: "/companies",
  //   icon: BuildOutlined,
  // },
  {
    id: "6",
    title: "MAPA",
    to: "/map",
    icon: PublicOutlined,
  },
];

const requestsMenu = [
  {
    id: "1",
    title: "DASHBOARD",
    to: "/dashboards/alertas",
    icon: CalendarTodayOutlined,
  },

  {
    id: "2",
    title: "ALERTAS",
    to: "/alertas/mapas",
    icon: WarningOutlined,
  },
];

export {
  adminMenu,
  companyMenu,
  branchMenu,
  ownerMenu,
  demoMenu,
  requestsMenu,
};
