import React, { useEffect, useState } from "react";
import { getBikers } from "../../firebase/orders";
import BikerListLocation from "./BikerListLocation";
import MapLocation from "./MapLocation";
import { makeStyles } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { GOOGLE_API_KEY } from "../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flexBasis: "100%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    marginBottom: "10px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const BikerLocation = () => {
  const [motoristaData, setMotoristaData] = useState([]);
  const [selectedMotorista, setSelectedMotorista] = useState(null);
  const [tabValue, setTabValue] = useState(0); // Valor de la pestaña seleccionada
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  
  const fetchBikersData = async () => {
    try {
      const querySnapshot = await getBikers();
      querySnapshot.onSnapshot((snapshot) => {
        setMotoristaData(
          snapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        );
      });
      setIsLoading(false);
    } catch (error) {
      console.error(`Error fetching bikers: ${error}`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Llama a la función para obtener los datos de los motoristas
    fetchBikersData();
  }, []);

  const motoristaComplete = motoristaData.filter((motorista) => {
    // return tabValue === 0
    //   ? motorista.Pertenece === "PROYECTOS"
    //   : motorista.Pertenece === "POLLO BRUJO";
    if (tabValue === 0) {
      return motorista.Pertenece === "PROYECTOS";
    } else if (tabValue === 1) {
      return motorista.Pertenece === "POLLO BRUJO";
    } else {
      return motorista.Pertenece === "ISHOP";
    }
  });

  const handleMotoristaClick = (motorista) => {
    // Actualiza el motorista seleccionado al hacer clic en su nombre
    setSelectedMotorista(motorista);
  };

  const handleTabChange = (event, newValue) => {
    // Cambia el valor de la pestaña seleccionada
    setTabValue(newValue);
  };

  const handleShowAllClick = () => {
    // Muestra todos los motoristas estableciendo selectedMotorista a null
    setSelectedMotorista(null);
  };

  return (
    <div>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="RASTREO DE PILOTOS"
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Pestañas para alternar entre "PROYECTOS" y "POLLO BRUJO" */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="PROYECTOS" />
            <Tab label="POLLO BRUJO" />
            <Tab label="ISHOP" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <BikerListLocation
                motoristas={motoristaComplete}
                selectedMotorista={selectedMotorista}
                onMotoristaClick={handleMotoristaClick}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowAllClick}
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                Mostrar Todos
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <div className={classes.mapContainer}>
        <MapLocation
          bikers={motoristaComplete}
          selectedBiker={selectedMotorista}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `750px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </div>
  );
};

export default BikerLocation;
