import React, { Fragment } from "react";
import DataGrid, { Column, Paging, Pager,FilterRow,SearchPanel } from "devextreme-react/data-grid";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import * as selector from "../../logic/reducers";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

function AssistanceTable({
  data,
  branch,
  fetchingPilots,
  pilotCompleted,
  general,
}) {
  const allowedPageSizes = [20, 40, 50];
  // const [pilots, setPilots] = useState({});
  // console.log(data)
  // useEffect(() => {
  //   if (!isEmpty(pilotCompleted)) {
  //     const _pilot = pilotCompleted;
  //     const clonePilot = { ...pilots };
  //     clonePilot[pilotCompleted?.id] = _pilot;
  //     setPilots(clonePilot);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pilotCompleted]);

  // const cellRenderBiker = ({ data }) => {
  //   if (!pilots[data.aliasId]) {
  //     fetchingPilots(data.aliasId);
  //   }
  //   return (
  //     <div>
  //       {(pilots[data.aliasId] && pilots[data.aliasId].name) || "SIN NOMBRE"}
  //     </div>
  //   );
  // };

  const cellRenderCreatedAt = ({ data }) => {
    const createdDate = new Date(
      (data.createdAt.seconds + data.createdAt.nanoseconds / 1000000000) * 1000
    );
    const momentDate = moment(createdDate).format("LLL");
    return <div>{momentDate}</div>;
  };

  const cellRenderTimeTotal = ({ data }) => {
    if (!isEmpty(data.initJourney) && !isEmpty(data.endJourney)) {
      let initJourneyDate = new Date(data.initJourney.seconds * 1000);
      let endJourneyDate = new Date(data.endJourney.seconds * 1000);
      initJourneyDate = moment(initJourneyDate);
      endJourneyDate = moment(endJourneyDate);

      const duration = moment.duration(endJourneyDate.diff(initJourneyDate));
      const hours = duration.asHours();
      return (
        <Grid
          container
          direction="center"
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <div>{hours}</div>
        </Grid>
      );
    } else {
      return <div>FALTA POR FINALIZAR</div>;
    }
  };

  const cellRenderInitialJourney = ({ data }) => {
    if (!isEmpty(data.initJourney)) {
      const initialJourney = new Date(data.initJourney.seconds * 1000);
      const momentInitialJourney = moment(initialJourney).format("LLL");
      return <div>{momentInitialJourney}</div>;
    }
  };

  const cellRenderEndJourney = ({ data }) => {
    if (!isEmpty(data.endJourney)) {
      const endJourney = new Date(data.endJourney.seconds * 1000);
      const momentInitialJourney = moment(endJourney).format("LLL");
      return <div>{momentInitialJourney}</div>;
    }
  };

  let filterValue = [];
  if (!isEmpty(branch) && general !== true) {
    filterValue = ["branch.name", "=", branch];
  }

  const cellRenderStatus = ({ data }) => {
    if ((data.status || "") === "Completado") {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#58D68D",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "16px",
          }}
        >
          COMPLETADO
        </Typography>
      );
    }
    if ((data.status || "") === "Pendiente") {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#D6EAF8",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "16px",
          }}
        >
          PENDIENTE
        </Typography>
      );
    }
    if ((data.status || "") === "Cancelado") {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#E59866",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "12px",
          }}
        >
          CANCELADO
        </Typography>
      );
    }

    if ((data.status || "") === "Iniciado") {
      return (
        <Typography
          style={{
            fontWeight: "bold",
            color: "black",
            weight: "10px",
            borderRadius: "20px",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#58D68D",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "16px",
          }}
        >
          INICIADO
        </Typography>
      );
    }
  };

  const cellRenderEndJourneyPilot = ({ data }) => {
    if (!isEmpty(data.statusTimes)) {
      if (!isEmpty(data.statusTimes.Completado)) {
        const endJourneyPilot = new Date((data.statusTimes.Completado.seconds+21600) * 1000);
        const momentEndJourneyPilot = moment(endJourneyPilot).format("MMMM Do YYYY, h:mm:ss a");
        return <div>{momentEndJourneyPilot}</div>
      } else {
        return <div>NO HA FINALIZADO</div>
      }
    } else {
      return <div>NO HA INICIADO</div>
    }
  }

  const cellRenderInitialJourneyPilot = ({ data }) => {
    if (!isEmpty(data.statusTimes)) {
      if (!isEmpty(data.statusTimes.Iniciado)) {
        const initJourneyPilot = new Date(((data.statusTimes.Iniciado.seconds+21600) * 1000));
        const momentInitialJourneyPilot = moment(initJourneyPilot).format("MMMM Do YYYY, h:mm:ss a");
        return <div>{momentInitialJourneyPilot}</div>
      } else {
        return <div>NO HA INICIADO</div>
      }
    } else {
      return <div>NO HA INICIADO</div>
    }
  }

  return (
    <Fragment>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={data}
        showBorders={true}
        width={"100%"}
        rowAlternationEnabled={true}
        defaultFilterValue={filterValue === [] ? false : filterValue}
        noDataText="NO ASISTENCIA DIARIA"
      >
        <Pager
          showPageSizeSelector={true}
          displayMode={true}
          allowedPageSizes={allowedPageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
        <FilterRow visible={true} />
        <SearchPanel visible={true} placeholder="BUSCAR" width={240} />
        <Paging enabled={true} defaultPageSize={20} />
        <Column
          dataField="alias.name"
          caption="NOMBRE PILOTOS"
          width={"auto"}
        />
        <Column dataField="branch.name" caption="SUCURSAL" width={"auto"} />
        <Column
          dataField=""
          caption="HORA CREADA"
          cellRender={cellRenderCreatedAt}
          width={"auto"}
        />
        <Column
          dataField=""
          caption="HORA INICIO"
          width={"auto"}
          cellRender={cellRenderInitialJourney}
        />
        <Column
          dataField=""
          caption="HORA FINAL"
          cellRender={cellRenderEndJourney}
          width={"auto"}
        />
        <Column
          dataField=""
          caption="HORAS TRABAJADAS"
          cellRender={cellRenderTimeTotal}
          width={"auto"}
        />
        <Column
          dataField=""
          caption="HORA PILOTO INICIO"
          cellRender={cellRenderInitialJourneyPilot}
          width={"auto"}
        />
        <Column
          dataField=""
          caption="HORA PILOTO FINAL"
          cellRender={cellRenderEndJourneyPilot}
          width={"auto"}
        />
        <Column
          dataField=""
          caption="ESTATUS"
          cellRender={cellRenderStatus}
          width={"auto"}
        />
      </DataGrid>
    </Fragment>
  );
}
export default connect(
  (state) => ({
    pilotCompleted: selector.getPilotLinkCompleted(state),
  }),
  (dispatch) => ({
    fetchingPilots(id) {
      dispatch(actions.pilotGetLinkStarted(id));
    },
  })
)(AssistanceTable);
