import React from "react";
import isEmpty from "lodash/isEmpty";
import { orderAlert, orderStatus } from "./constants";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import dateHelper from "../helpers/dateHelper";
import PersonIcon from "@material-ui/icons/Person";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRing";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOffOutlined";

export const ALL_ORDERS_COLUMNS = [
  // no correlativo
  {
    field: "No.",
    width: 50,
    renderCell: (param) => {
      return <div> {param.row.No}</div>;
    },
  },

  // COLUMNA DE ESTADO DE LA ORDEN
  {
    field: "status",
    headerName: "ESTADO",
    width: 130,
    renderCell: (param) => (
      <div
        style={{
          width: 140,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {renderStatusCell(param)}
      </div>
    ),
  },

  // COLUMNA DE TIEMPO
  {
    field: "time",
    headerName: "TIEMPO",
    width: 150,
    renderCell: (param) => renderTimeOnRute(param),
  },

  // COLUMNA DE ORDENID
  {
    field: "orderId",
    headerName: "ORDEN",
    width: 130,
    renderCell: (param) => {
      let icons = [];
      if (param.row.payMethod === "visa-link") {
        icons = [
          <PhonelinkRingIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      if (param.row.payMethod === "credit-card") {
        icons = [
          <CreditCardIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      if (param.row.payMethod === "cash") {
        icons = [
          <LocalAtmIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          {icons} {param.value}
        </div>
      );
    },
  },

  // COLUMNA DE EMPRESA
  { field: "company", headerName: "EMPRESA", width: 200 },

  // COLUMNA DE SUCURSAL
  { field: "branch", headerName: "SUCURSAL", width: 230 },

  //  COLUMNA DE CLIENTE
  {
    field: "name",
    headerName: "CLIENTE",
    width: 250,
    renderCell: (param) => {
      let icons = [];
      if (!isEmpty(param) && !isEmpty(param.row)) {
        if (
          !isEmpty(param.row.locationRef) &&
          param.row.locationRef.latitude === 14 &&
          param.row.locationRef.longitude === 14
        ) {
          icons = [<LocationOffIcon />];
        }
        if (isEmpty(param.row.biker)) {
          icons = [<MotorcycleIcon />];
        }
        if (
          (param.row.name || "").toUpperCase() === "ESPERANDO..." &&
          (param.row.orderId || "").toUpperCase() === "ESPERANDO..."
        ) {
          icons = [...icons, <KeyboardIcon />];
        }
      }
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          {icons} {param.value}
        </div>
      );
    },
  },

  // COLUMNA DE DIRECCION
  { field: "address", headerName: "DIRECCION", width: 800, flex: 1 },

  // COLUMNA DE TELEFONO
  {
    field: "phone",
    headerName: "TELEFONO CLIENTE",
    width: 200,
  },

  // COLUMNA DE AGENTE
  { field: "biker", headerName: "AGENTE", width: 250 },

  // COLUMNA DE OBSERVACIONES
  { field: "nota", headerName: "OBSERVACIONES", width: 500 },

  // COLUMNA DE TIPO DE ORDEN
  {
    field: "typeOrder",
    headerName: "TIPO",
    width: 90,
    renderCell: (param) => {
      let icons = [];
      if (
        param.row.typeOrder === "ORDEN CLIENTE" ||
        isEmpty(param.row.typeOrder)
      ) {
        icons = [
          <PersonIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      if (param.row.typeOrder === "TRASLADO") {
        icons = [
          <CompareArrowsIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      if (param.row.typeOrder === "PICK-UP") {
        icons = [
          <MarkunreadMailboxIcon
            style={{
              color: "grey",
            }}
          />,
        ];
      }
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          {icons}{" "}
        </div>
      );
    },
  },

  // COLUMNA DE ALERTA
  {
    field: "alert",
    headerName: "ALERTA",
    width: 130,
    renderCell: (param) => (
      <div
        style={{
          width: 140,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {renderAlertCell(param)}
      </div>
    ),
  },

  // COLUMNA DE MENSAJE DE TEXTO O GMAIL
  {
    field: "SMS/GMAIL",
    width: 150,
    renderCell: (p) => {
      let email = p.row.emailSended || undefined;
      let sms = p.row.smsSend || undefined;
      return (
        <div>
          {email !== undefined || sms !== undefined ? (
            email ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "center",
                }}
              >
                <CheckCircleOutlineIcon />
                CORREO
              </div>
            ) : (
              <div>
                {sms ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon />
                    SMS
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      alignItems: "center",
                    }}
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
            )
          ) : (
            <VoiceOverOffIcon />
          )}
        </div>
      );
    },
  },

  // ORDEN DE ESTADO
  {
    field: "ORDEN ACTIVA RASTREO",
    width: 250,
    renderCell: (p) => {
      return (
        <div>
          {p.row.status === "EN RUTA" ? (
            <div>
              {p.row.isActive ? <CheckCircleOutlineIcon /> : <CloseIcon />}
            </div>
          ) : (
            <PauseCircleOutlineIcon />
          )}
        </div>
      );
    },
  },

  // COLUMNA DE CIRCULO
  // {
  //   field: "circleValue",
  //   headerName: "CIRCULO",
  //   width: 100,
  //   renderCell: (param) => (
  //     <div
  //       style={{
  //         width: 140,
  //         marginLeft: 0,
  //         marginRight: 0,
  //       }}
  //     >
  //       {renderCircleCell(param)}
  //     </div>
  //   ),
  // },

  // COLUMNA DE ZONA
  { field: "zone", headerName: "ZONA", width: 150 },

  // COLUMNA DE MUNICIPIO
  { field: "munic", headerName: "MUNICIPIO", width: 250 },

  // COLUMNA DE MODIFICANDO
  { field: "modifyingBy", headerName: "MODIFICANDO", width: 250 },
];
export const ORDER_COLUMNS_BY_COMPANY = [
  { field: "orderId", headerName: "ORDEN", width: 200 },
  { field: "name", headerName: "CLIENTE", width: 200 },
  { field: "typeOrder", headerName: "TIPO DE ENTREGA", width: 250 },
  { field: "address", headerName: "DIRECCION", width: 250 },
  { field: "branch", headerName: "SUCURSAL", width: 250 },
  { field: "biker", headerName: "AGENTE", width: 250 },
  { field: "transportId", headerName: "PLACA PILOTO", width: 200 },
  { field: "bikerPhone", headerName: "TELÉFONO", width: 250 },
  {
    field: "time",
    headerName: "TIEMPO ESTIMADO DE ENTREGA",
    width: 250,
    renderCell: (param) => renderTimeOnRute(param),
  },
];

export const ORDER_DELIVERED_COLUMNS = [
  { field: "orderId", headerName: "ORDEN", width: 200 },
  { field: "name", headerName: "CLIENTE", width: 200 },
  { field: "typeOrder", headerName: "TIPO DE ENTREGA", width: 250 },
  { field: "address", headerName: "DIRECCION", width: 250 },
  { field: "branch", headerName: "SUCURSAL", width: 250 },
  { field: "biker", headerName: "AGENTE", width: 250 },
  { field: "deliveredTime", headerName: "HORA DE ENTREGA", width: 250 },
  { field: "totalDeliveredTime", headerName: "TIEMPO DE ENTREGA", width: 250 },
];

export const DELIVERED_ORDERS_COLUMNS = [
  {
    field: "status",
    headerName: "ESTADO",
    width: 130,
    renderCell: (param) => (
      <div
        style={{
          width: 140,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {renderStatusCell(param)}
      </div>
    ),
  },
  { field: "orderId", headerName: "ORDEN", width: 130 },
  { field: "name", headerName: "CLIENTE", width: 200 },
  { field: "company", headerName: "EMPRESA", width: 250 },
  { field: "biker", headerName: "AGENTE", width: 250 },
  { field: "address", headerName: "DIRECCION", width: 250 },
  {
    field: "time",
    headerName: "TIEMPO EN RUTA",
    width: 250,
    renderCell: (param) => renderTimeOnRute(param),
  },
  {
    field: "circleValue",
    headerName: "CIRCULO",
    width: 250,
    renderCell: (param) => (
      <div
        style={{
          width: 140,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {renderCircleCell(param)}
      </div>
    ),
  },
];
const renderAlertCell = (data) => {
  if (data.value.toLowerCase() === orderAlert.TO_BE_LATE.toLowerCase()) {
    return (
      <div className="order-notification order-alert-to-be-late">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderAlert.ON_TIME.toLowerCase()) {
    return (
      <div className="order-notification order-alert-on-time">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderAlert.VERIFY.toLowerCase()) {
    return (
      <div className="order-notification order-alert-verify">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderAlert.LATE.toLowerCase()) {
    return (
      <div className="order-notification order-alert-late">
        <small>{data.value}</small>
      </div>
    );
  } else {
    return (
      <div className="order-notification order-alert-default">
        <small>{data.value}</small>
      </div>
    );
  }
};
export const renderStatusCell = (data) => {
  if (data.value.toLowerCase() === orderStatus.NEW.toLowerCase()) {
    return (
      <div className="order-notification order-status-new">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderStatus.GOING.toLowerCase()) {
    return (
      <div className="order-notification order-status-going">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderStatus.CANCELLED.toLowerCase()) {
    return (
      <div className="order-notification order-status-cancelled">
        <small>{data.value}</small>
      </div>
    );
  } else if (data.value.toLowerCase() === orderStatus.DELIVERED.toLowerCase()) {
    return (
      <div className="order-notification order-status-delivered">
        <small>{data.value}</small>
      </div>
    );
  } else {
    return (
      <div className="order-notification order-status-new">
        <small>{data.value}</small>
      </div>
    );
  }
};
const renderCircleCell = (data) => {
  const val = data.value;
  if (val === 1) {
    return <div className="circle-cell circle-cell-1">{val}</div>;
  } else if (val === 2) {
    return <div className="circle-cell circle-cell-2">{val}</div>;
  } else if (val === 3) {
    return <div className="circle-cell circle-cell-3">{val}</div>;
  } else if (val === 4) {
    return <div className="circle-cell circle-cell-4">{val}</div>;
  } else {
    return <div className="circle-cell circle-cell-1">P</div>;
  }
};
const renderTimeOnRute = (data) => {
  return (
    <div>
      <p className="data-grid-text">{`${dateHelper.secondsToHms(
        data.value
      )}`}</p>
    </div>
  );
};
