import { put, takeEvery } from "redux-saga/effects";
import { getAllBranchOffices, getBranches } from "../../firebase/branches";
import * as types from "../types/branches";
import * as actions from "../actions/branches";
import branchServices from "../services/branchServices";

function* fetchBranchOffices(action) {
  try {
    const { filters } = action.payload;
    let response = yield getAllBranchOffices(filters);
    const { byId, order } = response.branchOffices;

    if (response.error === null) {
      yield put(actions.completeFetchingBranchOffices(byId, order));
    } else {
      yield put(
        actions.failFetchingBranchOffices(
          "Ha ocurrido un error trayendo las sucursales de firebase"
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.failFetchingBranchOffices(
        "Ha ocurrido un error trayendo las sucursales de firebase"
      )
    );
  }
}

export function* watchFetchBranchOffices() {
  yield takeEvery(types.BRANCH_OFFICES_FETCH_STARTED, fetchBranchOffices);
}

function* fetchBranches(action) {
  try {
    const { filters } = action.payload;
    const response = yield getBranches(filters);

    yield put(actions.branchActions.getBranchesSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(actions.branchActions.getBranchesFailure(error));
  }
}

export function* watchFetchBranches() {
  yield takeEvery(types.BRANCHES_FETCH_STARTED, fetchBranches);
}

function* createBranch(action) {
  try {
    const {
      name,
      description,
      address,
      CountryId,
      RegionId,
      CityId,
      point,
      perimeter,
      CompanyId,
    } = action.payload.branch;
    yield branchServices.createBranch(
      name,
      description,
      address,
      CountryId,
      RegionId,
      CityId,
      point,
      perimeter,
      CompanyId
    );
    yield put(actions.branchActions.createBranchSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.branchActions.createBranchFailure(error.message));
  }
}

export function* watchCreateBranch() {
  yield takeEvery(types.BRANCH_CREATE_STARTED, createBranch);
}

function* deleteBranch(action) {
  try {
    const {
      id,
    } = action.payload;
    yield branchServices.deleteBranch(
      id
    );
    yield put(actions.branchActions.deleteBranchSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.branchActions.deleteBranchFailure(error.message));
  }
}

export function* watchDeleteBranch() {
  yield takeEvery(types.BRANCH_DELETE_STARTED, deleteBranch);
}
