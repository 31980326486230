import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default ({ field, form, ...props }) => {
  const [isChecked, setIsChecked] = useState(field.value);
  const hanldeChange = (e) => {
    setIsChecked(e.target.checked);
    props.formikProps.setFieldValue(field.name, e.target.checked);
    if (props.setState) {
      props.setState((val) => ({
        ...val,
        [field.name]: e.target.checked,
      }));
    }
  };
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          checked={isChecked}
          onChange={hanldeChange}
          control={<Checkbox color="primary" />}
          label={props.label}
          labelPlacement="start"
        />
      </FormGroup>
    </FormControl>
  );
};
