import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "../Design/DataTable";
import isEmpty from "lodash/isEmpty";
import Modal from "../Design/Modal";
import BikerStatusModal from "./BikerStatusModal";
import { BIKER_STATUS_OPTIONS } from "../../constants/constants";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/pilots";

const BikerStatus = () => {
  const dispatch = useDispatch();
  const pilots = useSelector(selectors.getPilots);
  const [pilotToModify, setPilotToModiy] = useState({});

  useEffect(() => {
    dispatch(actions.clearPilotsSubscription());
    dispatch(
      actions.startFetchingPilots({
        comodines: true,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalClose = () => {
    setPilotToModiy({});
  };

  return (
    <div>
      {!isEmpty(pilotToModify) && (
        <Modal
          isOpened={!isEmpty(pilotToModify)}
          onClose={onModalClose}
          body={
            <BikerStatusModal
              onModalClose={onModalClose}
              biker={pilotToModify}
            />
          }
        />
      )}
      <DataTable
        usePagination
        align="left"
        headers={[
          {
            id: "name",
            name: "2Wheeler",
            style: {
              width: "20%",
            },
          },
          {
            id: "companyName",
            name: "Compañía",
            style: {
              width: "20%",
            },
          },
          {
            id: "SUCURSAL",
            name: "Sucursal",
            style: {
              width: "15%",
            },
          },
          {
            id: "companyName",
            name: "Compañía",
            style: {
              width: "15%",
            },
          },
          {
            id: "bikerStatus",
            name: "Estado",
            style: {
              width: "15%",
            },
          },
          {
            id: "modify",
            name: "",
            style: {
              width: "15%",
            },
          },
        ]}
        rows={(pilots || []).map((biker) => ({
          ...biker,
          companyName: biker.company || "",
          bikerStatus: !isEmpty(biker.status)
            ? (biker.status || "") ===
              BIKER_STATUS_OPTIONS.AVAILABLE.value.toLowerCase()
              ? "Disponible"
              : "No disponible"
            : "Sin estado",
          modify: (
            <div className="company-branches-links">
              <div onClick={() => setPilotToModiy(biker)}>Modificar Estado</div>
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default BikerStatus;
