import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/img/logo_app_orange_grey.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../logic/actions/auth";
import * as selectors from "../../logic/reducers";
import LinearProgress from "@material-ui/core/LinearProgress";
import isEmpty from "lodash/isEmpty";
import { Formik } from "formik";
import { ROLES } from "../../constants/constants";
import * as yup from "yup";
import { getController } from "../../logic/reducers";
const schema = yup.object({
  email: yup
    .string()
    .email("Ingrese un correo electrónico válido")
    .required("Ingrese un correo electrónico"),
  password: yup.string().required("Ingrese una contraseña"),
});
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Twowheelstogo.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },

  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const isAuthenticating = useSelector(selectors.getIsAuthenticating);
  const authenticationError = useSelector(selectors.getAuthenticationError);
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const controller = useSelector(getController);
  const authEmail = useSelector(selectors.getAuthEmail);

  const handleSignIn = (email, password) => {
    dispatch(actions.startingAuthentication(email, password));
  };

  const goToPage = () => {
    if (!isEmpty(authEmail)) {
      if (
        authEmail.split("@cbc.co").length > 1 ||
        authEmail.split("@onelinkbpo.com").length > 1 ||
        !isEmpty(controller.companyId) ||
        !isEmpty(controller.branchId)
      ) {
        switch (controller.role) {
          case ROLES.ADMINISTRATOR:
            history.push("/dashboards/company-resume");
            return;
          case ROLES.USER:
            history.push(`/dashboards/branch-resume/${controller.branchId}`);
            return;
          case ROLES.DEMO:
            history.push("/dashboards/company-resume");
            return;
          default:
            console.log("Role not matched");
        }
        return;
      }
      switch (controller.role) {
        case ROLES.REQUEST:
          history.push("/dashboards/alertas");
          return;
        default:
          history.push("/resume");
      }
    } else {
      console.log("authEmail is empty");
    }
  };

  if (isAuthenticated && !isEmpty(controller)) {
    goToPage();
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} width={150} alt="logotipos" />
          <Formik
            initialValues={{
              email: undefined,
              password: undefined,
            }}
            onSubmit={(values) => handleSignIn(values.email, values.password)}
            validationSchema={schema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              values,
              touched,
            }) => (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  error={errors.email && touched.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email && touched.email && errors.email}
                />
                <TextField
                  error={errors.password && touched.password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="current-password"
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />
                {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
                {!isAuthenticating && (
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.submit}
                  >
                    {"Iniciar sesión"}
                  </Button>
                )}
                {isAuthenticating && (
                  <div className={classes.submit}>
                    <LinearProgress color="primary" />
                  </div>
                )}
                {authenticationError !== null && (
                  <div className={classes.errorMessage}>
                    <Typography variant="caption" align="center" color="error">
                      Ha ocurrido un error al iniciar sesión
                    </Typography>
                  </div>
                )}
                {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
                <Box mt={5}>
                  ``
                  <Copyright />
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
