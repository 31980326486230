import React from "react";
import {
  Typography,
  ThemeProvider,
  createMuiTheme,
  Grid,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const CanceledOrder = () => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Lato",
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={10}>
            <InfoIcon
              style={{
                width: "50px",
                height: "auto",
                color: "#F44336",
              }}
            />
          </Grid>
        </Grid>
        <Typography
          style={{
            color: "#5499C7",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            textAlign: "center",
            fontWeight: 600,
          }}
          variant="subtitle2"
        >
          LA ORDEN FUE CANCELADA,PARA MAS INFORMACIÓN COMUNIQUESE CON PBX
        </Typography>
      </div>
    </ThemeProvider>
  );
};

export default CanceledOrder;
