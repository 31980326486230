import React, { Fragment } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import mapStyles from "./mapStyle";

const MapRender = withScriptjs(
  withGoogleMap((props) => {
    let orderPos = {
      lat:
        props.data.locationRef?.latitude === undefined
          ? 0
          : props.data.locationRef?.latitude,
      lng:
        props.data.locationRef?.longitude === undefined
          ? 0
          : props.data.locationRef?.longitude,
    };

    let bikerPos = {
      lat:
        props.pilot?.actualPosition.latitude === undefined
          ? 14.601554597799064
          : props.pilot.actualPosition?.latitude,
      lng:
        props.pilot?.actualPosition.longitude === undefined
          ? -90.5181858598407
          : props.pilot.actualPosition?.longitude,
    };
    var zoom = 13;
    let pointMedium = {
      lat: (bikerPos.lat + orderPos.lat) / 2,
      lng: (bikerPos.lng + orderPos.lng) / 2,
    };

    if (props.pilot === null) return <div>espere un momento</div>;
    return (
      <Fragment>
        <GoogleMap
          defaultZoom={zoom}
          defaultCenter={{ lat: pointMedium.lat, lng: pointMedium.lng }}
          defaultOptions={{ styles: mapStyles }}
        >
          <Marker
            position={{
              lat: orderPos.lat,
              lng: orderPos.lng,
            }}
            icon={{
              url:
                "https://firebasestorage.googleapis.com/v0/b/imagenesproyect.appspot.com/o/home_customer.svg?alt=media&token=bfeda40a-8988-48ed-8691-139833a4d7ef",
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{
              lat: bikerPos?.lat,
              lng: bikerPos?.lng,
            }}
            icon={{
              url:
                "https://firebasestorage.googleapis.com/v0/b/imagenesproyect.appspot.com/o/biker_marker.svg?alt=media&token=9de880c1-8df0-4374-86ee-a8f780560b0c",
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          {/* <DirectionsRenderer
                directions={direction}
                defaultOptions={{
                  suppressMarkers:false,
                  polylineOptions:{
                  strokeColor:'#5DADE2'
                  }
                }}
              />   */}
        </GoogleMap>
      </Fragment>
    );
  })
);


export default MapRender;
