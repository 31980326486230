import isEmpty from "lodash/isEmpty";

const onFilterChange = (orders, filterModelItems) => {
  if (!isEmpty(filterModelItems)) {
    let filteredOrders = [...orders];
    filterModelItems.forEach((filterModel) => {
      if (!isEmpty(filterModel.value)) {
        filteredOrders = filteredOrders.filter((order) => {
          if (filterModel.operatorValue === "contains") {
            return (order[filterModel.columnField] || "")
              .toLowerCase()
              .includes((filterModel.value || "").toLowerCase());
          }
          if (filterModel.operatorValue === "startsWith") {
            return (order[filterModel.columnField] || "")
              .toLowerCase()
              .startsWith((filterModel.value || "").toLowerCase());
          }
          if (filterModel.operatorValue === "endsWith") {
            return (order[filterModel.columnField] || "")
              .toLowerCase()
              .endsWith((filterModel.value || "").toLowerCase());
          }
          if (filterModel.operatorValue === "equals") {
            return (order[filterModel.columnField] || "")
              .toLowerCase()
              .localeCompare((filterModel.value || "").toLowerCase());
          }
          return true;
        });
      }
    });
  }
};

const filterHelper = {
  onFilterChange,
};

export default filterHelper;
