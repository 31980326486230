import React from "react";
import { OrderModel } from "../../models";
import { ListItem, Divider, ListItemText, makeStyles } from "@material-ui/core";
import { renderStatusCell } from "../../constants/tableConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  header: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#808080",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  content: {
    flex: 1,
    width: "100%",
  },
  status: {
    width: "80px",
    marginBottom: "5px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  footer: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#808080",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const OrderItem = ({ details = new OrderModel(), onClick }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ListItem
        className={classes.root}
        onClick={() => {
          onClick({
            row: {
              id: details.id,
            },
          });
        }}
      >
        <div className={classes.header}>
          <p>{details.orderId}</p>
        </div>
        <div className={classes.content}>
          <ListItemText
            primary={details.name}
            secondary={
              <React.Fragment>
                <div className={classes.status}>
                  {renderStatusCell({ value: details.status })}
                </div>
                <small>{`${details.address}`}</small>
              </React.Fragment>
            }
          />
        </div>
        <div className={classes.footer}>
          <p>{details.company.name}</p>
        </div>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </React.Fragment>
  );
};
export default OrderItem;
