import React from "react";
import Expandable from "../Design/Expandable";
import Card from "../Design/Card";
import { isEmpty } from "lodash";

const getRandomColor = () => {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const TopBikers = ({ orders, top = 10, useExpandable = true }) => {
  const renderTop = () => {
    let bikers = [];
    orders.forEach((order) => {
      if (
        !isEmpty(order) &&
        !isEmpty(order.alias) &&
        !isEmpty(order.alias.id) &&
        !isEmpty(order.alias.name)
      ) {
        const aliasIndex = bikers.findIndex(
          (alias) => alias.id === order.alias.id
        );
        if (aliasIndex > -1) {
          bikers[aliasIndex] = {
            ...bikers[aliasIndex],
            quantity: bikers[aliasIndex].quantity + 1,
          };
        } else {
          bikers = [
            ...bikers,
            {
              id: order.alias.id,
              name: order.alias.name,
              quantity: 1,
            },
          ];
        }
      }
    });
    return (
      <div>
        {/* <div className="link margin-left-sm">Ver lista completa</div> */}
        {bikers
          .sort((a, b) => {
            if (a.quantity > b.quantity) {
              return -1;
            }
            if (a.quantity < b.quantity) {
              return 1;
            }
            return 0;
          })
          .slice(0, top)
          .map((biker, i) => (
            <div
              className="card-container card-container-5 no-border-bottom"
              style={{ background: getRandomColor() }}
              key={biker.id}
            >
              <Card
                bodyCssClases="sumary-body text-center"
                titleCssClases="text-center padding-top-sm padding-bottom-sm"
                cssClases="no-padding"
                noPadding
                title={
                  <div className="uppercase margin-bottom-md bold">{`${
                    i + 1
                  }: ${biker.name}`}</div>
                }
                body={`Ordenes Entregadas: ${biker.quantity}`}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderView = () => {
    if (useExpandable) {
      return (
        <Expandable title={`Top ${top} - Pilotos`}>{renderTop()}</Expandable>
      );
    }
    return renderTop();
  };

  return <>{renderView()}</>;
};

export default TopBikers;
