import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import moment from "moment";

const onExporting = (e,n) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");

  const date = new Date();
  let dateNow = moment(date).format("L");
  console.log(date.getDay());
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `file-${n}-${dateNow}.xlsx`
      );
    });
  });
  e.cancel = true;
};

export default onExporting;