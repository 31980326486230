import { combineReducers } from "redux";
import {
  CANCELLED_ORDERS_ON_SAVE_FILTERS,
  CANCELLED_ORDERS_ON_REMOVE_FILTERS,
  ABANDONED_ORDERS_ON_SAVE_FILTERS,
  ABANDONED_ORDERS_ON_REMOVE_FILTERS,
  DELIVERED_ORDERS_ON_SAVE_FILTERS,
  DELIVERED_ORDERS_ON_REMOVE_FILTERS,
  IN_ROUTE_ORDERS_ON_SAVE_FILTERS,
  IN_ROUTE_ORDERS_ON_REMOVE_FILTERS,
  DELETED_ORDERS_ON_SAVE_FILTERS,
  DELETED_ORDERS_ON_REMOVE_FILTERS,
  RE_AGEND_ORDERS_ON_SAVE_FILTERS,
  RE_AGEND_ORDERS_ON_REMOVE_FILTERS,
} from "../types/filters";

const initalState = {
  filters: [],
};

const canceledOrders = (state = initalState, action) => {
  switch (action.type) {
    case CANCELLED_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case CANCELLED_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const abandonedOrders = (state = initalState, action) => {
  switch (action.type) {
    case ABANDONED_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case ABANDONED_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const deliveredOrders = (state = initalState, action) => {
  switch (action.type) {
    case DELIVERED_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case DELIVERED_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const inRouteOrders = (state = initalState, action) => {
  switch (action.type) {
    case IN_ROUTE_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case IN_ROUTE_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const deletedOrders = (state = initalState, action) => {
  switch (action.type) {
    case DELETED_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case DELETED_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const reAgendOrders = (state = initalState, action) => {
  switch (action.type) {
    case RE_AGEND_ORDERS_ON_REMOVE_FILTERS:
      return {
        ...state,
        ...initalState,
      };
    case RE_AGEND_ORDERS_ON_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default combineReducers({
  canceledOrders,
  abandonedOrders,
  deliveredOrders,
  inRouteOrders,
  deletedOrders,
  reAgendOrders,
});
