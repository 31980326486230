import React,{Fragment, useEffect} from 'react';
import { connect } from "react-redux"
import * as actions from "../../logic/actions/user";
import * as selectors from "../../logic/reducers";
import DataGrid, { Column, Paging, Pager,Selection,Scrolling} from 'devextreme-react/data-grid';
import BreadCrumb from "../../containers/navs/breadcrumb";
import { useHistory } from "react-router-dom";
const OrdersNewView = ({
    gettingAllOrders,
    initOrders,
    orders,
    failedOrders
  }) => {
    const history = useHistory();
  useEffect(() => {
    gettingAllOrders();
  }, [gettingAllOrders]);

  const allowedPageSizes = [20];
  const onSelectionChanged = (e)=> {
    history.push(`/edit-order/${e.selectedRowKeys[0]}`)
  }  

  return (
  <Fragment>
      <div>
      <BreadCrumb
        match={{ path: history.location.pathname }}
        title="TODAS LAS ORDENES"
      />
    </div>
    <DataGrid
    keyExpr="id"
    dataSource={orders}
    showBorders={true}
    remoteOperations={true}
    hoverStateEnabled={true}
    onSelectionChanged={onSelectionChanged}
  >
    <Selection mode="single"/>
    <Column
      dataField="data.alert"
      caption="ALERTA"
    />  
    <Column
      dataField="data.orderId"
      caption="N° ORDEN"
    />
    <Column
      dataField="data.name"
      caption="CLIENTE"
    />
    <Column
      dataField="data.status"
      caption="ESTATUS"
    />
    <Column
      dataField="data.company.name"
      caption="EMPRESA"
    />
    <Column
      dataField="data.branch.name"
      caption="EMPRESA"
    />
    <Column
      dataField="data.alias.name"
      caption="EMPRESA"
    />
     <Column
      dataField="data.address"
      caption="DIRECCIÓN"
    />
    <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

    <Paging defaultPageSize={20} />
    <Pager
      showPageSizeSelector={true}
      allowedPageSizes={allowedPageSizes}
    />
  </DataGrid>
  </Fragment>
  )
}

export default connect(
    (state) => ({
        // Update Pasword
        initOrders: selectors.gettingOrdes(state),
        orders: selectors.getAllOrders(state),
        failedOrders: selectors.getAllOrdersFailed(state)
        // Getting Data
    }),
    (dispatch) => ({
        gettingAllOrders() {
            dispatch(actions.gettingOrders());
        },
    })
)(OrdersNewView);