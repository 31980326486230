import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";

const getFirebaseGeopoint = (latitude, longitude) =>
  new firebase.firestore.GeoPoint(latitude, longitude);
const getFirebaseDate = (date) =>
  date !== null && date !== undefined
    ? firebase.firestore.Timestamp.fromDate(new Date(date))
    : null;

const dateHelper = {
  getFirebaseDate,
  getFirebaseGeopoint,
};

export default dateHelper;
