import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import DynamicForm from "../Design/Form/DynamicForm";
import validationHelper from "../../helpers/validationHelper";
import isEmpty from "lodash/isEmpty";

const CompanyForm = ({
  formFields,
  company,
  setCompany,
  btnList,
  onSubmit,
  validationSchema,
  ...rest
}) => {
  const [customErrors, setCustomErrors] = useState([]);

  const onSubmitCompanyForm = (values) => {
    let emails = [];
    if (!isEmpty(values.emails)) {
      const emailsToValidate = values.emails.split("\n");
      if (!isEmpty(emailsToValidate)) {
        let invalidEmails = [];
        emailsToValidate
          .filter((email) => !isEmpty(email))
          .forEach((email) => {
            if (validationHelper.validateEmail(email)) {
              emails = [...emails, email];
            } else {
              invalidEmails = [...invalidEmails, email];
            }
          });
        if (!isEmpty(invalidEmails)) {
          setCustomErrors([
            `Por favor verifica los siguientes correos: ${invalidEmails.join(
              ", "
            )}`,
          ]);
          return;
        }
      }
    } else {
      if (values.email) {
        setCustomErrors(["Por favor, ingresa por lo menos un correo"]);
        return;
      }
    }
    onSubmit({
      ...values,
      emails,
    });
  };
  return (
    <>
      {!isEmpty(customErrors) && (
        <div className="custom-errors">
          {customErrors.map((customError, i) => (
            <Alert key={`custom-error-${i}`} severity="error">
              {customError}
            </Alert>
          ))}
        </div>
      )}
      <DynamicForm
        obj={company}
        fields={formFields}
        validationSchema={validationSchema}
        buttons={btnList}
        onSubmit={onSubmitCompanyForm}
        btnAlignment="right"
        setState={setCompany}
        {...rest}
      />
    </>
  );
};

export default CompanyForm;
