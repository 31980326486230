import React from "react";
import Expandable from "../Design/Expandable";
import AlertList from "../Alerts/AlertList";
import OrderStatus from "../Order/OrderStatus";
import BikerStatus from "../Biker/BikerStatus";

const CatalogOperations = () => {
  return (
    <div title="ESTADOS DE LAS ORDENES">
      <Expandable title="ALERTAS DE PILOTO">
        <AlertList />
      </Expandable>
      <Expandable title="ESTADOS DE LAS ORDENES">
        <OrderStatus />
      </Expandable>
      <Expandable title="ESTADO DE LOS PILOTOS">
        <BikerStatus />
      </Expandable>
    </div>
  );
};

export default CatalogOperations;
