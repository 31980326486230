export const FETCH_ORDERS_STARTED = 'FETCH_ORDERS_STARTED';
export const SET_FETCH_ORDERS_LOADING = 'SET_FETCH_ORDERS_LOADING';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';


export const FETCH_SINGLE_ORDER_STARTED = 'FETCH_SINGLE_ORDER_STARTED'
export const FETCH_SINGLE_ORDER_COMPLETED ='FETCH_SINGLE_ORDER_COMPLETED'
export const FETCH_SINGLE_ORDER_FAILED = 'FETCH_SINGLE_ORDER_FAILED'
// Create Order
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const CLEAR_CREATE_ORDER = 'CLEAR_CREATE_ORDER';

export const FETCH_HISTORY_ORDERS_STARTED = 'FETCH_HISTORY_ORDERS_STARTED';
export const FETCH_HISTORY_ORDERS_COMPLETED = 'FETCH_HISTORY_ORDERS_COMPLETED';
export const FETCH_HISTORY_ORDERS_FAILED = 'FETCH_HISTORY_ORDERS_FAILED';

//  EDIT ORDER
export const EDIT_ORDER_USERS_IN_FORM = 'EDIT_ORDER_USERS_IN_FORM';
export const EDIT_ORDER_STARTED = 'EDIT_ORDER_STARTED';
export const EDIT_ORDER_COMPLETED = 'EDIT_ORDER_COMPLETED';
export const EDIT_ORDER_FAILED = 'EDIT_ORDER_FAILED';

//  ADD ORDER 
export const ORDER_ADDED = 'ORDER_ADDED';
export const CLEAR_ORDERS_SUBSCRIPTION = 'CLEAR_ORDERS_SUBSCRIPTION';
export const SET_SHOULD_RUN_TIMEOUT = 'SET_SHOULD_RUN_TIMEOUT';

// GET ORDER BY ID
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE';
export const CLEAR_ORDER_EDITION = 'CLEAR_ORDER_EDITION';

//  MODIFY ORDER 
export const ORDER_MODIFIED = 'ORDER_MODIFIED';

//  CANCEL ORDER
export const ORDER_CANCELED = 'ORDER_CANCELED';

//  REMOVE ORDER
export const ORDER_REMOVED = 'ORDER_REMOVED';

//  SELECT ORDER 
export const ORDER_SELECTED = 'ORDER_SELECTED';

//  DESELECT ORDER 
export const ORDER_DESELECTED = 'ORDER_DESELECTED';

//  REMOVE CHANGE
export const ORDER_CHANGE_REMOVED = 'ORDER_CHANGE_REMOVED';

// SUBSCRIBE PILOT ORDERS BY PILOT ID
export const SUBSCRIBE_ORDRES_BY_PILOT_ID = 'SUBSCRIBE_ORDRES_BY_PILOT_ID';
export const GET_ORDRES_WITH_TIME_BY_PILOT_ID = 'GET_ORDRES_WITH_TIME_BY_PILOT_ID';
export const GET_ORDRES_WITH_TIME_BY_PILOT_ID_SUCCESS = 'GET_ORDRES_WITH_TIME_BY_PILOT_ID_SUCCESS';
export const SUBSCRIBE_ORDRES_BY_PILOT_ID_CHANGE = 'SUBSCRIBE_ORDRES_BY_PILOT_ID_CHANGE';
export const SUBSCRIBE_ORDRES_BY_PILOT_ID_FAILURE = 'GET_PILOT_BY_PILOT_ID_FAILURE';
export const CLEAR_ORDERS_BY_PILOT_ID = 'CLEAR_ORDERS_BY_PILOT_ID';


// TOKEN for API
export const SET_API_TOKEN = 'SET_API_TOKEN';

// Delete Order
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
export const FETCH_MONTHLY_ORDERS_STARTED='FETCH_MONTHLY_ORDERS_STARTED';
export const MONTHLY_ORDER_ADDED = 'MONTHLY_ORDER_ADDED';
export const MONTHLY_ORDER_MODIFIED = 'MONTHLY_ORDER_MODIFIED';
export const MONTHLY_ORDER_REMOVED = 'MONTHLY_ORDER_REMOVED';

// GET LINK ORDER
export const GET_ORDER_LINK_STARTED = 'GET_ORDER_LINK_STARTED';
export const GET_ORDER_LINK_COMPLETED = 'GET_ORDER_LINK_COMPLETED';
export const GET_ORDER_LINK_FAILED = 'GET_ORDER_LINK_FAILED';

// LINK UPDATE
export const UPDATE_ORDER_LINK_STARTED ='UPDATE_ORDER_LINK_STARTED';
export const UPDATE_ORDER_LINK_COMPLETED = 'UPDATE_ORDER_LINK_COMPLETED';
export const UPDATE_ORDER_LINK_FAILED = 'UPDATE_ORDER_LINK_FAILED'; 

// LINK TIME 
export const GET_ORDER_WITH_TIME_STARTED = 'GET_ORDER_WITH_TIME_STARTED'; 
export const GET_ORDER_WITH_TIME_COMPLETED = 'GET_ORDER_WITH_TIME_COMPLETED'; 
export const GET_ORDER_WITH_TIME_FAILED = 'GET_ORDER_WITH_TIME_FAILED';

// ALL ORDERS
export const GET_ORDER_ALL_ORDER_STARTED = 'GET_ORDER_ALL_ORDER_STARTED'; 
export const GET_ORDER_ALL_ORDER_COMPLETED = 'GET_ORDER_ALL_ORDER_COMPLETED'; 
export const GET_ORDER_ALL_ORDER_FAILED = 'GET_ORDER_ALL_ORDER_FAILED';

