import React, { useEffect, useState } from "react";
import { getBikers, getStatusBikers } from "../../firebase/orders";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { BIKER_DEFAULT } from "../../constants/constants";
import BreadCrumb from "../../containers/navs/breadcrumb";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  h3: {
    fontSize: 24,
    fontWeight: 800,
    color: "#333",
    marginBottom: 15,
    alignContent: "center",
  },
}));

const DevicesBikers = ({ match: { params } }) => {
  const classes = useStyles();
  const [bikerData, setBikerData] = useState(null);
  const [bikerStatusData, setBikerStatusData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { bikerId } = params;
        const allBikers = await getBikersById(bikerId);
        const selectedBiker = allBikers.find((biker) => biker.id === bikerId);

        // Si se encuentra el biker, guarda sus datos
        if (selectedBiker) {
          const statusBikerDevice = await getBikerStatusByEmail(
            selectedBiker.email
          );
          const dataDevice = statusBikerDevice.find(
            (status) => status.email === selectedBiker.email
          );
          if (dataDevice) {
            setBikerStatusData(dataDevice);
          } else {
            setBikerStatusData({ error: "NO TIENE LA ULTIMA VERSION" });
          }
          setBikerData(selectedBiker);
        } else {
          console.error(`Biker with ID ${bikerId} not found.`);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [params]);

  const getBikersById = async () => {
    const bikersCollection = await getBikers();
    const bikersSnapshot = await bikersCollection.get();
    let dataBikers = [];
    bikersSnapshot.forEach((biker) => {
      dataBikers.push({
        ...biker.data(),
        id: biker.id,
      });
    });
    return dataBikers;
  };

  const getBikerStatusByEmail = async () => {
    const statusCollection = await getStatusBikers();
    const statusSnapshot = await statusCollection.get();
    let dataBikerStatus = [];
    statusSnapshot.forEach((status) => {
      dataBikerStatus.push({
        ...status.data(),
        id: status.id,
      });
    });
    return dataBikerStatus;
  };

  const getNameBiker = () => {
    if (bikerData?.id === params.bikerId) {
      return bikerData.name;
    } else {
      return "Motorista no encontrado";
    }
  };

  const nameBiker = getNameBiker();
  return (
    <>
      <BreadCrumb match={{ path: "/" }} title={nameBiker.toUpperCase()} />
      <Grid container spacing={2}>
        {bikerData && (
          <Grid item xs={6}>
            <Typography variant="h3" className={classes.h3}>
              {`INFORMACIÓN DE MOTORISTAS`}
            </Typography>
            <div>
              <div className="col-4 text-center two-wheeler-pic">
                <img
                  src={bikerData.pic ? bikerData.pic : BIKER_DEFAULT}
                  alt="2Wheeler"
                />
              </div>
              <div className="col-8">
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="col-5">
                    <strong>Nombre del Piloto</strong>
                  </div>
                  <div className="col-7">{bikerData.name}</div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="col-5">
                    <strong>Teléfono del Piloto</strong>
                  </div>
                  <div className="col-7">
                    {isNaN(bikerData.phone) ? "N/A" : bikerData.phone}
                  </div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="margin-bottom-sm">
                    <div className="col-5">
                      <strong>Estado del Piloto</strong>
                    </div>
                    <div className="col-7">
                      {bikerData.status === "available"
                        ? "Disponible"
                        : "No disponible"}
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="margin-bottom-sm">
                    <div className="col-5">
                      <strong>Tipo</strong>
                    </div>
                    <div className="col-7">
                      {bikerData.type === "WILDCARD"
                        ? "COMODIN"
                        : bikerData.type}
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="margin-bottom-sm">
                    <div className="col-5">
                      <strong>Compañía</strong>
                    </div>
                    <div className="col-7">{bikerData.company}</div>
                  </div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="margin-bottom-sm">
                    <div className="col-5">
                      <strong>Sucursal</strong>
                    </div>
                    <div className="col-7">{bikerData.SUCURSAL}</div>
                  </div>
                </div>
                <div className="margin-bottom-sm pilot-data-display">
                  <div className="margin-bottom-sm">
                    <div className="col-5">
                      <strong>Lugar de trabajo</strong>
                    </div>
                    <div className="col-7">{bikerData.Pertenece}</div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}

        {/* Mostrar los datos si bikerStatusData contiene datos (no error) */}
        {bikerStatusData && !bikerStatusData.error && (
          <Grid item xs={6}>
            <Typography variant="h3" className={classes.h3}>
              {`INFORMACIÓN DEL TELEFONO`}
            </Typography>
            <div
              style={{
                // backgroundColor: "lightblue",
                height: "100px",
                padding: "20px",
              }}
            >
              <p>Email: {bikerStatusData.email}</p>
              <div className="battery-icon">
                <div className="battery-body">
                  <div className="battery-level"></div>
                </div>
              </div>
            </div>
          </Grid>
        )}

        {/* Mostrar mensaje de error si bikerStatusData contiene un objeto de error */}
        {bikerStatusData && bikerStatusData.error && (
          <Grid item xs={6}>
            <Typography variant="h3" className={classes.h3}>
              {`ADVERTENCIA`}
            </Typography>
            <div
              style={{
                height: "100px",
                padding: "20px",
              }}
            >
              <p style={{ color: "white", background: "red", padding: "5px" }}>
                <strong>{bikerStatusData.error}</strong>
              </p>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DevicesBikers;
