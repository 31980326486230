import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/pilots";
import * as selectors from "../../logic/reducers";
import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  StateStoring,
  HeaderFilter,
  Summary,
  TotalItem,
  FilterPanel,
  FilterRow,
  Export,
  Scrolling,
} from "devextreme-react/data-grid";
import { isEmpty } from "lodash";
import Button from "@material-ui/core/Button";
import { ROUTES } from "../../constants/constants";

const TrackingBikers = ({ companyId, biker, fetchingBiker }) => {
  const allowedPageSizes = [10, 20, 50];
  const history = useHistory();

  useEffect(() => {
    fetchingBiker();
  }, [fetchingBiker]);

  let bikersFilterCompany = (biker || []).filter(
    (e) => e.companyId === companyId
  );

  const cellPictureRender = (values) => {
    const imagePic = values.data.pic;
    return (
      <div>
        {isEmpty(imagePic) ? (
          <div>NO TIENE IMAGEN</div>
        ) : (
          <img className="imgPicTable" src={imagePic} alt="employee"></img>
        )}
      </div>
    );
  };

  const cellSucursalRender = (values) => {
    return values.data.SUCURSAL.toUpperCase();
  };

  const cellEmailRender = (values) => {
    return values.data.email?.toUpperCase();
  };

  const cellNameRender = (values) => {
    return values.data.name.toUpperCase();
  };

  const handleDetailsClick = (bikerData) => {
    history.push(`${ROUTES.BIKER}/single-location/${bikerData.id}`);
  };

  return (
    <div>
      <DataGrid
        keyExpr="id"
        id="dataGridTracking"
        dataSource={bikersFilterCompany}
        width={"100%"}
        showBorders={true}
        rowAlternationEnabled={true}
        noDataText="NO HAY INFORMACIÓN"
        repaintChangesOnly={true}
      >
        <HeaderFilter visible={true} />
        <Paging enabled={true} defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          displayMode={true}
          allowedPageSizes={allowedPageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="orderId"
            cssClass="cell-highlightedOnCompany"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <Scrolling
          columnRenderingMode="virtual"
          showScrollbar="onHover"
          scrollByContent
        />
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="companyOrdersOnRoute"
        />
        <SearchPanel visible={true} placeholder="BUSCAR..." />
        <FilterRow visible={true} />
        <Paging enabled={true} defaultPageSize={20} />
        <Column
          dataField="name"
          caption="PILOTO"
          cellRender={cellNameRender}
          sortOrder="asc"
          width={"auto"}
        />
        <Column
          dataField="email"
          cellRender={cellEmailRender}
          caption="CORREO ELECTRONICO"
          width={"auto"}
        />
        <Column dataField="phone" caption="TELEFONO" width={"auto"} />
        <Column dataField="transportId" caption="PLACAS" width={"auto"} />
        <Column
          dataField="SUCURSAL"
          cellRender={cellSucursalRender}
          caption="SUCURSAL"
          width={"auto"}
        />
        <Column
          caption="RASTREAR MOTORISTA"
          dataField="id"
          alignment={"center"}
          width={"auto"}
          cellRender={({ data }) => (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleDetailsClick(data)}
            >
              RASTREAR
            </Button>
          )}
        />
        <Column
          caption="IMAGEN"
          cellRender={cellPictureRender}
          alignment={"center"}
        />
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </div>
  );
};

export default connect(
  (state) => ({
    biker: selectors.fetchedBikerBranch(state),
    startedBiker: selectors.fetchingBikerBranch(state),
  }),

  (dispatch) => ({
    fetchingBiker() {
      dispatch(actions.getBikerStarted());
    },
  })
)(TrackingBikers);
