import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { Fragment } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Tooltip , MenuItem,Menu} from "@mui/material";
import { Avatar } from "@material-ui/core";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import theme from "../../assets/theme";
import * as actions from "../../logic/actions/auth";
import { useDispatch } from "react-redux";
import { Warning } from "@material-ui/icons";
import { getController } from "../../logic/reducers";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    letterSpacing: "3px",
  },
  list: {
    marginTop: theme.spacing(3),
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  logo: {
    marginTop: theme.spacing(6),
  },

  hide: {
    display: "none",
  },
}));

const NavBar = ({ handleDrawerOpen, open, onStartCapture }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const controller = useSelector(getController);
  const [settings, setSettings] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuIcon = () => {
    dispatch(actions.logOut());
    history.push("/login");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const settingsClick = (event) => {
    setSettings(event.currentTarget);
  };
  const settingsClose = (event) => {
    setSettings(null);
  };
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          elevation={0}
        >
          <Toolbar
            style={{
              backgroundColor: "#1F44A1",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/logotipos%2FDLlogo2.png?alt=media&token=24fc77df-61df-4aaf-9c42-0f533a281aa8"
              }
              style={{
                width: "300px",
                height: "auto",
                marginRight: "10px",
                cursor: "pointer",
              }}
              alt="logo"
              //   onClick={() => goToPage(ROUTES.ALL_ORDERS)}
            />
            <div className={classes.title}></div>
            <div>V.2.0.4</div>
            <Fragment>
              <IconButton color="inherit" onClick={settingsClick}>
                <HelpOutlinedIcon />
              </IconButton>
              <Menu
                elevation={0}
                getContentAnchorEl={null}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                id="customized-menu"
                anchorEl={settings}
                keepMounted
                open={Boolean(settings)}
                onClose={settingsClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
              >
                <MenuItem onClick={onStartCapture}>
                  <ListItemIcon>
                    <Warning fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="REPORTA UN PROBLEMA" />
                </MenuItem>
              </Menu>
            </Fragment>
            <Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="CUENTA">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    color="inherit"
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <Avatar
                      style={{
                        color: "white",
                        backgroundColor: "#F88B1E",
                        width: "30px",
                        height: "30px",
                      }}
                    >
                      {controller.name[0]}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                getContentAnchorEl={null}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
              >
                <MenuItem onClick={() => handleMenuIcon()}>
                  <ListItemIcon>
                    <MeetingRoom fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar Sesión" />
                </MenuItem>
              </Menu>
            </Fragment>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Fragment>
  );
};
export default NavBar;
