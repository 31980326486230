import bikerForMap from "../assets/img/biker.svg";
import deliveryForMap from "../assets/img/order.svg";
import branchForMap from "../assets/img/branch.svg";
import bikerDefault from "../assets/img/biker_default.png";
import branchAlerts from "../assets/img/branch_alerts.svg";
import mechanicFailure from "../assets/img/mechanic_failure.svg";
import tireFail from "../assets/img/tire_fail.svg";
import outOfGas from "../assets/img/out_of_gas.svg";
import accident from "../assets/img/accident.svg";
import homeCustomer from "../assets/img/home_customer.svg";
import averageTime from "../assets/img/average_time.svg";
import incidents from "../assets/img/incidents.svg";
import robbery from "../assets/img/robbery.png";
import lost from "../assets/img/lost.png";

export const changeKeys = {
  ORDER_ADDED: "ORDER_ADDED",
  ORDER_MODIFIED: "ORDER_MODIFIED",
  ORDER_REMOVED: "ORDER_REMOVED",
};


export const orderStatus = {
  NEW: "Nuevo",
  GOING: "En ruta",
  ON_HOLD: "En cola",
  PENDING: "Pendiente",
  ACCEPTED: "Aceptado",
  ASSIGNED: "Asignado",
  CANCELLED: "Cancelado",
  DELIVERED: "Entregado",
  RECEIVING: "Recibiendo",
  DELETED: "Eliminado",
  RE_AGEND: "Reagendado",
};

export const STATUS_FILTERS = {
  GOING: {
    value: orderStatus.GOING,
    text: orderStatus.GOING,
  },
  NEW: {
    value: orderStatus.NEW,
    text: orderStatus.NEW,
  },
  ON_HOLD: {
    value: orderStatus.ON_HOLD,
    text: orderStatus.ON_HOLD,
  },
  PENDING: {
    value: orderStatus.PENDING,
    text: orderStatus.PENDING,
  },
  ACCEPTED: {
    value: orderStatus.ACCEPTED,
    text: orderStatus.ACCEPTED,
  },
  ASSIGNED: {
    value: orderStatus.ASSIGNED,
    text: orderStatus.ASSIGNED,
  },
  DELIVERED: {
    value: orderStatus.DELIVERED,
    text: orderStatus.DELIVERED,
  },
  CANCELLED: {
    value: orderStatus.CANCELLED,
    text: orderStatus.CANCELLED,
  },
  DELETED: {
    value: orderStatus.DELETED,
    text: orderStatus.DELETED,
  },
  RE_AGEND: {
    value: orderStatus.RE_AGEND,
    text: orderStatus.RE_AGEND,
  },
};

export const STATUS_OPTIONS = {
  options: [
    STATUS_FILTERS.ON_HOLD,
    STATUS_FILTERS.GOING,
    STATUS_FILTERS.NEW,
    STATUS_FILTERS.PENDING,
    STATUS_FILTERS.ACCEPTED,
    STATUS_FILTERS.ASSIGNED,
    STATUS_FILTERS.DELIVERED,
    STATUS_FILTERS.CANCELLED,
    STATUS_FILTERS.DELETED,
    STATUS_FILTERS.RE_AGEND,
  ],
  cssClass: {
    [STATUS_FILTERS.ON_HOLD.value]: "order-alert-default",
    [STATUS_FILTERS.GOING.value]: "order-status-going",
    [STATUS_FILTERS.NEW.value]: "order-status-new",
    [STATUS_FILTERS.PENDING.value]: "order-alert-default",
    [STATUS_FILTERS.ACCEPTED.value]: "order-alert-default",
    [STATUS_FILTERS.ASSIGNED.value]: "order-alert-default",
    [STATUS_FILTERS.DELIVERED.value]: "order-alert-default",
    [STATUS_FILTERS.CANCELLED.value]: "order-alert-default",
    [STATUS_FILTERS.DELETED.value]: "order-alert-default",
    [STATUS_FILTERS.RE_AGEND.value]: "order-alert-default",
  },
};

export const orderAlert = {
  TO_BE_LATE: "Por llegar Tarde",
  ON_TIME: "En Tiempo",
  VERIFY: "Verificar",
  LATE: "Tarde",
};

export const bikerAlert = {
  MECHANIC_FAILURE: "Problema Mecánico",
  TIRE_FAIL: "Llanta Pinchada",
  TIRE_FAIL_2: "Pinchazo",
  OUT_OF_GAS: "Falta de Gasolina",
  ACCIDENT: "Accidente",
  THIEF: "Robo",
  LOST: "Extraviado",
  NO_PROBLEMS: "Sin Problemas",
  ALL_OK: "Todo Bien",
};

export const snackBarConst = {
  vertical: "top",
  horizontal: "right",
};

export const ROUTES = {
  EDIT_ORDER_FILTER: "/edit-orders-filters",
  ALL_ORDERS: "/all-orders",
  ALL_ORDERS_FILTER: "/all-orders-pollo-brujo",
  ALL_ORDER_ISHOP: "/orders-ishop-guatemala",
  ALL_ORDER_SAN_MARTIN: "/orders-san-martin",
  CREATE_USERS: "/create",
  CREATE_ORDER: "/create-order",
  NEW_ORDERS: "/new-orders",
  HISTORY_ORDERS: "/history-orders",
  ABANDONED_ORDERS: "/abandoned-orders",
  DELETED_ORDERS: "/deleted-orders",
  CANCELED_ORDERS: "/canceled-orders",
  REAGEND_ORDERS: "/reagend-orders",
  BIKERS_QUALIFICATION: "/bikers-qualification",
  EDIT_ORDER: "/edit-order",
  MAP: "/map",
  BIKER: "/biker",
  SHARE: "/Share/order/:id",
  SHARECBC: "/Link/:id",
  BIKER_MODIFY: "/biker-modify",
  BIKER_CREATE: "/biker-create",
  BIKERS: "/bikers",
  LOGIN: "/login",
  ORDER: "/order",
  ORDER_IMPORT: "/order-import",
  BIKERS_RESUME: "/bikers-resume",
  BRANCH_RESUME: "/branch",
  COMPANY: "/companies",
  CREATE_COMPANY: "/companies/create",
  REPORT_VIEW: "/report",
  DASHBOARDS: "/dashboards",
  BRANCH_DASHBOARD: "/branch-resume",
  COMPANY_RESUME: "/company-resume",
  DAILY_PERFORMANCE: "/daily-performance",
  RESUME_VIEW: "/resume",
  COMPANY_BRANCHES: "/company",
  USERS: {
    LIST: "/users",
  },
  BRANCH: {
    MODIFY: "/branch-modify",
    CREATE: "/branch-create",
  },
  ALERTS: {
    HOME: "/alerts",
    LIST: "/alerts-all",
  },
  CATALOG: {
    ALL_CATALOGS: "/catalogs",
  },
};

export const INPUT_TYPE = {
  FIELD: "FIELD",
  SELECT: "SELECT",
  DATE_TIME: "DATE_TIME",
  FILTERABLE_LIST: "FILTERABLE_LIST",
  CHECKBOX: "CHECKBOX",
};

export const PROVIDER_TYPE = {
  APP: "app",
  ECOMMERCE: "ecommerce",
  CONTROLLER: "controller",
  BRANCH: "branch",
};

export const MAP_FILTERS = {
  BIKER: {
    NONE: {
      value: "none",
      text: "Ninguno",
    },
    ALL: {
      value: "all",
      text: "Todos",
    },
    WITH_ORDERS: {
      value: "with-orders",
      text: "Con ordenes",
    },
    WITHOUT_ORDERS: {
      value: "without-orders",
      text: "Sin ordenes",
    },
    PROJECT: {
      value: 'project',
      text: 'Proyectos'
    },
    POLLO_BRUJO: {
      value: 'pollo-brujo',
      text: 'Pollo Brujo'
    }
  },
};

export const BIKER_ALERTS_FILTERS = {
  NONE: {
    value: "NONE",
    text: "Ninguna",
  },
  MECHANIC_FAILURE: {
    value: "PROBLEMA MECÁNICO",
    text: "Problema Mecánico",
  },
  TIRE_FAIL: {
    value: "LLANTA PINCHADA",
    text: "Llanta Pinchada",
  },
  TIRE_FAIL_2: {
    value: "PINCHAZO",
    text: "Pinchazo",
  },
  OUT_OF_GAS: {
    value: "FALTA DE GASOLITA",
    text: "Falta de Gasolina",
  },
  ACCIDENT: {
    value: "ACCIDENTE",
    text: "Accidente",
  },
  THIEF: {
    value: "ROBO",
    text: "Robo",
  },
  LOST: {
    value: "EXTRAVIADO",
    text: "Extraviado",
  },
  OK: {
    value: "TODO BIEN",
    text: "Todo Bien",
  },
  NO_PROBLEMS: {
    value: "SIN PROBLEMAS",
    text: "Sin Problemas",
  },
  ALL_OK: {
    value: "TODO BIEN",
    text: "Todo Bien",
  },
};

export const BIKER_ICON = bikerForMap;
export const DELIVERY_ICON = deliveryForMap;
export const BRANCH_ICON = branchForMap;
export const BIKER_DEFAULT = bikerDefault;

export const BRANCH_ALERTS = branchAlerts;
export const MECHANIC_FAILURE = mechanicFailure;
export const TIRE_FAIL = tireFail;
export const OUT_OF_GAS = outOfGas;
export const ACCIDENT = accident;
export const HOME_CUSTOMER = homeCustomer;
export const AVERAGE_TIME = averageTime;
export const INCIDENTS_IMG = incidents;
export const ROBBERY_IMG = robbery;
export const LOST_IMG = lost;

export const GOOGLE_API_KEY = "AIzaSyBsbuaZ4GRNZkquHV2W2wyo9Zume7N_hzc";
// export const API_URL='http://localhost:5002/twowheelstogo-572d7/us-central1/';
export const API_URL2 =
  "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/";
export const API_URL =
  "https://us-central1-twowheelstogo-572d7.cloudfunctions.net/";
export const DEV_API_URL = "https://devapi.twowheelstogo.com";

export const FIRESTORAGE = {
  ORDERS: "orders",
  ORDER_IMPORT: "order_import",
  COMPANY: "companies",
  BIKERS: "bikers",
};

export const BIKER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  NEW: "NEW",
};
export const VIEW_STATE = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
  NON: "NON",
};
export const BIKER_TYPE = {
  WILDCARD: "WILDCARD",
  PERMANENT: "PERMANENT",
};

export const SELECT_MAP_POINTS = {
  BRANCH: {
    value: "BRANCH",
    text: "Sucursal",
  },
  POLYGON_1: {
    value: "POLYGON_1",
    text: "Poligono - 1",
  },
  POLYGON_2: {
    value: "POLYGON_2",
    text: "Poligono - 2",
  },
  POLYGON_3: {
    value: "POLYGON_3",
    text: "Poligono - 3",
  },
  POLYGON_4: {
    value: "POLYGON_4",
    text: "Poligono - 4",
  },
};

export const BIKER_STATUS_OPTIONS = {
  AVAILABLE: {
    text: "Disponible",
    value: "available",
  },
  UNAVAILABLE: {
    text: "No disponible",
    value: "unavailable",
  },
};

export const DEPARTAMENT_TYPE = "administrative_area_level_1";
export const MUNICIPALITY_TYPE = "administrative_area_level_2";
export const ZONE_TYPE = "sublocality_level_1";

export const ROLES = {
  ADMINISTRATOR: "ADMINISTRATOR",
  CONTROLLER: "CONTROLLER",
  USER: "USER",
  OWNER: "OWNER",
  DEMO: 'DEMO',
  REQUEST: "REQUEST"
};

export const BIKER_RATING_STATUS = {
  LOADING: "LOADING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  WAITING: "WAITING",
};

export const ON_TIME_ORDERS_ALL = "ALL";

export const ON_TIME_ORDERS = [
  {
    // Llao Llao
    id: "2904d349-d63d-4e5e-b36d-a7e663e7be63",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
      {
        id: 2,
        time: 60 * 60,
      },
    ],
  },
  {
    // La Tavola
    id: "66edd812-181b-47cd-a627-fb74b793cf9c",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
      {
        id: 2,
        time: 60 * 60,
      },
    ],
  },
  {
    // Pollo Brujo
    id: "69a22355-c9d7-41d9-8126-7af95aa031a2",
    time: [
      {
        id: ON_TIME_ORDERS_ALL,
        time: 60 * 45,
      },
    ],
  },
  {
    // Lulis
    id: "844b2639-a695-4d56-b9b2-3d1be20ce396",
    time: [
      {
        id: 1,
        time: 60 * 45,
      },
      {
        id: 2,
        time: 60 * 60,
      },
    ],
  },
  {
    // CBC
    id: "8e632828-dcda-4853-9660-f82af914238f",
    time: [
      {
        id: ON_TIME_ORDERS_ALL,
        time: 60 * 60 * 3,
      },
    ],
  },
  {
    // Agrochina
    id: "96b41e13-713d-42f5-a089-8a9c41334750",
    time: [
      {
        id: ON_TIME_ORDERS_ALL,
        time: 60 * 60 * 24,
      },
    ],
  },
  {
    // Yogen Fruz
    id: "aefbc45a-a146-4364-9475-b0d8fc708f26",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
      {
        id: 1,
        time: 60 * 60,
      },
    ],
  },
  {
    // Nacion Sushi
    id: "b0a7ef37-0806-4f9e-88fa-aeadd44bd55c",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
      {
        id: 2,
        time: 60 * 60,
      },
    ],
  },
  {
    // Gelatiamo
    id: "b66c1519-b997-4c75-a261-404bf57ede47",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
    ],
  },
  {
    // Peccatto Pasta
    id: "c67b81a6-fd52-45fb-9d2d-a27580a82181",
    time: [
      {
        id: 1,
        time: 60 * 60,
      },
      {
        id: 2,
        time: 60 * 60,
      },
    ],
  },
  {
    // iShop
    id: "fc078ef2-2276-415b-a46d-35d202b09f22",
    time: [
      {
        id: ON_TIME_ORDERS_ALL,
        time: 60 * 60 * 2,
      },
    ],
  },
];

const times = {
  CBC: 60 * 60 * 3,
  POLLO_BRUJO: 60 * 45,
  GELATIAMO: 60 * 45,
  EL_DUENDE_MALL: 60 * 60 * 3,
  PRIME_FOOD: 60 * 60 * 2,
  SAN_MARTI: 60 * 45,
  ISHOP: 60 * 60 * 3,
  LULIS: 60 * 45,
  PANINA: 60 * 45,
  CACAO_70: 60 * 45,
  LICORERA: 60 * 45,
  MIXTAS: 60 * 45,
  LA_TAVOLA: 60 * 45,
  OCEAN_WEAR: 60 * 45, //
  CHURERRIA_PORFIRIO: 60 * 45,
  SPLENDIDO_BISTRO: 60 * 45,
  AGROCINA: 60 * 45,
  YOGEN_FRUZ: 60 * 45,
  NACION_SUSHI: 60 * 45,
};

const getTimes = (timeToUse) => ({
  onTime: {
    text: `0-${((timeToUse / 3) * 2) / 60} mins`,
    start: 0,
    end: (timeToUse / 3) * 2 - 1,
  },
  verify: {
    text: `${((timeToUse / 3) * 2) / 60}-${timeToUse / 60} mins`,
    start: (timeToUse / 3) * 2,
    end: timeToUse - 1,
  },
  late: {
    text: `mas de ${timeToUse / 60} mins`,
    start: timeToUse,
  },
});

export const ORDERS_TIMING = [
  {
    // CBC
    id: "8e632828-dcda-4853-9660-f82af914238f",
    ...getTimes(times.CBC),
  },
  {
    // Pollo Brujo
    id: "69a22355-c9d7-41d9-8126-7af95aa031a2",
    ...getTimes(times.POLLO_BRUJO),
  },
  {
    // Gelatiamo
    id: "b66c1519-b997-4c75-a261-404bf57ede47",
    ...getTimes(times.GELATIAMO),
  },
  {
    // El Duende Mall
    id: "2ddea572-5c87-4443-9c3b-61238e06779a",
    ...getTimes(times.EL_DUENDE_MALL),
  },
  {
    // Prime Food
    id: "73b9cbcd-bb22-4eff-a570-a307e9e2a842",
    ...getTimes(times.PRIME_FOOD),
  },
  {
    // Ocean Weaver
    id: "837794ec-5419-4532-ad17-18a589422b50",
    ...getTimes(times.OCEAN_WEAR),
  },
  {
    // Licorera
    id: "3a54c4ce-9b9c-4e64-a8bf-9fe65aecf6a9",
    ...getTimes(times.LICORERA),
  },
  {
    // Licorera
    id: "49a417ea-e01a-45f0-882b-77e2213d997e",
    ...getTimes(times.CACAO_70),
  },
  {
    // Mixtas Frankfurt
    id: "4f63b438-bec6-4a25-ab03-560083023270",
    ...getTimes(times.MIXTAS),
  },
  {
    // La Tavola
    id: "66edd812-181b-47cd-a627-fb74b793cf9c",
    ...getTimes(times.LA_TAVOLA),
  },
  {
    // Lulis
    id: "844b2639-a695-4d56-b9b2-3d1be20ce396",
    ...getTimes(times.LULIS),
  },
  {
    // Churreria
    id: "877f568c-42ac-4c8f-bd25-030a03d24ab8",
    ...getTimes(times.CHURERRIA_PORFIRIO),
  },
  {
    // Splendido Bistro
    id: "896434b9-b80a-4a46-9849-ef2ebdbba675",
    ...getTimes(times.SPLENDIDO_BISTRO),
  },
  {
    // Yogen Fruz
    id: "aefbc45a-a146-4364-9475-b0d8fc708f26",
    ...getTimes(times.YOGEN_FRUZ),
  },
  {
    // Nacion Sushi
    id: "b0a7ef37-0806-4f9e-88fa-aeadd44bd55c",
    ...getTimes(times.NACION_SUSHI),
  },
  {
    // Panina
    id: "c304b195-adb3-40f5-b2d5-13ab037f9916",
    ...getTimes(times.PANINA),
  },
  {
    // San Marti
    id: "c3f020f3-e2b5-4b19-8410-1a60fa0eadd9",
    ...getTimes(times.SAN_MARTI),
  },
  {
    // iShop
    id: "fc078ef2-2276-415b-a46d-35d202b09f22",
    ...getTimes(times.ISHOP),
  },
];
