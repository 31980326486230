/* eslint-disable array-callback-return */
import React, { Fragment } from "react";
import {   
  cellRenderEc,
  headerCellComponentEN,
  cellRenderPa,
  headerCellComponentPA,
  headerCellComponentPR,
  cellRenderPr,
  cellRenderEr,
  headerCellComponentER,
  cellRenderOe,    
  headerCellComponentOE,
  FormPrincipal,
} from "./CompanyTableStatus"
import DataGridAllOrders, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  StateStoring,
  HeaderFilter,
  Summary,
  TotalItem,
  FilterPanel,
  FilterRow,
  Export,
  Scrolling,
} from "devextreme-react/data-grid";
import onExporting from "../Utils/downloadsFiles";

const CompanyTableOnStatusAll = ({ getRowsAllOrdersdev }) => {
  getRowsAllOrdersdev.map((inp)=> {
    inp.orderId = (inp || "").orderId.toUpperCase()
    inp.alias.name = inp.alias.name ? inp.alias.name.toUpperCase() : "" 
    inp.branch.name = (inp.branch || "").name.toUpperCase();
    inp.status = (inp||"").status.toUpperCase();
    inp.address = (inp||"").address.toUpperCase();
  })
 
  const calculateSortValue = (rowData) => {
    if (rowData.status === "En ruta") {
      let value = rowData.status;
      return value === 'asc' ? "aaa" : "zzz"; // CEOs are always displayed at the top    
    } else {
      return rowData.status; // Others are sorted as usual
    }
  }
  const allowedPageSizes = [10, 20, 50];
  return (
    <Fragment>
      <FormPrincipal/>
      <DataGridAllOrders
        keyExpr="id"
        dataSource={getRowsAllOrdersdev}
        width={"100%"}
        showBorders={true}
        rowAlternationEnabled={true}
        noDataText="NO HAY INFORMACIÓN"
        repaintChangesOnly={true}
        onExporting={(e)=>onExporting(e,'status')}
      >
        <HeaderFilter visible={true} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="companyStatusAll"
        />
        <SearchPanel visible={true} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showNavigationButtons={true}
        />
        <FilterRow visible={true} />
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="orderId"
            alignment="left"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />

        <Paging defaultPageSize={20} />
        <Column
          dataField="status"
          caption="ESTADO DE ORDEN"
          width={"auto"}
          sortOrder="asc"
          calculateSortValue={calculateSortValue}
        />
        <Column dataField="orderId" caption="ORDEN No." width={"auto"} />
        <Column dataField="alias.name" caption="PILOTO" width={"auto"} />
        <Column dataField="branch.name" caption="SUCURSAL" width={"auto"} />
        <Column
          dataField=""
          caption=""
          cellRender={cellRenderEc}
          headerCellComponent={headerCellComponentEN}
          width={"auto"}
        />
        <Column
          dataField=""
          caption=""
          headerCellComponent={headerCellComponentPA}
          cellRender={cellRenderPa}
          width={"auto"}
        />
        <Column
          dataField=""
          caption=""
          headerCellComponent={headerCellComponentPR}
          cellRender={cellRenderPr}
          width={"auto"}
        />
        <Column
          dataField=""
          caption=""
          headerCellComponent={headerCellComponentER}
          cellRender={cellRenderEr}
          width={"auto"}
        />
        <Column
          dataField=""
          caption=""
          headerCellComponent={headerCellComponentOE}
          cellRender={cellRenderOe}
          width={"auto"}
        />
        <Column dataField="address" caption="DIRECCIÓN" width={"auto"} />
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGridAllOrders>
    </Fragment>
  );
};

export default CompanyTableOnStatusAll;