import React,{Fragment  } from "react";
import { Breadcrumbs,Link,Typography,makeStyles} from "@material-ui/core";
const useStyles = makeStyles((theme)=>({
  title:{
    color:"#263238",
    fontSize:'25px',
    fontWeight:500,
    lineHeight:1.167,
    letterSpacing:'-0.06px'
    // line-height: 1.167;
    // letter-spacing: -0.06px;
  }
}))
const BreadCrumbContainer = ({title,  match }) => {
  const classes = useStyles();
    return (
      <Fragment>
      <Typography className={classes.title}>{title}</Typography>
        {/* {heading && <h1><IntlMessages id={heading}/></h1>} */}
        <BreadcrumbItems match={match} title={title} key= {title}/>
          <hr></hr>
      </Fragment>
    );
}
const getUrl = (path, sub, index) => {
    if (index === 0) {
      return "";
    } else {
      return path.split(sub)[0] + sub;
    }
  };
export const BreadcrumbItems = ({ match,title }) => {
    const path = match.path.substr(1);
    let paths = path.split("/");
    if (paths[paths.length - 1].indexOf(":") > -1) {
      paths = paths.filter(x => x.indexOf(":") === -1);
    }
    return (
      <Fragment>
        <Breadcrumbs aria-label="breadcrumb" key = "breadcrumbs">
          {paths.map((sub, index) => {
            return (
            <Link color="inherit" href={"/" + getUrl(path, sub, index)}>
                {sub}
              </Link>
            );
          })}
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
      </Fragment>
    );
  };
export default BreadCrumbContainer;