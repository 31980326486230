import * as types from "../types/orders";
import { getOrdersByBikerId, getToken } from "../selectors/orders";
import { getController } from "../reducers";
import { isEmpty } from "lodash";

export const setShouldRunTimeout = (shouldRunTimeout, opts = {}) => ({
  type: types.SET_SHOULD_RUN_TIMEOUT,
  payload: { opts, shouldRunTimeout },
});

export const clearOrderSubscription = (opts = {}) => ({
  type: types.CLEAR_ORDERS_SUBSCRIPTION,
  payload: { opts },
});

//  FETCH ORDERS
export const startFetchingOrders = (
  { statusFilter, alertFilter, branchId, companyId, companyIdList = [] } = {},
  opts = {}
) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  const controllerCompanyId = controller.companyId;
  const controllerBranchId = controller.branchId;
  dispatch({
    type: types.FETCH_ORDERS_STARTED,
    payload: {
      opts,
      filters: {
        statusFilter,
        alertFilter,
        branchId: isEmpty(branchId) ? controllerBranchId : branchId,
        companyId: isEmpty(companyId) ? controllerCompanyId : companyId,
        companyIdList,
      },
    },
  });
};
export const startFetchingMonthlyOrders = (
  { statusFilter, alertFilter, branchId, companyId } = {},
  opts = {}
) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  const controllerCompanyId = controller.companyId;
  const controllerBranchId = controller.branchId;
  dispatch({
    type: types.FETCH_MONTHLY_ORDERS_STARTED,
    payload: {
      opts,
      filters: {
        statusFilter,
        alertFilter,
        branchId: isEmpty(branchId) ? controllerBranchId : branchId,
        companyId: isEmpty(companyId) ? controllerCompanyId : companyId,
      },
    },
  });
};
export const setFetchOrdersLoadingStatus = (isLoading, opts = {}) => ({
  type: types.SET_FETCH_ORDERS_LOADING,
  payload: { opts, isLoading },
});

export const failFetchingOrders = (error, opts = {}) => ({
  type: types.FETCH_ORDERS_FAILED,
  payload: { opts, error },
});

export const startFetchingHistoryOrders = (
  { statusFilter, allOrders = false, days = 0 } = {},
  opts = {}
) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  dispatch({
    type: types.FETCH_HISTORY_ORDERS_STARTED,
    payload: {
      opts,
      statusFilter,
      branchId: controller.branchId,
      companyId: controller.companyId,
      allOrders: allOrders,
      days,
    },
  });
};

export const completeFetchingHistoryOrders = (entities, order, opts = {}) => ({
  type: types.FETCH_HISTORY_ORDERS_COMPLETED,
  payload: { opts, entities, order },
});

export const failFetchingHistoryOrders = (error, opts = {}) => ({
  type: types.FETCH_HISTORY_ORDERS_FAILED,
  payload: { opts, error },
});

//  EDIT ORDER
export const startEditingOrder = (order, prevOrder, opts = {}) => (
  dispatch,
  getState
) => {
  const currState = getState();
  let token = getToken(currState);
  if (!isEmpty(token)) {
    const now = new Date().getTime();
    if (token.expiresAtt < now) {
      token = undefined;
    }
  }
  dispatch({
    type: types.EDIT_ORDER_STARTED,
    payload: { opts, order, token, prevOrder },
  });
};

export const modifyUserInForm = (
  orderId,
  clear = false,
  orderModifiyingById = "",
  opts
) => ({
  type: types.EDIT_ORDER_USERS_IN_FORM,
  payload: { opts, orderId, clear, orderModifiyingById },
});

export const completeEditingOrder = (order, opts = {}) => ({
  type: types.EDIT_ORDER_COMPLETED,
  payload: { opts, order },
});

export const failEditingOrder = (error, opts = {}) => ({
  type: types.EDIT_ORDER_FAILED,
  payload: { opts, error },
});

//  ADD ORDER
export const addingOrder = (order, orderId, opts = {}) => ({
  type: types.ORDER_ADDED,
  payload: { opts, order, orderId },
});
export const addingMonthlyOrder = (order, orderId, opts = {}) => ({
  type: types.MONTHLY_ORDER_ADDED,
  payload: { opts, order, orderId },
});
//  MODIFY ORDER
export const modifyingOrder = (
  order,
  orderId,
  onlyTimeAlert = false,
  opts
) => ({
  type: types.ORDER_MODIFIED,
  payload: { opts, order, orderId, onlyTimeAlert },
});
export const modifyingMonthlyOrder = (order, orderId, opts = {}) => ({
  type: types.MONTHLY_ORDER_MODIFIED,
  payload: { opts, order, orderId },
});
//  CANCEL ORDER
export const cancelingOrder = (order, orderId, opts = {}) => ({
  type: types.ORDER_CANCELED,
  payload: { opts, order, orderId },
});

//  REMOVE ORDER
export const removingOrder = (order, orderId, opts = {}) => ({
  type: types.ORDER_REMOVED,
  payload: { opts, order, orderId },
});
export const removingMonthlyOrder = (order, orderId, opts = {}) => ({
  type: types.MONTHLY_ORDER_REMOVED,
  payload: { opts, order, orderId },
});
//  SELECT ORDER
export const selectingOrder = (order, opts = {}) => ({
  type: types.ORDER_SELECTED,
  payload: { opts, order },
});

//  DESELECT ORDER
export const deselectingOrder = (order, opts = {}) => ({
  type: types.ORDER_DESELECTED,
  payload: { opts, order },
});

//  REMOVE CHANGE
export const removingChange = (change, opts = {}) => ({
  type: types.ORDER_CHANGE_REMOVED,
  payload: { opts, change },
});

// GET ORDER BY ORDER ID
const getOrderByOrderId = (orderId, opts = {}) => ({
  type: types.GET_ORDER_BY_ID,
  payload: { opts, orderId },
});

const getOrderByOrderIdSuccess = (response, opts = {}) => ({
  type: types.GET_ORDER_BY_ID_SUCCESS,
  payload: { opts, response },
});

const getOrderByOrderIdFailure = (error, opts = {}) => ({
  type: types.GET_ORDER_BY_ID_FAILURE,
  payload: { opts, error },
});

const clearOrderEdition = (opts = {}) => ({
  type: types.CLEAR_ORDER_EDITION,
  payload: { opts },
});

// SUBSCRIBE ORDERS BY BIKER ID
const subscribeOrdersByBikerId = (bikerId, opts = {}) => ({
  type: types.SUBSCRIBE_ORDRES_BY_PILOT_ID,
  payload: { opts, bikerId },
});

const getOrdersWithTimeByBikerId = (biker, orders, opts = {}) => ({
  type: types.GET_ORDRES_WITH_TIME_BY_PILOT_ID,
  payload: { opts, biker, orders },
});

const getOrdersWithTimeByBikerIdSuccess = (orders, opts = {}) => ({
  type: types.GET_ORDRES_WITH_TIME_BY_PILOT_ID_SUCCESS,
  payload: { opts, orders },
});

const subscribeOrdersByBikerIdChanges = (
  response,
  shouldDelete = false,
  opts = {}
) => (dispatch, getState) => {
  const currState = getState();
  let orders = [...getOrdersByBikerId(currState)];
  if (!shouldDelete) {
    if (!isEmpty(orders)) {
      const orderIndex = orders.findIndex((val) => val.id === response.id);
      if (orderIndex >= 0) {
        orders[orderIndex] = response;
      } else {
        orders = orders.concat(response);
      }
    } else {
      orders = [response];
    }
  } else {
    orders = orders.filter((val) => val.id === response.id);
  }
  dispatch({
    type: types.SUBSCRIBE_ORDRES_BY_PILOT_ID_CHANGE,
    payload: { opts, orders },
  });
};

const subscribeOrdersByBikerIdFailure = (error, opts = {}) => ({
  type: types.SUBSCRIBE_ORDRES_BY_PILOT_ID_FAILURE,
  payload: { opts, error },
});

const clearOrdersByBikerId = (opts = {}) => ({
  type: types.CLEAR_ORDERS_BY_PILOT_ID,
  payload: { opts },
});

const setAPIToken = (token, opts = {}) => ({
  type: types.SET_API_TOKEN,
  payload: { opts, token },
});

const deleteOrder = (order, opts = {}) => ({
  type: types.DELETE_ORDER,
  payload: { opts, order },
});

const deleteOrderSuccess = (opts = {}) => ({
  type: types.DELETE_ORDER_SUCCESS,
  payload: { opts },
});

const deleteOrderFailure = (error, opts = {}) => ({
  type: types.DELETE_ORDER_FAILURE,
  payload: { opts, error },
});

const createOrder = (order, opts = {}) => ({
  type: types.CREATE_ORDER,
  payload: { opts, order },
});

const createOrderSuccess = (opts = {}) => ({
  type: types.CREATE_ORDER_SUCCESS,
  payload: { opts },
});

const createOrderFailure = (error, opts = {}) => ({
  type: types.CREATE_ORDER_FAILURE,
  payload: { opts, error },
});

const clearCreateOrder = (opts = {}) => ({
  type: types.CLEAR_CREATE_ORDER,
  payload: { opts },
});

export const startFetchingSingleOrder = (id, opts = {}) => ({
  type: types.FETCH_SINGLE_ORDER_STARTED,
  payload: { opts, id },
});

export const completeFetchingSingleOrder = (order, opts = {}) => ({
  type: types.FETCH_SINGLE_ORDER_COMPLETED,
  payload: { opts, order },
});

export const failFetchingSingleOrder = (error, opts = {}) => ({
  type: types.FETCH_SINGLE_ORDER_FAILED,
  paylod: {
    error,
  },
});
export const startedOrderLink = (id, opts = {}) => ({
  type: types.GET_ORDER_LINK_STARTED,
  payload: { opts, id },
});

export const completedFetchingLink = (order, opts = {}) => ({
  type: types.GET_ORDER_LINK_COMPLETED,
  payload: { opts, order },
});

export const failedOrderLInk = (error, opts = {}) => ({
  type: types.GET_ORDER_LINK_FAILED,
  payload: { opts, error },
});

export const updateLinkStarted = (id, order, opts = {}) => ({
  type: types.UPDATE_ORDER_LINK_STARTED,
  payload: { opts, id, order },
});

export const updateLinkCompleted = (order, opts = {}) => ({
  type: types.UPDATE_ORDER_LINK_COMPLETED,
  payload: { opts, order },
});

export const updateLinkError = (error, opts = {}) => ({
  type: types.UPDATE_ORDER_LINK_FAILED,
  payload: { opts, error },
});
//TIME BIKER LINK
export const linkGetOrderWhitTimerBikerStarted = (id, opts = {}) => ({
  type: types.GET_ORDER_WITH_TIME_STARTED,
  payload: { opts, id },
});

export const linkGetOrderWhitTimerBikerCompleted = (bikerTime, opts = {}) => ({
  type: types.GET_ORDER_WITH_TIME_COMPLETED,
  payload: { opts, bikerTime },
});

export const linkGetOrderWhitTimerBikerFailed = (error, opts = {}) => ({
  type: types.GET_ORDER_WITH_TIME_FAILED,
  payload: { opts, error },
});

/*
  ALL ORDERS
    GET_ORDER_ALL_ORDER_STARTED
    GET_ORDER_ALL_ORDER_COMPLETED
    GET_ORDER_ALL_ORDER_FAILED
 */

export const gettingAllOrders = () => ({
  type: types.GET_ORDER_ALL_ORDER_STARTED,
});

export const getAllOrders = (orders) => ({
  type: types.GET_ORDER_ALL_ORDER_COMPLETED,
  payload: { orders },
});

export const getAllOrdersFailed = (e) => ({
  type: types.GET_ORDER_ALL_ORDER_FAILED,
  payload: { e },
});

export const orderActions = {
  getOrdersWithTimeByBikerId,
  getOrdersWithTimeByBikerIdSuccess,
  getOrderByOrderId,
  getOrderByOrderIdSuccess,
  getOrderByOrderIdFailure,
  clearOrderEdition,
  subscribeOrdersByBikerId,
  subscribeOrdersByBikerIdChanges,
  subscribeOrdersByBikerIdFailure,
  clearOrdersByBikerId,
  setAPIToken,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderFailure,
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  clearCreateOrder,
};
