import isEmpty from "lodash/isEmpty";
import {firebaseFirestore} from "../firebase/config";

const addElementToOptions = (field, valToAdd) => {
  let options = [...field.options];
  if (!isEmpty(valToAdd.value) && !isEmpty(valToAdd.text)) {
    const isAdded = Boolean(
      (options || []).find((opt) => opt.value === valToAdd.value)
    );
    if (!isAdded) {
      options = [
        ...options,
        {
          value: valToAdd.value,
          text: valToAdd.text,
        },
      ];
    }
  }
  return options;
};

const searchBranch = async () => {
  const db = firebaseFirestore.collection('branches');
  let data = []
  await (await db.get()).forEach((doc)=> {
    data.push({
      _id: doc.id,
      _branch: doc.data().name || "",
      _idCompany: doc.data().companyId || "",
      _companyName: doc.data().company || ""
    })
  })
  return data
}

const arrayHelper = {
  addElementToOptions,
  searchBranch
};


export default arrayHelper;
