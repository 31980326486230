import { combineReducers } from "redux";
import {
  COMPANIES_FETCH_STARTED,
  COMPANIES_FETCH_COMPLETED,
  COMPANIES_FETCH_FAILED,
  CLEAR_COMPANIES,
  COMPANY_CREATE_STARTED,
  COMPANY_CREATE_COMPLETED,
  COMPANY_CREATE_FAILED,
  COMPANY_MODIFY_STARTED,
  COMPANY_MODIFY_COMPLETED,
  COMPANY_MODIFY_FAILED,
  COMPANY_DELETE_STARTED,
  COMPANY_DELETE_COMPLETED,
  COMPANY_DELETE_FAILED,
  COMPANY_CLEAR_ALL,
} from "../types/company";

import * as types from "../types/company";

const fetchCompaniesInitialState = {
  companies: [],
  isLoading: false,
  error: null,
};

const fetchCompanies = (state = fetchCompaniesInitialState, action) => {
  switch (action.type) {
    case COMPANIES_FETCH_STARTED:
      return {
        ...state,
        ...fetchCompaniesInitialState,
        isLoading: true,
      };
    case COMPANIES_FETCH_COMPLETED:
      return {
        ...state,
        companies: action.payload.companies,
        isLoading: false,
      };
    case COMPANIES_FETCH_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    case CLEAR_COMPANIES:
      return {
        ...state,
        ...fetchCompaniesInitialState,
      };
    default:
      return {
        ...state,
      };
  }
};

const createCompanyInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const createCompany = (state = createCompanyInitialState, action) => {
  switch (action.type) {
    case COMPANY_CREATE_STARTED:
      return {
        ...state,
        ...createCompanyInitialState,
        isLoading: true,
      };
    case COMPANY_CREATE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case COMPANY_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case COMPANY_CLEAR_ALL: {
      return {
        ...state,
        ...createCompanyInitialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const modifyCompanyInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const modifyCompany = (state = modifyCompanyInitialState, action) => {
  switch (action.type) {
    case COMPANY_MODIFY_STARTED:
      return {
        ...state,
        ...modifyCompanyInitialState,
        isLoading: true,
      };
    case COMPANY_MODIFY_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case COMPANY_MODIFY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case COMPANY_CLEAR_ALL: {
      return {
        ...state,
        ...modifyCompanyInitialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const deleteCompanyInitialState = {
  done: false,
  isLoading: false,
  error: null,
};

const deleteCompany = (state = deleteCompanyInitialState, action) => {
  switch (action.type) {
    case COMPANY_DELETE_STARTED:
      return {
        ...state,
        ...deleteCompanyInitialState,
        isLoading: true,
      };
    case COMPANY_DELETE_COMPLETED:
      return {
        ...state,
        isLoading: false,
        done: true,
      };
    case COMPANY_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case COMPANY_CLEAR_ALL: {
      return {
        ...state,
        ...deleteCompanyInitialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

/* 
  SUMMARY COMPANIES
      GETTING_COMPANY_STARTED
      GETTING_COMPANY_COMPLETED
      GETTING_COMPANY_FAILED
*/

const gettingCompanyAll = (state=false, action) => {
  switch (action.type) {
      case types.GETTING_COMPANY_STARTED:{
          return true
      }
      case types.GETTING_COMPANY_COMPLETED:{
          return false
      }
      case types.GETTING_COMPANY_FAILED:{
          return false
      }
      default:{
          return state
      }
  }
}

const getCompanyAll = (state=null, action) => {
  switch (action.type) {
      case types.GETTING_COMPANY_COMPLETED:{
          return action.payload.companies
      }
      default:{
          return state
      }
  }
}

const gettingCompanyFail = (state=null, action) => {
  switch (action.type) {
      case types.GETTING_COMPANY_FAILED:{
          return action.payload.e
      }
      default:{
          return state
      }
  }
}


export default combineReducers({
  deleteCompany,
  modifyCompany,
  fetchCompanies,
  createCompany,
  gettingCompanyAll,
  getCompanyAll,
  gettingCompanyFail
  
});

export const gettingCompany = (state) => state.gettingCompanyAll;
export const getCompany= (state) => state.getCompanyAll;
export const gettingCompanyFailed = (state) => state.gettingCompanyFail;

