import React from "react";
import DataGridOnRoute, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  StateStoring,
  HeaderFilter,
  Summary,
  TotalItem,
  FilterPanel,
  FilterRow,
  Export,Scrolling
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import dateHelper from "../../helpers/dateHelper";
// import * as selectors from "../../logic/reducers";
import onExporting from "../Utils/downloadsFiles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

export default function CompanyTableonRoute({
  rows,
  setCurrOrder,
  setModalMapOpen,
}) {

  const history = useHistory();
  const allowedPageSizes = [10, 20, 50];
  // const orders = useSelector(selectors.getOrders);
  const handleClickOpen = (e) => {
    // console.log(e);
    history.push(`/share-companies/${e.id}`);
  }
  const cellRenderOnTrack = ({ data }) => {
    // console.log({data});
    if (data.alias.name === undefined || data.alias.name === null) {
      return (
        "NO HAY PILOTO"
      );
    } else {
      return (
       <Button variant="outlined" color="primary" onClick={()=>handleClickOpen(data)}>
        VER PILOTO
      </Button>
      )
    }
  };

  // eslint-disable-next-line array-callback-return
  rows.map((data) => {
    if (data.alias?.name === undefined) {
      data.timeAprox = "FALTA POR ASIGNAR"
    }
    if (data.time !== null || data.time !== undefined) {
      data.timeAprox = dateHelper.secondsToHms(data.time);
    }
  });

  return (
    <div>
      <DataGridOnRoute
        keyExpr="id"
        id="dataGridOnRoute"
        dataSource={rows}
        width={"100%"}
        showBorders={true}
        rowAlternationEnabled={true}
        noDataText="NO HAY INFORMACIÓN"
        repaintChangesOnly={true}
        onExporting={(e) => onExporting(e, 'en-ruta')}
      >
        <HeaderFilter visible={true} />
        <Paging enabled={true} defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          displayMode={true}
          allowedPageSizes={allowedPageSizes}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Summary recalculateWhileEditing={true}>
          <TotalItem
            column="orderId"
            cssClass="cell-highlightedOnCompany"
            displayFormat="ORDENES: {0}"
            summaryType="count"
          />
        </Summary>
        <Scrolling columnRenderingMode="virtual" showScrollbar="onHover" scrollByContent />
        <FilterPanel
          visible={true}
          texts={{
            createFilter: "CREAR FILTRO",
            clearFilter: "ELIMINAR FILTRO",
            filterEnabledHint: "CREACIÓN DE FILTROS",
          }}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="companyOrdersOnRoute"
        />
        <SearchPanel visible={true} placeholder="BUSCAR..." />
        <FilterRow visible={true} />
        <Paging enabled={true} defaultPageSize={20} />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="orderId"
          caption="ORDEN No."
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="branch.name"
          caption="SUCURSAL"
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="alias.name"
          caption="PILOTO"
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="transportId"
          caption="PLACAS"
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="bikerPhone"
          caption="TELÉFONO PILOTO"
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="address"
          caption="DIRECCIÓN"
          width={"auto"}
        />
        <Column
          cssClass="cell-highlightedOnCompany"
          dataField="status"
          caption="ESTATUS"
          width={"auto"}
        />
        <Column
          dataField="timeAprox"
          caption="TIEMPO ENTREGA PROX"
          width={"auto"}
        />
        <Column
          dataField="id"
          caption=""
          cellRender={cellRenderOnTrack}
          width={"auto"}
        />
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGridOnRoute>
    </div>
  );
}
