/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import BranchForm from "./BranchForm";
import {
  emptyBranch,
  defaultFormFields,
} from "../../constants/branchConstants";
import { branchActions } from "../../logic/actions/branches";
import { ROUTES, snackBarConst } from "../../constants/constants";
import {
  isLoadingCreateBranch,
  getErrorCreateBranch,
  isDoneCreateBranch,
} from "../../logic/selectors/branch";

const validationSchema = yup.object({
  name: yup.string().required("Nombre es requerido"),
  address: yup.string().required("Dirección es requerida"),
  country: yup
    .object()
    .shape({ text: yup.string().required("El país es requerido") })
    .required(),
  region: yup
    .object()
    .shape({ text: yup.string().required("La región es requerida") })
    .required(),
  city: yup
    .object()
    .shape({ text: yup.string().required("La ciudad es requerida") })
    .required(),
});

const BranchCreate = ({ match: { params } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingCreateBranch);
  const isCreated = useSelector(isDoneCreateBranch);
  const error = useSelector(getErrorCreateBranch);
  const [branch, setBranch] = useState(emptyBranch);
  const btnList = [
    {
      id: "cancel-branch",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.push(`${ROUTES.COMPANY_BRANCHES}/${params.companyId}`);
        return;
      },
    },
    {
      id: "create-new-company",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear sucursal",
    },
  ];

  useOnCreatingBranch(
    isCreated,
    error,
    isLoading,
    history,
    enqueueSnackbar,
    params.companyId,
    dispatch
  );

  const onSubmit = (branchToCreate) => {
    dispatch(
      branchActions.createBranch({
        ...branchToCreate,
        CompanyId: params.companyId,
      })
    );
  };

  return (
    <BlockUi tag="div" blocking={isLoading}>
      <BranchForm
        formFields={defaultFormFields.map((formField) => ({
          ...formField,
        }))}
        branch={branch}
        btnList={btnList}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        setBranch={setBranch}
      />
    </BlockUi>
  );
};

const useOnCreatingBranch = (
  isCreated,
  error,
  isLoading,
  history,
  enqueueSnackbar,
  companyId,
  dispatch
) => {
  useEffect(() => {
    if (!isLoading) {
      if (isCreated) {
        enqueueSnackbar("Sucursal creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(branchActions.clearBranchStatuses());
        history.push(`${ROUTES.COMPANY_BRANCHES}/${companyId}`);
      } else if (!isEmpty(error)) {
        enqueueSnackbar(
          "Hubo un error al crear la sucursal, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [isCreated, error, isLoading]);
};

export default BranchCreate;
