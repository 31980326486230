import React, { useState } from "react";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PersonIcon from "@mui/icons-material/Person";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  tabContainer: {
    marginTop: theme.spacing(2),
  },
  contentContainer: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    width: 100,
    height: 100,
    transition: "transform 0.3s ease-in-out",
    cursor: "pointer",
    "&.zoomed": {
      transform: "scale(2.0)",
    },
  },
  phoneLink: {
    color: theme.palette.primary.main, // Color primario de Material-UI
    textDecoration: 'none', // Quita la subrayado por defecto
    transition: 'color 0.3s ease-in-out', // Transición de color suave
    '&:hover': {
      color: theme.palette.primary.dark, // Color oscuro al hacer hover
    },
  },
}));

const BikersInformation = ({ data_biker, data_orders }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [zoomed, setZoomed] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAvatarClick = () => {
    setZoomed(!zoomed); // Cambiar el estado de zoomed al hacer clic
  };

  // information
  const photoBiker = data_biker?.pic;
  const nameBiker = data_biker?.name;
  const placaBiker = data_biker?.transportId.toUpperCase();
  const cellphoneBiker = data_biker?.phone;

  return (
    <Container maxWidth="md" style={{ marginTop: "2rem" }}>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "0.5rem",
              color: "black",
            }}
          >
            información de tu pedido
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ textTransform: "uppercase", color: "black" }}
          >
            Estos son los datos de tu pedido
          </Typography>
        </div>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            icon={<TwoWheelerIcon />}
            arial-label="motorista"
            label="motorista"
          />
          <Tab icon={<PersonIcon />} arial-label="cliente" label="cliente" />
        </Tabs>

        <div className={classes.contentContainer}>
          {selectedTab === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
              >
                <Avatar
                  className={`${classes.avatar} ${zoomed ? "zoomed" : ""}`}
                  alt={nameBiker}
                  src={photoBiker}
                  onClick={handleAvatarClick}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Nombre:
                  </span>
                  <span
                    style={{
                      fontWeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {nameBiker}
                  </span>
                </Typography>
                <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Placa:
                  </span>
                  <span style={{ fontWeight: "normal" }}>{placaBiker}</span>
                </Typography>
                <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Telefono:
                  </span>
                  <a href={`tel:+502${cellphoneBiker}`} className={classes.phoneLink}>{cellphoneBiker}</a>
                </Typography>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                marginTop: "1rem",
                // textAlign: "center",
              }}
            >
              <div style={{ marginBottom: "1rem" }}>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "1rem", transform: "uppercase" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Número de Orden:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {data_orders.orderId}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "1rem", transform: "uppercase" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Cliente:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {data_orders.name}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "1rem", transform: "uppercase" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Dirección de entrega:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {data_orders.address}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "1rem", transform: "uppercase" }}
                >
                  <span style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
                    Observaciones:
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    {isEmpty(data_orders.nota) ||
                    data_orders.nota === "..." ||
                    data_orders.nota === "Esperando..."
                      ? "Sin observaciones"
                      : data_orders.nota}
                  </span>
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </Container>
  );
};

export default BikersInformation;
