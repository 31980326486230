import {CREATING_ISSUE_COMPLETED,CREATING_ISSUE_FAILED,CREATING_ISSUE_STARTED} from "../types/issues"
const initCreatingIssue = ({file,companyId,userName,branchId,email,comment,role})=>({
    type:CREATING_ISSUE_STARTED,
    payload:{file,companyId,userName,branchId,email,comment,role}
})
const creatingIssueCompleted = () =>({
    type:CREATING_ISSUE_COMPLETED
})
const creatingIssueFailed = () =>({
    type:CREATING_ISSUE_FAILED
})
export{
    initCreatingIssue,
    creatingIssueCompleted,
    creatingIssueFailed
}