import { getController } from "../reducers";
import isEmpty from "lodash/isEmpty";
import * as types from "../types/pilots";

export const startFetchingPilots = ({
  isActive,
  branchId,
  comodines = false,
} = {}) => (dispatch, getState) => {
  const currentState = getState();
  const controller = getController(currentState);
  dispatch({
    type: types.PILOTS_FETCH_STARTED,
    payload: {
      filter: {
        branchId: isEmpty(branchId) ? controller.branchId : branchId,
        isActive,
        comodines,
      },
    },
  });
};

export const completeFetchingPilots = (order, orderId) => ({
  type: types.PILOTS_FETCH_COMPLETED,
  payload: {
    order,
    orderId,
  },
});

export const failFetchingPilots = (error) => ({
  type: types.PILOTS_FETCH_FAILED,
  payload: {
    error,
  },
});

//  ADD PILOT
export const addingPilot = (pilot, pilotId) => ({
  type: types.PILOT_ADDED,
  payload: {
    pilot,
    pilotId,
  },
});

export const clearPilotsSubscription = () => ({
  type: types.PILOT_CLEAR,
});

//  EDIT PILOT
export const editingPilot = (pilot, pilotId) => ({
  type: types.PILOT_EDITED,
  payload: {
    pilot,
    pilotId,
  },
});

//  REMOVE PILOT
export const removingPilot = (pilot, pilotId) => ({
  type: types.PILOT_REMOVED,
  payload: {
    pilot,
    pilotId,
  },
});

//  SELECT A SPECIFIC PILOT
export const selectingPilot = (pilot) => ({
  type: types.SELECT_PILOT,
  payload: {
    pilot,
  },
});

//  DESELECT PILOT
export const deselectingPilot = () => ({
  type: types.DESELECT_PILOT,
});

const getPilotByPilotId = (bikerId) => ({
  type: types.GET_PILOT_BY_PILOT_ID,
  payload: {
    bikerId,
  },
});

const getPilotByPilotIdSuccess = (biker) => ({
  type: types.GET_PILOT_BY_PILOT_ID_SUCCESS,
  payload: {
    biker,
  },
});

const getPilotByPilotIdFailure = (error) => ({
  type: types.GET_PILOT_BY_PILOT_ID_FAILURE,
  payload: {
    error,
  },
});

const clearPilotByIdData = () => ({
  type: types.CLEAR_PILOT_BY_ID_DATA,
});

const getPilots = ({ branchId, companyId, closeBikers, useService } = {}) => ({
  type: types.GET_PILOTS,
  payload: {
    filters: {
      branchId,
      companyId,
      closeBikers,
      useService,
    },
  },
});

const getPilotsSuccess = (bikerList) => ({
  type: types.GET_PILOTS_SUCCESS,
  payload: {
    bikerList,
  },
});

const getPilotsFailure = (error) => ({
  type: types.GET_PILOTS_FAILURE,
  payload: {
    error,
  },
});

const clearPilotsData = () => ({
  type: types.CLEAR_PILOT_DATA,
});

const subscribePilotByPilotId = (bikerId, isMongoId = false) => ({
  type: types.SUBSCRIBE_PILOT_BY_PILOT_ID,
  payload: {
    bikerId,
    isMongoId,
  },
});

const subscribePilotByPilotIdSuccess = (biker) => ({
  type: types.SUBSCRIBE_PILOT_BY_PILOT_ID_SUCCESS,
  payload: {
    biker,
  },
});

const subscribePilotByPilotIdFailure = (error) => ({
  type: types.SUBSCRIBE_PILOT_BY_PILOT_ID_FAILURE,
  payload: {
    error,
  },
});

const clearSubscribeByPilotId = () => ({
  type: types.CLEAR_SUBSCRIBE_PILOT_BY_PILOT_ID,
});

export const startFetchingSinglePilot = (id) => ({
  type: types.FETCH_SINGLE_PILOT_STARTED,
  payload: {
    id,
  },
});

export const completeFetchingSinglePilot = (pilot) => ({
  type: types.FETCH_SINGLE_PILOT_COMPLETED,
  payload: {
    pilot,
  },
});

export const faildFetchingSinglePilot = (error) => ({
  type: types.FETCH_SINGLE_PILOT_FAILED,
  payload: {
    error,
  },
});
const createBiker = ({ name, phone, branch, company } = {}) => ({
  type: types.BIKER_CREATE_STARTED,
  payload: {
    name,
    phone,
    branch,
    company,
  },
});

const createBikerSuccess = () => ({
  type: types.BIKER_CREATE_COMPLETED,
});

const createBikerFailure = (error) => ({
  type: types.BIKER_CREATE_FAILED,
  payload: {
    error,
  },
});

const clearBikerEdition = () => ({
  type: types.BIKER_EDIT_CLEAR,
});

const editBiker = (
  { id, name, phone, type, branch, company, dpi, transportId, pertenece } = {},
  image
) => ({
  type: types.BIKER_EDIT_STARTED,
  payload: {
    id,
    name,
    phone,
    type,
    branch,
    company,
    image,
    dpi,
    transportId,
    pertenece
  },
});

const editBikerSuccess = () => ({
  type: types.BIKER_EDIT_COMPLETED,
});

const editBikerFailure = (error) => ({
  type: types.BIKER_EDIT_FAILED,
  payload: {
    error,
  },
});

const editBikerAlert = (id, alert) => ({
  type: types.BIKER_EDIT_ALERT_STARTED,
  payload: {
    id,
    alert,
  },
});

const editBikerAlertSuccess = () => ({
  type: types.BIKER_EDIT_ALERT_COMPLETED,
});

const editBikerAlertFailure = (error) => ({
  type: types.BIKER_EDIT_ALERT_FAILED,
  payload: {
    error,
  },
});

const editBikerAlertClear = () => ({
  type: types.BIKER_EDIT_ALERT_CLEAR,
  payload: {},
});

const deleteBiker = (id, mongoId) => ({
  type: types.BIKER_DELETE_STARTED,
  payload: {
    id,
    mongoId,
  },
});

const deleteBikerSuccess = () => ({
  type: types.BIKER_DELETE_COMPLETED,
});

const deleteBikerFailure = (error) => ({
  type: types.BIKER_DELETE_FAILED,
  payload: {
    error,
  },
});

const fetchPilotsByStatusStarted = (status) => ({
  type: types.BIKERS_BY_STATUS_STARTED,
  payload: { status },
});
const fetchPilotsByStatusCompleted = (data) => ({
  type: types.BIKERS_BY_STATUS_COMPLETED,
  payload: { data },
});
const fetchPilotsByStatusFailed = (error) => ({
  type: types.BIKERS_BY_STATUS_FAILED,
  payload: { error },
});
const fetchPilotsByStatusEmpty = () => ({
  type: types.BIKERS_BY_STATUS_EMPTY,
});
const authorizePilotStarted = ({ uid, id, type, status }) => ({
  type: types.AUTHORIZE_PILOT_STARTED,
  payload: {
    uid,
    id,
    type,
    body: { status },
  },
});
export const authorizePilotCompleted = () => ({
  type: types.AUTHORIZE_PILOT_COMPLETED,
});
export const authorizePilotError = () => ({
  type: types.AUTHORIZE_PILOT_FAILED,
});
const createBikerRatingStarted = ({
  clearBikerRatingStarted,
  bikerId,
  controllerId,
  nameBiker,
  branch,
  dpi,
  branchId,
  rating,
  message,
  issue,
  type,
  transportId,
  phone,
}) => ({
  type: types.CREATE_BIKER_RATING_STARTED,
  payload: {
    bikerId,
    nameBiker,
    branch,
    dpi,
    controllerId,
    branchId,
    rating,
    message,
    issue,
    type,
    transportId,
    phone,
  },
});
const clearBikerRatingStarted = () => ({
  type: types.CLEAR_BIKER_RATING_STARTED,
});
const createBikerRatingCompleted = () => ({
  type: types.CREATE_BIKER_RATING_COMPLETED,
});
const createBikerRatingError = () => ({
  type: types.CREATE_BIKER_RATING_FAILED,
});

//GET LINK ON PILOT
export const pilotGetLinkStarted = (id) => ({
  type: types.GET_PILOT_LINK_STARTED,
  payload: {
    id,
  },
});

export const pilotGetLinkCompleted = (pilot) => ({
  type: types.GET_PILOT_LINK_COMPLETD,
  payload: {
    pilot,
  },
});

export const pilotGetLinkFailed = (error) => ({
  type: types.GET_PILOT_LINK_FAILED,
  payload: {
    error,
  },
});

export const pilotRatingGetStarted = () => ({
  type: types.GET_BIKER_RATING_STARTED,
});

export const pilotRatingGetCompleted = (bikerRating) => ({
  type: types.GET_BIKER_RATING_COMPLETED,
  payload: {
    bikerRating,
  },
});

export const pilotRatingGetFailed = (error) => ({
  type: types.GET_BIKER_RATING_FAILED,
  payload: {
    error,
  },
});

export const pilotSchedulesStarted = (paramsBranch) => ({
  type: types.GET_SCHEDULES_STARTED,
  payload:{
    paramsBranch
  }
});

export const pilotSchedulesGetCompleted = (schedules) => ({
  type: types.GET_SCHEDULES_COMPLETED,
  payload: {
    schedules,
  },
});

export const pilotSchedulesGetFailed = (error) => ({
  type: types.GET_SCHEDULES_FAILED,
  payload: {
    error,
  },
});

export const pilotSchedulesMonthsStarted = (month) => ({
  type: types.GET_SCHEDULES_MONTH_STARTED,
  payload:{
    month
  }
})

export const pilotSchedulesMonthsCompleted = (assistance) => ({
  type: types.GET_SCHEDULES_MONTH_COMPLETED,
  payload:{
    assistance
  }
})

export const pilotSchedulesMonthsFailed = (error) =>  ({
  type: types.GET_SCHEDULES_MONTH_FAILED,
  payload:{
    error
  }
});


export const pilotCreatingRakingStarted = (props) => ({
  type: types.CREATING_RANKING_STARTED,
  payload:{
    props
  }
});

export const pilotCreatedRakingCompleted = (request) => ({
  type: types.CREATING_RANKING_COMPLETED,
  payload:{
    request
  }
});

export const pilotCreatingRakingFailed = (error)=> ({
  type: types.CREATING_RANKING_FAILED,
  payload:{
    error
  }
})

export const getBikerStarted = () => ({
  type: types.GET_BIKER_BRANCH_STARTED,
})

export const getBikerCompleted = (biker) => ({
  type: types.GET_BIKER_BRANCH_COMPLETED,
  payload:{
    biker
  }
});

export const getBikerFailed = (error) => ({
  type: types.GET_BIKER_BRANCH_FAILED,
  payload:{
    error
  }
})

/*
  GET_SCHEDULE_STARTED
  GET_SCHEDULE_COMPLETED
  GET_SCHEDULE_FAILED
*/

export const getScheduleBikerStarted = () => ({
  type: types.GET_SCHEDULE_STARTED,
})

export const getScheduleBikerCompleted = (biker) => ({
  type: types.GET_SCHEDULE_COMPLETED,
  payload:{
    biker
  }
});

export const getScheduleBikerFailed = (error) => ({
  type: types.GET_SCHEDULE_FAILED,
  payload:{
    error
  }
})

/*
  GET_ASSISTENT_BIKER_STARTED
  GET_ASSISTENT_BIKER_STARTED
  GET_ASSISTENT_BIKER_FAILED
*/

export const getAssistenBikerStarted = () => ({
  type: types.GET_ASSISTENT_BIKER_STARTED,
})

export const getAssistenBikerCompleted = (biker) => ({
  type: types.GET_ASSISTENT_BIKER_COMPLETED,
  payload:{
    biker
  }
});

export const getAssistenBikerFailed = (error) => ({
  type: types.GET_ASSISTENT_BIKER_FAILED,
  payload:{
    error
  }
})

/*
  GET_UPDATE_ASSISTENCE_BIKER_STARTED
  GET_UPDATE_ASSISTENCE_BIKER_COMPLETED
  GET_UPDATE_ASSISTENCE_BIKER_FAILED
*/

export const getUpdateAssistenBikerStarted = (date) => ({
  type: types.GET_UPDATE_ASSISTENCE_BIKER_STARTED,
  payload: {
    date
  }
})

export const getUpdateAssistenBikerCompleted = (biker) => ({
  type: types.GET_UPDATE_ASSISTENCE_BIKER_COMPLETED,
  payload:{
    biker
  }
});

export const getUpdateAssistenBikerFailed = (error) => ({
  type: types.GET_UPDATE_ASSISTENCE_BIKER_FAILED,
  payload:{
    error
  }
})

export const pilotActions = {
  pilotSchedulesStarted,
  pilotSchedulesGetCompleted,
  pilotSchedulesGetFailed,
  getPilotByPilotId,
  getPilotByPilotIdSuccess,
  getPilotByPilotIdFailure,
  clearPilotByIdData,
  getPilots,
  getPilotsSuccess,
  getPilotsFailure,
  clearPilotsData,
  subscribePilotByPilotId,
  subscribePilotByPilotIdSuccess,
  subscribePilotByPilotIdFailure,
  clearSubscribeByPilotId,
  createBiker,
  createBikerSuccess,
  createBikerFailure,
  editBiker,
  editBikerSuccess,
  editBikerFailure,
  deleteBiker,
  deleteBikerSuccess,
  deleteBikerFailure,
  clearBikerEdition,
  fetchPilotsByStatusStarted,
  fetchPilotsByStatusCompleted,
  fetchPilotsByStatusFailed,
  fetchPilotsByStatusEmpty,
  authorizePilotStarted,
  editBikerAlert,
  editBikerAlertSuccess,
  editBikerAlertFailure,
  editBikerAlertClear,
  createBikerRatingStarted,
  clearBikerRatingStarted,
  createBikerRatingCompleted,
  createBikerRatingError
};
