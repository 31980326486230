/* eslint-disable no-unused-vars */
import { put, takeEvery, call } from "redux-saga/effects";
import {
  subscribePilots,
  getPilotByPilotId,
  getPilots,
  subscribePilotByPilotId,
  getTrakingPilot,
  updatePilot,
  removePilotByPilotId,
  updateBikerAlert,
  getPilotLink,
} from "../../firebase/pilots";
import { uploadImage, getImageUrl, removeImage } from "../../firebase/image";
import isEmpty from "lodash/isEmpty";
import { getBranches } from "../../firebase/branches";
import * as types from "../types/pilots";
import * as actions from "../actions/pilots";
import bikerService from "../services/bikerService";
import { BIKER_STATUS } from "../../constants/constants";
import { FIRESTORAGE } from "../../constants/constants";

function* fetchPilots(action) {
  try {
    const { filter } = action.payload;
    yield subscribePilots(filter);
  } catch (error) {
    console.log(error);
    yield put(actions.failFetchingPilots(error));
  }
}

export function* watchFetchPilots() {
  yield takeEvery(types.PILOTS_FETCH_STARTED, fetchPilots);
}

function* fetchPilotByPilotId(action) {
  try {
    const {
      payload: { bikerId },
    } = action;
    const response = yield getPilotByPilotId(bikerId);
    console.log(response);
    yield put(actions.pilotActions.getPilotByPilotIdSuccess(response));
  } catch (error) {
    yield put(actions.pilotActions.getPilotByPilotIdFailure(error.message));
  }
}

export function* watcherFetchPilotByPilotId() {
  yield takeEvery(types.GET_PILOT_BY_PILOT_ID, fetchPilotByPilotId);
}

function* subscriptPilotByPilotId(action) {
  try {
    const {
      payload: { bikerId, isMongoId },
    } = action;
    let bikerIdToUse = bikerId;
      const biker = yield bikerService.getBikerByBikerId(bikerId);
        yield put(
          actions.pilotActions.subscribePilotByPilotIdSuccess({
            ...biker,
          })
        );
    // bikerIdToUse = biker.uid;
    yield subscribePilotByPilotId(bikerIdToUse);
  } catch (error) {
    yield put(actions.pilotActions.getPilotByPilotIdFailure(error.message));
  }
}

export function* watcherSubscriptPilotByPilotId() {
  yield takeEvery(types.SUBSCRIBE_PILOT_BY_PILOT_ID, subscriptPilotByPilotId);
}

function* fetchPilotList(action) {
  try {
    const { filters } = action.payload;
    // eslint-disable-next-line no-unused-vars
    let bikersClosersTo;
    if (!isEmpty(filters) && filters.closeBikers) {
      const branch = (yield getBranches(filters))[0];
      bikersClosersTo = {
        lat: branch.lat,
        lng: branch.lng,
      };
    }
    let response = [];
    if (filters.useService) {
      response = yield bikerService.getBikersByFilters(filters);
    } else {
      response = yield getPilots(filters);
    }
    yield put(actions.pilotActions.getPilotsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(actions.pilotActions.getPilotsFailure(error.message));
  }
}
function* fetchPilotsByStatus({ payload }) {
  const { status } = payload;
  try {
    let response = yield call(bikerService.getBikersByStatus, status);
    yield put(actions.pilotActions.fetchPilotsByStatusCompleted(response));
  } catch (error) {
    console.error(error);
    yield put(actions.pilotActions.fetchPilotsByStatusFailed(error));
  }
}
function* startAuthorizingPilot({ payload }) {
  const { id, uid, type } = payload;
  try {
    yield call(bikerService.changeBikerStatus, id, uid, "ACTIVE");
    yield call(bikerService.changeBikerType, uid, type);
    let response = yield call(bikerService.getBikersByStatus, "NEW");
    yield put(actions.pilotActions.fetchPilotsByStatusCompleted(response));
    yield put(actions.authorizePilotCompleted());
  } catch (error) {
    console.log(error);
    yield put(actions.authorizePilotError());
  }
}
export function* watchAuthorizePilot() {
  yield takeEvery(types.AUTHORIZE_PILOT_STARTED, startAuthorizingPilot);
}
export function* watchFetchPilotsByStatus() {
  yield takeEvery(types.BIKERS_BY_STATUS_STARTED, fetchPilotsByStatus);
}
export function* watcherFetchPilotList() {
  yield takeEvery(types.GET_PILOTS, fetchPilotList);
}

function* fetchSinglePilot(action) {
  try {
    const id = action.payload.id;
    yield getTrakingPilot(id);
  } catch (error) {
    console.log(error);
    yield put(actions.faildFetchingSinglePilot(error));
  }
}
export function* watchFetchSinglePilot() {
  yield takeEvery(types.FETCH_SINGLE_PILOT_STARTED, fetchSinglePilot);
}

function* createPilotRatingStarted({ payload }) {
  try {
    yield bikerService.createComment(
      payload.bikerId,
      payload.branchId,
      payload.nameBiker,
      payload.rating,
      payload.message,
      payload.issue,
      payload.type,
      payload.branch,
      payload.dpi,
      payload.transportId,
      payload.phone
    );
    // yield call(createRatingComment, payload);
    yield put(actions.pilotActions.createBikerRatingCompleted());
  } catch (error) {
    console.error(error);
    yield put(actions.pilotActions.createBikerRatingError());
  }
}
export function* watchCreateBikerRating() {
  yield takeEvery(types.CREATE_BIKER_RATING_STARTED, createPilotRatingStarted);
}
function* onBikerEdit(action) {
  let uploadedImage;
  try {
    let bikerPic;
    const {
      id,
      name,
      phone,
      type,
      branch,
      company,
      image,
      dpi,
      transportId,
      pertenece
    } = action.payload;
    if (!isEmpty(image)) {
      uploadedImage = yield uploadImage(FIRESTORAGE.BIKERS, image[0]);
      const imageUrl = yield getImageUrl(uploadedImage);
      bikerPic = imageUrl;
    }
    yield updatePilot(
      id,
      name,
      phone,
      type,
      branch,
      company,
      bikerPic,
      dpi,
      transportId,
      pertenece
    );
    yield put(actions.pilotActions.editBikerSuccess());
  } catch (error) {
    console.log(error);
    if (!isEmpty(uploadedImage)) {
      yield removeImage(uploadedImage);
    }
    yield put(actions.pilotActions.editBikerFailure(error.message));
  }
}

export function* watchEditBiker() {
  yield takeEvery(types.BIKER_EDIT_STARTED, onBikerEdit);
}

function* onBikerDelete(action) {
  try {
    const { id } = action.payload;
    yield removePilotByPilotId(id);
    yield put(actions.pilotActions.deleteBikerSuccess());
  } catch (error) {
    yield put(actions.pilotActions.deleteBikerFailure(error.message));
  }
}

export function* watchDeleteBiker() {
  yield takeEvery(types.BIKER_DELETE_STARTED, onBikerDelete);
}

function* onBikerEditAlert(action) {
  try {
    const { id, alert } = action.payload;
    yield updateBikerAlert(id, alert);
    yield put(actions.pilotActions.editBikerAlertSuccess());
  } catch (error) {
    yield put(actions.pilotActions.editBikerAlertFailure(error.message));
  }
}

export function* watchEditBikerAlert() {
  yield takeEvery(types.BIKER_EDIT_ALERT_STARTED, onBikerEditAlert);
}

function* getSinglePilotLink(action) {
  try {
    const id = action.payload.id;
    yield getPilotLink(id);
  } catch (error) {
    console.log(error);
    yield put(actions.pilotGetLinkFailed(error.message));
  }
}

export function* watchLinkSinglePilotLink() {
  yield takeEvery(types.GET_PILOT_LINK_STARTED, getSinglePilotLink);
}

function* getBikerRating(action) {
  try {
    yield bikerService.getCommentsByBikerIds();
  } catch (error) {
    yield put(actions.pilotRatingGetFailed(error.message));
  }
}

export function* watchBikerRating() {
  yield takeEvery(types.GET_BIKER_RATING_STARTED, getBikerRating);
}

export function* getPilotSchedules(action) {
  try {
    yield bikerService.getPilotSchedulesPilot(action.payload.paramsBranch);
  } catch (error) {
    yield put(actions.pilotSchedulesGetFailed("HA OCURRIDO UN ERROR"));
  }
}

export function* watchSchedulesPilot() {
  yield takeEvery(types.GET_SCHEDULES_STARTED, getPilotSchedules);
}

export function* getPilotSchedulesMonths(action) {
  try {
    const response = yield bikerService.bikerServiceShedulesMonths(
      action.payload.month
    );
    //console.log(response);
    if (response.length !== undefined) {
      yield put(actions.pilotSchedulesMonthsCompleted(response));
    } else {
      yield put(
        actions.pilotSchedulesMonthsFailed(
          "HA OCURRIDO UN ERROR AL MANDAR LA INFORMACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.pilotSchedulesMonthsFailed("HA OCURRIDO UN ERROR"));
  }
}

export function* watchSchedulesPilotMonths() {
  yield takeEvery(types.GET_SCHEDULES_MONTH_STARTED, getPilotSchedulesMonths);
}


export function* creatingBikerRanking(action) {
  try {
    const response = yield bikerService.bikerServicesRanking(
      action.payload.props
    );

    const {data} = response;  
    if (data !== null) {
      yield put(actions.pilotCreatedRakingCompleted(response));
    } else {
      yield put(
        actions.pilotCreatingRakingFailed(
          "HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.pilotCreatingRakingFailed("HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"));
  }
}

export function* watchSchedulesBikerRankingCreating() {
  yield takeEvery(types.CREATING_RANKING_STARTED, creatingBikerRanking);
}


export function* getBikerOnlyBranch(action) {
  try {
    const response = yield bikerService.getOnlyBikerBranch()
    const {data} = response;  
    if (data !== null) {
      yield put(actions.getBikerCompleted(data));
    } else {
      yield put(
        actions.getBikerFailed(
          "HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.getBikerFailed("HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"));
  }
}

export function* watchSchedulesGetBikerOnlyBranch() {
  yield takeEvery(types.GET_BIKER_BRANCH_STARTED, getBikerOnlyBranch);
}

export function* getBikerSchedule(action) {
  try {
    const response = yield bikerService.getScheduleBiker()
    const {data} = response;  
    if (data !== null) {
      yield put(actions.getScheduleBikerCompleted(data));
    } else {
      yield put(
        actions.getScheduleBikerFailed(
          "HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.getScheduleBikerFailed("HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"));
  }
}

export function* watchSchedulesGetBikerSchedule() {
  yield takeEvery(types.GET_SCHEDULE_STARTED, getBikerSchedule);
}

export function* getAssistenceBiker(action) {
  try {
    const response = yield bikerService.getAssistanceBiker()
    const {data} = response;  
    if (data !== null) {
      yield put(actions.getAssistenBikerCompleted(data));
    } else {
      yield put(
        actions.getAssistenBikerFailed(
          "HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.getAssistenBikerFailed("HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"));
  }
}

export function* watchGetAssistenceBiker() {
  yield takeEvery(types.GET_ASSISTENT_BIKER_STARTED, getAssistenceBiker);
}

export function* getUpdateAssistenceBiker(action) {
  try {
    let {date} = action.payload;
    const response = yield bikerService.getUpateAssistanceBiker(date)
    const {data} = response;  
    if (data !== null) {
      yield put(actions.getUpdateAssistenBikerCompleted(data));
    } else {
      yield put(
        actions.getUpdateAssistenBikerFailed(
          "HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"
        )
      );
    }
  } catch (error) {
    yield put(actions.getUpdateAssistenBikerFailed("HA OCURRIDO UN ERROR AL CREAR LA CALIFICACIÓN"));
  }
}

export function* watchGetUpdateAssistenceBiker() {
  yield takeEvery(types.GET_UPDATE_ASSISTENCE_BIKER_STARTED, getUpdateAssistenceBiker);
}