import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, Avatar } from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  adminMenu,
  branchMenu,
  companyMenu,
  ownerMenu,
  demoMenu,
  requestsMenu,
} from "../../constants/menu";
import { ROLES } from "../../constants/constants";
import { getController, getAuthEmail } from "../../logic/reducers";
import { RenderMenuItemList } from "../items";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nameText: {
    fontSize: "20px",
    fontWeight: "normal",
    fontFamily: "Lato",
    color: "#565656",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerContainer: {
    overflow: "auto",
    width: 260,
  },
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  large: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const itemsByRole = ({ controller }) => {
  switch (controller.role) {
    case ROLES.ADMINISTRATOR:
      return companyMenu;
    case ROLES.CONTROLLER:
      return adminMenu;
    case ROLES.REQUEST:
      return requestsMenu;
    case ROLES.USER:
      return branchMenu({ branchId: controller.branchId });
    case ROLES.OWNER:
      return ownerMenu;
    case ROLES.DEMO:
      return demoMenu;
    default:
      return adminMenu;
  }
};
const UserComponent = ({ details, email }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
      }}
    >
      <br></br>
      <br></br>
      <Avatar alt="Remy Sharp" className={classes.large}>
        {/* <img src={Logo} width={50}></img> */}
        {details.name[0]}
      </Avatar>
      <Typography className={classes.nameText}>{details.name}</Typography>
      {"\n"}
      <small>{email}</small>
      <br></br>
      <br></br>
    </div>
  );
};
export default function XsSideBar({
  handleDrawerClose,
  open,
  handleDrawerOpen,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory();
  const controller = useSelector(getController);
  const email = useSelector(getAuthEmail);
  // eslint-disable-next-line no-unused-vars
  const goToPage = (url) => {
    history.push(url);
  };
  // eslint-disable-next-line no-unused-vars
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
    >
      <UserComponent details={controller} email={email} />
      <div className={classes.drawerContainer}>
        <hr></hr>
        <TreeView
          className={classes.root}
          defaultExpanded={["3"]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <RenderMenuItemList data={itemsByRole({ controller: controller })} />
        </TreeView>
      </div>
    </SwipeableDrawer>
  );
}
