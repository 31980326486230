import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import ImageUploader from "react-images-upload";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import TabPanel from "../Design/TabPanel";
import { ThemeProvider } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSnackbar } from "notistack";
import AppBar from "@material-ui/core/AppBar";
import { useHistory } from "react-router-dom";
import { emptyOrder, defaultFormFields } from "../../constants/order_constants";
import {
  ROUTES,
  PROVIDER_TYPE,
  orderStatus,
  snackBarConst,
} from "../../constants/constants";
import theme from "../../assets/theme";
import MapWithAddress from "../Map/MapWithAddress";
import isEmpty from "lodash/isEmpty";
import { orderActions } from "../../logic/actions/orders";
import OrderForm from "../Order/OrderForm";
import {
  getCreateOrderDone,
  getCreateOrderError,
  getCreateOrderIsLoading,
} from "../../logic/selectors/orders";

const validationSchema = yup.object({
  company: yup
    .object()
    .shape({ text: yup.string().required("La compañía es requerida") })
    .required(),
  branch: yup
    .object()
    .shape({ text: yup.string().required("La sucursal es requerida") })
    .required(),
  phone: yup.number().typeError("Télefono debe ser un valor númerico"),
  zone: yup.number().typeError("Zona debe ser un valor númerico"),
  payMethod: yup.string().typeError("El tipo de pago es necesario indicarlo").required(),
});

const CreateOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState({
    ...emptyOrder,
  });
  const [formFields] = useState([...defaultFormFields]);
  const [markers, setMarkers] = useState([]);
  const [image, setImage] = useState();
  // eslint-disable-next-line no-unused-vars
  const [center, setCenter] = useState({ lat: 14.587461, lng: -90.514048 });
  const [tabIndex, setTabIndex] = useState(0);
  const createOrderDone = useSelector(getCreateOrderDone);
  const createOrderError = useSelector(getCreateOrderError);
  const isLoadingcreateOrder = useSelector(getCreateOrderIsLoading);

  const btnList = [
    {
      id: "cancel-order",
      type: "button",
      variant: "contained",
      color: "secondary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "cancelar",
      onClick: () => {
        history.push(ROUTES.ALL_ORDERS);
        return;
      },
    },
    {
      id: "create-new-order",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm",
      text: "crear orden",
    },
  ];

  useOnCreatingOrder(
    createOrderDone,
    createOrderError,
    isLoadingcreateOrder,
    dispatch,
    history,
    enqueueSnackbar
  );

  const onSubmit = (values) => {
    delete values.time;
    delete values.alert;
    delete values.createdAt; 
    const orderData = {
      ...values,
      payMethod: values.payMethod.value,
      provider: PROVIDER_TYPE.CONTROLLER,
      typeOrder: "ORDEN CLIENTE",
      status: orderStatus.ON_HOLD,
      without_biker: isEmpty(values.alias_id) ? "Yes" : undefined,
      image,
    };
    dispatch(orderActions.createOrder(orderData));
  };

  const renderForm = () => (
    <OrderForm
      defaultFormFields={formFields.map((formField) => ({
        ...formField,
      }))}
      // orderId={order.orderId}
      order={order}
      btnList={btnList}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      setOrder={setOrder}
      handleChange={handleChange}
      formCssClasses="scrollable-column all-upper-case"
      closeBikers
      useService
    />
  );

  const onConfirmAddress = (values) => {
    setOrder((val) => ({
      ...val,
      locationRef: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
      munic: values.munic,
      depto: values.depto,
      zone: values.zone,
    }));
    setMarkers([
      {
        position: {
          longitude: values.longitude,
          latitude: values.latitude,
        },
      },
    ]);
  };
  const handleChange = (e) => {
    setOrder({
      ...order,
      [e.target.id]: e.target.value,
    });
  };
  const renderMap = () => {
    return (
      <div className="overflow-auto">
        <MapWithAddress
          onConfirmAddress={onConfirmAddress}
          markers={markers}
          center={center}
          zoom={17}
        />
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div>
        <ImageUploader
          withIcon={true}
          buttonText="Cargar imagen"
          onChange={(pic) => setImage(pic)}
          imgExtension={[".jpg", ".png"]}
          maxFileSize={5242880}
          withPreview
          singleImage
        />
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <NavBar title="nueva orden" /> */}
      <div className="container">
        <BlockUi tag="div" blocking={isLoadingcreateOrder}>
          <div>
            <div className="col-5 vertical-top comanda-img">
              <AppBar position="static">
                <Tabs
                  value={tabIndex}
                  onChange={(e, newValue) => setTabIndex(newValue)}
                  aria-label="simple tabs example"
                >
                  <Tab label="Foto" />
                  <Tab label="Mapa" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabIndex} index={0}>
                {renderImage()}
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                {renderMap()}
              </TabPanel>
            </div>
            <div className="col-7">{renderForm()}</div>
          </div>
        </BlockUi>
      </div>
    </ThemeProvider>
  );
};

const useOnCreatingOrder = (
  createOrderDone,
  createOrderError,
  isLoadingcreateOrder,
  dispatch,
  history,
  enqueueSnackbar
) => {
  useEffect(() => {
    if (!isLoadingcreateOrder) {
      if (createOrderDone) {
        enqueueSnackbar("Orden creada correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        dispatch(orderActions.clearCreateOrder());
        history.push(ROUTES.ALL_ORDERS);
      } else if (!isEmpty(createOrderError)) {
        enqueueSnackbar(
          "Hubo un error al crear la orden, por favor, intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderDone, createOrderError, isLoadingcreateOrder]);
};

export default CreateOrder;
