import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bikerService from "../../logic/services/bikerService";
import Alert from "@material-ui/lab/Alert";
import BikerActiveTable from "./BikerActiveTable";
import { Tab, Tabs, Paper, Box } from "@material-ui/core";
import CircularProcess from "@material-ui/core/CircularProgress";
import Expandable from "../Design/Expandable";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { pilotActions } from "../../logic/actions/pilots";
import { useSnackbar } from "notistack";
import BlockUi from "react-block-ui";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { RatingBikerList } from "../../containers";
import { fetchRatingsOfToday } from "../../firebase/rating";

import {
  getBikerList,
  getIsBikerListLoading,
  getBikersError,
  getBikersByStatus,
  getBikersByStatusViewState,
  getCurrentAuthStateCompleted,
  getCurrentAuthStateError,
  getCurrentAuthStateLoading,
} from "../../logic/selectors/bikers";
import {
  BIKER_STATUS,
  VIEW_STATE,
  snackBarConst,
} from "../../constants/constants";
import BikerListByStatus from "./BikerListByStatus";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const BikerList = () => {
  const dispatch = useDispatch();
  const [deactivatedBikers, setDeactivatedBikers] = useState([]);
  const [value, setValue] = useState(0);
  const bikers = useSelector(getBikerList);
  const bikersByStatus = useSelector(getBikersByStatus);
  const bikersViewState = useSelector(getBikersByStatusViewState);
  const isBikerListLoading = useSelector(getIsBikerListLoading);
  /**Authorization selectors */
  const isAuthStateLoading = useSelector(getCurrentAuthStateLoading);
  const isAuthStateCompleted = useSelector(getCurrentAuthStateCompleted);
  const isAuthStateError = useSelector(getCurrentAuthStateError);
  const bikerError = useSelector(getBikersError);
  // eslint-disable-next-line no-unused-vars
  const [changeBikerStatusError, setChangeBikerStatusError] = useState("");
  const history = useHistory();
  const [ratings, setRatings] = useState([]);

  const updateRatings = async () => {
    try {
      let response = await fetchRatingsOfToday();
      setRatings(response);
    } catch (error) {
      console.error("error fetching Ratings: ", error);
    }
  };

  useEffect(() => {
    updateRatings();
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchInactiveBikers = async () => {
    try {
      const response = await bikerService.getBikersByStatus(
        BIKER_STATUS.INACTIVE
      );
      setDeactivatedBikers(response);
    } catch (e) {
      console.log("ERROR FETCHING UNACTIVE PILOTS");
    }
  };

  useOnLoad(dispatch, fetchInactiveBikers);

  useEffect(() => {
    if (!isAuthStateLoading) {
      if (isAuthStateCompleted) {
        enqueueSnackbar("El piloto ha sido autorizado correctamente", {
          variant: "success",
          preventDuplicate: true,
          anchorOrigin: {
            ...snackBarConst,
          },
        });
        // history.push(ROUTES.BIKERS);
      } else if (isAuthStateError) {
        enqueueSnackbar(
          "Error al autorizar al piloto, por favor intenta más tarde",
          {
            variant: "warning",
            preventDuplicate: true,
            anchorOrigin: {
              ...snackBarConst,
            },
          }
        );
      }
    }
  }, [
    isAuthStateLoading,
    isAuthStateCompleted,
    isAuthStateError,
    enqueueSnackbar,
  ]);

  const renderLoading = () => (
    <div className="progress-indicator">
      <CircularProcess />
    </div>
  );

  const renderView = () => {
    if (!isEmpty(bikerError)) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }
    if (isEmpty(bikers)) {
      return <div>No hay información a mostrar</div>;
    }
    return (
      <>
        {!isEmpty(changeBikerStatusError) && (
          <>
            <Alert severity="error">{changeBikerStatusError}</Alert>
            <br />
          </>
        )}
        <BlockUi tag="div" blocking={isAuthStateLoading}>
          <BreadCrumb
            title="TODOS LOS PILOTOS"
            match={{ path: history.location.pathname }}
          />
          <br></br>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="PILOTOS ACTIVOS" {...a11yProps(0)} />
            {/* <Tab label="PILOTOS NO ACTIVOS" {...a11yProps(1)} />
            <Tab label="PILOTOS PARA AUTORIZAR" {...a11yProps(2)} /> */}
          </Tabs>
          <TabPanel value={value} index={2}>
            <br></br>
            <div>
              <Expandable title="ACEPTAR PILOTOS" defaultExpanded>
                <BikerListByStatus
                  data={bikersByStatus}
                  loading={isAuthStateLoading}
                />
              </Expandable>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <br></br>
            <Paper elevation={0} style={{ minHeight: 400 }}>
              <Box padding={1}>
                <RatingBikerList
                  data={ratings.map((element) => {
                    let biker = bikers.filter((biker) => {
                      return biker.id === element.to;
                    });
                    if (biker.length === 0) return element;
                    return {
                      ...element,
                      name: biker[0].name,
                    };
                  })}
                />
              </Box>
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <BikerActiveTable
              biker={"bikerIsActive"}
              rows={(bikers || []).map((biker) => ({
                ...biker,
              }))}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BikerActiveTable
              biker={"bikerIsNotActive"}
              rows={(deactivatedBikers || []).map((biker) => ({
                ...biker,
              }))}
            />
          </TabPanel>
        </BlockUi>
      </>
    );
  };

  return (
    <div title="2Wheelers">
      {isBikerListLoading && bikersViewState === VIEW_STATE.LOADED
        ? renderLoading()
        : renderView()}
    </div>
  );
};

const useOnLoad = (dispatch, fetchInactiveBikers) => {
  useEffect(() => {
    dispatch(pilotActions.clearPilotsData());
    dispatch(pilotActions.getPilots());
    fetchInactiveBikers();
    dispatch(pilotActions.fetchPilotsByStatusStarted(BIKER_STATUS.NEW));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default BikerList;
