import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Divider,
} from "@material-ui/core";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import * as actions from "../../logic/actions/pilots";
import * as selectors from "../../logic/reducers";
import styled from "styled-components";

const imageDelivery =
  "https://firebasestorage.googleapis.com/v0/b/twowheelstogo-572d7.appspot.com/o/logotipos%2FDL-OL-orange.png?alt=media&token=01171bc7-ec0b-46e4-99e9-63d4835ce47b";

const TitleForm = styled.div`
  font-weight: 800;
  font-size: 22px;
  color: orange;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    display: "flex",
  },
  media: {
    height: "300px",
  },
  spacingData: {
    padding: "10px 0px",
  },
  buttonQty: {
    padding: "10px 10px",
  },
  textQty: {
    padding: "10px 10px",
  },
  dialogPilot: {
    width: 600,
    textAlign: "center",
    backgroundColor: "orange",
    color: "white",
    fontWeight: 800,
  },
  buttonPilot: {
    alignContent: "center",
    position: "absolute",
    bottom: "5px",
  },
  titleQty: {
    textAlign: "center",
    fontWeight: 800,
    paddingBottom: "20px",
  },
});

const RatingPerBikerView = ({
  branchId,
  biker,
  fetchingBiker,
  creatingRantigQty,
  schedule,
  getSchedule,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchingBiker();
    getSchedule();
  }, [fetchingBiker, getSchedule]);

  let bikersFilterBranch = (biker || []).filter((e) => e.branchId === branchId);
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = useState({});

  const handleClickOpen = (inp) => {
    setDialogValue(inp);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const getScheduleStarted = () => {
  //   let data;
  //   // eslint-disable-next-line no-array-constructor
  //   let info = new Array();
  //   if (schedule) {
  //     bikersFilterBranch.forEach((biker) => {
  //       data = schedule.find((schedules) => {
  //         if (schedules.status === "Iniciado") {
  //           return biker.id === schedules.alias.id;
  //         }
  //         return data;
  //       });
  //       info.push(data);
  //     });
  //   }
  //   return info;
  // };

  const onClickSendQty = (e) => {
    //console.log(e);
    let qty = {};
    qty.name = e.name;
    qty.id = e.id;
    qty.phone = e.phone || "+502 0000-0000";
    qty.branch = { id: e.branchId, branch: e["SUCURSAL"] };
    qty.rantig = value;
    creatingRantigQty(qty);
  };

  return (
    <div>
      <Grid container direction="column" justify="center" alignContent="center">
        <Grid item xs={12} md={12} lg={12}>
          <TitleForm> PILOTOS ASIGNADOS A SUCURSAL </TitleForm>
        </Grid>
      </Grid>
      <Grid container direction="row">
        {(bikersFilterBranch || []).map((inp, i) => {
          return (
            <div>
              <Grid item xs={4} md={6} lg={4}>
                <Card
                  style={{
                    margin: "20px 20px",
                    width: "400px",
                    height: "350px",
                  }}
                >
                  <CardActionArea style={{ display: "flex" }}>
                    <img
                      style={{
                        width: "200px",
                        height: "350px",
                        objectFit: "contain",
                      }}
                      src={inp.pic || imageDelivery}
                      alt="img"
                    />
                    <CardContent>
                      <Typography variant="h6" component="h5">
                        {`${(inp.name || "").toUpperCase()}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Tel.: +502 ${inp.phone || "000000"}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Placa: ${(
                          inp.transportId || "No tiene placa"
                        ).toUpperCase()}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`Alertas: ${inp.alert || "NO TIENE ALERTA"}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`HORARIO: ${inp.alert || "NO TIENE ALERTA"}`}
                      </Typography>
                    </CardContent>
                    <Button
                      onClick={() => handleClickOpen(inp)}
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.buttonPilot}
                    >
                      VERIFICA AL PILOTO
                    </Button>
                  </CardActionArea>
                  <CardActions></CardActions>
                </Card>
              </Grid>
            </div>
          );
        })}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogPilot}>
          {"INFORMACIÓN DE PILOTO"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={6} md={6} lg={6}>
                <img
                  style={{
                    width: "200px",
                    height: "350px",
                    objectFit: "contain",
                  }}
                  src={dialogValue.pic || imageDelivery}
                  alt="img"
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} style={{ paddingLeft: "20px" }}>
                <Typography style={{}}>
                  <div style={{ fontWeight: 800 }}> Nombre </div>
                  {`${(
                    dialogValue.name || "Nombre sin definir"
                  ).toUpperCase()}`}
                </Typography>
                <Typography className={classes.spacingData}>
                  <div style={{ fontWeight: 800 }}>Telefono:</div>
                  {`  +502 ${dialogValue.phone || "000000"}`}
                </Typography>
                <Typography className={classes.spacingData}>
                  <div style={{ fontWeight: 800 }}>Placa:</div>{" "}
                  {`  ${(
                    dialogValue.transportId || "No tiene placa"
                  ).toUpperCase()}`}
                </Typography>
                <Typography className={classes.spacingData}>
                  <div style={{ fontWeight: 800 }}>Alerta: </div>
                  {` ${(dialogValue.alert || "No tiene alerta").toUpperCase()}`}
                </Typography>
                <Typography></Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Divider light />
        <DialogActions>
          <Grid container>
            <Grid item md={12}>
              <div style={{ paddingRight: "20px" }}>
                {value !== null && (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.titleQty}>
                        CALIFICA AL PILOTO
                      </Typography>
                      <Rating
                        name="size-small"
                        defaultValue={2}
                        size="large"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
              <div className={classes.textQty}>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  id="comments-client"
                  label="DEJA ALGÚN COMENTARIO"
                  variant="outlined"
                />
              </div>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => onClickSendQty(dialogValue)}
                className={classes.buttonQty}
              >
                ENVIAR
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default connect(
  (state) => ({
    biker: selectors.fetchedBikerBranch(state),
    startedBiker: selectors.fetchingBikerBranch(state),

    schedule: selectors.fetchedScheduleBiker(state),
    startSchedule: selectors.fetchingScheduleBiker(state),
  }),

  (dispatch) => ({
    fetchingBiker() {
      dispatch(actions.getBikerStarted());
    },
    creatingRantigQty(qty) {
      dispatch(actions.pilotCreatingRakingStarted(qty));
    },
    getSchedule() {
      dispatch(actions.getScheduleBikerStarted());
    },
  })
)(RatingPerBikerView);
