import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProcess from '@material-ui/core/CircularProgress';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import * as selectors from '../../logic/reducers';
import * as actions from '../../logic/actions/orders';
import theme from '../../assets/theme';

const NewOrders = ({ isAuthenticated, isLoading, orders, onLoad, selected }) => {
    let history = useHistory();
    useEffect(onLoad, []);
    // eslint-disable-next-line no-unused-vars
    const [ comandaImage, setImage ] = useState(false);

    if(!isAuthenticated) {
        history.push('/login');
    }

    // eslint-disable-next-line array-callback-return
    orders = orders.map( order => {
        if(order.time <= 5) {
            return order;
        };
    });
    
    const selectedRow = event =>  {
        if(event.node.data !== undefined) {
            setImage(true);
            selected(event.node.data);
            history.push('./edit-order');
        }
    };

    const columnDef = [
        {
            headerName: 'Estado',
            field: 'status',
        }, {
            headerName: 'Orden',
            field: 'orderId',
        }, {
            headerName: 'Comanda',
            field: 'orderPic',
        }, {
            headerName: 'Hora de Comanda',
            field: 'createdAt',
        }, {
            headerName: 'Hora Entregado',
            field: 'deliveryAt',
        }, {
            headerName: 'Cliente',
            field: 'name',
        }, {
            headerName: 'Dirección',
            field: 'address',
        }, {
            headerName: 'Observaciones',
            field: 'nota',
        }, {
            headerName: 'Agente',
            field: 'biker',
        }, {
            headerName: 'Hora de Creación',
            field: 'startedAt',
        },  {
            headerName: 'Latitud de Entrega Sugerida',
            field: 'latSugested',
        }, {
            headerName: 'Longitud de Entrega Sugerida',
            field: 'longSugested',
        }, {
            headerName: 'Latitud de Entrega',
            field: 'latDelivered',
        }, {
            headerName: 'Longitud de Entrega',
            field: 'longDelivered',
        }, {
            headerName: 'Timepo en ruta',
            field: 'time',
            sortable: true,
            initialSort: 'desc',
        }, {
            headerName: 'Alertas',
            field: 'alert',
        }, {
            headerName: 'Empresa',
            field: 'company',
        },
    ]


    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                {/* <NavBar /> */}
                {
                    isAuthenticated && (
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            alignItems="center"
                            justify="center"
                            style={{ height: '100vh' }}
                        >
                            {
                                isLoading && orders.length === 0 && (
                                    <CircularProcess color='secondary' />
                                )
                            }

                            {
                                orders.length > 0 && (
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <div className="ag-theme-material" style={ { height: '85vh', width: 'auto' } }>
                                                <AgGridReact
                                                    rowData={ orders }
                                                    columnDefs={ columnDef }
                                                    groupDefaultExpanded={-1}
                                                    onRowDoubleClicked = { selectedRow }
                                                    groupSelectsChildren={true}
                                                    
                                                >
                                                </AgGridReact>
                                            </div>
                                        </Grid>
                                    </Fragment> 
                                )
                            }
                        </Grid>
                    )
                }
            </ThemeProvider>
        </Fragment>
    );
};

export default connect(
    state => ({
        isAuthenticated: selectors.getIsAuthenticated(state),
        isLoading: selectors.getIsFetchingOrders(state),
        orders: selectors.getOrders(state),
    }),
    dispatch => ({
        onLoad() {
            dispatch(actions.clearOrderSubscription());
            dispatch(actions.startFetchingOrders());
        },
        selected(order) {
            dispatch(actions.selectingOrder(order));
        },
    }),
)(NewOrders);