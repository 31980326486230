import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { orderAlert } from "../../constants/constants";
import lateImg from "../../assets/img/late.svg";
import onTimeImg from "../../assets/img/onTime.svg";
import toBeLateImg from "../../assets/img/toBeLate.svg";
import verifyImg from "../../assets/img/verify.svg";
import OrderAlerts from "../OrderAlerts/OrderAlerts";

const BranchGeneralResume = ({ orders }) => {
  const [onTimeOrders, setOnTimeOrders] = useState([]);
  const [checkOrders, setCheckOrders] = useState([]);
  const [toBeLateOrders, setToBeLateOrders] = useState([]);
  const [lateOrders, setLateOrders] = useState([]);

  useEffect(() => {
    if (!isEmpty(orders)) {
      setOnTimeOrders(
        orders.filter((order) => order.alert === orderAlert.ON_TIME)
      );
      setCheckOrders(
        orders.filter((order) => order.alert === orderAlert.VERIFY)
      );
      setToBeLateOrders(
        orders.filter((order) => order.alert === orderAlert.TO_BE_LATE)
      );
      setLateOrders(orders.filter((order) => order.alert === orderAlert.LATE));
    }
  }, [orders]);

  return (
    <div>
      <OrderAlerts
        useExpandable={false}
        fullBackground
        orders={[
          {
            label: orderAlert.ON_TIME,
            value: (
              <div>
                <div className="order-alerts-img">
                  <img src={onTimeImg} alt="Alert" />
                </div>
                <div className="order-alerts-text">
                  <h2>{`${onTimeOrders.length}/${orders.length}`}</h2>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            ),
            cssClases: "card-order-alert-on-time",
            cardCssClases:
              "card-container-half card-order-alert-on-time no-border-bottom",
          },
          {
            label: orderAlert.VERIFY,
            value: (
              <div>
                <div className="order-alerts-img">
                  <img src={verifyImg} alt="Alert" />
                </div>
                <div className="order-alerts-text">
                  <h2>{`${checkOrders.length}/${orders.length}`}</h2>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            ),
            cssClases: "circle-cell-1",
            cardCssClases: "card-container-half circle-cell-1 no-border-bottom",
          },
          {
            label: orderAlert.TO_BE_LATE,
            value: (
              <div>
                <div className="order-alerts-img">
                  <img src={toBeLateImg} alt="Alert" />
                </div>
                <div className="order-alerts-text">
                  <h2>{`${toBeLateOrders.length}/${orders.length}`}</h2>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            ),
            cssClases: "card-order-alert-to-be-late",
            cardCssClases:
              "card-container-half card-order-alert-to-be-late no-border-bottom",
          },
          {
            label: orderAlert.LATE,
            value: (
              <div>
                <div className="order-alerts-img">
                  <img src={lateImg} alt="Alert" />
                </div>
                <div className="order-alerts-text">
                  <h2>{`${lateOrders.length}/${orders.length}`}</h2>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            ),
            cssClases: "card-order-alert-late",
            cardCssClases:
              "card-container-half card-order-alert-late no-border-bottom",
          },
        ]}
        total={orders.length}
      />
    </div>
  );
};

export default BranchGeneralResume;
