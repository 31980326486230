/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { orderStatus, orderAlert } from "../../constants/constants";
import * as yup from "yup";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-material.css";
// import "devextreme/dist/css/dx.material.orange.light.compact.css";
import { ROUTES } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import * as selectors from "../../logic/reducers";
import * as actions from "../../logic/actions/orders";
import theme from "../../assets/theme";
import BreadCrumb from "../../containers/navs/breadcrumb";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { INPUT_TYPE } from "../../constants/constants";
import { deliveredOrdersFilterActions } from "../../logic/actions/filters";
import { getDeliveredOrdersFilters } from "../../logic/selectors/filters";
import dateHelper from "../../helpers/dateHelper";
import DataGrid, {
  Column,
  SearchPanel,
  Paging,
  Pager,
  StateStoring,
  HeaderFilter,
  Summary,
  TotalItem,
  FilterPanel,
  FilterRow,
  Export,
  Selection,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";
const formFields = [
  {
    label: "COMPAÑÍAS A EXCLUIR",
    autofocus: true,
    name: "excludedCompanies",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [],
    multiple: true,
  },
];

const HistoryOrders = ({ orders, onLoad, clearOrderEdition }) => {
  const history = useHistory();
  // const uiTheme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [avgTime, setAvgTime] = useState(0);

  const dispatch = useDispatch();
  const savedFilterModel = useSelector(getDeliveredOrdersFilters);
  const [filterModel, setFilterModel] = useState([]);
  const [filterModelToUse, setFilterModelToUse] = useState([]);
  const [isFilterConfigured, setIsFilterConfigured] = useState(false);

  const [customFormFields, setCustomFormFields] = useState(formFields);
  const [filters, setFilters] = useState({
    excludedCompanies: [],
  });
  const [data, setData] = useState([]);

  useEffect(onLoad, []);

  const selectedRow = (event) => {
    if (event.row.id !== undefined) {
      clearOrderEdition();
      history.push(`${ROUTES.EDIT_ORDER}/${event.row.id}?delivered=true`);
    }
  };

  const onFilterChange = (filterModelItems) => {
    let avg = 0;
    if (!isEmpty(filterModelItems)) {
      let filteredOrders = [...data];
      filterModelItems.forEach((filterModel) => {
        if (!isEmpty(filterModel.value)) {
          filteredOrders = filteredOrders.filter((order) => {
            if (filterModel.operatorValue === "contains") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .includes((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "startsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .startsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "endsWith") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .endsWith((filterModel.value || "").toLowerCase());
            }
            if (filterModel.operatorValue === "equals") {
              return (order[filterModel.columnField] || "")
                .toLowerCase()
                .localeCompare((filterModel.value || "").toLowerCase());
            }
            return true;
          });
        }
      });
      avg =
        (filteredOrders || []).reduce((acc, current) => acc + current.time, 0) /
        filteredOrders.length;
    } else {
      avg =
        (data || []).reduce((acc, current) => acc + current.time, 0) /
        data.length;
    }
    if (!isEmpty(filterModelItems)) {
      if (!isEmpty(filterModel)) {
        setFilterModel(undefined);
      } else {
        setFilterModelToUse(filterModelItems);
      }
    }
    setAvgTime(avg);
  };

  const applyFilters = ({ excludedCompanies }) => {
    if (!isEmpty(excludedCompanies)) {
      setData(
        orders.filter(
          (order) =>
            !Boolean(
              excludedCompanies.find(
                (company) => company.value === order.company.id
              )
            )
        )
      );
    } else {
      setData(orders);
    }
  };

  const onSubmit = (values) => {
    setFilters(values);
    applyFilters(values);
  };

  useEffect(() => {
    const avg =
      (orders || []).reduce((acc, current) => acc + current.time, 0) /
      orders.length;
    setAvgTime(avg);
    const companies = {};
    orders.forEach((order) => {
      if (isEmpty(companies[order.company.id])) {
        companies[order.company.id] = order.company.name;
      }
    });
    if (!isEmpty(companies)) {
      setCustomFormFields((customFields) => {
        let newData = [...customFields];
        newData[0] = {
          ...newData[0],
          options: Object.keys(companies).map((company) => ({
            value: company,
            text: companies[company],
          })),
        };
        return newData;
      });
    }
    setData(orders);
  }, [orders]);

  useEffect(() => {
    if (!isFilterConfigured) {
      if (!isEmpty(savedFilterModel)) {
        setFilterModel(savedFilterModel);
        setFilterModelToUse(savedFilterModel);
        setIsFilterConfigured(true);
      }
    }
  }, [savedFilterModel, isFilterConfigured]);
  // data.map((e)=>{// console.log("DATOS",e)})
  const allowedPageSizes = [20, 30, 40];

  const renderTimeOnRute = ({ data }) => {
    // console.log(data)
    return (
      <div>
        <p className="data-grid-text">{`${dateHelper.secondsToHms(
          data.time
        )}`}</p>
      </div>
    );
  };
  const renderCircleCell = ({ data }) => {
    // // console.log(`Data undefined: ${data.circle.id}`)
    const val = data.circle?.id || "";
    if (val === 1) {
      return <div className="circle-cell circle-cell-1">{val}</div>;
    } else if (val === 2) {
      return <div className="circle-cell circle-cell-2">{val}</div>;
    } else if (val === 3) {
      return <div className="circle-cell circle-cell-3">{val}</div>;
    } else if (val === 4) {
      return <div className="circle-cell circle-cell-4">{val}</div>;
    } else {
      return <div className="circle-cell circle-cell-1">P</div>;
    }
  };

  const renderAlertCell = ({ data }) => {
    if (data.alert.toLowerCase() === orderAlert.TO_BE_LATE.toLowerCase()) {
      return (
        <div className="order-notification order-alert-to-be-late">
          <small>{data.alert}</small>
        </div>
      );
    } else if (data.alert.toLowerCase() === orderAlert.ON_TIME.toLowerCase()) {
      return (
        <div className="order-notification order-alert-on-time">
          <small>{data.alert}</small>
        </div>
      );
    } else if (data.alert.toLowerCase() === orderAlert.VERIFY.toLowerCase()) {
      return (
        <div className="order-notification order-alert-verify">
          <small>{data.alert}</small>
        </div>
      );
    } else if (data.alert.toLowerCase() === orderAlert.LATE.toLowerCase()) {
      return (
        <div className="order-notification order-alert-late">
          <small>{data.alert}</small>
        </div>
      );
    } else {
      return (
        <div className="order-notification order-alert-default">
          <small>{data.alert}</small>
        </div>
      );
    }
  };

  const selectedChanged = (e) => {
    console.table([e]);
    // clearOrderEdition();
    history.push(`${ROUTES.EDIT_ORDER}/${e.selectedRowKeys[0]}?delivered=true`);
    window.location.reload();
  };

  return (
    <Fragment>
      <Prompt
        message={(location, action) => {
          dispatch(
            deliveredOrdersFilterActions.onFilterSave(
              filterModelToUse.map((filter) => ({
                columnField: filter.columnField,
                operatorValue: filter.operatorValue,
                value: filter.value,
              }))
            )
          );
          return true;
        }}
      />
      <ThemeProvider theme={theme}>
        <BreadCrumb
          match={{ path: history.location.pathname }}
          title="ORDENES ENTREGADAS"
        />
        <DataGrid
          id="DataOrderDelivery"
          keyExpr="id"
          dataSource={data}
          showBorders={true}
          width={"100%"}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          repaintChangesOnly={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          noDataText="NO HAY INFORMACIÓN"
          onSelectionChanged={selectedChanged}
        >
          <Selection mode="single" />
          <HeaderFilter visible={true} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="ordersTwoDelivered"
          />
          <SearchPanel visible={true} />
          <Pager
            showPageSizeSelector={true}
            displayMode={true}
            allowedPageSizes={allowedPageSizes}
            showInfo={true}
            showNavigationButtons={true}
          />
          <FilterRow visible={true} />
          <FilterPanel
            visible={true}
            texts={{
              createFilter: "CREAR FILTRO",
              clearFilter: "ELIMINAR FILTRO",
              filterEnabledHint: "CREACIÓN DE FILTROS",
            }}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <FilterBuilderPopup title="FILTROS" />
          <Paging enabled={true} defaultPageSize={55} />
          <Summary recalculateWhileEditing={true}>
            <TotalItem
              column="alert"
              alignment="left"
              displayFormat="ORDENES: {0}"
              summaryType="count"
            />
          </Summary>

          <Scrolling
            columnRenderingMode="virtual"
            showScrollbar="onHover"
            scrollByContent
          />

          <Column caption="ALERTA" cellRender={renderAlertCell} />
          <Column dataField="orderId" caption="ORDEN" />
          <Column dataField="alias.name" caption="AGENTE" />
          <Column dataField="name" caption="CLIENTE" />
          <Column dataField="company.name" caption="EMPRESA" />
          <Column dataField="branch.name" caption="SUCURSAL" />
          <Column dataField="address" caption="DIRECCION" />
          <Column caption="TIEMPO EN RUTA" cellRender={renderTimeOnRute} />
          {/* <Column
            caption="CIRCULO"
            cellRender={renderCircleCell}
            
          /> */}

          <Column dataField="zone" caption="ZONA" />
          <Column dataField="munic" caption="MUNICIPIO" />
        </DataGrid>
      </ThemeProvider>
    </Fragment>
  );
};

// eslint-disable-next-line no-lone-blocks
{
  /* <div className="container">
  <div className="container-body">
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <DynamicForm
            obj={filters}
            fields={customFormFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
          />
        </Paper>
        <br />
        <Paper>
          <Box padding={1}>
            {!matches && (
              <SingleTableView
                rows={data.map((order) => ({
                  ...order,
                  createdDate: !isEmpty(order.createdAt)
                    ? new Date(order.createdAt.seconds * 1000)
                    : "",
                  circleValue: order.circle ? order.circle.id : 0,
                }))}
                onCellClick={selectedRow}
                onFilterChange={onFilterChange}
                columns={DELIVERED_ORDERS_COLUMNS}
                filterModel={
                  !isEmpty(filterModel)
                    ? { items: [...filterModel] }
                    : undefined
                }
              />
            )}
            {matches && (
              <ResponsiveOrderList
                orders={data.map((order) => ({
                  ...order,
                  createdDate: !isEmpty(order.createdAt)
                    ? `${order.createdAt.seconds}`
                    : "",
                }))}
                onClick={selectedRow}
              />
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
    <div className="table-footer">
      <div>
        <Grid container>
          <Grid item xs={3}>
            <small>{`Ordenes entregadas: ${data.length}`}</small>
          </Grid>
          {/* <Grid item xs={3}>
          <small>{`Tiempo promedio de entrega: ${avgTime.toFixed(
            2
          )} min`}</small>
        </Grid>
        </Grid>
      </div>
    </div>
  </div>
</div>; */
}

export default connect(
  (state) => ({
    isAuthenticated: selectors.getIsAuthenticated(state),
    isLoading: selectors.getIsFetchingOrders(state),
    orders: selectors.getHistoryOrders(state),
  }),
  (dispatch) => ({
    onLoad() {
      dispatch(
        actions.startFetchingHistoryOrders({
          statusFilter: orderStatus.DELIVERED,
        })
      );
    },
    clearOrderEdition() {
      dispatch(actions.orderActions.clearOrderEdition());
    },
  })
)(HistoryOrders);
